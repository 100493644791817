import React, { useState, useEffect } from "react";
import { axiosInstance } from "../utils/axiosInterceptor";
import { Container, Row, Col } from "react-bootstrap";
import moment from "moment";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import DashboardLogo1 from "../assests/elecbitsLogo.svg";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import LinearProgress from "@material-ui/core/LinearProgress";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import "./projectView.css";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { SnackbarProvider, useSnackbar } from "notistack";
import Divider from "@mui/material/Divider";
import CustomBreadcrumbs from "../Components/CustomBreadcrumbs";

let stages = ["Idea", "POC", "Mass Manufacturing"];
let microservicies = [
  "Consultation with IoT expert (Understand IoT product development/manufacturing lifecycle)",
  "PCB Designing",
  "Firmware Development",
  "Cloud Architecture",
  "Enclosure Designing",
  "3D Printing",
  "Injection Moulding",
  "Mass Production (BOM Procurement, PCB Print, Assembly)",
  "Get Certifications",
];
export default function ProjectView(props) {
  let [loading, set_loading] = useState(false);
  let [project_data, set_projects] = useState(null);
  let [dialogOpen, setDialogOpen] = useState(false);
  let [dialogLoad, setDialogLoad] = useState(false);
  let [projectName, setProjectName] = useState(null);
  let [description, setDescription] = useState(null);
  let [servicies, setSelectedServicies] = useState([]);
  let [stageActive, setStageActive] = useState(null);
  let [createLoading, setCreateLoading] = useState(false);

  let fetchProject = () => {
    set_loading(true);
    axiosInstance
      .get("/projects/getOngoingProject")
      .then((resp) => {
        console.log(resp);
        set_projects(resp.data);
        set_loading(false);
      })
      .catch((err) => {
        set_loading(false);
      });
  };

  const useStyles = makeStyles((theme) => ({
    rootLoad: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
      formControl: {
        marginBottom: theme.spacing(3.1),
        minWidth: "100%",
      },
    },
  }));

  const classes = useStyles();
  useEffect(() => {
    document.title = "Elecbits | Project Console";
    fetchProject();
  }, []);
  const ColorFab = withStyles((theme) => ({
    root: {
      color: "#ffffff",
      backgroundColor: "#000000",
      "&:hover": {
        backgroundColor: "#000000",
      },
    },
  }))(Fab);
  const handleChangeMultiple = (event) => {
    setSelectedServicies(event.target.value);
  };
  const { enqueueSnackbar } = useSnackbar();
  return (
    <>
      <Dialog
        open={dialogOpen}
        fullWidth
        maxWidth="md"
        onClose={() => {
          setDialogOpen(false);
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <>
          {
            <div className={classes.root}>
              {createLoading ? <LinearProgress></LinearProgress> : ""}
            </div>
          }
          <div className={classes.rootLoad} style={{ position: "absolute" }}>
            {dialogLoad ? <LinearProgress /> : ""}
          </div>
          <DialogTitle id="form-dialog-title">Create a new project</DialogTitle>
          <DialogContent>
            <form>
              <TextField
                className={classes.formControl}
                required
                multiline
                margin="dense"
                id="name"
                label="Project Name"
                fullWidth
                value={projectName}
                onChange={(e) => {
                  setProjectName(e.target.value);
                }}
                variant="outlined"
                style={{ marginBottom: "10px" }}
              />
                {/*
                  <TextField
                    className={classes.formControl}
                    required
                    multiline
                    margin="dense"
                    id="name"
                    label="Project Description"
                    fullWidth
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                    variant="outlined"
                    style={{ marginBottom: "10px" }}
                  />
                */}
              <div className="form_label">
                Which stage are you currently at?
              </div>
              <FormControl
                fullWidth
                className={classes.formControl}
                style={{ marginBottom: "10px" }}
              >
                <InputLabel id="demo-simple-select-label">Stage</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={stageActive}
                  onChange={(e) => {
                    setStageActive(e.target.value);
                  }}
                >
                  {stages.map((stage) => {
                    return <MenuItem value={stage}>{stage}</MenuItem>;
                  })}
                </Select>
              </FormControl>
              <div className="form_label">
                Which microservices do you intend to use?
              </div>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">
                  Microservices
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  multiple
                  input={<Input />}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>Placeholder</em>;
                    }
                    return selected.join(", ");
                  }}
                  value={servicies}
                  onChange={handleChangeMultiple}
                  MenuProps={{
                    getContentAnchorEl: () => null,
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {microservicies.map((service) => {
                    return (
                      <MenuItem value={service}>
                        <Checkbox
                          color="primary"
                          checked={servicies.indexOf(service) > -1}
                        />
                        <ListItemText primary={service} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <DialogActions style={{ marginTop: "20px" }}>
                <Button
                  onClick={() => {
                    setDialogOpen(false);
                  }}
                  color="primary"
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    setCreateLoading(true);
                    axiosInstance
                      .post("/projects/createProjectClient", {
                        projectName: projectName,
                        projectDescription: description,
                        projectOwner: props.profile_data.data._id,
                        currentStage: stageActive,
                        microservices: servicies,
                      })
                      .then((resp) => {
                        setCreateLoading(false);
                        enqueueSnackbar("Successfully created a new project", {
                          variant: "success",
                        });
                        setDialogOpen(false);
                        fetchProject();
                      })
                      .catch((err) => {
                        console.log(err);
                        setCreateLoading(false);
                        enqueueSnackbar("Not able to create a new project", {
                          variant: "error",
                        });
                      });
                  }}
                  variant="contained"
                  color="primary"
                >
                  Create
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </>
      </Dialog>
      {!loading && project_data ? (
        <Container
          style={{ width: "90vw", paddingBottom: "20px", paddingTop: "20px" }}
        >
          <Row>
            <Col>
              <CustomBreadcrumbs
                data={[
                  {
                    name: "Home",
                    link: "/",
                  },
                  {
                    name: "Virtual Project Manager",
                    link: "/virtualProjectManager",
                  },
                ]}
              />
              <h2 id="fon_head">🚀 Virtual Project Manager</h2>
            </Col>
          </Row>
          <Row>
            <span className="xysad">
              <u>Projects Owned</u>
            </span>
          </Row>
          <Row>
            {project_data.projectsOwned.length == 0 ? (
              <h4 className="xcvr">No Owned Project</h4>
            ) : (
              ""
            )}
            {project_data.projectsOwned.map((project) => {
              return (
                <Col md={4} sm={12} xs={12}>
                  <div className="project_card">
                    {project.projectPic ? (
                      <img
                        style={{
                          width: "100%",
                          height: "257px",
                          objectFit: "cover",
                        }}
                        src={project.projectPic.fileLocation}
                      ></img>
                    ) : (
                      <img
                        style={{ width: "100%" }}
                        src="https://elecbits.in/wp-content/uploads/2021/06/Graphic-for-website.png"
                      ></img>
                    )}
                    <div className="txt_wrap">
                      <div className="project_name_card">
                        {project.projectName}
                      </div>
                      <div className="card_view_btn">
                        <Link to={`/viewProject/${project._id}`}>
                          <Button variant="outlined" color="primary">
                            View Project
                          </Button>
                        </Link>
                      </div>
                      {/* <div>
                        Created At :{" "}
                        {project.createdAt
                          ? moment(project.createdAt).format(
                              "MMMM Do YYYY, h:mm a"
                            )
                          : "NA"}
                      </div>
                      <div>
                        Updated At :{" "}
                        {project.updatedAt
                          ? moment(project.updatedAt).format(
                              "MMMM Do YYYY, h:mm a"
                            )
                          : "NA"}
                      </div> */}
                      <div className="proj_card_description">
                        <div>Project Description</div>
                        <div>{project.projectDescription}</div>
                      </div>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
          <Divider style={{ marginTop: "13px" }}></Divider>
          <Row>
            <span className="xysad" style={{ marginTop: "30px" }}>
              <u>Projects Collabed</u>
            </span>
          </Row>
          <Row>
            {project_data.projectsCollabed.length == 0 ? (
              <h4 className="xcvr">No Collaborated Project</h4>
            ) : (
              ""
            )}
            {project_data.projectsCollabed.map((project) => {
              return (
                <Col md={4} sm={12} xs={12}>
                  <div className="project_card">
                    {project.projectPic ? (
                      <img
                        style={{
                          width: "100%",
                          height: "257px",
                          objectFit: "cover",
                        }}
                        src={project.projectPic.fileLocation}
                      ></img>
                    ) : (
                      <img
                        style={{ width: "100%" }}
                        src="https://elecbits.in/wp-content/uploads/2021/06/Graphic-for-website.png"
                      ></img>
                    )}
                    <div className="txt_wrap">
                      <div className="project_name_card">
                        {project.projectName}
                      </div>
                      <div className="card_view_btn">
                        <Link to={`/viewProject/${project._id}`}>
                          <Button variant="outlined" color="primary">
                            View Project
                          </Button>
                        </Link>
                      </div>
                      {/* <div>
                        Created At :{" "}
                        {project.createdAt
                          ? moment(project.createdAt).format(
                              "MMMM Do YYYY, h:mm a"
                            )
                          : "NA"}
                      </div>
                      <div>
                        Updated At :{" "}
                        {project.updatedAt
                          ? moment(project.updatedAt).format(
                              "MMMM Do YYYY, h:mm a"
                            )
                          : "NA"}
                      </div> */}
                      <div className="proj_card_description">
                        <div>Project Description</div>
                        <div>{project.projectDescription}</div>
                      </div>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
          <Row>
            <div
              style={{
                position: "absolute",
                right: "15px",
                bottom: "15px",
                textAlign: "right",
              }}
            >
              <ColorFab
                size="large"
                color="primary"
                aria-label="add"
                onClick={() => {
                  setDialogOpen(true);
                }}
              >
                <AddIcon />
              </ColorFab>
            </div>
          </Row>
        </Container>
      ) : loading ? (
        <div className="time_wrap">
          <img src={DashboardLogo1} style={{ width: "150px" }}></img>
          <CircularProgress style={{ marginLeft: "20px" }}></CircularProgress>
        </div>
      ) : (
        <div className="nothing_found" style={{ textAlign: "center" }}>
          <div className="console_head">Elecbits Projects Console</div>
          <div className="no_proj_text">
            No project created yet<br></br>
            Click the ‘+’ button to create a new project
            <img
              style={{ width: "21px" }}
              src="https://uc-emoji.azureedge.net/orig/ca/fe5c38a90070c12f3a9f77492023d4.png"
            ></img>
          </div>
          <div style={{ textAlign: "right", paddingRight: "26px" }}>
            <ColorFab
              size="large"
              color="primary"
              aria-label="add"
              onClick={() => {
                setDialogOpen(true);
              }}
            >
              <AddIcon />
            </ColorFab>
          </div>
        </div>
      )}
    </>
  );
}
