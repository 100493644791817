import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import { axiosInstance } from "../utils/axiosInterceptor";
import { useSnackbar } from "notistack";
import LinearProgress from "@material-ui/core/LinearProgress";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import DeleteTwoToneIcon from "@material-ui/icons/DeleteTwoTone";
import clsx from "clsx";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link } from "react-router-dom";
import { nanoid } from "nanoid";
import DashboardLogo1 from "../assests/elecbitsLogo.svg";
import CircularProgress from "@material-ui/core/CircularProgress";

var createReactClass = require("create-react-class");

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  root1: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  textField: {
    width: "25ch",
  },
}));
let finalBilling = {};
let nodes = {};
  
export default function EditBilling(props) {
  
  let [pendingAmount, setPending] = useState(0);
  let [paidAmount, setPaid] = useState(0);
  let [loading, setLoading] = useState(false);
  let [pageLoading, setPageLoading] = useState(false);
  let [projData, setProjData] = useState(null);

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  useEffect(() => {
    //Get the project Billing
    finalBilling={};
    nodes={};
    setUpProject();
  }, []);
  let setUpProject = () => {
    let projId = props.history.location.pathname.split("/")[2];
    setPageLoading(true);
    axiosInstance
      .get(`/projects/getProject/${projId}`)
      .then((resp) => {
        setPageLoading(false);
        setProjData(resp.data);
        console.log(resp.data);
        setPending();
        if (resp.data.billing) {
          for (let obj in resp.data.billing) {
            console.log(resp.data.billing[obj].type);
            if (resp.data.billing[obj].type.toString() == "Pending Amount") {
              setPending(resp.data.billing[obj].amount);
            } else if (
              resp.data.billing[obj].type.toString() == "Paid Amount"
            ) {
              setPaid(resp.data.billing[obj].amount);
            } else {
              let id = nanoid(5);
              finalBilling[id] = resp.data.billing[obj];
              nodes[id] = (
                <div className="form_el_cont" key={id}>
                  <FormControl
                    className={clsx(
                      classes.margin,
                      classes.withoutLabel,
                      classes.textField
                    )}
                    style={{
                      width: "55%",
                    }}
                  >
                    <TextField
                      label="Billing type"
                      value={resp.data.billing[obj]["type"]}
                      variant="outlined"
                    />
                  </FormControl>
                  <FormControl
                    className={clsx(
                      classes.margin,
                      classes.withoutLabel,
                      classes.textField
                    )}
                    style={{
                      width: "35%",
                    }}
                    className={classes.margin}
                    variant="outlined"
                  >
                    <InputLabel htmlFor="outlined-adornment-amount">
                      Amount
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      value={resp.data.billing[obj]["amount"]}
                      startAdornment={
                        <InputAdornment position="start">₹</InputAdornment>
                      }
                      labelWidth={60}
                    ></OutlinedInput>
                  </FormControl>

                  <FormControl className={clsx(classes.margin)}>
                    <IconButton
                      onClick={() => {
                        handleDelete(id);
                      }}
                    >
                      <DeleteTwoToneIcon></DeleteTwoToneIcon>
                    </IconButton>
                  </FormControl>
                </div>
              );
            }
          }
          var Fields = createReactClass({
            render: () => {
              // Change this to get the list from props or state
              let rNodes = [];
              for (let k in nodes) {
                rNodes.push(nodes[k]);
              }
              return <>{rNodes}</>;
            },
          });
          ReactDOM.render(<Fields />, document.getElementById("addedFields"));
        }
      })
      .catch((err) => {
        setPageLoading(false);
      });
  };
  let saveBilling = () => {
    setLoading(true);
    let billing = [];
    for (let key in finalBilling) {
      billing.push(finalBilling[key]);
    }
    billing.push({
      amount: pendingAmount,
      type: "Pending Amount",
    });
    billing.push({
      amount: paidAmount,
      type: "Paid Amount",
    });
    let projId = props.history.location.pathname.split("/")[2];
    axiosInstance
      .put(`/projects/editDetails/${projId}`, {
        billing: billing,
      })
      .then((resp) => {
        enqueueSnackbar("Billing updated successfully", {
          variant: "success",
        });
        setLoading(false);
      })
      .catch((err) => {
        enqueueSnackbar("Not able to update billing", {
          variant: "error",
        });
        setLoading(false);
      });
  };
  let handleDelete = (idx) => {
    for (let id in finalBilling) {
      nodes[id] = (
        <div className="form_el_cont" key={id}>
          <FormControl
            className={clsx(
              classes.margin,
              classes.withoutLabel,
              classes.textField
            )}
            style={{
              width: "55%",
            }}
          >
            <TextField
              label="Billing type"
              value={finalBilling[id]["type"]}
              variant="outlined"
            />
          </FormControl>
          <FormControl
            className={clsx(
              classes.margin,
              classes.withoutLabel,
              classes.textField
            )}
            style={{
              width: "35%",
            }}
            className={classes.margin}
            variant="outlined"
          >
            <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              value={finalBilling[id]["amount"]}
              startAdornment={
                <InputAdornment position="start">₹</InputAdornment>
              }
              labelWidth={60}
            ></OutlinedInput>
          </FormControl>

          <FormControl className={clsx(classes.margin)}>
            <IconButton
              onClick={() => {
                handleDelete(id);
              }}
            >
              <DeleteTwoToneIcon></DeleteTwoToneIcon>
            </IconButton>
          </FormControl>
        </div>
      );
    }

    delete nodes[idx];
    delete finalBilling[idx];
    var Fields = createReactClass({
      render: () => {
        // Change this to get the list from props or state
        let rNodes = [];
        for (let k in nodes) {
          rNodes.push(nodes[k]);
        }
        return <>{rNodes}</>;
      },
    });
    ReactDOM.render(<Fields />, document.getElementById("addedFields"));
  };

  let handleAdd = () => {
    for (let id in finalBilling) {
      nodes[id] = (
        <div className="form_el_cont" key={id}>
          <FormControl
            className={clsx(
              classes.margin,
              classes.withoutLabel,
              classes.textField
            )}
            style={{
              width: "55%",
            }}
          >
            <TextField
              label="Billing type"
              value={finalBilling[id]["type"]}
              variant="outlined"
            />
          </FormControl>
          <FormControl
            className={clsx(
              classes.margin,
              classes.withoutLabel,
              classes.textField
            )}
            style={{
              width: "35%",
            }}
            className={classes.margin}
            variant="outlined"
          >
            <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              value={finalBilling[id]["amount"]}
              startAdornment={
                <InputAdornment position="start">₹</InputAdornment>
              }
              labelWidth={60}
            ></OutlinedInput>
          </FormControl>

          <FormControl className={clsx(classes.margin)}>
            <IconButton
              onClick={() => {
                handleDelete(id);
              }}
            >
              <DeleteTwoToneIcon></DeleteTwoToneIcon>
            </IconButton>
          </FormControl>
        </div>
      );
    }
    let idx = nanoid(5);
    finalBilling[idx] = {};
    finalBilling[idx]["type"] = "";
    finalBilling[idx]["amount"] = "";

    nodes[idx] = (
      <div className="form_el_cont" key={idx}>
        <FormControl
          className={clsx(
            classes.margin,
            classes.withoutLabel,
            classes.textField
          )}
          style={{
            width: "55%",
          }}
        >
          <TextField
            label="Billing type"
            onChange={(e) => {
              finalBilling[idx]["type"] = e.target.value;

              console.log(finalBilling.hasOwnProperty(idx), finalBilling);
            }}
            variant="outlined"
          />
        </FormControl>
        <FormControl
          className={clsx(
            classes.margin,
            classes.withoutLabel,
            classes.textField
          )}
          style={{
            width: "35%",
          }}
          className={classes.margin}
          variant="outlined"
        >
          <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            onChange={(e) => {
              finalBilling[idx]["amount"] = e.target.value;
            }}
            startAdornment={<InputAdornment position="start">₹</InputAdornment>}
            labelWidth={60}
          ></OutlinedInput>
        </FormControl>

        <FormControl className={clsx(classes.margin)}>
          <IconButton
            onClick={() => {
              handleDelete(idx);
            }}
          >
            <DeleteTwoToneIcon></DeleteTwoToneIcon>
          </IconButton>
        </FormControl>
      </div>
    );

    var Fields = createReactClass({
      render: () => {
        // Change this to get the list from props or state
        let rNodes = [];
        for (let k in nodes) {
          rNodes.push(nodes[k]);
        }
        return <>{rNodes}</>;
      },
    });
    ReactDOM.render(<Fields />, document.getElementById("addedFields"));
  };

  return (
    <>
      {!pageLoading ? (
        <>
          {loading ? (
            <div className={classes.root1}>
              <LinearProgress></LinearProgress>
            </div>
          ) : (
            ""
          )}
          <div className="billing_container">
            <div className="bill_head">
              <Link to="/clientEngagement">
                <div>
                  <IconButton aria-label="delete">
                    <ArrowBackIcon fontSize="large"></ArrowBackIcon>
                  </IconButton>
                </div>
              </Link>
              <h3>Edit Billing</h3>
              <div></div>
            </div>

            <div className="form_el_cont">
              <FormControl
                className={clsx(
                  classes.margin,
                  classes.withoutLabel,
                  classes.textField
                )}
                style={{
                  width: "55%",
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Billing type"
                  variant="outlined"
                  value="Pending Amount"
                />
              </FormControl>
              <FormControl
                className={clsx(
                  classes.margin,
                  classes.withoutLabel,
                  classes.textField
                )}
                style={{
                  width: "35%",
                }}
                className={classes.margin}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-amount">
                  Amount
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-amount"
                  startAdornment={
                    <InputAdornment position="start">₹</InputAdornment>
                  }
                  labelWidth={60}
                  value={pendingAmount}
                  onChange={(e) => {
                    setPending(e.target.value);
                  }}
                ></OutlinedInput>
              </FormControl>

              <FormControl className={clsx(classes.margin)}>
                <IconButton disabled>
                  <DeleteTwoToneIcon></DeleteTwoToneIcon>
                </IconButton>
              </FormControl>
            </div>
            <div className="form_el_cont">
              <FormControl
                className={clsx(
                  classes.margin,
                  classes.withoutLabel,
                  classes.textField
                )}
                style={{
                  width: "55%",
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Billing type"
                  variant="outlined"
                  value="Paid Amount"
                />
              </FormControl>
              <FormControl
                className={clsx(
                  classes.margin,
                  classes.withoutLabel,
                  classes.textField
                )}
                style={{
                  width: "35%",
                }}
                className={classes.margin}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-amount">
                  Amount
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-amount"
                  startAdornment={
                    <InputAdornment position="start">₹</InputAdornment>
                  }
                  labelWidth={60}
                  value={paidAmount}
                  onChange={(e) => {
                    setPaid(e.target.value);
                  }}
                ></OutlinedInput>
              </FormControl>

              <FormControl className={clsx(classes.margin)}>
                <IconButton disabled>
                  <DeleteTwoToneIcon></DeleteTwoToneIcon>
                </IconButton>
              </FormControl>
            </div>
            <span id="addedFields"></span>
            <div style={{ width: "100%", textAlign: "center" }}>
              <Button
                style={{ width: "80%", marginTop: "5px" }}
                color="primary"
                variant="outlined"
                onClick={(e) => {
                  handleAdd();
                }}
              >
                Add Billing
              </Button>
            </div>
            <div className="billing_divider"></div>
            <div
              className="actions_btns"
              style={{ width: "100%", textAlign: "left" }}
            >
              <Link to="/">
                <Button
                  color="primary"
                  variant="contained"
                  style={{ marginRight: "7px" }}
                >
                  Cancel
                </Button>
              </Link>
              <Button color="primary" variant="contained" onClick={saveBilling}>
                Save Changes
              </Button>
            </div>
          </div>
        </>
      ) : (
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <img src={DashboardLogo1} style={{ width: "150px" }}></img>
          <CircularProgress style={{ marginLeft: "20px" }}></CircularProgress>
        </div>
      )}
    </>
  );
}
