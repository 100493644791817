import {
  styled,
  Box,
  Grid,
  TextField,
  Select,
  Slider,
  Button,
  TableRow,
} from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
// import { Select } from "@material-ui/core";

export const PageContainer = styled(Box)(() => ({
  width: "100vw",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
}));

export const ColosseumProjectsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  flex: 1,
  padding: "50px 50px",
  fontFamily: "Poppins",
  backgroundColor: "#F4F7FE",
  overflowY: "auto",

  "& .title": {
    // marginTop: "30px",
    fontWeight: 600,
  },
  "& .sub-title": {
    marginTop: "10px",
    color: "#8B8B8B",
    marginBottom: "50px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "20px 20px",
  },
}));

export const CustomSelect = styled(Select)(() => ({
  width: "100%",

  backgroundColor: "transparent",
  borderRadius: 8,
  padding: "10px",
  "& .MuiSelect-select": {
    padding: "5px",
  },
  "& .MuiInputBase-root.MuiOutlinedInput-root": {
    border: "2px solid #8B8B8B4D",
    borderRadius: "8px",
  },
  "& fieldset": {
    // border:'none',
    border: "2px solid #8B8B8B4D",
    borderRadius: "8px",
  },
  "& legend": {
    width: "unset",
  },
}));

export const StyledInput = styled(TextField)(() => ({
  "& input": {
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: "10px 10px 10px 0px",
    color: "#7A7A7A",
    "&::placeholder": {
      color: "#7A7A7A",
    },
  },
  "&:focus": {
    outline: "none",
  },
  "& legend": {
    width: "unset",
  },
  "& fieldset": {
    border: "none",
  },
  "& .MuiInputBase-root.MuiOutlinedInput-root": {
    backgroundColor: "#fff",
    borderRadius: "8px",
  },
}));

export const CustomInput = styled(TextField)(() => ({
  width: "25vw",
  border: "2px solid #8B8B8B4D",
  borderRadius: "8px",
  "& input": {
    // backgroundColor: "#fff",
    borderRadius: "8px",
    padding: "12px 10px 12px 0px",
    // color: "#7A7A7A",

    "&::placeholder": {
      // color: "#7A7A7A",
    },
  },
  "&:focus": {
    outline: "none",
  },
  "& legend": {
    width: "unset",
  },
  "& fieldset": {
    border: "none",
  },
  "& .MuiInputBase-root.MuiOutlinedInput-root": {
    // backgroundColor: "#fff",
    borderRadius: "8px",
  },
}));

const getPriorityColor = (priority) => {
  if (priority == "low") {
    return "rgba(29, 201, 255, 0.15)";
  }
  if (priority == "medium") {
    return "rgba(158, 0, 255, 0.15)";
  }
  if (priority == "high") {
    return "rgba(255, 151, 42, 0.15)";
  }
  if (priority == "very high") {
    return "rgba(255, 0, 0, 0.15)";
  }
};

const getPriorityBorder = (priority) => {
  if (priority == "low") {
    return "1px solid rgba(29, 201, 255)";
  }
  if (priority == "medium") {
    return "1px solid rgba(158, 0, 255)";
  }
  if (priority == "high") {
    return "1px solid rgba(255, 151, 42)";
  }
  if (priority == "very high") {
    return "1px solid rgba(255, 0, 0)";
  }
};

export const StyledRow = styled(TableRow)(({ priority }) => ({
  backgroundColor: getPriorityColor(priority?.toLowerCase()),
  border: getPriorityBorder(priority?.toLowerCase()),
}));
