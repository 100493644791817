import React, { useState, useEffect } from 'react';

//components
import CustomFileDropper from './CustomFileDropper';
//libs
import {
  Modal,
  Box,
  styled,
  Button,
  Slide,
  Typography,
  TextField,
  Avatar,
  InputAdornment,
  useTheme,
} from '@mui/material';
import { axiosInstance } from '../utils/axiosInterceptor';
import { useSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';

//assets
import { ReactComponent as ClockIcon } from '../assests/clock.svg';
import { ReactComponent as DocumentIcon } from '../assests/document.svg';
import { ReactComponent as CommentIcon } from '../assests/comment.svg';
import { ReactComponent as DownloadIcon } from '../assests/download.svg';
import { ReactComponent as AddCommentIcon } from '../assests/addComment.svg';
import bin from '../assests/bin.png';

import moment from 'moment';

const ModalContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '70vw',
  height: '80vh',
  left: '15vw',
  top: '15vh',
  backgroundColor: '#fff',
  boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.2)',
  borderRadius: 15,
  display: 'flex',
  flexDirection: 'column',
  padding: '20px',
  gap: '20px',
  '& .heading': {
    fontSize: '1.2em',
    fontWeight: 700,
    fontFamily: 'Poppins',
  },
  '& legend': {
    width: 'unset',
  },
  '& .MuiTypography-root': {
    fontFamily: 'Poppins',
  },
  fontFamily: 'Poppins',
  [theme.breakpoints.down('md')]: {
    width: '90vw',
    left: '5vw',
  },
}));
const UploadModalContainer = styled(Box)(() => ({
  position: 'absolute',
  width: '36vw',
  height: 'fit-content',
  left: '32vw',
  top: '15vh',
  backgroundColor: '#fff',
  boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.2)',
  borderRadius: 15,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  gap: '20px',
  '& .heading': {
    fontSize: '1.2em',
    fontWeight: 700,
    fontFamily: 'Poppins',
  },
  '& legend': {
    width: 'unset',
  },
  '& .MuiTypography-root': {
    fontFamily: 'Poppins',
  },
  fontFamily: 'Poppins',
}));

const CustomButton = styled(LoadingButton)(() => ({
  '&&&': {
    backgroundColor: '#FF972A',
    color: '#fff',
    textTransform: 'none',
    padding: '5px 50px',
    borderRadius: '8px',
    fontWeight: 500,
  },
}));

const hyperlink = (textContent) => {
  let a = textContent?.replace(
    /(https?:\/\/[^\s]+)/g,
    (href) => `<a href="${href}" target="_blank">${href}</a>`
  );
  return { __html: a };
};

const ProjectTrackingLogModal = ({
  state,
  toggleModal,
  service,
  type,
  project,
  number,
  isAdmin,
}) => {
  const [comment, setComment] = useState('');
  const [allLogs, setAllLogs] = useState([]);
  const [allDocs, setAllDocs] = useState([]);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [uploadFilesLoading, setUploadFilesLoading] = useState({
    progress: 0,
    loading: false,
  });
  const [stageData, setStageData] = useState(null);

  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const handleChange = (e) => {
    setComment(e.target.value);
  };

  const toggleUploadModal = () => {
    setShowUploadModal(!showUploadModal);
  };

  useEffect(() => {
    if (project && project.projectTrackingLogs) {
      console.log(project);
      setAllLogs([...project?.projectTrackingLogs]);
    } else {
      project.projectTrackingLogs = [];
      setAllLogs([...project?.projectTrackingLogs]);
    }
  }, [project]);

  const handleName = (name, len) => {
    if (len === null) {
      len = 20;
    }
    if (name === null) {
      return name;
    }

    if (name.length > len) {
      return name.substring(0, len) + '...';
    } else {
      return name;
    }
  };

  const handleUploadFiles = async () => {
    if (comment.length == 0) {
      enqueueSnackbar('Please add a comment!', { variant: 'error' });
      return;
    }

    let formData = new FormData();
    formData.append('comment', comment);

    let url;
    if (service === '3d') {
      url = `/3d/admin/addCommentLog/${type}/${project._id}`;
      uploadFiles.forEach((fl) => formData.append('3d', fl));
    } else if (service == 'fab') {
      url = `/fab/admin/addCommentLog/${project._id}`;
      uploadFiles.forEach((fl) => formData.append('fab', fl));
    } else if (service == 'bom') {
      url = `/bom/admin/addCommentLog/${project._id}`;
      uploadFiles.forEach((fl) => formData.append('bom', fl));
    }

    setUploadFilesLoading({ ...uploadFilesLoading, loading: true });
    try {
      const token = localStorage.getItem('token');
      axiosInstance
        .post(url, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-type': 'multipart/form-data',
          },
        })
        .then((res) => {
          setAllLogs(res.data.data.newProject.projectTrackingLogs);
        });
      setUploadFiles([]);
      setComment('');
      // setProject(res?.data?.project);

      setUploadFilesLoading({ ...uploadFilesLoading, loading: false });
      enqueueSnackbar('Files uploaded successfully!', { variant: 'success' });
    } catch (err) {
      console.log(err);
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const handleDeleteComment = async (comLog) => {
    console.log(comLog);
    try {
      setAllLogs(allLogs.filter((curr) => curr._id != comLog._id));

      let url = '';
      if (service === '3d') {
        //type projectId stageNumber
        url = `/3d/admin/deleteCommentLog/${type}/${project._id}/${comLog._id}`;
      } else if (service == 'fab') {
        url = `/fab/admin/deleteCommentLog/${project._id}/${comLog._id}`;
      } else if (service == 'bom') {
        url = `/bom/admin/deleteCommentLog/${project._id}/${comLog._id}`;
      }

      const res = await axiosInstance.post(url);
      if (res) {
        enqueueSnackbar('Comment deleted successfully!', {
          variant: 'success',
        });
      }
      console.log(res, 'deleted res');
    } catch (e) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
      console.log(e);
    }
  };

  console.log(uploadFiles);
  return (
    <Modal open={state} onClose={toggleModal}>
      <Slide in={state} direction="up">
        <ModalContainer>
          <Box sx={{ width: '100%', textAlign: 'center' }}>
            <div className="heading">Project Trackings Comments</div>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              height: '90%',
              padding: '0px 0px 30px 0px',
              marginRight: '30px',
              [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
              },
            }}
          >
            {/* <Box
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                [theme.breakpoints.down('md')]: {
                  height: '50%',
                  width: '100%',
                  padding: '10px 0px',
                },
              }}
            >
              <Typography
                sx={{ marginTop: '20px', '& svg': { transform: 'scale(0.8)' } }}
              >
                <DocumentIcon /> Documents
              </Typography>

              <Box sx={{ margin: '10px 0px' }}>
                <CustomButton onClick={toggleUploadModal}>
                  UPLOAD DOC
                </CustomButton>
              </Box>

              <Modal open={showUploadModal} onClose={toggleUploadModal}>
                <Slide in={showUploadModal} direction="up">
                  <UploadModalContainer>
                    <div className="heading">Upload files</div>
                    <CustomFileDropper
                      uploadFiles={uploadFiles}
                      small
                      setFile={setUploadFiles}
                    />
                    <CustomButton
                      onClick={handleUploadFiles}
                      loading={uploadFilesLoading.loading}
                      loadingPosition="end"
                    >
                      UPLOAD
                    </CustomButton>
                  </UploadModalContainer>
                </Slide>
              </Modal>

              <Box
                sx={{
                  flex: 1,
                  overflowY: 'auto',
                  marginTop: '0px',
                  padding: '10px 0px',
                }}
              >
                {allDocs.map((i) => (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      border: '2px solid #E8E8E8',
                      margin: '10px 5px',
                      padding: '15px 15px',
                      width: '70%',
                      borderRadius: '10px',
                      '& svg': { transform: 'scale(0.8)' },
                    }}
                  >
                    <Typography>
                      {handleName(i.originalname || i.originalName, 25)}
                    </Typography>
                    <a href={i.location}>
                      {' '}
                      <DownloadIcon style={{ cursor: 'pointer' }} />
                    </a>
                  </Box>
                ))}
              </Box>
            </Box> */}
            <Modal open={showUploadModal} onClose={toggleUploadModal}>
              <Slide in={showUploadModal} direction="up">
                <UploadModalContainer>
                  <div className="heading">Upload files</div>
                  <CustomFileDropper
                    uploadFiles={uploadFiles}
                    small
                    setFile={setUploadFiles}
                  />
                  <CustomButton
                    // onClick={handleUploadFiles}
                    // loading={uploadFilesLoading.loading}
                    // loadingPosition="end"
                    onClick={toggleUploadModal}
                  >
                    Confirm
                  </CustomButton>
                </UploadModalContainer>
              </Slide>
            </Modal>
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                minWidth: '50%',
                borderLeft: '1px solid #8B8B8B4D',
                padding: '0px 30px 0px 30px',
                height: '100%',
                [theme.breakpoints.down('md')]: {
                  height: '50%',
                  width: '100%',
                  borderLeft: 'none',
                  borderTop: '1px solid #8B8B8B4D',
                  padding: '10px 0px',
                },
              }}
            >
              <Typography
                sx={{
                  '& svg': { transform: 'scale(0.8)' },
                  marginBottom: '10px',
                }}
              >
                {/* <CommentIcon /> Comments */}
              </Typography>
              <Box
                sx={{
                  width: '100%',
                  flex: 1,
                  overflowY: 'auto',
                }}
              >
                {allLogs.length > 0 &&
                  allLogs
                    .slice(0)
                    .reverse()
                    .map((remark) => (
                      <Box
                        sx={{
                          display: 'flex',
                          whiteSpace: 'pre-line',
                          justifyContent: 'flex-start',
                          alignItems: 'flex-start',
                          // boxShadow: "0px 0px 30px rgba(139, 139, 139, 0.15)",
                          border: '2px solid #E8E8E8',
                          margin: '10px 5px',
                          padding: '10px 5px',
                          width: '95%',
                          overflow: 'visible',
                          borderRadius: '8px',
                          gap: '10px',
                          position: 'relative',
                        }}
                      >
                        <Box
                          sx={{
                            width: '20%',
                            height: '100%',
                            display: 'grid',
                            placeItems: 'center',
                          }}
                        >
                          <Avatar
                            sx={{ width: '65px', height: '65px' }}
                            src={remark.remark.userProfilePic}
                          />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Box sx={{ flex: 1, maxWidth: '80%' }}>
                            <Typography
                              sx={{ color: '#2F2F2F', fontSize: '0.8em' }}
                            >
                              {remark.remark.userName}
                            </Typography>
                            <Typography
                              sx={{ color: '#8B8B8B', fontSize: '0.7em' }}
                            >
                              {moment(remark.remark.date).format(
                                'MMMM Do YYYY, h:mma'
                              )}
                            </Typography>
                            <Typography sx={{ color: '#2F2F2F' }}>
                              {' '}
                              <div
                                style={{
                                  maxWidth: '100%',
                                  overflowWrap: 'break-word',
                                }}
                                dangerouslySetInnerHTML={hyperlink(
                                  remark.remark.comment
                                )}
                              />
                            </Typography>
                          </Box>
                          {remark.remarkDocs.map((doc) => (
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                border: '2px solid #E8E8E8',
                                margin: '10px 0px',
                                padding: '15px 15px',
                                width: '100%',
                                borderRadius: '10px',
                                '& svg': { transform: 'scale(0.8)' },
                              }}
                            >
                              <Typography>
                                {handleName(
                                  doc.originalname || doc.originalName,
                                  25
                                )}
                              </Typography>
                              <a href={doc.location}>
                                {' '}
                                <DownloadIcon style={{ cursor: 'pointer' }} />
                              </a>
                            </Box>
                          ))}
                        </Box>
                        <Box
                          sx={{
                            position: 'absolute',
                            bottom: '0',
                            right: '0',
                            margin: '1rem',
                            cursor: 'pointer',
                          }}
                          onClick={() => handleDeleteComment(remark)}
                        >
                          <img src={bin} alt="" />
                        </Box>
                      </Box>
                    ))}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  gap: '10px',
                  alignItems: 'center',
                  marginTop: '20px',
                }}
              >
                <Avatar />
                <form onSubmit={handleUploadFiles} style={{ width: '100%' }}>
                  <TextField
                    autoFocus
                    value={comment}
                    onChange={handleChange}
                    sx={{
                      width: '100%',
                      maxHeight: '10rem',
                      overflowY: 'scroll',
                      backgroundColor: '#E8E8E880',
                      border: 'none',
                      color: '#2F2F2F',
                      borderRadius: '8px',
                      '& fieldset': {
                        border: 'none',
                      },
                      '& input': {
                        padding: '10px',
                      },
                    }}
                    // InputProps={{
                    //   endAdornment: (

                    //   ),
                    // }}
                    multiline
                  />
                </form>
                <InputAdornment>
                  <DocumentIcon
                    onClick={toggleUploadModal}
                    style={{ cursor: 'pointer', marginRight: '0.8rem' }}
                  />
                  <AddCommentIcon
                    onClick={handleUploadFiles}
                    style={{ cursor: 'pointer' }}
                  />
                </InputAdornment>
              </Box>
              <Box>
                {uploadFiles.map((i) => (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      border: '2px solid #E8E8E8',
                      margin: '10px 5px',
                      padding: '15px 15px',
                      width: '70%',
                      borderRadius: '10px',
                      '& svg': { transform: 'scale(0.8)' },
                    }}
                  >
                    <Typography>{handleName(i.name, 100)}</Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </ModalContainer>
      </Slide>
    </Modal>
  );
};

export default ProjectTrackingLogModal;
