import React, { useEffect, useState, useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Container, Row, Col } from 'react-bootstrap';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import LockIcon from '@material-ui/icons/Lock';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import { axiosInstance } from '../../src/utils/axiosInterceptor';
import { SnackbarProvider, useSnackbar } from 'notistack';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import DashboardLogo1 from '../assests/elecbitsLogo.svg';
import CreateIcon from '@material-ui/icons/Create';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import DescriptionIcon from '@material-ui/icons/Description';
import SmsIcon from '@material-ui/icons/Sms';
import GetAppIcon from '@material-ui/icons/GetApp';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import Linkify from 'linkify-react';
import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import LinkIcon from '@mui/icons-material/Link';
import CancelIcon from '@mui/icons-material/Cancel';
import axios from 'axios';
import LoadingButton from '@mui/lab/LoadingButton';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px',
    paddingBottom: '20px',
    paddingTop: '20px',
    maxWidth: '850px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
export default function AdminTimeLine_view(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [stageOpened, setOpened] = useState(null);
  const [loading, setLoading] = useState(false);
  const [remarks, setRemarks] = useState(null);
  const [project_data, setProjectData] = useState(null);
  const [stages, setStages] = useState(null);
  const [remarksLoading, setRemarksLoading] = useState(false);
  const [fileSelected, setFileSelected] = useState(null);
  const [cmtAdding, setCmtAdding] = useState(false);
  const [remark, setRemark] = useState(null);
  const inputFile = useRef(null);

  const handleClickOpen = (stage) => {
    setOpened(stage);
    setRemarks(stage.remarks);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    let projectId = props.history.location.pathname.split('/')[2];
    setLoading(true);

    axiosInstance
      .get(`/projects/getProject/${projectId}`)
      .then((resp) => {
        setLoading(false);
        console.log(resp.data);
        setProjectData(resp.data);
        setStages(resp.data.stageInfo);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const { enqueueSnackbar } = useSnackbar();
  console.log(stages);
  const fetchRemarks = (projectId, stageId) => {
    setRemarksLoading(true);
    axiosInstance
      .post(`/projects/getRemarks/${projectId}`, {
        stageId: stageId,
      })
      .then((remarks) => {
        setRemarks(remarks.data.response);
        setRemarksLoading(false);
      })
      .catch((err) => {
        setRemarksLoading(false);
      });
  };

  return project_data && !loading ? (
    <Container
      style={{ overflowX: 'auto', borderRadius: '10px', marginBottom: '20px' }}
    >
      <input
        onChange={(e) => {
          setFileSelected(e.target.files[0]);
        }}
        type="file"
        id="file"
        ref={inputFile}
        style={{ display: 'none' }}
      />
      <Row>
        <Col
          style={{
            paddingTop: '40px',
            textAlign: 'center',
            color: 'rgba(0, 0, 0, 0.904)',
            paddingBottom: '40px',
          }}
          md={12}
          sm={12}
        >
          <h2>{project_data.projectName}</h2>
        </Col>
      </Row>
      <Row>
        <Col md={12} sm={12}>
          <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            fullWidth="true"
            maxWidth="lg"
          >
            {loading ? (
              <div className={classes.root}>
                <LinearProgress></LinearProgress>
              </div>
            ) : (
              ''
            )}
            {stageOpened ? (
              <>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                  {stageOpened.stageName}
                </DialogTitle>
                <DialogContent dividers>
                  <Container style={{ padding: '0px' }}>
                    <Row>
                      <Col md={6} sm={12}>
                        <div className="sec">
                          <h3 className="sec_head">
                            <CreateIcon style={{ width: '21px' }}></CreateIcon>
                            <span style={{ marginLeft: '6px' }}>
                              Description
                            </span>
                          </h3>
                          <Typography
                            style={{ whiteSpace: 'pre-wrap' }}
                            gutterBottom
                          >
                            {stageOpened.stageDescription ? (
                              <Linkify tagName="p" options={{}}>
                                {stageOpened.stageDescription}
                              </Linkify>
                            ) : (
                              'No description provided'
                            )}
                          </Typography>
                        </div>
                        <div className="sec">
                          <h3 className="sec_head">
                            <CalendarTodayIcon
                              style={{ width: '21px' }}
                            ></CalendarTodayIcon>
                            <span style={{ marginLeft: '6px' }}>Timeline</span>
                          </h3>
                          {!stageOpened.status ||
                          stageOpened.status.toString() == 'locked' ? (
                            <Typography gutterBottom>
                              Stage is locked
                            </Typography>
                          ) : (
                            <div className="date_div">
                              <div>
                                Start Date :{' '}
                                {moment(
                                  stageOpened.startDate,
                                  'YYYY-MM-DDTHH:mm:ss'
                                ).format('dddd, MMMM Do YYYY, h:mm a')}
                              </div>
                              <div>
                                End Date :{' '}
                                {moment(
                                  stageOpened.endDate,
                                  'YYYY-MM-DDTHH:mm:ss'
                                ).format('dddd, MMMM Do YYYY, h:mm a')}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="sec">
                          <h3 className="sec_head">₹ BILLING</h3>
                          Stage Unlock Amount : ₹{' '}
                          {stageOpened.advanceCosting
                            ? stageOpened.advanceCosting
                            : 'NA'}
                          <br />
                          On Completion Ammount : ₹{' '}
                          {stageOpened.pendingCosting
                            ? stageOpened.pendingCosting
                            : 'NA'}
                          <br />
                          Estimated Total : ₹{' '}
                          {stageOpened.estimatedCost
                            ? stageOpened.estimatedCost
                            : 'NA'}
                          <br />
                          Amount Paid : ₹{' '}
                          {stageOpened.paidAmount
                            ? stageOpened.paidAmount
                            : 'NA'}
                        </div>
                      </Col>
                      <Col md={6} sm={12}>
                        {/* <div className="sec">
                          <h3 className="sec_head">
                            <DescriptionIcon
                              style={{ width: '21px' }}
                            ></DescriptionIcon>
                            <span style={{ marginLeft: '6px' }}>Documents</span>
                          </h3>
                          <Typography gutterBottom>
                            {stageOpened.documents.length > 0
                              ? stageOpened.documents.map((document) => {
                                  console.log(document);
                                  return (
                                    <div className="document_item">
                                      {document.fileName}

                                      <a
                                        href={document.fileLocation}
                                        target="_blank"
                                      >
                                        <IconButton>
                                          <GetAppIcon></GetAppIcon>
                                        </IconButton>
                                      </a>
                                    </div>
                                  );
                                })
                              : 'No documents attached'}
                          </Typography>
                        </div> */}
                        <div className="sec">
                          <h3 className="sec_head">
                            <SmsIcon style={{ width: '21px' }}></SmsIcon>
                            <span style={{ marginLeft: '6px' }}>Remarks</span>
                          </h3>
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();

                              let formData = new FormData();
                              formData.append('projectId', project_data._id);
                              if (fileSelected) {
                                formData.append('doc', fileSelected);
                                formData.append('type', 'multimedia');
                              } else {
                                formData.append('type', 'textMessage');
                                formData.append('message', remark);
                              }

                              let token = localStorage.getItem('token');
                              setCmtAdding(true);
                              axios
                                .post(
                                  `https://platform.elecbits.in/api/projects/addRemark/${stageOpened._id}`,
                                  formData,
                                  {
                                    headers: {
                                      Authorization: `Bearer ${token}`,
                                      'Content-type': 'multipart/form-data',
                                    },
                                  }
                                )
                                .then((resp) => {
                                  setCmtAdding(false);
                                  fetchRemarks(
                                    project_data._id,
                                    stageOpened._id
                                  );
                                  enqueueSnackbar('Remark added successfully', {
                                    variant: 'success',
                                  });
                                  setRemark('');
                                  setFileSelected(null);
                                })
                                .catch((err) => {
                                  setCmtAdding(false);
                                  console.log(err, 'this is the error');
                                  enqueueSnackbar('Not able to add remark', {
                                    variant: 'error',
                                  });
                                });
                            }}
                          >
                            <div className="slector_cmts">
                              <div className="attach_icn">
                                <IconButton
                                  onClick={() => {
                                    inputFile.current.click();
                                  }}
                                >
                                  <AttachFileIcon
                                    style={{ transform: 'rotate(45deg)' }}
                                  ></AttachFileIcon>
                                </IconButton>
                              </div>
                              <TextField
                                id="standard-multiline-flexible"
                                label={
                                  fileSelected ? 'Add File' : 'Add Comment'
                                }
                                focused={
                                  fileSelected || (remark && remark.length > 0)
                                    ? true
                                    : false
                                }
                                multiline
                                maxRows={7}
                                style={{
                                  width: '100%',
                                  marginTop: '4px',
                                  marginBottom: '10px',
                                }}
                                value={
                                  fileSelected ? fileSelected.name : remark
                                }
                                onChange={(e) => {
                                  if (!fileSelected) {
                                    setRemark(e.target.value);
                                  }
                                }}
                              />
                              {fileSelected ? (
                                <IconButton
                                  onClick={() => {
                                    setFileSelected(null);
                                    setRemark('');
                                  }}
                                >
                                  <CancelIcon></CancelIcon>
                                </IconButton>
                              ) : (
                                ''
                              )}
                            </div>

                            <div
                              style={{
                                marginBottom: '20px',
                                textAlign: 'right',
                              }}
                            >
                              <LoadingButton
                                type="submit"
                                endIcon={<SendIcon />}
                                loading={cmtAdding}
                                loadingPosition="end"
                                variant="contained"
                              >
                                Send
                              </LoadingButton>
                            </div>
                          </form>

                          {remarksLoading ? (
                            <div style={{ textAlign: 'center' }}>
                              <CircularProgress></CircularProgress>
                            </div>
                          ) : (
                            <div className="remark_section_contain">
                              <Typography gutterBottom>
                                {remarks
                                  .slice(0)
                                  .reverse()
                                  .map((remark) => {
                                    return (
                                      <div className="dialog_remark">
                                        <div className="avatar_area">
                                          {remark.creator &&
                                          remark.creator.profilePic ? (
                                            <Avatar
                                              src={remark.creator.profilePic}
                                            ></Avatar>
                                          ) : (
                                            <Avatar></Avatar>
                                          )}
                                        </div>
                                        <div className="remark_abt">
                                          <div className="remark_creator">
                                            <span className="author_name">
                                              {remark.creator
                                                ? remark.creator.admin
                                                  ? 'Admin User'
                                                  : `${remark.creator.email}`
                                                : `NA`}
                                            </span>

                                            <span className="cmt_date">
                                              {remark.createdAt
                                                ? moment(
                                                    remark.createdAt
                                                  ).format(
                                                    'MMMM Do YYYY, h:mm a'
                                                  )
                                                : ''}
                                            </span>
                                          </div>
                                          <div
                                            style={{ whiteSpace: 'pre-wrap' }}
                                            className="remark_info"
                                          >
                                            {remark.remark_type &&
                                            remark.remark_type.toString() ==
                                              'multimedia' ? (
                                              <div className="downloadCmt">
                                                <u>
                                                  {remark.info.originalname}
                                                </u>
                                                <a href={remark.info.location}>
                                                  <IconButton>
                                                    <DownloadForOfflineIcon></DownloadForOfflineIcon>
                                                  </IconButton>
                                                </a>
                                              </div>
                                            ) : (
                                              <Linkify tagName="p" options={{}}>
                                                {remark.info}
                                              </Linkify>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                {remarks.length == 0 ? 'No remarks' : ''}
                              </Typography>
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </DialogContent>
              </>
            ) : (
              ''
            )}
          </Dialog>

          <Timeline align="left">
            {stages
              ? stages.map((stage) => {
                  let class_name;
                  let chipNode;

                  if (stage.status) {
                    if (stage.status.toString() == 'completed') {
                      chipNode = (
                        <Chip
                          icon={<DoneIcon></DoneIcon>}
                          color="secondary"
                          label="Completed"
                        ></Chip>
                      );
                      class_name = 'completed';
                    } else if (
                      stage.status.toString() == 'active' ||
                      stage.status.toString() == 'acitve'
                    ) {
                      chipNode = (
                        <Chip
                          icon={
                            <NotificationsActiveIcon></NotificationsActiveIcon>
                          }
                          color="primary"
                          label="Active"
                        ></Chip>
                      );
                      class_name = 'active';
                    } else {
                      chipNode = (
                        <Chip
                          icon={<LockIcon></LockIcon>}
                          label="Locked"
                        ></Chip>
                      );
                      class_name = 'locked';
                    }
                    console.log(class_name);
                  } else {
                    if (
                      project_data.activeStageNumber && stage._id
                        ? stage._id.toString() ==
                          project_data.activeStageNumber.toString()
                        : false
                    ) {
                      chipNode = (
                        <Chip
                          icon={
                            <NotificationsActiveIcon></NotificationsActiveIcon>
                          }
                          color="primary"
                          label="Active"
                        ></Chip>
                      );
                      class_name = 'active';
                    } else {
                      chipNode = (
                        <Chip
                          icon={<LockIcon></LockIcon>}
                          label="Locked"
                        ></Chip>
                      );
                      class_name = 'locked';
                    }
                  }
                  return (
                    <TimelineItem>
                      <TimelineOppositeContent>
                        <Typography variant="body2" color="textSecondary">
                          {stage.endDate
                            ? moment(
                                stage.endDate,
                                'YYYY-MM-DDTHH:mm:ss'
                              ).format('dddd, MMMM Do YYYY, h:mm:ss a')
                            : 'Due date:NA'}
                        </Typography>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Paper elevation={3} className={classes.paper}>
                          <Container>
                            <Row style={{ paddingRight: '20px' }}>
                              <Col md={9} sm={12} className="text_timeline">
                                <Typography variant="h6" component="h1">
                                  {stage.stageName}
                                </Typography>
                                <Typography style={{ whiteSpace: 'pre-wrap' }}>
                                  {stage.stageDescription}
                                </Typography>
                                <div className="chips">{chipNode}</div>
                              </Col>
                              <Col md={3} sm={12} className="details_btn">
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={() => {
                                    handleClickOpen(stage);
                                  }}
                                  disabled={class_name == 'locked'}
                                >
                                  More Details
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                        </Paper>
                      </TimelineContent>
                    </TimelineItem>
                  );
                })
              : ''}
          </Timeline>
        </Col>
      </Row>
    </Container>
  ) : (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
      }}
    >
      <img src={DashboardLogo1} style={{ width: '150px' }}></img>
      <CircularProgress style={{ marginLeft: '20px' }}></CircularProgress>
    </div>
  );
}
