import React, { useState, useEffect } from 'react';

//components
import CustomBreadcrumbs from './Components/CustomBreadcrumbs';
import DashboardHeader from './ClientEngagement/DashboardHeader';
import CustomTooltip from './Components/CustomToolTip';
//utils
import {
  VendorStats,
  VendorStatsLeft,
  VendorStatsRight,
  VendorLeftUp,
  VendorLeftDown,
  StatNumberBox,
  VendorRightUp,
  VendorRightDown,
} from './VSM.styles';
//styles
import {
  PageContainer,
  TimelineContainer,
  CustomButton,
  CustomSelect,
  TimeLineStage,
} from './BOMProcurement/ProjectTimeline/BomTImeline.styles';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  RadialBarChart,
  RadialBar,
} from 'recharts';

import { ReactComponent as RocketIcon } from './assests/rocket.svg';
import { ReactComponent as EyeIcon } from './assests/eye.svg';

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  InputAdornment,
  useTheme,
  FormControl,
  Select,
  InputLabel,
  Pagination,
  Typography,
} from '@mui/material';
import { MenuItem } from '@material-ui/core';
import { ReactComponent as Arrow } from './assests/shape.svg';
import CircularProgress from '@material-ui/core/CircularProgress';
import DashboardLogo1 from './assests/elecbitsLogo.svg';
import { useHistory } from 'react-router-dom';
//assets
import { axiosInstance } from './utils/axiosInterceptor';
import CountUp from 'react-countup';
import { CustomButton2 } from './Admin/VendorManagement.styles';

const VMS = ({ isAdmin, history }) => {
  const [vendorNumbers, setVendorNumbers] = useState([
    {
      numbers: 7.6,
      title: 'New vendors onboarded this month',
      // subtitle: 'bruh',
      titleIcon: <Arrow style={{ width: '1.2rem' }} />,
    },
    {
      numbers: 350,
      title: 'Vendors active this month',
      subtitle: '10.2% this month',
      stitleIcon: <Arrow style={{ width: '0.8rem', marginRight: '0.5rem' }} />,
    },
    {
      numbers: 0,
      title: 'Total Vendors Onboarded',
      subtitle: '10.20% this year',
      stitleIcon: <Arrow style={{ width: '0.8rem', marginRight: '0.5rem' }} />,
    },
  ]);
  const cities = [
    'Mumbai',
    'Delhi',
    'Pune',
    'Nashik',
    'Gurugram',
    'Mohali',
    'Kolkata',
    'Ahemdabad',
  ];
  const states = [
    'Maharashtra',
    'Uttar Pradesh',
    'Goa',
    'Karnataka',
    'Punjab',
    'Haryana',
    'Gujarat',
  ];
  const barColors = ['#0702fb', '#ff972a', '#0ca42d'];
  const [serviceWiseData, setServiceWiseData] = useState([
    {
      name: '18-24',
      uv: 31.47,
      fill: '#ff972a',
    },
    {
      name: '25-29',
      uv: 26.69,
      fill: '#0702fb',
    },
    {
      name: '25-29',
      uv: 26.69,
      fill: '#0ca42d',
    },
  ]);
  const [filters, setFilters] = useState({
    city: 'Mumbai',
    state: 'Maharashtra',
  });

  const [vendorBarGraph, setVendorBarGraph] = useState([
    {
      title: 'City-wise Vendors',
      type: 'city',
      chartData: [],
      options: cities,
    },
    {
      title: 'State-wise Vendors',
      type: 'state',
      chartData: [],
      options: states,
    },
  ]);

  const [loading, setLoading] = useState(true);
  //const history = useHistory();
  useEffect(() => {
    if (localStorage.PM && history.location.pathname.includes('/admin'))
      history.push('/pm/vendorManagementDashboard');
  }, []);
  useEffect(
    () =>
      (async () => {
        const data = await axiosInstance.get('/vendor/getMiscData');
        console.log(data, 'pleasee');
        setLoading(false);
        setServiceWiseData(data.data.servicesData);
        vendorNumbers[2].numbers = data.data.totalVendors;
        setVendorNumbers([...vendorNumbers]);
      })(),
    []
  );

  console.log(vendorNumbers, 'vendornumberssssss');
  useEffect(
    () =>
      (async () => {
        // fetchProjects();
        let combinedObj = filters;
        if (history.location.search) {
          var search = history.location.search.substring(1);
          const finalObj = JSON.parse(
            '{"' +
              decodeURI(search)
                .replace(/"/g, '\\"')
                .replace(/&/g, '","')
                .replace(/=/g, '":"') +
              '"}'
          );
          combinedObj = { ...filters, ...finalObj };
          console.log('helloo');
        }
        setFilters({
          ...combinedObj,
        });
        await fetchData(combinedObj);
      })(),
    [history.location]
  );

  const serialize = (obj) =>
    Object.keys(obj)
      .map((key) => `${key}=${encodeURIComponent(obj[key])}`)
      .join('&');

  const fetchData = async (query) => {
    console.log(query, 'query');
    try {
      const data = await axiosInstance.post('/vendor/getStats', query);
      console.log(data);
      if (data.data.cityData.length > 0) {
        vendorBarGraph[0].chartData = data.data.cityData;
        setVendorBarGraph([...vendorBarGraph]);
      }
      if (data.data.stateData.length > 0) {
        console.log(data.data.stateData, 'dattaattatta');
        vendorBarGraph[1].chartData = data.data.stateData;
        setVendorBarGraph([...vendorBarGraph]);
      }
    } catch (e) {
      console.log(e);
    }
  };
  console.log(vendorBarGraph, 'soddubfiduvbdiuf');
  const handleSelect = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });

    fetchData({ [e.target.name]: e.target.value });

    history.push({
      pathname: '/admin/vendorManagementDashboard',
      search: serialize({
        ...filters,
        [e.target.name]: e.target.value,
      }),
    });
  };
  console.log(filters, 'filterrrss');
  return (
    <Box>
      {!loading ? (
        <PageContainer>
          {/* <DashboardHeader profile_data={profile.data} isBlack={true} /> */}
          <TimelineContainer>
            <CustomBreadcrumbs
              data={[
                {
                  name: 'Home',
                  link: '/',
                },
                {
                  name: 'EB VMS',
                  link: '/admin/vendorManagementDashboard',
                },
              ]}
            />

            <div className="title">Project Details</div>
            <Box className="container1" sx={{ marginBottom: '30px' }}>
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Typography className="projectName">
                  Vendor Management Service
                </Typography>
                <Box sx={{ display: 'flex', gap: '10px' }}>
                  <a href={`${isAdmin ? '/admin' : '/pm'}/vendorMasterList`}>
                    <CustomButton2>
                      <EyeIcon /> Vendor Master List
                    </CustomButton2>
                  </a>
                  <a href={`${isAdmin ? '/admin' : '/pm'}/addNewVendor`}>
                    <CustomButton2>
                      <RocketIcon /> Add New Vendor
                    </CustomButton2>
                  </a>
                </Box>
              </Box>
            </Box>
            <VendorStats>
              <VendorStatsLeft>
                <VendorLeftUp>
                  {vendorNumbers.map((vendor, i) => {
                    return (
                      <StatNumberBox>
                        <Box
                          sx={{
                            fontWeight: 'bold',
                            fontSize: '2rem',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {/* <Box>{vendor.numbers}</Box> */}
                          <CountUp
                            decimals={Number.isInteger(vendor.numbers) ? 0 : 2}
                            end={vendor.numbers}
                          />{' '}
                          {i == 0 ? '%' : ''}
                          <Box sx={{ marginLeft: '0.4rem' }}>
                            {vendor.titleIcon}
                          </Box>
                        </Box>
                        <Typography sx={{ fontSize: '1.2rem' }}>
                          {vendor.title}
                        </Typography>
                        <Typography sx={{ color: 'gray' }}>
                          {' '}
                          {vendor.stitleIcon && (
                            <Box
                              sx={{
                                marginLeft: '0.4rem',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              {vendor.stitleIcon}
                              <Typography>{vendor.subtitle}</Typography>
                            </Box>
                          )}
                        </Typography>
                      </StatNumberBox>
                    );
                  })}
                </VendorLeftUp>
                <VendorLeftDown>
                  {vendorBarGraph?.map((barGraph) => {
                    return (
                      <Box
                        sx={{
                          width: '50%',
                          margin: '1rem 1rem 0rem 0rem',
                          backgroundColor: 'white',
                          height: '50vh',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          flexDirection: 'column',
                          boxShadow: '2px 2px 10px #D3D3D3',
                          borderRadius: '5px',
                        }}
                      >
                        <Typography
                          sx={{
                            margin: '1rem',
                            fontWeight: 'bold',
                            fontSize: '1.5rem',
                            float: 'left',
                            // backgroundColor: 'red',
                            width: '80%',
                          }}
                        >
                          {barGraph.title}
                        </Typography>
                        <Box sx={{ width: '80%', marginBottom: '1rem' }}>
                          <CustomSelect
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={filters[barGraph.type] || ''}
                            label="Service Category"
                            name={barGraph.type}
                            onChange={(e) => handleSelect(e)}
                          >
                            {barGraph.options.map((dat) => (
                              <MenuItem value={dat}>{dat}</MenuItem>
                            ))}
                          </CustomSelect>
                        </Box>
                        <Box
                          sx={{
                            height: '100%',
                            width: '100%',
                          }}
                        >
                          <ResponsiveContainer width="90%" height="95%">
                            <BarChart
                              // width={230}
                              // height={250}
                              data={barGraph.chartData}
                              barSize={30}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis dataKey="service" />
                              <YAxis type="number" domain={[0, 100]} />
                              <Tooltip content={<CustomTooltip />} />
                              <Bar radius={[4, 4, 4, 4]} dataKey="data">
                                {barGraph.chartData.map((entry, index) => (
                                  <Cell
                                    key={`cell-${index}`}
                                    fill={barColors[index % 20]}
                                  />
                                ))}
                              </Bar>
                            </BarChart>
                          </ResponsiveContainer>
                        </Box>
                      </Box>
                    );
                  })}
                </VendorLeftDown>
              </VendorStatsLeft>
              <VendorStatsRight>
                <VendorRightUp>
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'white',
                      boxShadow: '2px 2px 10px #D3D3D3',
                      padding: '0.8rem',
                      borderRadius: '10px',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '1.5rem',
                        fontWeight: 'bold',
                        marginBottom: '-1.2rem',
                      }}
                    >
                      Service-wise Vendors
                    </Typography>

                    <Box
                      sx={{
                        width: '100%',
                        height: '90%',
                      }}
                    >
                      <ResponsiveContainer width="100%" height="100%">
                        <RadialBarChart
                          cx="50%"
                          cy="50%"
                          innerRadius="40%"
                          outerRadius="80%"
                          barSize={10}
                          data={serviceWiseData}
                          barGap={10}
                          dataKey="service"
                          // startAngle={90}
                          // endAngle={450}
                        >
                          <defs>
                            {barColors.map((col, i) => (
                              <linearGradient
                                id={`colorUv${i}`}
                                x1="0"
                                y1="0"
                                x2="1"
                                y2="1"
                              >
                                <stop
                                  offset="50%"
                                  stopColor={col}
                                  stopOpacity={0.7}
                                />
                                <stop
                                  offset="100%"
                                  stopColor="#FFFFFF"
                                  stopOpacity={0.3}
                                />
                              </linearGradient>
                            ))}
                          </defs>
                          <RadialBar
                            minAngle={90}
                            // label={{ position: 'insideStart', fill: '#fff' }}
                            background
                            dataKey="data"
                            cornerRadius={10}
                            startAngle={30}
                          >
                            {serviceWiseData.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={`url(#colorUv${index})`}
                              />
                            ))}
                          </RadialBar>
                          <Tooltip
                            // labelFormatter={() => ''}
                            // formatter={(val) => `${val} Vendors`}
                            content={<CustomTooltip />}
                          />

                          <Legend
                            iconSize={10}
                            layout="horizontal"
                            align="center"
                            verticalAlign="bottom"
                            // wrapperStyle={style}
                            payload={serviceWiseData.map((item, index) => ({
                              id: item.name,
                              type: 'circle',
                              value: `${item.name}`,
                              color: barColors[index % 20],
                            }))}
                          />
                        </RadialBarChart>
                      </ResponsiveContainer>
                    </Box>
                  </Box>
                </VendorRightUp>
                <VendorRightDown></VendorRightDown>
              </VendorStatsRight>
            </VendorStats>
          </TimelineContainer>
        </PageContainer>
      ) : (
        <Box
          sx={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img src={DashboardLogo1} style={{ width: '150px' }} alt="logo"></img>
          <CircularProgress style={{ marginLeft: '20px' }}></CircularProgress>
        </Box>
      )}
    </Box>
  );
};

export default VMS;
