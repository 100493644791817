export const printingData = {
  FDM: {
    colors: [
      { value: "#FFFFFF", name: "white" },
      { value: "#000000", name: "black" },
      { value: "#FF4B4B", name: "red" },
      { value: "#0702FC", name: "blue" },
      { value: "#FFF500", name: "yellow" },
      { value: "#00720B", name: "green" },
      { value: "transparent", name: "transparent" },
    ],
    material: ["PLA", "ABS", "PETG", "TPU", "Nylon"],
    printQuality: [
      {
        label: "Standard (0.4mm Print Accuracy)",
        val: "standard",
      },
      {
        val: "high",
        label: "High (0.1mm Print Accuracy)",
      },
      {
        label: "Low (1mm Print Accuracy)",
        val: "low",
      },
    ],
    infill: 50,
    surfaceFinish: ["standard", "custom"],
  },
  SLA: {
    material: ["Standard", "Clear", "Tough", "Flexible"],
    colors: [
      { value: "#FFFFFF", name: "white" },
      { value: "#000000", name: "black" },
      { value: "#FF4B4B", name: "red" },
      { value: "#0702FC", name: "blue" },
      { value: "#FFF500", name: "yellow" },
      { value: "#00720B", name: "green" },
      { value: "transparent", name: "transparent" },
    ],
    surfaceFinish: ["standard", "custom"],
    infill: 0,
    printQuality: [],
  },
  MJF: {
    material: ["Nylon PA11", "Nylon PA12", "Polypropylene PP"],
    colors: [
      { value: "#FFFFFF", name: "white" },
      { value: "#000000", name: "black" },
      { value: "#a9a9a9", name: "gray" },
    ],
    surfaceFinish: ["standard", "custom"],
    infill: 0,
    printQuality: [],
  },
};

export const injectionMoldingData = {
  mold: ["Steel", "Aluminium", "Mild Steel"],
  resinMaterial: [
    "ABS (+ PP/PC)",
    "PLA (PLA & Elastomer Mix)",
    "Acrylic (PMMA)",
    "HDPE",
    "LDPE",
    "Nylon 6",
    "Nylon 66",
    "PP",
    "PC",
  ],
  colors: [
    { value: "#FFFFFF", name: "white" },
    { value: "#000000", name: "black" },
    { value: "transparent", name: "transparent" },
  ],
};
