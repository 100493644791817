import React, { useMemo } from "react";
import { useDropzone } from "react-dropzone";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import EditIcon from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button";
import { DropzoneDialog } from 'material-ui-dropzone';
import CustomButton from "./CustomButton";
export default function FileDroper(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
 
 
  return (
    <div style={{ textAlign: "center",display:"inline" }}>
      <CustomButton
        onClick={handleClickOpen}
      >
        <span style={{ marginRight: "4px" }}>UPLOAD PICTURE </span> 
      </CustomButton>
      <DropzoneDialog
        acceptedFiles={['image/*']}
        cancelButtonText={"cancel"}
        submitButtonText={"submit"}
        maxFileSize={5000000}
        open={open}
        onClose={() => setOpen(false)}
        onSave={(files) => {
            props.uploadFile(files[0]);
            setOpen(false);
        }}
        showPreviews={true}
        showFileNamesInPreview={true}
       />
     
    </div>
  );
}
