import {
  styled,
  Box,
  Grid,
  TextField,
  Select,
  Slider,
  Button,
} from '@mui/material';

export const VendorStatsLeft = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '70%',
  flexDirection: 'column',
  height: '100%',
}));
export const VendorStatsRight = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '30%',
  flexDirection: 'column',
  height: '97%',
}));
export const VendorStats = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '80vh',
}));

export const VendorLeftUp = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
}));

export const VendorLeftDown = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
}));

export const VendorRightUp = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '60%',
}));
export const VendorRightDown = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
}));

export const StatNumberBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  flexDirection: 'column',
  backgroundColor: 'white',
  margin: '0rem 0.8rem 0.2rem 0rem',
  padding: '1rem',
  width: '33%',
  height: '25vh',
  overflow: 'hidden',
  boxShadow: '2px 2px 10px #D3D3D3',
  borderRadius: '10px',
}));
