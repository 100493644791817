import React, { useState } from "react";
import ElecbitsWhite from "../assests/elecbitsLogoWhite.svg";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import "../ProfileComponents/LoginComponent/Login.css";
import * as EmailValidator from "email-validator";

import { SnackbarProvider, useSnackbar } from "notistack";
import LinearProgress from "@material-ui/core/LinearProgress";
import { axiosInstance } from "../utils/axiosInterceptor";
import { Link } from "react-router-dom";
import loginLeftImage from "../assests/loginLeftImg.svg";
import loginRightImage from "../assests/loginRightImg.svg";
import { GoogleLogin } from "react-google-login";

const useStyles = makeStyles((theme) => ({
  margin: {
    marginTop: theme.spacing(2),
  },
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(1),
    },
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    backgroundColor: "black",
    "&:hover": {
      backgroundColor: "black",
    },
  },
}))(Button);
const LightColorButton = withStyles((theme) => ({
  root: {
    backgroundColor: "rgba(0, 0, 0, 0.034)",
    color: "black",
    fontWeight: "600",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.034)",
    },
  },
}))(Button);
export default function PMLogin(props) {
  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email_valid, set_valid] = useState(false);
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  let submit = (user_name, pass_word) => {
    setLoading(true);
    if (EmailValidator.validate(user_name)) {
      axiosInstance
        .post("/pm/login", {
          email: user_name,
          password: pass_word,
        })
        .then((res) => {
          enqueueSnackbar("Successfully signed in to elecbits!", {
            variant: "success",
          });
          setLoading(false);
          const newToken = res.data.token;
          axiosInstance.interceptors.request.use((config) => {
            const auth = `Bearer ${newToken}`;

            config.headers.Authorization = `Bearer ${newToken}`;
            return config;
          });

          let isPM = res.data.user.PM;
          localStorage.setItem("token", newToken);
          localStorage.setItem("PM", isPM);
          props.history.push("/");
          props.toggleLoggedIn();
        })
        .catch((err) => {
          if (err.response.data.message) {
            enqueueSnackbar(`${err.response.data.message}`, {
              variant: "error",
            });
          }
          let message;

          if (err.response && err?.response?.data) {
            message = err.response?.data?.err?.message;
          }

          enqueueSnackbar("Not able to signIn!", { variant: "error" });
          if (message) {
            enqueueSnackbar(`${message}`, { variant: "error" });
          }
          setLoading(false);
        });
    } else {
      enqueueSnackbar("Give a valid email", { variant: "error" });
      setLoading(false);
    }
  };


  return (
    <SnackbarProvider maxSnack={3}>
      <div className={classes.root}>{loading ? <LinearProgress /> : ""}</div>
      <div className="signUpWrapper">
        <div className="content_wrap">
          <div className="leftt_content_login_supplier1">
            <div className="signUpheaderLogin">
              <img src={ElecbitsWhite}></img>
            </div>
          </div>
          <div className="main_content_login">
            <div className="l_body">
              <div className="signUp_head_login">PM Login</div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  submit(username, password);
                }}
              >
                <TextField
                  error={email_valid}
                  helperText="Your Email Id"
                  required
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  className={classes.margin}
                  onChange={(e) => {
                    setUsername(e.target.value);
                    if (!EmailValidator.validate(e.target.value)) {
                      set_valid(true);
                    } else {
                      set_valid(false);
                    }
                  }}
                />
                <TextField
                  required
                  type="password"
                  id="outlined-basic"
                  label="Password"
                  variant="outlined"
                  helperText="Should include atleast 8 characters"
                  className={classes.margin}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
                <ColorButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.margin}
                >
                  Sign In
                </ColorButton>

             

              </form>
             
            </div>
          </div>
        </div>
      </div>
    </SnackbarProvider>
  );
}
