import React, { useState, useMemo, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { axiosInstance } from '../utils/axiosInterceptor';
import { Next } from 'react-bootstrap/esm/PageItem';
import { ListSubheader, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useCallback } from 'react';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: '100%',
  },
}));

export default function ProjectCreate(props) {
  let allUsers;
  if (props.allUsers) {
    allUsers = props.allUsers.data.data;
  }

  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const [project_name, setProjectName] = useState(null);
  const [project_description, setProjectDescription] = useState(null);
  const [client_id, setClientId] = useState(null);
  const [searchText, setSearchText] = useState('');
  const classes = useStyles();
  const handleSubmit = () => {
    console.log(client_id);
    if (project_name && client_id) {
      axiosInstance
        .post('/projects/createNewProject', {
          projectName: project_name,
          projectDescription: project_description,
          projectOwner: client_id,
        })
        .then((resp) => {
          setOpen(false);
          enqueueSnackbar('New project created successfully', {
            variant: 'success',
          });
          props.fetchProjectData();
          props.fetchUsers();
        })
        .catch((err) => {
          enqueueSnackbar('Not able to create the project', {
            variant: 'error',
          });
        });
    } else {
      if (!project_name) {
        enqueueSnackbar('Project name is required', { variant: 'error' });
      }
      if (!client_id) {
        enqueueSnackbar('Client is required', { variant: 'error' });
      }
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [displayedOptions, setDisplayedOptions] = useState([
    ...(allUsers || []),
  ]);
  const debounce = useCallback((func, wait) => {
    console.log('ehe');
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), wait);
    };
  }, []);
  const queryHandler = useCallback(
    debounce((e) => {
      const result = allUsers.filter((option) => {
        console.log(option);
        return (
          option.email
            .toString()
            .toLowerCase()
            .indexOf(e.target.value.toLowerCase()) > -1
        );
      });
      setDisplayedOptions(result);
    }, 500),
    []
  );
  return (
    <div>
      <Fab
        onClick={handleClickOpen}
        color="primary"
        aria-label="add"
        style={{ zIndex: 5 }}
      >
        <AddIcon fontSize="large"></AddIcon>
      </Fab>
      <Dialog
        fullWidth="true"
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Create Project</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Create a new project for the client user
          </DialogContentText>
          <TextField
            onChange={(e) => {
              setProjectName(e.target.value);
            }}
            autoFocus
            margin="dense"
            id="project_name"
            variant="outlined"
            label="Project Name"
            type="project_name"
            fullWidth
          />
          <TextField
            onChange={(e) => {
              setProjectDescription(e.target.value);
            }}
            margin="dense"
            variant="outlined"
            id="project_description"
            label="Project Description"
            type="project_description"
            multiline
            fullWidth
          />
          <FormControl
            className={classes.formControl}
            variant="filled"
            fullWidth
          >
            <InputLabel id="demo-simple-select-label" fullWidth>
              Select Client
            </InputLabel>
            <Select
              onChange={(e) => {
                setClientId(e.target.value);
              }}
              MenuProps={{ autoFocus: false }}
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              label="Client Id"
              onClose={() => setSearchText('')}
            >
              <ListSubheader>
                <TextField
                  size="small"
                  autoFocus
                  placeholder="Type to search..."
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => queryHandler(e)}
                  onKeyDown={(e) => {
                    if (e.key !== 'Escape') {
                      // Prevents autoselecting item while typing (default Select behaviour)
                      e.stopPropagation();
                    }
                  }}
                />
              </ListSubheader>
              {displayedOptions
                ? displayedOptions.map((user) => {
                    if (user.googleId) {
                      return (
                        <MenuItem value={user._id}>
                          {user.email} -- Google
                        </MenuItem>
                      );
                    } else {
                      return <MenuItem value={user._id}>{user.email}</MenuItem>;
                    }
                  })
                : ''}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
