import React, { useState } from 'react';
import ElecbitsWhite from '../../assests/elecbitsLogoWhite.svg';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import './Login.css';
import axios from 'axios';
import * as EmailValidator from 'email-validator';

import { SnackbarProvider, useSnackbar } from 'notistack';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Typography } from '@mui/material';
import { axiosInstance } from '../../utils/axiosInterceptor';
import { Link } from 'react-router-dom';
import loginLeftImage from '../../assests/loginLeftImg.svg';
import loginRightImage from '../../assests/loginRightImg.svg';
import { GoogleLogin } from 'react-google-login';

const useStyles = makeStyles((theme) => ({
  margin: {
    marginTop: theme.spacing(2),
  },
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: 'black',
    },
  },
}))(Button);
const LightColorButton = withStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0.034)',
    color: 'black',
    fontWeight: '600',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.034)',
    },
  },
}))(Button);
export default function SupplierLogin(props) {
  const classes = useStyles();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email_valid, set_valid] = useState(false);
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  let submit = (user_name, pass_word) => {
    setLoading(true);
    if (EmailValidator.validate(user_name)) {
      axiosInstance
        .post('/supplier/login', {
          email: user_name,
          password: pass_word,
        })
        .then((res) => {
          console.log(res);
          enqueueSnackbar('Successfully signed in to elecbits!', {
            variant: 'success',
          });
          setLoading(false);
          const newToken = res.data.token;
          axiosInstance.interceptors.request.use((config) => {
            const auth = `Bearer ${newToken}`;

            config.headers.Authorization = `Bearer ${newToken}`;
            return config;
          });

          let isAdmin = res.data.user.admin;
          localStorage.setItem('token', newToken);
          localStorage.setItem('admin', isAdmin);
          localStorage.setItem('supplier', true);
          props.toggleLoggedIn();
          console.log('here', res.data.user.organizationMail);
          if (res.data.user.phoneNumber === undefined) {
            props.history.push('/supplier/onBoarding');
          } else {
            props.history.push('/');
          }
        })
        .catch((err) => {
          if (err.response.data.message) {
            enqueueSnackbar(`${err.response.data.message}`, {
              variant: 'error',
            });
          }
          let message;

          if (err.response && err?.response?.data) {
            message = err.response?.data?.err?.message;
          }

          enqueueSnackbar('Not able to signIn!', { variant: 'error' });
          if (message) {
            enqueueSnackbar(`${message}`, { variant: 'error' });
          }
          setLoading(false);
        });
    } else {
      enqueueSnackbar('Give a valid email', { variant: 'error' });
      setLoading(false);
    }
  };

  let googleSubmit = (accessToken) => {
    setLoading(true);
    axiosInstance
      .post('/supplier/googleLogin', {
        tokenId: accessToken,
      })
      .then((res) => {
        console.log(res);
        enqueueSnackbar('Successfully signed in to elecbits!', {
          variant: 'success',
        });
        setLoading(false);
        const newToken = res.data.token;
        axiosInstance.interceptors.request.use((config) => {
          config.headers.Authorization = `Bearer ${newToken}`;
          return config;
        });

        let isAdmin = res.data.user?.admin;
        if (!isAdmin) {
          isAdmin = false;
        }
        localStorage.setItem('token', newToken);
        localStorage.setItem('admin', isAdmin);
        localStorage.setItem('supplier', true);
        if (res.data.user.phoneNumber) {
          props.history.push('/');
        } else {
          props.history.push('/supplier/onBoarding');
        }
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar('Not able to signIn!', { variant: 'error' });
        setLoading(false);
      });
  };
  return (
    <SnackbarProvider maxSnack={3}>
      <div className={classes.root}>{loading ? <LinearProgress /> : ''}</div>
      <div className="signUpWrapper">
        <div className="content_wrap">
          <div className="leftt_content_login_supplier1">
            <div className="signUpheaderLogin">
              <img src={ElecbitsWhite}></img>
              <p>
                Develop and Manufacture Electronics Just Like You Order a 🍕
                Online
              </p>
            </div>
          </div>
          <div className="main_content_login">
            <div className="l_body">
              <div className="signUp_head_login">Sign in to Elecbits</div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  submit(username, password);
                }}
              >
                <TextField
                  error={email_valid}
                  helperText="Your Email Id"
                  required
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  className={classes.margin}
                  onChange={(e) => {
                    setUsername(e.target.value);
                    if (!EmailValidator.validate(e.target.value)) {
                      set_valid(true);
                    } else {
                      set_valid(false);
                    }
                  }}
                />
                <TextField
                  required
                  type="password"
                  id="outlined-basic"
                  label="Password"
                  variant="outlined"
                  className={classes.margin}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
                <Typography sx={{ marginTop: '10px' }}>
                  <Link to="/forgotpassword">Forgot Password?</Link>
                </Typography>
                <ColorButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.margin}
                >
                  Sign In
                </ColorButton>
                <div className="or_opt">
                  <span>OR</span>
                </div>
                <GoogleLogin
                  clientId="574459066254-4pdosurnbhcdkblbc7rqhcd3eq8nk96t.apps.googleusercontent.com"
                  render={(renderProps) => (
                    <LightColorButton
                      variant="contained"
                      color="primary"
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                      className={classes.margin}
                    >
                      <img
                        style={{ width: '30px', marginRight: '10px' }}
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/320px-Google_%22G%22_Logo.svg.png?1629537112517"
                      ></img>{' '}
                      Sign in with GOOGLE
                    </LightColorButton>
                  )}
                  buttonText="Login"
                  onSuccess={(resp) => {
                    console.log(resp);
                    googleSubmit(resp.tokenId);
                  }}
                  onFailure={(resp) => {
                    console.log(resp);
                  }}
                  cookiePolicy={'single_host_origin'}
                />

                {/* <ColorButton
                  variant="contained"
                  color="primary"
                  className={classes.margin}
                >
                  Sign in with FACEBOOK
                </ColorButton> */}
              </form>
              <div className="sign_up">
                Not registered?Click{' '}
                <Link to="/supplier/signUp">
                  {' '}
                  <u style={{ color: 'black' }}>here</u>
                </Link>{' '}
                to sign up
              </div>
            </div>
          </div>
        </div>
      </div>
    </SnackbarProvider>
  );
}
