import React from "react";
import { styled, Box, Avatar, Typography, Button, Modal } from "@mui/material";
import { useState } from "react";
import DeleteModal from "./DeleteModal";
import { useSnackbar } from "notistack";
import moment from "moment";
import { axiosInstance } from "../utils/axiosInterceptor";

const ModalContainer = styled(Box)(({ theme }) => ({
  width: "30vw",
  height: "fit-content",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#fff",
  outline: "none",
  borderRadius: 10,
  padding: "20px 25px 20px 25px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  maxHeight: "80vh",
  overflowY: "auto",

  "& .attr": {
    display: "flex",
    flexDirection: "row",
    fontSize: "19px",
    gap: "10px",
    width: "100%",
  },

  [theme.breakpoints.down("md")]: {
    width: "50vw",
  },
  [theme.breakpoints.down("sm")]: {
    width: "80vw",
  },
}));

const UserOverviewContainer = styled(Box)(({ theme }) => ({
  width: "60vw",
  height: "fit-content",
  display: "flex",
  flexDirection: "row",
  padding: "20px",
  backgroundColor: "#F4F9FF",
  border: "1px solid #A9D1FF",
  marginBottom: 25,
  borderRadius: 25,
  gap: "30px",
  "& .attr": {
    display: "flex",
    flexDirection: "row",
    fontSize: "19px",
    gap: "10px",
  },
  "& .MuiButton-root": {
    width: "fit-content",
    height: "fit-content",
    borderRadius: 100,
  },
  "& .view-details": {
    backgroundColor: "#0275FC",
  },
  "& .delete-btn": {
    backgroundColor: "#FF972A",
  },
  "& .view-details:hover": {
    backgroundColor: "#0275FC",
  },
  "& .delete-btn:hover": {
    backgroundColor: "#FF972A",
  },
  [theme.breakpoints.down("md")]: {
    width: "80vw",
    borderRadius: 15,
  },
  [theme.breakpoints.down("sm")]: {
    width: "95vw",
    borderRadius: 10,
    flexDirection: "column",
  },
}));

const UserOverview = ({ user, users, setUsers }) => {
  const [showUserModal, setShowUserModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const toggleUserModal = () => {
    setShowUserModal(!showUserModal);
  };

  const handleDeleteUser = async () => {
    const res = await axiosInstance.delete(`/users/admin/${user._id}`);
    console.log(res);
    if (res.status == 200) {
      setUsers(users.filter((u) => u?._id !== user?._id));
      enqueueSnackbar("User deleted successfully", { variant: "success" });
    } else {
      enqueueSnackbar("Error while deleting user", { variant: "error" });
    }
    toggleDeleteModal();
  };

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const getName = (user) => {
    if (user?.fullName) {
      return user.fullName;
    }
    if (user.firstName) {
      return `${user.firstName} ${user.lastName}`;
    }
    return user.fullName;
  };

  return (
    <UserOverviewContainer>
      <Avatar sx={{ width: "90px", height: "90px" }} src={user?.profilePic} />
      <Box
        sx={{ display: "flex", flexDirection: "column", gap: "5px", flex: 1 }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            fontSize: "19px",
            gap: "10px",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Typography sx={{ color: "#898989" }}>Name 👨</Typography>
            {"  "}
            <Typography sx={{ fontWeight: 500 }}>{getName(user)}</Typography>
          </Box>
          <Box
            sx={{
              fontSize: "0.7em",
              letterSpacing: "2px",
              border: "2px solid #0abf47",
              padding: "2px 10px",
              borderRadius: "10px",
              color: "#0abf47",
            }}
          >
            {user.supplier ? "SUPPLIER" : "USER"}
          </Box>
        </Box>
        <Box className="attr">
          <Typography sx={{ color: "#898989" }}>Email 📩</Typography>
          {"  "}
          <Typography sx={{ fontWeight: 500 }}>{user?.email}</Typography>
        </Box>
        <Box className="attr">
          <Typography sx={{ color: "#898989" }}>Organization 🏢 </Typography>
          {"  "}
          <Typography sx={{ fontWeight: 500 }}>
            {user?.enterpriseName || "undefined"}
          </Typography>
        </Box>
        <Box className="attr">
          <Typography sx={{ color: "#898989" }}>Signup Time 💻 </Typography>
          {"  "}
          <Typography sx={{ fontWeight: 500 }}>
            {user.createdOn !== undefined
              ? moment(user?.createdOn).format("hh:mm a, Do MMMM YYYY")
              : "--"}
          </Typography>
          {/* <Typography sx={{ fontWeight: 500 }}>
            {user?.googleId ? "Google" : "Email"}
          </Typography> */}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            gap: "20px",
            marginTop: "20px",
          }}
        >
          <Button
            variant="contained"
            className="view-details"
            onClick={toggleUserModal}
          >
            View Details
          </Button>
          <Button
            variant="contained"
            className="delete-btn"
            onClick={toggleDeleteModal}
          >
            Delete User
          </Button>
        </Box>
      </Box>
      <DeleteModal
        state={showDeleteModal}
        toggleModal={toggleDeleteModal}
        onYes={handleDeleteUser}
        onNo={toggleDeleteModal}
      />
      <Modal open={showUserModal} onClose={toggleUserModal}>
        <ModalContainer>
          <Typography
            sx={{ fontSize: "2em", color: "#565656", fontWeight: 700 }}
          >
            User Details 👓
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "30px",
              marginTop: "25px",
              marginBottom: "30px",
            }}
          >
            <Box className="attr">
              <Typography
                sx={{
                  width: "50%",
                  color: "#898888",
                  minWidth: "50%",
                  fontWeight: 500,
                }}
              >
                Signing up type
              </Typography>
              <Box sx={{ display: "flex", gap: "20px" }}>
                <Typography
                  sx={{
                    color: user?.googleId ? "#000" : "#989898",
                    fontWeight: user?.googleId ? 600 : 400,
                  }}
                >
                  {" "}
                  Google
                </Typography>
                <Typography
                  sx={{
                    color: !user?.googleId ? "#000" : "#989898",
                    fontWeight: !user?.googleId ? 600 : 400,
                  }}
                >
                  {" "}
                  Email
                </Typography>
              </Box>
            </Box>
            <Box className="attr">
              <Typography
                sx={{
                  width: "50%",
                  color: "#898888",
                  minWidth: "50%",
                  fontWeight: 500,
                }}
              >
                Statup or enterprise
              </Typography>
              <Typography>{user?.enterpriseOrStartUpOrIndividual}</Typography>
            </Box>
            <Box className="attr">
              <Typography
                sx={{
                  width: "50%",
                  color: "#898888",
                  minWidth: "50%",
                  fontWeight: 500,
                }}
              >
                Industry
              </Typography>
              <Typography>{user?.industry}</Typography>
            </Box>{" "}
            <Box className="attr">
              <Typography
                sx={{
                  width: "50%",
                  color: "#898888",
                  minWidth: "50%",
                  fontWeight: 500,
                }}
              >
                Contact Number
              </Typography>
              <Typography>{user?.phoneNumber}</Typography>
            </Box>{" "}
            <Box className="attr">
              <Typography
                sx={{
                  width: "50%",
                  color: "#898888",
                  minWidth: "50%",
                  fontWeight: 500,
                }}
              >
                Service interested in
              </Typography>
              <Typography>
                {user?.services.map((a) => (
                  <div>{a}</div>
                ))}
              </Typography>
            </Box>
            <Box className="attr">
              <Typography
                sx={{
                  width: "50%",
                  color: "#898888",
                  minWidth: "50%",
                  fontWeight: 500,
                }}
              >
                Current Stage
              </Typography>
              <Typography>{user?.currentStage || ""}</Typography>
            </Box>
            <Box className="attr">
              <Typography
                sx={{
                  width: "50%",
                  color: "#898888",
                  minWidth: "50%",
                  fontWeight: 500,
                }}
              >
                Location
              </Typography>
              <Typography>{user?.location || "N/A"}</Typography>
            </Box>{" "}
            <Box className="attr">
              <Typography
                sx={{
                  width: "50%",
                  color: "#898888",
                  minWidth: "50%",
                  fontWeight: 500,
                }}
              >
                Current Stage
              </Typography>
              <Typography>{user?.designation || "N/A"}</Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  width: "50%",
                  color: "#898888",
                  minWidth: "50%",
                  fontWeight: 500,
                  marginBottom: "20px",
                }}
              >
                Shipping Address
              </Typography>
              <div>
                {user?.shippingAddress.map((address) => (
                  <Box
                    sx={{
                      border: "1px solid #A3A3A3",
                      marginBottom: "15px",
                      padding: "8px",
                      borderRadius: "10px",
                    }}
                  >
                    <div>
                      <h6>{address?.addressType}</h6>
                      <p>
                        {`${address?.houseNumber},${address?.street},near ${address?.landmark},${address.city},${address.state}-${address.pincode}`}
                      </p>
                      <h6>
                        {address?.fullName},{address.mobile}
                      </h6>
                    </div>
                  </Box>
                ))}
              </div>
            </Box>
          </Box>
        </ModalContainer>
      </Modal>
    </UserOverviewContainer>
  );
};

export default UserOverview;
