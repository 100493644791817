import React, { useState } from "react";
import { TextField, styled } from "@mui/material";
import CustomButton from "./CustomButton";

const TextfieldContainer = styled("div")(() => ({
  widht: "100%",
  "& .label": {
    color: "#898888",
    fontSize: 18,
    fontWeight: 500,
  },
  margin: "20px 0",
  "& .main_container": {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  "& .MuiInput-input.Mui-disabled": {
    color: "#000",
    textFillColor:'#000',
  },
}));

const CustomTextfield = ({ label, disableEdit, saveChange, ...props }) => {
  const [editMode, setEditMode] = useState(false);

  const toggleEditMode = () => {
    if (editMode) {
      saveChange();
    }
    setEditMode(!editMode);
  };

  return (
    <TextfieldContainer>
      <div className="label">{label}</div>
      <div className="main_container">
        <TextField
          {...props}
          disabled={!editMode}
          variant="standard"
          sx={{ width: "50%" }}
          InputProps={{ disableUnderline: !editMode }}
        />
        {!disableEdit ? (
          <CustomButton onClick={toggleEditMode}>
            {editMode ? "SAVE" : "EDIT"}
          </CustomButton>
        ) : null}
      </div>
    </TextfieldContainer>
  );
};

export default CustomTextfield;
