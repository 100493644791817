import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Button,
  Modal,
  styled,
  TextField,
  Typography,
  Fade,
  Slide,
  Grow,
} from '@mui/material';
import { ReactComponent as AlertSvg } from '../assests/alert.svg';
import * as EmailValidator from 'email-validator';
import { useSnackbar } from 'notistack';
import { axiosInstance } from '../utils/axiosInterceptor';

const ViewCollaboratorsContainer = styled(Box)(({ theme }) => ({
  width: '40vw',
  height: 'fit-content',
  maxHeight: '70vh',
  position: 'absolute',
  top: '20vh',
  left: '30vw',
  //   transform: "translate(-50%, -50%)",
  backgroundColor: '#fff',
  outline: 'none',
  borderRadius: 10,
  padding: '30px 40px 20px 40px',
  display: 'flex',
  flexDirection: 'column',
  //   justifyContent: "space-between",
  //   '& .alert-svg':{
  //     '& path':{
  //         fill:'#c73737'
  //     }
  //   },

  '& .header-text': {
    color: '#7E7E7E',
    fontWeight: 500,
    fontSize: '1.5em',
  },
  '& .add-btn': {
    borderRadius: 5,
    backgroundColor: '#0275FC',
    '&:hover': {
      backgroundColor: '#0275FC',
    },
  },
  '& .remove-btn': {
    fontSize: '0.7em',
    letterSpacing: '2px',
    color: '#0275FC',
    borderColor: '#0275FC',
    padding: '5px 30px',
  },
  //   "& .btn-container": {
  //     width: "100%",
  //     display: "flex",
  //     flexDirection: "row",
  //     justifyContent: "space-between",
  //     marginTop: "30px",
  //   },
  //   "& .delete-btn": {
  //     backgroundColor: "#000",
  //     color: "#fff",
  //     "&:hover": {
  //       backgroundColor: "#000",
  //     },
  //   },
  //   "& .cancel-btn": {
  //     backgroundColor: "#FF972A",
  //     color: "#fff",
  //     "&:hover": {
  //       backgroundColor: "#FF972A",
  //     },
  //   },
  '& legend': {
    width: 'unset',
  },
  [theme.breakpoints.down('md')]: {
    width: '50vw',
  },
  [theme.breakpoints.down('sm')]: {
    width: '80vw',
  },
}));

const ViewCollaborators = ({
  state,
  toggleModal,
  service,
  projectId,
  collaborators,
}) => {
  const [projectCollaborators, setProjectCollaborators] = useState([]);

  useEffect(() => {
    setProjectCollaborators(collaborators);
  }, []);

  return (
    <Modal open={state} onClose={toggleModal} closeAfterTransition>
      <Slide in={state} direction="up">
        <ViewCollaboratorsContainer>
          <div className="header-text">👤 View Project Collaborators</div>

          <Box
            sx={{
              flex: 1,
              overflowY: 'auto',
              borderTop: '1px solid #a3a3a3',
              marginTop: '20px',
            }}
          >
            {projectCollaborators.length > 0 ? (
              projectCollaborators
                .slice()
                .reverse()
                .map((user) => (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '5px',
                      margin: '15px 0px',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '20px',
                        alignItems: 'center',
                      }}
                    >
                      <Avatar src={user?.profilePic}>
                        {user.firstName && user.firstName[0]}
                      </Avatar>
                      <div>
                        <Typography sx={{ fontSize: '1.1em', fontWeight: 500 }}>
                          {`${user?.firstName || ''} ${user?.lastName || ''}`}
                        </Typography>
                        <Typography sx={{ color: '#A3A3A3' }}>
                          {user?.email}
                        </Typography>
                      </div>
                    </Box>
                  </Box>
                ))
            ) : (
              <Typography
                sx={{
                  color: '#A5A5A5',
                  fontWeight: 500,
                  width: '100%',
                  textAlign: 'center',
                  marginTop: '20px',
                }}
              >
                No Collaborators added yet 😐{' '}
              </Typography>
            )}
          </Box>
        </ViewCollaboratorsContainer>
      </Slide>
    </Modal>
  );
};

export default ViewCollaborators;
