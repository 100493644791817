import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import timeline from '../assests/timeline.png';

import quotation from '../assests/quotation.png';
import detail from '../assests/detail.png';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import DashboardHeader from '../ClientEngagement/AdminDashboardHeader';
import Box from '@mui/material/Box';
import { axiosInstance } from '../utils/axiosInterceptor';
import CircularProgress from '@material-ui/core/CircularProgress';
import DashboardLogo1 from '../assests/elecbitsLogo.svg';
import DeleteModal from '../Components/DeleteModal';
import { useSnackbar } from 'notistack';
import bin from '../assests/bin.png';
import CustomBreadcrumbs from '../Components/CustomBreadcrumbs';
import useErrorHandler from '../Hooks/useErrorHandler';
import { styled, Modal, TextField, Button } from '@mui/material';
import moment from 'moment';
import { LoadingButton } from '@mui/lab';
import { DropzoneArea } from 'material-ui-dropzone';
import requestQuotation from '../assests/requestQuotation.svg';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Switch } from '@material-ui/core';

const ModalContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  backgroundColor: '#fff',
  boxShadow: 24,
  padding: '1.5rem',
  borderRadius: '3px',
  [theme.breakpoints.down('md')]: {
    width: 500,
  },
  [theme.breakpoints.down('sm')]: {
    width: 350,
  },
}));

const columns = [
  { id: 'CompanyName', label: 'Company Name', minWidth: 170 },
  { id: 'ProjectName', label: 'Project Name', minWidth: 150 },
  { id: 'InternalName', label: 'Internal Name', minWidth: 150 },

  {
    id: 'OrderId',
    label: 'Order ID',
    minWidth: 150,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'CreatedBy',
    label: 'Created By',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'CurrentStage',
    label: 'Current Stage',
    minWidth: 140,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'CreatedOn',
    label: 'Created On',
    minWidth: 140,
    align: 'right',
  },
  { id: 'RequestForQuotation', label: 'RFQ', minWidth: 80, align: 'center' },
  { id: 'Timeline', label: 'Timeline', minWidth: 80, align: 'center' },
  { id: 'Quotation', label: 'Quotation', minWidth: 80, align: 'center' },
  { id: 'Delete', label: '', minWidth: 80, align: 'center' },
];

function createData(
  CompanyName,
  ProjectName,
  InternalName,
  OrderId,
  CreatedBy,
  CurrentStage,
  CreatedOn,
  RequestForQuotation,
  Timeline,
  Quotation,
  Delete
) {
  return {
    CompanyName,
    ProjectName,
    InternalName,
    OrderId,
    CreatedBy,
    CurrentStage,
    CreatedOn,
    RequestForQuotation,
    Timeline,
    Quotation,
    Delete,
  };
}

const BomExplorer = (props) => {
  const [projects, setProjects] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [deleteModalState, setDeleteModalState] = React.useState({
    visible: false,
    text: '',
    onYes: () => {},
  });
  const { enqueueSnackbar } = useSnackbar();

  const [profile_data, set_profile] = React.useState(null);
  const [loading, set_loading] = React.useState(null);
  const [createLoading, setCreateLoading] = useState(false);

  //create project states
  const [newProjectModalOpen, setNewProjectModalOpen] = useState(false);
  const openNewProjectModal = () => setNewProjectModalOpen(true);
  const closeNewProjectModal = () => setNewProjectModalOpen(false);
  const [files, setFiles] = useState([]);
  const [projectName, setProjectName] = useState('');
  const [prof_loading, setLoading] = useState(true);
  const [userEmail, setUserEmail] = useState('');
  const [numberOfBoards, setNumberOfBoards] = useState(0);
  const [isNumberError, NumberHelperText, NumberErrorHandler] =
    useErrorHandler();
  const [testToggle, setTestToggle] = useState(false);
  const [actualProjects, setActualProjects] = useState([]);
  const [testProjects, setTestProjects] = useState([]);

  let fetchProfile = () => {
    set_loading(true);
    axiosInstance
      .get('/users/getProfile')
      .then((resp) => {
        set_profile(resp.data);
        set_loading(false);
      })
      .catch((err) => {
        set_loading(false);
      });
  };
  React.useEffect(() => {
    fetchProfile();
  }, []);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const deleteProject = async (projectId, orderId) => {
    const res = await axiosInstance.delete(`/bom/admin/projects/${projectId}`);
    enqueueSnackbar('Successfully deleted project!', { variant: 'success' });
    console.log(res);
    setDeleteModalState({
      ...deleteModalState,
      visible: false,
    });
    console.log(projects, 'projjj');
    window.location.reload();
  };

  const handleDeleteProject = async (projectId, projectName, orderId) => {
    console.log(projects, 'here how works?');
    setDeleteModalState({
      visible: true,
      text: `Delete project ${projectName}`,
      onYes: () => {
        deleteProject(projectId, orderId);
      },
    });
  };

  const toggleDeleteModal = () => {
    setDeleteModalState({
      ...deleteModalState,
      visible: !deleteModalState.visible,
    });
  };

  const submitHandler = async () => {
    if (files.length > 0) {
      const val = {
        bom: files[0],
        projecName: projectName,
        numberOfBoards,
      };
      let formData = new FormData();
      formData.append('bom', files[0]);
      formData.append('projectName', projectName);
      formData.append('numberOfBoards', numberOfBoards);
      formData.append('userEmail', userEmail);
      console.log(val);
      const token = localStorage.getItem('token');
      const body = JSON.stringify(val);
      setCreateLoading(true);
      try {
        const res = await axiosInstance.post(
          '/bom/admin/createProject',
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-type': 'multipart/form-data',
            },
          }
        );
        setCreateLoading(false);
        console.log(res);
        enqueueSnackbar('Project Created Successfully!', {
          variant: 'success',
        });

        // closeNewProjectModal();
        window.location.reload();
      } catch (e) {
        console.log(e);
        enqueueSnackbar('some error occurred', { variant: 'error' });
      }
    } else {
      enqueueSnackbar('Please select atleast one file!', { variant: 'error' });
    }
  };
  const createDataFun = (project) => {
    return createData(
      project.companyName,
      project.projectName || 'Some Project',
      project.internalName || '--',
      project.projectId || '1234',
      project?.projectOwner?.email || 'test@1234.com',

      project.isClosed
        ? 'Project Closed'
        : project.currentStatus
        ? project.currentStatus[project.currentStatus.length - 1]?.status
        : 'Under Review',
      moment(project?.startDate).format('MMMM Do YYYY, h:mm a') || 'no date',
      <img
        src={requestQuotation}
        onClick={() =>
          window.open(`/admin/bom/suppliers/${project._id}`, '_blank')
        }
        style={{ cursor: 'pointer' }}
      />,
      <img
        src={timeline}
        onClick={() =>
          window.open(`/admin/bomExplorer/timeline/${project._id}`, '_blank')
        }
        style={{ cursor: 'pointer' }}
      />,
      <img
        src={quotation}
        onClick={() =>
          window.open(`/admin/bomExplorer/quotation/${project._id}`, '_blank')
        }
        style={{ cursor: 'pointer' }}
      />,
      <img
        src={bin}
        onClick={() => {
          console.log('delete clicked');
          handleDeleteProject(
            project._id,
            project.projectName,
            project.projectId
          );
        }}
        style={{ cursor: 'pointer' }}
      />
    );
  };
  React.useEffect(() => {
    const fetchProjects = async () => {
      const res = await axiosInstance.get('/bom/admin/allProjects');
      console.log(res);
      res.data?.projects?.forEach((project) => {
        const val = createDataFun(project);
        if (project.isTest) {
          testProjects.unshift(val);
        } else {
          actualProjects.unshift(val);
        }
        // rows.push(createData(project.companyName, project.projectName || "Some Project", project.projectId || "1234", "some data", project.currentStatus || "waiting"))
        // setProjects(projects);
      });
      setProjects([...actualProjects]);
      setActualProjects([...actualProjects]);
      setTestProjects([...testProjects]);

      console.log(actualProjects, 'actual hmm');
    };
    fetchProjects();
  }, []);
  console.log(projects, 'all projectsss');
  useEffect(() => {
    if (projects) {
      if (testToggle) {
        setProjects([...testProjects]);
      } else {
        setProjects([...actualProjects]);
      }
    }
  }, [testToggle]);
  return (
    <Box>
      {!loading && profile_data ? (
        <>
          <DashboardHeader
            profile_data={profile_data.data}
            toggleLoggedIn={props.toggleLoggedIn}
            history={props.history}
            isBlack={true}
          ></DashboardHeader>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                fontSize: '1.8rem',
                fontWeight: 'bold',
                margin: '1rem 2.8rem',
                width: '100%',
              }}
            >
              <CustomBreadcrumbs
                data={[
                  {
                    name: 'Home',
                    link: '/',
                  },
                  {
                    name: 'EB BOM Explorer',
                    link: '/admin/bomExplorer',
                  },
                ]}
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '90%',
                }}
              >
                Elecbits BOM Explorer
                <button
                  style={{
                    backgroundColor: '#493eff',
                    fontSize: '1rem',
                    color: '#fff',
                    border: 'none',
                    padding: '5px 15px',
                    borderRadius: '5px',
                  }}
                  onClick={openNewProjectModal}
                >
                  CREATE PROJECT 🚀
                </button>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '90%',
                }}
              >
                <Box sx={{ fontWeight: 'normal', fontSize: '1.2rem' }}>
                  All Projects
                </Box>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={testToggle}
                        onChange={() => setTestToggle(!testToggle)}
                        color="primary"
                      />
                    }
                    label="Test Projects"
                  />
                </FormGroup>
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <Paper
                sx={{
                  width: '90%',
                  overflow: 'hidden',
                }}
              >
                <TableContainer sx={{ maxHeight: 500 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {projects
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.code}
                            >
                              {columns.map((column) => {
                                const value = row[column.id];
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.format && typeof value === 'number'
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={projects.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Box>
            <DeleteModal
              onYes={deleteModalState.onYes}
              onNo={toggleDeleteModal}
              state={deleteModalState.visible}
              toggleModal={toggleDeleteModal}
              text={deleteModalState.text}
            />
          </Box>
          <Modal
            open={newProjectModalOpen}
            onClose={closeNewProjectModal}
            aria-labelledby="modal-modal-title"
          >
            <ModalContainer>
              <p
                style={{ textAlign: 'center' }}
                className="explorer-title"
                id="modal-modal-title"
              >
                Elecbits BOM Explorer - New Project
              </p>
              <Paper>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                    flexDirection: 'column',
                    padding: '1rem',
                  }}
                >
                  <TextField
                    id="outlined-basic"
                    label="Project Name"
                    variant="outlined"
                    sx={{
                      width: '90%',
                      margin: '0 auto',
                      marginBottom: '1rem',
                    }}
                    onChange={(e) => setProjectName(e.target.value)}
                  />
                  <TextField
                    id="outlined-basic"
                    label="User Email"
                    variant="outlined"
                    sx={{
                      width: '90%',
                      margin: '0 auto',
                      marginBottom: '1rem',
                    }}
                    onChange={(e) => setUserEmail(e.target.value)}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Number of Boards"
                    variant="outlined"
                    sx={{
                      width: '90%',
                      margin: '0 auto',
                      marginBottom: '1rem',
                    }}
                    helperText={NumberHelperText}
                    error={isNumberError}
                    onChange={(e) => {
                      setNumberOfBoards(e.target.value);
                      NumberErrorHandler(e);
                    }}
                  />
                  {/* <Box sx={{display: 'flex'}}>
                             <p>Please make sure that the BOM excel is in the correct format.</p>
                             <br />
                             <p>Download sample</p>
                             <button onClick={() => props.history.push("/bomExplorer/project")}>Submit</button>
                         </Box> */}
                  <DropzoneArea
                    maxFileSize={100000000}
                    init={() => {}}
                    onChange={(files) => {
                      setFiles(files);
                    }}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Box>
                      Please make sure that the BOM excel is in the correct
                      format.{' '}
                      <a href="/files/Eb-BOM-Sample-Sheet.xlsx" download>
                        Download Sample
                      </a>
                    </Box>
                    <LoadingButton
                      sx={{ margin: '1rem' }}
                      variant="contained"
                      loading={createLoading}
                      onClick={submitHandler}
                    >
                      SUBMIT
                    </LoadingButton>
                    {/* <Button
                      sx={{ margin: "1rem" }}
                      variant="contained"
                      onClick={submitHandler}
                    >
                      Submit
                    </Button> */}
                  </Box>
                </Box>
              </Paper>
            </ModalContainer>
          </Modal>
        </>
      ) : (
        <Box
          sx={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img src={DashboardLogo1} style={{ width: '150px' }} alt="logo"></img>
          <CircularProgress style={{ marginLeft: '20px' }}></CircularProgress>
        </Box>
      )}
    </Box>
  );
};
export default BomExplorer;
