import React, { useState, useEffect } from 'react';

//components
import CustomBreadcrumbs from '../../Components/CustomBreadcrumbs';
import DashboardHeader from '../../ClientEngagement/DashboardHeader';
import FabDetailsModal from '../ProjectTimeline/FabDetailsModal';
import ColosseumOrderSummary from './PcbOrderSummary';
import ColosseumQuotationsTable from './PcbQuotationsTable';
import AddQuotationModal from './AddQuotationModal';
import ClosedProject from '../../Components/ClosedProject';

//utils
import { axiosInstance } from '../../utils/axiosInterceptor';
import { Box, createTheme, Grid, Typography } from '@mui/material';
import { useParams, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import moment from 'moment';

//assets
import { ReactComponent as MagicIcon } from '../../assests/magic.svg';
import { ReactComponent as ManualIcon } from '../../assests/manual.svg';

//styles
import {
  PageContainer,
  QuotationsContainer,
  CustomButton,
} from './PcbQuotations.styles';

const ColosseumQuotations = ({ isAdmin }) => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState(null);
  const [isClose, setIsClose] = useState(false);
  const [showProjectDetail, setShowProjectDetail] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [selectionMode, setSelectionMode] = useState(false);
  const [selectedQuotation, setSelectedQuotation] = useState(null);
  const [currentMode, setCurrentMode] = useState('manual');
  const [orderState, setOrderState] = useState({
    confirm: false,
    order: null,
  });
    const isPM = localStorage.PM;
  const history = useHistory();

  const toggleProjectDetail = () => {
    setShowProjectDetail(!showProjectDetail);
  };

  const toggleAddModal = () => {
    setShowAddModal(!showAddModal);
  };

  const toggleSelectionMode = () => {
    setSelectionMode(!selectionMode);
    setSelectedQuotation(null);
  };

  useEffect(() => {
    if (currentMode === 'manual') {
      setSelectedQuotation(null);
    }
  }, [currentMode]);

  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const fetchProfile = () => {
    setLoading(true);
    axiosInstance
      .get('/users/getProfile')
      .then((resp) => {
        setProfile(resp.data);
        console.log(resp);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const fetchProject = async () => {
    const res = await axiosInstance.get(`/fab/client/singleProject/${id}`);
    console.log(res);
    setProject(res.data.project);
    setIsClose(res.data?.project?.isClosed);
    if (res.data.project.orderSummary) {
      const order = res.data.project.orderSummary;
      setOrderState({
        confirm: true,
        order: order,
      });

      setSelectedQuotation({
        quantity: order.quantity,
        pricePerQty: order.totalCost,
        shippingCost: order.shippingCost,
        totalCost: order.grandTotal,
      });
      setCurrentMode(order.isMagic ? 'magic' : 'manual');
      console.log(order, 'here is the order!!');
      console.log('bruhhhh');
    }
  };

  const handleOrderConfirm = async () => {
    const data = {
      cost: selectedQuotation.unitCost,
      quantity: selectedQuotation.quantity,
      totalCost: selectedQuotation.pricePerQty,
      shippingCost: selectedQuotation.shippingCost,
      grandTotal: selectedQuotation.totalCost,
      isMagic: currentMode === 'magic',
    };

    try {
      const res = await axiosInstance.post(
        `/fab/client/addOrderDetail/${id}`,
        data
      );
      console.log(res);
      enqueueSnackbar('Order confirmed successfully!', { variant: 'success' });
      if (isAdmin) {
        history.push(`/admin/pcbFab/timeline/${id}`);
      } else {
        history.push(`/pcbFabTimeline/${id}`);
      }
    } catch (err) {
      console.log(err);
      enqueueSnackbar('Something went wrong!', { variant: 'error' });
    }

    console.log(selectedQuotation);
    console.log(data);
  };

  useEffect(() => {
    fetchProject();
  }, [id]);

  useEffect(() => {
    fetchProfile();
    fetchProfile();
  }, []);
  return (
    <Box sx={isClose ? { pointerEvents: 'none' } : {}}>
      {isClose && <ClosedProject />}
      {!loading && project ? (
        <PageContainer>
          <DashboardHeader profile_data={profile.data} isBlack={true} />
          <QuotationsContainer>
            {isAdmin ? (
              <CustomBreadcrumbs
                data={[
                  {
                    name: 'Home',
                    link: `/${isPM? "pm" : "admin"}`,
                  },
                  {
                    name: 'EB PCB Fabricator',
                    link: `/${isPM? "pm" : "admin"}/pcbFab`,
                  },
                  {
                    name: 'Quotations',
                    link: `/${isPM? "pm" : "admin"}/pcbFab/quotations/${id}`,
                  },
                ]}
              />
            ) : (
              <CustomBreadcrumbs
                data={[
                  {
                    name: 'Home',
                    link: '/',
                  },
                  // {
                  //   name: 'EB PCB Fabricator',
                  //   link: '/pcbFabricator',
                  // },
                  {
                    name: 'My Projects',
                    link: '/pcbFab/projects',
                  },
                  {
                    name: 'View Details',
                    link: `/pcbFabTimeline/${id}`,
                  },
                  {
                    name: 'Quotations',
                    link: `/pcbFab/quotations/${id}`,
                  },
                ]}
              />
            )}
            <div className="title">Quotations</div>
            <Box className="container1">
              <Box>
                <Typography className="projectName">
                  {project.projectName} - {project.projectOwner.enterpriseName}
                </Typography>
                <Typography className="projectTime">
                  {' '}
                  Project Created on:{' '}
                  {moment(project?.createdOn).format('MMMM Do YYYY, h:mm a')}
                </Typography>
              </Box>
              <Box
                sx={{
                  borderBottom: '1px solid #0702FC',
                  color: '#0702FC',
                  fontWeight: 500,
                  cursor: 'pointer',
                }}
                onClick={toggleProjectDetail}
              >
                View Project Details
              </Box>
              <FabDetailsModal
                state={showProjectDetail}
                toggleModal={toggleProjectDetail}
                project={project}
              />
            </Box>
            <Box className="button-container">
              {isAdmin ? (
                <div>
                  <CustomButton
                    fill
                    onClick={toggleSelectionMode}
                    disabled={orderState.confirm}
                  >
                    Selection Mode: {selectionMode ? 'ON' : 'OFF'}
                  </CustomButton>
                  <CustomButton
                    onClick={toggleAddModal}
                    disabled={orderState.confirm}
                  >
                    Add Quotation
                  </CustomButton>
                </div>
              ) : (
                <div>
                  <CustomButton
                    fill={currentMode === 'magic'}
                    onClick={() => {
                      setCurrentMode('magic');
                    }}
                    disabled={orderState.confirm}
                  >
                    <MagicIcon className="qbtn" />
                    Magic Recommendation
                  </CustomButton>
                  <CustomButton
                    onClick={() => {
                      setCurrentMode('manual');
                    }}
                    fill={currentMode === 'manual'}
                    disabled={orderState.confirm}
                  >
                    <ManualIcon className="qbtn" />
                    Manual Selection
                  </CustomButton>
                </div>
              )}
              {isAdmin ? (
                <AddQuotationModal
                  state={showAddModal}
                  toggleModal={toggleAddModal}
                  id={id}
                />
              ) : null}

              <div>
                <a href={project.gerberFile.location}>
                  <CustomButton>Download File</CustomButton>
                </a>
              </div>
            </Box>
            <ColosseumQuotationsTable
              project={project}
              selectionMode={selectionMode}
              selectQuotation={setSelectedQuotation}
              isAdmin={isAdmin}
              mode={currentMode}
              disabled={orderState.confirm}
              selectedQuotation={selectedQuotation}
            />
          </QuotationsContainer>
          {selectedQuotation !== null ? (
            <ColosseumOrderSummary
              selectedQuotation={selectedQuotation}
              confirmOrder={handleOrderConfirm}
              orderState={orderState}
            />
          ) : null}
        </PageContainer>
      ) : null}
    </Box>
  );
};

export default ColosseumQuotations;
