import React, { useState, useEffect } from 'react';

//components
import CustomBreadcrumbs from '../../Components/CustomBreadcrumbs';
import DashboardHeader from '../../ClientEngagement/DashboardHeader';
import ServiceCard from './ServiceCard.component';

//utils
import { axiosInstance } from '../../utils/axiosInterceptor';
import { Box } from '@mui/material';
import { useHistory } from 'react-router-dom';

//styles
import {
  PageContainer,
  ColosseumServicesContainer,
} from './ColossuemServices.styles';

const ColosseumServices = ({ isAdmin }) => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const fetchProfile = () => {
    setLoading(true);
    axiosInstance
      .get('/users/getProfile')
      .then((resp) => {
        setProfile(resp.data);
        console.log(resp);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchProfile();
  }, []);
  return (
    <Box>
      {!loading ? (
        <PageContainer>
          <DashboardHeader profile_data={profile.data} isBlack={true} />
          <ColosseumServicesContainer>
            <CustomBreadcrumbs
              data={[
                {
                  name: 'Home',
                  link: '/',
                },
                {
                  name: 'EB 3D Colosseum',
                  link: '/pm/3d',
                },
                {
                  name: 'Create new project',
                  link: history.location.pathname,
                },
              ]}
            />
            <div className="title">Choose a service</div>
            <div className="sub-title">
              Fully managed and scalable 3D Assembly Service
            </div>
            <div className="card-container">
              <ServiceCard
                title="3D Printing"
                imageName="3d-printing.png"
                link={
                  isAdmin
                    ? '/admin/3d/newProject/3dPrinting'
                    : '/3dcolosseum/newProject/3dPrinting'
                }
              />
              <ServiceCard
                title="Vacuum Casting"
                imageName="vaccum-casting.png"
                link="/"
              />{' '}
              <ServiceCard
                title="Injection Molding"
                imageName="injection-molding.png"
                link={
                  isAdmin
                    ? '/admin/3d/newProject/injectionMolding'
                    : '/3dcolosseum/newProject/injectionMolding'
                }
              />{' '}
              <ServiceCard
                title="Pre-Built Cases"
                imageName="prebuilt-cases.svg"
                link={
                  isAdmin
                    ? '/admin/3d/newProject/prebuiltCases'
                    : '/3dcolosseum/newProject/prebuiltCases'
                }
              />
            </div>
          </ColosseumServicesContainer>
        </PageContainer>
      ) : null}
    </Box>
  );
};

export default ColosseumServices;
