import React, { useState, useEffect } from 'react';

//utils
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
} from '@mui/material';
import { Switch } from '@material-ui/core';
import { axiosInstance } from '../../utils/axiosInterceptor';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import ClosedProject from '../../Components/ClosedProject';

//components
import DashboardHeader from "../../ClientEngagement/DashboardHeader";
import CustomBreadcrumbs from "../../Components/CustomBreadcrumbs";
import ConfirmModal from "../../Components/ComfirmModal";
import AdminBomQuotationModal from "./AdminBomQuotationModal.jsx";

//styles
import {
  SupplierQuotationsContainer,
  PageContainer,
  CustomSwitch,
} from './SupplierQuotations.styles';

import quotationImg from '../../assests/quotation.png';

const BomSupplierQuotations = () => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState(null);
  const [isClose, setIsClose] = useState(false);

  const [isLive, setIsLive] = useState(false);
  const [supplierQuotations, setSupplierQuotations] = useState([]);
  const { id } = useParams();
  const history = useHistory();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showBomModal, setShowBomModal] = useState(false);

  const toggleConfirmModal = () => {
    setIsLive(!isLive);
    setShowConfirmModal(!showConfirmModal);
  };

  const toggleBomModal = (isSubmit) => {
    if (isSubmit && isSubmit == "submit") {
      setShowBomModal(!showBomModal);
      return;
    }
    setIsLive(!isLive);
    setShowBomModal(!showBomModal);
  };

  const { enqueueSnackbar } = useSnackbar();

  const fetchProfile = () => {
    setLoading(true);
    axiosInstance
      .get('/users/getProfile')
      .then((resp) => {
        setProfile(resp.data);
        console.log(resp);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const fetchProject = async () => {
    const res = await axiosInstance.get(`/bom/client/singleProject/${id}`);
    const res2 = await axiosInstance.get(`/bom/admin/quotations/${id}`);
    console.log(res2);

    setProject(res.data.project);
    setSupplierQuotations(res2.data.quotations);
    setIsLive(res.data?.project?.liveStatus?.isLive);
    setIsClose(res.data?.project?.isClosed);
  };

  const handleChange = async (e) => {
    setIsLive(e.target.checked);
    // toggleConfirmModal();
    if (e.target.checked) {
      toggleBomModal();
    } else {
      toggleConfirmModal();
    }
    // const res = await axiosInstance.post(`/bom/admin/toggleStatus/${id}`, {
    //   state: e.target.checked,
    // });
    // console.log(res);
  };
  const handleStageToggle = async () => {
    try {
      const res = await axiosInstance.post(`/bom/admin/toggleStatus/${id}`, {
        state: false,
      });
      let text;
      if (isLive) {
        text = 'Project is now live!';
      } else {
        text = 'Project is now offline';
      }
      enqueueSnackbar(text, { variant: 'success' });
    } catch (err) {
      enqueueSnackbar('Something went wrong!', { variant: 'error' });
      console.log(err);
    }
    setShowConfirmModal(false);
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  useEffect(() => {
    fetchProject();
  }, [id]);

  return (
    <Box sx={isClose ? { pointerEvents: 'none' } : {}}>
      {isClose && <ClosedProject />}
      {!loading && project ? (
        <PageContainer>
          <DashboardHeader profile_data={profile.data} isBlack={true} />
          <SupplierQuotationsContainer>
            <CustomBreadcrumbs
              data={[
                {
                  name: 'Home',
                  link: '/',
                },
                {
                  name: 'EB BOM Explorer',
                  link: '/admin/bomExplorer',
                },
                {
                  name: 'Request for quotation',
                  link: `/admin/bom/suppliers/${id}`,
                },
              ]}
            />
            <div className="head-title">Elecbits BOM Explorer</div>
            <div className="title">
              Supplier Quotations for {project.internalName} -{' '}
              {project.projectName}
            </div>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '50vw' }}>
              <Typography sx={{ color: '#6F6F6F' }}>
                Activate Request for Quotation 🚀
              </Typography>
              <CustomSwitch checked={isLive} onChange={handleChange} />
            </Box>
            <AdminBomQuotationModal
              state={showBomModal}
              toggleModal={toggleBomModal}
              id={id}
            />
            <ConfirmModal
              state={showConfirmModal}
              toggleModal={toggleConfirmModal}
              onYes={handleStageToggle}
              onNo={toggleConfirmModal}
            />
            <TableContainer component={Paper} sx={{ marginTop: '30px' }}>
              <Table sx={{ minWidth: 50 }} aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ backgroundColor: '#8B8B8B33' }}>
                    <TableCell
                      sx={{ fontSize: '0.9rem', fontWeight: 500 }}
                      align="left"
                    >
                      Supplier Name
                    </TableCell>
                    <TableCell
                      sx={{ fontSize: '0.9rem', fontWeight: 500 }}
                      align="left"
                    >
                      Location
                    </TableCell>

                    <TableCell
                      sx={{ fontSize: '0.9rem', fontWeight: 500 }}
                      align="left"
                    >
                      Quote Status
                    </TableCell>
                    <TableCell
                      sx={{ fontSize: '0.9rem', fontWeight: 500 }}
                      align="left"
                    >
                      Quote Updated On
                    </TableCell>
                    <TableCell
                      sx={{ fontSize: '0.9rem', fontWeight: 500 }}
                      align="left"
                    >
                      View Quote
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {supplierQuotations.length > 0 ? (
                    <>
                      {supplierQuotations.map((quote) => (
                        <TableRow
                          key={project.projectId}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            cursor: "pointer",
                            backgroundColor: quote.isAccepted
                              ? "#21cb51a1"
                              : "#fff",
                            "&:hover": {
                              backgroundColor: quote.isAccepted
                                ? "#21cb51a1"
                                : "#d9d9d9b2",
                            },
                          }}
                          onClick={() =>
                            history.push(
                              `/admin/bom/suppliers/quotation/${quote._id}`
                            )
                          }
                        >
                          <TableCell>{quote.supplier.fullName}</TableCell>
                          <TableCell>
                            {quote.supplier.supplierDetails.address}
                          </TableCell>

                          <TableCell>
                            {
                              quote?.currentStatus[
                                quote?.currentStatus.length - 1
                              ].status
                            }
                          </TableCell>
                          <TableCell>
                            {' '}
                            {moment(quote.createdOn).format(
                              'MMMM Do YYYY, h:mm a'
                            ) || 'no date'}
                          </TableCell>
                          <TableCell>
                            <img src={quotationImg} alt="" />
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  ) : (
                    <TableCell
                      colSpan={7}
                      sx={{
                        color: '#737373',
                        margin: '10px',
                        width: '100%',
                        textAlign: 'center',
                      }}
                    >
                      Currently there are no quotations for this project
                    </TableCell>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </SupplierQuotationsContainer>
        </PageContainer>
      ) : null}
    </Box>
  );
};

export default BomSupplierQuotations;
