import { styled, Box, Button } from "@mui/material";
import { Switch } from "@material-ui/core";

export const PageContainer = styled(Box)(() => ({
  width: "100vw",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
}));

export const AllConsultationsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  flex: 1,
  padding: "50px 50px",
  fontFamily: "Poppins",
  backgroundColor: "#F4F7FE",
  overflowY: "auto",
  "& .head-title": {
    fontWeight: 700,
    color: "#898888",
    marginTop: "30px",
  },

  "& .title": {
    marginTop: "10px",
    fontWeight: 600,
    marginBottom: "20px",
  },
  "& .sub-title": {
    marginTop: "10px",
    color: "#8B8B8B",
    marginBottom: "50px",
  },
  ".card-container": {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    gap: "30px",
  },
  [theme.breakpoints.down("md")]: {
    ".card-container": {
      flexDirection: "column",
    },
  },
}));