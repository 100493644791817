import React, { useState, useEffect, useRef } from "react";

//components
import CustomBreadcrumbs from '../../Components/CustomBreadcrumbs';
import DashboardHeader from '../../ClientEngagement/DashboardHeader';

import QuotationStatus from "../../assests/quotationStatus.png";

//utils
import { axiosInstance } from '../../utils/axiosInterceptor';
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  Select,
} from "@mui/material";
import { MenuItem } from "@material-ui/core";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineComponent from "../ProjectTimeline/TimelineComponent";
import { useWindowSize } from "../../Hooks/useWindowSize";
import { useDropzone } from "react-dropzone";
import SupplierTimelineComponent from "./SupplierTimelineComponent";
//styles
import {
  PageContainer,
  SupplierBomProjectsContainer,
  StyledInput,
  CustomButton,
  RootContainer,
  BrowseButton,
} from './SupplierBom.styles';

import { supplierStages } from './Supplier.data';
// import { TimelineContainer } from "../ProjectTimeline/FabTimeline.styles";

//assets
import { ReactComponent as UploadIcon } from "../../assests/Upload.svg";
import { ReactComponent as DownloadIcon } from "../../assests/download.svg";
import { ReactComponent as BoardsIcon } from "../../assests/boards.svg";
import { ReactComponent as DescriptionIcon } from "../../assests/description.svg";
import { ReactComponent as TimeIcon } from "../../assests/time.svg";
import { ReactComponent as TimerIcon } from "../../assests/timer.svg";

import bin from "../../assests/bin.png";
import CustomFileDropper from "../../Components/CustomFileDropper";

const SupplierBomQuotation = (props) => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState(null);
  const inputRef = useRef(null);
  // const [quotation, setQuotation] = useState({
  //   minTime: null,
  //   maxTime: null,
  //   shippingCost: null,
  // });
  const [quotation, setQuotation] = useState({
    fashShippingMinTime: "",
    fastShippingMaxTime: "",
    fastShippingCost: "",
    normalShippingMinTime: "",
    normalShippingMaxTime: "",
    normalShippingCost: "",
    advance: "",
  });

  const [quotationBomFile, setQuotationBomFile] = useState(null);
  const { id } = useParams();
  const [prevStageNumber, setPrevStageNumber] = useState(0);
  const [isQuoted, setIsQuoted] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { width } = useWindowSize();
  const [currentStage, setCurrentStage] = useState(null);

  // const { getRootProps, getInputProps, acceptedFiles, isDragActive } =
  //   useDropzone();

  const handleBrowseClick = () => {
    inputRef.current.click();
  };

  const handleFileChange = (e) => {
    const fileObj = e.target.files && e.target.files[0];

    e.target.value = null;
    console.log(fileObj, "here is the admin file");
    setQuotationBomFile(fileObj);
  };

  const fetchProfile = () => {
    setLoading(true);
    axiosInstance
      .get('/users/getProfile')
      .then((resp) => {
        setProfile(resp.data);
        console.log(resp);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getQuoteStatus = (project) => {
    if (project?.isRejected) {
      return "Unfortunately, your quote has not been selected. But we're hoping to collaborate with you on the next project.";
    }
    if (project?.bomQuotation?.isAccepted) {
      return "Congratulations! Your quotation for the following components has been selected.";
    }
    if (project?.isQuoted) {
      return "Well done! We have received your quotation. We will review it at the earliest.";
    }
    return "Please upload your quotation in “My Quote” section as soon as possible to proceed further.";
  };

  const handleName = (name, len) => {
    if (len === null) {
      len = 20;
    }
    if (name === null) {
      return name;
    }

    if (name?.length > len) {
      return name.substring(0, len) + "...";
    } else {
      return name;
    }
  };

  const fetchProject = async () => {
    try {
      const res = await axiosInstance.get(`/supplier/bomProject/${id}`);
      console.log(res);
      setProject(res.data.project);
      setCurrentStage(
        res.data.project.bomQuotation?.currentStatus[
          res.data.project.bomQuotation.currentStatus.length - 1
        ].status || 'Request For Quote'
      );
      setPrevStageNumber(res.data.project?.bomQuotation?.currentStatus?.length);
    } catch (err) {
      console.log(err);
    }
  };

  const getQuoteBackgroundColor = (project) => {
    if (project?.isRejected) {
      return "#ff0000";
    }
    if (project?.bomQuotation?.isAccepted) {
      return "#0ba52c";
    }
    return "#8b8b8b";
  };

  useEffect(() => {
    if (project && project.isQuoted) {
      setIsQuoted(true);
      console.log(project, "hahahahhaahahha");
      setQuotationBomFile(project.bomQuotation.quotation.bomFile);
      setQuotation({
        fastShippingCost: project.bomQuotation.quotation.shippingCost.fast.cost,
        fashShippingMinTime:
          project.bomQuotation.quotation.shippingCost.fast.minTime,
        fastShippingMaxTime:
          project.bomQuotation.quotation.shippingCost.fast.maxTime,
        normalShippingCost:
          project.bomQuotation.quotation.shippingCost.normal.cost,
        normalShippingMaxTime:
          project.bomQuotation.quotation.shippingCost.normal.maxTime,
        normalShippingMinTime:
          project.bomQuotation.quotation.shippingCost.normal.minTime,
        advance: project.bomQuotation.quotation.paymentTerms.advance,
      });
    }
  }, [project]);

  const handleChange = (e) => {
    setQuotation({ ...quotation, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(quotation);
    console.log(quotationBomFile);
    if (quotationBomFile == null) {
      enqueueSnackbar('Please add a quotation file!', { variant: 'error' });
      return;
    }

    let formData = new FormData();
    formData.append("bom", quotationBomFile);
    Object.keys(quotation).map((a) => {
      formData.append(a, quotation[a]);
    });

    try {
      const token = localStorage.getItem("token");
      let url = "";
      if (project.isQuoted) {
        url = `/supplier/updateBomQuotation/${project.bomQuotation._id}`;
        // formData.append("bomQuote", true);
      } else {
        url = `/supplier/bomQuotation/${id}`;
      }

      const res = await axiosInstance.post(url, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "multipart/form-data",
        },
      });
      console.log(res);
      enqueueSnackbar('Quotation submitted successfully!', {
        variant: 'success',
      });
      if (!project.isQuoted) {
        window.location.reload();
      }
    } catch (err) {
      console.log(err);
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const handleStageChange = async () => {
    const data = supplierStages[prevStageNumber].value;
    try {
      const res = await axiosInstance.post(
        `/supplier/bom/quotation/addStage/${project.bomQuotation._id}`,
        { status: data }
      );
      enqueueSnackbar('Stage updated successfully!', { variant: 'success' });
      window.location.reload();
    } catch (err) {
      console.log(err);
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  useEffect(() => {
    fetchProfile();
    fetchProject();
  }, []);

  return (
    <Box>
      {!loading && project ? (
        <PageContainer>
          <DashboardHeader profile_data={profile.data} isBlack={true} />
          <SupplierBomProjectsContainer>
            <CustomBreadcrumbs
              data={[
                {
                  name: 'Home',
                  link: '/',
                },
                {
                  name: 'EB BOM Explorer',
                  link: '/supplier/bom/projects',
                },
                {
                  name: `Project ${id}`,
                  link: `/supplier/bom/projects/${id}`,
                },
              ]}
            />
            <input
              style={{ display: "none" }}
              ref={inputRef}
              type="file"
              onChange={handleFileChange}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div className="title">Project ID: {id}</div>
            </Box>
            <Box>
              <Typography sx={{ margin: '30px 0px 20px 0px' }}>
                Project Details 📃
              </Typography>
              <Box
                sx={{
                  border: '1px solid #0275FC',
                  borderRadius: '12px',
                  padding: '20px',
                  margin: '10px 0px',
                }}
              >
                <Grid container sx={{ marginBottom: '20px' }}>
                  <Grid item md={6}>
                    <Box
                      sx={{
                        display: 'flex',
                        margin: '10px 10px 10px 0px',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#8C8C8C",
                          marginRight: "20px",
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        <BoardsIcon width="15px" height="15px" />
                        Number Of Boards:
                      </Typography>
                      <Typography>
                        {project.projectDetails?.numberOfBoards}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item md={6}>
                    <Box
                      sx={{
                        display: 'flex',
                        margin: '10px 10px 10px 0px',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#8C8C8C",
                          marginRight: "20px",
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        <TimeIcon width="15px" height="15px" />
                        Project Live Since:
                      </Typography>
                      <Typography>
                        {' '}
                        {moment(project?.updationDate).format(
                          'MMMM Do YYYY, h:mm a'
                        ) || 'no date'}
                      </Typography>
                    </Box>
                  </Grid>{' '}
                  <Grid item md={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        margin: '10px 10px 10px 0px',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#8C8C8C",
                          marginRight: "20px",
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        <DescriptionIcon width="15px" height="15px" />
                        Additional Description:
                      </Typography>
                      <Typography>
                        {project.projectDetails.otherSpecification || '--'}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                    alignItems: "center",
                    justifyContent: "space-between",
                    "& svg > path": {
                      fill: "white",
                    },
                  }}
                >
                  <a href={project.bomFile.location}>
                    <CustomButton style={{ display: "flex", gap: "10px" }}>
                      <DownloadIcon width="15px" height="15px" /> DOWNLOAD BOM
                    </CustomButton>
                  </a>
                  <Box
                    sx={{
                      display: "flex",
                      marginTop: "0px",
                      gap: "10px",
                      alignItems: "center",
                      "& svg > path": {
                        fill: "#7D7D7D",
                      },
                    }}
                  >
                    <TimerIcon width="15px" height="15px" />
                    <Typography sx={{ color: "#7D7D7D" }}>
                      Last Bom File upload was on{" "}
                    </Typography>
                    <Typography sx={{ color: '#0275FC', fontWeight: 700 }}>
                      {moment(
                        project.bomFile.uploadedOn || project.updationDate
                      ).format('MMMM Do YYYY, h:mm a') || 'no date'}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                marginTop: "30px",
                display: "flex",
                flexDirection: "row",
                gap: "30px",
                height: "fit-content",
              }}
            >
              <Box sx={{ flex: 2 }}>
                <Typography>My Quote</Typography>
                <form onSubmit={handleSubmit}>
                  <Box
                    sx={{
                      width: "100%",
                      height: "fit-content",
                      border: "3px solid #0275FC",
                      borderRadius: "12px",
                      marginTop: "30px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                      padding: "20px",
                    }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <Typography
                        sx={{ width: "20%", color: "#0702FC", fontWeight: 500 }}
                      >
                        Step 1:
                      </Typography>
                      <Box sx={{ flex: 1 }}>
                        <Typography>Upload Quotation</Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <UploadIcon />
                      <Typography>
                        {project.isQuoted
                          ? "Re-upload BOM file with your quotation "
                          : "Upload BOM file with your quotation"}
                      </Typography>
                      <BrowseButton
                        onClick={handleBrowseClick}
                        disabled={
                          project?.bomQuotation?.isAccepted ||
                          project.isRejected
                        }
                      >
                        Browse Computer
                      </BrowseButton>
                    </Box>

                    {project.isQuoted ? (
                      <Box
                        sx={{
                          display: "flex",
                          marginTop: "0px",
                          gap: "10px",
                          alignItems: "center",
                          "& svg > path": {
                            fill: "#7D7D7D",
                          },
                        }}
                      >
                        <TimerIcon width="15px" height="15px" />
                        <Typography sx={{ color: "#7D7D7D" }}>
                          Last Bom file{" "}
                          {
                           handleName( project?.bomQuotation?.quotation?.bomFile
                            ?.originalname,24)
                          }{" "}
                          upload was on{" "}
                        </Typography>
                        <Typography sx={{ color: "#0275FC", fontWeight: 700 }}>
                          {moment(
                            project?.bomQuotation?.quotation?.bomFile
                              ?.uploadedOn
                          ).format("MMMM Do YYYY, h:mm a") || "no date"}
                        </Typography>
                        <a
                          href={
                            project?.bomQuotation?.quotation?.bomFile?.location
                          }
                        >
                          <DownloadIcon width="15px" height="15px" />
                        </a>
                      </Box>
                    ) : null}
                    {quotationBomFile && quotationBomFile.name ? (
                      <Box
                        sx={{
                          width: '100%',
                          border: '2px solid #8B8B8B4D',
                          padding: '5px 10px',
                          borderRadius: '5px',
                          // boxShadow: " 0px 0px 30px rgba(139, 139, 139, 0.2)",
                          display: 'flex',
                          justifyContent: 'space-between',
                          marginTop: '10px',
                        }}
                      >
                        <Typography sx={{ maxWidth: "70%" }}>
                          {handleName(quotationBomFile.name, 35)}
                        </Typography>
                        <Typography>
                          {(quotationBomFile.size / (1024 * 1024)).toFixed(2)}mb
                        </Typography>
                        <img
                          src={bin}
                          alt=""
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setQuotationBomFile(null);
                          }}
                        />
                      </Box>
                    ) : null}
                    <Box sx={{ display: "flex" }}>
                      <Typography
                        sx={{ width: "20%", color: "#0702FC", fontWeight: 500 }}
                      >
                        Step 2:
                      </Typography>
                      <Box sx={{ flex: 1 }}>
                        <Typography>Shipping Quotation</Typography>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            gap: "20px",
                            marginTop: "30px",
                          }}
                        >
                          <Box
                            sx={{
                              flex: 1,
                              display: "flex",
                              alignItems: "center",
                              gap: "20px",
                            }}
                          >
                            <Typography sx={{ width: "40%" }}>
                              Fast Shipping
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <TextField
                                variant="standard"
                                name="fashShippingMinTime"
                                onChange={handleChange}
                                value={quotation.fashShippingMinTime}
                                disabled={
                                  project?.bomQuotation?.isAccepted ||
                                  project.isRejected
                                }
                                sx={{
                                  width: "30px",
                                  "& input": { textAlign: "center" },
                                }}
                                required
                              />
                              <div>to</div>
                              <TextField
                                variant="standard"
                                name="fastShippingMaxTime"
                                onChange={handleChange}
                                value={quotation.fastShippingMaxTime}
                                disabled={
                                  project?.bomQuotation?.isAccepted ||
                                  project.isRejected
                                }
                                sx={{
                                  width: "30px",
                                  "& input": { textAlign: "center" },
                                }}
                                required
                              />
                              <Typography>days</Typography>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              flex: 1,
                              display: "flex",
                              alignItems: "center",
                              gap: "20px",
                            }}
                          >
                            <Typography sx={{ width: "40%" }}>
                              Shipping cost:
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                gap: "10px",
                                alignItems: "baseline",
                              }}
                            >
                              <Typography>₹</Typography>
                              <TextField
                                variant="standard"
                                name="fastShippingCost"
                                value={quotation.fastShippingCost}
                                disabled={
                                  project?.bomQuotation?.isAccepted ||
                                  project.isRejected
                                }
                                onChange={handleChange}
                                required
                                sx={{ width: "50%" }}
                              />
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            gap: "20px",
                            marginTop: "30px",
                          }}
                        >
                          <Box
                            sx={{
                              flex: 1,
                              display: "flex",
                              alignItems: "center",
                              gap: "20px",
                            }}
                          >
                            <Typography sx={{ width: "40%" }}>
                              Normal Shipping
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <TextField
                                variant="standard"
                                name="normalShippingMinTime"
                                onChange={handleChange}
                                value={quotation.normalShippingMinTime}
                                disabled={
                                  project?.bomQuotation?.isAccepted ||
                                  project.isRejected
                                }
                                sx={{
                                  width: "30px",
                                  "& input": { textAlign: "center" },
                                }}
                                required
                              />
                              <div>to</div>
                              <TextField
                                variant="standard"
                                name="normalShippingMaxTime"
                                onChange={handleChange}
                                value={quotation.normalShippingMaxTime}
                                disabled={
                                  project?.bomQuotation?.isAccepted ||
                                  project.isRejected
                                }
                                sx={{
                                  width: "30px",
                                  "& input": { textAlign: "center" },
                                }}
                                required
                              />
                              <Typography>days</Typography>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              flex: 1,
                              display: "flex",
                              alignItems: "center",
                              gap: "20px",
                            }}
                          >
                            <Typography sx={{ width: "40%" }}>
                              Shipping cost:
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                gap: "10px",
                                alignItems: "baseline",
                              }}
                            >
                              <Typography>₹</Typography>
                              <TextField
                                variant="standard"
                                name="normalShippingCost"
                                value={quotation.normalShippingCost}
                                onChange={handleChange}
                                required
                                sx={{ width: "50%" }}
                                disabled={
                                  project?.bomQuotation?.isAccepted ||
                                  project.isRejected
                                }
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{ display: "flex" }}>
                      <Typography
                        sx={{ width: "20%", color: "#0702FC", fontWeight: 500 }}
                      >
                        Step 3:
                      </Typography>
                      <Box sx={{ flex: 1 }}>
                        <Typography>Payment Terms</Typography>
                        <Box
                          sx={{
                            width: "100%",
                            marginTop: "30px",
                          }}
                        >
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              alignItems: "end",
                              gap: "15px",
                            }}
                          >
                            <Typography sx={{ width: "25%" }}>
                              Advance Payment:
                            </Typography>
                            <TextField
                              variant="standard"
                              name="advance"
                              onChange={(e) => {
                                if (
                                  Number(e.target.value) >= 0 &&
                                  Number(e.target.value) <= 100
                                ) {
                                  setQuotation({
                                    ...quotation,
                                    advance: e.target.value,
                                  });
                                }
                              }}
                              value={quotation.advance}
                              disabled={
                                project?.bomQuotation?.isAccepted ||
                                project.isRejected
                              }
                              sx={{
                                width: "30px",
                                "& input": { textAlign: "center" },
                              }}
                              required
                            />
                            <Typography>%</Typography>
                          </Box>
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              alignItems: "end",
                              gap: "15px",
                              marginTop: "20px",
                            }}
                          >
                            <Typography sx={{ width: "25%" }}>
                              On-Delivery Payment:
                            </Typography>
                            <TextField
                              variant="standard"
                              name="minTime"
                              disabled
                              value={100 - quotation.advance}
                              sx={{
                                width: "30px",
                                "& input": { textAlign: "center" },
                              }}
                              required
                            />
                            <Typography>%</Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <CustomButton
                        type="submit"
                        disabled={
                          project?.bomQuotation?.isAccepted ||
                          project.isRejected
                        }
                      >
                        SUBMIT QUOTE
                      </CustomButton>

                      {project.isQuoted ? (
                        // <Typography
                        //   sx={{
                        //     textAlign: "start",
                        //     fontSize: "0.9em",
                        //     width: "35%",
                        //   }}
                        // >
                        //   Latest BOM quotation was updated at{" "}
                        //   {moment(project.bomQuotation?.updatedOn).format(
                        //     "HH:mma, Do MMMM YYYY"
                        //   )}
                        // </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            marginTop: "0px",
                            gap: "10px",
                            alignItems: "center",
                            "& svg > path": {
                              fill: "#7D7D7D",
                            },
                          }}
                        >
                          <TimerIcon width="15px" height="15px" />
                          <Typography sx={{ color: "#7D7D7D" }}>
                            Last Bom quotation was updated on{" "}
                          </Typography>
                          <Typography
                            sx={{ color: "#0275FC", fontWeight: 700 }}
                          >
                            {moment(project.bomQuotation?.updatedOn).format(
                              "MMMM Do YYYY, h:mm a"
                            ) || "no date"}
                          </Typography>
                        </Box>
                      ) : null}
                    </Box>
                  </Box>
                </form>
              </Box>
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  height: "auto",
                  flexDirection: "column",
                }}
              >
                <Typography>Quote Status</Typography>
                <Box
                  sx={{
                    width: "100%",
                    backgroundColor: " rgba(139, 139, 139, 0.1)",
                    flex: 1,
                    border: `3px solid ${getQuoteBackgroundColor(project)}`,
                    borderRadius: "12px",
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "20px",
                    padding: "20px",
                    backgroundColor: getQuoteBackgroundColor(project) + "25",
                  }}
                >
                  <img
                    src={QuotationStatus}
                    height="220px"
                    width="220px"
                    alt=""
                  />
                  <Typography
                    sx={{
                      fontSize: "1.2em",
                      textAlign: "center",
                      lineHeight: "18 0%",
                    }}
                  >
                    {getQuoteStatus(project)}
                  </Typography>
                  {project?.bomQuotation?.isAccepted ? (
                    <>
                      <a href={project.bomQuotation.adminBomFile.location}>
                        <CustomButton>DOWNLOAD ACCEPTED BOM</CustomButton>
                      </a>
                      {/* <Typography sx={{ textAlign: "center", width: "80%" }}>
                        Latest BOM quotation was updated at{" "}
                        {moment(
                          project.bomQuotation?.adminBomFile?.uploadedOn
                        ).format("HH:mma, Do MMMM YYYY")}
                      </Typography> */}
                      <Box
                        sx={{
                          display: "flex",
                          marginTop: "0px",
                          flexDirection: "column",
                          gap: "10px",
                          alignItems: "center",
                          "& svg > path": {
                            fill: "#7D7D7D",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                          <TimerIcon width="15px" height="15px" />
                          <Typography sx={{ color: "#7D7D7D" }}>
                            Last Bom quotation was uploaded at{" "}
                          </Typography>
                        </Box>
                        <Typography sx={{ color: "#0275FC", fontWeight: 700 }}>
                          {moment(
                            project.bomQuotation?.adminBomFile?.uploadedOn
                          ).format("MMMM Do YYYY, h:mm a") || "no date"}
                        </Typography>
                      </Box>
                    </>
                  ) : null}
                </Box>
              </Box>
            </Box>
            <Box sx={{ marginTop: '30px' }}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  margin: '20px 0px',
                }}
              >
                <Typography>Order Timeline 📈</Typography>
                <Box
                  sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}
                >
                  <CustomButton
                    disabled={
                      project.bomQuotation?.currentStatus.length <= 2 ||
                      project.bomQuotation == null ||
                      prevStageNumber === supplierStages.length
                    }
                    onClick={handleStageChange}
                  >
                    Next Stage
                  </CustomButton>
                </Box>
              </Box>
              <Box>
                <Timeline>
                  {supplierStages.map((stage, i) => (
                    <TimelineItem>
                      <TimelineOppositeContent
                        color="text.secondary"
                        style={{ flex: 0 }}
                      >
                        {project.bomQuotation?.currentStatus.length >= i + 1 ? (
                          moment(
                            project?.bomQuotation?.currentStatus[i]
                              ?.completionDate
                          ).format('DD/MM/YYYY')
                        ) : (
                          <Typography sx={{ visibility: 'hidden' }}>
                            {moment().format('DD/MM/YYYY')}
                          </Typography>
                        )}
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot />
                        {i != 5 ? <TimelineConnector /> : null}
                      </TimelineSeparator>
                      <TimelineContent>
                        <SupplierTimelineComponent
                          stage={stage}
                          i={i}
                          quotation={project.bomQuotation}
                        />
                      </TimelineContent>
                    </TimelineItem>
                  ))}
                </Timeline>
              </Box>
            </Box>
          </SupplierBomProjectsContainer>
        </PageContainer>
      ) : null}
    </Box>
  );
};

export default SupplierBomQuotation;
