import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { axiosInstance } from "../utils/axiosInterceptor";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import Button from "@mui/material/Button";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import IconButton from "@mui/material/IconButton";
import "./Billing.css";

const label = { inputProps: { "aria-label": "Checkbox demo" } };
export default function BillingHome() {
  const [loading, setLoading] = useState(false);
  const [bills, setBills] = useState([]);
  const [expanded, setExpanded] = React.useState(false);
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [paymentAmount,setPaymentAmount]=useState(0);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleCheckBoxChange=(billIdx,checked)=>{
    console.log(billIdx,checked);
    if(checked){
      setPaymentAmount(paymentAmount+bills[billIdx].totalbillingAmount);
    }
    else{
      setPaymentAmount(paymentAmount-bills[billIdx].totalbillingAmount);
    }
  };
  const fetchData = (service_type) => {
    setLoading(true);
    axiosInstance
      .get(`/products/getBilling/${service_type}`)
      .then((resp) => {
        console.log(resp.data);
        setBills(resp.data.response);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const numberFormat = (value) =>
  new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(value);


  // let newOne = bills.map(item => item.billingBreakdown)
  // let newTwo = Object.entries(newOne)
  // let newThree = newTwo.map(item => item[1])
  // let oneFour = Object.entries(newThree[0])
  // console.log(oneFour[0][1])
  return (
    <Container>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Row style={{ marginTop: "20px" }}>
        <Col>
          <h3>Billings</h3>
        </Col>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Autocomplete
          value={value}
          onChange={(event, newValue) => {
            console.log(newValue);
            fetchData(newValue.name);
            setValue(newValue);
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          disablePortal
          id="combo-box-demo"
          options={top100Films}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label="Select a service" />
          )}
        />
      </Row>
      <Row style={{ marginTop: "20px", marginLeft: "0px" }}>
        {
          bills.length === 0?(
            <h4>No billings found with this service</h4>
          ):('')
        }
        {bills.map((bill, index) => {
          return (
            <Accordion
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  <Checkbox inputProps={{ "aria-label": "controlled" }} onChange={(e)=>{
                      handleCheckBoxChange(index,e.target.checked);
                  }}/>
                  Total Amount - {numberFormat(bill.totalbillingAmount)}
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  Creation Date -{" "}
                  {moment(bill.createdAt).format("DD/MM/YYYY - HH:mm")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <div className="Billing breakdown">
                    <h6>Billing breakdown</h6>
                    <table id="billBreakDown">
                    {(bill&&bill.billingBreakdown)?(
                        Object.entries(bill.billingBreakdown).map((obj) => {
                          const key = obj[0];
                          const value = obj[1];
    
                          return (
                            <tr>
                              <td>
                                {value.type}
                              </td>
                              <td>{numberFormat(value.amount)}</td>
                            </tr>
                          );
                        }
                    )):('')
                    }
                    <tr>
                      <td>
                        
                        Total Amount
                      </td>
                      <td>{numberFormat(bill.totalbillingAmount)}</td>
                    </tr>
                    </table>
                  </div>
                  <div className="shipping_to">
                    <h6>Shipping Time : {bill.order?.shippingTime}</h6>
                  </div>
                  <div className="shipping_to">
                    <h6>Order Status : {bill.order?.orderStatus}</h6>
                  </div>
                  <div className="shipping_to">
                    <h6>Ship To</h6>
                    <p>Name: {bill.order?.shippingAddress?.fullName}</p>
                    <p>Phone: {bill.order?.shippingAddress?.mobileNumber}</p>
                    <p>
                      {bill.order?.shippingAddress?.addressType}, City:{" "}
                      {bill.order?.shippingAddress?.city}, State:{" "}
                      {bill.order?.shippingAddress?.state}
                    </p>
                    <p>
                      {" "}
                      Street: {bill.order?.shippingAddress?.street}, House
                      Number: {bill.order?.shippingAddress?.houseNumber}, Pin
                      Code: {bill.order?.shippingAddress?.pinCode}
                    </p>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Row>
      <Row>
        <Col style={{display:'flex',justifyContent:'flex-end',marginTop:'20px'}}>
        <h5>Total Amount - {numberFormat(paymentAmount)}</h5>
        <Button disabled={(paymentAmount==0)} variant="contained" color="primary" style={{marginLeft:'20px'}}>
          Pay Now
        </Button>
        </Col>
      </Row>

    </Container>
  );
}

const top100Films = [
  { label: "PCB Market", name: "pcb_market" },
  { label: "Client Engagement", name: "client_enagement" },
];
