import React from "react";
import "./PCBCart.css";
import { useEffect, useState } from "react";
import { axiosInstance } from "../../utils/axiosInterceptor";
import { Container, Row, Col, Modal } from "react-bootstrap";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@material-ui/core/TextField";
import DashboardLogo1 from "../../assests/elecbitsLogo.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import PCBShipping from "./PCBShipping/PCBShipping";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Backdrop from "@mui/material/Backdrop";

function createRow(deleteOpt, product, price, quantity, subTotal) {
  return { deleteOpt, product, price, quantity, subTotal };
}
const rows = [
  createRow("Paperclips (Box)", 100, 1.15),
  createRow("Paper (Case)", 10, 45.99),
  createRow("Waste Basket", 2, 17.99),
];
let all_quantity = {};

const PCBCart = (props) => {
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowsToRender, setRowsToRender] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [idx, setIdx] = useState(null);
  const [delLoading, setDelLoading] = useState(false);
  const [subTotal, setSubTotal] = useState(0);
  const [addressModal, setAddressModal] = useState(false);

  const handleShow = (idx) => {
    setOpen(true);
    setIdx(idx);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(value);

  const getQPricing = (quantity, prod) => {
    console.log(prod);
    let obj = prod.quantity_wise_pricing[0];
    let perUnit = -1;
    let maxRangeEnd = -1;
    let sPrice = -1;

    for (let key in obj) {
      let x = obj[key];
      quantity = parseInt(quantity);

      if (x.rangeEnd > maxRangeEnd) {
        sPrice = x.amount;
        maxRangeEnd = x.rangeEnd;
      }

      if (
        quantity >= parseInt(x.rangeStart) &&
        quantity <= parseInt(x.rangeEnd)
      ) {
        perUnit = x.amount;
        break;
      }
    }

    if (perUnit == -1) {
      return sPrice * quantity;
    } else {
      return perUnit * quantity;
    }
  };

  const handleUpdateCart = () => {
    let alterItems = [];
    console.log(all_quantity);
    for (let i = 0; i < cartItems.length; ++i) {
      let priceFinal = getQPricing(all_quantity[i], cartItems[i].product);
      alterItems.push({
        product: cartItems[i].product._id,
        quantity: all_quantity[i],
        newPrice: priceFinal,
      });
    }

    console.log(alterItems);

    setDelLoading(true);
    axiosInstance
      .put("/products/alterCart/", {
        products: alterItems,
      })
      .then((resp) => {
        console.log(resp);
        setDelLoading(false);
        getProducts();
      })
      .catch((err) => {
        console.log(err);
        setDelLoading(false);
      });
  };
  const updateRowsToRender = (cart_items) => {
    let resRows = [];
    for (let i = 0; i < cart_items.length; ++i) {
      let item = cart_items[i];
      let quantity = item.quantity;
      let totalPrice = item.newPrice;
      let price = totalPrice / quantity;

      all_quantity[i] = quantity;
      console.log(item);

      resRows.push(
        createRow(
          <IconButton onClick={() => handleShow(i)} aria-label="delete">
            <DeleteIcon />
          </IconButton>,
          <div className="cart_pd">
            <img
              src={item.product.product_images[0].location}
              alt=""
              style={{ marginRight: "10px" }}
            />
            {item.product.short_name}
          </div>,
          numberFormat(price),
          <TextField
            id="outlined-number"
            type="number"
            defaultValue={quantity}
            onChange={(e) => {
              if (e.target.value <= 0) {
                all_quantity[i] = 1;
              } else {
                all_quantity[i] = e.target.value;
              }
              console.log(all_quantity);
            }}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: "80px" }}
            variant="outlined"
          />,
          numberFormat(item.newPrice)
        )
      );
    }
    setRowsToRender(resRows);
    setLoading(false);
  };

  const getProducts = () => {
    setLoading(true);
    axiosInstance
      .get("/products/getCartProducts")
      .then((resp) => {
        console.log(resp.data.response);
        if (
          resp.data.response.length > 0 &&
          resp.data.response[0].products &&
          resp.data.response[0].products.length == 0
        ) {
          console.log("x");
          setCartItems([]);
          updateRowsToRender([]);
          getSumOfSubTotals([]);
        } else {
          if(resp.data.response[0]!=null){
            setCartItems(resp.data.response[0].products);
            updateRowsToRender(resp.data.response[0].products);
            getSumOfSubTotals(resp.data.response[0].products);  
          }
          else{
            setCartItems([]);
            updateRowsToRender([]);
            getSumOfSubTotals([]);  
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getProducts();
    all_quantity = {};
  }, []);
  const getSumOfSubTotals = (cartItems) => {
    let totalSub = 0;
    for (let i = 0; i < cartItems.length; ++i) {
      let item = cartItems[i];
      totalSub += item.newPrice;
    }
    setSubTotal(totalSub);
  };
  const handleDeleteItem = () => {
    console.log(idx);
    let items = cartItems;
    let alterItems = [];

    for (let i = 0; i < items.length; ++i) {
      console.log(items[i]);
      if (i != idx) {
        alterItems.push({
          product: items[i].product._id,
          quantity: items[i].quantity,
          newPrice: items[i].newPrice,
        });
      }
    }
    setDelLoading(true);
    handleClose();
    axiosInstance
      .put("/products/alterCart/", {
        products: alterItems,
      })
      .then((resp) => {
        console.log(resp);
        setDelLoading(false);
        getProducts();
      })
      .catch((err) => {
        console.log(err);
        setDelLoading(false);
      });
  };

  

  return (
    <div className="pcb-cart">
      <PCBShipping
        open={addressModal}
        showModal={() => {
          setAddressModal(true);
        }}
        handleCloseModal={() => {setAddressModal(false)}}
      />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={delLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete this product from cart ?"}
        </DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            No
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleDeleteItem();
            }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      {!loading && cartItems && rowsToRender ? (
        <Container>
          <Row style={{ paddingTop: "20px", marginBottom: "20px" }}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link to="/">Home</Link>
              <Link to="/pcbMarket">PCB Market</Link>
              <Typography color="text.primary">Cart</Typography>
            </Breadcrumbs>
          </Row>

          <Row>
            <Col>
              <div>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="left">Product</TableCell>
                        <TableCell align="left">Price / Unit</TableCell>
                        <TableCell align="left">Quantity</TableCell>
                        <TableCell align="left">Sub Total</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rowsToRender.map((row) => {
                        return (
                          <TableRow key={row._id}>
                            <TableCell align="left" component="th" scope="row">
                              {row.deleteOpt}
                            </TableCell>
                            <TableCell align="left">{row.product}</TableCell>
                            <TableCell align="left">{row.price}</TableCell>
                            <TableCell align="left">{row.quantity}</TableCell>
                            <TableCell align="left">{row.subTotal}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                  <div className="cart_bottom">
                    <div className="proceed_cart">
                      <div className="cart_total">
                        <h5>Total Price: {numberFormat(subTotal)}</h5>
                      </div>
                      <div>
                        <Button
                          variant="primary"
                          onClick={() => {
                            handleUpdateCart();
                          }}
                        >
                          Update Cart
                        </Button>
                        <Button onClick={()=>{
                          setAddressModal(true);
                        }} variant="primary">
                          Place Order
                        </Button>
                      </div>
                    </div>
                  </div>
                </TableContainer>
              </div>
            </Col>
          </Row>
        </Container>
      ) : (
        <div className="time_wrap">
          <img src={DashboardLogo1} style={{ width: "150px" }} alt="logo" />
          <CircularProgress style={{ marginLeft: "20px" }}></CircularProgress>
        </div>
      )}
    </div>
  );
};

export default PCBCart;