import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import Stack from "@mui/material/Stack";
import { DropzoneArea } from "material-ui-dropzone";
import Chip from "@material-ui/core/Chip";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Switch from "@material-ui/core/Switch";
import QuantityWisePricing from "./QuantityWisePricing";
import Button from "@material-ui/core/Button";
import { set } from "date-fns";
import { Link } from "react-router-dom";
import { SnackbarProvider, useSnackbar } from "notistack";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useParams } from "react-router-dom";
import { axiosInstance } from "../utils/axiosInterceptor";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function EditProduct(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const [hardware_features, set_hardware_features] = useState([]);
  const [newFeatureHardware, setNewFeatureHardware] = useState(null);

  const [software_features, set_software_features] = useState([]);
  const [newFeatureSoftware, setNewFeatureSoftware] = useState(null);

  const [code_upload_methods, set_code_upload_methods] = useState([]);
  const [newCodeMethod, setNewCodeMethod] = useState(null);

  const [productTags, setProductTags] = useState([]);
  const [newTag, setNewTag] = useState(null);

  const [shrtName, setShrtName] = useState("");
  const [skuName, setSkuName] = useState("");
  const [prodDesc, setProdDesc] = useState("");
  const [dataSheet, setDataSheet] = useState("");
  const [leadTime, setLeadTime] = useState("");
  const [category, setCategory] = useState("");
  const [usedIn, setUsedIn] = useState("");
  const [form_factor, setFormFactor] = useState("");
  const [weight, setWeight] = useState("");
  const [interfaces, setInterfaces] = useState("");
  const [memory_map, setMemoryMap] = useState("");
  const [power, setPower] = useState("");
  const [prodFiles, setProdFiles] = useState(null);
  const [edtaFiles, setEdtaFiles] = useState(null);
  const [uploadedProdFiles, setUploadedProdFiles] = useState([]);
  const [uploadedEdtaFiles,setUploadedEdtaFiles] = useState([]);
  const [iotSecurity, setIotSecurity] = useState(false);
  const [pricing, setPricing] = useState(null);

  const { productId } = useParams();
  const [product, setProduct] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  const handleDelete = (chipToDelete) => () => {
    set_hardware_features((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
  };
  const addFeature = (chip) => {
    hardware_features.push(chip);
    set_hardware_features(hardware_features);
  };

  const handleDeletes = (chipToDelete) => () => {
    set_software_features((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
  };
  const addFeatures = (chip) => {
    software_features.push(chip);
    set_software_features(software_features);
  };

  const handleDeletec = (chipToDelete) => () => {
    set_code_upload_methods((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
  };

  const addFeaturec = (chip) => {
    code_upload_methods.push(chip);
    set_code_upload_methods(code_upload_methods);
  };

  const handleDeletet = (chipToDelete) => () => {
    setProductTags((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
  };
  const addFeaturet = (chip) => {
    productTags.push(chip);
    setProductTags(productTags);
  };
  const handleSubmit = () => {
    setOpen(true);
    let formData = new FormData();
    formData.append("short_name", shrtName);
    formData.append("sku_name", skuName);
    formData.append("description", prodDesc);
    formData.append("datasheet", dataSheet);
    formData.append("quantity_wise_pricing", JSON.stringify(pricing));
    formData.append("lead_time", leadTime);
    formData.append("category", category);
    formData.append("used_in", usedIn);
    formData.append("tags", JSON.stringify(productTags));
    formData.append("ioT_security_marked_or_not", iotSecurity);
    formData.append("form_factor", form_factor);
    formData.append("weight", weight);
    formData.append("interfaces", interfaces);
    formData.append("memory_map", memory_map);
    formData.append("power", power);
    formData.append("hardware_features", JSON.stringify(hardware_features));
    formData.append("software_features", JSON.stringify(software_features));
    formData.append(
      "code_uploading_methods_and_tools",
      JSON.stringify(code_upload_methods)
    );

    if(uploadedEdtaFiles.length > 0){
      for (let i = 0; i < uploadedEdtaFiles.length; i++) {
        formData.append("edtaFiles", uploadedEdtaFiles[i]);
      }
    }else{
      formData.append("edtaFilesImages",JSON.stringify(edtaFiles));
    }

    if(uploadedProdFiles.length>0){
      for (let i = 0; i < uploadedProdFiles.length; i++) {
        formData.append("prodFiles", uploadedProdFiles[i]);
      }
    }else{
      formData.append("prodFilesImages",JSON.stringify(prodFiles));
    }

    //Files

    console.log(edtaFiles,prodFiles);



   

    let token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "multipart/form-data",
      },
    };
    let url = `https://platform.elecbits.in/api/products/editProduct/${productId}`;

    axiosInstance
      .put(`/products/editProduct/${productId}`, formData, config)
      .then((response) => {
        setOpen(false);
        console.log(response);
        enqueueSnackbar("Product edited successfully", {
          variant: "success",
        });
      })
      .catch((error) => {
        setOpen(false);
        enqueueSnackbar("Not able to Edit", { variant: "error" });
        console.log(error);
      });
  };
  const getProduct = () => {
    axiosInstance
      .get(`/products/productView/${productId}`)
      .then((resp) => {
        setProduct(resp.data.response);

        setShrtName(resp.data.response.short_name);
        setSkuName(resp.data.response.sku_name);
        setProdDesc(resp.data.response.description);
        setDataSheet(resp.data.response.datasheet);
        setLeadTime(resp.data.response.lead_time);
        setCategory(resp.data.response.category);
        setUsedIn(resp.data.response.used_in);
        setFormFactor(resp.data.response.form_factor);
        setWeight(resp.data.response.weight);
        setInterfaces(resp.data.response.interfaces);
        setMemoryMap(resp.data.response.memory_map);
        setPower(resp.data.response.power);
        setProdFiles(resp.data.response.product_images);
        setEdtaFiles(resp.data.response.edta_step_files);
        setIotSecurity(resp.data.response.ioT_security_marked_or_not);
        setPricing(resp.data.response.quantity_wise_pricing);

        console.log(resp.data.response);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //   const editProduct = (productId) => {
  //   axiosInstance
  //     .put(`/products/editProduct/${productId}`)
  //     .then((resp) => {
  //       setProducts(resp.data.response);
  //       console.log(resp.data.response);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  useEffect(() => {
    getProduct();
  }, []);

  return (
    <Container>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Row>
        <Col>
          <Row>
            <Col id="all_ordrs">
              <h4>Elecbits PCB Arcade - Edit Product</h4>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col style={{ marginBottom: "30px", marginTop: "20px" }}>
          <Stack
            component="form"
            sx={{
              width: "100%",
            }}
            spacing={2}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="outlined-basic"
              label="Short Name"
              variant="outlined"
              value={shrtName}
              onChange={(e) => {
                setShrtName(e.target.value);
              }}
            />
            <TextField
              id="outlined-basic"
              label="Sku Name"
              variant="outlined"
              value={skuName}
              onChange={(e) => {
                setSkuName(e.target.value);
              }}
            />
            <TextField
              id="outlined-basic"
              multiline
              label="Product Description"
              variant="outlined"
              value={prodDesc}
              onChange={(e) => {
                setProdDesc(e.target.value);
              }}
            />
            <TextField
              id="outlined-basic"
              multiline
              label="Datasheet"
              variant="outlined"
              value={dataSheet}
              onChange={(e) => {
                setDataSheet(e.target.value);
              }}
            />
            <TextField
              id="outlined-basic"
              label="Lead Time"
              variant="outlined"
              value={leadTime}
              onChange={(e) => {
                setLeadTime(e.target.value);
              }}
            />
            <TextField
              id="outlined-basic"
              label="Category"
              variant="outlined"
              value={category}
              onChange={(e) => {
                setCategory(e.target.value);
              }}
            />
            <TextField
              value={usedIn}
              onChange={(e) => {
                setUsedIn(e.target.value);
              }}
              id="outlined-basic"
              label="Used In"
              variant="outlined"
            />
            <TextField
              id="outlined-basic"
              label="Form Factor"
              variant="outlined"
              value={form_factor}
              onChange={(e) => {
                setFormFactor(e.target.value);
              }}
            />
            <TextField
              type="number"
              id="outlined-basic"
              label="Weight"
              variant="outlined"
              value={weight}
              onChange={(e) => {
                setWeight(e.target.value);
              }}
            />
            <TextField
              id="outlined-basic"
              label="Interfaces"
              variant="outlined"
              value={interfaces}
              onChange={(e) => {
                setInterfaces(e.target.value);
              }}
            />
            <TextField
              id="outlined-basic"
              label="Memory Map"
              variant="outlined"
              value={memory_map}
              onChange={(e) => {
                setMemoryMap(e.target.value);
              }}
            />
            <TextField
              value={power}
              onChange={(e) => {
                setPower(e.target.value);
              }}
              id="outlined-basic"
              label="Power"
              variant="outlined"
            />
            <div>Add Product Images</div>
            <DropzoneArea
              filesLimit={10}
              acceptedFiles={["image/*"]}
              dropzoneText={"Drag and drop an image here or click"}
              onChange={(files) => {
                // setProdFiles(files);
                setUploadedProdFiles(files);
              }}
            />
            {/* 
            //Addable chips
            <div>Add hardware features</div>
            <Paper
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                flexWrap: "wrap",
                listStyle: "none",
                p: 0.5,
                m: 0,
              }}
              component="ul"
            >
              {hardware_features.map((data) => {
                return (
                  <ListItem key={data.key}>
                    <Chip label={data.label} onDelete={handleDelete(data)} />
                  </ListItem>
                );
              })}
              <input
                id="setting_inp"
                onChange={(e) => {
                  setNewFeatureHardware(e.target.value);
                }}
                placeholder="New Hardware Feature"
                value={newFeatureHardware}
              ></input>
              <IconButton
                onClick={() => {
                  let k = hardware_features.length - 1;
                  addFeature({ key: k, label: newFeatureHardware });
                  setNewFeatureHardware("");
                }}
              >
                <AddCircleIcon></AddCircleIcon>
              </IconButton>
            </Paper>
            <div>Add Software features</div>
            <Paper
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                flexWrap: "wrap",
                listStyle: "none",
                p: 0.5,
                m: 0,
              }}
              component="ul"
            >
              {software_features.map((data) => {
                return (
                  <ListItem key={data.key}>
                    <Chip label={data.label} onDelete={handleDeletes(data)} />
                  </ListItem>
                );
              })}
              <input
                id="setting_inp"
                onChange={(e) => {
                  setNewFeatureSoftware(e.target.value);
                }}
                placeholder="New Software Feature"
                value={newFeatureSoftware}
              ></input>
              <IconButton
                onClick={() => {
                  let k = software_features.length - 1;
                  addFeatures({ key: k, label: newFeatureSoftware });
                  setNewFeatureSoftware("");
                }}
              >
                <AddCircleIcon></AddCircleIcon>
              </IconButton>
            </Paper>
            <div>Add code uploading methods and tools</div>
            <Paper
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                flexWrap: "wrap",
                listStyle: "none",
                p: 0.5,
                m: 0,
              }}
              component="ul"
            >
              {code_upload_methods.map((data) => {
                return (
                  <ListItem key={data.key}>
                    <Chip label={data.label} onDelete={handleDeletec(data)} />
                  </ListItem>
                );
              })}
              <input
                id="setting_inp"
                onChange={(e) => {
                  setNewCodeMethod(e.target.value);
                }}
                placeholder="New code uploading method and tool"
                value={newCodeMethod}
              ></input>
              <IconButton
                onClick={() => {
                  let k = code_upload_methods.length - 1;
                  addFeaturec({ key: k, label: newCodeMethod });
                  setNewCodeMethod("");
                }}
              >
                <AddCircleIcon></AddCircleIcon>
              </IconButton>
            </Paper>
            <div>Add product tags</div>
            <Paper
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                flexWrap: "wrap",
                listStyle: "none",
                p: 0.5,
                m: 0,
              }}
              component="ul"
            >
              {productTags.map((data) => {
                return (
                  <ListItem key={data.key}>
                    <Chip label={data.label} onDelete={handleDeletet(data)} />
                  </ListItem>
                );
              })}
              <input
                id="setting_inp"
                onChange={(e) => {
                  setNewTag(e.target.value);
                }}
                placeholder="New product tag"
                value={newTag}
              ></input>
              <IconButton
                onClick={() => {
                  let k = productTags.length - 1;
                  addFeaturet({ key: k, label: newTag });
                  setNewTag("");
                }}
              >
                <AddCircleIcon></AddCircleIcon>
              </IconButton>
            </Paper> */}
            <div>EDTA Step Files</div>
            <DropzoneArea
              filesLimit={10}
              acceptedFiles={["image/*"]}
              dropzoneText={"Drag and drop an image here or click"}
              onChange={(files) => {
                // setEdtaFiles(files);
                setUploadedEdtaFiles(files);
              }}
            />
            <div>IOT Security marked or not</div>
            <Switch
              checked={iotSecurity}
              onChange={(event) => {
                setIotSecurity(event.target.checked);
              }}
              color="primary"
            />
          </Stack>
        </Col>
      </Row>
      <Row>
        <Col>
          <div>Quantity wise pricing</div>
          <div>
            <QuantityWisePricing setPricing={setPricing}></QuantityWisePricing>
          </div>
          {/* <QuantityWisePricing></QuantityWisePricing> */}
        </Col>
      </Row>
      <Row>
        <Col style={{ marginTop: "30px", marginBottom: "20px" }}>
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Button
              variant="contained"
              onClick={handleSubmit}
              style={{ backgroundColor: "#fca702" }}
            >
              Edit Product
            </Button>
            <Link to="/pcbMarket">
              <Button variant="outlined" style={{ color: "#000000" }}>
                Cancel
              </Button>
            </Link>
          </Stack>
        </Col>
      </Row>
    </Container>
  );
}
