import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DashboardHeader from "../ClientEngagement/AdminDashboardHeader";
import Box from "@mui/material/Box";
import { axiosInstance } from "../utils/axiosInterceptor";
import CircularProgress from "@material-ui/core/CircularProgress";
import DashboardLogo1 from "../assests/elecbitsLogo.svg";
import Button from "@mui/material/Button";

import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import useErrorHandler from "../Hooks/useErrorHandler";
import { Paper, Modal } from "@material-ui/core";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { DropzoneArea } from "material-ui-dropzone";
import MenuItem from "@mui/material/MenuItem";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { SnackbarProvider, useSnackbar } from "notistack";
import { Formik, useFormik } from "formik";
import moment from "moment";
import CustomBreadcrumbs from "../Components/CustomBreadcrumbs";
const stages = [
  { value: "Under Review", label: "Under Review" },
  { value: "Quotation Optimization", label: "Quotation Optimization" },
  { value: "Quotation Uploaded", label: "Quotation Uploaded" },
  { value: "Order Review", label: "Order Review" },
  { value: "Order Accepted", label: "Order Accepted" },
  { value: "Shipment Packed", label: "Shipment Packed" },
  { value: "Waiting for Pickup", label: "Waiting for Pickup" },
  { value: "In Transit", label: "In Transit" },
  { value: "Out for Delivery", label: "Out for Delivery" },
  { value: "Successfully Delivered", label: "Successfully Delivered" },
];

const initialQuotesData = {
  componentQuoptes: [
    {
      component: {
        compName: "",
        mountingType: "",
        package: "",
        make: "",
        designator: "",
        compDescription: "",
        quantity: 0,
        mpn: "",
      },
      quotes: [
        {
          id: 0,
          vendorName: "",
          vendorLocation: "",
          leadTime: 0,
          mom: 0,
          cost: 0,
          totalCost: 0,
          shippingCost: 0,
          isFav: false,
        },
      ],
    },
  ],
};

const modalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  backgroundColor: "#fff",
  boxShadow: 24,
  p: 4,
  borderRadius: "3px",
};

let componentQuoteObject = {
  component: {},
  quotes: [],
};
const finalQuotesData = initialQuotesData;

const vendorQuoteObject = {};

const BomExplorerDetail = (props) => {
  const [newProjectModalOpen, setNewProjectModalOpen] = useState(false);
  const openNewProjectModal = () => setNewProjectModalOpen(true);
  const closeNewProjectModal = () => setNewProjectModalOpen(false);
  const { id } = useParams();
  const [files, setFiles] = useState([]);
  const [stageDate, setStageDate] = useState(new Date());
  const [profile_data, set_profile] = React.useState(null);
  const [loading, set_loading] = React.useState(null);
  const [add, setAdd] = React.useState(false);
  const [project, setProject] = React.useState(null);
  const [componentData, setComponentData] = useState(
    initialQuotesData.componentQuoptes[0].component
  );

  const [isGSTError, GSTHelperText, GSTErrorHandler] = useErrorHandler();
  const [isCostError, costHelperText, costErrorHandler] = useErrorHandler();
  const [isMOQError, MOQHelperText, MOQErrorHandler] = useErrorHandler();
  const [isLeadTimeError, LeadTimeHelperText, LeadtimeErrorHandler] =
    useErrorHandler();
  const [isMPNError, MPNHelperText, MPNErrorHandler] = useErrorHandler();
  const [isQuantityError, QuantityHelperText, QuantityErrorHandler] =
    useErrorHandler();

  const [quoteData, setQuoteData] = useState(
    initialQuotesData.componentQuoptes[0].quotes[0]
  );
  const [disableComponentButton, toggleComponentButton] = useState(false);
  const [editNumBoards, setEditNumBoards] = useState({
    boards: 0,
    state: false,
  });

  const { enqueueSnackbar } = useSnackbar();

  const [stage, setStage] = React.useState("stage 1");

  const handleChange = (e) => {
    setStage(e.target.value);
  };

  let fetchProfile = () => {
    set_loading(true);
    axiosInstance
      .get("/users/getProfile")
      .then((resp) => {
        set_profile(resp.data);
        console.log(resp.data);
        set_loading(false);
      })
      .catch((err) => {
        set_loading(false);
      });
  };
  const singleProject = async () => {
    const id = window.location.pathname.split("/")[3];
    console.log(id);
    try {
      const res = await axiosInstance.get(`/bom/client/singleProject/${id}`);
      setProject(res.data.project);
      setEditNumBoards({
        ...editNumBoards,
        boards: res.data?.numberOfBoards,
      });
      console.log(res.data);
    } catch (e) {
      console.log(e);
    }
  };

  const updateNumBoards = async () => {
    const id = window.location.pathname.split("/")[3];
    try {
      const res = await axiosInstance.put(`/bom/admin/editProject/${id}`, {
        numberOfBoards: editNumBoards.boards,
      });
      console.log(res);
      if (res.status === 200) {
        enqueueSnackbar("Project Updated Successfully!", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Something went wrong", { variant: "error" });
      }
    } catch (err) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
    setEditNumBoards({
      ...editNumBoards,
      state: !editNumBoards.state,
    });
  };

  console.log(
    project?.currentStatus[project?.currentStatus.length - 1]?.status
  );
  const [temp, setTemp] = useState("");

  var initialStageData = {};
  useEffect(() => {
    initialStageData = {
      currentStage:
        project?.currentStatus[project?.currentStatus.length - 1]?.status || "",
      comment: "",
      documents: [],
      date: new Date(),
    };
    setTemp(project?.currentStatus[project?.currentStatus.length - 1]?.status);
  }, [project]);
  console.log(temp);

  // const componentQuoteObject = {
  //   component: {},
  //   quotes: [],
  // };

  React.useEffect(() => {
    fetchProfile();
    singleProject();
  }, []);
  const clearComponentDetails = () => {
    setComponentData(initialQuotesData.componentQuoptes[0].component);
  };
  const clearQuotesDetails = () => {
    setQuoteData(initialQuotesData.componentQuoptes[0].quotes[0]);
  };
  const stageSubmitHandler = async (values) => {
    try {
      stageSettingsFormik.values.date = stageDate;
      console.log(stageSettingsFormik.values);
      let formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("bom", files[i]);
      }

      stageSettingsFormik.values.comment == undefined
        ? formData.append("comment", "")
        : formData.append("comment", stageSettingsFormik.values.comment);
      formData.append("currentStage", temp);
      formData.append("date", stageSettingsFormik.values.date);
      const token = localStorage.getItem("token");
      console.log(stageSettingsFormik.values.currentStage);
      const res = await axiosInstance.post(
        `/bom/admin/addStage/${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "multipart/form-data",
          },
        }
      );
      console.log(res);
      enqueueSnackbar("Stage updated successfully", {
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
        variant: "success",
      });
    } catch (e) {
      console.log(e);
    }
  };

  const quotesSubmitHandler = async () => {
    console.log(componentQuoteObject);
    quotationsFomik.values.componentQuoptes.push(componentQuoteObject);
    finalQuotesData.componentQuoptes.push(componentQuoteObject);
    console.log(quotationsFomik.values);
    quotationsFomik.values = initialQuotesData;
    toggleComponentButton(false);
    const res = await axiosInstance.post(`bom/admin/quotationsUpload/${id}`, {
      componentQuotes: componentQuoteObject,
    });
    console.log(res);
    componentQuoteObject = {
      component: {},
      quotes: [],
    };
    enqueueSnackbar(res.data.msg, { variant: "success" });
    clearComponentDetails();
    clearQuotesDetails();
  };
  const onChangeHandler = (e) => {
    setTemp(e.target.value);
  };
  const stageSettingsFormik = useFormik({
    initialValues: initialStageData,
    // onSubmit: stageSubmitHandler,
  });
  const quotationsFomik = useFormik({
    initialValues: initialQuotesData,
    onSubmit: quotesSubmitHandler,
  });

  console.log(project);
  return (
    <Box>
      {!loading && profile_data && project ? (
        <>
          <DashboardHeader
            profile_data={profile_data.data}
            toggleLoggedIn={props.toggleLoggedIn}
            history={props.history}
            isBlack={true}
          ></DashboardHeader>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
              margin: "2rem",
              marginLeft: "8rem",
              fontSize: "1.2rem",
            }}
          >
            <Box
              sx={{
                fontSize: "2rem",
                fontWeight: "bold",
                marginBottom: "1rem",
              }}
            >
              <CustomBreadcrumbs
                data={[
                  {
                    name: "Home",
                    link: "/",
                  },
                  {
                    name: "EB BOM Explorer",
                    link: "/admin/bomExplorer",
                  },
                  {
                    name: `${project.projectName} - Project Detail`,
                    link: `/admin/bomExplorerDetail/${project._id}`,
                  },
                ]}
              />
              Project Detail Page
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "80%",
              }}
            >
              <Box sx={{ fontSize: "1.4rem", fontWeight: "bold" }}>
                {project.projectName} - {project.companyName}
              </Box>
              <Box>Project Id: {project.projectId}</Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "80%",
                margin: "1rem 0rem",
              }}
            >
              <Box>
                Project Created on:{" "}
                {moment(project.startDate).format("MMMM Do YYYY, h:mm a")}
              </Box>
              {/* <Box>
                Total Number Of Boards:{" "}
                {project?.numberOfBorads}
              </Box> */}
              <a href={project?.bomSheet?.location}>
                <Button variant="contained">Download Bom</Button>
              </a>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  "& .MuiInput-input.Mui-disabled": {
                    color: "#000",
                    textFillColor: "#000",
                  },
                }}
              >
                Total Number Of Boards:{" "}
                <TextField
                  disabled={!editNumBoards.state}
                  variant="standard"
                  sx={{ width: "100px" }}
                  value={editNumBoards.boards}
                  InputProps={{ disableUnderline: !editNumBoards.state }}
                  onChange={(e) => {
                    setEditNumBoards({
                      ...editNumBoards,
                      boards: e.target.value,
                    });
                  }}
                />{" "}
              </Box>

              {!editNumBoards.state ? (
                <EditOutlinedIcon
                  sx={{ cursor: "pointer", transform: "scale(0.9)" }}
                  onClick={() => {
                    setEditNumBoards({
                      ...editNumBoards,
                      state: !editNumBoards.state,
                    });
                  }}
                />
              ) : (
                <SaveOutlinedIcon
                  sx={{ cursor: "pointer", transform: "scale(0.9)" }}
                  onClick={updateNumBoards}
                />
              )}
            </Box>
            {/* <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  "& .MuiInput-input.Mui-disabled": {
                    color: "#000",
                    textFillColor: "#000",
                  },
                }}
              >
                Project Internal Name:{" "}
                <TextField
                  disabled={!editNumBoards.state}
                  variant="standard"
                  sx={{ width: "100px" }}
                  value={editNumBoards.boards}
                  InputProps={{ disableUnderline: !editNumBoards.state }}
                  onChange={(e) => {
                    setEditNumBoards({
                      ...editNumBoards,
                      boards: e.target.value,
                    });
                  }}
                />{" "}
              </Box>

              {!editNumBoards.state ? (
                <EditOutlinedIcon
                  sx={{ cursor: "pointer", transform: "scale(0.9)" }}
                  onClick={() => {
                    setEditNumBoards({
                      ...editNumBoards,
                      state: !editNumBoards.state,
                    });
                  }}
                />
              ) : (
                <SaveOutlinedIcon
                  sx={{ cursor: "pointer", transform: "scale(0.9)" }}
                  onClick={updateNumBoards}
                />
              )}
            </Box> */}

            {/* <Box>Total Number Of Boards: {project?.numberOfBoards}</Box> */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "80%",
                margin: "1.4rem 0rem",
              }}
            >
              <Box
                sx={{
                  fontSize: "1.8rem",
                  margin: "0.5rem 0rem 1rem 0rem",
                  fontWeight: "500",
                }}
              >
                Stage Settings
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "40%",
                }}
              >
                <Box sx={{ textDecoration: "underline", color: "black" }}>
                  <a
                    style={{ color: "black" }}
                    href={`/admin/bomExplorer/timeline/${id}`}
                    target="_blank"
                  >
                    {" "}
                    View TimeLine
                  </a>
                </Box>
                <Box
                  style={{ color: "black" }}
                  sx={{ textDecoration: "underline" }}
                >
                  <a
                    style={{ color: "black" }}
                    href={`/admin/bomExplorer/quotation/${id}`}
                    target="_blank"
                  >
                    View Quotation Sheet
                  </a>
                </Box>
              </Box>
            </Box>
            {/* <form
              onSubmit={(e) => {
                e.preDefault();
                stageSubmitHandler(e);
              }} */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TextField
                  id="outlined currentStage"
                  name="currentStage"
                  select
                  label="Select Stage"
                  value={temp || ""}
                  defaultValue={temp || ""}
                  // onChange={stageSettingsFormik.handleChange}
                  onChange={(e) => {
                    onChangeHandler(e);
                  }}
                  helperText="Please select your current stage"
                  sx={{ margin: "0.5rem 0rem 1rem 0rem" }}
                >
                  {stages.map((option) => (
                    <MenuItem
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "start",
                        flexDirection: "column",
                        padding: "1rem 0.5rem",
                      }}
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                {/* <TextField
                  id="outlined-basic comment"
                  name="comment"
                  value={stageSettingsFormik.values.comment}
                  onChange={stageSettingsFormik.handleChange}
                  label="Per stage comment addition"
                  variant="outlined"
                  sx={{ margin: "-2rem 0rem 0rem 1rem" }}
                /> */}
              </Box>
              {/* <Box sx={{ margin: "1rem 0rem" }}>
                <Button
                  sx={{ margin: "1rem" }}
                  variant="contained"
                  component="label"
                  onClick={openNewProjectModal}
                >
                  Document Upload per stage
                  {/* <input type="file" hidden /> */}
              {/* </Button> */}
              {/* </Box> */}
              <Box sx={{ margin: "1rem 0rem" }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Completion Date"
                    inputFormat="MM/dd/yyyy"
                    // value={stageSettingsFormik.values.date}
                    value={stageDate}
                    onChange={(value) => setStageDate(value)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                {/* <Button
                  sx={{ margin: "0.5rem 0rem 1rem 0rem" }}
                  variant="contained"
                  component="label"
                > */}
                {/* </Button> */}
              </Box>
              <Button
                margin="1rem"
                m={2}
                sx={{ margin: 1 }}
                variant="contained"
                onClick={stageSubmitHandler}
              >
                Save
              </Button>
              {/* </form> */}
            </Box>
            <Box
              sx={{
                width: "80%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    fontSize: "1.8rem",
                    margin: "2rem 0rem",
                    fontWeight: "500",
                  }}
                >
                  Quotation Creation Area
                </Box>
                <Button onClick={() => setAdd(!add)} variant="contained">
                  Add Component
                </Button>
              </Box>
            </Box>
            {add && (
              <Box>
                {JSON.stringify(componentQuoteObject, null, "\t")}
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    quotesSubmitHandler(e);
                  }}
                >
                  <Box
                    sx={{
                      width: "80%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          margin: "0.8rem 0rem",
                          fontSize: "1.2rem",
                          fontWeight: "800",
                        }}
                      >
                        Components Details
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <TextField
                        id="outlined-basic"
                        label="Component Name"
                        variant="outlined"
                        value={componentData.compName}
                        onChange={(e) =>
                          setComponentData({
                            ...componentData,
                            compName: e.target.value,
                          })
                        }
                        sx={{
                          width: "40%",
                          margin: "0rem 1rem 1rem 0rem",
                        }}
                      />
                      <TextField
                        id="outlined-basic"
                        label="Mounting Type"
                        variant="outlined"
                        value={componentData.mountingType}
                        onChange={(e) =>
                          setComponentData({
                            ...componentData,
                            mountingType: e.target.value,
                          })
                        }
                        sx={{
                          width: "40%",
                          margin: "0rem 1rem 1rem 0rem",
                        }}
                      />
                      <TextField
                        id="outlined-basic"
                        label="package"
                        variant="outlined"
                        value={componentData.package}
                        onChange={(e) =>
                          setComponentData({
                            ...componentData,
                            package: e.target.value,
                          })
                        }
                        sx={{
                          width: "40%",
                          margin: "0rem 1rem 1rem 0rem",
                        }}
                      />
                      <TextField
                        id="outlined-basic"
                        label="Make"
                        variant="outlined"
                        value={componentData.make}
                        onChange={(e) =>
                          setComponentData({
                            ...componentData,
                            make: e.target.value,
                          })
                        }
                        sx={{
                          width: "40%",
                          margin: "0rem 1rem 1rem 0rem",
                        }}
                      />
                      <TextField
                        id="outlined-basic"
                        label="designator"
                        variant="outlined"
                        value={componentData.designator}
                        onChange={(e) =>
                          setComponentData({
                            ...componentData,
                            designator: e.target.value,
                          })
                        }
                        sx={{
                          width: "40%",
                          margin: "0rem 1rem 1rem 0rem",
                        }}
                      />
                      <TextField
                        id="outlined-basic"
                        label="MPN"
                        value={componentData.mpn}
                        onChange={(e) => {
                          setComponentData({
                            ...componentData,
                            mpn: e.target.value,
                          });
                          MPNErrorHandler(e);
                        }}
                        error={isMPNError}
                        helperText={MPNHelperText}
                        variant="outlined"
                        sx={{
                          width: "40%",
                          margin: "0rem 1rem 1rem 0rem",
                        }}
                      />
                      <TextField
                        id="outlined-basic"
                        label="Quantity"
                        variant="outlined"
                        value={componentData.quantity}
                        onChange={(e) => {
                          setComponentData({
                            ...componentData,
                            quantity: e.target.value,
                          });
                          QuantityErrorHandler(e);
                        }}
                        error={isQuantityError}
                        helperText={QuantityHelperText}
                        sx={{
                          width: "40%",
                          margin: "0rem 1rem 1rem 0rem",
                        }}
                      />
                      <TextField
                        id="outlined-basic"
                        label="Component Description"
                        variant="outlined"
                        value={componentData.compDescription}
                        onChange={(e) =>
                          setComponentData({
                            ...componentData,
                            compDescription: e.target.value,
                          })
                        }
                        sx={{
                          width: "40%",
                          margin: "0rem 1rem 1rem 0rem",
                        }}
                        type="text"
                      />
                    </Box>
                    <Button
                      variant="contained"
                      disabled={disableComponentButton}
                      onClick={() => {
                        componentQuoteObject.component = componentData;
                        toggleComponentButton(true);
                        enqueueSnackbar("New component created", {
                          anchorOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          variant: "success",
                        });
                      }}
                    >
                      Save
                    </Button>
                  </Box>

                  <Box
                    sx={{
                      width: "80%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          margin: "1.8rem 0rem 1rem 0rem",
                          fontSize: "1.2rem",
                          fontWeight: "800",
                        }}
                      >
                        Vendor Details
                      </Box>
                      <AddCircleIcon
                        fontSize="large"
                        sx={{ color: "#303f9f", cursor: "pointer" }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <TextField
                        id="outlined-basic"
                        label="Vendor Name"
                        variant="outlined"
                        value={quoteData.vendorName}
                        onChange={(e) =>
                          setQuoteData({
                            ...quoteData,
                            vendorName: e.target.value,
                          })
                        }
                        sx={{
                          width: "40%",
                          margin: "0rem 1rem 1rem 0rem",
                        }}
                        type="text"
                      />
                      <TextField
                        id="outlined-basic"
                        label="Vendor Location"
                        variant="outlined"
                        value={quoteData.vendorLocation}
                        onChange={(e) =>
                          setQuoteData({
                            ...quoteData,
                            vendorLocation: e.target.value,
                          })
                        }
                        sx={{
                          width: "40%",
                          margin: "0rem 1rem 1rem 1rem",
                        }}
                        type="text"
                      />
                      <TextField
                        id="outlined-basic"
                        label="Lead Time"
                        variant="outlined"
                        value={quoteData.leadTime}
                        onChange={(e) => {
                          setQuoteData({
                            ...quoteData,
                            leadTime: e.target.value,
                          });
                          LeadtimeErrorHandler(e);
                        }}
                        error={isLeadTimeError}
                        helperText={LeadTimeHelperText}
                        sx={{
                          width: "40%",
                          margin: "0rem 1rem 1rem 0rem",
                        }}
                      />
                      <TextField
                        id="outlined-basic"
                        label="MOQ"
                        variant="outlined"
                        value={quoteData.mom}
                        onChange={(e) => {
                          setQuoteData({ ...quoteData, mom: e.target.value });
                          MOQErrorHandler(e);
                        }}
                        error={isMOQError}
                        helperText={MOQHelperText}
                        sx={{
                          width: "40%",
                          margin: "0rem 1rem 1rem 1rem",
                        }}
                      />
                      <TextField
                        id="outlined-basic"
                        label="Cost"
                        variant="outlined"
                        value={quoteData.cost}
                        onChange={(e) => {
                          setQuoteData({ ...quoteData, cost: e.target.value });
                          costErrorHandler(e);
                        }}
                        error={isCostError}
                        helperText={costHelperText}
                        sx={{
                          width: "40%",
                          margin: "0rem 1rem 1rem 0rem",
                        }}
                      />
                      <TextField
                        id="outlined-basic"
                        label="Cost with GST"
                        variant="outlined"
                        value={quoteData.totalCost}
                        onChange={(e) => {
                          setQuoteData({
                            ...quoteData,
                            totalCost: e.target.value,
                          });
                          GSTErrorHandler(e);
                        }}
                        sx={{
                          width: "40%",
                          margin: "0rem 1rem 1rem 1rem",
                        }}
                        error={isGSTError}
                        helperText={GSTHelperText}
                      />

                      <Box></Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        color: "#D3D3D3",
                      }}
                    >
                      <Button
                        variant="contained"
                        onClick={() => {
                          componentQuoteObject.quotes.push(quoteData);
                          clearQuotesDetails();
                          enqueueSnackbar("Quote added", {
                            anchorOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            variant: "success",
                          });
                        }}
                      >
                        Save
                      </Button>
                      <TextField
                        id="outlined currentStage"
                        select
                        label="isFav"
                        style={{
                          width: "20%",
                          margin: "0rem 10rem 1rem 1rem",
                        }}
                        value={quoteData.isFav}
                        onChange={(e) =>
                          setQuoteData({
                            ...quoteData,
                            isFav: e.target.value,
                          })
                        }
                        // helperText="Please select "
                        sx={{ margin: "0rem 0rem 1rem 1rem" }}
                      >
                        <MenuItem
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "start",
                            flexDirection: "column",
                            padding: "1rem 0.5rem",
                          }}
                          value={true}
                        >
                          Yes
                        </MenuItem>
                        <MenuItem
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "start",
                            flexDirection: "column",
                            padding: "1rem 0.5rem",
                          }}
                          value={false}
                        >
                          No
                        </MenuItem>
                      </TextField>
                    </Box>

                    <br />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      flexDirection: "column",
                    }}
                  >
                    <Button
                      sx={{ marginTop: "1rem" }}
                      variant="contained"
                      type="submit"
                    >
                      SUBMIT QUOTATIONS
                    </Button>
                  </Box>
                </form>
              </Box>
            )}
          </Box>
        </>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={DashboardLogo1} style={{ width: "150px" }} alt="logo"></img>
          <CircularProgress style={{ marginLeft: "20px" }}></CircularProgress>
        </Box>
      )}

      <Modal
        open={newProjectModalOpen}
        onClose={closeNewProjectModal}
        aria-labelledby="modal-modal-title"
      >
        <Box sx={modalStyles}>
          <p
            style={{ textAlign: "center" }}
            className="explorer-title"
            id="modal-modal-title"
          >
            Add File for Current Stage
          </p>
          <Paper>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
                flexDirection: "column",
                padding: "1rem",
              }}
            >
              {/* <Box sx={{display: 'flex'}}>
                            <p>Please make sure that the BOM excel is in the correct format.</p>
                            <br />
                            <p>Download sample</p>
                            <button onClick={() => props.history.push("/bomExplorer/project")}>Submit</button>
                        </Box> */}
              <DropzoneArea
                maxFileSize={100000000}
                init={() => {}}
                onChange={(files) => {
                  setFiles(files);
                }}
                id="documents"
                name="documents"
                // onChange={stageSettingsFormik.handleChange}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  sx={{ margin: "1rem" }}
                  variant="contained"
                  onClick={closeNewProjectModal}
                >
                  close
                </Button>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Modal>
    </Box>
  );
};

export default BomExplorerDetail;
