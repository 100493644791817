import React, { useState, useEffect } from "react";
import { axiosInstance } from "../utils/axiosInterceptor";
import DashboardStats from "./DashboardStats";
import DashboardTimeLine from "./DashboardTimeLine";
import DashboardLogo1 from "../assests/elecbitsLogo.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Container, Row, Col } from "react-bootstrap";
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Button from '@mui/material/Button';

export default function ProjectTimeLine(props) {
  const [loading, set_loading] = useState(true);
  const [prof_loading, setLoading] = useState(true);
  const [profile_data, set_profile] = useState(null);
  const [project_info, set_project] = useState(null);
  const [activeStage, setActiveStage] = useState(null);
  const [totalPaidAmount, setTotalPaid] = useState(0);
  const [totalPendingAmount, setToalPending] = useState(0);

  let fetchProject = (projectId) => {
    set_loading(true);
    axiosInstance
      .get(`/projects/getProject/${projectId}`)
      .then((resp) => {
        console.log(resp.data);

        set_project(resp.data);

        let proj = resp.data;
        document.title = "Elecbits" + " | " + proj.projectName;
        let active_Stage = proj.activeStageNumber;
        let stage = proj.stageInfo;
        console.log(proj, stage);
        let totalPaid = 0;
        let totalPending = 0;
        let i = 0;
        for (i = 0; i < stage.length; ++i) {
          if (stage[i].status&&stage[i].status.toString() == "active") {
            setActiveStage(stage[i]);
          }
        }
        if (proj.billing) {
          for (let x in proj.billing) {
            if (proj.billing[x].type.toString() == "Pending Amount") {
              totalPending = proj.billing[x].amount;
            } else if (proj.billing[x].type.toString() == "Paid Amount") {
              totalPaid = proj.billing[x].amount;
            }
          }
        }

        setTotalPaid(totalPaid);
        setToalPending(totalPending);
        set_loading(false);
      })
      .catch((err) => {
        console.log(err);
        set_loading(false);
      });
  };

  let fetchProfile = () => {
    setLoading(true);
    axiosInstance
      .get("/users/getProfile")
      .then((resp) => {
        set_profile(resp.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    document.title = "Elecbits";
    let projectId = props.history.location.pathname.split("/")[2];
    fetchProfile();
    fetchProject(projectId);
  }, []);

  console.log(loading);
  return (
    <div>
      {!loading && !prof_loading && project_info && profile_data ? (
        <>
          <Container>
            <Row>
              <div style={{paddingTop:"20px"}} className="project_name_top">
                📱 {project_info.projectName}
              </div>
            </Row>
          </Container>
          
          <DashboardStats
            profile_data={profile_data}
            project_data={project_info}
            activeStage={activeStage}
            totalPaidAmount={totalPaidAmount}
            totalPendingAmount={totalPendingAmount}
            allUsers={props.allUsers}
            fetchProject={fetchProject}
            fetchProfile={fetchProfile}
            history={props.history}
          ></DashboardStats>

          <DashboardTimeLine
            project_data={project_info}
            activeStage={activeStage}
          ></DashboardTimeLine>
        </>
      ) : (
        <div className="time_wrap">
          <img src={DashboardLogo1} style={{ width: "150px" }}></img>
          <CircularProgress style={{ marginLeft: "20px" }}></CircularProgress>
        </div>
      )}
    </div>
  );
}
