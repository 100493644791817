import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { axiosInstance } from "../utils/axiosInterceptor";
import { Container, Row, Col, Modal } from "react-bootstrap";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Backdrop from "@mui/material/Backdrop";
import IconButton from "@mui/material/IconButton";
import TextField from "@material-ui/core/TextField";
import DashboardLogo1 from "../assests/elecbitsLogo.svg";
import CircularProgress from "@material-ui/core/CircularProgress";

function createRow(product, price, quantity, subTotal) {
  return { product, price, quantity, subTotal };
}
let all_quantity = {};
export default function AdminBilling() {
  let history = useHistory();
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowsToRender, setRowsToRender] = useState(null);
  const [subTotal, setSubTotal] = useState(0);

  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(value);

  const getQPricing = (quantity, prod) => {
    console.log(prod);
    let obj = prod.quantity_wise_pricing[0];
    let perUnit = -1;
    let maxRangeEnd = -1;
    let sPrice = -1;

    for (let key in obj) {
      let x = obj[key];
      quantity = parseInt(quantity);

      if (x.rangeEnd > maxRangeEnd) {
        sPrice = x.amount;
        maxRangeEnd = x.rangeEnd;
      }

      if (
        quantity >= parseInt(x.rangeStart) &&
        quantity <= parseInt(x.rangeEnd)
      ) {
        perUnit = x.amount;
        break;
      }
    }

    if (perUnit == -1) {
      return sPrice * quantity;
    } else {
      return perUnit * quantity;
    }
  };

  const updateRowsToRender = (cart_items) => {
    let resRows = [];
    for (let i = 0; i < cart_items.length; ++i) {
      let item = cart_items[i];
      let quantity = item.quantity;
      let totalPrice = item.newPrice;
      let price = totalPrice / quantity;

      all_quantity[i] = quantity;
      console.log(item);

      resRows.push(
        createRow(
          <div className="cart_pd">
            <img
              src={item.product.product_images[0].location}
              alt=""
              style={{ marginRight: "10px" }}
            />
            {item.product.short_name}
          </div>,
          numberFormat(price),
          quantity,
          numberFormat(item.newPrice)
        )
      );
    }
    setRowsToRender(resRows);
    setLoading(false);
  };

  const getProducts = (cartId) => {
    setLoading(true);
    axiosInstance
      .get(`/products/getCartProducts/${cartId}`)
      .then((resp) => {
        console.log(resp.data.response);
        if (
          resp.data.response.products &&
          resp.data.response.products.length == 0
        ) {
          console.log("x");
          setCartItems([]);
          updateRowsToRender([]);
          getSumOfSubTotals([]);
        } else {
          if (resp.data.response != null) {
            setCartItems(resp.data.response.products);
            updateRowsToRender(resp.data.response.products);
            getSumOfSubTotals(resp.data.response.products);
          } else {
            setCartItems([]);
            updateRowsToRender([]);
            getSumOfSubTotals([]);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const getSumOfSubTotals = (cartItems) => {
    let totalSub = 0;
    for (let i = 0; i < cartItems.length; ++i) {
      let item = cartItems[i];
      totalSub += item.newPrice;
    }
    setSubTotal(totalSub);
  };
  useEffect(() => {
    getProducts(history.location.pathname.split("/")[3]);
  }, []);
  return (
    <div className="pcb-cart">
      {!loading && cartItems && rowsToRender ? (
        <Container>
          <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
            <Col>
              <div>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Product</TableCell>
                        <TableCell align="left">Price / Unit</TableCell>
                        <TableCell align="left">Quantity</TableCell>
                        <TableCell align="left">Sub Total</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rowsToRender.map((row) => {
                        return (
                          <TableRow key={row._id}>
                            <TableCell align="left">{row.product}</TableCell>
                            <TableCell align="left">{row.price}</TableCell>
                            <TableCell align="left">{row.quantity}</TableCell>
                            <TableCell align="left">{row.subTotal}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                  <div className="cart_bottom">
                    <div className="proceed_cart">
                      <div className="cart_total">
                        <h5>Total Price: {numberFormat(subTotal)}</h5>
                      </div>
                    </div>
                  </div>
                </TableContainer>
              </div>
            </Col>
          </Row>
        </Container>
      ) : (
        <div className="time_wrap">
          <img src={DashboardLogo1} style={{ width: "150px" }} alt="logo" />
          <CircularProgress style={{ marginLeft: "20px" }}></CircularProgress>
        </div>
      )}
    </div>
  );
}
