import {
  styled,
  Box,
  Grid,
  TextField,
  Select,
  Slider,
  Button,
} from "@mui/material";

// import { Select } from "@material-ui/core";

export const PageContainer = styled(Box)(() => ({
  width: "100vw",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
}));

export const TimelineContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  flex: 1,
  padding: "50px 50px",
  fontFamily: "Poppins",
  backgroundColor: "#F4F7FE",
  overflowY: "auto",

  "& .title": {
    marginTop: "30px",
    fontWeight: 600,
  },
  "& .sub-title": {
    marginTop: "10px",
    color: "#8B8B8B",
    marginBottom: "50px",
  },
  "& .container1": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
    width: "100",
    marginTop: "20px",
  },

  "& .projectName": {
    fontSize: "1.4em",
    fontWeight: 600,
  },
  "& .projectTime": {
    color: "#8B8B8B",
    fontSize: "0.9em",
    marginTop: "10px",
  },
  "& .button-container": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100",
    marginTop: "20px",
  },

  [theme.breakpoints.down("md")]: {
    padding: "20px 20px",
    "& .container1": {
      flexDirection: "column",
      alignItems: "start",
    },
    "& .button-container": {
      flexDirection: "column",
      alignItems: "start",
      gap: "10px",
    },
    "& .btn-cont-2": {
      display: "flex",
      flexDirection: "column",
      gap: "10px                                                                                                                                                                                                                                                                         ",
    },
  },
}));

export const CustomButton = styled(Button)(({ fill, disabled }) =>
  disabled
    ? {
        "&&&": {
          backgroundColor: fill ? "#caccd0" : "#transparent",
          color: fill ? "#fff" : "#caccd0",
          border: "2px solid #caccd0",
          borderRadius: "4px",
          marginRight: "20px",
        },
        "& svg": {
          transform: "scale(0.8) !important",
          marginRight: "10px !important",
        },
      }
    : {
        "&&&": {
          backgroundColor: fill ? "#0702FC" : "#transparent",
          color: fill ? "#fff" : "#0702FC",
          border: "2px solid #0702FC",
          borderRadius: "4px",
          marginRight: "20px",
        },
        "& svg": {
          transform: "scale(0.8) !important",
          marginRight: "10px !important",
        },
      }
);

export const CustomSelect = styled(Select)(() => ({
  width: "100%",

  backgroundColor: "transparent",
  borderRadius: 8,
  padding: "5px",
  "& .MuiSelect-select": {
    padding: "5px",
  },
  "& .MuiInputBase-root.MuiOutlinedInput-root": {
    border: "2px solid #8B8B8B4D",
    borderRadius: "8px",
  },
  "& fieldset": {
    // border:'none',
    border: "2px solid #8B8B8B4D",
    borderRadius: "8px",
  },
  "& legend": {
    width: "unset",
  },
}));
export const TimeLineStage = styled(Box)(() => ({
  "&&&": {
    borderBottom: "2px solid #0702FC",
    padding: "8px",
    borderRadius: "5px",
    margin: "0px 20px 0px 0px",
    color: "#0702FC",
    fontWeight: "600",
  },
}));

const getPriorityColor = (priority) => {
  if (priority == "low") {
    return "#AD90FF";
  }
  if (priority == "medium") {
    return "#AD90FF";
  }
  if (priority == "high") {
    return "#AD90FF";
  }
  if (priority == "very high") {
    return "#AD90FF";
  }

};

const getPriorityBorder = (priority) => {
      if (priority == "low") {
    return "0.5px solid #6943D4";
  }
  if (priority == "medium") {
    return "0.5px solid #6943D4";
  }
  if (priority == "high") {
    return "0.5px solid #6943D4";
  }
  if (priority == "very high") {
    return "0.5px solid #6943D4";
  }
};

export const PriorityBox = styled(Box)(({ priority }) => ({
  width: "fit-content",
  height: "fit-content",
  backgroundColor: getPriorityColor(priority?.toLowerCase()),
  border: getPriorityBorder(priority?.toLowerCase()),
    borderRadius: "10px",
  fontSize: "0.8em",
  padding: "5px 12px",
    boxShadow: "1px 1px 6px #C4AEFF",
    color: "#000000"

}));
