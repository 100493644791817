import React from "react";
import { Box, styled } from "@mui/material";
import { Link } from "react-router-dom";

const OuterContainer = styled(Link)(() => ({
  flex: 1,
  borderRadius: 15,
  padding: 3,
  background:
    "linear-gradient(90deg, #F67C1B 20%, #E95252 40%,#E95252 60%,#0275FC 80%,#0275FC 100%)",
}));

const MainContainer = styled(Box)(({theme}) => ({
  backgroundColor: "#fff",
  width: "100%",
  height: "100%",
  borderRadius: 13,
  padding: '10px 20px',
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  "& .img-container": {
    width: "30%",
    display: "grid",
    placeItems: "center",
  },
  "& .text-continer": {
    width: "70%",
  },
  "& .heading": {
    fontSize: "1.4em",
    fontWeight: 600,
    color: "#000",
  },
  "& .sub-heading": {
    fontSize: "0.8em",
    marginTop: "10px",
    color: "#8B8B8B",
  },
  "& img": {
    width: "80%",
    height: "80%",
  },
  [theme.breakpoints.down('md')]:{
    '& .heading':{
      fontSize:'1.1em'
    },
    "& img": {
      width: "70%",
      height: "70%",
    },
  }
}));

const ExplorerCard = ({ title, subtitle, imageName, link }) => {
  return (
    <OuterContainer to={link}>
      <MainContainer>
        <div className="img-container">
          <img src={require(`../../assests/${imageName}`).default} alt="" />
        </div>
        <div className="text-continer">
          <div className="heading">{title}</div>
          {/* <div className="sub-heading">
            {subtitle}
          </div> */}
        </div>
      </MainContainer>
    </OuterContainer>
  );
};

export default ExplorerCard;
