import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ElecbitsLogo from '../assests/elecbitsLogo.svg';
import './SupplierOnBoarding.css';
import { onBoardingAction } from './supplierActions';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { useHistory } from 'react-router';

import { axiosInstance } from '../utils/axiosInterceptor';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  Checkbox,
  // MenuItem,
  ListItemText,
} from '@mui/material';
// import ListItemText from "@material-ui/core/ListItemText";
// import Checkbox from "@material-ui/core/Checkbox";
// import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input';

const servicesList = [
  'PCB Fabrication',
  'BOM Procurement',
  '3D/Enclosure Services',
];

const SupplierOnBoarding = (props) => {
  const history = useHistory();
  useEffect(async () => {
    const res = await axiosInstance.get('/users/getProfile');
    if (!res.data.data.phoneNumber) {
      history.push('/supplier/onBoarding');
    }
  }, []);

  const [data, setData] = useState({
    fullName: '',
    companyName: '',
    phoneNumber: '',
    services: [],
    address: '',
    gstNum: '',
  });
  const { enqueueSnackbar } = useSnackbar();
  const onChangeHandler = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (!isValidPhoneNumber(data.phoneNumber)) {
      enqueueSnackbar('Please enter a valid phone number', {
        variant: 'error',
      });
      return;
    }
    console.log(data);
    onBoardingAction(data, enqueueSnackbar, props);
  };

  return (
    <div className="main">
      <div className="top">
        <img src={ElecbitsLogo} />
      </div>
      <div className="details">
        <p className="title">You are almost here!</p>

        <p className="sub-title">
          Please share the following details to help us serve you better
        </p>
        <form className="supplier_form" onSubmit={onSubmitHandler}>
          <Grid
            container
            spacing={4}
            sx={{ '& .MuiGrid-item': { padding: '5px 40px' } }}
            className="textfields"
          >
            <Grid item md={6}>
              <TextField
                variant="outlined"
                className="text"
                label="Full Name"
                name="fullName"
                onChange={(e) => onChangeHandler(e)}
                required
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                onChange={(e) => onChangeHandler(e)}
                variant="outlined"
                className="text"
                name="companyName"
                label="Company Name"
                required
              />
            </Grid>
            <Grid className="phoneGrid" item md={6}>
              {/* <TextField
                onChange={(e) => onChangeHandler(e)}
                variant="outlined"
                className="text"
                name="phoneNumber"
                label="Phone Number"
                required
              /> */}
              <PhoneInput
                style={{
                  border: '1px solid #c4c4c4',
                  padding: '1rem 0.4rem',
                  borderRadius: '0.2rem',
                }}
                placeholder="Enter phone number"
                className="text"
                name="phoneNumber"
                label="Phone Number"
                onChange={(e) => setData({ ...data, phoneNumber: e })}
                required
              />
            </Grid>
            <Grid item md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Services Provided
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  multiple
                  label="Services Interested In"
                  variant="outlined"
                  value={data.services}
                  name="services"
                  style={{
                    width: '100%',
                  }}
                  onChange={(e) => onChangeHandler(e)}
                  required
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>Placeholder</em>;
                    }
                    return selected.join(', ');
                  }}
                >
                  {servicesList.map((service) => {
                    return (
                      <MenuItem value={service}>
                        <Checkbox
                          color="primary"
                          checked={data.services.indexOf(service) > -1}
                        />
                        <ListItemText primary={service} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <TextField
                onChange={(e) => onChangeHandler(e)}
                variant="outlined"
                className="text"
                name="address"
                label="City"
                required
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                onChange={(e) => onChangeHandler(e)}
                variant="outlined"
                className="text"
                name="gstNum"
                label="GST No."
                required
              />
            </Grid>
          </Grid>

          <Button type="submit" className="button">
            Create account
          </Button>
        </form>
      </div>
    </div>
  );
};

export default SupplierOnBoarding;
