export const stages = [
  {
    stage: "Under Review",
    desc: "Kudos to you, you’ve found the best place to get the components that you need to turn your imaginary project into a working reality. For this stage, we take the task of scrutinizing each component in the given BOM for cheaper and better alternative components which might also help in acquiring needed certifications for various regions.",
  },
  {
    stage: "Quotation Optimization",
    desc: "Once the BOM is finalized, it’s time to ring the bell for vendors supplying the components, at this moment, 100s of vendors for each component are bidding their lowest quotations to be a part of your project.",
  },
  {
    stage: "Quotation Uploaded",
    desc: "Time for vendors to present their lowest bid is over, we would now present you with various quotation combinations arranged according to the delivery timeline, this will help you decide the group of vendors to source from according to the timeline of your preference.",
  },
  {
    stage: "Order Review",
    desc: "We are going through your order, carefully examining each component and vendor that has been selected before placing an order with the vendors",
  },
  {
    stage: "Order Accepted",
    desc: "Congratulations, your order is now officially active. The vendors have confirmed receiving the order.",
  },
  {
    stage: "Order in process",
    desc: "Your order is now officially active. The vendors have confirmed that your project is the one they’re currently working on and will be done within the given timeline.",
  },
  {
    stage: "Shipment Packed",
    desc: "At this moment, the vendor has confirmed that the components are packed suitably and the package is awaiting pickup by our delivery partner.",
  },
  {
    stage: "Waiting For Pickup",
    desc: "The package is ready to ship and will be picked up by the transit partner soon.",
  },
  {
    stage: "In Transit",
    desc: "The package has been picked up by our delivery partner and is on its way.",
  },
  {
    stage: "Out For Delivery",
    desc: "The delivery partner is out for the delivery of the package. It shall be at its destination soon.",
  },
  {
    stage: "Delivered",
    desc: "Pleased to inform you that the components have reached their destination. If it’s delivered to you, please proceed to inspect the package and its contents as suggested on our website/platform and report any issues with them. If it’s delivered to us, then it’s our job to inspect and rectify any issues with order mismatch or any other issue with the order.",
  },
];

export const adminStages = [
  { value: "Under Review", label: "Under Review" },
  { value: "Quotation Optimization", label: "Quotation Optimization" },
  { value: "Quotation Uploaded", label: "Quotation Uploaded" },
  { value: "Order Review", label: "Order Review" },
  { value: "Order Accepted", label: "Order Accepted" },
  { value: "Order in process", label: "Order in process" },
  { value: "Shipment Packed", label: "Shipment Packed" },
  { value: "Waiting for Pickup", label: "Waiting for Pickup" },
  { value: "In Transit", label: "In Transit" },
  { value: "Out for Delivery", label: "Out for Delivery" },
  { value: "Successfully Delivered", label: "Successfully Delivered" },
];