import React, { useState } from "react";
import Button from "@mui/material/Button";
import { Avatar } from "@mui/material";
import { Paper, Modal } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import { DropzoneArea } from "material-ui-dropzone";
import DownloadIcon from "@mui/icons-material/Download";
import moment from "moment";

import ArticleIcon from "@mui/icons-material/Article";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import { axiosInstance } from "../utils/axiosInterceptor";
import { SnackbarProvider, useSnackbar } from "notistack";
import { styled, Box, useTheme } from "@mui/material";

const TimelineBox = styled(Box)(({ theme, isSuccess }) => ({
  display: "flex",
  justifyContent: "space-evenly",
  alignItems: "center",
  boxShadow: "2px 2px 10px #D3D3D3",
  width: "80%",
  padding: "2rem",
  marginBottom: "2rem",
  height: "auto",
  backgroundColor: isSuccess ? "#dbdbdb" : "",
  [theme.breakpoints.down("md")]: {
    width: "75vw",
    flexDirection: "column",
    alignItems: "start",
    gap: 10,
    padding: "1rem",
  },
}));

const TimelineInnerBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  flexDirection: "column",
  width: "80%",
  maxWidth: "80%",
  marginRight: "0.2rem",
}));

const modalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  backgroundColor: "#fff",
  boxShadow: 24,
  p: 4,
  borderRadius: "3px",
};

const hyperlink = (textContent) => {
  let a = textContent.replace(
    /(https?:\/\/[^\s]+)/g,
    (href) => `<a href="${href}" target="_blank">${href}</a>`
  );
  return { __html: a };
};

const TimelineComponent = ({
  number,
  title,
  desc,
  isSuccess,
  viewDetails,
  projectName,
  date,
  documents,
  id,
  remarks,
  count,
  setCount,
  Icon,
  fromFab,
  currentStage,
  isAdmin,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    backgroundColor: "#fff",
    height: "80vh",
    boxShadow: 24,
    p: 4,
    borderRadius: "3px",
  };

  const { enqueueSnackbar } = useSnackbar();

  const [newProjectModalOpen, setNewProjectModalOpen] = useState(false);
  const [fileUploadModal, setOpenFileUploadModal] = useState(false);

  const openNewProjectModal = () => setNewProjectModalOpen(true);
  const closeNewProjectModal = () => setNewProjectModalOpen(false);

  const openFileUploadModal = () => setOpenFileUploadModal(true);
  const closeFileUploadModal = () => setOpenFileUploadModal(false);
  const [comment, setComment] = useState("");
  const { breakpoints } = useTheme();
  React.useEffect(() => {
    console.log(remarks);
  }, [remarks]);
  const [files, setFiles] = useState([]);
  const nums = [1, 2, 3, 4, 5, 6, 7, 8];
  console.log(remarks);

  const fileSubmitHandler = async (number) => {
    let formData = new FormData();
    formData.append("currentStage", currentStage);
    formData.append("something", "somethihg");
    formData.append("stageNumber", number - 1);
    for (let i = 0; i < files.length; i++) {
      formData.append("fab", files[i]);
    }
    const token = localStorage.getItem("token");
    if (fromFab) {
      const res = await axiosInstance.post(
        `/fab/admin/addFile/${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "multipart/form-data",
          },
        }
      );
      console.log(res);
    } else {
      await axiosInstance.post(`/bom/admin/addFile/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "multipart/form-data",
        },
      });
    }
    closeFileUploadModal();
    closeNewProjectModal();
  };
  const submitHandler = async (number) => {
    const token = localStorage.getItem("token");
    const body = {
      comment,
      stageNumber: number - 1,
    };
    if (comment.length == 0) {
      enqueueSnackbar("please add a comment!", { variant: "error" });
      return;
    }
    try {
      console.log(comment);

      if (fromFab) {
        const res = await axiosInstance.post(
          `/fab/client/addComment/${id}`,
          JSON.stringify(body),
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-type": "application/json",
            },
          }
        );
      } else {
        const res = await axiosInstance.post(
          `/bom/client/addComment/${id}`,
          JSON.stringify(body),
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-type": "application/json",
            },
          }
        );
      }
      setCount(count + 1);
      enqueueSnackbar("comment added successfully!", { variant: "success" });
      setComment("");
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Box>
      <TimelineBox isSuccess={isSuccess}>
        <TimelineInnerBox>
          <Box sx={{ fontWeight: "500", fontSize: "1.4rem" }}>
            {number}. {title}
          </Box>
          <Box
            sx={{
              width: "80%",
              maxWidth: "80%",
              marginLeft: "1.4rem",
              marginBottom: "0.5rem",
              [breakpoints.down("md")]: {
                marginLeft: "0rem",
                width: "100%",
                maxWidth: "100%",
              },
            }}
          >
            {desc}
          </Box>
          {Icon}
        </TimelineInnerBox>
        <Box>
          <Button
            onClick={openNewProjectModal}
            variant="outlined"
            disabled={!viewDetails}
          >
            View Details
          </Button>
        </Box>
      </TimelineBox>
      <Modal
        open={newProjectModalOpen}
        onClose={closeNewProjectModal}
        aria-labelledby="modal-modal-title"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            backgroundColor: "#fff",
            height: "80vh",
            boxShadow: 24,
            p: 4,
            borderRadius: "3px",
            [breakpoints.down("md")]: {
              overflow: "scroll",
              p: 1.5,
              width: "90%",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              [breakpoints.down("md")]: {
                flexDirection: "column",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
                width: "50%",
                [breakpoints.down("md")]: {
                  width: "100%",
                },
              }}
            >
              <Box
                sx={{
                  fontSize: "1.8rem",
                  fontWeight: "800",
                  marginBottom: "0.5rem",
                }}
              >
                {projectName} - {title}
              </Box>
              <Box
                sx={{
                  marginBottom: "1rem",
                  fontSize: "1.2rem",
                  color: "gray",
                  width: "80%",
                }}
              ></Box>
              <Box
                sx={{
                  fontSize: "1.1rem",
                  marginBottom: "0.5rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <QueryBuilderIcon />
                <Box sx={{ marginLeft: "0.2rem" }}>{title}</Box>
              </Box>
              <Box
                sx={{
                  fontWeight: "800",
                  fontSize: "1.5rem",
                  marginBottom: "1rem",
                }}
              >
                {moment(date).format("DD/MM/YYYY")}
              </Box>
              {isAdmin && (
                <Box sx={{ margin: "1rem 0rem" }}>
                  <Button
                    sx={{ margin: "1rem" }}
                    variant="contained"
                    component="label"
                    onClick={openFileUploadModal}
                  >
                    Document Upload per stage
                    {/* <input type="file" hidden /> */}
                  </Button>
                </Box>
              )}
              <Box
                sx={{
                  fontSize: "1.2rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "gray",
                }}
              >
                <ArticleIcon />
                <Box> Documents</Box>
              </Box>
              <Box
                sx={{
                  height: "40vh",
                  overflowY: "scroll",
                  maxHeight: "inherit",
                  width: "100%",
                  [breakpoints.down("md")]: {
                    height: "fit-content",
                    marginBottom: "20px",
                  },
                }}
              >
                {documents?.map((docs) => {
                  return (
                    <Box
                      sx={{
                        width: "90%",
                        margin: "0.8rem",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        padding: "0.8rem",
                        color: "black",
                      }}
                    >
                      <Box>{docs.originalName}</Box>
                      <a
                        style={{ textDecoration: "none", color: "gray" }}
                        href={docs.location}
                      >
                        <DownloadIcon />
                      </a>
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Box
              sx={{
                width: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
                height: "70vh",
                [breakpoints.down("md")]: {
                  width: "100%",
                },
              }}
            >
              <Box sx={{ fontSize: "1.5rem", fontWeight: "600" }}>
                Add Comment
              </Box>
              <TextField
                id="outlined-basic"
                label="Comment..."
                variant="outlined"
                sx={{
                  width: "90%",
                  margin: "1rem 0rem",
                }}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                multiline
                rows={3}
              />
              <Button onClick={() => submitHandler(number)} variant="contained">
                Add comment
              </Button>
              <Box sx={{ margin: "1rem", fontSize: "1.4rem" }}>Comments</Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                  width: "100%",
                  alignItems: "flex-start",
                  height: "70vh",
                  maxHeight: "70vh",
                  overflowY: "scroll",
                  [breakpoints.down("md")]: {
                    overflow: "visible",
                    height: "fit-content",
                  },
                }}
              >
                {remarks
                  ?.slice(0)
                  .reverse()
                  .map((remark) => {
                    console.log(remark);
                    return remark.comment ? (
                      <Box
                        sx={{
                          height: "auto",
                          boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          margin: "1rem",
                          width: "90%",
                          maxWidth: "90%",
                          borderRadius: "5px",
                          whiteSpace: "pre-line",
                          [breakpoints.down("md")]: {
                            width: "100%",
                            margin: "0.2rem",
                            fontSize: "0.9em",
                          },
                        }}
                      >
                        <Avatar
                          src={remark.userProfilePic}
                          alt={remark.userName}
                          sx={{
                            marginLeft: "1rem",
                            transform: "scale(1.3)",
                            [breakpoints.down("md")]: {
                              transform: "scale(1)",
                            },
                          }}
                        >
                          {!remark.userProfilePic
                            ? remark.userName[0].toUpperCase()
                            : null}
                        </Avatar>
                        {/* <img
                            src={remark.userProfilePic}
                            style={{
                              width: "4rem",
                              height: "4rem",
                              borderRadius: "50%",
                              margin: "0.4rem",
                            }}
                            alt=""
                          /> */}

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "flex-start",
                            flexDirection: "column",
                            padding: "0.5rem",
                            width: "80%",
                            maxWidth: "80%",
                          }}
                        >
                          <Box>{remark.userName}</Box>
                          <Box sx={{ fontSize: "0.8rem" }}>
                            {moment(remark.date).format("MMMM Do YYYY, h:mm a")}
                          </Box>
                          <div
                            style={{
                              maxWidth: "100%",
                              overflowWrap: "break-word",
                            }}
                            dangerouslySetInnerHTML={hyperlink(remark.comment)}
                          />
                          {/* {remark.comment.replace(
                              /(https?:\/\/[^\s]+)/g,
                              (href) => `<a href="${href}">${href}</a>`
                            )} */}
                          {/* </Box> */}
                        </Box>
                      </Box>
                    ) : (
                      <></>
                    );
                  })}
              </Box>

              <Modal
                open={fileUploadModal}
                onClose={closeFileUploadModal}
                aria-labelledby="modal-modal-title"
              >
                <Box sx={modalStyles}>
                  <p
                    style={{ textAlign: "center" }}
                    className="explorer-title"
                    id="modal-modal-title"
                  >
                    Add New File
                  </p>
                  <Paper>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-end",
                        flexDirection: "column",
                        padding: "1rem",
                      }}
                    >
                      {/* <Box sx={{display: 'flex'}}>
                                    <p>Please make sure that the BOM excel is in the correct format.</p>
                                    <br />
                                    <p>Download sample</p>
                                    <button onClick={() => props.history.push("/bomExplorer/project")}>Submit</button>
                                </Box> */}
                      <DropzoneArea
                        maxFileSize={100000000}
                        init={() => {}}
                        onChange={(files) => {
                          setFiles(files);
                        }}
                        id="documents"
                        name="documents"
                        // onChange={stageSettingsFormik.handleChange}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          // width: "100%"
                        }}
                      >
                        <Button
                          sx={{ margin: "1rem" }}
                          style={{ marginRight: "1.5rem" }}
                          variant="contained"
                          onClick={() => fileSubmitHandler(number)}
                        >
                          submit
                        </Button>
                        <Button
                          sx={{ margin: "1rem" }}
                          variant="contained"
                          onClick={closeFileUploadModal}
                        >
                          close
                        </Button>
                      </Box>
                    </Box>
                  </Paper>
                </Box>
              </Modal>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
export default TimelineComponent;
