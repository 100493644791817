import React, { useEffect, useState } from 'react';
import ElecbitsLogo from '../assests/dashboardLogo.svg';
import elecbitsLogoWhite from '../assests/elecbitsLogoWhite.svg';
import './market.css';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Popper from '@material-ui/core/Popper';
import IconButton from '@mui/material/IconButton';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

export default function PCBMarketHeader(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isSrchVis, setSrchVis] = useState(false);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  useEffect(() => {
    console.log(props.profile_data);
  }, []);
  return (
    <div className="pcb_market_header">
      <Popover
        id={id}
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
      >
        <div className="acct_sett">
          <div>Your account</div>

          <div className="liprop">
            <Link to="/profile">Your account</Link>
          </div>
          <div className="liprop">
            <Link to="/pcbMarket/orderPage">Your Orders</Link>
          </div>
          <div
            className="liprop_logout"
            onClick={() => {
              localStorage.clear();
              props.history.push('/');
              props.toggleLoggedIn();
            }}
          >
            Sign Out
          </div>
        </div>
      </Popover>
      <div className={isSrchVis ? 'logo_imgasd invisibe' : 'logo_imgasd'}>
        <Link to="/">
          <img src={elecbitsLogoWhite} style={{ width: '160px' }}></img>
        </Link>
      </div>
      {/* <div className={(isSrchVis)?"pcb_market_search visible":"pcb_market_search"}>
        <IconButton onClick={()=>{
          setSrchVis(false);
        }} id="ardsc" style={{color:'white',marginLeft:'3px',marginTop:'3px'}} className="srch_icn_mod">
           <ArrowBack></ArrowBack>
        </IconButton>
        <input type="text" placeholder="Search for products ..."></input>
        <div className="srch_icn">
          <SearchIcon></SearchIcon>
        </div>
      </div> */}

      <div
        className={
          isSrchVis
            ? 'right_pcbHeader_content invisible'
            : 'right_pcbHeader_content'
        }
      >
        {/* <div className="icnSearch_btn">
          <IconButton onClick={()=>{
            setSrchVis(true);
          }}>
            <SearchIcon></SearchIcon>
          </IconButton>
        </div> */}

        <div aria-describedby={id} id="mmacc" onClick={handlePopoverOpen}>
          <div>Hello, {props.profile_data?.firstName}</div>
          <div id="macc">
            {' '}
            My account <ArrowDropDownIcon></ArrowDropDownIcon>
          </div>
        </div>
        <div id="vertical_divider"></div>

        <div className="header__cart">
          <Link to="/pcbMarket/cart">
            <div>
              <ShoppingCartIcon></ShoppingCartIcon>
            </div>
            <div>Cart</div>
          </Link>
        </div>
      </div>
    </div>
  );
}
