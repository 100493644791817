import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { axiosInstance } from "../../utils/axiosInterceptor";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import "./OrderPage.css";
import DashboardLogo1 from "../../assests/elecbitsLogo.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import moment from "moment";
const OrderPage = () => {
  const [orderedItem, setOrderedItem] = useState([]);
  const [loading, setLoading] = useState(false);

  const [detailsModal, setDetailsModal] = useState([]);
  const [open, setOpen] = useState(false);

  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(value);

  const handleClose = () => {
    setOpen(false);
  };

  const getOrderedItems = () => {
    setLoading(true);
    axiosInstance
      .get("/products/getOrders")
      .then((resp) => {
        console.log(resp.data);
        setLoading(false);
        setOrderedItem(resp.data.response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOrderedItems();
  }, []);
  useEffect(() => {
    console.log(orderedItem);
  }, [orderedItem]);

  const handleShow = (id) => {
    // console.log('clicked', id)
    let item = orderedItem;
    let itemDetails = [];

    for (let i = 0; i < item.length; ++i) {
      //console.log(item[i]._id)
      if (item[i]._id === id) {
        itemDetails = item[i];
        setDetailsModal(itemDetails);
        console.log("founded", itemDetails);
      }
    }
    setOpen(true);
  };

  console.log(detailsModal);

  //console.log(orderedItem)

  const modalCart = detailsModal.cart;
  //console.log(modalCart)

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <h5> Order Status: {detailsModal?.orderStatus}</h5>
          <p className="order_status"> {detailsModal?.orderMessage}</p>
          <p className="order_ship_time">
            {" "}
            {detailsModal?.shippingTime ? (
              <>
                <span>Order Will be shipped within</span>{" "}
                {detailsModal?.shippingTime}
              </>
            ) : (
              " "
            )}
          </p>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div className="order_details">
              {modalCart?.map((singleItem) => (
                <div className="order_status_modal" key={singleItem._id}>
                  <img
                    src={singleItem?.product?.product_images[0]?.location}
                    alt=""
                  />

                  <div className="p_details">
                    <h6>{singleItem?.product?.short_name}</h6>
                    <h6>Quantity: {singleItem?.quantity}</h6>
                    <h6>Price: ₹{singleItem?.newPrice}</h6>
                  </div>
                </div>
              ))}
            </div>
            <div className="shipping_to">
              <h6>Ship To</h6>
              <p>Name: {detailsModal.shippingAddress?.fullName}</p>
              <p>Phone: {detailsModal.shippingAddress?.mobileNumber}</p>
              <p>
                {detailsModal.shippingAddress?.addressType}, City:{" "}
                {detailsModal.shippingAddress?.city}, State:{" "}
                {detailsModal.shippingAddress?.state}
              </p>
              <p>
                {" "}
                Street: {detailsModal.shippingAddress?.street}, House Number:{" "}
                {detailsModal.shippingAddress?.houseNumber}, Pin Code:{" "}
                {detailsModal.shippingAddress?.pinCode}
              </p>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {!loading && orderedItem ? (
        <Container>
          <Row style={{ paddingTop: "20px", marginBottom: "20px" }}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link to="/">Home</Link>
              <Link to="/pcbMarket">PCB Market</Link>
              <Typography color="text.primary">Order Page</Typography>
            </Breadcrumbs>
          </Row>

          <Row>
            <Col>
              <div className="order_table">
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Date</TableCell>
                        <TableCell align="left">Order Price</TableCell>
                        <TableCell align="left">Ship To</TableCell>
                        <TableCell align="left">Status</TableCell>
                        <TableCell align="left"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orderedItem.map((item) => {
                        return (
                          <TableRow key={item._id}>
                            <TableCell align="left">
                              {moment(item.createdAt).format(
                                "DD/MM/YYYY - HH:mm"
                              )}
                            </TableCell>
                            <TableCell align="left">
                              ₹
                              {item.cart.length > 1 ? (
                                <span>
                                  {item.cart.reduce(
                                    (totalPrice, price) =>
                                      totalPrice + price.newPrice,
                                    0
                                  )}{" "}
                                </span>
                              ) : (
                                <span>
                                  {item.billing
                                    ? item.billing.totalbillingAmount
                                    : item.cart[0].newPrice}
                                </span>
                              )}
                            </TableCell>
                            <TableCell align="left">
                              {item.shippingAddress.addressType},{" "}
                              {item.shippingAddress.city}
                            </TableCell>
                            <TableCell align="left">
                              {item.orderStatus}
                            </TableCell>
                            <TableCell align="left">
                              <Button
                                variant="contained"
                                size="small"
                                onClick={() => {
                                  // handleDetails(item._id)
                                  handleShow(item._id);
                                }}
                              >
                                View Details
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Col>
          </Row>
        </Container>
      ) : (
        <div className="time_wrap">
          <img src={DashboardLogo1} style={{ width: "150px" }} alt="logo" />
          <CircularProgress style={{ marginLeft: "20px" }}></CircularProgress>
        </div>
      )}
    </div>
  );
};

export default OrderPage;
