import React, { useState, useEffect } from "react";

//utils
import { Box } from "@mui/material";
import { axiosInstance } from "../../utils/axiosInterceptor";

//components
import DashboardHeader from "../../ClientEngagement/DashboardHeader";
import CustomBreadcrumbs from "../../Components/CustomBreadcrumbs";
import ExplorerCard from "./ExplorerCard.component";

//styles
import { ExplorerContainer, PageContainer } from "./ColosseumExplorer.styles";

const ColsseumExplorer = () => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchProfile = () => {
    setLoading(true);
    axiosInstance
      .get("/users/getProfile")
      .then((resp) => {
        setProfile(resp.data);
        console.log(resp);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <Box>
      {!loading ? (
        <PageContainer>
          <DashboardHeader profile_data={profile.data} isBlack={true} />
          <ExplorerContainer>
            <CustomBreadcrumbs
              data={[
                {
                  name: "Home",
                  link: "/",
                },
                {
                  name: "EB 3D Colosseum",
                  link: "/3dcolosseum",
                },
              ]}
            />
            <div className="title">Elecbits 3D Colosseum Console</div>
            <div className="sub-title">
              Fully managed and scalable 3D Assembly Service
            </div>
            <div className="card-container">
              <ExplorerCard
                title="Create New Project"
                subtitle="lorem ipsum"
                imageName="rocket.svg"
                link="/3dcolosseum/services"
              />
              <ExplorerCard
                title="My Projects"
                subtitle="lorem ipsum"
                imageName="project-group.svg"
                link="/3dcolosseum/projects"
              />
              <ExplorerCard
                title="Getting Started?"
                subtitle="lorem ipsum"
                imageName="video tutorial.svg"
                link="/"
              />
            </div>
          </ExplorerContainer>
        </PageContainer>
      ) : null}
    </Box>
  );
};

export default ColsseumExplorer;
