import React, { useState } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from "@material-ui/core/LinearProgress";
import { axiosInstance } from "../utils/axiosInterceptor";
import { SnackbarProvider, useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import {Container,Row,Col} from 'react-bootstrap';
import Button from "@material-ui/core/Button";
import './dashboard.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));
export default function DeleteProject(props) {
  const [open, setOpen] = useState(false);
  const [deleteProjectLoading, setDeleteLoading] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  let deleteProject=()=>{
      let projectId=props.projectId;
      setDeleteLoading(true);
      axiosInstance.delete(`/projects/deleteProject/${projectId}`).then((resp)=>{
        setDeleteLoading(false);
        enqueueSnackbar('Successfully deleted the project', {
            variant: "success",
          });
          setOpen(false);
          props.fetchProjectData();
    })
      .catch((err)=>{
        setDeleteLoading(false);
        enqueueSnackbar('Not able to delete the project', {
            variant: "error",
          });
      });
  }
  return (
    <>
      <IconButton
        aria-label="delete"
        onClick={() => {
          setOpen(true);
        }}
      >
        <DeleteIcon fontSize="medium"></DeleteIcon>
      </IconButton>
      <Dialog
        fullWidth="true"
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <>
          {deleteProjectLoading ? (
            <div className={classes.root}>
              <LinearProgress></LinearProgress>
            </div>
          ) : (
            ""
          )}
          <DialogTitle id="form-dialog-title">Are you sure you want to delete project?</DialogTitle>
          <DialogContent>
          <div className="btn_opts_del">
              <Button style={{ marginRight: "10px" }}
                        variant="contained"
                        color="primary" onClick={deleteProject}>Delete</Button>
              <Button  variant="outlined"
                        color="primary" onClick={handleClose}>Cancel</Button>
          </div>
          </DialogContent>
        </>
      </Dialog>
    </>
  );
}
