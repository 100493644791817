import React, { useState, useEffect } from 'react';

//components
import CustomBreadcrumbs from '../../Components/CustomBreadcrumbs';
import DashboardHeader from '../../ClientEngagement/DashboardHeader';

//utils
import { axiosInstance } from '../../utils/axiosInterceptor';
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  Select,
} from '@mui/material';
import { MenuItem } from '@material-ui/core';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import { useWindowSize } from '../../Hooks/useWindowSize';

//styles
import {
  PageContainer,
  SupplierFabProjectsContainer,
  StyledInput,
  CustomButton,
} from './SupplierFab.styles';

import { supplierStages } from './Supplier.data';
// import { TimelineContainer } from "../ProjectTimeline/FabTimeline.styles";

//assets
import { ReactComponent as SearchIcon } from '../../assests/search.svg';
import { ReactComponent as DownloadIcon } from '../../assests/download.svg';
import SupplierTimelineComponent from './SupplierTimelineComponent';

const SupplierFabQuotation = (props) => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState(null);
  const [quotation, setQuotation] = useState({
    minTime: null,
    maxTime: null,
    totalCost: null,
    shippingCost: null,
  });
  const [prevStageNumber, setPrevStageNumber] = useState(0);
  const { id } = useParams();
  const [isQuoted, setIsQuoted] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { width } = useWindowSize();
  const [currentStage, setCurrentStage] = useState(null);

  const fetchProfile = () => {
    setLoading(true);
    axiosInstance
      .get('/users/getProfile')
      .then((resp) => {
        setProfile(resp.data);
        console.log(resp);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const fetchProject = async () => {
    try {
      const res = await axiosInstance.get(`/supplier/fabProject/${id}`);
      console.log(res);
      setProject(res.data.project);
      setCurrentStage(
        res.data.project.fabQuotation?.currentStatus[
          res.data.project.fabQuotation.currentStatus.length - 1
        ].status || 'Request For Quote'
      );
      setPrevStageNumber(res.data.project?.fabQuotation?.currentStatus?.length);
    } catch (err) {
      console.log(err);
    }
  };
  console.log(prevStageNumber);
  useEffect(() => {
    if (project && project.isQuoted) {
      setIsQuoted(true);
      setQuotation({
        totalCost: project.fabQuotation.quotation.totalCost,
        shippingCost: project.fabQuotation.quotation.shippingCost,
        minTime: project.fabQuotation.quotation.leadTime.minTime,
        maxTime: project.fabQuotation.quotation.leadTime.maxTime,
      });
    }
  }, [project]);

  const handleChange = (e) => {
    setQuotation({ ...quotation, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(quotation);
    try {
      const res = await axiosInstance.post(`/supplier/fabQuotation/${id}`, {
        ...quotation,
      });
      console.log(res);
      enqueueSnackbar('Quotation submitted successfully!', {
        variant: 'success',
      });
      setIsQuoted(true);
      window.location.reload();
    } catch (err) {
      console.log(err);
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const handleStageChange = async () => {
    try {
      const res = await axiosInstance.post(
        `/supplier/fab/quotation/addStage/${project.fabQuotation._id}`,
        { status: currentStage }
      );
      enqueueSnackbar('Stage updated successfully!', { variant: 'success' });
      window.location.reload();
    } catch (err) {
      console.log(err);
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  useEffect(() => {
    fetchProfile();
    fetchProject();
  }, []);

  return (
    <Box>
      {!loading && project ? (
        <PageContainer>
          <DashboardHeader profile_data={profile.data} isBlack={true} />
          <SupplierFabProjectsContainer>
            <CustomBreadcrumbs
              data={[
                {
                  name: 'Home',
                  link: '/',
                },
                {
                  name: 'EB PCB Fabricator',
                  link: '/supplier/fab/projects',
                },
                {
                  name: `Project ${id}`,
                  link: `/supplier/fab/projects/${id}`,
                },
              ]}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div className="title">Project ID: {id}</div>
            </Box>
            <Box>
              <Typography sx={{ margin: '30px 0px 20px 0px' }}>
                Project Details 📃
              </Typography>
              <Box
                sx={{
                  border: '1px solid #0275FC',
                  borderRadius: '12px',
                  padding: '20px',
                  margin: '10px 0px',
                }}
              >
                <Grid container sx={{ marginBottom: '20px' }}>
                  <Grid item md={6}>
                    <Box
                      sx={{
                        display: 'flex',
                        margin: '10px 10px 10px 0px',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{ color: '#8C8C8C', marginRight: '20px' }}
                      >
                        PCB Quantity:
                      </Typography>
                      <Typography>
                        {project.projectDetails?.pcbQuantity}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item md={6}>
                    <Box
                      sx={{
                        display: 'flex',
                        margin: '10px 10px 10px 0px',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{ color: '#8C8C8C', marginRight: '20px' }}
                      >
                        Surface Finish:
                      </Typography>
                      <Typography>
                        {project.projectDetails?.surfaceFinish}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item md={6}>
                    <Box
                      sx={{
                        display: 'flex',
                        margin: '10px 10px 10px 0px',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{ color: '#8C8C8C', marginRight: '20px' }}
                      >
                        Delivery Format:
                      </Typography>
                      <Typography>
                        {project.projectDetails?.deliveryFormat}
                      </Typography>
                    </Box>
                  </Grid>{' '}
                  <Grid item md={6}>
                    <Box
                      sx={{
                        display: 'flex',
                        margin: '10px 10px 10px 0px',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{ color: '#8C8C8C', marginRight: '20px' }}
                      >
                        Stencil Requirement:
                      </Typography>
                      <Typography>
                        {project.projectDetails.isStencil ? 'Yes' : 'No'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item md={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        margin: '10px 10px 10px 0px',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{ color: '#8C8C8C', marginRight: '20px' }}
                      >
                        Project Created On:
                      </Typography>
                      <Typography>
                        {' '}
                        {moment(project?.updationDate).format(
                          'MMMM Do YYYY, h:mm a'
                        ) || 'no date'}
                      </Typography>
                    </Box>
                  </Grid>{' '}
                  <Grid item md={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        margin: '10px 10px 10px 0px',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{ color: '#8C8C8C', marginRight: '20px' }}
                      >
                        Additional Description:
                      </Typography>
                      <Typography>
                        {project.projectDetails.otherSpecification || '--'}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Box
                  sx={{ display: 'flex', gap: '20px', alignItems: 'center' }}
                >
                  <a href={project.gerberFile.location}>
                    <CustomButton>DOWNLOAD GERBER</CustomButton>
                  </a>
                  <Box sx={{ display: 'flex', marginTop: '0px', gap: '10px' }}>
                    <Typography sx={{ color: '#7D7D7D' }}>
                      Last Gerber File upload was on{' '}
                    </Typography>
                    <Typography sx={{ color: '#0275FC', fontWeight: 700 }}>
                      {moment(
                        project.gerberFile.uploadedOn || project.updationDate
                      ).format('MMMM Do YYYY, h:mm a') || 'no date'}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box>
              <Typography sx={{ margin: '30px 0px 20px 0px' }}>
                My Quote 📃
              </Typography>
              <Box
                sx={{
                  border: '1px solid #0275FC',
                  borderRadius: '12px',
                  padding: '20px',
                  margin: '10px 0px',
                }}
              >
                <form onSubmit={handleSubmit}>
                  <Grid container sx={{ marginBottom: '20px' }}>
                    <Grid item md={12}>
                      <Box
                        sx={{
                          display: 'flex',
                          margin: '10px 10px 10px 0px',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          sx={{ color: '#8C8C8C', marginRight: '20px' }}
                        >
                          Lead time:
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                          }}
                        >
                          <TextField
                            variant="standard"
                            name="minTime"
                            onChange={handleChange}
                            value={quotation.minTime}
                            sx={{
                              width: '30px',
                              '& input': { textAlign: 'center' },
                            }}
                            required
                            disabled={isQuoted}
                          />
                          <div>to</div>
                          <TextField
                            variant="standard"
                            name="maxTime"
                            onChange={handleChange}
                            value={quotation.maxTime}
                            sx={{
                              width: '30px',

                              '& input': { textAlign: 'center' },
                            }}
                            required
                            disabled={isQuoted}
                          />
                          <div>days</div>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item md={6}>
                      <Box
                        sx={{
                          display: 'flex',
                          margin: '10px 10px 10px 0px',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          sx={{ color: '#8C8C8C', marginRight: '20px' }}
                        >
                          Total cost:
                        </Typography>
                        <Typography sx={{ marginRight: '10px' }}>₹</Typography>
                        <TextField
                          variant="standard"
                          name="totalCost"
                          value={quotation.totalCost}
                          onChange={handleChange}
                          required
                          disabled={isQuoted}
                        />
                      </Box>
                    </Grid>
                    <Grid item md={6}>
                      <Box
                        sx={{
                          display: 'flex',
                          margin: '10px 10px 10px 0px',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          sx={{ color: '#8C8C8C', marginRight: '20px' }}
                        >
                          Total cost(incl GST):
                        </Typography>
                        <Typography sx={{ marginRight: '10px' }}>₹</Typography>
                        <Typography>
                          {(quotation.totalCost * 1.18)
                            .toFixed(2)
                            .replace(/[.,]00$/, '')}
                        </Typography>
                      </Box>
                    </Grid>{' '}
                    <Grid item md={6}>
                      <Box
                        sx={{
                          display: 'flex',
                          margin: '10px 10px 10px 0px',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          sx={{ color: '#8C8C8C', marginRight: '20px' }}
                        >
                          Shipping cost:
                        </Typography>
                        <Typography sx={{ marginRight: '10px' }}>₹</Typography>
                        <TextField
                          variant="standard"
                          name="shippingCost"
                          value={quotation.shippingCost}
                          onChange={handleChange}
                          required
                          disabled={isQuoted}
                        />
                      </Box>
                    </Grid>
                    <Grid item md={6}>
                      <Box
                        sx={{
                          display: 'flex',
                          margin: '10px 10px 10px 0px',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          sx={{ color: '#8C8C8C', marginRight: '20px' }}
                        >
                          Grand Total:
                        </Typography>
                        <Typography sx={{ marginRight: '10px' }}>₹</Typography>
                        <Typography>
                          {(
                            Number(quotation.totalCost * 1.18) +
                            Number(quotation.shippingCost)
                          )
                            .toFixed(2)
                            .replace(/[.,]00$/, '')}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <CustomButton type="submit" disabled={isQuoted}>
                    SUBMIT QUOTE
                  </CustomButton>
                </form>
              </Box>
            </Box>
            <Box sx={{ marginTop: '30px' }}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  margin: '20px 0px',
                }}
              >
                <Typography>Order Timeline 📈</Typography>
                <Box
                  sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}
                >
                  <CustomButton
                    disabled={
                      project.fabQuotation?.currentStatus.length <= 2 ||
                      project.fabQuotation == null ||
                      prevStageNumber === supplierStages.length
                    }
                    onClick={handleStageChange}
                  >
                    Next Stage
                  </CustomButton>
                </Box>
              </Box>
              <Box>
                <Timeline>
                  {supplierStages.map((stage, i) => (
                    <TimelineItem>
                      <TimelineOppositeContent
                        color="text.secondary"
                        style={{ flex: 0 }}
                      >
                        {project.fabQuotation?.currentStatus.length >= i + 1 ? (
                          moment(
                            project?.fabQuotation?.currentStatus[i]
                              ?.completionDate
                          ).format('DD/MM/YYYY')
                        ) : (
                          <Typography sx={{ visibility: 'hidden' }}>
                            {moment().format('DD/MM/YYYY')}
                          </Typography>
                        )}
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot />
                        {i != 5 ? <TimelineConnector /> : null}
                      </TimelineSeparator>
                      <TimelineContent>
                        <SupplierTimelineComponent
                          stage={stage}
                          i={i}
                          quotation={project.fabQuotation}
                        />
                      </TimelineContent>
                    </TimelineItem>
                  ))}
                </Timeline>
              </Box>
            </Box>
          </SupplierFabProjectsContainer>
        </PageContainer>
      ) : null}
    </Box>
  );
};

export default SupplierFabQuotation;
