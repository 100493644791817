import React, { useEffect, useState } from "react";
import ElecLogo from "../../assests/elecbitsLogo.svg";
import RightImage from "../../assests/rightImage.svg";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ElecbitsWhite from "../../assests/elecbitsLogoWhite.svg";
import {
  createTheme,
  withStyles,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import "./Login.css";
import axios from "axios";
import * as EmailValidator from "email-validator";
import { SnackbarProvider, useSnackbar } from "notistack";
import LinearProgress from "@material-ui/core/LinearProgress";
import { axiosInstance } from "../../utils/axiosInterceptor";
import { Link } from "react-router-dom";
import { Box, Typography, useTheme } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  margin: {
    marginTop: theme.spacing(2),
  },
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(1),
    },
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#fca702",
    "&:hover": {
      backgroundColor: "#fca720",
    },
  },
}))(Button);
const LightColorButton = withStyles((theme) => ({
  root: {
    backgroundColor: "rgba(0, 0, 0, 0.034)",
    color: "black",
    fontWeight: "600",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.034)",
    },
  },
}))(Button);
export default function ForgotPassword(props) {
  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email_valid, set_valid] = useState(false);
  const [loading, setLoading] = useState(false);
  const { breakpoints } = useTheme();

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async () => {
    try {
      const res = await axiosInstance.post("/passwordReset", {
        email: username.toLowerCase(),
      });
      console.log(res);
      if (res.status == 200) {
        enqueueSnackbar("Reset link sent to your email", {
          variant: "success",
        });
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        enqueueSnackbar(err.response.data.message, { variant: "error" });
      } else {
        enqueueSnackbar("Something went wrong", { variant: "error" });
      }
    }
  };

  return (
    <SnackbarProvider maxSnack={3}>
      <div className={classes.root}>{loading ? <LinearProgress /> : ""}</div>
      <div className="signUpWrapper">
        <div className="content_wrap">
          <div className="leftt_content_login_supplier1">
            <div className="signUpheaderLogin">
              <img src={ElecbitsWhite}></img>
              <p>
                Develop and Manufacture Electronics Just Like You Order a 🍕
                Online
              </p>
            </div>
          </div>
          <div className="main_content_login">
            <Box
              className="l_body"
              sx={{
                [breakpoints.down("md")]: {
                  width: "90vw",
                },
              }}
            >
              <div className="signUp_head_login">Forgot Password?</div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <TextField
                  error={email_valid}
                  helperText="Your Email Id"
                  required
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  className={classes.margin}
                  onChange={(e) => {
                    setUsername(e.target.value);
                    if (!EmailValidator.validate(e.target.value)) {
                      set_valid(true);
                    } else {
                      set_valid(false);
                    }
                  }}
                />

                <ColorButton
                  type="submit"
                  variant="contained"
                  color="white"
                  sx={{ color: "white" }}
                  className={classes.margin}
                >
                  SEND RESET LINK
                </ColorButton>

                {/* <ColorButton
                  variant="contained"
                  color="primary"
                  className={classes.margin}
                >
                  Sign in with FACEBOOK
                </ColorButton> */}
              </form>
              <div className="sign_up">
                Not registered?{"   "}Click
                <Link to="/signUp">
                  {" "}
                  <u style={{ color: "black" }}>here</u>
                </Link>{" "}
                to sign up
              </div>
            </Box>
          </div>
        </div>
      </div>
    </SnackbarProvider>
  );
}
