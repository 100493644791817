import {
  styled,
  Box,
  Grid,
  TextField,
  Select,
  Slider,
  Button,
} from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import LoadingButton from "@mui/lab/LoadingButton";
// import { Select } from "@material-ui/core";

export const PageContainer = styled(Box)(() => ({
  width: "100vw",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
}));

export const QuotationsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  flex: 1,
  padding: "50px 50px",
  fontFamily: "Poppins",
  backgroundColor: "#F4F7FE",
  overflowY: "auto",
  paddingBottom:'120px',

  "& .title": {
    marginTop: "30px",
    fontWeight: 600,
  },
  "& .sub-title": {
    marginTop: "10px",
    color: "#000",
    fontSize: "1.2em",
    fontWeight: 600,
    marginBottom: "50px",
  },

  "& .container1": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
    width: "100",
    marginTop: "20px",
  },

  "& .projectName": {
    fontSize: "1.4em",
    fontWeight: 600,
  },
  "& .projectTime": {
    color: "#8B8B8B",
    fontSize: "0.9em",
    marginTop: "20px",
  },
  "& .button-container": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100",
    marginTop: "20px",
  },

  [theme.breakpoints.down("md")]: {
    "& .container1": {
      flexDirection: "column",
      alignItems: "start",
    },
    "& .button-container": {
      flexDirection: "column",
      alignItems: "start",
    },
  },
}));

export const CustomButton = styled(Button)(({ fill }) => ({
  "&&&": {
    backgroundColor: fill ? "#0702FC" : "#transparent",
    color: fill ? "#fff" : "#000",
    border: "2px solid #0702FC",
    borderRadius: "4px",
    marginRight: "20px",
    textTransform: "none",
  },
  "& svg": {
    transform: "scale(0.8) !important",
    marginRight: "10px !important",
  },
  '& .qbtn path':{
    fill:fill?'#fff':'#0702FC'
  }
}));

export const CustomInput = styled(TextField)(({ error }) => ({
  width: "100%",

  backgroundColor: "transparent",
  outline: "none",
  "&:focus": {
    outline: "none",
  },
  "& legend": {
    width: "unset",
  },
  "& .MuiOutlinedInput-root": {
    border: error ? "2px solid red" : "2px solid #8B8B8B4D",
    borderRadius: 8,
  },
  "& input": {
    padding: "7px",
  },
  "& fieldset": {
    border: "none",
  },
}));

export const CustomSelect = styled(Select)(() => ({
  width: "100%",

  backgroundColor: "transparent",
  borderRadius: 8,
  padding: "5px",
  "& .MuiSelect-select": {
    padding: "5px",
  },
  "& .MuiInputBase-root.MuiOutlinedInput-root": {
    border: "2px solid #8B8B8B4D",
    borderRadius: "8px",
  },
  "& fieldset": {
    // border:'none',
    border: "2px solid #8B8B8B4D",
    borderRadius: "8px",
  },
  "& legend": {
    width: "unset",
  },
}));
