import React, { useState, useEffect } from "react";

//components
import CustomBreadcrumbs from "../../Components/CustomBreadcrumbs";
import DashboardHeader from "../../ClientEngagement/DashboardHeader";

//utils
import { axiosInstance } from "../../utils/axiosInterceptor";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  InputAdornment,
} from "@mui/material";
import moment from "moment";

//styles
import {
  PageContainer,
  SupplierBomProjectsContainer,
  StyledInput,
  ProjectChip,
} from "./SupplierBom.styles";

//assets
import { ReactComponent as SearchIcon } from "../../assests/search.svg";
import { ReactComponent as DownloadIcon } from "../../assests/download.svg";

const SupplierBomProjects = (props) => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filter, setFilter] = useState("live");

  const fetchProfile = () => {
    setLoading(true);
    axiosInstance
      .get("/users/getProfile")
      .then((resp) => {
        setProfile(resp.data);
        console.log(resp);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const fetchProjects = async () => {
    try {
      const res = await axiosInstance.get("/supplier/bom/projects");
      console.log(res);
      setProjects(res.data.projects);
      setFilteredProjects(res.data.projects.filter((a) => a.isLive == true));
    } catch (err) {
      console.log(err);
    }
  };

  const filterProjects = (filter) => {
    let newprojects = [];
    if (filter == "live") {
      newprojects = projects.filter((p) => p.isLive == true);
    } else if (filter == "rejected") {
      newprojects = projects.filter((p) => p.isRejected == true);
    } else if (filter == "accepted") {
      newprojects = projects.filter((p) => p.isAccepted == true);
    } else if (filter == "sent") {
      newprojects = projects.filter(
        (p) => p.isLive == true && p.status == "Quote Submitted"
      );
    } else if (filter == "nsent") {
      newprojects = projects.filter(
        (p) => p.isLive == true && p.status == "Request For Quotation"
      );
    }

    setFilteredProjects([...newprojects]);
  };

  // const filterProjects = (query) => {
  //   const newProjects = projects.filter((p) =>
  //     p?.internalName?.toLowerCase().includes(query.toLowerCase())
  //   );
  //   setFilteredProjects([...newProjects]);
  // };

  // const handleChange = (e) => {
  //   setSearchQuery(e.target.value);
  // };

  useEffect(() => {
    filterProjects(filter);
  }, [filter]);

  useEffect(() => {
    fetchProfile();
    fetchProjects();
  }, []);

  const isProjectNew = (updationDate) => {
    let d = new Date();
    let d2 = new Date(updationDate);
    if (Math.abs(d2.getTime() - d.getTime()) / (1000 * 3600 * 24) <= 5) {
      return true;
    }
    return false;
  };

  return (
    <Box>
      {!loading ? (
        <PageContainer>
          <DashboardHeader profile_data={profile.data} isBlack={true} />
          <SupplierBomProjectsContainer>
            <CustomBreadcrumbs
              data={[
                {
                  name: "Home",
                  link: "/",
                },
                {
                  name: "EB BOM Explorer",
                  link: "/supplier/bom/projects",
                },
              ]}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="title">Live Projects</div>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                marginTop: "20px",
              }}
            >
              <ProjectChip
                color="#0275FC"
                selected={filter === "live"}
                onClick={() => {
                  setFilter("live");
                }}
              >
                LIVE
              </ProjectChip>
              <ProjectChip
                color="#0275FC"
                selected={filter === "sent"}
                onClick={() => {
                  setFilter("sent");
                }}
              >
                QUOTE SENT
              </ProjectChip>
              <ProjectChip
                color="#0275FC"
                selected={filter === "nsent"}
                onClick={() => {
                  setFilter("nsent");
                }}
              >
                QUOTE NOT SENT
              </ProjectChip>
              <ProjectChip
                color="#0ba52c"
                selected={filter === "accepted"}
                onClick={() => {
                  setFilter("accepted");
                }}
              >
                ACCEPTED
              </ProjectChip>
              <ProjectChip
                color="#ff0000"
                selected={filter === "rejected"}
                onClick={() => {
                  setFilter("rejected");
                }}
              >
                REJECTED
              </ProjectChip>
            </Box>
            <TableContainer component={Paper} sx={{ marginTop: "30px" }}>
              <Table sx={{ minWidth: 50 }} aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#8B8B8B33" }}>
                    <TableCell
                      sx={{ fontSize: "0.9rem", fontWeight: 500 }}
                      align="left"
                    ></TableCell>
                    <TableCell
                      sx={{ fontSize: "0.9rem", fontWeight: 500 }}
                      align="left"
                    >
                      Project ID
                    </TableCell>

                    <TableCell
                      sx={{ fontSize: "0.9rem", fontWeight: 500 }}
                      align="left"
                    >
                      Proejct Created On
                    </TableCell>
                    <TableCell
                      sx={{ fontSize: "0.9rem", fontWeight: 500 }}
                      align="left"
                    >
                      No. of units
                    </TableCell>
                    <TableCell
                      sx={{ fontSize: "0.9rem", fontWeight: 500 }}
                      align="left"
                    >
                      Current Stage
                    </TableCell>
                    <TableCell
                      sx={{ fontSize: "0.9rem", fontWeight: 500 }}
                      align="center"
                    >
                      Download BOM
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredProjects?.length > 0 ? (
                    <>
                      {filteredProjects
                        .slice(0)
                        .reverse()
                        .map((project) => (
                          <TableRow
                            key={project.projectId}
                            hover
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                              cursor: "pointer",
                            }}
                          >
                            <TableCell>
                              {" "}
                              {project.status == "Request For Quotation" ? (
                                <Box
                                  sx={{
                                    width: "fit-content",
                                    height: "fit-content",
                                    padding: "2px 10px",
                                    border: "1px solid #0275FC",
                                    borderRadius: "4px",
                                    fontSize: "0.8em",
                                  }}
                                >
                                  NEW ⚡
                                </Box>
                              ) : null}
                            </TableCell>
                            <TableCell
                              onClick={() =>
                                props.history.push(
                                  `/supplier/bom/projects/${project.projectId}`
                                )
                              }
                            >
                              {project.projectId || ""}
                            </TableCell>

                            <TableCell
                              onClick={() =>
                                props.history.push(
                                  `/supplier/bom/projects/${project.projectId}`
                                )
                              }
                            >
                              {" "}
                              {moment(project?.updationDate).format(
                                "MMMM Do YYYY, h:mm a"
                              ) || "no date"}
                            </TableCell>
                            <TableCell
                              onClick={() =>
                                props.history.push(
                                  `/supplier/bom/projects/${project.projectId}`
                                )
                              }
                            >
                              {project.quantity}
                            </TableCell>
                            <TableCell
                              onClick={() =>
                                props.history.push(
                                  `/supplier/bom/projects/${project.projectId}`
                                )
                              }
                            >
                              {project.status}
                            </TableCell>
                            <TableCell
                              sx={{ "& svg": { transform: "scale(0.8)" } }}
                              align="center"
                            >
                              <a href={project.bomFile.location}>
                                <DownloadIcon />
                              </a>
                            </TableCell>
                          </TableRow>
                        ))}
                    </>
                  ) : (
                    <TableCell
                      colSpan={7}
                      sx={{
                        color: "#737373",
                        margin: "10px",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      Currently there are no live projects
                    </TableCell>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </SupplierBomProjectsContainer>
        </PageContainer>
      ) : null}
    </Box>
  );
};

export default SupplierBomProjects;
