import React from "react";
import "../HomeComponent/Home.css";
import { Container, Row, Col } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

import PCBImage from "../assests/pcbImg.svg";
import DashboardHeader from "../ClientEngagement/AdminDashboardHeader";
import Box from "@mui/material/Box";
import { axiosInstance } from "../utils/axiosInterceptor";
import CircularProgress from "@material-ui/core/CircularProgress";
import DashboardLogo1 from "../assests/elecbitsLogo.svg";
import _3dServices from "../assests/_3dServices.png";
import bomExplorer from "../assests/bomExplorer.png";
import consultations from "../assests/consultations.png";
import customerEngagement from "../assests/customerEngagement.png";

import pcbArena from "../assests/pcbArena.png";
import pcbAssembly from "../assests/pcbAssembly.png";

const StyledButton = withStyles({
  root: {
    background: "#6200EE",
    color: "white",
    "&:hover": {
      backgroundColor: "#6200EE",
    },
  },
})(Button);

const design = [
  {
    name: "Elecbits Hardware Arcade",
    value: "cl",
    link: "/pcbMarket",
    src: pcbArena,
    desc: "PCBs PCBs Everywhere!",
  },
];
const manufacturing = [
  {
    name: "Elecbits PCB Fabricator",
    value: "cl",
    link: "/admin/pcbFab/projects",
    src: PCBImage,
    desc: "Fabricate PCBs Like a Pro",
  },
  {
    name: "Elecbits BOM Explorer",
    value: "cl",
    link: "/admin/bomExplorer",
    src: bomExplorer,
    desc: "Streamline BOM Procurement, Launch Faster",
  },
  {
    name: "PCB Assembly",
    value: "cl",
    link: "/",
    src: pcbAssembly,
    desc: "single line description",
  },
  {
    name: "3D Services",
    value: "cl",
    link: "/admin/3d/projects",
    src: _3dServices,
    desc: "single line description",
  },
];
const misc = [
  {
    name: "Elecbits Virtual Project Manager",
    value: "cl",
    link: "/clientEngagement",
    src: customerEngagement,
    desc: "Your Virtual Project Manager",
  },
  {
    name: "Consultation with Experts",
    value: "cl",
    link: "/admin/allConsultations",
    src: consultations,
    desc: "Confused? We Can Help You!",
  },
  {
    name: "Platform Users 👬",
    value: "pu",
    link: "/admin/allUsers",
    desc: "",
  },
];

export default function PMHomepage(props) {
  const [profile_data, set_profile] = React.useState(null);
  const [loading, set_loading] = React.useState(null);
  let fetchProfile = () => {
    set_loading(true);
    axiosInstance
      .get("/users/getProfile")
      .then((resp) => {
        set_profile(resp.data);
        set_loading(false);
      })
      .catch((err) => {
        set_loading(false);
      });
  };
  React.useEffect(() => {
    fetchProfile();
  }, []);
  return (
    <Box>
      {!loading && profile_data ? (
        <Box>
          <DashboardHeader
            profile_data={profile_data.data}
            toggleLoggedIn={props.toggleLoggedIn}
            history={props.history}
            isBlack
          ></DashboardHeader>
          <Container>
            <Row>
              <Col>
                <div className="cconsole_head">
                  Elecbits Platform PM Console 😎
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={9} sm={12}>
                <div className="allServices">
                  <div className="find_serve_text">All Services</div>
                  <Container className="section_type">
                    <Row className="serve_sec_head">
                      Design and Development 🛠
                    </Row>
                    <Row style={{ marginLeft: "-24px" }}>
                      {design.map((option) => {
                        return (
                          <Col md={6}>
                            <Link to={option.link}>
                              <div className="service_text">
                                <div>
                                  <img src={option.src}></img>
                                </div>
                                <div className="service_text_text">
                                  {option.name}
                                </div>
                                <div className="service_text_desc">
                                  {option.desc}
                                </div>
                              </div>
                            </Link>
                          </Col>
                        );
                      })}
                    </Row>
                  </Container>

                  <Container className="section_type">
                    <Row className="serve_sec_head">Manufacturing 🏭</Row>
                    <Row style={{ marginLeft: "-24px" }}>
                      {manufacturing.map((option) => {
                        return (
                          <Col md={6}>
                            <Link to={option.link}>
                              <div className="service_text">
                                <div>
                                  <img src={option.src}></img>
                                </div>
                                <div className="service_text_text">
                                  {option.name}
                                </div>
                                <div className="service_text_desc">
                                  {option.desc}
                                </div>
                              </div>
                            </Link>
                          </Col>
                        );
                      })}
                    </Row>
                  </Container>

                  <Container className="section_type">
                    <Row className="serve_sec_head">Miscellaneous 🎈</Row>
                    <Row style={{ marginLeft: "-24px" }}>
                      {misc.map((option) => {
                        return (
                          <Col md={6}>
                            <Link to={option.link}>
                              <div className="service_text">
                                <div>
                                  <img src={option.src}></img>
                                </div>
                                <div className="service_text_text">
                                  {option.name}
                                </div>
                                <div className="service_text_desc">
                                  {option.desc}
                                </div>
                              </div>
                            </Link>
                          </Col>
                        );
                      })}
                    </Row>
                  </Container>
                </div>
              </Col>
            </Row>
          </Container>
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={DashboardLogo1} style={{ width: "150px" }} alt="logo"></img>
          <CircularProgress style={{ marginLeft: "20px" }}></CircularProgress>
        </Box>
      )}
    </Box>
  );
}
