import React, { useState, useEffect } from 'react';

//libs
import {
  Modal,
  Box,
  styled,
  Button,
  Slide,
  Typography,
  TextField,
  Grid,
  Select,
  FormControl,
  OutlinedInput,
  InputLabel,
  Chip,
  useTheme,
} from '@mui/material';
import { MenuItem } from '@material-ui/core';
import moment from 'moment';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { useSnackbar } from 'notistack';
import { axiosInstance } from '../../utils/axiosInterceptor';
import { CustomButton } from './BomTImeline.styles';
const ModalContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '60vw',
  maxHeight: '70vh',
  overflow: 'hidden',
  overflowY: 'scroll',
  left: '22vw',
  top: '12vh',
  backgroundColor: '#fff',
  boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.2)',
  borderRadius: 15,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '20px',
  padding: '20px 20px',
  '& .heading': {
    fontSize: '1.2em',
    fontWeight: 700,
  },
  [theme.breakpoints.down('md')]: {
    width: '90vw',
    left: '5vw',
  },
}));
const InternalNameField = ({ data, label, id, isAdmin }) => {
  const [internalState, setInternalState] = useState({
    editMode: false,
    data: data || 'default',
  });
  const { enqueueSnackbar } = useSnackbar();

  const handleSaveInternalState = async () => {
    console.log(internalState);

    try {
      const res = await axiosInstance.put(`/bom/admin/editProject/${id}`, {
        [label]: internalState.data,
      });
      console.log(res);

      enqueueSnackbar('Project Updated Successfully!', {
        variant: 'success',
      });
    } catch (err) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
    setInternalState({
      ...internalState,
      editMode: false,
    });
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '-30px' }}>
      <Box
        sx={{
          display: 'flex',
          gap: '10px',
          '& .MuiInput-input.Mui-disabled': {
            color: '#000',
            textFillColor: '#000',
          },
        }}
      >
        <TextField
          disabled={!internalState.editMode}
          variant="standard"
          sx={{
            width: '100%',
            '& input': { textAlign: 'start', paddingRight: '10px' },
          }}
          value={internalState.data}
          InputProps={{ disableUnderline: !internalState.editMode }}
          onChange={(e) => {
            setInternalState({
              ...internalState,
              data: e.target.value,
            });
          }}
        />{' '}
      </Box>

      {isAdmin ? (
        <>
          {!internalState.editMode ? (
            <EditOutlinedIcon
              sx={{
                cursor: 'pointer',
                transform: 'scale(0.9)',
                marginLeft: '0rem',
              }}
              onClick={() => {
                setInternalState({
                  ...internalState,
                  editMode: !internalState.editMode,
                });
              }}
            />
          ) : (
            <SaveOutlinedIcon
              sx={{ cursor: 'pointer', transform: 'scale(0.9)' }}
              onClick={handleSaveInternalState}
            />
          )}
        </>
      ) : null}
    </Box>
  );
};
function getStyles(val, data, theme) {
  return {
    fontWeight:
      data.indexOf(val) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MultiSelect = ({ options, label, data1, editData, setEditData }) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [internalState, setInternalState] = useState({
    data: data1 || [],
  });
  const handleSaveInternalState = async (e) => {
    console.log(internalState);
  };
  const handleChange = (e) => {
    console.log(e.target.value);
    setInternalState({
      ...internalState,
      data:
        typeof e.target.value === 'string'
          ? e.target.value.split(',')
          : e.target.value,
    });
    setEditData({
      ...editData,
      [label]:
        typeof e.target.value === 'string'
          ? e.target.value.split(',')
          : e.target.value,
    });
  };
  return (
    <Box
      sx={{
        minWidth: 120,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
      }}
    >
      <FormControl variant="standard" fullWidth sx={{ border: 'none' }}>
        <InputLabel id="demo-multiple-chip-label">Chip</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={internalState.data}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          // MenuProps={MenuProps}
        >
          {options.map((opt) => (
            <MenuItem
              key={opt}
              value={opt}
              style={getStyles(opt, internalState.data, theme)}
            >
              {opt}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <></>
    </Box>
  );
};

const SingleSelect = ({ options, label, data, editData, setEditData }) => {
  const [val, setval] = useState(data || 'no value');
  const handleChange = async (e) => {
    setval(e.target.value);
    setEditData({ ...editData, [label]: e.target.value });
  };
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl variant="standard" fullWidth>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={val}
          label={label}
          onChange={handleChange}
        >
          {options.map((opt) => (
            <MenuItem value={opt}>{opt}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
const PMselect = ({ options, label, data, editData, setEditData }) => {
  console.log(data, 'bruh how this working');
  const [val, setval] = useState(data || 'no value');
  const handleChange = async (e) => {
    setval(e.target.value);
    setEditData({ ...editData, [label]: e.target.value });
  };
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl variant="standard" fullWidth>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={val}
          label={label}
          onChange={handleChange}
        >
          {options.map((opt) => (
            <MenuItem value={opt.id}>
              <Box>
                <Typography>{opt.fullName}</Typography>
                <Typography sx={{ fontSize: '0.7em', color: '#989898' }}>
                  {opt.email}
                </Typography>
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
const NewBomDetailsModal = ({ state, toggleModal, project, isAdmin, user }) => {
  console.log(user.data._id, 'bruh');
  const [data, setData] = useState({});
  const [projectTrackingState, setProjectTrackingState] = useState({});
  const [allPms, setAllPms] = useState([]);
  const [editData, setEditData] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  const handleEditChange = async () => {
    try {
      const res = await axiosInstance.post(
        `/bom/admin/editProject/${project._id}/${user.data._id}`,
        editData
      );
      console.log(res);

      enqueueSnackbar('Project Updated Successfully!', {
        variant: 'success',
      });
    } catch (err) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const getAllPms = async () => {
    try {
      const res = await axiosInstance.get('/pm/getAllPMs');
      const cleanData = res.data.data.map((pm) => {
        return {
          fullName: pm?.fullName || `${pm?.firstName} ${pm?.lastName}` || 'NaN',
          email: pm.email,
          id: pm._id,
        };
      });
      setAllPms(cleanData);
    } catch (e) {
      console.log(e);
    }
  };
  const generateData = (project) => {
    let ProjectDetails = {
      'Project Id': project.projectId,
      'Owner Email': project.projectOwner.email,
      'Created on':
        moment(project?.startDate).format('DD-MM-YY, h:mm a') || 'no date',
      'Internal Name': (
        <InternalNameField
          data={project.internalName}
          label="internalName"
          id={project._id}
          isAdmin={isAdmin}
        />
      ),
      Quantity: (
        <InternalNameField
          data={project.numberOfBoards}
          label="numberOfBoards"
          id={project._id}
          isAdmin={isAdmin}
        />
      ),
      'Project Name': project.projectName,
      'Other Specification': project.otherSpecification,
    };

    return ProjectDetails;
  };
  useEffect(() => {
    if (project) {
      const d = generateData(project);
      setData(d);
    }
  }, [project]);
  useEffect(() => {
    getAllPms();
  }, []);

  const generateProjectTrackingData = (project, user) => {
    console.log(user, 'hereee');
    let ProjectTrackingDetails = {
      'Client ratings': (
        <SingleSelect
          options={[1, 2, 3, 4, 5]}
          label="rating"
          editData={editData}
          setEditData={setEditData}
          data={user.data.clientDetails?.rating}
        />
      ),
      Priority: (
        <SingleSelect
          options={['Low', 'Medium', 'High', 'Very High']}
          label="priority"
          editData={editData}
          setEditData={setEditData}
          data={project.projectDetails?.priority}
        />
      ),
      'Organization Size': (
        <SingleSelect
          options={['Mid-Size-Enterprise', 'Startup', 'Large Enterprise']}
          label="organizationSize"
          editData={editData}
          setEditData={setEditData}
          data={user.data.clientDetails?.organizationSize}
        />
      ),
      'Mode of Communication': (
        <MultiSelect
          label="modeOfCommunication"
          options={['WhatsApp', 'Mail', 'Platform']}
          id={project._id}
          userId={user.data._id}
          data1={project.projectDetails?.modeOfCommunication}
          editData={editData}
          setEditData={setEditData}
        />
      ),
      'Project Manager': (
        <PMselect
          data={project.projectDetails?.projectManager?._id}
          label="projectManager"
          editData={editData}
          setEditData={setEditData}
          isAdmin={isAdmin}
          options={allPms}
        />
      ),
      'Backup PM': (
        <PMselect
          data={project.projectDetails?.backUpPM}
          label="backUpPM"
          editData={editData}
          setEditData={setEditData}
          isAdmin={isAdmin}
          options={allPms}
        />
      ),
    };
    return ProjectTrackingDetails;
  };

  useEffect(() => {
    setProjectTrackingState(generateProjectTrackingData(project, user));
  }, [project, user, editData]);
  console.log(editData);
  return (
    <Modal open={state} onClose={toggleModal}>
      <Slide in={state} direction="up">
        {project ? (
          <ModalContainer>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Typography
                sx={{ fontWeight: 'bold', fontSize: '1.5rem', margin: '1rem' }}
              >
                Project Details
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  width: '100%',
                  flexWrap: 'wrap',
                }}
              >
                {data != null &&
                  Object.keys(data).map((key) => (
                    <Box
                      sx={{
                        width: '45%',
                        display: 'flex',
                        flexDirection: 'row',
                        margin: '1rem 1rem',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography sx={{ width: '40%' }}>{key}: </Typography>
                      <Box
                        sx={{ borderBottom: '1px solid black', width: '60%' }}
                      >
                        {data[key]}
                      </Box>
                    </Box>
                  ))}
              </Box>
              {isAdmin && (
                <>
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '1.5rem',
                      margin: '1.5rem',
                    }}
                  >
                    Project Tracking Details
                  </Typography>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      flexWrap: 'wrap',
                    }}
                  >
                    {projectTrackingState != null &&
                      Object.keys(projectTrackingState).map((key) => (
                        <Box
                          sx={{
                            width: '45%',
                            display: 'flex',
                            flexDirection: 'row',
                            margin: '1rem 1rem',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Typography sx={{ width: '40%' }}>{key}: </Typography>
                          <Box
                            sx={{
                              borderBottom: '1px solid black',
                              width: '60%',
                            }}
                          >
                            {projectTrackingState[key]}
                          </Box>
                        </Box>
                      ))}
                  </Box>
                </>
              )}
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'flex-end',
                  marginTop: '1rem',
                }}
              >
                {isAdmin && (
                  <CustomButton onClick={handleEditChange} fill>
                    Submit
                  </CustomButton>
                )}
              </Box>
            </Box>
          </ModalContainer>
        ) : null}
      </Slide>
    </Modal>
  );
};

export default NewBomDetailsModal;
