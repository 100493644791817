import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import timeline from '../assests/timeline.png';
import { useSnackbar } from 'notistack';
import quotation from '../assests/quotation.png';
import detail from '../assests/detail.png';
import bin from '../assests/bin.png';
import requestQuotation from '../assests/requestQuotation.svg';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import DashboardHeader from '../ClientEngagement/AdminDashboardHeader';
import Box from '@mui/material/Box';
import { axiosInstance } from '../utils/axiosInterceptor';
import CircularProgress from '@material-ui/core/CircularProgress';
import DashboardLogo1 from '../assests/elecbitsLogo.svg';
import DeleteModal from '../Components/DeleteModal';
import CustomBreadcrumbs from '../Components/CustomBreadcrumbs';
import { Button, styled } from '@mui/material';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Switch } from '@material-ui/core';
// const CustomButtonContainer = styled(Button)(() => ({
//   "& .MuiButton-root": {
//     backgroundColor: "#493eff",
//     color: "#fff",
//   },
//   "& .MuiButton-root:hover": {
//     backgroundColor: "#493eff",
//     color: "#fff",
//   },
// }));

// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& .MuiPaper-root': {
//       '&. css-6hp17o-MuiList-root-MuiMenu-list': {
//         backgroundColor: 'blue',
//       },
//     },
//   },
// }));
const columns = [
  { id: 'CompanyName', label: 'Company Name', minWidth: 170 },

  { id: 'ProjectName', label: 'Project Name', minWidth: 150 },
  { id: 'InternalName', label: 'Internal Name', minWidth: 150 },

  {
    id: 'CreatedBy',
    label: 'Created By',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'OrderId',
    label: 'Order ID',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'CurrentStage',
    label: 'Current Stage',
    minWidth: 140,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'CreatedOn',
    label: 'Created On',
    minWidth: 140,
    align: 'right',
  },
  { id: 'RequestForQuotation', label: 'RFQ', minWidth: 80, align: 'center' },
  { id: 'Timeline', label: 'Timeline', minWidth: 80, align: 'center' },
  { id: 'Quotation', label: 'Quotation', minWidth: 80, align: 'center' },
  { id: 'Delete', label: '', minWidth: 80, align: 'center' },
];

function createData(
  CompanyName,
  ProjectName,
  InternalName,
  OrderId,
  CreatedBy,
  CurrentStage,
  CreatedOn,
  RequestForQuotation,
  Timeline,
  Quotation,
  Delete
) {
  return {
    CompanyName,
    ProjectName,
    InternalName,
    OrderId,
    CreatedBy,
    CurrentStage,
    CreatedOn,
    RequestForQuotation,
    Timeline,
    Quotation,
    Delete,
  };
}

const FABAllProjects = (props) => {
  console.log('testing here');
  const [projects, setProjects] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [deleteModalState, setDeleteModalState] = React.useState({
    visible: false,
    text: '',
    onYes: () => {},
  });
  const [profile_data, set_profile] = React.useState(null);
  const [loading, set_loading] = React.useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [testToggle, setTestToggle] = React.useState(false);
  const [actualProjects, setActualProjects] = React.useState([]);
  const [testProjects, setTestProjects] = React.useState([]);
  const [isPM, setIsPM] = React.useState(localStorage.getItem('PM'));
  let fetchProfile = () => {
    set_loading(true);
    axiosInstance
      .get('/users/getProfile')
      .then((resp) => {
        set_profile(resp.data);
        set_loading(false);
      })
      .catch((err) => {
        set_loading(false);
      });
  };
  React.useEffect(() => {
    fetchProfile();
  }, []);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const deleteProject = async (projectId, orderId) => {
    const res = await axiosInstance.delete(`/fab/admin/projects/${projectId}`);
    enqueueSnackbar('Successfully deleted project!', { variant: 'success' });
    console.log(res);
    setDeleteModalState({
      ...deleteModalState,
      visible: false,
    });
    window.location.reload();
  };

  const handleDeleteProject = async (projectId, projectName, orderId) => {
    setDeleteModalState({
      visible: true,
      text: `Delete project ${projectName}`,
      onYes: () => {
        deleteProject(projectId, orderId);
      },
    });
  };

  const toggleDeleteModal = () => {
    setDeleteModalState({
      ...deleteModalState,
      visible: !deleteModalState.visible,
    });
  };
  const createDataFun = (project) => {
    return createData(
      project?.projectOwner?.enterpriseName || 'test compant name',
      project.projectName || 'Some Project',
      project?.internalName || '--',
      project.projectId || '1234',
      project?.projectOwner?.email || 'test@1234.com',

      project.isClosed
        ? 'Project Closed'
        : project.currentStatus
        ? project.currentStatus[project.currentStatus.length - 1]?.status
        : 'Under Review',
      moment(project?.createdOn).format('MMMM Do YYYY, h:mm a') || 'no date',
      <img
        src={requestQuotation}
        onClick={() =>
          window.open(`/admin/pcbFab/suppliers/${project._id}`, '_blank')
        }
        style={{ cursor: 'pointer' }}
      />,
      <img
        src={timeline}
        onClick={() =>
          window.open(`/admin/pcbFab/timeline/${project._id}`, '_blank')
        }
        style={{ cursor: 'pointer' }}
      />,
      <img
        src={quotation}
        onClick={() =>
          window.open(`/admin/pcbFab/quotations/${project._id}`, '_blank')
        }
        style={{ cursor: 'pointer' }}
      />,
      <img
        src={bin}
        onClick={() => {
          console.log('delete clicked');
          handleDeleteProject(
            project._id,
            project.projectName,
            project.projectId
          );
        }}
        style={{ cursor: 'pointer' }}
      />
    );
  };
  React.useEffect(() => {
    const fetchProjects = async () => {
      const res = await axiosInstance.get('/fab/admin/allProjects');
      console.log(res);
      res.data?.projects?.forEach((project) => {
        const val = createDataFun(project);
        if (project.isTest) {
          testProjects.unshift(val);
        } else {
          actualProjects.unshift(val);
        }
        // rows.push(createData(project.companyName, project.projectName || "Some Project", project.projectId || "1234", "some data", project.currentStatus || "waiting"))
        // setProjects(projects);
      });
      setProjects([...actualProjects]);
      setActualProjects([...actualProjects]);
      setTestProjects([...testProjects]);

      console.log(projects);
    };
    fetchProjects();
  }, []);
  React.useEffect(() => {
    if (projects) {
      if (testToggle) {
        setProjects([...testProjects]);
      } else {
        setProjects([...actualProjects]);
      }
    }
  }, [testToggle]);
  return (
    <div>
      <Box>
        {!loading && profile_data ? (
          <>
            <DashboardHeader
              profile_data={profile_data.data}
              toggleLoggedIn={props.toggleLoggedIn}
              history={props.history}
              isBlack={true}
            ></DashboardHeader>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'column',
              }}
            >
              <Box
                sx={{
                  fontSize: '1.8rem',
                  fontWeight: 'bold',
                  margin: '1rem 2.8rem',
                  width: '100%',
                }}
              >
                <CustomBreadcrumbs
                  data={[
                    {
                      name: 'Home',
                      link: '/',
                    },
                    {
                      name: 'EB PCB Fabricator',
                      link: `${isPM ? '/pm' : '/admin'}/pcbFab/projects`,
                    },
                  ]}
                />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '90%',
                  }}
                >
                  PCB Fabricator
                  <Link
                    to="/admin/pcbFab/newProject"
                    style={{
                      backgroundColor: '#493eff',
                      fontSize: '1rem',
                      color: '#fff',
                      border: 'none',
                      padding: '5px 15px',
                      borderRadius: '5px',
                    }}
                  >
                    CREATE PROJECT 🚀
                  </Link>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '90%',
                  }}
                >
                  <Box sx={{ fontWeight: 'normal', fontSize: '1.2rem' }}>
                    All Projects
                  </Box>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={testToggle}
                          onChange={() => setTestToggle(!testToggle)}
                          color="primary"
                        />
                      }
                      label="Test Projects"
                    />
                  </FormGroup>
                </Box>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <Paper
                  sx={{
                    width: '90%',
                    overflow: 'hidden',
                  }}
                >
                  <TableContainer sx={{ maxHeight: 500 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {projects
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={row.code}
                              >
                                {columns.map((column) => {
                                  const value = row[column.id];
                                  return (
                                    <TableCell
                                      key={column.id}
                                      align={column.align}
                                    >
                                      {column.format &&
                                      typeof value === 'number'
                                        ? column.format(value)
                                        : value}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={projects.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              </Box>
            </Box>
            <DeleteModal
              onYes={deleteModalState.onYes}
              onNo={toggleDeleteModal}
              state={deleteModalState.visible}
              toggleModal={toggleDeleteModal}
              text={deleteModalState.text}
            />
          </>
        ) : (
          <Box
            sx={{
              width: '100%',
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={DashboardLogo1}
              style={{ width: '150px' }}
              alt="logo"
            ></img>
            <CircularProgress style={{ marginLeft: '20px' }}></CircularProgress>
          </Box>
        )}
      </Box>
    </div>
  );
};
export default FABAllProjects;
