import React, { useState } from "react";
import ElecbitsWhite from "../../assests/elecbitsLogoWhite.svg";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  createTheme,
  withStyles,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import axios from "axios";
import * as EmailValidator from "email-validator";
import { SnackbarProvider, useSnackbar } from "notistack";
import LinearProgress from "@material-ui/core/LinearProgress";
import { axiosInstance } from "../../utils/axiosInterceptor";
import { Link } from "react-router-dom";
import "./SignUp.css";
import { GoogleLogin } from "react-google-login";
import ReCAPTCHA from "react-google-recaptcha";

const useStyles = makeStyles((theme) => ({
  margin: {
    marginTop: theme.spacing(2),
  },
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    backgroundColor: "black",
    "&:hover": {
      backgroundColor: "black",
    },
  },
}))(Button);
const LightColorButton = withStyles((theme) => ({
  root: {
    backgroundColor: "rgba(0, 0, 0, 0.034)",
    color: "black",
    fontWeight: "600",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.034)",
    },
  },
}))(Button);
export default function SupplierSignup(props) {
  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email_valid, set_valid] = useState(false);
  const [password_valid, set_pass_valid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isRobot, setIsRobot] = useState(true);
  const [disabled, setDisabled] = useState(true);

  const { enqueueSnackbar } = useSnackbar();
  let submit = (user_name, pass_word) => {
    console.log(user_name, pass_word);

    setLoading(true);
    if (EmailValidator.validate(user_name) && pass_word.length >= 8) {
      axiosInstance
        .post("/supplier/signup", {
          email: user_name,
          password: pass_word,
        })
        .then((res) => {
          enqueueSnackbar("Successfully signed up to elecbits!", {
            variant: "success",
          });
          setLoading(false);
          props.history.push("/supplier/login");
          props.toggleLoggedIn();
        })
        .catch((err) => {
          let message;
          if (err.response && err?.response?.data) {
            message = err.response.data.err.message;
          }

          // enqueueSnackbar("Not able to signIn!", { variant: "error" });
          if (message) {
            enqueueSnackbar(`${message}`, { variant: "error" });
          }
          setLoading(false);
        });
    } else {
      enqueueSnackbar("Give a valid email and password", { variant: "error" });
      setLoading(false);
    }
  };

  let googleSubmit = (accessToken) => {
    setLoading(true);
    axiosInstance
      .post("/supplier/googleLogin", {
        tokenId: accessToken,
      })
      .then((res) => {
        console.log(res);
        enqueueSnackbar("Successfully signed in to elecbits!", {
          variant: "success",
        });
        setLoading(false);
        const newToken = res.data.token;
        axiosInstance.interceptors.request.use((config) => {
          config.headers.Authorization = `Bearer ${newToken}`;
          return config;
        });

        let isAdmin = res.data.user.admin;
        localStorage.setItem("token", newToken);
        localStorage.setItem("admin", isAdmin);
        localStorage.setItem("supplier", true);
        props.history.push("/");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("Not able to signIn!", { variant: "error" });
        setLoading(false);
      });
  };
  console.log(password_valid);
  return (
    <SnackbarProvider maxSnack={3}>
      <div className={classes.root}>{loading ? <LinearProgress /> : ""}</div>
      <div className="signUpWrapper">
        <div className="content_wrap_supplier">
          <div className="leftt_content_login_supplier">
            {/* <div className="signUpheaderLogin">
              <img src={ElecbitsWhite}></img>
              <p>
                Develop and Manufacture Electronics Just Like You Order a 🍕
                Online
              </p>
            </div> */}
          </div>
          <div className="main_content">
            <div className="l_body">
              <div className="signUp_head">Sign Up for Elecbits</div>
              <div className="secondary_head">
                Sign up to produce IoT products like one, two, three
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (!isRobot) {
                    submit(username, password);
                  } else {
                    enqueueSnackbar("Verify your identity first", {
                      variant: "error",
                    });
                  }
                }}
              >
                <TextField
                  error={email_valid}
                  helperText="Your Email Id"
                  required
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  className={classes.margin}
                  onChange={(e) => {
                    setUsername(e.target.value);
                    if (!EmailValidator.validate(e.target.value)) {
                      set_valid(true);
                    } else {
                      set_valid(false);
                    }
                  }}
                />
                <TextField
                  error={password_valid}
                  required
                  type="password"
                  id="outlined-basic"
                  label="Password"
                  variant="outlined"
                  helperText="Should include atleast 8 characters"
                  className={classes.margin}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    if (e.target.value.length >= 8) {
                      set_pass_valid(false);
                    } else {
                      set_pass_valid(true);
                    }
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                  }}
                >
                  <ReCAPTCHA
                    sitekey="6LcesmscAAAAANUd6XKfdq9Z8OBWHFJecz6oTg0H"
                    onChange={(value) => {
                      console.log("Captcha value:", value);
                      setIsRobot(false);
                    }}
                  />
                </div>
                <ColorButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.margin}
                >
                  Sign Me Up
                </ColorButton>
                <div className="or_opt">
                  <span>OR</span>
                </div>
                <GoogleLogin
                  clientId="574459066254-4pdosurnbhcdkblbc7rqhcd3eq8nk96t.apps.googleusercontent.com"
                  render={(renderProps) => (
                    <LightColorButton
                      variant="contained"
                      color="primary"
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                      className={classes.margin}
                    >
                      <img
                        style={{ width: "30px", marginRight: "10px" }}
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/320px-Google_%22G%22_Logo.svg.png?1629537112517"
                      ></img>{" "}
                      Sign in with GOOGLE
                    </LightColorButton>
                  )}
                  disabled={isRobot}
                  buttonText="Login"
                  onSuccess={(resp) => {
                    console.log(resp);
                    if (!isRobot) {
                      googleSubmit(resp.tokenId);
                    } else {
                      enqueueSnackbar("Verify your identity first");
                    }
                  }}
                  onFailure={(resp) => {
                    console.log(resp);
                  }}
                  cookiePolicy={"single_host_origin"}
                />

                {/* <LightColorButton
              variant="contained"
              color="primary"
              className={classes.margin}
            >
             <img style={{width:'30px',marginRight:'10px'}} src="https://facebookbrand.com/wp-content/uploads/2019/04/f_logo_RGB-Hex-Blue_512.png?w=512&h=512"></img> Sign Up with FACEBOOK
            </LightColorButton> */}
              </form>
              <div style={{ marginBottom: "20px" }} className="sign_up">
                Already signed up,click{" "}
                <Link to="/supplier/login">
                  <u style={{ color: "black" }}>here</u>
                </Link>{" "}
                to login
              </div>
            </div>
          </div>
        </div>
      </div>
    </SnackbarProvider>
  );
}
