import React, { useState, useEffect } from "react";
import "../HomeComponent/Home.css";
import { axiosInstance } from "../utils/axiosInterceptor";
import { Container, Row, Col } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import PCBImage from "../assests/pcbPrinting.svg";
import AdminDashboardHeader from "../ClientEngagement/AdminDashboardHeader";
import CircularProgress from "@material-ui/core/CircularProgress";
import DashboardLogo1 from "../assests/elecbitsLogo.svg";

import "./ServiceView.css";

const StyledButton = withStyles({
  root: {
    background: "#6200EE",
    color: "white",
    "&:hover": {
      backgroundColor: "#6200EE",
    },
  },
})(Button);

const options = [
  {
    name: "Client Engagement",
    value: "cl",
    link: "/clientEngagement",
    src: PCBImage,
  },
  { name: "PCB Arena", value: "pb", link: "/pcbMarket", src: PCBImage },
];
export default function ServiceView(props) {
  const [profile_data, set_profile] = useState(null);
  const [loading, set_loading] = useState(false);
  let toggleLoggedIn = props.children[3];
  let history = props.children[1];

  let fetchProfile = () => {
    set_loading(true);
    axiosInstance
      .get("/users/getProfile")
      .then((resp) => {
        set_profile(resp.data);
        set_loading(false);
      })
      .catch((err) => {
        set_loading(false);
      });
  };

  useEffect(() => {
    console.log(props, props.toggleLoggedIn, "s");
    fetchProfile();
  }, []);
  return (
    <div className="console_wrapper">
      {!loading&&profile_data ? (
        <>
          <div className="header">
            <AdminDashboardHeader
              profile_data={profile_data.data}
              toggleLoggedIn={toggleLoggedIn}
              history={history}
              isBlack
            ></AdminDashboardHeader>
          </div>
          <Container>
            <Row>
              <Col>
                <div className="cconsole_head">EB Management Console</div>
              </Col>
            </Row>
            <Row>
              <Col md={12} sm={12}>
                <div className="sservice_finder">
                  <div className="find_serve_text">Find Your service</div>
                  <Autocomplete
                    //   onChange={(event, value) => console.log(value)}
                    id="combo-box-demo"
                    options={options}
                    getOptionLabel={(option) => option.name}
                    autoHighlight
                    renderOption={(option) => (
                      <React.Fragment>
                        <span
                          style={{ cursor: "pointer", width: "100%" }}
                          onClick={() => {
                            console.log(option.link, props.history);
                            history.push(option.link);
                          }}
                        >
                          {option.name}
                        </span>
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        value={params.value}
                        {...params}
                        label="Search for services, features, products and docs.."
                        variant="outlined"
                      />
                    )}
                  />
                  <div className="find_serve_description">
                    Try searching for keywords like PCB Designing,
                    Certifications,
                  </div>
                </div>
                <div className="aallServices">
                  <div className="find_serve_text">All Services</div>
                  <Container className="section_type">
                    <Row className="serve_sec_head">Design and Development</Row>
                    <Row style={{ marginLeft: "-24px" }}>
                      {options.map((option) => {
                        return (
                          <Col md={6}>
                            <Link to={`${option.link}`}>
                              <div className="service_text">
                                <div>
                                  <img src={option.src}></img>
                                </div>
                                <div className="service_text_text">
                                  {option.name}
                                </div>
                              </div>
                            </Link>
                          </Col>
                        );
                      })}
                    </Row>
                  </Container>
                </div>
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        <div id="loader">
          <img src={DashboardLogo1} style={{ width: "150px" }}></img>
          <CircularProgress style={{ marginLeft: "20px" }}></CircularProgress>
        </div>
      )}
    </div>
  );
}
