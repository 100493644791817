import React, { useEffect, useState } from "react";
import PCBMarketNewHeader from "./PCBMarketNewHeader";
import { axiosInstance } from "../utils/axiosInterceptor";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import {
  BrowserRouter as Router,
  Route,
  useLocation,
  Switch,
} from "react-router-dom";
import { Typography, Box } from "@mui/material";
import AddProduct from "./AddProduct";
import DashboardLogo1 from "../assests/elecbitsLogo.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createTheme } from "@material-ui/core";
import { ThemeProvider } from "@mui/material/styles";
import { Row, Col } from "react-bootstrap";
import { Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
  },
});

export default function ManageProducts(props) {
  const [loading, set_loading] = useState(true);
  const [profile_data, set_profile] = useState(null);
  const [products, setProducts] = useState(null);
  const [loadingProducts, setLoadingProducts] = useState(false);

  let fetchProfile = () => {
    set_loading(true);
    axiosInstance
      .get("/users/getProfile")
      .then((resp) => {
        set_profile(resp.data);
        set_loading(false);
      })
      .catch((err) => {
        set_loading(false);
      });
  };
  const getProducts = () => {
    setLoadingProducts(true);
    axiosInstance
      .get("/products/getProducts")
      .then((resp) => {
        setLoadingProducts(false);
        setProducts(resp.data.response);
        console.log(resp.data.response);
      })
      .catch((err) => {
        setLoadingProducts(false);
      });
  };
  const deleteProduct = (productId) => {
    axiosInstance
      .delete(`/products/deleteProduct/${productId}`)
      .then((resp) => {
        setProducts(resp.data.response);
        console.log(resp.data.response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchProfile();
    getProducts();
  }, []);
  const pathname = useLocation();
  return (
    <div>
      {!loading && profile_data && products ? (
        <>
          <div className="main_adminPcb_body">
            <Switch>
              <Route
                path="/pcbMarket/manageProducts"
                exact
                children={(props) => {
                  return (
                    <>
                      <Box
                        sx={{
                          fontSize: "1.8rem",
                          fontWeight: "bold",
                          margin: "2rem 2.8rem",
                        }}
                      >
                        <Row>
                          <Col>
                            <div
                              style={{
                                display: "flex",

                                alignItems: "center",
                                height: "100%",
                              }}
                            >
                              <h4>Elecbits PCB Arcade - Manage Products</h4>
                            </div>
                          </Col>
                          <Col>
                            <div
                              style={{
                                float: "right",
                                display: "flex",
                                alignItems: "center",
                                height: "100%",
                              }}
                            >
                              <ThemeProvider theme={theme}>
                                <Link to="/pcbMarket/addProduct">
                                  <Button
                                    variant="contained"
                                    endIcon={<AddIcon />}
                                    style={{
                                      backgroundColor: "#fca702",
                                      color: "#000000",
                                    }}
                                  >
                                    Add Product
                                  </Button>
                                </Link>
                              </ThemeProvider>
                            </div>
                          </Col>
                        </Row>
                        <Grid
                          container
                          spacing={{ xs: 2, md: 3 }}
                          columns={{ xs: 4, sm: 8, md: 12 }}
                          style={{ marginTop: "20px" }}
                        >
                          {products.map((product, index) => (
                            <Grid
                              item
                              xs={2}
                              sm={4}
                              md={4}
                              key={index}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                width: "100%",
                              }}
                            >
                              <Card
                                style={{ width: "800px" }}
                                sx={{ maxWidth: 345 }}
                              >
                                <CardMedia
                                  component="img"
                                  width="auto"
                                  height="300px"
                                  style={{ padding: "20px" }}
                                  image={product.product_images[0].location}
                                />
                                <CardContent>
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    component="div"
                                  >
                                    {product.short_name}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                  >
                                    {product.sku_name}
                                  </Typography>
                                </CardContent>
                                <CardActions>
                                  <Link
                                    to={`/pcbMarket/editProduct/${product._id}`}
                                  >
                                    <Button size="small">EDIT</Button>
                                  </Link>
                                  <Link to="/pcbMarket">
                                    <Button
                                      size="small"
                                      onClick={() => deleteProduct(product._id)}
                                    >
                                      DELETE
                                    </Button>
                                  </Link>
                                </CardActions>
                              </Card>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    </>
                  );
                }}
              ></Route>

              <Route
                path="/pcbMarket/addProduct"
                exact
                children={(props) => {
                  return <AddProduct history={props.history}></AddProduct>;
                }}
              ></Route>
            </Switch>
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <img src={DashboardLogo1} style={{ width: "150px" }}></img>
          <CircularProgress style={{ marginLeft: "20px" }}></CircularProgress>
        </div>
      )}
    </div>
  );
}
