import { Box, styled, useTheme } from '@mui/material';
import {
  useEffect,
  useState,
  useMemo,
  useCallback,
  memo,
  createContext,
} from 'react';
import { axiosInstance } from '../utils/axiosInterceptor';
import './explorer.css';
import QuotationsTable from './QuotationsTable';
import { useParams, use } from 'react-router-dom';
import OrderSummary from './OrderSummary';
import moment from 'moment';
import InfoLogo from '../assests/InfoLogo.svg';
import TextField from '@mui/material/TextField';
import { SnackbarProvider, useSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import '../ClientEngagement/dashboard.css';
import DashboardHeader from '../ClientEngagement/DashboardHeader';
import DashboardHeaderAdmin from '../ClientEngagement/AdminDashboardHeader';
import { Tooltip } from '@mui/material';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { Paper, Modal } from '@material-ui/core';
import useErrorHandler from '../Hooks/useErrorHandler';
import CustomBreadcrumbs from '../Components/CustomBreadcrumbs';
import BomOrderSummary from './BomOrderSummary';
import ClosedProject from '../Components/ClosedProject';

//for order summary
const initialVendorSelection = {
  componentOrder: [],
  cost: 0,
  totalCost: 0,
  shippingCost: 0,
  grandTotal: 0,
  totalBomItems: 0,
  totalItemsSelected: 0,
  isMagic: false,
};

//for quotationsTable
const initialQuotesData = {
  componentQuoptes: [
    {
      component: {
        compName: '',
        mountingType: '',
        package: '',
        make: '',
        designator: '',
        compDescription: '',
        quantity: 0,
        mpn: '',
      },
      quotes: [
        {
          id: 0,
          vendorName: '',
          vendorLocation: '',
          leadTime: 0,
          mom: 0,
          cost: 0,
          totalCost: 0,
          shippingCost: 0,
          isFav: false,
        },
      ],
    },
  ],
};

const Quotations = ({ isAdmin, history, toggleLoggedIn }) => {
  const [vendorSelect, setVendorSelect] = useState(initialVendorSelection);
  const [count, setCount] = useState(0);
  const [project, setProject] = useState(null);
  const [orderDetail, setOrderDetail] = useState(null);
  const [isClose, setIsClose] = useState(false);
  const [disableButtons, setDisableButtons] = useState(false);
  const [componentData, setComponentData] = useState(
    initialQuotesData.componentQuoptes[0].component
  );

  const [shippingCostModal, setshippingCostModal] = useState(false);
  const openShippingCostModal = () => setshippingCostModal(true);
  const closeShippingCostModal = () => setshippingCostModal(false);

  const [addComponentModal, setAddComponentModal] = useState(false);
  const openAddComponentModal = () => setAddComponentModal(true);
  const closeAddComponentModal = () => setAddComponentModal(false);

  const [shippingCost, setShippingCost] = useState(0);
  const [shippingDescription, setShippingDescription] = useState('');
  const [isShippingError, shippingHelperText, shippingErrorHandler] =
    useErrorHandler();
  const [isQuantityError, QuantityHelperText, QuantityErrorHandler] =
    useErrorHandler();
  const [defaultVendorSelect, setDefaultVendorSelect] = useState([
    '6267e5e66f62fb8d5e749d5b',
  ]);
  // const []

  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [quotes, setQuotes] = useState([]);
  const [isMagicSelected, setIsMagicSelected] = useState(false);
  const [prof_loading, setLoading] = useState(true);
  const [profile_data, set_profile] = useState(null);
  const [selectMode, setSelectMode] = useState(false);
  const { breakpoints } = useTheme();

  const toggleSelectMode = () => {
    if (selectMode == true) {
      setVendorSelect(initialVendorSelection);
      setSelectMode(!selectMode);
    } else {
      setSelectMode(!selectMode);
    }
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    backgroundColor: '#fff',
    boxShadow: 24,
    p: 4,
    borderRadius: '3px',
  };

  let fetchProfile = () => {
    setLoading(true);
    axiosInstance
      .get('/users/getProfile')
      .then((resp) => {
        set_profile(resp.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const singleProject = async () => {
    const id = window.location.pathname.split('/')[4];
    console.log(id);
    try {
      const res = await axiosInstance.get(`/bom/client/singleProject/${id}`);
      console.log(res);
      setProject(res.data.project);
      setIsClose(res.data?.project?.isClosed);

      console.log(res);
    } catch (e) {
      console.log(e);
    }
  };
  console.log(project);
  const OrderDetail = async () => {
    const id = window.location.pathname.split('/')[4];
    console.log(id);
    try {
      const res = await axiosInstance.get(`/bom/client/getOrderDetails/${id}`);
      setOrderDetail(res.data);
      console.log(res, 'yoooooooo');
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    singleProject();
    fetchProfile();
  }, []);

  useEffect(() => {
    if (project?.orderDetail) {
      OrderDetail();
    }
  }, [project]);

  useEffect(() => {
    vendorSelect.isMagic = isMagicSelected;
  }, [isMagicSelected]);
  let quotationsresponse;
  const fetchQuotes = async () => {
    const res = await axiosInstance.get(`/bom/quotations/${id}`);
    console.log(res);
    quotationsresponse = res?.data?.quotationsResponse;
    console.log(quotationsresponse);
    setVendorSelect((v) => ({
      ...v,
      totalBomItems: quotationsresponse.length,
    }));
    setQuotes(quotationsresponse);
    console.log(quotes);
  };
  useEffect(() => {
    fetchQuotes();
  }, [id]);

  console.log(vendorSelect);
  console.log(count);

  const shippingSubmitHadler = async () => {
    const body = {
      shippingCost,
      shippingDescription,
    };
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const res = await axiosInstance.post(
        `/bom/admin/addShippingCost/${id}`,
        JSON.stringify(body),
        config
      );
      console.log(res);
      if (res.status) {
        enqueueSnackbar('Shipping Cost Added Successfully', {
          variant: 'success',
        });
      }
      closeShippingCostModal();
    } catch (e) {
      console.log(e);
    }
  };

  const submitHandler = async () => {
    try {
      const body = JSON.stringify(vendorSelect);
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const res = await axiosInstance.post(
        `/bom/client/addOrderDetail/${id}`,
        body,
        config
      );

      console.log(res);
      enqueueSnackbar('Order Placed Successfully', { variant: 'success' });

      if (isAdmin) {
        history.push(`/admin/bomExplorer/timeline/${id}`);
      } else {
        history.push(`/bomExplorerTimeline/${id}`);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const myCall = (someFunc) => {
    someFunc();
  };
  // useEffect(() => {
  //   if(orderDetail?.project !== undefined) setDisableButtons(true)
  // }, [disableButtons]);

  const submitComponentHandler = async () => {
    console.log(componentData);
    const res = await axiosInstance.post(
      `bom/admin/quotationsUpload/${project._id}`,
      {
        componentQuotes: {
          component: componentData,
          quotes: [],
        },
      }
    );
    console.log(res);
    window.location.reload();
  };

  const [magicInfoAnchor, setMagicInfoAnchor] = useState(null);

  const attachMagicAnchor = (e) => {
    setMagicInfoAnchor(e.currentTarget);
  };
  const closeMagicInfoPopover = (e) => {
    setMagicInfoAnchor(null);
  };

  const magicInfoPopoverOpen = Boolean(magicInfoAnchor);
  const magicElementId = magicInfoPopoverOpen ? 'simple-popover' : undefined;
  return (
    <Box sx={isClose ? { pointerEvents: 'none' } : {}}>
      {isClose && <ClosedProject />}

      {!prof_loading && profile_data && (
        <div>
          <div className="header">
            {profile_data.data.admin ? (
              <DashboardHeaderAdmin
                profile_data={profile_data.data}
                toggleLoggedIn={toggleLoggedIn}
                history={history}
                isBlack={true}
              ></DashboardHeaderAdmin>
            ) : (
              <DashboardHeader
                profile_data={profile_data.data}
                toggleLoggedIn={toggleLoggedIn}
                history={history}
                isBlack={true}
              ></DashboardHeader>
            )}
          </div>
          {orderDetail ? (
            // quptation page after order placed
            <Box
              className="flex justify-between"
              sx={{
                margin: '3rem',
                [breakpoints.down('md')]: {
                  margin: '1rem',
                  flexDirection: 'column',
                },
              }}
            >
              <Box className="quotations-main">
                {profile_data.data.admin ? (
                  <CustomBreadcrumbs
                    data={[
                      {
                        name: 'Home',
                        link: '/',
                      },
                      {
                        name: 'EB BOM Explorer',
                        link: '/admin/bomExplorer',
                      },
                      {
                        name: `${project?.projectName}-Project Detail`,
                        link: `/admin/bomExplorerDetail/${project?._id}`,
                      },
                      {
                        name: 'Project Quotation',
                        link: `/admin/bomExplorer/quotation/${project?._id}`,
                      },
                    ]}
                  />
                ) : (
                  <CustomBreadcrumbs
                    data={[
                      {
                        name: 'Home',
                        link: '/',
                      },
                      {
                        name: 'EB BOM Explorer',
                        link: '/bomExplorer',
                      },
                      {
                        name: 'My Projects',
                        link: '/bomExplorer/project',
                      },
                      {
                        name: 'View Details',
                        link: `/bomExplorerTimeline/${project._id}`,
                      },
                      {
                        name: 'View Quotations',
                        link: `/bomExplorer/project/quotations/${project._id}`,
                      },
                    ]}
                  />
                )}

                <p className="quotation-title">Quotations</p>
                <Box
                  sx={{
                    marginTop: '1.5rem',
                    display: 'flex',
                    justifyContent: 'space-between',
                    [breakpoints.down('md')]: {
                      flexDirection: 'column',
                    },
                  }}
                >
                  <div>
                    <p className="quotation-subtitle">
                      {project?.projectName} - {project?.companyName}
                    </p>
                    <p>Number of Boards: {project?.numberOfBoards}</p>
                    <p className="explorer-muted-subtitle">
                      Project created on{' '}
                      {moment(project?.startDate).format('DD/MM/YYYY')}
                    </p>
                    {!isAdmin ? (
                      //quotations page for client
                      <span>
                        <Button
                          variant={isMagicSelected ? 'contained' : 'outlined'}
                          color="primary"
                          style={{ marginTop: '10px', marginRight: '10px' }}
                          onClick={() => setIsMagicSelected(true)}
                        >
                          🔮 MAGIC RECOMMENDATION
                        </Button>
                        <Button
                          // variant="outlined"
                          variant={!isMagicSelected ? 'contained' : 'outlined'}
                          color="primary"
                          style={{ marginTop: '10px', marginRight: '15px' }}
                          onClick={() => setIsMagicSelected(false)}
                        >
                          ✏️ MANUAL SELECTION
                        </Button>
                        {/* <span style={{marginTop: "5px"}}>&#9432;</span> */}
                        {/* <InfoLogo /> */}
                        <Tooltip title="What's this?">
                          <img
                            aria-describedby={magicElementId}
                            onClick={attachMagicAnchor}
                            src={InfoLogo}
                            alt="Information Logo"
                            style={{
                              marginTop: '10px',
                              height: '15px',
                              cursor: 'pointer',
                            }}
                          />
                        </Tooltip>
                        <Popover
                          id={magicElementId}
                          open={magicInfoPopoverOpen}
                          anchorEl={magicInfoAnchor}
                          onClose={closeMagicInfoPopover}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                        >
                          <Typography sx={{ p: 2, width: '450px' }}>
                            There are two ways by which you can place an order
                            for your BOM: <br />
                            <br />
                            1. <b>Magic Recommendation (recommended):</b> <br />
                            Using our intelligent algorithm, we will choose the
                            optimum vendor for you keeping in mind various
                            factors like costing, lead time, quality,
                            reliability etc, thus ensuring a hassle free and
                            reliable BOM procurement. <br />
                            <br />
                            2. <b>Manual Selection: </b> This option gives you
                            complete control over your BOM Procurement process.
                            Go through lead times and pricing from various
                            global vendors and choose a vendor that best suits
                            your requirements.
                          </Typography>
                        </Popover>
                      </span>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ marginTop: '10px', marginRight: '15px' }}
                        onClick={() => setIsMagicSelected(false)}
                      >
                        + ADD COMPONENT
                      </Button>
                    )}
                  </div>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                      [breakpoints.down('md')]: {
                        alignItems: 'flex-start',
                        paddingTop: '10px',
                      },
                    }}
                  >
                    <p id="order-id-title">
                      Order ID: <span id="order-id">{project?.projectId}</span>
                    </p>
                    <a href={project?.bomSheet?.location}>
                      <button className="secondary-btn">DOWNLOAD BOM</button>
                    </a>
                    {isAdmin && (
                      <Box sx={{ width: '30vw', marginTop: '30px' }}>
                        <Box className="summary-container1">
                          <BomOrderSummary
                            submitHandler={submitHandler}
                            vendorSelect={{
                              componentOrder:
                                orderDetail?.project.componentOrder,
                              shippingCost: orderDetail?.project.shippingCost,
                              totalCost: orderDetail?.project.totalCost,
                              grandTotal: orderDetail?.project.grandTotal,
                              cost: orderDetail?.project.cost,
                              totalBomItems: vendorSelect?.totalBomItems,
                              totalItemsSelected:
                                orderDetail?.selectedComponent.length,
                            }}
                            disabled={true}
                            numberOfBoards={project?.numberOfBoards}
                            shippingCost={project?.shippingCost}
                            shippingDescription={project?.shippingDescription}
                            isAdmin={isAdmin}
                            projectName={project?.projectName}
                          />
                        </Box>
                      </Box>
                    )}
                    {/* <Button
                      variant="contained"
                      color="primary"
                      style={{ marginTop: "10px" }}
                      disabled={true}
                      onClick={() => setIsMagicSelected(false)}
                    >
                      normal
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ marginTop: "10px" }}
                      disabled={true}
                      onClick={() => setIsMagicSelected(true)}
                    >
                      magicss
                    </Button> */}
                  </Box>
                </Box>
                <Box sx={{ marginTop: '5rem' }}>
                  {quotes.map((componentQuotes, idx) => {
                    let componentOrdered;
                    let selectedVendor;
                    const orderedCompIds = orderDetail?.selectedComponent.map(
                      (comp) => comp.component
                    );
                    if (
                      orderedCompIds?.includes(componentQuotes.component._id)
                    ) {
                      componentOrdered = true;
                    }
                    orderDetail?.selectedComponent.forEach((obj) => {
                      if (obj.component === componentQuotes.component._id) {
                        selectedVendor = obj.quotation;
                      }
                    });

                    // vendorSelectThis = {
                    //   componentOrder: orderDetail.project.componentOrder,
                    //   shippingCost: orderDetail.project.shippingCost,
                    //   totalCost: orderDetail.project.totalCost,
                    //   grandTotal: orderDetail.project.grandTotal,
                    //   cost: orderDetail.project.cost,
                    //   totalBomItems: vendorSelect.totalBomItems,
                    // }
                    return (
                      <QuotationsTable
                        componentQuotes={componentQuotes}
                        isAdmin={isAdmin}
                        idx={idx}
                        vendorSelect={vendorSelect}
                        setVendorSelect={setVendorSelect}
                        count={count}
                        setCount={setCount}
                        isMagicSelected={isMagicSelected}
                        myCall={myCall}
                        disabled={true}
                        orderDetail={orderDetail}
                        selectedVendor={selectedVendor}
                        componentOrdered={componentOrdered}
                      />
                    );
                  })}
                </Box>
              </Box>

              {!isAdmin && (
                <Box
                  sx={{
                    flex: 0,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    height: 'fit-content',
                    [breakpoints.down('md')]: {
                      justifyContent: 'start',
                    },
                  }}
                >
                  <BomOrderSummary
                    submitHandler={submitHandler}
                    vendorSelect={{
                      componentOrder: orderDetail?.project.componentOrder,
                      shippingCost: orderDetail?.project.shippingCost,
                      totalCost: orderDetail?.project.totalCost,
                      grandTotal: orderDetail?.project.grandTotal,
                      cost: orderDetail?.project.cost,
                      totalBomItems: vendorSelect?.totalBomItems,
                      totalItemsSelected: orderDetail?.selectedComponent.length,
                    }}
                    disabled={true}
                    numberOfBoards={project?.numberOfBoards}
                    shippingCost={project?.shippingCost}
                    shippingDescription={project?.shippingDescription}
                    isAdmin={profile_data.isAdmin}
                    projectName={project?.projectName}
                  />
                </Box>
              )}
            </Box>
          ) : (
            // Quotation page before order placed
            <Box
              className="flex justify-between"
              sx={{
                margin: '3rem',
                [breakpoints.down('md')]: {
                  margin: '1rem',
                  flexDirection: 'column',
                },
              }}
            >
              <Box className="quotations-main">
                {profile_data.data.admin ? (
                  <CustomBreadcrumbs
                    data={[
                      {
                        name: 'Home',
                        link: '/',
                      },
                      {
                        name: 'EB BOM Explorer',
                        link: '/admin/bomExplorer',
                      },
                      {
                        name: `${project?.projectName}-Project Detail`,
                        link: `/admin/bomExplorerDetail/${project?._id}`,
                      },
                      {
                        name: 'Project Quotation',
                        link: `/admin/bomExplorer/quotation/${project?._id}`,
                      },
                    ]}
                  />
                ) : (
                  <CustomBreadcrumbs
                    data={[
                      {
                        name: 'Home',
                        link: '/',
                      },
                      {
                        name: 'EB BOM Explorer',
                        link: '/bomExplorer',
                      },
                      {
                        name: 'My Projects',
                        link: '/bomExplorer/project',
                      },
                      {
                        name: 'View Details',
                        link: `/bomExplorerTimeline/${project?._id}`,
                      },
                      {
                        name: 'View Quotations',
                        link: `/bomExplorer/project/quotations/${project?._id}`,
                      },
                    ]}
                  />
                )}
                <p className="quotation-title">Quotations</p>
                <Box
                  sx={{
                    marginTop: '1.5rem',
                    display: 'flex',
                    justifyContent: 'space-between',
                    [breakpoints.down('md')]: {
                      flexDirection: 'column',
                    },
                  }}
                >
                  <div>
                    <p className="quotation-subtitle">
                      {project?.projectName} - {project?.companyName}
                    </p>
                    <p>Number of Boards: {project?.numberOfBoards}</p>
                    <p className="explorer-muted-subtitle">
                      Project created on{' '}
                      {moment(project?.startDate).format('DD/MM/YYYY')}
                    </p>
                    {!isAdmin ? (
                      //quotations page for client
                      <span>
                        {/* <Button
                          variant="contained"
                          color="primary"
                          style={{ marginTop: "10px", marginRight: "10px" }}
                          onClick={() => setIsMagicSelected(true)}
                        >
                        🔮 MAGIC RECOMMENDATION
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          style={{ marginTop: "10px", marginRight: "15px" }}
                          onClick={() => setIsMagicSelected(false)}
                        >
                          ✏️ MANUAL SELECTION
                        </Button> */}

                        {isMagicSelected ? (
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ marginTop: '10px', marginRight: '10px' }}
                            onClick={() => setIsMagicSelected(true)}
                          >
                            🔮 MAGIC RECOMMENDATION
                          </Button>
                        ) : (
                          <Button
                            variant="outlined"
                            color="primary"
                            style={{ marginTop: '10px', marginRight: '10px' }}
                            onClick={() => setIsMagicSelected(true)}
                          >
                            🔮 MAGIC RECOMMENDATION
                          </Button>
                        )}

                        {isMagicSelected ? (
                          <Button
                            variant="outlined"
                            color="primary"
                            style={{ marginTop: '10px', marginRight: '10px' }}
                            onClick={() => setIsMagicSelected(false)}
                          >
                            ✏️ MANUAL SELECTION
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ marginTop: '10px', marginRight: '10px' }}
                            onClick={() => setIsMagicSelected(false)}
                          >
                            ✏️ MANUAL SELECTION
                          </Button>
                        )}
                        <Tooltip title="What's this?">
                          <img
                            aria-describedby={magicElementId}
                            onClick={attachMagicAnchor}
                            src={InfoLogo}
                            alt="Information Logo"
                            style={{
                              marginTop: '10px',
                              height: '15px',
                              cursor: 'pointer',
                            }}
                          />
                        </Tooltip>
                        <Popover
                          id={magicElementId}
                          open={magicInfoPopoverOpen}
                          anchorEl={magicInfoAnchor}
                          onClose={closeMagicInfoPopover}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                        >
                          <Typography sx={{ p: 2, width: '450px' }}>
                            There are two ways by which you can place an order
                            for your BOM: <br />
                            <br />
                            1. <b>Magic Recommendation (recommended):</b> <br />
                            Using our intelligent algorithm, we will choose the
                            optimum vendor for you keeping in mind various
                            factors like costing, lead time, quality,
                            reliability etc, thus ensuring a hassle free and
                            reliable BOM procurement. <br />
                            <br />
                            2. <b>Manual Selection: </b> This option gives you
                            complete control over your BOM Procurement process.
                            Go through lead times and pricing from various
                            global vendors and choose a vendor that best suits
                            your requirements.
                          </Typography>
                        </Popover>
                      </span>
                    ) : (
                      <span>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ marginTop: '10px', marginRight: '15px' }}
                          onClick={() => openAddComponentModal()}
                        >
                          + ADD COMPONENT
                        </Button>
                        <Button
                          variant="contained"
                          // color="#FF972A"
                          sx={{
                            marginTop: '10px',
                            marginRight: '15px',
                          }}
                          onClick={toggleSelectMode}
                        >
                          SELECTION MODE: {selectMode ? 'ON' : 'OFF'}
                        </Button>
                        <Modal
                          open={addComponentModal}
                          onClose={closeAddComponentModal}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Paper
                            variant="outlined"
                            elevation={2}
                            style={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              width: 800,
                              padding: '1.5rem',
                              // bgcolor: 'background.paper',
                              // border: '2px solid #000',
                              boxShadow: 24,
                            }}
                          >
                            <p className="quotation-title">Add a Component</p>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                              }}
                            >
                              <TextField
                                id="outlined-basix"
                                label="Component Name"
                                variant="outlined"
                                value={componentData.compName}
                                sx={{
                                  width: '45%',
                                  margin: '0rem 1rem 1rem 0rem',
                                }}
                                onChange={(e) => {
                                  setComponentData({
                                    ...componentData,
                                    compName: e.target.value,
                                  });
                                }}
                              />
                              <TextField
                                id="outlined-basic"
                                label="Mounting Type"
                                variant="outlined"
                                value={componentData.mountingType}
                                onChange={(e) =>
                                  setComponentData({
                                    ...componentData,
                                    mountingType: e.target.value,
                                  })
                                }
                                sx={{
                                  width: '45%',
                                  margin: '0rem 1rem 1rem 0rem',
                                }}
                              />
                              <TextField
                                id="outlined-basic"
                                label="package"
                                variant="outlined"
                                value={componentData.package}
                                onChange={(e) =>
                                  setComponentData({
                                    ...componentData,
                                    package: e.target.value,
                                  })
                                }
                                sx={{
                                  width: '45%',
                                  margin: '0rem 1rem 1rem 0rem',
                                }}
                              />
                              <TextField
                                id="outlined-basic"
                                label="Make"
                                variant="outlined"
                                value={componentData.make}
                                onChange={(e) =>
                                  setComponentData({
                                    ...componentData,
                                    make: e.target.value,
                                  })
                                }
                                sx={{
                                  width: '45%',
                                  margin: '0rem 1rem 1rem 0rem',
                                }}
                              />
                              <TextField
                                id="outlined-basic"
                                label="designator"
                                variant="outlined"
                                value={componentData.designator}
                                onChange={(e) =>
                                  setComponentData({
                                    ...componentData,
                                    designator: e.target.value,
                                  })
                                }
                                sx={{
                                  width: '45%',
                                  margin: '0rem 1rem 1rem 0rem',
                                }}
                              />
                              <TextField
                                id="outlined-basic"
                                label="MPN"
                                value={componentData.mpn}
                                onChange={(e) => {
                                  setComponentData({
                                    ...componentData,
                                    mpn: e.target.value,
                                  });
                                  // MPNErrorHandler(e);
                                }}
                                // error={isMPNError}
                                // helperText={MPNHelperText}
                                variant="outlined"
                                sx={{
                                  width: '45%',
                                  margin: '0rem 1rem 1rem 0rem',
                                }}
                              />
                              <TextField
                                id="outlined-basic"
                                label="Quantity"
                                variant="outlined"
                                value={componentData.quantity}
                                onChange={(e) => {
                                  setComponentData({
                                    ...componentData,
                                    quantity: e.target.value,
                                  });
                                  QuantityErrorHandler(e);
                                }}
                                error={isQuantityError}
                                helperText={QuantityHelperText}
                                sx={{
                                  width: '45%',
                                  margin: '0rem 1rem 1rem 0rem',
                                }}
                              />
                              <TextField
                                id="outlined-basic"
                                label="Component Description"
                                variant="outlined"
                                value={componentData.compDescription}
                                onChange={(e) =>
                                  setComponentData({
                                    ...componentData,
                                    compDescription: e.target.value,
                                  })
                                }
                                sx={{
                                  width: '45%',
                                  margin: '0rem 1rem 1rem 0rem',
                                }}
                                type="text"
                              />
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <Button
                                variant="outlined"
                                sx={{
                                  marginRight: '0.5rem',
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                variant="contained"
                                sx={{
                                  marginLeft: '0.5rem',
                                }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  submitComponentHandler();
                                }}
                              >
                                Submit
                              </Button>
                            </Box>
                          </Paper>
                        </Modal>
                      </span>
                    )}
                  </div>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                      [breakpoints.down('md')]: {
                        alignItems: 'flex-start',
                        paddingTop: '10px',
                      },
                    }}
                  >
                    <p id="order-id-title">
                      Order ID: <span id="order-id">{project?.projectId}</span>
                    </p>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'content',
                        alignItem: 'center',
                      }}
                    >
                      <a href={project?.bomSheet?.location}>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            marginTop: '10px',
                            marginRight: '10px',
                            lineHeight: '1rem',
                          }}
                        >
                          DOWNLOAD BOM
                        </Button>
                      </a>
                      {isAdmin && (
                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            marginTop: '10px',
                            marginRight: '0px',
                            lineHeight: '1rem',
                          }}
                          onClick={openShippingCostModal}
                        >
                          🚢 ADD SHIPPING COST
                        </Button>
                      )}
                    </Box>
                    {isAdmin && (
                      <Box sx={{ width: '30vw', marginTop: '30px' }}>
                        <Box className="summary-container1">
                          {/* <OrderSummary
                            submitHandler={submitHandler}
                            vendorSelect={vendorSelect}
                            numberOfBoards={project?.numberOfBoards}
                            shippingCost={project?.shippingCost}
                            shippingDescription={project?.shippingDescription}
                            isAdmin={profile_data.data.admin}
                          /> */}
                          <BomOrderSummary
                            submitHandler={submitHandler}
                            vendorSelect={vendorSelect}
                            numberOfBoards={project?.numberOfBoards}
                            shippingCost={project?.shippingCost}
                            shippingDescription={project?.shippingDescription}
                            isAdmin={profile_data.data.admin}
                            projectName={project?.projectName}
                          />
                        </Box>
                      </Box>
                    )}
                  </Box>
                  <Modal
                    open={shippingCostModal}
                    onClose={closeShippingCostModal}
                    aria-labelledby="modal-modal-title"
                  >
                    <Box sx={style}>
                      <p style={{ textAlign: 'center' }} id="modal-modal-title">
                        Shipping Cost 🚢
                      </p>
                      <Paper>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            flexDirection: 'column',
                          }}
                        >
                          <TextField
                            id="outlined-multiline-static"
                            label="Shipping Cost Description"
                            multiline
                            rows={4}
                            onChange={(e) => {
                              setShippingDescription(e.target.value);
                            }}
                            sx={{
                              margin: '1rem 1rem 1rem 1rem',
                              width: '94%',
                            }}
                          />
                          <TextField
                            id="outlined-basic"
                            label="Total Shipping Cost"
                            variant="outlined"
                            onChange={(e) => {
                              setShippingCost(e.target.value);
                              shippingErrorHandler(e);
                            }}
                            helperText={shippingHelperText}
                            error={isShippingError}
                            sx={{
                              width: '40%',
                              margin: '0rem 1rem 1rem 1rem',
                            }}
                          />
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              width: '40%',
                              margin: '0rem 1rem 1rem 1rem',
                            }}
                          >
                            <Button
                              onClick={shippingSubmitHadler}
                              variant="contained"
                            >
                              Confirm
                            </Button>
                            <Button
                              sx={{
                                backgroundColor: 'gray',
                                '&:hover': {
                                  backgroundColor: 'gray',
                                },
                              }}
                              variant="contained"
                              onClick={closeShippingCostModal}
                            >
                              Cancel
                            </Button>
                          </Box>
                        </Box>
                      </Paper>
                    </Box>
                  </Modal>
                </Box>
                <Box sx={{ marginTop: '5rem' }}>
                  {quotes.map((componentQuotes, idx) => {
                    return (
                      <QuotationsTable
                        componentQuotes={componentQuotes}
                        componentId={componentQuotes?.component._id}
                        projectId={project?._id}
                        isAdmin={isAdmin}
                        idx={idx}
                        vendorSelect={vendorSelect}
                        setVendorSelect={setVendorSelect}
                        count={count}
                        setCount={setCount}
                        isMagicSelected={isMagicSelected}
                        myCall={myCall}
                        defaultVendorSelect={defaultVendorSelect}
                        setDefaultVendorSelect={setDefaultVendorSelect}
                        fetchQuotes={fetchQuotes}
                        shippingCost={project?.shippingCost}
                        adminSelectMode={selectMode}
                      />
                    );
                  })}
                </Box>
              </Box>
              {!isAdmin && (
                <BomOrderSummary
                  submitHandler={submitHandler}
                  vendorSelect={vendorSelect}
                  numberOfBoards={project?.numberOfBoards}
                  shippingCost={project?.shippingCost}
                  shippingDescription={project?.shippingDescription}
                  isAdmin={profile_data.data.admin}
                  projectName={project?.projectName}
                />
              )}
            </Box>
          )}
        </div>
      )}
    </Box>
  );
};

export default Quotations;
