import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import IconButton from '@material-ui/core/IconButton';
import './header.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Slide from '@material-ui/core/Slide';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { axiosInstance } from '../utils/axiosInterceptor';
import { SnackbarProvider, useSnackbar } from 'notistack';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import elecbitsLogoWhite from '../assests/elecbitsLogoWhite.svg';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  theming: {
    color: 'white',
    backgroundColor: 'black',
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  rootLoad: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  typography: {
    padding: theme.spacing(1.4),
    textAlign: 'center',
  },
  formControl: {
    marginBottom: theme.spacing(2.5),
    minWidth: '100%',
  },
}));

export default function PCBMarketNewHeader(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedDate, handleDateChange] = useState(new Date());
  const [topic, setTopic] = useState('');
  const [loading, setLoading] = useState(false);
  const [projectData, setProjectData] = useState(null);
  const [project, setProject] = useState(null);

  let handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  let handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event) => {
    setProject(event.target.value);
  };
  let fetchProject = () => {
    axiosInstance
      .get('/projects/getOngoingProject')
      .then((resp) => {
        setProjectData(resp.data.response[0]);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    fetchProject();
  }, []);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <div
      style={props.isBlack ? { backgroundColor: 'black' } : {}}
      className="header_wrapper"
    >
      <Link to="/">
        <div>
          <img
            src={
              props.isBlack
                ? elecbitsLogoWhite
                : 'https://elecbits.in/wp-content/uploads/2021/06/elecbits_new-1.png'
            }
            style={{ width: '160px' }}
          ></img>
        </div>
      </Link>
      <Dialog
        open={dialogOpen}
        fullWidth
        maxWidth="md"
        TransitionComponent={Transition}
        onClose={() => {
          setDialogOpen(false);
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div className={classes.rootLoad} style={{ position: 'absolute' }}>
          {loading ? <LinearProgress /> : ''}
        </div>
        <DialogTitle id="form-dialog-title">Book A Consultation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Talk to experts from Electbits.We are always there to solve your
            problems.
          </DialogContentText>
          <form
            onkeydown="return event.key != 'Enter"
            onSubmit={(e) => {
              e.preventDefault();
              setLoading(true);
              axiosInstance
                .post('/bookConsultation', {
                  topic: topic,
                  date: selectedDate,
                  projectName: project,
                })
                .then((resp) => {
                  setDialogOpen(false);
                  setLoading(false);
                  enqueueSnackbar(
                    'Request sent successfully! Our team will be soon in touch with you.',
                    {
                      variant: 'success',
                    }
                  );
                })
                .catch((err) => {
                  setLoading(false);
                  enqueueSnackbar('Not able to send request', {
                    variant: 'error',
                  });
                });
            }}
          >
            <FormControl className={classes.formControl} required>
              <InputLabel id="demo-simple-select-label">
                Select Project
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={project}
                onChange={handleChange}
              >
                {projectData && projectData.length > 0 ? (
                  projectData.map((project) => {
                    return (
                      <MenuItem value={project.projectName}>
                        {project.projectName}
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem value={null}>
                    <em>None</em>
                  </MenuItem>
                )}
              </Select>
            </FormControl>

            <TextField
              className={classes.formControl}
              required
              multiline
              margin="dense"
              id="name"
              label="Topic of discussion"
              fullWidth
              value={topic}
              onChange={(e) => {
                setTopic(e.target.value);
              }}
              variant="outlined"
            />
            <DateTimePicker
              className={classes.formControl}
              variant="inline"
              label="Select Your Slot"
              inputVariant="outlined"
              value={selectedDate}
              onChange={handleDateChange}
              required
            />
            <DialogActions>
              <Button
                onClick={() => {
                  setDialogOpen(false);
                }}
                color="primary"
                variant="outlined"
              >
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Book
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
      <div>
        <a href="https://calendly.com/elecbits-saurav/" target="_blank">
          <Button
            className="consult_book_large"
            variant="outlined"
            color="white"
            style={
              props.isBlack
                ? {
                    color: 'white',
                    marginRight: '15px',
                    border: '1px solid white',
                  }
                : { marginRight: '15px' }
            }
          >
            Book a Consultation
          </Button>
        </a>
        <IconButton
          aria-label="delete"
          color="primary"
          className="consult_book_small"
          onClick={() => {
            setDialogOpen(true);
          }}
        >
          <EventAvailableIcon fontSize="large"></EventAvailableIcon>
        </IconButton>
        {props.profile_data.profilePic ? (
          <Button aria-describedby={id} onClick={handleClick}>
            <Avatar
              className={classes.theming}
              src={`${props.profile_data.profilePic}`}
            ></Avatar>
          </Button>
        ) : (
          <Button aria-describedby={id} onClick={handleClick}>
            <Avatar className={classes.theming}>
              {props.profile_data.email[0].toUpperCase()}
            </Avatar>
          </Button>
        )}
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Link to="/profile" style={{ textDecoration: 'none', color: 'black' }}>
          <div className="pop_over_item">
            <Typography className={classes.typography}>
              {props.profile_data.email}
            </Typography>
          </div>
        </Link>

        <div
          className="pop_over_item"
          onClick={() => {
            localStorage.clear();
            props.history.push('/');
            props.toggleLoggedIn();
          }}
        >
          <Typography className={classes.typography}>Logout</Typography>
        </div>
      </Popover>
    </div>
  );
}
