import { styled, Box } from "@mui/material";

export const PageContainer = styled(Box)(()=>({
  width: '100vw',
  height:'100vh',
  display: 'flex',
  flexDirection:'column'
}))

export const ColosseumServicesContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  flex:1,
  padding: "50px 50px",
  fontFamily: "Poppins",
  backgroundColor:'#F4F7FE',

  "& .title": {
    marginTop: "30px",
    fontWeight: 600,
  },
  "& .sub-title": {
    marginTop: "10px",
    color: "#8B8B8B",
    marginBottom: "50px",
  },
  ".card-container": {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    gap: "70px",
  },
  [theme.breakpoints.down("md")]: {
    ".card-container": {
      flexDirection: "column",
    },
  },
}));
