import { Box, Paper, Select } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
// import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { axiosInstance } from "../utils/axiosInterceptor";
import { useSnackbar } from "notistack";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { InputLabel } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { MenuItem } from "@material-ui/core";
import { Grid, Tooltip, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import useErrorHandler from "../Hooks/useErrorHandler";
const QuotationsTable = (props) => {
  const { vendorSelect, setVendorSelect, count, setCount } = props;
  const [isGSTError, GSTHelperText, GSTErrorHandler] = useErrorHandler();
  const [isCostError, costHelperText, costErrorHandler] = useErrorHandler();
  const [isMOQError, MOQHelperText, MOQErrorHandler] = useErrorHandler();
  const [isLeadTimeError, LeadTimeHelperText, LeadtimeErrorHandler] =
    useErrorHandler();
  const { enqueueSnackbar } = useSnackbar();
  let rows = [];
  const { component, quotes } = props.componentQuotes;

  let componentQuote = {
    component: component,
    quote: {},
  };

  useEffect(() => {
    console.log(component, "here is the all mighty");
  }, []);

  quotes.map((quote) => {
    console.log(quote);
    const {
      _id,
      vendorName,
      vendorLocation,
      leadTime,
      cost,
      totalCost,
      moq,
      isFav,
      shippingCost,
    } = quote;
    const completeCost = (cost * component?.quantity)
      .toFixed(2)
      .replace(/[.,]00$/, "");
    const completeCostWithGST = (totalCost * component?.quantity)
      .toFixed(2)
      .replace(/[.,]00$/, "");
    rows.push({
      _id,
      vendorName,
      vendorLocation,
      leadTime,
      cost,
      totalCost,
      moq,
      isFav,
      shippingCost,
      completeCost,
      completeCostWithGST,
    });

    return "";
  });

  const [rowsState] = useState(rows);
  const [sortName, setSortName] = useState(undefined);
  const [sortOrder, setSortOrder] = useState(undefined);
  const [checked, setChecked] = useState(false);
  const [defaultSelected, setDefaultSelected] = useState([]);
  const [unselectable, setUnselectable] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [vendName, setVendName] = useState("");
  const [vendLocation, setVendLocation] = useState("");
  const [leadTime, setLeadTime] = useState("");
  const [moq, setMoq] = useState("");
  const [cost, setCost] = useState("");
  const [totalCost, setTotalCost] = useState("");
  const [isFav, setIsFav] = useState("");

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  useEffect(() => {
    if (props?.disabled === true) {
      //set default selected to selected vendors
      console.log("disabled");
      const { orderDetail } = props;
      console.log(orderDetail);
      setDefaultSelected([...defaultSelected, props.selectedVendor?._id]);

      const unselectableIds = rowsState.map((row) => row._id);
      setUnselectable(unselectableIds);
    }
  }, [props.disabled]);

  useEffect(() => {
    if (props.isMagicSelected) {
      setDefaultSelected(
        rowsState.map((row) => {
          if (row.isFav) return row._id;
        })
      );
    } else {
      setDefaultSelected([]);
      setUnselectable([]);
      setVendorSelect({
        componentOrder: [],
        cost: 0,
        totalCost: 0,
        shippingCost: 0,
        grandTotal: 0,
        totalBomItems: 0,
        totalItemsSelected: 0,
        isMagic: false,
      });
    }
  }, [props.isMagicSelected]);

  useEffect(() => {
    if (props.isMagicSelected) {
      let disableds = [];
      rowsState.forEach((row) => {
        if (row.isFav) {
          onRowSelect(row);
        }
        if (!row.isFav) {
          disableds.push(row._id);
        }
      });
      setUnselectable(disableds);
    }
  }, [props.isMagicSelected]);

  const handleCheckboxChange = () => {
    setChecked(!checked);
    console.log(checked);
    if (checked === true) {
      console.log(vendorSelect.totalItemsSelected);
      // vendorSelect.totalItemsSelected +=  1;
      setVendorSelect((v) => ({
        ...v,
        totalItemsSelected: vendorSelect.totalItemsSelected - 1,
      }));
    } else {
      setVendorSelect((v) => ({
        ...v,
        totalItemsSelected: vendorSelect.totalItemsSelected + 1,
      }));
      console.log(vendorSelect.totalItemsSelected);
      // vendorSelect.totalItemsSelected -= 1;
    }
  };

  const onRowSelect = (row, isSelected, e) => {
    componentQuote.quote = row;
    let found = false;
    console.log(props);
    //check wether an componentQuote object already exisits for this component. If it does, edit that object
    vendorSelect.componentOrder.forEach((v) => {
      if (v.component === componentQuote.component) {
        found = true;
        //from the cost and total cost up until now, remove the previous quote's costs
        vendorSelect.cost -= v.quote.cost * v.component.quantity;
        vendorSelect.totalCost -= v.quote.totalCost * v.component.quantity;
        vendorSelect.shippingCost -= props.shippingCost;
        //add the quote object to the respective component
        v.quote = componentQuote.quote;
        //add the new quote costs to the total costs
        vendorSelect.shippingCost += props.shippingCost;
        vendorSelect.cost =
          Number(vendorSelect.cost) + Number(row.completeCost);
        vendorSelect.totalCost =
          Number(vendorSelect.totalCost) + Number(row.completeCostWithGST);
        vendorSelect.grandTotal =
          vendorSelect.completeCostWithGST + props.shippingCost;
        console.log("here1");
        setVendorSelect(vendorSelect);
      }
    });
    if (!found) {
      vendorSelect.componentOrder.push(componentQuote);
      vendorSelect.cost = Number(vendorSelect.cost) + Number(row.completeCost);
      vendorSelect.totalCost =
        Number(vendorSelect.totalCost) + Number(row.completeCostWithGST);
      vendorSelect.shippingCost += props.shippingCost;
      vendorSelect.grandTotal =
        vendorSelect.completeCostWithGST + props.shippingCost;

      console.log("here2");
    }
    // props.setDefaultVendorSelect([row._id]);
    setDefaultSelected([row._id]);
    console.log(vendorSelect);
    setCount(count + 1);
    setVendorSelect(vendorSelect);
    // setDefaultSelected([...defaultSelected, row._id]);
    setDefaultSelected([row._id]);
    // setState(`hey ${count}`);
  };
  const [toDeleteVendors, setToDeleteVendors] = useState([]);
  const deleteVendorHandler = async (row, isSelected, e) => {
    let temp;
    if (!toDeleteVendors.includes(row._id)) {
      toDeleteVendors.push(row._id);
      temp = toDeleteVendors;
      setToDeleteVendors(temp);
      setToDeleteVendors([...toDeleteVendors, row._id]);
    } else {
      temp = toDeleteVendors.filter((vend) => vend != row._id);
      setToDeleteVendors(temp);
    }
    console.log(setToDeleteVendors);
  };

  useEffect(() => {
    setToDeleteVendors([]);
    // setDefaultSelected([]);
  }, [props.adminSelectMode]);

  const deleteVendorHandlerAPI = async () => {
    const body = {
      toDeleteVendors,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axiosInstance.post(
        `/bom/admin/deleteQuote/${component._id}`,
        body,
        config
      );
      enqueueSnackbar("Vendor deleted successfully", { variant: "success" });
      setTimeout(() => {
        window.location.reload();
      }, 500);
      console.log(res);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (props.isMagicSelected) {
      let disableds = [];
      rowsState.forEach((row) => {
        if (row.isFav && checked) {
          onRowSelect(row);
        }
        if (!row.isFav) {
          disableds.push(row._id);
        }
      });
      setUnselectable(disableds);
    }
  }, [props.isMagicSelected, checked]);

  const sortOptions = {
    sortName,
    sortOrder,
    sortIndicator: true,
    onSortChange: (sortName, sortOrder) => {
      setSortName(sortName);
      setSortOrder(sortOrder);
    },
    onAddRow: (row) => {
      console.log(row, "bababooey");
    },
  };
  const cellEditProps = props.isAdmin
    ? {
        mode: "dbclick",
        blurToSave: true,
        afterSaveCell: async (oldValue, newValue, row, column) => {
          console.log(oldValue, newValue, row, column, "hehehehehe");
          const cellName = column.dataField || "cell";
          const res = await axiosInstance.post(
            `/bom/admin/quote/edit/${component._id}`,
            { row, cellName }
          );
          console.log(res, "this is the result!");
          enqueueSnackbar(res.data?.msg || "Update successfull!", {
            variant: "success",
          });
        },
        // afterSaveCell: async (row, cellName, value) => {
        //   console.log(row);
        //   console.log(cellName);
        //   console.log(value);
        //   const res = await axiosInstance.post(
        //     `/bom/admin/quote/edit/${component._id}`,
        //     { row, cellName }
        //   );
        //   enqueueSnackbar(res.data?.msg);
        // },
      }
    : {};

  const selectRow =
    !props.isAdmin || props.isMagicSelected || props.disabled
      ? {
          mode: "radio",
          clickToSelect: true,
          onSelect: onRowSelect,
          unselectable: unselectable,
          bgColor: "#b1fc95",
          selected: defaultSelected || props.defaultVendorSelect,
        }
      : props.isAdmin && !props.adminSelectMode && !props.disabled
      ? {
          mode: "checkbox",
          clickToSelect: true,
          onSelect: deleteVendorHandler,
          unselectable: unselectable,
          bgColor: "#b1fc95",
          selected: toDeleteVendors,
          clickToEdit: true,
        }
      : props.isAdmin && props.adminSelectMode && !props.disabled
      ? {
          mode: "radio",
          clickToSelect: true,
          onSelect: onRowSelect,
          unselectable: unselectable,
          bgColor: "#b1fc95",
          clickToEdit: true,
          selected:
            defaultSelected.length > 0
              ? defaultSelected
              : [props.selectedVendor],
        }
      : {};
  //adminSelectMode

  const handleAddQuotation = async (e) => {
    e.preventDefault();
    const newQuote = {
      vendName,
      vendLocation,
      leadTime,
      moq,
      cost,
      totalCost,
      isFav,
    };
    console.log(component._id);
    console.log(newQuote);
    const res = await axiosInstance.post(
      `/bom/admin/addQuote/${component._id}`,
      { newQuote }
    );
    console.log(res);
    setIsDialogOpen(false);
    setCount(count + 1);
    enqueueSnackbar(res?.data?.msg, { variant: "success" });
    setTimeout(() => {}, 1000);
    window.location.reload();
  };
  const deleteComponent = async () => {
    try {
      const res = await axiosInstance.delete(
        `/bom/admin/deleteComponent/${props.projectId}/${props.componentId}`
      );

      enqueueSnackbar("component deleted successfully", { variant: "success" });
      setTimeout(() => {
        window.location.reload();
      }, 500);
      console.log(res);
    } catch (e) {
      console.log(e);
    }
  };
  const colWidth = "130";
  const [isQuantityError, QuantityHelperText, QuantityErrorHandler] =
    useErrorHandler();

  const columns = [
    {
      dataField: "_id",
      isKey: true,
      hidden: true,
      autoValue: true,
      width: colWidth,
      text: "ID",
    },
    {
      dataField: "vendorName",
      headerTitle: true,
      width: colWidth,
      text: "Name",
    },
    {
      dataField: "vendorLocation",
      width: colWidth,
      text: "Location ",
    },
    {
      dataField: "leadTime",
      width: 90,
      sort: true,
      text: <div>Lead time (in days) &#8645;</div>
    },
    {
      dataField: "cost",
      sort: true,
      width: 90,
      text: <div>Unit Cost &#8645;</div>,
    },
    {
      dataField: "shippingCost",
      sort: true,
      hidden: true,
      width: colWidth,
      text: <div>Shipping Cost &#8645;</div>,
    },
    {
      dataField: "totalCost",
      headerTitle: true,
      sort: true,
      width: 90,
      text: <div>Unit Cost(incl GST) &#8645;</div>,
    },
    {
      width: 90,
      dataField: "completeCost",
      headerTitle: true,
      sort: true,
      text: <div>Total Cost &#8645;</div>,
    },
    {
      width: colWidth,
      dataField: "completeCostWithGST",
      headerTitle: true,
      sort: true,
      text: <div>Total Cost(incl GST) &#8645;</div>,
    },
    {
      dataField: "moq",
      width: colWidth,
      headerTitle: true,
      text: "MOQ",
    },
    {
      dataField: "isFav",
      width: colWidth,
      hidden: !props.isAdmin,
      text: "isFav",
    },
  ];

  return (
    <Box
      style={{
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        padding: "1rem",
      }}
      className="quote-container"
    >
      <Box className="quote-component-details">
        {/* <p style={{marginRight: "25px"}}>{props.idx+1}.</p> */}
        {/* <Checkbox
          style={{ marginBottom: "68px", marginRight: "17px" }}
          // checked={checked}
          onChange={handleCheckboxChange}
          inputProps={{ "aria-label": "controlled" }}
          checked={checked || (props.disabled && props.componentOrdered)}
          disabled={props.disabled}
        /> */}
        <div>
          {props.isAdmin && !props.disabled && (
            <Button
              onClick={() => deleteComponent()}
              variant="outlined"
              startIcon={<DeleteIcon />}
              sx={{ marginBottom: "1rem" }}
            >
              Delete Component
            </Button>
          )}
          <p style={{ marginBottom: "0px" }}>
            {component.compName} - {component.compDescription}
          </p>

          <Grid container sx={{ marginBottom: "10px" }}>
            <Grid
              item
              sm={4}
              sx={{ display: "flex", alignItems: "center", gap: "5px" }}
              spacing={5}
            >
              <Typography sx={{ color: "#838383" }}>Qty: </Typography>
              <Typography sx={{ color: "#000" }}>
                {component?.quantity}
              </Typography>
            </Grid>
            <Grid
              item
              sm={4}
              sx={{ display: "flex", alignItems: "center", gap: "5px" }}
            >
              <Typography sx={{ color: "#838383" }}>MPN: </Typography>
              <Typography sx={{ color: "#000" }}>{component?.mpn}</Typography>
            </Grid>
            <Grid
              item
              sm={4}
              sx={{ display: "flex", alignItems: "center", gap: "5px" }}
            >
              <Typography sx={{ color: "#838383" }}>Make: </Typography>
              <Typography sx={{ color: "#000" }}>{component?.make}</Typography>
            </Grid>
            <Grid
              item
              sm={4}
              sx={{ display: "flex", alignItems: "center", gap: "5px" }}
            >
              <Typography sx={{ color: "#838383" }}>Package: </Typography>
              <Typography sx={{ color: "#000" }}>
                {component.package}
              </Typography>
            </Grid>
            <Grid
              item
              sm={4}
              sx={{ display: "flex", alignItems: "center", gap: "5px" }}
            >
              <Typography sx={{ color: "#838383" }}>Designator: </Typography>
              <Typography sx={{ color: "#000" }}>
                {component?.designator}
              </Typography>
            </Grid>
            <Grid
              item
              sm={4}
              sx={{ display: "flex", alignItems: "center", gap: "5px" }}
            >
              <Typography sx={{ color: "#838383" }}>Mounting Type: </Typography>
              <Typography sx={{ color: "#000" }}>
                {component?.mountingType}
              </Typography>
            </Grid>
          </Grid>
          {/* <p>{state}</p> */}
        </div>
      </Box>

      {props.isAdmin && !props.disabled ? (
        <Button
          style={{ marginBottom: "1rem" }}
          variant="contained"
          onClick={() => setIsDialogOpen(true)}
        >
          Add Quotation+
        </Button>
      ) : (
        ""
      )}
      <BootstrapTable
        keyField="_id"
        data={rowsState}
        selectRow={selectRow}
        columns={columns}
        cellEdit={cellEditFactory(cellEditProps)}
        hover
      />

      {/* <BootstrapTable
        data={rowsState}
        selectRow={selectRow}
        cellEdit={cellEditProps}
        hover
        options={sortOptions}
        version="4"
        headerStyle={{ fontSize: "0.8rem" }}
        // insertRow={props.isAdmin && !props.disabled}
      >
        <TableHeaderColumn
          width={colWidth}
          isKey={true}
          hidden
          autoValue={true}
          dataField="_id"
        >
          ID{" "}
        </TableHeaderColumn>
        <TableHeaderColumn width={colWidth} dataField="vendorName" columnTitle>
          Name{" "}
        </TableHeaderColumn>
        <TableHeaderColumn width={colWidth} dataField="vendorLocation">
          Location
        </TableHeaderColumn>
        <TableHeaderColumn width="90" dataField="leadTime" dataSort={true}>
          <Tooltip title="Lead time (in days) &#8645;">
            <span>Lead time (in days) &#8645;</span>
          </Tooltip>
        </TableHeaderColumn>
        <TableHeaderColumn width="90" dataField="cost" dataSort={true}>
          <Tooltip title="Unit cost &#8645;">
            <span>Unit Cost &#8645;</span>
          </Tooltip>
        </TableHeaderColumn>
        <TableHeaderColumn
          width={colWidth}
          hidden
          dataField="shippingCost"
          dataSort={true}
        >
          {"Shipping Cost"} &#8645;
        </TableHeaderColumn>
        <TableHeaderColumn
          width="90"
          dataField="totalCost"
          columnTitle
          dataSort={true}
        >
          <Tooltip title="Unit Cost(incl GST) &#8645;">
            <span>{"Unit Cost(incl GST)"} &#8645;</span>
          </Tooltip>
        </TableHeaderColumn>
        <TableHeaderColumn
          width="90"
          dataField="completeCost"
          columnTitle
          dataSort={true}
        >
          <Tooltip title="Total Cost &#8645;">
            <span>{"Total Cost"} &#8645;</span>
          </Tooltip>
        </TableHeaderColumn>
        <TableHeaderColumn
          width="90"
          dataField="completeCostWithGST"
          columnTitle
          dataSort={true}
        >
          <Tooltip title="Total Cost(With GST) &#8645;">
            <span>{"Total Cost(incl GST)"} &#8645;</span>
          </Tooltip>
        </TableHeaderColumn>
        <TableHeaderColumn
          width={colWidth}
          dataField="moq"
          columnTitle
          dataSort={true}
        >
          <Tooltip title="MOQ &#8645;">
            <span>{"MOQ"} &#8645;</span>
          </Tooltip>
        </TableHeaderColumn>
        <TableHeaderColumn
          width={colWidth}
          dataField="isFav"
          hidden={!props.isAdmin}
        >
          {"isFav"} &#8645;
        </TableHeaderColumn>
      </BootstrapTable> */}
      {props.isAdmin && !props.disabled && (
        <Button
          onClick={() => deleteVendorHandlerAPI()}
          variant="outlined"
          startIcon={<DeleteIcon />}
          sx={{ marginTop: "1rem" }}
          disabled={toDeleteVendors.length == 0 ? true : false}
        >
          Delete Quotations
        </Button>
      )}
      <Dialog open={isDialogOpen} onClose={handleDialogClose}>
        <Paper>
          <DialogTitle>Add New Quotation</DialogTitle>
          <DialogContent>
            {/* <DialogContentText>
              To subscribe to this website, please enter your email address here. We
              will send updates occasionally.
            </DialogContentText> */}
            <form onSubmit={handleAddQuotation}>
              <TextField
                autoFocus
                margin="dense"
                id="VendorName"
                label="Vendor Name"
                fullWidth
                variant="outlined"
                onChange={(e) => setVendName(e.target.value)}
              />
              <TextField
                autoFocus
                margin="dense"
                id="VendorLocation"
                label="VendorLocation"
                fullWidth
                variant="outlined"
                onChange={(e) => setVendLocation(e.target.value)}
              />
              <TextField
                autoFocus
                margin="dense"
                id="LeadTime"
                label="Lead Time"
                fullWidth
                variant="outlined"
                onChange={(e) => {
                  LeadtimeErrorHandler(e);
                  setLeadTime(e.target.value);
                }}
                error={isLeadTimeError}
                helperText={LeadTimeHelperText}
              />
              <TextField
                autoFocus
                margin="dense"
                id="Moq"
                label="MOQ"
                fullWidth
                variant="outlined"
                onChange={(e) => {
                  MOQErrorHandler(e);
                  setMoq(e.target.value);
                }}
                error={isMOQError}
                helperText={MOQHelperText}
              />
              <TextField
                autoFocus
                margin="dense"
                id="cost"
                label="Cost"
                fullWidth
                variant="outlined"
                onChange={(e) => {
                  costErrorHandler(e);
                  setCost(e.target.value);
                }}
                error={isCostError}
                helperText={costHelperText}
              />
              <TextField
                autoFocus
                margin="dense"
                id="TotalCost"
                label="Total Cost"
                fullWidth
                variant="outlined"
                onChange={(e) => {
                  GSTErrorHandler(e);
                  setTotalCost(e.target.value);
                }}
                error={isGSTError}
                helperText={GSTHelperText}
              />
              {/* <TextField
                autoFocus
                margin="dense"
                id="isFav"
                label="isFav"
                fullWidth
                variant="outlined"
                onChange={(e) => setIsFav(e.target.value)}
              /> */}
              <InputLabel id="demo-simple-select-label">is Fav</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={isFav}
                variant="outlined"
                label="is Fav"
                onChange={(e) => setIsFav(e.target.value)}
                fullWidth
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>

              <DialogActions>
                <Button onClick={handleDialogClose}>Cancel</Button>
                <Button type="submit">Submit</Button>
              </DialogActions>
            </form>
          </DialogContent>
        </Paper>
      </Dialog>
    </Box>
  );
};

export default QuotationsTable;
