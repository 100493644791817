import React, { useState } from "react";
import {
  Box,
  Button,
  Modal,
  styled,
  Slide,
  Grid,
  TextField,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { axiosInstance } from "../utils/axiosInterceptor";
import { useSnackbar } from "notistack";

const ConsultationModalContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: "60vw",
  height: "fit-content",
  left: "20vw",
  top: "15vh",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#fff",
  outline: "none",
  borderRadius: 10,
  padding: "20px 30px 20px 30px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "20px",
  justifyContent: "space-between",
  "& .headerText": {
    fontSize: "1.7em",
    fontWeight: 600,
  },
  [theme.breakpoints.down("md")]: {
    width: "50vw",
  },
  [theme.breakpoints.down("sm")]: {
    width: "80vw",
  },
}));

const CustomButton = styled(LoadingButton)(() => ({
  "&&&": {
    backgroundColor: "#FF972A",
    color: "#fff",
    textTransform: "none",
    padding: "5px 50px",
    borderRadius: "8px",
    fontWeight: 500,
  },
}));

const ConsultationModal = ({ state, toggleModal }) => {
  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
    designation: "",
    details: "",
    company: "",
  });

  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(data);
    const phoneRegExp = new RegExp(
      "^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$"
    );

    if (!phoneRegExp.test(data.phone) || data.phone.length !== 10) {
      enqueueSnackbar("Please enter a valid phone number", {
        variant: "error",
      });
      return;
    }

    try {
      const res = await axiosInstance.post("/users/bookConsultation", data);
      console.log(res);
      enqueueSnackbar(
        "Thanks for filling out your details. Our team will shortly get back to you",
        { variant: "success" }
      );
      toggleModal();
    } catch (err) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
    }
  };

  return (
    <Modal open={state} onClose={toggleModal}>
      <Slide in={state} direction="up">
        <ConsultationModalContainer>
          <h1 className="headerText">Consult an expert</h1>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={4} sx={{ marginBottom: "20px" }}>
              <Grid item md={6}>
                <TextField
                  variant="standard"
                  label="Full Name"
                  name="name"
                  fullWidth
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  variant="standard"
                  label="Company Name"
                  name="company"
                  fullWidth
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  variant="standard"
                  label="Your Designation"
                  fullWidth
                  name="designation"
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  variant="standard"
                  label="Phone"
                  fullWidth
                  name="phone"
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  variant="standard"
                  label="Email"
                  fullWidth
                  name="email"
                  onChange={handleChange}
                  required
                  type="email"
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  variant="standard"
                  label="What are you looking for?"
                  fullWidth
                  multiline
                  rows={4}
                  name="details"
                  placeholder="Please explain in detail the stage where you're at and what are you looking for."
                  onChange={handleChange}
                  required
                />
              </Grid>
            </Grid>
            <CustomButton type="submit">SEND</CustomButton>
          </form>
        </ConsultationModalContainer>
      </Slide>
    </Modal>
  );
};

export default ConsultationModal;
