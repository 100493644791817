import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../../utils/axiosInterceptor";
import "./PCBShipping.css";
import { RadioGroup } from "@headlessui/react";
import axios from "axios";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { SnackbarProvider, useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";

const PCBShipping = (props) => {
  let history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const [shipAddress, setShipAddress] = useState([]);
  const [loading, setLoading] = useState(false);

  let getAddress = () => {
    axiosInstance
      .get("/users/getProfile")
      .then((add) => {
        setShipAddress(add.data.data.shippingAddress);
        //console.log(add.data.data.shippingAddress)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAddress();
  }, []);

  // console.log(shipAddress)

  const [selectedShip, setSelectedShip] = useState(null);
  //console.log(selectedShip)

  const handleOrder = (e) => {
    e.preventDefault();
    if (selectedShip) {
      console.log(selectedShip);
      setLoading(true);
      axiosInstance
        .post("/products/addOrder", {
          shippingAddress: selectedShip,
        })
        .then((res) => {
          axiosInstance
            .put("/products/alterCart", { products: [] })
            .then((resp) => {
              setLoading(false);
              enqueueSnackbar("Order is Successful", { variant: "success" });
              setTimeout(() => {
                history.push("/pcbMarket/orderPage");
              }, 500);
            })
            .catch((err) => {
              setLoading(false);
              enqueueSnackbar("Not able to Order!", { variant: "error" });
            });
        })
        .catch((err) => {
          setLoading(false);
          enqueueSnackbar("Not able to Order!", { variant: "error" });
        });
    } else {
      enqueueSnackbar("Please select an address", { variant: "error" });
    }
  };

  return (
    <div>
      <>
        <Dialog
          open={props.open}
          onClose={props.handleCloseModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          maxWidth={"sm"}
        >
          <DialogTitle id="alert-dialog-title">
            Select a shipping address
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <RadioGroup value={selectedShip} onChange={setSelectedShip}>
                {shipAddress.length < 1 ? (
                  <div>
                    <h6>No shipping Address Found!</h6>
                  </div>
                ) : (
                  shipAddress.map((address) => (
                    <RadioGroup.Option value={address} className="shipRadio">
                      {({ checked }) => (
                        <div
                          className={checked ? "single_address" : ""}
                          id="singleAdrr"
                        >
                          <h6>
                            {address.state}, {address.street}, {address.city}
                          </h6>
                          <p>{address.fullName}</p>
                          <p>{address.mobileNumber}</p>
                        </div>
                      )}
                    </RadioGroup.Option>
                  ))
                )}
              </RadioGroup>
              <Link to="/pcbMarket/cart/addNewShipping">
                <Button variant="outlined">Add New Address</Button>
              </Link>
            </DialogContentText>
            <DialogActions>
              <Button variant="outlined" onClick={props.handleClose}>
                Cancel
              </Button>

              <LoadingButton
                onClick={handleOrder}
                loading={loading}
                variant="contained"
              >
                Confirm Order
              </LoadingButton>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </>
    </div>
  );
};

export default PCBShipping;
