import React, { useState, useEffect } from 'react';

//components
import CustomBreadcrumbs from '../../Components/CustomBreadcrumbs';
import DashboardHeader from '../../ClientEngagement/DashboardHeader';

//utils
import { axiosInstance } from '../../utils/axiosInterceptor';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  InputAdornment,
  useTheme,
} from '@mui/material';
import moment from 'moment';

//styles
import {
  PageContainer,
  ColosseumProjectsContainer,
  StyledInput,
} from './ColosseumProjects.styles';

//assets
import { ReactComponent as SearchIcon } from '../../assests/search.svg';

const ColosseumProjects = (props) => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const theme = useTheme();

  const fetchProfile = () => {
    setLoading(true);
    axiosInstance
      .get('/users/getProfile')
      .then((resp) => {
        setProfile(resp.data);
        console.log(resp);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const fetchProjects = async () => {
    try {
      const res = await axiosInstance.get('/3d/client/allProjects');
      console.log(res);
      setProjects([...projects, ...res.data.projects]);
      setFilteredProjects([...projects, ...res.data.projects]);
    } catch (err) {
      console.log(err);
    }
  };

  const filterProjects = (query) => {
    const newProjects = projects.filter((p) =>
      p.projectName.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredProjects([...newProjects]);
  };

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    filterProjects(searchQuery);
  }, [searchQuery]);

  const getProjectType = (details) => {
    if (details.resinMaterial) {
      return {
        val: 'injectionMolding',
        label: 'Injection Molding',
      };
    } else if (details.outerDimensions) {
      return {
        val: 'prebuiltCase',
        label: 'Prebuilt Case',
      };
    } else {
      return {
        val: '3dprinting',
        label: '3D Printing',
      };
    }
  };

  useEffect(() => {
    fetchProfile();
    fetchProjects();
  }, []);

  return (
    <Box>
      {!loading ? (
        <PageContainer>
          <DashboardHeader profile_data={profile.data} isBlack={true} />
          <ColosseumProjectsContainer>
            <CustomBreadcrumbs
              data={[
                {
                  name: 'Home',
                  link: '/',
                },
                {
                  name: 'EB 3D Colosseum',
                  link: '/3dcolosseum',
                },
                {
                  name: 'My Projects',
                  link: '/3dcolosseum/projects',
                },
              ]}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                [theme.breakpoints.down('md')]: {
                  flexDirection: 'column',
                  alignItems: 'start',
                  gap: '10px',
                },
              }}
            >
              <div className="title">My Projects</div>
              <StyledInput
                type="text"
                placeholder="Search for projects"
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <TableContainer component={Paper} sx={{ marginTop: '30px' }}>
              <Table sx={{ minWidth: 50 }} aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ backgroundColor: '#8B8B8B33' }}>
                    <TableCell
                      sx={{ fontSize: '0.9rem', fontWeight: 500 }}
                      align="left"
                    >
                      Project name
                    </TableCell>
                    <TableCell
                      sx={{ fontSize: '0.9rem', fontWeight: 500 }}
                      align="left"
                    >
                      Created By
                    </TableCell>
                    <TableCell
                      sx={{ fontSize: '0.9rem', fontWeight: 500 }}
                      align="left"
                    >
                      Internal name
                    </TableCell>
                    <TableCell
                      sx={{ fontSize: '0.9rem', fontWeight: 500 }}
                      align="left"
                    >
                      Created On
                    </TableCell>
                    <TableCell
                      sx={{ fontSize: '0.9rem', fontWeight: 500 }}
                      align="left"
                    >
                      Company Name
                    </TableCell>
                    <TableCell
                      sx={{ fontSize: '0.9rem', fontWeight: 500 }}
                      align="left"
                    >
                      Project ID
                    </TableCell>
                    <TableCell
                      sx={{ fontSize: '0.9rem', fontWeight: 500 }}
                      align="left"
                    >
                      Service
                    </TableCell>

                    <TableCell
                      sx={{ fontSize: '0.9rem', fontWeight: 500 }}
                      align="left"
                    >
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredProjects.length > 0 ? (
                    <>
                      {filteredProjects.map((project) => (
                        <TableRow
                          key={project.projectId}
                          hover
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            cursor: 'pointer',
                          }}
                          onClick={() =>
                            props.history.push(
                              `/3dcolosseum/${
                                getProjectType(project.projectDetails).val
                              }/${project._id}`
                            )
                          }
                        >
                          <TableCell>{project.projectName}</TableCell>
                          <TableCell>
                            {project.projectOwner.email || ''}
                          </TableCell>
                          <TableCell>{project.internalName || ''}</TableCell>
                          <TableCell>
                            {moment(project?.createdOn).format(
                              'MMMM Do YYYY, h:mm a'
                            ) || 'no date'}
                          </TableCell>
                          <TableCell>
                            {project.projectOwner.enterpriseName}
                          </TableCell>
                          <TableCell>{project.projectId}</TableCell>

                          <TableCell>
                            {getProjectType(project.projectDetails).label}
                          </TableCell>

                          <TableCell>
                            {project.isClosed
                              ? 'Project Closed'
                              : project.currentStatus[
                                  project.currentStatus.length - 1
                                ]?.status}
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  ) : (
                    <TableCell
                      colSpan={7}
                      sx={{
                        color: '#737373',
                        margin: '10px',
                        width: '100%',
                        textAlign: 'center',
                      }}
                    >
                      Currently you don't have any projects !
                    </TableCell>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </ColosseumProjectsContainer>
        </PageContainer>
      ) : null}
    </Box>
  );
};

export default ColosseumProjects;
