import React, { useState, useEffect } from "react";

//components
import CustomBreadcrumbs from "../../Components/CustomBreadcrumbs";
import DashboardHeader from "../../ClientEngagement/DashboardHeader";
import CustomFileDropper from "../../Components/CustomFileDropper";
import CustomColorPicker from "../../Components/CustomColorPicker";

//utils
import { axiosInstance } from "../../utils/axiosInterceptor";
import { Box, createTheme, Grid } from "@mui/material";
// import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";

//styles
import {
  PageContainer,
  CreateProjectContainer,
  CreateProjectFormContainer,
  CusotmGridItem,
  CustomInput,
  CustomSelect,
  LightTooltip,
  CustomSlider,
  CustomButton,
} from "./CreateNewProject.styles";

//assets
import { ReactComponent as InfoLogo } from "../../assests/InfoLogo.svg";

const dropzoneTheme = createTheme({
  overrides: {
    root: {
      backgroundColor: "transparent",
    },
  },
});

const CreatePrebuiltCasesProject = ({ isAdmin }) => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [createLoading, setCreateLoading] = useState(false);
  const [projectData, setProjectData] = useState({
    projectName: "",
    otherSpecification: "",
    outerDimensions: "",
    enclosureApplication: "",
    colour: "",
  });
  const [dimensions, setDimensions] = useState({
    length: "",
    breadth: "",
    height: "",
  });
  const [userEmail, setUserEmail] = useState("");

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const fetchProfile = () => {
    setLoading(true);
    axiosInstance
      .get("/users/getProfile")
      .then((resp) => {
        setProfile(resp.data);
        console.log(resp);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleChange = (e) => {
    setProjectData({
      ...projectData,
      [e.target.name]: e.target.value,
    });
  };

  const handleDimensionChange = (e) => {
    setDimensions({
      ...dimensions,
      [e.target.name]: e.target.value,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    console.log(projectData);


    if (projectData.colour == "") {
      enqueueSnackbar("Please select a colour", { variant: "info" });
      return;
    }

    setCreateLoading(true);
    try {
      let url;
      let data;
      if (isAdmin) {
        url = "/3d/admin/createProject/prebuiltCases";
        data = {
          ...projectData,
          userEmail,
          outerDimensions: `${dimensions.length}x${dimensions.breadth}x${dimensions.height}`,
        };
      } else {
        url = "/3d/client/createProject/prebuiltCases";
        data = {
          ...projectData,
          outerDimensions: `${dimensions.length}x${dimensions.breadth}x${dimensions.height}`,
        };
      }
      const res = await axiosInstance.post(url, data);
      console.log(res);
      enqueueSnackbar("Project Created Successfully!", {
        variant: "success",
      });
      setCreateLoading(false);
      if (isAdmin) {
        history.push(`/admin/3d/timeline/prebuiltCase/${res.data.project._id}`);
      } else {
        history.push(`/3dcolosseum/prebuiltCase/${res.data.project._id}`);
      }
    } catch (err) {
      console.log(e);
      enqueueSnackbar("some error occurred", { variant: "error" });
    }
  };

    useEffect(() => {
        if(localStorage.PM && history.location.pathname.includes("/admin")) history.push("/pm/3d/newProject/prebuiltCases");
    }, []);
  useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <Box>
      {!loading ? (
        <PageContainer>
          <DashboardHeader profile_data={profile.data} isBlack={true} />
          <CreateProjectContainer>
            <CustomBreadcrumbs
              data={[
                {
                  name: "Home",
                  link: "/",
                },
                {
                  name: "EB 3D Colosseum",
                  link: "/admin/3d",
                },
                {
                  name: "Create new project",
                  link: "/admin/3d/services",
                },
                {
                  name: "Prebuilt Case",
                  link: history.location.pathname,
                },
              ]}
            />
            <div className="title">Prebuilt Cases - Create a new Project</div>
            <CreateProjectFormContainer>
              <form onSubmit={handleFormSubmit}>
                <Grid container spacing={5}>
                  <CusotmGridItem item md={6}>
                    <div className="grid-item-inner">
                      <label htmlFor="project-name">Project Name*</label>
                      <CustomInput
                        type="text"
                        id="project-name"
                        name="projectName"
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </CusotmGridItem>
                  <CusotmGridItem item md={6} shift></CusotmGridItem>
                  <CusotmGridItem item md={6}>
                    <div className="grid-item-inner">
                      <label htmlFor="outerDimensions">
                        Outer Dimensions (mm)*
                      </label>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ width: "30%" }}>
                          <CustomInput
                            type="text"
                            id="outerDimensions"
                            name="length"
                            onChange={handleDimensionChange}
                            placeholder="Length"
                            required
                          />
                        </Box>{" "}
                        <Box sx={{ width: "30%" }}>
                          <CustomInput
                            type="text"
                            id="outerDimensions"
                            name="breadth"
                            onChange={handleDimensionChange}
                            placeholder="Breadth"
                            required
                          />
                        </Box>{" "}
                        <Box sx={{ width: "30%" }}>
                          <CustomInput
                            type="text"
                            id="outerDimensions"
                            name="height"
                            onChange={handleDimensionChange}
                            placeholder="Height"
                            required
                          />
                        </Box>
                      </Box>
                    </div>
                  </CusotmGridItem>
                  <CusotmGridItem item md={6} shift>
                    <div className="grid-item-inner">
                      <label htmlFor="project-name">
                        Colour of Resin Material*
                      </label>
                      <CustomColorPicker
                        colors={[
                          { value: "#FFFFFF", name: "white" },
                          { value: "#000000", name: "black" },
                        ]}
                        onChange={(c) => {
                          setProjectData({ ...projectData, colour: c });
                        }}
                        enableTextField
                      />
                    </div>
                  </CusotmGridItem>
                  {isAdmin ? (
                    <CusotmGridItem item md={6}>
                      <div className="grid-item-inner">
                        <label htmlFor="user-email">User Email*</label>
                        <CustomInput
                          type="text"
                          id="user-email"
                          name="userEmail"
                          onChange={(e) => {
                            setUserEmail(e.target.value);
                          }}
                          required
                          value={userEmail}
                        />
                      </div>
                    </CusotmGridItem>
                  ) : null}
                  <CusotmGridItem item md={12} isLast>
                    <div className="grid-item-inner">
                      <label htmlFor="enclosureApplication">
                        Application of enclosure
                      </label>
                      <CustomInput
                        type="text"
                        id="enclosureApplication"
                        name="enclosureApplication"
                        onChange={handleChange}
                        multiline
                        rows={4}
                        placeholder="What will be the enclosure used for?"
                      />
                    </div>
                  </CusotmGridItem>
                  <CusotmGridItem item md={12} isLast>
                    <div className="grid-item-inner">
                      <label htmlFor="project-name">
                        Anyother Specification
                      </label>
                      <CustomInput
                        type="text"
                        id="otherSpecification"
                        name="otherSpecification"
                        onChange={handleChange}
                        multiline
                        rows={4}
                        placeholder="Waterproof, dustproof IP ratings, if any?"
                      />
                    </div>
                  </CusotmGridItem>
                </Grid>

                <CustomButton
                  variant="contained"
                  type="submit"
                  loading={createLoading}
                  loadingPosition="end"
                >
                  Submit
                </CustomButton>
              </form>
            </CreateProjectFormContainer>
          </CreateProjectContainer>
        </PageContainer>
      ) : null}
    </Box>
  );
};

export default CreatePrebuiltCasesProject;
