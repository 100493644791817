import React, { useState, useEffect } from 'react';

//components
import CustomBreadcrumbs from '../../Components/CustomBreadcrumbs';
import DashboardHeader from '../../ClientEngagement/DashboardHeader';
import ReuploadCadFileModal from './ReuploadCadFileModal';
import ColosseumProjectDetailsModal from './ColosseumProjectDetailsModal';
import CollaborationModal from '../../Components/CollaboratorModal';
import ViewCollaborators from '../../Components/ViewCollaborators';
import ClosedProject from '../../Components/ClosedProject';
import ConfirmModal from '../../Components/ComfirmModal';

//utils
import { axiosInstance } from '../../utils/axiosInterceptor';
import {
  Box,
  Button,
  Rating,
  Select,
  Typography,
  useTheme,
} from '@mui/material';
import moment from 'moment';
import { useParams, useHistory } from 'react-router-dom';
import { stages, adminStages } from './ColosseumTImeline.data';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineComponent from './TimelineComponent';
import { MenuItem } from '@material-ui/core';
import { useWindowSize } from '../../Hooks/useWindowSize';
import { useSnackbar } from 'notistack';
//styles
import {
  PageContainer,
  TimelineContainer,
  CustomButton,
  CustomSelect,
  TimeLineStage,
} from './ColosseumTimeline.styles';

//assets
import { ReactComponent as ViewIcon } from '../../assests/view.svg';
import { ReactComponent as CollaboratorsIcon } from '../../assests/collaborators.svg';
import { ReactComponent as TimelineIcon } from '../../assests/timeline.svg';
import ProjectTrackingLogModal from '../../Components/ProjectTrackingLogModal';
import { PriorityBox } from '../../BOMProcurement/ProjectTimeline/BomTImeline.styles';

const ColosseumTimeline = ({ isAdmin }) => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showReuploadModal, setShowReuploadModal] = useState(false);
  const [showProjectDetail, setShowProjectDetail] = useState(false);
  const [showCollaboratorModal, setShowCollaboratorModal] = useState(false);
  const [openConfirmStage, setOpenConfirmStage] = useState(false);
  const [isClose, setIsClose] = useState(false);
  const [showViewCollabModal, setShowViewCollabModal] = React.useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [project, setProject] = useState(null);
  const { id, type } = useParams();
  const history = useHistory();
  const [tempStage, setTempStage] = useState(null);
  const [showProjectTrackingLogsModal, setShowProjectTrackingLogsModal] =
    useState(false);

  const isPM = localStorage.PM;
  const toggleShowProjectTrackingLogsModal = () => {
    setShowProjectTrackingLogsModal(!showProjectTrackingLogsModal);
  };

  const theme = useTheme();
  const { width } = useWindowSize();
  const { enqueueSnackbar } = useSnackbar();
  const toggleConfirmModalStage = () => {
    setOpenConfirmStage(!openConfirmStage);
  };
  const toggleReuploadModal = () => {
    setShowReuploadModal(!showReuploadModal);
  };
  const toggleProjectDetail = () => {
    setShowProjectDetail(!showProjectDetail);
  };
  const toggleCollaboratorModal = () => {
    setShowCollaboratorModal(!showCollaboratorModal);
  };

  const toggleViewCollabModal = () => {
    setShowViewCollabModal(!showViewCollabModal);
  };
  const toggleConfirmModal = () => {
    setOpenConfirm(!openConfirm);
  };

  const handleStageChange = async () => {
    try {
      const nextStage = adminStages[tempStage];
      console.log(nextStage);
      const data = {
        currentStage: nextStage.value,
      };

      const res = await axiosInstance.post(
        `/3d/admin/addStage/${type}/${id}`,
        data
      );
      console.log(res);
      enqueueSnackbar('Stage updated successfully!', { variant: 'success' });
      window.location.reload();
    } catch (err) {
      console.log(err);
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const fetchProfile = () => {
    setLoading(true);
    axiosInstance
      .get('/users/getProfile')
      .then((resp) => {
        setProfile(resp.data);
        console.log(resp);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const fetchProject = async () => {
    const res = await axiosInstance.get(
      `/3d/client/singleProject/${type}/${id}`
    );
    console.log(res);
    setProject(res.data.project);
    let p = res.data.project.currentStatus;
    setTempStage(p.length);
    setIsClose(res.data.project.isClosed);
  };

  const closeProjectHandler = async () => {
    try {
      const val = await axiosInstance.get(`/3d/admin/closeProject/${id}`);
      enqueueSnackbar('Project closed successfully!', { variant: 'success' });
      setIsClose(true);
      toggleConfirmModal();
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchProfile();
  }, []);

  useEffect(() => {
    fetchProject();
  }, [type, id]);

  return (
    <Box>
      {isClose && <ClosedProject />}
      {!loading && project ? (
        <PageContainer>
          <DashboardHeader profile_data={profile.data} isBlack={true} />
          <TimelineContainer>
            {isAdmin ? (
              <CustomBreadcrumbs
                data={[
                  {
                    name: 'Home',
                    link: `/${isPM ? 'pm' : ''}`,
                  },
                  {
                    name: 'EB 3D Colosseum',
                    link: `/${isPM ? 'pm' : 'admin'}/3d`,
                  },
                  {
                    name: 'View Details',
                    link: `/${isPM ? 'pm' : 'admin'}/3d/timeline/${type}/${id}`,
                  },
                ]}
              />
            ) : (
              <CustomBreadcrumbs
                data={[
                  {
                    name: 'Home',
                    link: '/',
                  },
                  {
                    name: 'EB 3D Colosseum',
                    link: '/3dcolosseum',
                  },
                  {
                    name: 'My Projects',
                    link: '/3dcolosseum/projects',
                  },
                  {
                    name: 'View Details',
                    link: `/3dcolosseum/${type}/${id}`,
                  },
                ]}
              />
            )}
            <div className="title">Project Details</div>
            <Box className="container1" sx={{ marginBottom: '30px' }}>
              <Box>
                {isAdmin ? (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '20px',
                      marginBottom: '10px',
                    }}
                  >
                    <Typography>
                      {project?.projectOwner?.enterpriseName}
                    </Typography>
                    <Box>
                      <Rating
                        readOnly
                        value={project?.projectOwner?.clientDetails?.rating}
                      />
                    </Box>
                    <Box>
                      {project?.projectOwner?.clientDetails?.organizationSize}
                    </Box>
                  </Box>
                ) : null}
                <Box
                  sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}
                >
                  <Typography className="projectName">
                    {project.projectName}
                  </Typography>
                  {isAdmin ? (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '20px',
                      }}
                    >
                      <PriorityBox priority={project?.projectDetails?.priority}>
                        {project?.projectDetails?.priority}
                      </PriorityBox>
                      {project?.projectDetails.projectManager && (
                        <PriorityBox priority="medium">
                          {project?.projectDetails?.projectManager?.fullName ||
                            project?.projectDetails?.projectManager?.email ||
                            'N/A'}
                        </PriorityBox>
                      )}
                      {project?.projectDetails?.modeOfCommunication &&
                        project?.projectDetails?.modeOfCommunication.map(
                          (comm) => (
                            <PriorityBox priority="low">
                              {comm || 'N/A'}
                            </PriorityBox>
                          )
                        )}
                    </Box>
                  ) : null}
                </Box>
                <Typography className="projectTime">
                  {' '}
                  Project Created on:{' '}
                  {moment(project?.createdOn).format('MMMM Do YYYY, h:mm a')}
                </Typography>
              </Box>
              {/* <Box>
                <Typography className="projectName">
                  {project.projectName}
                </Typography>
                <Typography className="projectTime">
                  {" "}
                  Project Created on:{" "}
                  {moment(project?.createdOn).format("MMMM Do YYYY, h:mm a")}
                </Typography>
              </Box> */}
              <Box
                sx={{
                  borderBottom: '1px solid #0702FC',
                  color: '#0702FC',
                  fontWeight: 500,
                  cursor: 'pointer',
                }}
                onClick={toggleProjectDetail}
              >
                View Project Details
              </Box>
              <ColosseumProjectDetailsModal
                state={showProjectDetail}
                toggleModal={toggleProjectDetail}
                project={project}
                type={type}
                isAdmin={isAdmin}
                user={{ data: project.projectOwner }}
              />
              <ProjectTrackingLogModal
                state={showProjectTrackingLogsModal}
                toggleModal={toggleShowProjectTrackingLogsModal}
                // number={number}
                project={project}
                service="3d"
                isAdmin={isAdmin}
                type={type}
              />
            </Box>
            <Box className="button-container" sx={{ marginBottom: '60px' }}>
              <div className="btn-cont-2">
                <CustomButton
                  fill
                  disabled={
                    (!(project.currentStatus.length > 2) && !isAdmin) || isClose
                  }
                  onClick={() => {
                    if (isAdmin) {
                      history.push(
                        `/${isPM ? 'pm' : 'admin'}/3d/quotations/${type}/${id}`
                      );
                    } else {
                      history.push(`/3dcolosseum/quotations/${type}/${id}`);
                    }
                  }}
                >
                  <ViewIcon />
                  View Quotations
                </CustomButton>
                {project.projectOwner._id == profile.data._id || isAdmin ? (
                  <CustomButton
                    disabled={isClose}
                    onClick={toggleCollaboratorModal}
                  >
                    {!isClose && <CollaboratorsIcon />} Manage Collaborators
                  </CustomButton>
                ) : (
                  <CustomButton
                    disabled={isClose}
                    onClick={toggleViewCollabModal}
                  >
                    {!isClose && <CollaboratorsIcon />} View Collaborators
                  </CustomButton>
                )}
                {isAdmin && (
                  <CustomButton
                    onClick={toggleConfirmModal}
                    variant="outlined"
                    fill
                    disabled={isClose}
                  >
                    CLOSE PROJECT
                  </CustomButton>
                )}
                <ConfirmModal
                  toggleModal={toggleConfirmModal}
                  state={openConfirm}
                  text="Do you really want to Close this Project?"
                  onYes={closeProjectHandler}
                  onNo={toggleConfirmModal}
                />
              </div>
              <CollaborationModal
                state={showCollaboratorModal}
                toggleModal={toggleCollaboratorModal}
                service="3d"
                projectId={project._id}
                collaborators={project?.collaborators}
                type={type}
              />
              <ViewCollaborators
                state={showViewCollabModal}
                toggleModal={toggleViewCollabModal}
                service="fab"
                projectId={project._id}
                collaborators={project?.collaborators}
                type={type}
              />
              {type !== 'prebuiltCase' ? (
                <div>
                  <a href={project.cadFile.location}>
                    <CustomButton disabled={isClose}>Download CAD</CustomButton>
                  </a>
                  <CustomButton
                    onClick={toggleReuploadModal}
                    disabled={isClose}
                  >
                    Reupload CAD
                  </CustomButton>
                </div>
              ) : null}
              {isAdmin && (
                <CustomButton
                  onClick={toggleShowProjectTrackingLogsModal}
                  disabled={isClose}
                >
                  Project Tracking Logs
                </CustomButton>
              )}
              <ReuploadCadFileModal
                state={showReuploadModal}
                toggleModal={toggleReuploadModal}
                id={id}
                type={type}
              />
            </Box>
            {isAdmin ? (
              <Box sx={{ width: '34%', marginTop: '20px' }}>
                <Box sx={{ width: '100%', display: 'flex', gap: '10px' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <TimeLineStage>
                      {project?.currentStatus[tempStage - 1]?.status}
                    </TimeLineStage>
                    <CustomButton
                      disabled={tempStage === adminStages.length || isClose}
                      fill
                      style={{ padding: '5px 25px' }}
                      onClick={toggleConfirmModalStage}
                      variant="contained"
                    >
                      Next stage
                    </CustomButton>
                    <ConfirmModal
                      toggleModal={toggleConfirmModalStage}
                      state={openConfirmStage}
                      text="Do you really want to update it to next stage?"
                      onYes={handleStageChange}
                      onNo={toggleConfirmModalStage}
                    />
                  </Box>
                </Box>
              </Box>
            ) : null}
            <Typography
              sx={{
                margin: '30px 0px',
                fontWeight: 600,
                fontSize: '1.2em',
                '& svg': { transform: 'scale(0.7)' },
                letterSpacing: '0.4px',
                fontFamily: 'Poppins',
              }}
            >
              <TimelineIcon /> Order Timeline
            </Typography>
            <Box>
              {
                <Timeline>
                  {stages.map((stage, i) => {
                    let status = '';
                    if (
                      project?.currentStatus?.length > i + 1 ||
                      project.currentStatus?.length === 11
                    ) {
                      status = 'complete';
                    } else if (project?.currentStatus?.length == i + 1) {
                      status = 'active';
                    } else {
                      status = 'locked';
                    }

                    return (
                      <TimelineItem>
                        {width > 900 ? (
                          <TimelineOppositeContent
                            color="text.secondary"
                            style={{ flex: 0 }}
                          ></TimelineOppositeContent>
                        ) : null}
                        <TimelineSeparator
                          sx={{
                            [theme.breakpoints.down('md')]: {
                              width: '10px',
                            },
                          }}
                        >
                          {project.currentStatus.length >= i + 1 && i == 0 ? (
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                color: '#000',
                                width: '100px',
                              }}
                            >
                              <Typography
                                sx={{ fontSize: '1.5em', fontWeight: 600 }}
                              >
                                {moment(
                                  project?.currentStatus[i].completionDate
                                ).format('DD')}
                              </Typography>
                              <Typography sx={{ fontWeight: 500 }}>
                                {moment(
                                  project?.currentStatus[i].completionDate
                                ).format('MMM, YYYY')}
                              </Typography>
                            </Box>
                          ) : (
                            <Box sx={{ width: '100px' }}></Box>
                          )}
                          {project.currentStatus.length >= i + 1 &&
                          i > 0 &&
                          moment(
                            project.currentStatus[i].completionDate
                          ).format('DD MMM') !==
                            moment(
                              project.currentStatus[i - 1].completionDate
                            ).format('DD MMM') ? (
                            <>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                  color: '#000',
                                  width: '100px',
                                }}
                              >
                                <Typography
                                  sx={{ fontSize: '1.5em', fontWeight: 600 }}
                                >
                                  {moment(
                                    project?.currentStatus[i].completionDate
                                  ).format('DD')}
                                </Typography>
                                <Typography sx={{ fontWeight: 500 }}>
                                  {moment(
                                    project?.currentStatus[i].completionDate
                                  ).format('MMM, YYYY')}
                                </Typography>
                              </Box>
                              {/* <TimelineDot
                                sx={{
                                  background:
                                    "linear-gradient(90deg, #F67C1B 0%, #E95252 40.55%, #E65254 40.57%, #0275FC 114%, #0275FC 114%)",
                                  alignSelf: "center",
                                }}
                              /> */}
                            </>
                          ) : (
                            <>
                              <Box sx={{ width: '100px' }}></Box>
                              {i > 0 ? (
                                <TimelineDot
                                  sx={{
                                    background:
                                      'linear-gradient(90deg, #F67C1B 0%, #E95252 40.55%, #E65254 40.57%, #0275FC 114%, #0275FC 114%)',
                                    alignSelf: 'center',
                                  }}
                                ></TimelineDot>
                              ) : null}
                            </>
                          )}

                          <TimelineConnector
                            sx={{
                              background:
                                'linear-gradient(180deg, #F67C1B 20%, #E95252 40%,#E95252 60%,#0275FC 80%,#0275FC 100%)',
                              border: '2px dashed #fff',
                            }}
                          />
                          {i === 10 ? (
                            <TimelineDot
                              sx={{
                                background:
                                  'linear-gradient(90deg, #F67C1B 0%, #E95252 40.55%, #E65254 40.57%, #0275FC 114%, #0275FC 114%)',
                                alignSelf: 'center',
                              }}
                            />
                          ) : null}
                        </TimelineSeparator>
                        <TimelineContent>
                          <TimelineComponent
                            number={i + 1}
                            stage={stage}
                            status={status}
                            type={type}
                            isAdmin={isAdmin}
                            project={project}
                            isClose={isClose}
                          />
                        </TimelineContent>
                      </TimelineItem>
                    );
                  })}
                </Timeline>
              }
            </Box>
          </TimelineContainer>
        </PageContainer>
      ) : null}
    </Box>
  );
};

export default ColosseumTimeline;
