import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Button,
  Modal,
  styled,
  TextField,
  Typography,
  Fade,
  Slide,
  Grow,
} from '@mui/material';
const Dropdown = ({ data, setUserEmail, setSuggestEmails }) => {
  const style = {
    width: '33vw',
    zIndex: '99999',
    marginLeft: '-8px',
    marginTop: '-10px',
    position: 'absolute',
    top: '150px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    maxHeight: '200px',
    overflowY: 'scroll',
    display: data.length > 0 ? 'block' : 'none',
  };
  return (
    <Box sx={style}>
      {data.map((user) => (
        <User
          setSuggestEmails={setSuggestEmails}
          setUserEmail={setUserEmail}
          user={user}
        />
      ))}
    </Box>
  );
};
const User = ({ user, setUserEmail, setSuggestEmails, ...props }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '5px',
        margin: '8px 0px',
        alignItems: 'center',
        boxShadow: 'rgba(100, 100, 111, 0.1) 0px 7px 29px 0px',
        borderRadius: '10px',
        cursor: 'pointer',
      }}
      onClick={() => {
        setUserEmail(user.email);
        setSuggestEmails([]);
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '20px',
          alignItems: 'center',
        }}
      >
        <Avatar src={user?.profilePic}>
          {user.firstName && user.firstName[0]}
        </Avatar>
        <div>
          <Typography sx={{ fontSize: '1.1em', fontWeight: 500 }}>
            {`${user?.firstName || ''} ${user?.lastName || ''}`}
          </Typography>
          <Typography sx={{ color: '#A3A3A3' }}>{user?.email}</Typography>
        </div>
      </Box>
    </Box>
  );
};

export default Dropdown;
