import {useState} from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { axiosInstance } from "../utils/axiosInterceptor";

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const modalStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const documentPreview = {
    width: '100%',
}


export default function DocumentsAccordian({file, vendorId, handleFileDelete}) {
    console.log(file);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{file.originalName}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Stack spacing={2} direction="row">
                <a target="_blank" href={file.location}>
                    <Button size="small" variant="contained">View</Button>
                </a>
                <Button size="small" variant="contained" onClick={() => handleFileDelete(vendorId, file.location, file.originalName)}>Delete</Button>
            </Stack>
        </AccordionDetails>
      </Accordion>
  );
}
/*
                      <Box
                        sx={{
                          width: "100%",
                          border: "1px solid #a4a4a4",
                          borderRadius: "5px",
                          padding: "5px 10px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography> {file.originalName}</Typography>
                        <a href={file.location}>
                          <DownloadIcon width="20px" height="20px" />
                        </a>
                      </Box>
    */
