import { ReactComponent as ProjectClosed } from '../assests/projectClosed.svg';
import { Box } from '@material-ui/core';
const ClosedProject = () => {
  return (
    <Box
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'rgba(46, 49, 49, 0.6)',
        zIndex: 200,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        pointerEvents: 'none',
      }}
    >
      <ProjectClosed />
    </Box>
  );
};

export default ClosedProject;
