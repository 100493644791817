import React, { useEffect, useState } from "react";
import ElecLogo from "../../assests/elecbitsLogo.svg";
import RightImage from "../../assests/rightImage.svg";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import ElecbitsWhite from "../../assests/elecbitsLogoWhite.svg";
import {
  createTheme,
  withStyles,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import "./Login.css";
import { SnackbarProvider, useSnackbar } from "notistack";
import LinearProgress from "@material-ui/core/LinearProgress";
import { axiosInstance } from "../../utils/axiosInterceptor";
import { Link } from "react-router-dom";
import { Box, Typography, useTheme } from "@mui/material";
import { useParams,useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  margin: {
    marginTop: theme.spacing(2),
  },
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(1),
    },
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#fca702",
    "&:hover": {
      backgroundColor: "#fca720",
    },
  },
}))(Button);
const LightColorButton = withStyles((theme) => ({
  root: {
    backgroundColor: "rgba(0, 0, 0, 0.034)",
    color: "black",
    fontWeight: "600",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.034)",
    },
  },
}))(Button);
export default function ResetPassword(props) {
  const classes = useStyles();
  const [password, setPassword] = useState("");
  const [cpassword, setCpassword] = useState("");
  const [password_valid, set_pass_valid] = useState(false);
  const [cpassword_valid, set_cpass_valid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isValidToken, setIsValidToken] = useState(false);
  const { breakpoints } = useTheme();

  const { enqueueSnackbar } = useSnackbar();

  const { token } = useParams();
  const history = useHistory();

  const handleSubmit = async () => {
    if (!cpassword_valid && !password_valid) {
      console.log(password, "hello");
      const res = await axiosInstance.post("/passwordReset/resetpassword", {
        password,
        token,
      });

      if (res.status === 200) {
        if (res.data.error) {
          enqueueSnackbar(res.data.message, { variant: "error" });
        } else {
          enqueueSnackbar("Password reset successfull!", {
            variant: "success",
          });
          history.push('/');
        }
      } else {
        enqueueSnackbar("Something went wrong!", { variant: "error" });
      }

      console.log(res);
    }
  };

  const checkTokenValidity = async (token) => {
    const res = await axiosInstance.get(
      `/passwordReset/validateToken/${token}`
    );
    console.log(res, "token validity");
    if (res.status == 200) {
      setIsValidToken(!res.data.error);
      return !res.data.error;
    }
    setIsValidToken(false);
    return false;
  };

  useEffect(() => {
    console.log(token);
    checkTokenValidity(token);
  }, [token]);

  useEffect(() => {
    console.log(isValidToken);
  }, [isValidToken]);

  return (
    <SnackbarProvider maxSnack={3}>
      <div className={classes.root}>{loading ? <LinearProgress /> : ""}</div>

      <div className="signUpWrapper">
        <div className="content_wrap">
          <div className="leftt_content_login_supplier1">
            <div className="signUpheaderLogin">
              <img src={ElecbitsWhite}></img>
              <p>
                Develop and Manufacture Electronics Just Like You Order a 🍕
                Online
              </p>
            </div>
          </div>
          <div className="main_content_login">
            {isValidToken ? (
              <Box
                className="l_body"
                sx={{
                  [breakpoints.down("md")]: {
                    width: "90vw",
                  },
                }}
              >
                <div className="signUp_head_login">Reset Password</div>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <TextField
                    error={password_valid}
                    required
                    type="password"
                    id="outlined-basic"
                    label="Password"
                    variant="outlined"
                    helperText="Should include atleast 8 characters"
                    className={classes.margin}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      if (e.target.value.length >= 8) {
                        set_pass_valid(false);
                      } else {
                        set_pass_valid(true);
                      }
                    }}
                  />{" "}
                  <TextField
                    error={cpassword_valid}
                    required
                    type="password"
                    id="outlined-basic"
                    label="Confirm Password"
                    variant="outlined"
                    helperText="Should be same as above"
                    className={classes.margin}
                    onChange={(e) => {
                      if (e.target.value === password) {
                        set_cpass_valid(false);
                      } else {
                        set_cpass_valid(true);
                      }
                    }}
                  />
                  <ColorButton
                    type="submit"
                    variant="contained"
                    color="white"
                    sx={{ color: "white" }}
                    className={classes.margin}
                  >
                    RESET PASSWORD
                  </ColorButton>
                  {/* <ColorButton
                variant="contained"
                color="primary"
                className={classes.margin}
              >
                Sign in with FACEBOOK
              </ColorButton> */}
                </form>
                <div className="sign_up">
                  Not registered?{"   "}Click
                  <Link to="/signUp">
                    {" "}
                    <u style={{ color: "black" }}>here</u>
                  </Link>{" "}
                  to sign up
                </div>
              </Box>
            ) : (
              <Box
                sx={{
                  width: "100%",
                  padding: "20px",
                  color: "red",
                  border: "1px solid red",
                  textAlign: "center",
                  borderRadius: "10px",
                }}
              >
                Invalid or expired link
              </Box>
            )}
          </div>
        </div>
      </div>
    </SnackbarProvider>
  );
}
