import React, { useState, useEffect } from "react";
import { axiosInstance } from "../utils/axiosInterceptor";
import DashboardLogo1 from "../assests/elecbitsLogo.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Container, Row, Col } from "react-bootstrap";
import ReactImageMagnify from "react-image-magnify";
import ReactSlick from "react-slick";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import "./ProdDetails.css";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Table from "react-bootstrap/Table";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";
import { SnackbarProvider, useSnackbar } from "notistack";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  createTheme,
  withStyles,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        backgroundColor: "gray",
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        paddingTop: "3px",
        borderRadius: "40px",
        paddingRight: "3px",
      }}
      onClick={onClick}
    />
  );
}
const useStyles = makeStyles((theme) => ({
  margin: {
    marginTop: theme.spacing(2),
  },
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(1),
    },
  },
}));

export default function ProductDetails(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState(null);
  const [pricing, setPricing] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [value, setValue] = React.useState(0);
  const [priceNodes, setPriceNodes] = useState([]);
  const [cartAddLoading, setCartAddLoading] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  let pricingNode = [];

  const getProducts = () => {
    setLoading(true);
    let prodId = props.history.location.pathname.toString().split("/")[3];
    axiosInstance
      .get(`/products/productView/${prodId}`)
      .then((resp) => {
        setLoading(false);

        setProduct(resp.data.response);
        setQPricing(1, resp.data.response);

        for (let key in resp.data.response.quantity_wise_pricing[0]) {
          let x = resp.data.response.quantity_wise_pricing[0][key];
          pricingNode.push(
            <li>
              {x.rangeStart} - {x.rangeEnd} : ₹{x.amount}
            </li>
          );
        }
        setPriceNodes(pricingNode);
        console.log(resp.data.response);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const setQPricing = (quantity, prod) => {
    let obj = prod.quantity_wise_pricing[0];
    let perUnit = -1;
    let maxRangeEnd=-1;
    let sPrice=-1;

    for (let key in obj) {
      let x = obj[key];
      quantity = parseInt(quantity);
      
      if(x.rangeEnd>maxRangeEnd){
        sPrice=x.amount;
        maxRangeEnd=x.rangeEnd;
      }

      if (
        quantity >= parseInt(x.rangeStart) &&
        quantity <= parseInt(x.rangeEnd)
      ) {
        perUnit = x.amount;
        break;
      }
    }

    if(perUnit==-1){
        setPricing(sPrice*quantity);
    }
    else{
        setPricing(perUnit * quantity);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);
  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(value);
  const { rimProps, rsProps } = props;

  const { enqueueSnackbar } = useSnackbar();

  const handleAddToCart = () => {
    const newCartProduct = {
      product: product._id,
      newPrice: pricing,
      quantity: quantity,
    };

    setCartAddLoading(true);
    axiosInstance
      .post("/products/addProductToCart", { products: newCartProduct })
      .then((response) => {
        setCartAddLoading(false);
        console.log(response.config.data);
        enqueueSnackbar("Successfully added to Cart", { variant: "success" });
      })
      .catch((error) => {
        setCartAddLoading(false);
        enqueueSnackbar("Not able to add to Cart", { variant: "error" });
        console.log(error);
      });
  };

  return (
    <div>
      {!loading && product ? (
        <>
          <div className={classes.root}>
            {cartAddLoading ? <LinearProgress /> : ""}
          </div>
          <Container
            style={{ paddingTop: "20px" }}
            className="main_product_wrap"
          >
            <Row>
              <Breadcrumbs aria-label="breadcrumb">
                <Link to="/">Home</Link>
                <Link to="/pcbMarket">PCB Market</Link>
                <Typography color="text.primary">
                  {product.short_name}
                </Typography>
              </Breadcrumbs>
            </Row>
            <Row>
              <Col md={6} sm={12} style={{ marginTop: "23px" }}>
                <ReactSlick
                  {...{
                    dots: true,
                    dotsClass: "slick-dots slick-thumb",
                    infinite: true,
                    speed: 500,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    nextArrow: <SampleNextArrow />,
                    prevArrow: <SampleNextArrow />,
                    rimProps: {
                      enlargedImagePortalId: "portal",
                      enlargedImageContainerDimensions: {
                        width: "200%",
                        height: "100%",
                      },
                    },
                  }}
                >
                  {product.product_images.map((img, index) => {
                    return (
                      <div
                        key={index}
                        id="image"
                        style={{ position: "relative" }}
                      >
                        <img
                          src={img.location}
                          style={{ width: "100%", height: "570px" }}
                        ></img>
                        {/* <ReactImageMagnify
                                            {...{
                                                smallImage: {
                                                    alt: 'Wristwatch by Versace',
                                                    isFluidWidth: true,
                                                    src: img.location,
                                                    srcSet: img.location,
                                                    sizes: '(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px'
                                                },
                                                largeImage: {
                                                    src: img.location,
                                                    width: 1426,
                                                    height: 2000
                                                },
                                                lensStyle: { backgroundColor: 'rgba(0,0,0,.6)' },
                                                // enlargedImageContainerStyle: {
                                                //     position: 'fixed',
                                                //     top: '8px',
                                                //     left:'800px',
                                                //   }
                                            }}
                                         
                                        /> */}
                      </div>
                    );
                  })}
                </ReactSlick>
              </Col>
              <Col md={6} className="description_area">
                <div className="viewProdHead">{product.short_name}</div>
                <div className="viewProdAvailable">Availability: In stock</div>
                <Divider />
                <div className="skuName">SKU: {product.sku_name}</div>
                <div
                  className="prodDescription"
                  style={{ whiteSpace: "preWrap" }}
                >
                  {product.description}
                </div>
                <div className="curPrice">{numberFormat(pricing)}</div>
                <div className="PricingAndCard">
                  <TextField
                    id="outlined-number"
                    label="Quantity"
                    type="number"
                    value={quantity}
                    onChange={(e) => {
                      let quantity = e.target.value;

                      setQuantity(quantity);
                      if (quantity <= 1) {
                        setQuantity(1);
                        quantity = 1;
                      }
                      setQPricing(quantity, product);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ width: "100px" }}
                    variant="outlined"
                  />
                  <button
                    onClick={() => handleAddToCart()}
                    className="addToCartBtn"
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <AddShoppingCartIcon
                        style={{ marginRight: "5px" }}
                      ></AddShoppingCartIcon>
                      {"   "}Add to cart
                    </div>
                  </button>
                  <div className="skze skze1">
                    <b>Category</b> : {product.category}
                  </div>
                  <div className="skze">
                    <b>Used In </b> : {product.used_in}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: "30px", marginBottom: "40px" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                    aria-label="disabled tabs example"
                  >
                    <Tab label="Description" {...a11yProps(0)} />
                    <Tab label="Specifications" {...a11yProps(1)} />
                    <Tab label="Attachments" {...a11yProps(2)} />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <div className="header_feature">Hardware Features :</div>
                  <div>
                    {product.hardware_features.map((feature) => {
                      return <div>{feature}</div>;
                    })}
                  </div>
                  <div className="header_feature">Software Features :</div>
                  <ul>
                    {product.software_features.map((feature) => {
                      return <li>{feature}</li>;
                    })}
                  </ul>

                  <div className="header_feature">Quantity Wise Pricing :</div>
                  <ul>
                    {product.code_uploading_methods_and_tools.map((tool) => {
                      return <li>{tool}</li>;
                    })}
                  </ul>
                  <ul>{priceNodes}</ul>
                  <div className="header_feature">
                    Code Uploading Methods and Tools :
                  </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <table id="specs">
                    <tbody>
                      <tr>
                        <th>Form Factor</th>
                        <td>{product.form_factor}</td>
                      </tr>
                      <tr>
                        <th>Interfaces</th>
                        <td>{product.interfaces}</td>
                      </tr>
                      <tr>
                        <th>IoT Security Marked Or Not</th>
                        <td>{product.ioT_security_marked_or_not}</td>
                      </tr>
                      <tr>
                        <th>Lead Time</th>
                        <td>{product.lead_time}</td>
                      </tr>
                      <tr>
                        <th>Power</th>
                        <td>{product.power}</td>
                      </tr>
                      <tr>
                        <th>Weight</th>
                        <td>{product.weight}</td>
                      </tr>
                    </tbody>
                  </table>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <div className="header_feature">
                    Edta Step Files :
                    {product.edta_step_files.map((file) => {
                      return <a href={file.location}></a>;
                    })}
                  </div>
                  <div className="header_feature">Data Sheet :</div>
                </TabPanel>
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        <div className="time_wrap">
          <img src={DashboardLogo1} style={{ width: "150px" }}></img>
          <CircularProgress style={{ marginLeft: "20px" }}></CircularProgress>
        </div>
      )}
    </div>
  );
}
