import { useState } from "react";
const useErrorHandler = () => {
  const [isError, setIsError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const onChangeErrorHandler = (e) => {
    if (!isNaN(e.target.value)) {
      setIsError(false);
      setHelperText("");
    } else {
      setHelperText("It should be a number");
      setIsError(true);
    }
  };
  return [isError, helperText, onChangeErrorHandler];
};
export default useErrorHandler;
