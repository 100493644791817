import React, { useState, useEffect, useRef } from 'react';
import './Home.css';
import { Container, Row, Col } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import PCBPrinting from '../assests/pcbPrinting.svg';
import PCBArena from '../assests/pcbArena.svg';
import BOMProcurement from '../assests/BOMProcurement.svg';
import PCBAssembly from '../assests/PCBAssembly.svg';
import ThreeDServcies from '../assests/ThreeDServices.svg';
import CustomerEngagement from '../assests/CustomerEngagement.svg';
import Consultation from '../assests/Consultation.svg';
import HardwareArcade from '../assests/client_homepage/hardware_arcade.svg';
import PcbFab from '../assests/client_homepage/pcbFab.svg';
import bomEx from '../assests/client_homepage/bomEx.svg';
import digiSMT from '../assests/client_homepage/digiSMT.svg';
import threeDCOll from '../assests/client_homepage/3dcoll.svg';
import consultation from '../assests/client_homepage/consultation.svg';
import virtualManager from '../assests/client_homepage/virtualManager.svg';
import xlab from '../assests/client_homepage/xLabs.svg';
// import TawkTo from "tawkto-react";
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

import { styled, Box } from '@mui/material';
import ConsultationModal from '../Components/ConsultationModal';

const ServiceOverviewContainer = styled(Box)(({ disabled }) => ({
  display: 'flex',
  flexDirection: 'row',
  boxShadow:
    '0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
  margin: '20px',
  padding: '30px 20px',
  gap: '15px',
  alignItems: 'center',
  borderRadius: 4,
  backgroundColor: disabled ? '#E8E8E8' : '#fff',
  transition: 'all 0.2s ease-in-out',
  '& .service_sub': {
    color: '#838383',
    fontSize: 12,
  },
  '& .service_title': {
    color: '#000',
    fontSize: 20,
    fontWeight: 500,
  },
  '&:hover': {
    backgroundColor: '#E8E8E8',
  },
  '& .coming_soon': {
    color: '#838383',
    position: 'absolute',
  },
  cursor: 'pointer',
}));

const ServiceOverview = ({ service }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [showConsultationModal, setShowConsultationModal] = useState(false);

  const toggleConsultationModal = () => {
    setShowConsultationModal(!showConsultationModal);
  };

  return service.value === 'cs' ? (
    <>
      <ServiceOverviewContainer
        disabled={service?.disabled}
        onClick={toggleConsultationModal}
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
      >
        <div style={{ marginBottom: '10px' }}>
          <img src={service.src} alt={service.src}></img>
        </div>
        <div>
          <div className="service_title">{service.name}</div>
          <div className="service_sub">{service.description}</div>
          {isHovered && service?.disabled ? (
            <div className="coming_soon">COMING SOON...</div>
          ) : null}
        </div>
      </ServiceOverviewContainer>
      <ConsultationModal
        state={showConsultationModal}
        toggleModal={toggleConsultationModal}
      />
    </>
  ) : (
    <Link to={service.link}>
      <ServiceOverviewContainer
        disabled={service?.disabled}
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
      >
        <div style={{ marginBottom: '10px' }}>
          <img src={service.src} alt={service.src}></img>
        </div>
        <div>
          <div className="service_title">{service.name}</div>
          <div className="service_sub">{service.description}</div>
          {isHovered && service?.disabled ? (
            <div className="coming_soon">COMING SOON...</div>
          ) : null}
        </div>
      </ServiceOverviewContainer>
    </Link>
  );
};

const StyledButton = withStyles({
  root: {
    background: '#ff972a',
    color: 'white',
    '&:hover': {
      backgroundColor: '#ff9720',
    },
  },
})(Button);

const options = [
  {
    serviceType: 'Design and Development ',
    services: [
      {
        name: 'Hardware Arcade',
        description: 'PCBs PCBs Everywhere!',
        value: 'cl',
        disabled: true,
        // link: "/pcbMarket",
        link: '/',
        src: HardwareArcade,
      },
    ],
  },
  {
    serviceType: 'Manufacturing ',
    services: [
      {
        name: 'PCB Fabricator',
        description: 'Fabricate PCBs Like a Pro',
        value: 'pb',
        // link: "/pcbFabricator",
        link: '/pcbFabricator',
        src: PcbFab,
      },
      {
        name: 'BOM Explorer',
        description: 'Streamline Procurement, Launch Faster',
        value: 'bl',
        link: '/bomExplorer',
        src: bomEx,
      },
      {
        name: 'DigiSMT',
        description: 'Fully Managed and Scalable PCB Assembly Service',
        value: 'dg',
        // link: "/pcbFabricator",
        link: '/',
        src: digiSMT,
        disabled: true,
      },
      {
        name: '3D Colosseum',
        description: 'Enclosure manufacturing simplified',
        value: 'd3',
        link: '/3dcolosseum',
        src: threeDCOll,
      },
      // {name:"PCB Assembly",description: "Single line description",value:"bl",link:"/billing",src:PCBAssembly},
      // {name:"3D Services",description: "Single line description",value:"bl",link:"/billing",src:ThreeDServcies},
    ],
  },
  {
    serviceType: 'Miscellaneous ',
    services: [
      {
        name: 'Consult an Expert',
        description: 'Confused? We Can Help You!',
        value: 'cs',
        link: 'https://calendly.com/elecbits-saurav/',
        src: consultation,
      },
      {
        name: 'Virtual Manager',
        description: 'Your Virtual Project Manager',
        value: 'vm',
        link: '/virtualProjectManager',
        src: virtualManager,
      },
      {
        name: 'Elecbits xLabs',
        description: 'Where Magic Happens',
        value: 'bl',
        link: '/',
        disabled: true,
        src: xlab,
      },
    ],
  },
];

let autoCompleteOptions = [];

options.forEach((value) => {
  autoCompleteOptions = autoCompleteOptions.concat(value.services);
});

export default function HomePage(props) {
  const [showConsultationModal, setShowConsultationModal] = useState(false);

  const toggleConsultationModal = () => {
    setShowConsultationModal(!showConsultationModal);
  };

  const tawkMessengerRef = useRef();

  // const onLoad = () => {
  //   tawkMessengerRef.current.setAttributes(
  //     {
  //       email: "shivamgavandi256@gmail.com",
  //       name: "test name",
  //       hash: "hhhhwienckwejk",
  //     },
  //     function (err) {
  //       console.log(err, "error ");
  //     }
  //   );
  // };

  useEffect(() => {
    if (props.hasProfile === undefined) {
      props.history.push('/client/onBoarding');
    }
  }, [props.hasProfile]);

  // useEffect(() => {
  //   var tawk = new TawkTo("63a2910fdaff0e1306dda4c2", "1gkpgda3m");
  //   console.log(tawk, "this is tawk");
  //   tawk.showWidget();
  // }, []);

  return (
    <Container>
      <Row>
        <Col>
          <div className="cconsole_head">Elecbits Management Console ⚡</div>
        </Col>
      </Row>
      <Row>
        <Col lg={9} md={12} sm={12}>
          <div className="service_finder">
            <div className="find_serve_text">Find Your service</div>
            <Autocomplete
              //   onChange={(event, value) => console.log(value)}
              id="combo-box-demo"
              options={autoCompleteOptions}
              getOptionLabel={(option) => option.name}
              autoHighlight
              renderOption={(option) => (
                <React.Fragment>
                  <span
                    style={{ cursor: 'pointer', width: '100%' }}
                    onClick={() => {
                      console.log(option.link);
                      props.history.push(option.link);
                    }}
                  >
                    {option.name}
                  </span>
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  value={params.value}
                  {...params}
                  label="Search for services, features, products and docs.."
                  variant="outlined"
                />
              )}
            />
            <div className="find_serve_description">
              Try searching for keywords like PCB Designing, Certifications,
            </div>
          </div>
          <div className="allServices">
            <Container className="section_type">
              {options.map((option) => {
                return (
                  <Row>
                    <Row className="serve_sec_head">{option.serviceType}</Row>
                    {option.services.map((service) => {
                      return (
                        // <Col>
                        // <Row style={{'marginLeft': '-24px'}}>
                        <Col style={{ marginLeft: '-24px' }} md={6}>
                          <ServiceOverview service={service} />
                        </Col>
                        // </Row>
                        // </Col>
                      );
                    })}
                  </Row>
                );
              })}
            </Container>
          </div>
        </Col>
        {/* <Col lg={3} md={12} sm={12}>
          <div className="service_finder bookConsultation">
            <div className="tanl_head">Talk to an IoT expert</div>
            <div className="tal_des">
              Not sure from where to start? Book consultation with an IoT expert
              to help you to start using the EB platform to build your product.
            </div>

            <StyledButton onClick={toggleConsultationModal} variant="contained">
              Book Now
            </StyledButton>
            {/* <TawkMessengerReact propertyId="63a2910fdaff0e1306dda4c2" widgetId="1gkpgda3m" /> */}
        {/* <TawkMessengerReact propertyId="5b3266c1d0b5a547968231b3" widgetId="default" /> */}
        {/* <TawkMessengerReact
              propertyId="63a580e5daff0e1306de09a9"
              widgetId="1gkv7v240"
              // onLoad={onLoad}
              ref={tawkMessengerRef}
            /> */}
        {/* <ConsultationModal
              state={showConsultationModal}
              toggleModal={toggleConsultationModal}
            />
          </div>
          </Col> */}
      </Row>
    </Container>
  );
}
