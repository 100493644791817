import { useState, useEffect } from "react";

//components
import CustomBreadcrumbs from "../Components/CustomBreadcrumbs";
import DashboardHeader from "../ClientEngagement/DashboardHeader";

//utils
import { axiosInstance } from "../utils/axiosInterceptor";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  InputAdornment,
  useTheme,
  FormControl,
  Select,
  InputLabel,
  Pagination,
  Rating,
  Typography,
  Switch,
} from "@mui/material";
import { MenuItem } from "@material-ui/core";
import moment from "moment";
import { Link } from "react-router-dom";
import useDebounce from "../Hooks/useDebounce";
import { useSnackbar } from "notistack";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";

//styles
import {
  PageContainer,
  ColosseumProjectsContainer,
  StyledInput,
  CustomSelect,
  CustomSmallSelect,
  CustomSmallMultiSelect,
  CustomSmallInput,
  CustomInput,
  StyledRow,
  CustomButton,
} from "./VendorManagement.styles";
// import DeleteModal from "../Components/DeleteModal";

//assets
import { ReactComponent as SearchIcon } from "../assests/search.svg";
import timeline from "../assests/timeline.png";
import bin from "../assests/bin.png";
import { useHistory } from "react-router-dom";
import DeleteModal from "../Components/DeleteModal";
import VendorInfoModal from "./VendorInfoModal";

const services = [
  "Bill of Material",
  "PCB Fabrication",
  "PCB Assembly",
  "3D Printing",
  "Injection Moulding",
  "Vaccum Casting",
  "Mould Manufacturer",
  "Metal Casting",
  "Metal Fabrication",
  "Pre built Cases",
  "CNC Machining & Tooling",
  "QA &  Certifications",
  "Designing & Modeling",
  "Lab Equipment",
  "PCB Designing",
  "EV Battery & Charger",
  "Wire harness",
  "Assembly Line Equipment",
  "Mechanical Tools",
  "Smart board/tv",
  "Boxes",
];
const states = [
  "Maharashtra",
  "Uttar Pradesh",
  "Goa",
  "Karnataka",
  "Punjab",
  "Haryana",
  "Gujarat",
];

const serviceMapper = {
  "Bill of Material": "Bill of Material",
  "PCB Fabrication": "PCB Fabrication",
  "PCB Assembly": "PCB Assembly",
  "3D Printing": "3D Printing",
  "Injection Moulding": "Injection Moulding",
  "Vaccum Casting": "Vaccum Casting",
  "Mould Manufacturer": "Mould Manufacturer",
  "Metal Casting": "Metal Casting",
  "Metal Fabrication": "Metal Fabrication",
  "Pre built Cases": "Pre built Cases",
  "CNC Machining & Tooling": "CNC Machining and Tooling",
  "QA &  Certifications": "QA and  Certifications",
  "Designing & Modeling": "Designing and Modeling",
  "Lab Equipment": "Lab Equipment",
  "PCB Designing": "PCB Designing",
  "EV Battery & Charger": "EV Battery and Charger",
  "Wire harness": "Wire harness",
  "Assembly Line Equipment": "Assembly Line Equipment",
  "Mechanical Tools": "Mechanical Tools",
  "Smart board/tv": "Smart board/tv",
  Boxes: "Boxes",
};

const VendorManagement = (props) => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [deleteModalState, setDeleteModalState] = useState({
    visible: false,
    text: "",
    onYes: () => {},
  });
  const [filters, setFilters] = useState({
    service: "",
    rating: "",
    state: "",
    city: "",
    country: "",
    vendor: "",
    sort: "new",
    // squery: "",
  });
  const [isPM, setIsPM] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [pageCount, setPageCount] = useState(10);
  const [vendorModalState, setVendorModalState] = useState({
    show: false,
    vendor: null,
  });
  const [selectedVendor, setSelectedVendor] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const debouncedSearchItem = useDebounce(searchQuery, 500);

  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const serialize = (obj) =>
    Object.keys(obj)
      .map((key) => `${key}=${encodeURIComponent(obj[key])}`)
      .join("&");

  const fetchProfile = () => {
    setLoading(true);
    axiosInstance
      .get("/users/getProfile")
      .then((resp) => {
        setProfile(resp.data);
        console.log(resp);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const fetchProjects = async () => {
    try {
      let url = `/vendor/filterVendors${history.location.search}`;
      setVendors([]);
      const res = await axiosInstance.post(url, {
        page: page - 1,
        rowsPerPage,
        squery: searchQuery,
      });
      console.log(res, "opopopopopo");
      setVendors(res.data.vendors);

      setSelectedVendor(res?.data?.currentVendor || null);

      let a = res.data.total;
      console.log(a, "this is the total count");
      setPageCount(Math.floor(a / 50) + 1);
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const fetchProjectesSearch = async (query) => {
    const res = await axiosInstance.post("/vendor/getVendorsBySearch", {
      page: page - 1,
      rowsPerPage,
      query,
      filters,
    });
    console.log(res.data, "ppppppppp");
    setVendors(res.data.vendors);
    setPageCount(Math.floor(res.data.total / 50));
    setPage(1);
  };

  const getVendorScore = (statData) => {
    let total = 0;
    total +=
      Number(statData?.responseTime || 0) +
        Number(statData?.componentQuality || 0) +
        Number(statData?.pricing || 0) +
        Number(statData?.leadTime || 0) +
        Number(statData?.pastPerformance) || 0;

    let score = Math.round((total / 25) * 10);

    return score;
  };

  const getOverallColor = (v) => {
    let a = Number(v);
    if (a <= 4) return "#FF7575";
    if (a <= 6) return "#02FCB1";
    if (a == 8) return "#02FC66";
    return "#0702FC";
  };

  const deleteVendor = async (vendorId) => {
    const res = await axiosInstance.delete(`/vendor/deleteVendor/${vendorId}`);
    enqueueSnackbar("Successfully deleted vendor!", { variant: "success" });
    console.log(res);
    setDeleteModalState({
      ...deleteModalState,
      visible: false,
    });

    setVendors(vendors.filter((v) => v._id != vendorId));
  };

  const handleDeleteVendor = async (vendorId, vendorName) => {
    setDeleteModalState({
      visible: true,
      text: `Delete vendor ${vendorName}`,
      onYes: () => {
        deleteVendor(vendorId);
      },
    });
  };

  const toggleDeleteModal = () => {
    setDeleteModalState({
      ...deleteModalState,
      visible: !deleteModalState.visible,
    });
  };

  // useEffect(() => {
  //   setFilters({
  //     ...filters,
  //     squery: searchQuery,
  //   });
  // }, [searchQuery]);

  useEffect(() => {
    if (localStorage.PM) {
      setIsPM(true);
    }
    fetchProfile();
  }, []);

  useEffect(() => {
    fetchProjects();
  }, [isPM, profile]);

  useEffect(() => {
    fetchProjects();
  }, [page]);

  useEffect(() => {
    if (debouncedSearchItem) {
      fetchProjectesSearch(debouncedSearchItem);
    }
  }, [debouncedSearchItem]);

  useEffect(() => {
    if (searchQuery == "") {
      fetchProjects();
    }
  }, [searchQuery]);

  useEffect(() => {
    if (selectedVendor) {
      setVendorModalState({
        show: true,
        vendor: selectedVendor,
      });
    }
  }, [selectedVendor]);

  useEffect(() => {
    fetchProjects();
    if (history.location.search) {
      var search = history.location.search.substring(1);
      const finalObj = JSON.parse(
        '{"' +
          decodeURI(search)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );
      console.log(finalObj);
      setFilters({
        ...finalObj,
      });
    }
  }, [history.location]);

  useEffect(() => {
    axiosInstance.get("/vendor/filterDefaults").then((res) => {
      console.log("fitler defaults", res);
      setCities(res?.data?.city);
      setStates(res?.data?.states);
    });
  }, []);

  const handleFilterChange = (e) => {
    setPage(1);
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
    history.push({
      pathname: `${isPM ? "/pm" : "/admin"}/vendorMasterList`,
      search: serialize({
        ...filters,
        [e.target.name]: e.target.value,
      }),
    });
  };

  const handleSwitchChange = (e) => {
    setPage(1);
    setFilters({
      ...filters,
      [e.target.name]: e.target.checked,
    });
    history.push({
      pathname: `${isPM ? "/pm" : "/admin"}/vendorMasterList`,
      search: serialize({
        ...filters,
        [e.target.name]: e.target.checked,
      }),
    });
  };

  useEffect(() => {
    console.log(filters, "tghese are the filters");
  }, [filters]);

  const toggleVendorModal = (vendor) => {
    // const qp = new URLSearchParams(history.location.search);

    if (vendorModalState.show) {
      setVendorModalState({
        show: false,
        vendor: null,
      });
      // qp.delete("vendor");
      // history.replace({
      //   search: qp.toString(),
      // });
      setSelectedVendor(null);

      history.push({
        pathname: `${isPM ? "/pm" : "/admin"}/vendorMasterList`,
        search: serialize({
          ...filters,
          vendor: "",
        }),
      });
    } else {
      setVendorModalState({
        show: true,
        vendor,
      });
      history.push({
        pathname: `${isPM ? "/pm" : "/admin"}/vendorMasterList`,
        search: serialize({
          ...filters,
          vendor: vendor.vendorId,
        }),
      });
    }
  };

  return (
    <Box>
      {!loading ? (
        <PageContainer>
          {/* <DashboardHeader profile_data={profile.data} isBlack={true} /> */}
          <ColosseumProjectsContainer>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <CustomBreadcrumbs
                data={[
                  {
                    name: "Home",
                    link: "/",
                  },
                  {
                    name: "EB VMS",
                    link: `${
                      isPM ? "/pm" : "/admin"
                    }/vendorManagementDashboard`,
                  },
                  {
                    name: "EB Vendor Master List",
                    link: `${isPM ? "/pm" : "/admin"}/vendorMasterList`,
                  },
                ]}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "50px",
                [theme.breakpoints.down("md")]: {
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "10px",
                },
              }}
            >
              <div className="title">Vendor Master List</div>
              <Box
                sx={{
                  display: "flex",
                  gap: "5px",
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                }}
              >
                <Box sx={{ minWidth: 130 }}>
                  <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel
                      sx={{ fontSize: "15px" }}
                      id="demo-select-small"
                    >
                      Sort By
                    </InputLabel>
                    <CustomSmallSelect
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={filters.sort}
                      label="Sort by"
                      name="sort"
                      onChange={handleFilterChange}
                    >
                      <MenuItem value="">none</MenuItem>

                      <MenuItem value="old">
                        <Typography>Old first</Typography>
                      </MenuItem>
                      <MenuItem value="new">
                        <Typography>New first</Typography>
                      </MenuItem>
                    </CustomSmallSelect>
                  </FormControl>
                </Box>
                <Box sx={{ minWidth: 130 }}>
                  <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel
                      sx={{ fontSize: "15px" }}
                      id="demo-select-small"
                    >
                      Rating
                    </InputLabel>
                    <CustomSmallSelect
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={filters.rating}
                      label="Rating"
                      name="rating"
                      onChange={handleFilterChange}
                    >
                      <MenuItem value="">none</MenuItem>
                      {[...Array(10)].map((rating, idx) => (
                        <MenuItem value={idx + 1}>
                          <Typography>{idx + 1}</Typography>
                        </MenuItem>
                      ))}
                    </CustomSmallSelect>
                  </FormControl>
                </Box>

                <Box sx={{ minWidth: 130 }}>
                  <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel
                      sx={{ fontSize: "15px" }}
                      id="demo-select-small"
                    >
                      State
                    </InputLabel>
                    <CustomSmallSelect
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={filters.state}
                      label="State"
                      name="state"
                      onChange={handleFilterChange}
                    >
                      <MenuItem value="">none</MenuItem>
                      {states.map((state, idx) => (
                        <MenuItem value={state}>{state}</MenuItem>
                      ))}
                    </CustomSmallSelect>
                  </FormControl>
                </Box>

                <Box sx={{ minWidth: 130 }}>
                  <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel
                      sx={{ fontSize: "15px" }}
                      id="demo-select-small"
                    >
                      City
                    </InputLabel>
                    <CustomSmallSelect
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={filters.city}
                      label="City"
                      name="city"
                      onChange={handleFilterChange}
                    >
                      <MenuItem value="">none</MenuItem>
                      {cities.map((city, idx) => (
                        <MenuItem value={city}>{city}</MenuItem>
                      ))}
                    </CustomSmallSelect>
                  </FormControl>
                </Box>

                <Box sx={{ minWidth: 130 }}>
                  <FormControl sx={{ m: 1, minWidth: 170 }} size="small">
                    <InputLabel
                      sx={{ fontSize: "15px" }}
                      id="demo-select-small"
                    >
                      Service Category
                    </InputLabel>
                    <CustomSmallMultiSelect
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={filters.service}
                      label="Service Category"
                      name="service"
                      onChange={handleFilterChange}
                    >
                      <MenuItem value="">none</MenuItem>
                      {services.map((service) => (
                        <MenuItem value={service}>{service}</MenuItem>
                      ))}
                    </CustomSmallMultiSelect>
                  </FormControl>
                </Box>

                <Box sx={{ marginBottom: 0.9 }}>
                  <CustomSmallInput
                    fullWidth
                    type="text"
                    placeholder="Search for projects,users,company"
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <Typography sx={{ color: "#686868" }}>Recommended</Typography>
              <Switch
                checked={filters.recommended == "true"}
                onChange={handleSwitchChange}
                name="recommended"
              />
            </Box>
            <TableContainer component={Paper} sx={{ marginTop: "30px" }}>
              <Table sx={{ minWidth: 50 }} aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#8B8B8B33" }}>
                    <TableCell
                      sx={{ fontSize: "0.9rem", fontWeight: 500 }}
                      align="left"
                      minWidth={80}
                    >
                      S No
                    </TableCell>
                    <TableCell
                      sx={{ fontSize: "0.9rem", fontWeight: 500 }}
                      align="left"
                    >
                      Vendor Name
                    </TableCell>
                    <TableCell
                      sx={{ fontSize: "0.9rem", fontWeight: 500 }}
                      align="left"
                    >
                      Vendor ID
                    </TableCell>
                    <TableCell
                      sx={{ fontSize: "0.9rem", fontWeight: 500 }}
                      align="left"
                    >
                      Vendor Service
                    </TableCell>
                    <TableCell
                      sx={{ fontSize: "0.9rem", fontWeight: 500 }}
                      align="left"
                    >
                      Email
                    </TableCell>{" "}
                    <TableCell
                      sx={{ fontSize: "0.9rem", fontWeight: 500 }}
                      align="left"
                    >
                      Phone
                    </TableCell>{" "}
                    <TableCell
                      sx={{ fontSize: "0.9rem", fontWeight: 500 }}
                      align="left"
                    >
                      State
                    </TableCell>
                    <TableCell
                      sx={{ fontSize: "0.9rem", fontWeight: 500 }}
                      align="left"
                    >
                      Rating
                    </TableCell>
                    <TableCell
                      sx={{ fontSize: "0.9rem", fontWeight: 500 }}
                      align="center"
                    >
                      Delete
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {vendors?.length > 0 ? (
                    <>
                      {vendors.map((v) => {
                        var val = v?.location2
                          ? (val = Object.keys(v?.location2)
                              .map((key) => v?.location2[key])
                              .join(","))
                          : "";
                        return (
                          <TableRow
                            hover
                            onClick={(e) => {
                              if (e.target?.id == "deleteimg") {
                                return;
                              }
                              toggleVendorModal(v);
                            }}
                          >
                            <TableCell>{v.sNo}</TableCell>
                            <TableCell>{v.vendorName}</TableCell>
                            <TableCell>{v.vendorId}</TableCell>
                            <TableCell>{v.vendorService}</TableCell>
                            <TableCell>{v?.emails[0]}</TableCell>
                            <TableCell>{v?.phone[0]}</TableCell>
                            <TableCell>{val}</TableCell>
                            <TableCell>
                              <Box sx={{ width: "30px", height: "30px" }}>
                                <CircularProgressbarWithChildren
                                  value={getVendorScore(v?.statistics) * 10}
                                  styles={{
                                    path: {
                                      stroke: getOverallColor(
                                        getVendorScore(v?.statistics)
                                      ),
                                    },
                                  }}
                                  counterClockwise
                                >
                                  <Typography
                                    sx={{ fontSize: "1em", fontWeight: 700 }}
                                  >
                                    {getVendorScore(v?.statistics)}
                                  </Typography>
                                </CircularProgressbarWithChildren>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <img
                                src={bin}
                                id="deleteimg"
                                onClick={() => {
                                  console.log("delete clicked");
                                  handleDeleteVendor(v?._id, v?.vendorName);
                                }}
                                style={{ cursor: "pointer" }}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </>
                  ) : (
                    <TableCell
                      colSpan={7}
                      sx={{
                        color: "#737373",
                        margin: "10px",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      Currently you don't have any projects !
                    </TableCell>
                  )}
                </TableBody>
              </Table>
              <VendorInfoModal
                state={vendorModalState.show}
                toggleModal={toggleVendorModal}
                vendor={vendorModalState.vendor}
              />
              <DeleteModal
                onYes={deleteModalState.onYes}
                onNo={toggleDeleteModal}
                state={deleteModalState.visible}
                toggleModal={toggleDeleteModal}
                text={deleteModalState.text}
              />
            </TableContainer>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Pagination
                count={pageCount}
                page={page}
                onChange={handleChangePage}
                variant="outlined"
                shape="rounded"
                sx={{ margin: "30px auto" }}
              />
            </Box>
            {/* <TablePagination
              rowsPerPageOptions={[50, 100]}
              component="div"
              count={vendors.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
          </ColosseumProjectsContainer>
        </PageContainer>
      ) : null}
    </Box>
  );
};

export default VendorManagement;
