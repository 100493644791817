import React from "react";
import { Container, Row,Col } from "react-bootstrap";
import './dashboard.css';

export default function AdminStats(props) {
   
  return (
    <Container className="stat_wrapper">
      <Row>
        <h2 className="stat_header">Hi Admin!</h2>
      </Row>
      <Row className="stats_row">
        <Col md={4}>
          <div className="stat_card"><div>{props.project_data.data.length}</div><div>Total Projects</div> </div>
        </Col>
        <Col md={4}>
          <div className="stat_card"><div>{(props.allUsers)?props.allUsers.data.data.length:0}</div><div>Total Clients</div></div>
        </Col>
        <Col md={4}>
          <div className="stat_card"><div>{props.project_data.data.length}</div><div>Total Projects</div></div>
        </Col>
      </Row>
    </Container>
  );
}
