import { styled, Box, Button } from "@mui/material";
import { Switch } from "@material-ui/core";

export const PageContainer = styled(Box)(() => ({
  width: "100vw",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
}));

export const SupplierQuotationsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  flex: 1,
  padding: "50px 50px",
  fontFamily: "Poppins",
  backgroundColor: "#F4F7FE",
  overflowY: "auto",
  "& .head-title": {
    fontWeight: 700,
    color: "#898888",
    marginTop: "30px",
  },

  "& .title": {
    marginTop: "10px",
    fontWeight: 600,
    marginBottom: "20px",
  },
  "& .sub-title": {
    marginTop: "10px",
    color: "#8B8B8B",
    marginBottom: "50px",
  },
  ".card-container": {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    gap: "30px",
  },
  [theme.breakpoints.down("md")]: {
    ".card-container": {
      flexDirection: "column",
    },
  },
}));

export const CustomButton = styled(Button)(() => ({
  "&&&": {
    width: "fit-content",
    height: "fit-content",
    backgroundColor: "#0275FC",
    color: "#fff",
    borderRadius: "4px",
    padding: "5px 20px",
  },
}));

export const CustomSwitch = styled(Switch)(({ theme, checked }) => ({
  "&&&": {
    display: "flex",
    alignItems: "center",
    "& .MuiSwitch-track": {
      backgroundColor: checked ? "#76EE59" : "#a2a3a3",
      opacity: 1,
      height: "20px",
      borderRadius: "30px",
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: "#fff",
      height: "20px",
      width: "20px",
    },
  },
}));

export const BrowseButton = styled(Button)(() => ({
  "&&": {
    backgroundColor: "#fff",
    color: "#000",
    padding: "10px 20px",
    border: "2px solid #E8E8E8",
    borderRadius: "8px",
    textTransform: "none",
    fontWeight: 400,
  },
}));
