import React, { useState, useEffect } from 'react';

//components
import CustomBreadcrumbs from '../../Components/CustomBreadcrumbs';
import DashboardHeader from '../../ClientEngagement/DashboardHeader';
import CustomFileDropper from '../../Components/CustomFileDropper';

//utils
import { axiosInstance } from '../../utils/axiosInterceptor';
import {
  Box,
  createTheme,
  ThemeProvider,
  Grid,
  Tooltip,
  Typography,
  Slider,
  Button,
} from '@mui/material';
import { MenuItem } from '@material-ui/core';
import { pcbData } from './createProject.data';
// import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

//styles
import {
  PageContainer,
  CreateProjectContainer,
  CreateProjectFormContainer,
  CusotmGridItem,
  CustomInput,
  CustomSelect,
  CustomButton,
} from './CreateNewProject.styles';

//assets

const dropzoneTheme = createTheme({
  overrides: {
    root: {
      backgroundColor: 'transparent',
    },
  },
});

const CreateFabProject = ({ isAdmin }) => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [bomFile, setBomFile] = useState(null);
  const [createLoading, setCreateLoading] = useState(false);
  const [isPM, setIsPM] = useState(localStorage.PM);
  const [projectData, setProjectData] = useState({
    numberOfBoards: 0,
    projectName: '',
    otherSpecification: '',
  });
  const [errors, setErrors] = useState({
    numberOfBoardsError: false,
  });
  const [userEmail, setUserEmail] = useState('');

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const fetchProfile = () => {
    setLoading(true);
    axiosInstance
      .get('/users/getProfile')
      .then((resp) => {
        setProfile(resp.data);
        console.log(resp);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleChange = (e) => {
    setProjectData({
      ...projectData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    //numberOfBoards check
    if (isNaN(Number(projectData.numberOfBoards))) {
      setErrors({
        ...errors,
        numberOfBoardsError: true,
      });
      enqueueSnackbar('Quantity should be a valid number', {
        variant: 'error',
      });
      return;
    }

    if (bomFile === null || projectData.colour == '') {
      if (bomFile === null) {
        enqueueSnackbar('Please upload bom file.', { variant: 'info' });
        return;
      }
    }
    let formData = new FormData();
    formData.append('bom', bomFile);
    // formData.append("printingService", projectData.printingService);
    for (let i in projectData) {
      formData.append(i, projectData[i]);
    }

    for (let pair of formData.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }
    setCreateLoading(true);
    const token = localStorage.getItem('token');
    try {
      let url;
      if (isAdmin) {
        url = '/bom/admin/createProject';
        formData.append('userEmail', userEmail);
      } else {
        url = '/bom/client/createProject';
      }
      const res = await axiosInstance.post(url, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-type': 'multipart/form-data',
        },
      });
      console.log(res);
      enqueueSnackbar('Project Created Successfully!', {
        variant: 'success',
      });
      setCreateLoading(false);
      if (isAdmin) {
        history.push(`/admin/bomExplorer/timeline/${res.data._id}`);
      } else {
        history.push(`/bomExplorerTimeline/${res.data._id}`);
      }
    } catch (err) {
      console.log(e);
      enqueueSnackbar('some error occurred', { variant: 'error' });
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <Box>
      {!loading ? (
        <PageContainer>
          <DashboardHeader profile_data={profile.data} isBlack={true} />
          <CreateProjectContainer>
            <CustomBreadcrumbs
              data={[
                {
                  name: 'Home',
                  link: '/',
                },
                {
                  name: 'EB BOM Explorer',
                  link: `${
                    isPM ? '/pm' : isAdmin ? '/admin' : '/'
                  }bomExplorer `,
                },
                {
                  name: 'Create new project',
                  link: `${
                    isPM
                      ? '/pm/bomNewProject'
                      : isAdmin
                      ? '/admin/bomNewProject'
                      : '/bomNewProject'
                  } `,
                },
              ]}
            />
            <div className="title">Create new Project - BOM Explorer</div>
            <CreateProjectFormContainer>
              <div className="dropzone-container">
                <CustomFileDropper
                  setFile={setBomFile}
                  single
                  headerText="Drag and drop to upload a file"
                  subText="Please make sure that the BOM excel is in the correct format"
                />
                <div style={{ marginTop: '1rem' }}>
                  Please make sure that the BOM excel is in the correct format.{' '}
                  <a href="https://elecbits-platform.s3.ap-south-1.amazonaws.com/Eb-BOM-Sample-Sheet-6868.xlsx">
                    Download Sample
                  </a>
                </div>
              </div>
              <form onSubmit={handleFormSubmit}>
                <Grid container spacing={5}>
                  <CusotmGridItem item md={6}>
                    <div className="grid-item-inner">
                      <label htmlFor="project-name">Project Name*</label>
                      <CustomInput
                        type="text"
                        id="project-name"
                        name="projectName"
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </CusotmGridItem>
                  <CusotmGridItem item md={6} shift></CusotmGridItem>
                  <CusotmGridItem item md={6}>
                    <div className="grid-item-inner">
                      <label htmlFor="numberOfBoards">Quantity*</label>
                      <CustomInput
                        type="text"
                        id="numberOfBoards"
                        name="numberOfBoards"
                        required
                        onChange={handleChange}
                        error={errors.numberOfBoardsError}
                      />
                    </div>
                  </CusotmGridItem>

                  {isAdmin ? (
                    <CusotmGridItem item md={6}>
                      <div className="grid-item-inner">
                        <label htmlFor="user-email">User Email*</label>
                        <CustomInput
                          type="text"
                          id="user-email"
                          name="userEmail"
                          onChange={(e) => {
                            setUserEmail(e.target.value);
                          }}
                          required
                          value={userEmail}
                        />
                      </div>
                    </CusotmGridItem>
                  ) : null}
                  <CusotmGridItem item md={12} isLast>
                    <div className="grid-item-inner">
                      <label htmlFor="otherSpecification">
                        Anyother Specification
                      </label>
                      <CustomInput
                        type="text"
                        id="otherSpecification"
                        name="otherSpecification"
                        onChange={handleChange}
                        multiline
                        rows={4}
                        placeholder="Additional Service may include in - fill, support, surface finishing, polishing, drilling and treading, tapping, beading and blasting etc"
                      />
                    </div>
                  </CusotmGridItem>
                </Grid>
                <CustomButton
                  variant="contained"
                  type="submit"
                  loading={createLoading}
                  loadingPosition="end"
                >
                  Submit
                </CustomButton>
              </form>
            </CreateProjectFormContainer>
          </CreateProjectContainer>
        </PageContainer>
      ) : null}
    </Box>
  );
};

export default CreateFabProject;
