import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableFooter from '@material-ui/core/TableFooter';
import AddIcon from '@material-ui/icons/Add';
import ProjectCreate from './ProjectCreate.js';
import EditProject from './EditProject';
import moment from 'moment';
import TablePagination from '@material-ui/core/TablePagination';
import './dashboard.css';
import { Container, Row, Col } from 'react-bootstrap';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@mui/material/Tooltip';

export default function AdminTable(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, props.rowsToRender.length - page * rowsPerPage);
  return (
    <Container className="admin_table_wrapper">
      <Row className="stat_table_row">
        <Col>
          <h2>Clients Table</h2>
          <Paper>
            <TableContainer
              component={Paper}
              style={{
                position: 'relative',
                marginBottom: '20px',
                boxShadow: '0px 0px 0px 0px white',
              }}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Client Name</TableCell>
                    <TableCell align="right">Collaborators</TableCell>
                    <TableCell align="right">Project Name</TableCell>
                    <TableCell align="right">Current Step</TableCell>
                    <TableCell align="right">Due Date</TableCell>
                    <TableCell align="right">Options</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.rowsToRender
                    .slice(0)
                    .reverse()
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow key={row._id}>
                          <TableCell component="th" scope="row">
                            {row.client_name}
                          </TableCell>
                          <TableCell align="right">
                            {row.collaborators}
                          </TableCell>
                          <TableCell align="right">
                            {row.project_name}
                          </TableCell>
                          <TableCell align="right">
                            {row.current_step}
                          </TableCell>
                          <TableCell align="right">{row.due_date}</TableCell>
                          <TableCell align="right">{row.options}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="foot_table">
              <ProjectCreate
                fetchUsers={props.fetchUsers}
                rowsProcess={props.rowsProcess}
                allUsers={props.allUsers}
                fetchProjectData={props.fetchProjectData}
              ></ProjectCreate>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={props.rowsToRender.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </Paper>
        </Col>
      </Row>
    </Container>
  );
}
