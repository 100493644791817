import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  styled,
  Slide,
  Grid,
  InputAdornment,
  useTheme,
  FormControl,
  Select,
  InputLabel,
  Rating,
  Switch,
  // CircularProgress,
} from "@mui/material";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { MenuItem } from "@material-ui/core";
import { CustomButton } from "../BOMProcurement/BomOrderSummary";
import { axiosInstance } from "../utils/axiosInterceptor";
import { useSnackbar } from "notistack";
import { CustomSelect, CustomButton2 } from "./VendorManagement.styles";
import VendorDocModal from "./VendorDocModal";
import { ReactComponent as CloseIcon } from "../assests/close.svg";
import { useDropzone } from "react-dropzone";
import { ReactComponent as UploadIcon } from "../assests/Upload.svg";
import { ReactComponent as DownloadIcon } from "../assests/download.svg";
import DocumentsAccordian from "./DocumentsAccordian";
import SupportedDocumentsAccordian from "./DocumentsAccordian";

import { ReactComponent as SaveIcon } from "../assests/rocket.svg";
import { ReactComponent as EditIcon } from "../assests/enableEdit.svg";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DescriptionIcon from "@mui/icons-material/Description";
import BusinessIcon from "@mui/icons-material/Business";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import BarChartIcon from "@mui/icons-material/BarChart";
import VendorImageEditModal from "./VendirImageEditModal";

const VendorInfoModalContainer = styled(Box)(({ theme }) => ({
  width: "90vw",
  height: "fit-content",
  position: "absolute",
  top: "5vh",
  left: "5vw",
  maxHeight: "90vh",
  overflowY: "auto",
  backgroundColor: "#fff",
  outline: "none",
  borderRadius: 10,
  padding: "10px 20px 20px 20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  "& .headerText": {
    fontSize: "1.7em",
    fontWeight: 600,
    marginBottom: "30px",
  },
  "& form": {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    alignItems: "start",
  },

  "& .MuiInputBase-input.Mui-disabled": {
    color: "#000 !important",
    "-webkit-text-fill-color": "#000 !important",
    borderColor: "#000 !important",
  },
  "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
    borderColor: "#000 !important",
  },

  [theme.breakpoints.down("md")]: {
    width: "50vw",
  },
  [theme.breakpoints.down("sm")]: {
    width: "80vw",
  },
}));

const services = [
  "Bill of Material",
  "PCB Fabrication",
  "PCB Assembly",
  "3D Printing",
  "Injection Moulding",
  "Vaccum Casting",
  "Mould Manufacturer",
  "Metal Casting",
  "Metal Fabrication",
  "Pre built Cases",
  "CNC Machining & Tooling",
  "QA &  Certifications",
  "Designing & Modeling",
  "Lab Equipment",
  "PCB Designing",
  "EV Battery & Charger",
  "Wire harness",
  "Assembly Line Equipment",
  "Mechanical Tools",
  "Smart board/tv",
  "Boxes",
];

const VendorInfoModal = ({ state, toggleModal, vendor }) => {
  const [data, setData] = useState({
    vendorName: "",
    vendorId: "",
    vendorService: "",
    personName: "",
    emails: [],
    phone: [],
    location: "",
    websiteLink: "",
    remark: "",
    whatsappNo: "",
    address: "",
    bankName: "",
    accountName: "",
    accountNo: "",
    ifsc: "",
    branch: "",
    mobileNo: "",
    responseTime: "",
    timeForQuotation: "",
    paymentTerms: "",
    modeOfPayment: "",
    cinNumber: "",
    panNumber: "",
    gstNumber: "",
  });
  const [addLoading, setAddLoading] = useState(false);
  const [adminComment, setAdminComment] = useState("");
  const [primaryData, setPrimaryData] = useState({
    vendorName: "",
    vendorId: "",
    vendorService: "",
    personName: "",
    emails: "",
    phone: "",
    location: "",
    websiteLink: "",
    whatsappNo: "",
    address: "",
    remarks: [],
  });
  const [industryData, setIndustryData] = useState({
    rating: 0,
    responseTime: "",
    orderOfServices: "",
    timeForQuotation: "",
    paymentTerms: "",
    modeOfPayment: "",
    cinNumber: "",
    panNumber: "",
    gstNumber: "",
    reviews: "",
  });
  const [bankData, setBankData] = useState({
    bankName: "",
    accountName: "",
    accountNumber: "",
    ifsc: "",
    branch: "",
    mobileNumber: "",
  });
  const [statData, setStatData] = useState({
    responseTime: 0,
    componentQuality: 0,
    pricing: 0,
    leadTime: 0,
    pastPerformance: 0,
  });
  const [images, setImages] = useState([]);
  const [docs, setDocs] = useState([]);
  const [supportedDocs, setSpportedDocs] = useState([]);
  const [currentRemark, setCurrentRemark] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [isRecommended, setIsRecommended] = useState(false);

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const [showDocModal, setShowDocModal] = useState(false);
  const toggleDocModal = () => {
    setShowDocModal(!showDocModal);
  };

  const [showSupportedModal, setShowSupportedModal] = useState(false);
  const toggleSupportedModal = () => {
    setShowSupportedModal(!showSupportedModal);
  };

  const [showImageModal, setShowImageModal] = useState(false);
  const toggleImageModal = () => {
    setShowImageModal(!showImageModal);
  };

  const { enqueueSnackbar } = useSnackbar();
  const { getRootProps, getInputProps, acceptedFiles, isDragActive } =
    useDropzone();
  const theme = useTheme();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleDataChange = (e) => {
    console.log(e.target.name, e.target.value);
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handlePrimaryDataChange = (e) => {
    setPrimaryData({
      ...primaryData,
      [e.target.name]: e.target.value,
    });
  };

  const handleIndustryDataChange = (e) => {
    setIndustryData({
      ...industryData,
      [e.target.name]: e.target.value,
    });
  };

  const handleBankDataChange = (e) => {
    setBankData({
      ...bankData,
      [e.target.name]: e.target.value,
    });
  };

  const handleStatChange = (e) => {
    setStatData({
      ...statData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (vendor) {
      console.log(vendor, "this is your vendor");
      setPrimaryData({
        vendorName: vendor?.vendorName,
        vendorId: vendor?.vendorId,
        vendorService: vendor?.vendorService,
        personName: vendor?.personName,
        emails: vendor?.emails?.join(","),
        phone: vendor?.phone?.join(","),
        location: `${vendor?.location2?.city || ""},${
          vendor?.location2?.state || ""
        },${vendor?.location2?.country || ""}`,
        websiteLink: vendor?.websiteLink,
        whatsappNo: vendor?.whatsappNo,
        address: vendor?.address,
        remarks: vendor?.remarks,
      });
      setIndustryData({
        rating: vendor?.indusryInfo?.rating,
        responseTime: vendor?.indusryInfo?.responseTime,
        orderOfServices: vendor?.indusryInfo?.orderOfServices?.join(","),
        timeForQuotation: vendor?.indusryInfo?.timeForQuotation,
        paymentTerms: vendor?.indusryInfo?.paymentTerms,
        modeOfPayment: vendor?.indusryInfo?.modeOfPayment,
        cinNumber: vendor?.indusryInfo?.cinNumber,
        panNumber: vendor?.indusryInfo?.panNumber,
        gstNumber: vendor?.indusryInfo?.gstNumber,
        reviews: vendor?.indusryInfo?.reviews?.join(","),
      });
      setBankData({
        bankName: vendor?.bankInfo?.bankName,
        accountName: vendor?.bankInfo?.accountName,
        accountNumber: vendor?.bankInfo?.accountNo,
        ifsc: vendor?.bankInfo?.ifsc,
        branch: vendor?.bankInfo?.Branch,
        mobileNumber: vendor?.bankInfo?.mobileNo,
      });
      setStatData({
        responseTime: vendor?.statistics?.responseTime || 0,
        componentQuality: vendor?.statistics?.componentQuality || 0,
        pricing: vendor?.statistics?.pricing || 0,
        leadTime: vendor?.statistics?.leadTime || 0,
        pastPerformance: vendor?.statistics?.pastPerformance || 0,
      });
      setAdminComment(vendor.adminComment);
      setIsRecommended(vendor?.recommended);
      console.log("ADMIN COMMENT", vendor.adminComment);
    }
  }, [vendor]);

  useEffect(() => {
    setImages(acceptedFiles);
  }, [acceptedFiles]);

  const getVendorScore = () => {
    let total = 0;
    total +=
      Number(statData.responseTime) +
      Number(statData.componentQuality) +
      Number(statData.pricing) +
      Number(statData.leadTime) +
      Number(statData.pastPerformance);

    let score = Math.round((total / 25) * 10);

    return score;
  };

  const getStatColor = (v) => {
    let a = Number(v);
    if (a <= 2) return "#FF7575";
    if (a <= 4) return "#02FCB1";
    // if (a == 4) return '#02FC66';
    return "#0702FC";
  };

  const getOverallColor = (v) => {
    let a = Number(v);
    if (a <= 3) return "#FF7575";
    if (a <= 7) return "#02FCB1";
    if (a >= 8) return "#0702FC";
  };

  const uploadDocuments = async () => {
    let vendorId = vendor?._id;
    let formData = new FormData();
    let url;
    const token = localStorage.getItem("token");

    if (!vendorId) {
      return;
    }

    if (images.length > 0) {
      // formData.append("images", acceptedFiles);
      images.forEach((fl) => formData.append("images", fl));
      url = `/vendor/addImages/${vendorId}`;

      const res = await axiosInstance.post(url, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "multipart/form-data",
        },
      });

      console.log(res, "yo,res1");
    }

    formData.delete("images");

    if (docs.length > 0) {
      docs.forEach((fl) => formData.append("docs", fl));

      url = `/vendor/addDocs/${vendorId}`;

      const res = await axiosInstance.post(url, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "multipart/form-data",
        },
      });
      console.log(res, "yo,res2");
    }

    formData.delete("docs");

    if (supportedDocs.length > 0) {
      supportedDocs.forEach((fl) => formData.append("sdocs", fl));
      url = `/vendor/addSupportedDocs/${vendorId}`;
      const res = await axiosInstance.post(url, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "multipart/form-data",
        },
      });
      console.log(res, "yo,res3");
    }
  };

  const handleSubmit = async (e) => {
    if (
      primaryData.vendorName == "" ||
      primaryData.vendorId == "" ||
      primaryData.vendorService == ""
    ) {
      enqueueSnackbar("Please fill the required fields", { variant: "error" });
      return;
    }
    setAddLoading(true);
    console.log("isRecommended", isRecommended);
    try {
      const res = await axiosInstance.post(
        `/vendor/updateVendor/${vendor?._id}`,
        {
          primaryData,
          industryData,
          bankData,
          statData,
          adminComment,
          overallScore: getVendorScore(),
          isRecommended: isRecommended,
        }
      );
      console.log(res, "yo, this is the invincible response");

      await uploadDocuments();

      setAddLoading(false);
      setEditMode(false);
      setImages([]);
      setDocs([]);
      setSpportedDocs([]);
      enqueueSnackbar("Vendor updated successfully!", { variant: "success" });
    } catch (error) {
      setAddLoading(false);
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  const handleFileDelete = async (vendorId, fileLocation, fileName) => {
    const res = await axiosInstance.post("/vendor/deleteDoc", {
      vendorId,
      fileLocation: fileLocation,
      fileName: fileName,
    });
    console.log(res, "this is the invisible response");
    setAddLoading(false);
    setEditMode(false);
    enqueueSnackbar("File deleted successfully!", { variant: "success" });
    setTimeout(() => {}, 2000);
    window.location.reload();
  };
  const handleSupportedFileDelete = async (
    vendorId,
    fileLocation,
    fileName
  ) => {
    const res = await axiosInstance.post("/vendor/deleteSupportedDoc", {
      vendorId,
      fileLocation: fileLocation,
      fileName: fileName,
    });
    console.log(res, "this is the invisible response");
    setAddLoading(false);
    setEditMode(false);
    enqueueSnackbar("File deleted successfully!", { variant: "success" });
    setTimeout(() => {}, 2000);
    window.location.reload();
  };
  return (
    <Modal open={state} onClose={toggleModal}>
      <Slide in={state} direction="up">
        <VendorInfoModalContainer>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "10px",
              [theme.breakpoints.down("md")]: {
                flexDirection: "column",
                alignItems: "center",
                gap: "10px",
              },
            }}
          >
            <div className="title">
              {vendor?.vendorName} - {vendor?.vendorId}
            </div>

            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <CustomButton2
                disabled={addLoading}
                fill
                onClick={toggleEditMode}
              >
                <EditIcon />
                {editMode ? "Disable Editing" : " Enable Editing"}
              </CustomButton2>
              <CustomButton2
                onClick={handleSubmit}
                disabled={addLoading || !editMode}
              >
                <SaveIcon />
                Save
              </CustomButton2>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              alignItems: "center",
              zIndex: 9999,
              marginBottom: "30px",
              width: "100%",
            }}
          >
            {primaryData.remarks.length > 0 &&
              primaryData.remarks.map((remark) => (
                <Box
                  sx={{
                    fontSize: "0.8em",
                    backgroundColor: "#AD90FF",
                    border: "0.5px solid #6943D4",
                    borderRadius: "10px",
                    padding: "5px 12px",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    boxShadow: "1px 1px 6px #C4AEFF",
                    color: "#000000",
                  }}
                >
                  <Typography> {remark}</Typography>
                  {editMode ? (
                    <Box
                      sx={{
                        cursor: "pointer",
                        padding: "3px",
                      }}
                      onClick={() => {
                        const nrm = primaryData.remarks.filter(
                          (r) => r != remark
                        );
                        setPrimaryData({
                          ...primaryData,
                          remarks: nrm,
                        });
                      }}
                    >
                      <CloseIcon style={{ cursor: "pointer" }} />
                    </Box>
                  ) : null}
                </Box>
              ))}
          </Box>
          <Box sx={{ display: "flex", gap: "60px" }}>
            <Grid sx={{ flex: 3 }} container spacing={3}>
              <Grid item md={12}>
                <Typography
                  sx={{
                    fontSize: "1.5em",
                    fontWeight: 600,
                    marginTop: "30px",
                  }}
                >
                  <BarChartIcon
                    sx={{ marginBottom: "0.5rem", marginRight: "0.5rem" }}
                  />
                  Vendor Stats
                </Typography>
              </Grid>
              <Grid item md={12}>
                <Box sx={{ display: "flex", gap: "50px" }}>
                  <Box sx={{ width: "150px", height: "150px" }}>
                    <CircularProgressbarWithChildren
                      value={getVendorScore() * 10}
                      styles={{
                        path: {
                          stroke: getOverallColor(getVendorScore()),
                        },
                      }}
                      counterClockwise
                    >
                      <Typography sx={{ fontSize: "2em", fontWeight: 700 }}>
                        {getVendorScore()}
                      </Typography>
                    </CircularProgressbarWithChildren>
                  </Box>
                  {/* <CircularProgress
                    variant="determinate"
                    value={60}
                    style={{ width: "100px", height: "100px" }}
                    conte
                  >
                    60
                  </CircularProgress> */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <Box sx={{ display: "flex", gap: "10px" }}>
                      <Typography sx={{ color: "#767676" }}>
                        Vendor Name:
                      </Typography>
                      <Typography sx={{ fontWeight: 600 }}>
                        {primaryData.vendorName}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", gap: "10px" }}>
                      <Typography sx={{ color: "#767676" }}>
                        Location:
                      </Typography>
                      <Typography sx={{ fontWeight: 600 }}>
                        {primaryData.location}
                      </Typography>
                    </Box>{" "}
                    <Box sx={{ display: "flex", gap: "10px" }}>
                      <Typography sx={{ color: "#767676" }}>
                        Contact Number:
                      </Typography>
                      <Typography sx={{ fontWeight: 600 }}>
                        {primaryData?.phone}
                      </Typography>
                    </Box>{" "}
                    <Box sx={{ display: "flex", gap: "10px" }}>
                      <Typography sx={{ color: "#767676" }}>
                        Email Id:
                      </Typography>
                      <Typography sx={{ fontWeight: 600 }}>
                        {primaryData.emails}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <Box>
                    <Box
                      sx={{ display: "flex", marginLeft: "200px", gap: "10px" }}
                    >
                      <Typography
                        sx={{
                          width: "70px",
                          display: "grid",
                          placeItems: "center",
                        }}
                      >
                        Weak
                      </Typography>
                      <Typography
                        sx={{
                          width: "70px",
                          display: "grid",
                          placeItems: "center",
                        }}
                      >
                        Caution
                      </Typography>
                      <Typography
                        sx={{
                          width: "70px",
                          display: "grid",
                          placeItems: "center",
                        }}
                      >
                        Variable
                      </Typography>
                      <Typography
                        sx={{
                          width: "70px",
                          display: "grid",
                          placeItems: "center",
                        }}
                      >
                        Positive
                      </Typography>
                      <Typography
                        sx={{
                          width: "70px",
                          display: "grid",
                          placeItems: "center",
                        }}
                      >
                        Strong
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ minWidth: "200px", fontWeight: "700" }}>
                      Response Time
                    </Typography>
                    <Rating
                      value={statData.responseTime}
                      readOnly={!editMode}
                      sx={{ gap: "10px" }}
                      name="responseTime"
                      onChange={handleStatChange}
                      icon={
                        <Box
                          sx={{
                            width: "70px",
                            height: "30px",
                            backgroundColor: getStatColor(
                              statData.responseTime
                            ),
                          }}
                        />
                      }
                      emptyIcon={
                        <Box
                          sx={{
                            width: "70px",
                            height: "30px",
                            backgroundColor: "#D9D9D9",
                          }}
                        />
                      }
                    />
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ minWidth: "200px", fontWeight: "700" }}>
                      Components Quality
                    </Typography>
                    <Rating
                      value={statData.componentQuality}
                      readOnly={!editMode}
                      sx={{ gap: "10px" }}
                      name="componentQuality"
                      onChange={handleStatChange}
                      icon={
                        <Box
                          sx={{
                            width: "70px",
                            height: "30px",
                            backgroundColor: getStatColor(
                              statData.componentQuality
                            ),
                          }}
                        />
                      }
                      emptyIcon={
                        <Box
                          sx={{
                            width: "70px",
                            height: "30px",
                            backgroundColor: "#D9D9D9",
                          }}
                        />
                      }
                    />
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ minWidth: "200px", fontWeight: "700" }}>
                      Pricing
                    </Typography>
                    <Rating
                      value={statData.pricing}
                      readOnly={!editMode}
                      sx={{ gap: "10px" }}
                      name="pricing"
                      onChange={handleStatChange}
                      icon={
                        <Box
                          sx={{
                            width: "70px",
                            height: "30px",
                            backgroundColor: getStatColor(statData.pricing),
                          }}
                        />
                      }
                      emptyIcon={
                        <Box
                          sx={{
                            width: "70px",
                            height: "30px",
                            backgroundColor: "#D9D9D9",
                          }}
                        />
                      }
                    />
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ minWidth: "200px", fontWeight: "700" }}>
                      Lead Time
                    </Typography>
                    <Rating
                      value={statData.leadTime}
                      readOnly={!editMode}
                      sx={{ gap: "10px" }}
                      name="leadTime"
                      onChange={handleStatChange}
                      icon={
                        <Box
                          sx={{
                            width: "70px",
                            height: "30px",
                            backgroundColor: getStatColor(statData.leadTime),
                          }}
                        />
                      }
                      emptyIcon={
                        <Box
                          sx={{
                            width: "70px",
                            height: "30px",
                            backgroundColor: "#D9D9D9",
                          }}
                        />
                      }
                    />
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ minWidth: "200px", fontWeight: "700" }}>
                      Past Performance
                    </Typography>
                    <Rating
                      value={statData.pastPerformance}
                      readOnly={!editMode}
                      sx={{ gap: "10px" }}
                      name="pastPerformance"
                      onChange={handleStatChange}
                      icon={
                        <Box
                          sx={{
                            width: "70px",
                            height: "30px",
                            backgroundColor: getStatColor(
                              statData.pastPerformance
                            ),
                          }}
                        />
                      }
                      emptyIcon={
                        <Box
                          sx={{
                            width: "70px",
                            height: "30px",
                            backgroundColor: "#D9D9D9",
                          }}
                        />
                      }
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item md={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <Typography>Recommended:</Typography>
                  <Switch
                    checked={isRecommended}
                    onChange={(e) => setIsRecommended(e.target.checked)}
                    disabled={!editMode}
                  />
                </Box>
              </Grid>
              <Grid item md={12}>
                <Typography
                  sx={{
                    fontSize: "1.5em",
                    fontWeight: 600,
                    marginTop: "30px",
                  }}
                >
                  <DescriptionIcon
                    sx={{ marginBottom: "0.5rem", marginRight: "0.5rem" }}
                  />
                  Primary Information
                </Typography>
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Vendor Name"
                  fullWidth
                  variant="standard"
                  value={primaryData.vendorName}
                  onChange={handlePrimaryDataChange}
                  disabled={!editMode}
                  name="vendorName"
                  required
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Vendor Id"
                  fullWidth
                  variant="standard"
                  value={primaryData.vendorId}
                  onChange={handlePrimaryDataChange}
                  disabled={!editMode}
                  name="vendorId"
                  required
                />
              </Grid>
              <Grid item md={4}>
                <FormControl
                  fullWidth
                  variant="standard"
                  sx={{
                    "& .MuiInputBase-root": {
                      padding: "0px",
                    },
                  }}
                >
                  <InputLabel id="demo-multiple-chip-label">
                    Service Category *
                  </InputLabel>
                  <CustomSelect
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    value={primaryData.vendorService}
                    label="Service Category"
                    name="vendorService"
                    multiple
                    onChange={(e) => {
                      setPrimaryData({
                        ...primaryData,
                        vendorService: e.target.value,
                      });
                    }}
                    requried
                    disabled={!editMode}
                  >
                    <MenuItem value="">none</MenuItem>
                    {services.map((service) => (
                      <MenuItem value={service}>{service}</MenuItem>
                    ))}
                  </CustomSelect>
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Contact Person Name"
                  fullWidth
                  variant="standard"
                  value={primaryData.personName}
                  onChange={handlePrimaryDataChange}
                  disabled={!editMode}
                  name="personName"
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="E-mail ID"
                  fullWidth
                  variant="standard"
                  value={primaryData.emails}
                  onChange={handlePrimaryDataChange}
                  disabled={!editMode}
                  name="emails"
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Phone Number"
                  fullWidth
                  variant="standard"
                  value={primaryData.phone}
                  onChange={handlePrimaryDataChange}
                  disabled={!editMode}
                  name="phone"
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Location"
                  fullWidth
                  variant="standard"
                  value={primaryData.location}
                  onChange={handlePrimaryDataChange}
                  disabled={!editMode}
                  name="location"
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Website Link"
                  fullWidth
                  variant="standard"
                  value={primaryData.websiteLink}
                  onChange={handlePrimaryDataChange}
                  disabled={!editMode}
                  name="websiteLink"
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Whatsapp No"
                  fullWidth
                  variant="standard"
                  value={primaryData.whatsappNo}
                  onChange={handlePrimaryDataChange}
                  disabled={!editMode}
                  name="whatsappNo"
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Address"
                  fullWidth
                  variant="standard"
                  value={primaryData.address}
                  onChange={handlePrimaryDataChange}
                  disabled={!editMode}
                  name="address"
                />
              </Grid>{" "}
              <Grid item md={8}>
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <TextField
                    label="Remarks"
                    fullWidth
                    variant="standard"
                    value={currentRemark}
                    onChange={(e) => {
                      setCurrentRemark(e.target.value);
                    }}
                    disabled={!editMode}
                    name="remarks"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <CustomButton2
                            onClick={() => {
                              setPrimaryData({
                                ...primaryData,
                                remarks: [
                                  ...primaryData.remarks,
                                  currentRemark,
                                ],
                              });
                              setCurrentRemark("");
                            }}
                            style={{
                              padding: "0px",
                              fontSize: "0.8em",
                              marginBottom: "10px",
                            }}
                            fill
                            disabled={!editMode}
                          >
                            ADD
                          </CustomButton2>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {/* <CustomButton2
                      onClick={() => {
                        setPrimaryData({
                          ...primaryData,
                          remarks: [...primaryData.remarks, currentRemark],
                        });
                        setCurrentRemark("");
                      }}
                      fill
                    >
                      ADD
                    </CustomButton2> */}
                </Box>
              </Grid>
              <Grid item md={12}>
                <Typography
                  sx={{
                    fontSize: "1.5em",
                    fontWeight: 600,
                    marginTop: "50px",
                  }}
                >
                  <BusinessIcon
                    sx={{ marginBottom: "0.5rem", marginRight: "0.5rem" }}
                  />
                  Industry Information
                </Typography>
              </Grid>
              {/* <Grid item md={4}>
                <Typography sx={{ color: "#7d7d7d" }}>Rating</Typography>
                <Rating
                  value={industryData.rating}
                  onChange={(e) => {
                    setIndustryData({
                      ...industryData,
                      rating: e.target.value,
                    });
                  }}
                  readOnly={!editMode}
                />
              </Grid> */}
              <Grid item md={4}>
                <TextField
                  label="Response Time"
                  fullWidth
                  variant="standard"
                  value={industryData.responseTime}
                  onChange={handleIndustryDataChange}
                  disabled={!editMode}
                  name="responseTime"
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Order Of Services"
                  fullWidth
                  variant="standard"
                  value={industryData.orderOfServices}
                  onChange={handleIndustryDataChange}
                  disabled={!editMode}
                  name="orderOfServices"
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Time For Quotation"
                  fullWidth
                  variant="standard"
                  value={industryData.timeForQuotation}
                  onChange={handleIndustryDataChange}
                  disabled={!editMode}
                  name="timeForQuotation"
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Payment Terms"
                  fullWidth
                  variant="standard"
                  value={industryData.paymentTerms}
                  onChange={handleIndustryDataChange}
                  disabled={!editMode}
                  name="paymentTerms"
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Mode Of Payment"
                  fullWidth
                  variant="standard"
                  value={industryData.modeOfPayment}
                  onChange={handleIndustryDataChange}
                  disabled={!editMode}
                  name="modeOfPayment"
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="CIN Number"
                  fullWidth
                  variant="standard"
                  value={industryData.cinNumber}
                  onChange={handleIndustryDataChange}
                  disabled={!editMode}
                  name="cinNumber"
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="PAN Number"
                  fullWidth
                  variant="standard"
                  value={industryData.panNumber}
                  onChange={handleIndustryDataChange}
                  disabled={!editMode}
                  name="panNumber"
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="GST Number"
                  fullWidth
                  variant="standard"
                  value={industryData.gstNumber}
                  onChange={handleIndustryDataChange}
                  disabled={!editMode}
                  name="gstNumber"
                />
              </Grid>
              <Grid item md={12}>
                <Typography
                  sx={{
                    fontSize: "1.5em",
                    fontWeight: 600,
                    marginTop: "50px",
                  }}
                >
                  <AccountBalanceIcon
                    sx={{ marginBottom: "0.5rem", marginRight: "0.5rem" }}
                  />
                  Bank Details
                </Typography>
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Bank Name"
                  fullWidth
                  variant="standard"
                  value={bankData.bankName}
                  onChange={handleBankDataChange}
                  disabled={!editMode}
                  name="bankName"
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Account Name"
                  fullWidth
                  variant="standard"
                  value={bankData.accountName}
                  onChange={handleBankDataChange}
                  disabled={!editMode}
                  name="accountName"
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Account No"
                  fullWidth
                  variant="standard"
                  value={bankData.accountNumber}
                  onChange={handleBankDataChange}
                  disabled={!editMode}
                  name="accountNumber"
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="IFSC Code"
                  fullWidth
                  variant="standard"
                  value={bankData.ifsc}
                  onChange={handleBankDataChange}
                  disabled={!editMode}
                  name="ifsc"
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Branch"
                  fullWidth
                  variant="standard"
                  value={bankData.branch}
                  onChange={handleBankDataChange}
                  disabled={!editMode}
                  name="branch"
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Mobil Number(Related to bank account)"
                  fullWidth
                  variant="standard"
                  value={bankData.mobileNumber}
                  onChange={handleBankDataChange}
                  disabled={!editMode}
                  name="mobileNumber"
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                gap: "30px",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: "1.5em",
                    fontWeight: 600,
                    marginTop: "50px",
                  }}
                >
                  Photos
                </Typography>
                <Box sx={{ width: "300px" }}>
                  <Slider {...settings}>
                    {vendor?.images &&
                      vendor.images.map((i) => (
                        <Box
                          sx={{
                            width: "100%",
                            height: "200px",
                            backgroundColor: "#a4a4a4",
                            backgroundImage: `url('${i.location}')`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            borderRadius: "10px",
                          }}
                        />
                      ))}
                  </Slider>
                </Box>
                {!editMode ? null : (
                  <Button
                    sx={{
                      width: "100%",
                      height: "fit-content",
                      padding: "5px",
                      display: "grid",
                      placeItems: "center",
                      color: "#000",
                      backgroundColor: "rgba(47, 47, 47, 0.1) !important",
                      borderRadius: "5px",
                      margin: "25px 0px !important",
                      cursor: "pointer",
                      padding: "5px 10px !important",
                    }}
                    onClick={toggleImageModal}
                    disabled={!editMode}
                  >
                    EDIT IMAGES
                  </Button>
                )}

                <VendorImageEditModal
                  state={showImageModal}
                  toggleModal={toggleImageModal}
                  images={vendor?.images}
                  vendorId={vendor?._id}
                />

                {/* <Box
                    sx={{
                      backgroundColor: 'rgba(47, 47, 47, 0.1)',
                      padding: '30px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: '10px',
                      borderRadius: '5px',
                      textAlign: 'center',
                      border: isDragActive ? '2px solid red' : '',
                    }}
                    {...getRootProps({ className: 'dropzone' })}
                  >
                    <input {...getInputProps()} />
                    <UploadIcon width="60px" height="60px" />
                    <Typography>
                      Drag and drop to upload <br />
                      pictures
                    </Typography>
                    <Button
                      sx={{
                        backgroundColor: 'white !important',
                        borderRadius: '5px !important',
                        fontSize: '0.8em !important',
                        color: '#000 !important',
                        padding: '5px 10px !important',
                      }}
                    >
                      Browse Computer
                    </Button>
                  </Box> */}

                <Box
                  sx={{
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  {images.length > 0 &&
                    images.map((file) => (
                      <Box
                        sx={{
                          width: "100%",
                          border: "1px solid #a4a4a4",
                          borderRadius: "5px",
                          padding: "5px 10px",
                        }}
                      >
                        {file.name}
                      </Box>
                    ))}
                </Box>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: "1.5em",
                    fontWeight: 600,
                    marginTop: "30px",
                  }}
                >
                  List of Documents
                </Typography>

                <Box
                  sx={{
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  {vendor?.documents?.length > 0 &&
                    vendor?.documents.map((file) => (
                      <DocumentsAccordian
                        handleFileDelete={handleFileDelete}
                        file={file}
                        vendorId={vendor._id}
                      />
                    ))}
                </Box>

                <Button
                  sx={{
                    width: "100%",
                    height: "fit-content",
                    padding: "5px",
                    display: "grid",
                    placeItems: "center",
                    color: "#000",
                    backgroundColor: "rgba(47, 47, 47, 0.1) !important",
                    borderRadius: "5px",
                    margin: "10px 0px !important",
                    cursor: "pointer",
                    padding: "5px 10px !important",
                  }}
                  onClick={toggleDocModal}
                  disabled={!editMode}
                >
                  Upload new file
                </Button>

                <VendorDocModal
                  state={showDocModal}
                  toggleModal={toggleDocModal}
                  setFiles={setDocs}
                />
                <Box
                  sx={{
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  {docs.length > 0 &&
                    docs.map((file) => (
                      <Box
                        sx={{
                          width: "100%",
                          border: "1px solid #a4a4a4",
                          borderRadius: "5px",
                          padding: "5px 10px",
                        }}
                      >
                        {file.name}
                      </Box>
                    ))}
                </Box>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: "1.5em",
                    fontWeight: 600,
                    marginTop: "50px",
                  }}
                >
                  Supported Documents
                </Typography>

                <Box
                  sx={{
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  {vendor?.supportedDocuments?.length > 0 &&
                    vendor?.supportedDocuments.map((file) => (
                      <SupportedDocumentsAccordian
                        handleFileDelete={handleSupportedFileDelete}
                        file={file}
                        vendorId={vendor._id}
                      />
                    ))}
                </Box>
                <Button
                  sx={{
                    width: "100%",
                    height: "fit-content",
                    padding: "5px",
                    display: "grid",
                    placeItems: "center",
                    color: "#000",
                    backgroundColor: "rgba(47, 47, 47, 0.1) !important",
                    borderRadius: "5px",
                    margin: "10px 0px !important",
                    cursor: "pointer",
                    padding: "5px 10px !important",
                  }}
                  onClick={toggleSupportedModal}
                  disabled={!editMode}
                >
                  Upload new file
                </Button>
                <VendorDocModal
                  state={showSupportedModal}
                  toggleModal={toggleSupportedModal}
                  setFiles={setSpportedDocs}
                />
                <Box
                  sx={{
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  {supportedDocs.length > 0 &&
                    supportedDocs.map((file) => (
                      <Box
                        sx={{
                          width: "100%",
                          border: "1px solid #a4a4a4",
                          borderRadius: "5px",
                          padding: "5px 10px",
                        }}
                      >
                        {file.name}
                      </Box>
                    ))}
                </Box>
              </Box>
              <textarea
                value={adminComment}
                rows="10"
                cols="40"
                disabled={!editMode}
                onChange={(e) => {
                  setAdminComment(e.target.value);
                }}
              ></textarea>
            </Box>
          </Box>
        </VendorInfoModalContainer>
      </Slide>
    </Modal>
  );
};

export default VendorInfoModal;

// ndaSigned: {
//   type: Boolean,
// },

// previousClients: [{ type: String }],
// bankInfo: {
//   bankName: String,
//   accountName: String,
//   accountNo: String,
//   ifsc: String,
//   Branch: String,
//   mobileNo: String,
// },
// indusryInfo: {
//   responseTime: String,
//   orderOfServices: [{ type: String }],
//   timeForQuotation: String,
//   paymentTerms: String,
//   modeOfPayment: String,
//   cinNumber: String,
//   panNumber: String,
//   gstNumber: String,
//   reviews: [{ type: String }],
// },
