import React, { useState, useEffect } from "react";

//components
import CustomBreadcrumbs from "../../Components/CustomBreadcrumbs";
import DashboardHeader from "../../ClientEngagement/DashboardHeader";
import CustomFileDropper from "../../Components/CustomFileDropper";
import CustomColorPicker from "../../Components/CustomColorPicker";

//utils
import { axiosInstance } from "../../utils/axiosInterceptor";
import {
  Box,
  createTheme,
  ThemeProvider,
  Grid,
  Tooltip,
  Typography,
  Slider,
  Button,
} from "@mui/material";
import { MenuItem } from "@material-ui/core";
import { injectionMoldingData } from "./createProject.data";
// import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";

//styles
import {
  PageContainer,
  CreateProjectContainer,
  CreateProjectFormContainer,
  CusotmGridItem,
  CustomInput,
  CustomSelect,
  CustomButton,
} from "./CreateNewProject.styles";

//assets

const dropzoneTheme = createTheme({
  overrides: {
    root: {
      backgroundColor: "transparent",
    },
  },
});

const CreateInjectionMoldingProject = ({ isAdmin }) => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [cadFile, setCadFile] = useState(null);
  const [createLoading, setCreateLoading] = useState(false);
  const [projectData, setProjectData] = useState({
    quantity: 0,
    projectName: "",
    resinMaterial: "",
    colour: "",
    mold: "",
    otherSpecification: "",
  });
  const [errors, setErrors] = useState({
    quantityError: false,
  });
  const [userEmail, setUserEmail] = useState("");

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const fetchProfile = () => {
    setLoading(true);
    axiosInstance
      .get("/users/getProfile")
      .then((resp) => {
        setProfile(resp.data);
        console.log(resp);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleChange = (e) => {
    setProjectData({
      ...projectData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    //quantity check
    if (isNaN(Number(projectData.quantity)) || Number(projectData.quantity)==0) {
      setErrors({
        ...errors,
        quantityError: true,
      });
      enqueueSnackbar("Quantity should be a valid number and greater than 0", {
        variant: "error",
      });
      return;
    }

    if (cadFile === null || projectData.colour == "") {
      if (cadFile === null) {
        enqueueSnackbar("Please upload a cad file.", { variant: "info" });
        return;
      }
      if (projectData.colour == "") {
        enqueueSnackbar("Please select a colour", { variant: "info" });
        return;
      }
    }
    let formData = new FormData();
    formData.append("cad", cadFile);
    // formData.append("printingService", projectData.printingService);
    for (let i in projectData) {
      formData.append(i, projectData[i]);
    }

    for (let pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    setCreateLoading(true);
    const token = localStorage.getItem("token");
    try {
      let url;
      if (isAdmin) {
        url = "/3d/admin/createProject/injectionMolding";
        formData.append("userEmail", userEmail);
      } else {
        url = "/3d/client/createProject/injectionMolding";
      }
      const res = await axiosInstance.post(url, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "multipart/form-data",
        },
      });
      console.log(res);
      enqueueSnackbar("Project Created Successfully!", {
        variant: "success",
      });
      setCreateLoading(false);
      if (isAdmin) {
        history.push(
          `/admin/3d/timeline/injectionMolding/${res.data.project._id}`
        );
      } else {
        history.push(`/3dcolosseum/injectionMolding/${res.data.project._id}`);
      }
    } catch (err) {
      console.log(e);
      enqueueSnackbar("some error occurred", { variant: "error" });
    }
  };

    useEffect(() => {
        if(localStorage.PM && history.location.pathname.includes("/admin")) history.push("/pm/3d/newProject/injectionMolding");
    }, []);
  useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <Box>
      {!loading ? (
        <PageContainer>
          <DashboardHeader profile_data={profile.data} isBlack={true} />
          <CreateProjectContainer>
            <CustomBreadcrumbs
              data={[
                {
                  name: "Home",
                  link: "/",
                },
                {
                  name: "EB 3D Colosseum",
                  link: "/admin/3d",
                },
                {
                  name: "Create new project",
                  link: "/admin/3d/services",
                },
                {
                  name: "Injection Molding",
                  link: history.location.pathname,
                },
              ]}
            />
            <div className="title">
              Injection Molding - Create a new Project
            </div>
            <CreateProjectFormContainer>
              <div className="dropzone-container">
                <CustomFileDropper setFile={setCadFile} single />
              </div>
              <form onSubmit={handleFormSubmit}>
                <Grid container spacing={5}>
                  <CusotmGridItem item md={6}>
                    <div className="grid-item-inner">
                      <label htmlFor="project-name">Project Name*</label>
                      <CustomInput
                        type="text"
                        id="project-name"
                        name="projectName"
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </CusotmGridItem>
                  <CusotmGridItem item md={6} shift>
                  </CusotmGridItem>
                  <CusotmGridItem item md={6} >
                    <div className="grid-item-inner">
                      <label htmlFor="quantity">Quantity*</label>
                      <CustomInput
                        type="text"
                        id="quantity"
                        name="quantity"
                        required
                        onChange={handleChange}
                        error={errors.quantityError}
                      />
                    </div>
                  </CusotmGridItem>
                  <CusotmGridItem item md={6} shift>
                    <div className="grid-item-inner">
                      <label htmlFor="project-name">Mold*</label>

                      <CustomSelect
                        variant="outlined"
                        id="select-pservice"
                        value={projectData.mold}
                        onChange={handleChange}
                        required
                        name="mold"
                      >
                        {injectionMoldingData.mold.map((m) => (
                          <MenuItem value={m}>{m}</MenuItem>
                        ))}
                      </CustomSelect>
                    </div>
                  </CusotmGridItem>{" "}
                  <CusotmGridItem item md={6}>
                    <div className="grid-item-inner">
                      <label htmlFor="resinMaterial">Resin Material*</label>
                      <CustomSelect
                        variant="outlined"
                        id="resinMaterial"
                        required
                        name="resinMaterial"
                        onChange={handleChange}
                      >
                        {injectionMoldingData.resinMaterial.map((material) => (
                          <MenuItem value={material}>{material}</MenuItem>
                        ))}
                      </CustomSelect>
                    </div>
                  </CusotmGridItem>
                  <CusotmGridItem item md={6} shift>
                    <div className="grid-item-inner">
                      <label htmlFor="project-name">
                        Colour of Resin Material*
                      </label>
                      <CustomColorPicker
                        colors={injectionMoldingData.colors}
                        onChange={(c) => {
                          setProjectData({ ...projectData, colour: c });
                        }}
                      />
                    </div>
                  </CusotmGridItem>
                  {isAdmin ? (
                    <CusotmGridItem item md={6}>
                      <div className="grid-item-inner">
                        <label htmlFor="user-email">User Email*</label>
                        <CustomInput
                          type="text"
                          id="user-email"
                          name="userEmail"
                          onChange={(e) => {
                            setUserEmail(e.target.value);
                          }}
                          required
                          value={userEmail}
                        />
                      </div>
                    </CusotmGridItem>
                  ) : null}
                  <CusotmGridItem item md={12} isLast>
                    <div className="grid-item-inner">
                      <label htmlFor="otherSpecification">
                        Anyother Specification
                      </label>
                      <CustomInput
                        type="text"
                        id="otherSpecification"
                        name="otherSpecification"
                        onChange={handleChange}
                        multiline
                        rows={4}
                        placeholder="Additional Service may include in - fill, support, surface finishing, polishing, drilling and treading, tapping, beading and blasting etc"
                      />
                    </div>
                  </CusotmGridItem>
                </Grid>
                <CustomButton
                  variant="contained"
                  type="submit"
                  loading={createLoading}
                  loadingPosition="end"
                >
                  Submit
                </CustomButton>
              </form>
            </CreateProjectFormContainer>
          </CreateProjectContainer>
        </PageContainer>
      ) : null}
    </Box>
  );
};

export default CreateInjectionMoldingProject;
