import React from "react";
import { Button, styled } from "@mui/material";

const CustomButtonContainer = styled(Button)(({ isDisabled,gray }) => ({
  width: "fit-content",
  height: "fit-content",
  backgroundColor: isDisabled ? "#EEEEEE" : "#FF972A",
  padding: "5px 15px",
  color: "#000",
  fontSize: 14,
  marginRight: 20,
  "&:hover": {
    backgroundColor: isDisabled ? "#EEEEEE" : "#FF972A",
    color: "#000",
  },
}));

const CustomButton = ({ children, isDisabled, ...props }) => {
  return (
    <CustomButtonContainer
      {...props}
      disabled={isDisabled}
      isDisabled={isDisabled}
    >
      {children}
    </CustomButtonContainer>
  );
};

export default CustomButton;
