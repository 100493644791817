import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Button,
  Modal,
  styled,
  TextField,
  Typography,
  Slide,
  useTheme,
} from '@mui/material';
import { ReactComponent as AlertSvg } from '../assests/alert.svg';
import * as EmailValidator from 'email-validator';
import { useSnackbar } from 'notistack';
import { axiosInstance } from '../utils/axiosInterceptor';
import { ReactComponent as CollaboratorIcon } from '../assests/collaborators.svg';
import { useCallback } from 'react';
import Dropdown from './DropDown';
const CollaboratorModalContainer = styled(Box)(({ theme }) => ({
  width: '50vw',
  height: '60vh',
  position: 'absolute',
  top: '20vh',
  left: '25vw',
  // transform: "translate(-50%, -50%)",
  backgroundColor: '#fff',
  outline: 'none',
  borderRadius: 10,
  padding: '30px 40px 20px 40px',
  display: 'flex',
  flexDirection: 'column',
  //   justifyContent: "space-between",
  //   '& .alert-svg':{
  //     '& path':{
  //         fill:'#c73737'
  //     }
  //   },

  '& .header-text': {
    color: '#000',
    fontWeight: 600,
    fontFamily: 'Poppins',
    fontSize: '1.3em',
  },
  '& .add-btn': {
    borderRadius: 5,
    backgroundColor: '#0275FC',
    '&:hover': {
      backgroundColor: '#0275FC',
    },
  },
  '& .remove-btn': {
    fontSize: '0.7em',
    letterSpacing: '2px',
    color: '#0275FC',
    borderColor: '#0275FC',
    padding: '5px 30px',
  },
  '& legend': {
    width: 'unset',
  },
  [theme.breakpoints.down('md')]: {
    width: '50vw',
    left: '25vw',
  },
  [theme.breakpoints.down('sm')]: {
    width: '90vw',
    left: '5vw',
  },
}));

const CollaboratorModal = ({
  state,
  toggleModal,
  service,
  projectId,
  collaborators,
  type,
}) => {
  const [userEmail, setUserEmail] = useState('');
  const [suggestEmails, setSuggestEmails] = useState([]);
  const [projectCollaborators, setProjectCollaborators] = useState([]);

  useEffect(() => {
    setProjectCollaborators(collaborators);
  }, []);
  const theme = useTheme();

  const { enqueueSnackbar } = useSnackbar();
  const handleAddCollaborator = async () => {
    if (EmailValidator.validate(userEmail)) {
      console.log(userEmail, service, projectId, 'hereee');
      let url;
      if (service === 'fab') {
        url = `/fab/client/addCollaborator/${projectId}`;
      } else if (service === 'bom') {
        url = `/bom/client/addCollaborator/${projectId}`;
      } else if (service === '3d') {
        url = `/3d/client/addCollaborator/${type}/${projectId}`;
      }
      const res = await axiosInstance.post(url, { userEmail });
      console.log(res);
      if (!res.data.ok) {
        enqueueSnackbar(res.data.message, { variant: 'error' });
      } else {
        enqueueSnackbar(res.data.message, { variant: 'success' });
        setProjectCollaborators([...projectCollaborators, res.data.user]);
      }
    } else {
      enqueueSnackbar('Please enter a valid email', { variant: 'error' });
    }
    setUserEmail('');
  };

  const handleRemoveCollaborator = async (userId) => {
    let url;
    if (service === 'fab') {
      url = `/fab/client/removeCollaborator/${projectId}`;
    } else if (service === 'bom') {
      url = `/bom/client/removeCollaborator/${projectId}`;
    } else if (service === '3d') {
      url = `/3d/client/removeCollaborator/${type}/${projectId}`;
    }
    const res = await axiosInstance.post(url, { userId });

    console.log(res);
    if (!res.data.ok) {
      enqueueSnackbar(res.data.message, { variant: 'error' });
    } else {
      enqueueSnackbar(res.data.message, { variant: 'success' });
      const newCollabs = projectCollaborators.filter((a) => a._id !== userId);
      setProjectCollaborators(newCollabs);
    }
  };
  const debounce = useCallback((func, wait) => {
    let timer;
    return (...args) => {
      console.log(wait);
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), wait);
    };
  }, []);
  const getColabEmails = useCallback(
    debounce(async (query) => {
      if (query == '') {
        setSuggestEmails([]);
        return;
      }
      try {
        const body = {
          query,
        };
        const res = await axiosInstance.post('/fab/admin/collabQuery', body);
        const emails = [];
        res?.data?.data?.map((em) => {
          emails.push(em);
        });
        setSuggestEmails(emails);
      } catch (error) {}
    }, 500),
    []
  );

  const onChangeCollabHandler = (e) => {
    setUserEmail(e.target.value);
    getColabEmails(e.target.value);
  };

  return (
    <Modal open={state} onClose={toggleModal}>
      <Slide in={state} direction="up">
        <CollaboratorModalContainer
          onClick={() => {
            if (suggestEmails.length > 0) {
              setSuggestEmails([]);
            }
          }}
        >
          <Box
            className="header-text"
            sx={{
              '& svg': { transform: 'scale(0.8)' },
              '& path': { fill: '#000' },
            }}
          >
            <CollaboratorIcon /> Manage Project Collaborators
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: '20px',
              marginTop: '30px',
              marginBottom: '20px',
              justifyContent: 'space-between',
              [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
              },
            }}
          >
            <TextField
              placeholder="Email id of collaborator"
              variant="outlined"
              value={userEmail}
              onChange={onChangeCollabHandler}
              sx={{ '& input': { padding: '7px 10px' }, flex: 1 }}
            />
            <Button
              variant="contained"
              className="add-btn"
              onClick={handleAddCollaborator}
              sx={{
                backgroundColor: '#FF972A !important',
                fontFamily: 'Poppins !important',
                textTransform: 'none !important',
                boxShadow: 'none !important',
              }}
            >
              Add Collaborator
            </Button>
          </Box>
          <Dropdown
            setSuggestEmails={setSuggestEmails}
            setUserEmail={setUserEmail}
            data={suggestEmails}
          />
          <Box sx={{ flex: 1, overflowY: 'auto' }}>
            {projectCollaborators.length > 0 ? (
              projectCollaborators
                .slice()
                .reverse()
                .map((user) => (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '10px 5px',
                      margin: '15px 0px',
                      alignItems: 'center',
                      borderBottom: '1px solid #8B8B8B4D',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '20px',
                        alignItems: 'center',
                      }}
                    >
                      <Avatar src={user?.profilePic}>
                        {user.firstName && user.firstName[0]}
                      </Avatar>
                      <div>
                        <Typography sx={{ fontSize: '1.1em', fontWeight: 500 }}>
                          {`${user?.firstName || ''} ${user?.lastName || ''}`}
                        </Typography>
                        <Typography sx={{ color: '#A3A3A3' }}>
                          {user?.email}
                        </Typography>
                      </div>
                    </Box>
                    <Button
                      variant="outlined"
                      className="remove-btn"
                      onClick={() => {
                        handleRemoveCollaborator(user?._id);
                      }}
                      sx={{
                        color: '#8B8B8B !important',
                        border: 'none !important',
                      }}
                    >
                      REMOVE
                    </Button>
                  </Box>
                ))
            ) : (
              <Typography
                sx={{
                  color: '#A5A5A5',
                  fontWeight: 500,
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                No Collaborators added yet 😐{' '}
              </Typography>
            )}
          </Box>
        </CollaboratorModalContainer>
      </Slide>
    </Modal>
  );
};

export default CollaboratorModal;
