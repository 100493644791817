import { axiosInstance } from "../utils/axiosInterceptor";

export const onBoardingAction = async (formData, enqueueSnackbar, props) => {
  const body = JSON.stringify(formData);
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axiosInstance.post("/supplier/onBoarding", body, config);
    enqueueSnackbar(`${res.data.message}`, { variant: "success" });
    console.log(res.data);
    props.history.push("/");
  } catch (err) {
    enqueueSnackbar(`Some error occurred`, { variant: "error" });
    console.log(err);
  }
};
