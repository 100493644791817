import * as React from "react";
import DashboardHeader from "../ClientEngagement/DashboardHeader";
import { Box, LinearProgress, styled, useTheme } from "@mui/material";
import { axiosInstance } from "../utils/axiosInterceptor";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@mui/material/Button";
import DashboardLogo1 from "../assests/elecbitsLogo.svg";
import moment from "moment";
import LoadingButton from "@mui/lab/LoadingButton";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineComponent from "./TimelineComponent";
import "../BOMProcurement/explorer.css";
import { Paper, Modal } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import { DropzoneArea } from "material-ui-dropzone";
import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import LockIcon from "@material-ui/icons/Lock";
import { SnackbarProvider, useSnackbar } from "notistack";
import { useWindowSize } from "../Hooks/useWindowSize";
import DashboardHeaderAdmin from "../ClientEngagement/AdminDashboardHeader";
import CustomBreadcrumbs from "../Components/CustomBreadcrumbs";
import CollaborationModal from "../Components/CollaboratorModal";
import ViewCollaborators from "../Components/ViewCollaborators";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

const MainBoxContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  flexDirection: "column",
  margin: "2rem",
  marginLeft: "8rem",
  fontSize: "1.2rem",
  [theme.breakpoints.down("lg")]: {
    margin: "1.5rem",
    marginLeft: "5rem",
  },
  [theme.breakpoints.down("md")]: {
    margin: "1rem",
    marginLeft: "3rem",
  },
  [theme.breakpoints.down("sm")]: {
    margin: "1rem",
    marginLeft: "2rem",
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  backgroundColor: "#fff",
  boxShadow: 24,
  p: 4,
  borderRadius: "3px",
};

const BomExplorerTimeLine = (props) => {
  const [profile_data, set_profile] = React.useState(null);
  const [loading, set_loading] = React.useState(null);
  const [showCollabModal, setShowCollabModal] = React.useState(false);
  const [showViewCollabModal, setShowViewCollabModal] = React.useState(false);
  const [progress, setProgress] = React.useState({
    loading: false,
    value: 0,
  });
  const [project, setProject] = React.useState(null);
  const [newProjectModalOpen, setNewProjectModalOpen] = React.useState(false);
  const openNewProjectModal = () => setNewProjectModalOpen(true);
  const closeNewProjectModal = () => setNewProjectModalOpen(false);
  const [files, setFiles] = React.useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { breakpoints } = useTheme();
  const { width } = useWindowSize();
  const [internalNameState, setInternalNameState] = React.useState({
    editMode: false,
    name: "Internal Name(default)",
  });

  const handleSaveInternalName = async () => {
    const id = window.location.pathname.split("/")[4];
    console.log(id, "fhwewerui");
    try {
      const res = await axiosInstance.put(`/bom/admin/editProject/${id}`, {
        internalName: internalNameState.name,
      });
      console.log(res);
      if (res.status === 200) {
        enqueueSnackbar("Project Updated Successfully!", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Something went wrong", { variant: "error" });
      }
    } catch (err) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
    setInternalNameState({
      ...internalNameState,
      editMode: false,
    });
  };

  const toggleCollabModal = () => {
    setShowCollabModal(!showCollabModal);
  };
  const toggleViewCollabModal = () => {
    setShowViewCollabModal(!showViewCollabModal);
  };

  const stages = [
    {
      stage: "Under Review",
      desc: "Kudos to you, you’ve found the best place to get the components that you need to turn your imaginary project into a working reality. For this stage, we take the task of scrutinizing each component in the given BOM for cheaper and better alternative components which might also help in acquiring needed certifications for various regions.",
    },
    {
      stage: "Quotation Optimization",
      desc: "Once the BOM is finalized, it’s time to ring the bell for vendors supplying the components, at this moment, 100s of vendors for each component are bidding their lowest quotations to be a part of your project.",
    },
    {
      stage: "Quotation Uploaded",
      desc: "Time for vendors to present their lowest bid is over, we would now present you with various quotation combinations arranged according to the delivery timeline, this will help you decide the group of vendors to source from according to the timeline of your preference.",
    },
    {
      stage: "Order Review",
      desc: "We are going through your order, carefully examining each component and vendor that has been selected before placing an order with the vendors",
    },
    {
      stage: "Order Accepted",
      desc: "Congratulations, your order is now officially active. The vendors have confirmed receiving the order.",
    },
    {
      stage: "Shipment Packed",
      desc: "At this moment, the vendor has confirmed that the components are packed suitably and the package is awaiting pickup by our delivery partner.",
    },
    {
      stage: "Waiting For Pickup",
      desc: "The package is ready to ship and will be picked up by the transit partner soon.",
    },
    {
      stage: "In Transit",
      desc: "The package has been picked up by our delivery partner and is on its way.",
    },
    {
      stage: "Out For Delivery",
      desc: "The delivery partner is out for the delivery of the package. It shall be at its destination soon.",
    },
    {
      stage: "Delivered",
      desc: "Pleased to inform you that the components have reached their destination. If it’s delivered to you, please proceed to inspect the package and its contents as suggested on our website/platform and report any issues with them. If it’s delivered to us, then it’s our job to inspect and rectify any issues with order mismatch or any other issue with the order.",
    },
  ];
  const submitHandler = async () => {
    if (files.length > 0) {
      let formData = new FormData();
      formData.append("bom", files[0]);
      const token = localStorage.getItem("token");
      try {
        const id = props.isAdmin
          ? window.location.pathname.split("/")[4]
          : window.location.pathname.split("/")[2];
        console.log(id);
        setProgress({
          value: 0,
          loading: true,
        });
        const res = await axiosInstance.post(
          `/bom/client/reuploadBom/${id}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
              var percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              console.log(percentCompleted, "this is the current percentage");
              setProgress({
                loading: true,
                value: percentCompleted,
              });
            },
          }
        );

        console.log(res);
        enqueueSnackbar("BOM Updated successfully Successfully!", {
          variant: "success",
        });
        setProgress({
          value: 100,
          loading: false,
        });
        closeNewProjectModal();
        window.location.reload();
      } catch (e) {
        console.log(e);
        enqueueSnackbar("some error occurred", { variant: "error" });
      }
    } else {
      enqueueSnackbar("Please select atleast one file!", { variant: "error" });
    }
  };

  let fetchProfile = () => {
    set_loading(true);
    axiosInstance
      .get("/users/getProfile")
      .then((resp) => {
        set_profile(resp.data);
        set_loading(false);
      })
      .catch((err) => {
        set_loading(false);
      });
  };
  const singleProject = async () => {
    const id = props.isAdmin
      ? window.location.pathname.split("/")[4]
      : window.location.pathname.split("/")[2];
    console.log(id);
    try {
      const res = await axiosInstance.get(`/bom/client/singleProject/${id}`);
      setProject(res.data);
      setInternalNameState({
        ...internalNameState,
        name:res.data?.internalName || "default internal name"
      })
      console.log(res);
    } catch (e) {
      console.log(e);
    }
  };
  const [count, setCount] = React.useState(0);
  React.useEffect(() => {
    fetchProfile();
    singleProject();
  }, []);
  React.useEffect(() => {
    singleProject();
  }, [count]);
  return (
    <Box>
      {!loading && profile_data && project ? (
        <>
          {profile_data.data.admin ? (
            <DashboardHeaderAdmin
              profile_data={profile_data.data}
              toggleLoggedIn={props.toggleLoggedIn}
              history={props.history}
              isBlack={true}
            ></DashboardHeaderAdmin>
          ) : (
            <DashboardHeader
              profile_data={profile_data.data}
              toggleLoggedIn={props.toggleLoggedIn}
              history={props.history}
              isBlack={true}
            ></DashboardHeader>
          )}
          <MainBoxContainer>
            <Box
              sx={{
                fontSize: "2rem",
                fontWeight: "bold",
                marginBottom: "1rem",
              }}
            >
              {props.isAdmin ? (
                <CustomBreadcrumbs
                  data={[
                    {
                      name: "Home",
                      link: "/",
                    },
                    {
                      name: "EB BOM Explorer",
                      link: "/admin/bomExplorer",
                    },
                    {
                      name: `${project.projectName}-Project Detail`,
                      link: `/admin/bomExplorerDetail/${project._id}`,
                    },
                    {
                      name: "Project Timeline",
                      link: `/admin/bomExplorer/timeline/${project._id}`,
                    },
                  ]}
                />
              ) : (
                <CustomBreadcrumbs
                  data={[
                    {
                      name: "Home",
                      link: "/",
                    },
                    {
                      name: "EB BOM Explorer",
                      link: "/bomExplorer",
                    },
                    {
                      name: "My Projects",
                      link: "bomExplorer/project",
                    },
                    {
                      name: "View Details",
                      link: "bomExplorer/project",
                    },
                  ]}
                />
              )}

              {props.isAdmin
                ? "Project Timeline"
                : "Elecbits BOM Explorer - Project Details"}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "82%",
                [breakpoints.down("md")]: {
                  flexDirection: "column",
                  alignItems: "start",
                },
              }}
            >
              <Box sx={{ fontSize: "1.4rem", fontWeight: "bold" }}>
                {project.projectName} - {project.companyName}
              </Box>
              <Box>Project Id: {project.projectId}</Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "82%",
              }}
            >
              Number of Boards: {project.numberOfBoards}
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "82%",
                margin: "1rem 0rem",
                [breakpoints.down("md")]: {
                  flexDirection: "column",
                  alignItems: "start",
                  gap: "10px",
                },
              }}
            >
              <Box>
                Project Created on:{" "}
                {moment(project?.startDate).format("MMMM Do YYYY, h:mm a")}
              </Box>
              <Box>
                {props.isAdmin ? (
                  <a
                    style={{ color: "#303f9f" }}
                    href={project.bomSheet.location}
                    download
                  >
                    <Button variant="contained"> Download Bom</Button>
                  </a>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      [breakpoints.down("md")]: {
                        flexDirection: "column",
                        alignItems: "start",
                        gap: "10px",
                      },
                    }}
                  >
                    {" "}
                    <Box sx={{ marginRight: "1rem" }}>
                      <a href={project.bomSheet.location} download>
                        <Button variant="outlined">Download Bom</Button>{" "}
                      </a>
                    </Box>
                    <Button variant="outlined" onClick={openNewProjectModal}>
                      Reupload Bom
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
            {!props.isAdmin ? (
              <Box
                sx={{
                  margin: "2rem 0rem 2rem 0rem",
                  fontSize: "1.2rem",
                  display: "flex",
                  gap: "20px",
                }}
              >
                {" "}
                <Button
                  onClick={() =>
                    props.history.push(
                      `/bomExplorer/project/quotations/${project._id}`
                    )
                  }
                  variant="contained"
                  disabled={project.currentStatus.length > 2 ? false : true}
                >
                  View Quotation
                </Button>
                {project.projectOwner === profile_data.data._id ? (
                  <Button onClick={toggleCollabModal} variant="outlined">
                    MANAGE COLLABORATORS
                  </Button>
                ) : (
                  <Button onClick={toggleViewCollabModal} variant="outlined">
                    VIEW COLLABORATORS
                  </Button>
                )}
              </Box>
            ) : (
              <Box
                sx={{
                  margin: "2rem 0rem 2rem 0rem",
                  fontSize: "1.2rem",
                  // display: "flex",
                  // flexDirection:"column",
                  // gap: "20px",
                }}
              >
                <Button onClick={toggleCollabModal} variant="outlined">
                  MANAGE COLLABORATORS
                </Button>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",

                      "& .MuiInput-input.Mui-disabled": {
                        color: "#000",
                        textFillColor: "#000",
                      },
                    }}
                  >
                    Internal Name:
                    <TextField
                      disabled={!internalNameState.editMode}
                      variant="standard"
                      sx={{
                        "& input":{
                          fontSize: "1.2em",
                        }
                      }}
                      value={internalNameState.name}
                      InputProps={{
                        disableUnderline: !internalNameState.editMode,
                      }}
                      onChange={(e) => {
                        setInternalNameState({
                          ...internalNameState,
                          name: e.target.value,
                        });
                      }}
                    />{" "}
                  </Box>

                  {!internalNameState.editMode ? (
                    <EditOutlinedIcon
                      sx={{ cursor: "pointer", transform: "scale(0.9)" }}
                      onClick={() => {
                        setInternalNameState({
                          ...internalNameState,
                          editMode: !internalNameState.editMode,
                        });
                      }}
                    />
                  ) : (
                    <SaveOutlinedIcon
                      sx={{ cursor: "pointer", transform: "scale(0.9)" }}
                      onClick={handleSaveInternalName}
                    />
                  )}
                </Box>
              </Box>
            )}
            <CollaborationModal
              state={showCollabModal}
              toggleModal={toggleCollabModal}
              service="bom"
              projectId={project._id}
              collaborators={project?.collaborators}
            />
            <ViewCollaborators
              state={showViewCollabModal}
              toggleModal={toggleViewCollabModal}
              service="fab"
              projectId={project._id}
              collaborators={project?.collaborators}
            />
            <Box
              sx={{
                margin: "2rem 0rem 1rem 0rem",
                fontSize: "1.4rem",
                fontWeight: "500",
              }}
            >
              📈{props.isAdmin ? "Project Timeline" : "Order Timeline"}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                width: "100%",
                marginLeft: "-2rem",
              }}
            >
              <Timeline>
                {stages.map((stage, i) => {
                  let class_name;
                  let chipNode;

                  if (
                    project?.currentStatus?.length > i + 1 ||
                    project.currentStatus?.length === 10
                  ) {
                    chipNode = (
                      <Chip
                        icon={<DoneIcon></DoneIcon>}
                        color="secondary"
                        label="Completed"
                      ></Chip>
                    );
                    class_name = "completed";
                  } else if (project?.currentStatus?.length == i + 1) {
                    chipNode = (
                      <Chip
                        icon={
                          <NotificationsActiveIcon></NotificationsActiveIcon>
                        }
                        color="primary"
                        label="Active"
                      ></Chip>
                    );
                    class_name = "active";
                  } else {
                    chipNode = (
                      <Chip icon={<LockIcon></LockIcon>} label="Locked"></Chip>
                    );
                    class_name = "locked";
                  }
                  // if (
                  //   props.project_data.activeStageNumber && stage._id
                  //     ? stage._id.toString() ==
                  //       props.project_data.activeStageNumber.toString()
                  //     : false
                  // ) {
                  //   chipNode = (
                  //     <Chip
                  //       icon={
                  //         <NotificationsActiveIcon></NotificationsActiveIcon>
                  //       }
                  //       color="primary"
                  //       label="Active"
                  //     ></Chip>
                  //   );
                  //   class_name = "active";
                  // } else {
                  //   chipNode = (
                  //     <Chip icon={<LockIcon></LockIcon>} label="Locked"></Chip>
                  //   );
                  //   class_name = "locked";
                  // }
                  return (
                    <TimelineItem>
                      {width > 900 ? (
                        <TimelineOppositeContent color="text.secondary">
                          {project.currentStatus.length >= i + 1
                            ? moment(
                                project?.currentStatus[i].completionDate
                              ).format("DD/MM/YYYY")
                            : ""}
                        </TimelineOppositeContent>
                      ) : null}
                      <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <TimelineComponent
                          number={i + 1}
                          title={stage.stage}
                          desc={stage.desc}
                          isSuccess={
                            project.currentStatus.length == i + 1 ? true : false
                          }
                          viewDetails={
                            project.currentStatus.length >= i + 1 ? true : false
                          }
                          projectName={project?.projectName}
                          date={project?.currentStatus[i]?.completionDate}
                          documents={project?.currentStatus[i]?.remarkDocs}
                          id={project._id}
                          remarks={project?.currentStatus[i]?.remarks}
                          count={count}
                          setCount={setCount}
                          Icon={chipNode}
                          currentStage={
                            project?.currentStatus[
                              project?.currentStatus?.length - 1
                            ]?.status
                          }
                          isAdmin={props.isAdmin}
                        />
                      </TimelineContent>
                    </TimelineItem>
                  );
                })}
              </Timeline>
            </Box>
            <Modal
              open={newProjectModalOpen}
              onClose={closeNewProjectModal}
              aria-labelledby="modal-modal-title"
            >
              <Box sx={style}>
                <p
                  style={{ textAlign: "center" }}
                  className="explorer-title"
                  id="modal-modal-title"
                >
                  Elecbits BOM Explorer - ReUpload BOM
                </p>
                <Paper>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-end",
                      flexDirection: "column",
                      padding: "1rem",
                    }}
                  >
                    {/* <Box sx={{display: 'flex'}}>
                            <p>Please make sure that the BOM excel is in the correct format.</p>
                            <br />
                            <p>Download sample</p>
                            <button onClick={() => props.history.push("/bomExplorer/project")}>Submit</button>
                        </Box> */}
                    <DropzoneArea
                      maxFileSize={100000000}
                      init={() => {}}
                      onChange={(files) => {
                        setFiles(files);
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <Box>
                        Please make sure that the BOM excel is in the correct
                        format.{" "}
                        <a href="/files/Eb-BOM-Sample-Sheet.xlsx">
                          Download Sample
                        </a>
                      </Box>

                      <Button
                        sx={{ margin: "1rem" }}
                        variant="contained"
                        onClick={submitHandler}
                        disabled={progress.loading}
                      >
                        Submit
                      </Button>
                    </Box>
                    <Box sx={{ width: "100%", marginTop: "20px" }}>
                      <LinearProgress
                        variant="determinate"
                        value={progress.value}
                      />
                      <p>{progress.value}%</p>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Modal>
          </MainBoxContainer>
        </>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={DashboardLogo1} style={{ width: "150px" }} alt="logo"></img>
          <CircularProgress style={{ marginLeft: "20px" }}></CircularProgress>
        </Box>
      )}
    </Box>
  );
};
export default BomExplorerTimeLine;
