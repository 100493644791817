import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Button } from "@material-ui/core";
import { useReducer } from "react";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "./AddNewShipping.css";
import { axiosInstance } from "../../../../utils/axiosInterceptor";
import axios from "axios";
import { SnackbarProvider, useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";

const AddNewShipping = () => {
  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    rightIcon: {
      marginLeft: theme.spacing(1),
    },
    iconSmall: {
      fontSize: 20,
    },
    root: {
      padding: theme.spacing(3, 2),
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 400,
    },
  }));

  const [shipAddress, setShipAddress] = useState([]);
  const [loading, setLoading] = useState(false);

  let getAddress = () => {
    setLoading(true);
    axiosInstance
      .get("/users/getProfile")
      .then((add) => {
        setLoading(false);
        setShipAddress(add.data.data.shippingAddress);
        //console.log(add.data.data.shippingAddress)
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getAddress();
  }, []);
  const { enqueueSnackbar } = useSnackbar();

  const [address, setAddress] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      fullName: "",
      mobileNumber: "",
      pinCode: "",
      houseNumber: "",
      street: "",
      landmark: "",
      city: "",
      state: "",
      addressType: "",
    }
  );

  let history = useHistory();

  const handleOnSubmit = (evt) => {
    evt.preventDefault();
    console.log(address, shipAddress);
    let f_address = shipAddress;
    f_address.push(address);

    var formData = new FormData();

    const myJSON = JSON.stringify(f_address);
    formData.append("shippingAddress", myJSON);
    setLoading(true);
    let token = localStorage.getItem("token");
    axios
      .put(`https://platform.elecbits.in/api/users/editProfile`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        setLoading(false);
        enqueueSnackbar("Successfully saved Address!", { variant: "success" });
        setTimeout(() => {
          history.push("/pcbMarket/cart");
        }, 500);
      })
      .catch((err) => {
        setLoading(false);
        enqueueSnackbar("Not able to save changes!", { variant: "error" });
      });
  };

  const handleInput = (evt) => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    setAddress({ [name]: newValue });
  };

  const classes = useStyles();

  return (
    <div ClassName="add_new_shipping">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container>
        <Row style={{ marginBottom: "20px", marginTop: "20px" }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/">Home</Link>
            <Link to="/pcbMarket">PCB Market</Link>
            <Link to="/pcbMarket/cart">Cart</Link>
            <Typography color="text.primary">Add New Address</Typography>
          </Breadcrumbs>
        </Row>

        <div className="shipping_inputs">
          <Paper className={classes.root}>
            <form onSubmit={handleOnSubmit}>
              <Stack spacing={2}>
                <TextField
                  required
                  label="Full Name"
                  id="margin-normal"
                  name="fullName"
                  defaultValue={address.fullName}
                  className={classes.textField}
                  onChange={handleInput}
                />
                <TextField
                  required
                  label="Mobile Number"
                  id="margin-normal"
                  name="mobileNumber"
                  type="number"
                  defaultValue={address.mobileNumber}
                  className={classes.textField}
                  onChange={handleInput}
                />
                <TextField
                  required
                  label="Pin Code"
                  id="margin-normal"
                  name="pinCode"
                  defaultValue={address.pinCode}
                  className={classes.textField}
                  onChange={handleInput}
                 
                />
                <TextField
                  required
                  label="House Number"
                  id="margin-normal"
                  name="houseNumber"
                  defaultValue={address.houseNumber}
                  className={classes.textField}
                  onChange={handleInput}
                  
                />
                <TextField
                  required
                  label="Street"
                  id="margin-normal"
                  name="street"
                  defaultValue={address.street}
                  className={classes.textField}
                  onChange={handleInput}
                  
                />
                <TextField
                  required
                  label="LandMark"
                  id="margin-normal"
                  name="landmark"
                  defaultValue={address.landmark}
                  className={classes.textField}
                  onChange={handleInput}
                  
                />
                <TextField
                  required
                  label="City"
                  id="margin-normal"
                  name="city"
                  defaultValue={address.city}
                  className={classes.textField}
                  onChange={handleInput}
                
                />
                <TextField
                  required
                  label="State"
                  id="margin-normal"
                  name="state"
                  defaultValue={address.state}
                  className={classes.textField}
                  onChange={handleInput}
                 
                />
                <TextField
                  required
                  label="Address Type"
                  id="margin-normal"
                  name="addressType"
                  defaultValue={address.addressType}
                  className={classes.textField}
                  helperText="e.g. Home or Office"
                  onChange={handleInput}
                 
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  Add Address
                </Button>
              </Stack>
            </form>
          </Paper>
        </div>
      </Container>
    </div>
  );
};

export default AddNewShipping;
