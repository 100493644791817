import React, { useEffect, useState } from "react";

//libs
import { useDropzone } from "react-dropzone";
import { styled, Box, Typography, Button } from "@mui/material";

//assets
import { ReactComponent as UploadIcon } from "../assests/Upload.svg";
import bin from "../assests/bin.png";

import { useSnackbar } from "notistack";

const RootContainer = styled(Box)(({ isActive, small, theme }) => ({
  width: "100%",
  border: !isActive ? "2px solid #8B8B8B4D" : "2px solid red",
  borderRadius: 8,
  padding: small ? "25px" : "25px 140px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: small ? "15px" : "25px",

  "& .main-content": {
    fontSize: "1.3em",
    textAlign: "center",
  },
  "& .sub-content": {
    fontSize: "0.8em",
    textAlign: "center",
    color: "#8B8B8B",
  },
  "& .note-content": {
    fontSize: "0.75em",
    textAlign: "center",
    color: "#8B8B8B",
  },
  [theme.breakpoints.down("md")]: {
    padding: small ? "25px" : "25px",
  },
}));

const BrowseButton = styled(Button)(() => ({
  "&&": {
    backgroundColor: "#fff",
    color: "#000",
    padding: "10px 20px",
    border: "2px solid #E8E8E8",
    borderRadius: "8px",
    textTransform: "none",
    fontWeight: 400,
  },
}));

const CustomFileDropper = ({
  uploadFiles,
  setFile,
  single,
  small,
  headerText,
  subText,
  showUploadModal,
}) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const { getRootProps, getInputProps, acceptedFiles, isDragActive } =
    useDropzone();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    console.log(uploadFiles);

    if (!single && uploadFiles) {
      console.log("here");
      setSelectedFiles([...uploadFiles, ...acceptedFiles]);
    } else if (acceptedFiles.length > 0) {
      setSelectedFiles([...acceptedFiles]);
    }
  }, [acceptedFiles]);

  useEffect(() => {
    if (selectedFiles.length > 0) {
      if (single) {
        setFile(selectedFiles[0]);
      } else {
        setFile([...selectedFiles]);
      }
    }
  }, [selectedFiles.length]);
  useEffect(() => {
    setSelectedFiles([]);
  }, [showUploadModal]);
  const removeFile = (file) => {
    setSelectedFiles(selectedFiles.filter((f) => f !== file));
    if (single) {
      setFile(null);
    } else {
      setFile(selectedFiles.filter((f) => f !== file));
    }
  };

  const handleName = (name, len) => {
    if (len === null) {
      len = 20;
    }
    if (name === null) {
      return name;
    }

    if (name.length > len) {
      return name.substring(0, len) + "...";
    } else {
      return name;
    }
  };
  console.log(selectedFiles, "selected");
  console.log(uploadFiles, "uploads");
  return (
    <div>
      <RootContainer
        {...getRootProps({ className: "dropzone" })}
        isActive={isDragActive}
        small={small}
      >
        <input {...getInputProps()} multiple={!single} />
        <UploadIcon width="60px" height="60px" />
        <div className="main-content">
          {headerText || "Drag and drop the files to be uploaded or"}
        </div>
        <BrowseButton>Browse computer</BrowseButton>
        {selectedFiles.length > 0 ? null : (
          <>
            <div className="sub-content">
              {subText ||
                "Currently supportive file format: .stl, .obj .3mf, parasolid, 2D,DWG, .stp, .step, .sldprt, .iges, .sat, .catpart, .zip, etc.Maximum file size: 100mb "}
            </div>
            {subText ? (
              ""
            ) : (
              <div className="note-content">
                Note: Upload a sample image of the product prototype for
                reference.
              </div>
            )}
          </>
        )}
      </RootContainer>
      <Box>
        <Box sx={{ width: "100%" }}>
          {selectedFiles.length > 0 &&
            selectedFiles.map((f) => (
              <Box
                sx={{
                  width: "100%",
                  border: "2px solid #8B8B8B4D",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  // boxShadow: " 0px 0px 30px rgba(139, 139, 139, 0.2)",
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <Typography sx={{ maxWidth: "70%" }}>
                  {handleName(f.name, 35)}
                </Typography>
                <Typography>{(f.size / (1024 * 1024)).toFixed(2)}mb</Typography>
                <img
                  src={bin}
                  alt=""
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    removeFile(f);
                  }}
                />
              </Box>
            ))}
        </Box>
      </Box>
    </div>
  );
};

export default CustomFileDropper;
