import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from "@material-ui/core/LinearProgress";
import { axiosInstance } from "../utils/axiosInterceptor";
import { useSnackbar } from "notistack";
import { Row, Container, Col } from "react-bootstrap";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import DocumentUpload from "./DocumentUpload";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import moment from "moment";
import { Link } from "react-router-dom";
import DeleteProject from "./DeleteProject";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import ProjectProfileChange from "./ProjectProfileChange";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import axios from "axios";
import CloseIcon from "@material-ui/icons/Close";
import CancelIcon from "@mui/icons-material/Cancel";
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2),
    minWidth: "100%",
  },
  textField: {
    marginTop: theme.spacing(1),
    minWidth: "100%",
  },
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function EditProject(props) {
  const inputFile = useRef(null);
  const [open, setOpen] = useState(false);
  const [projectName, setProjectName] = useState("_23#");
  const [projectDescription, setDescription] = useState("_23#");
  const [activeStage, setActiveStage] = useState(null);
  const [editProjectLoading, setEditLoading] = useState(false);

  let [stageNumber, setStageNumber] = useState(false);
  let [startDate, setStartDate] = useState(null);
  let [endDate, setEndDate] = useState(null);
  let [remark, setRemark] = useState(null);
  let [stageDescription, setStageDescription] = useState("");
  let [status, setStatus] = useState("");
    let [isStageHidden, setIsStageHidden] = useState(false);
  let [pendingCost, setPendingCost] = useState(null);
  let [advanceCost, setAdvanceCost] = useState(null);
  let [estimatedCost, setEstimatedCost] = useState(null);
  let [paidAmount, setPaidAmount] = useState(null);
  let [fileSelected, setFileSelected] = useState(null);
  let [cmtAdding, setCmtAdding] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const classes = useStyles();

  // useEffect(() => {
  //   if (props.project) {
  //     setProjectName(props.project.projectName);
  //     setDescription(props.project.projectDescription);
  //     setActiveStage(props.project.activeStageNumber);
  //   }
  // }, []);
  useEffect(() => {
    console.log(props.project);
    // setDescription(props.project.projectDescription);
    console.log(projectDescription);
  }, [setOpen]);
  const { enqueueSnackbar } = useSnackbar();

  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <input
        onChange={(e) => {
          setFileSelected(e.target.files[0]);
        }}
        type="file"
        id="file"
        ref={inputFile}
        style={{ display: "none" }}
      />
      <Link
        to={`/viewProject/${props.project._id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconButton aria-label="delete" disabled={props.project ? false : true}>
          <OpenInNewIcon fontSize="medium"></OpenInNewIcon>
        </IconButton>
      </Link>
      <Link to={`/editBilling/${props.project._id}`}>
        <IconButton
          aria-label="delete"
          onClick={() => {
            setOpen(true);
          }}
          disabled={props.project ? false : true}
        >
          <AttachMoneyIcon fontSize="medium"></AttachMoneyIcon>
        </IconButton>
      </Link>

      <IconButton
        aria-label="delete"
        onClick={() => {
          console.log(props.project);
          setOpen(true);
        }}
        disabled={props.project ? false : true}
      >
        <EditIcon fontSize="medium"></EditIcon>
      </IconButton>
      <DeleteProject
        fetchProjectData={props.fetchProjectData}
        projectId={props.project._id}
      ></DeleteProject>
      <Dialog
        fullWidth="true"
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        {editProjectLoading || cmtAdding ? (
          <div className={classes.root}>
            <LinearProgress></LinearProgress>
          </div>
        ) : (
          ""
        )}
        {props.project ? (
          <>
            <DialogTitle id="form-dialog-title">
              {props.project.projectName}
            </DialogTitle>
            <DialogContent>
              <Container className="content_edit_wrapper">
                <Row>
                  <Col md={7} sm={12}>
                    <h3 className="sec_head">Edit Project</h3>
                    <TextField
                      className={classes.formControl}
                      margin="dense"
                      variant="outlined"
                      id="project_name"
                      label="Project Name"
                      type="project_name"
                      defaultValue={props.project.projectName}
                      onChange={(e) => {
                        setProjectName(e.target.value);
                      }}
                      fullWidth
                    />
                    <TextField
                      className={classes.formControl}
                      margin="dense"
                      variant="outlined"
                      id="project_name"
                      label="Project Description"
                      type="project_description"
                      fullWidth
                      defaultValue={props.project.projectDescription}
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                      multiline
                    />

                    <div className={classes.formControl}>
                      <Button
                        style={{ marginRight: "10px" }}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          let projName = projectName,
                            projDesc = projectDescription;
                          if (projectName == "_23#") {
                            projName = props.project.projectName;
                          }
                          if (projectDescription == "_23#") {
                            projDesc = props.project.projectDescription;
                          }
                          console.log(projName, projDesc);
                          if (projName == "") {
                            enqueueSnackbar("Project name is required");
                          } else {
                            setEditLoading(true);
                            axiosInstance
                              .put(
                                `/projects/editDetails/${props.project._id}`,
                                {
                                  projectName: projName,
                                  projectDescription: projDesc,
                                  projectActiveStage: activeStage,
                                }
                              )
                              .then((resp) => {
                                setEditLoading(false);
                                enqueueSnackbar(
                                  "Project updated successfully",
                                  {
                                    variant: "success",
                                  }
                                );
                              })
                              .catch((err) => {
                                setEditLoading(false);
                                enqueueSnackbar(
                                  "Not able to update the project",
                                  {
                                    variant: "error",
                                  }
                                );
                              });
                          }
                        }}
                      >
                        Save Changes
                      </Button>
                    </div>
                    <h3 className="sec_head">Project Image</h3>
                    <div className="proj_img">
                      IMAGE :{" "}
                      {props.project.projectPic ? (
                        <a href={props.project.projectPic.fileLocation}>
                          {props.project.projectPic.fileLocation}
                        </a>
                      ) : (
                        "NA"
                      )}
                    </div>
                    <ProjectProfileChange
                      setEditLoading={setEditLoading}
                      project={props.project}
                      fetchProjectData={props.fetchProjectData}
                      handleClose={handleClose}
                    ></ProjectProfileChange>
                    <h3 className="sec_head">Edit Stage</h3>

                    <FormControl
                      className={classes.formControl}
                      variant="filled"
                      fullWidth
                      className={classes.formControl}
                    >
                      <InputLabel id="demo-simple-select-label" fullWidth>
                        Select stage to edit
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label="Slect active stage"
                        value={stageNumber}
                        onChange={(e) => {
                          setStageNumber(e.target.value);
                          let stages = props.project.stageInfo;
                          let i = 0;
                          while (i < stages.length) {
                            if (
                              stages[i]._id.toString() ==
                              e.target.value.toString()
                            ) {
                              let strt_date;
                              if (stages[i].startDate) {
                                strt_date = moment(
                                  stages[i].startDate,
                                  "YYYY-MM-DDTHH:mm:ss"
                                ).format("YYYY-MM-DDTHH:mm:ss");
                              } else {
                                strt_date = "0000-00-00T00:00";
                              }
                              let end_date;
                              if (stages[i].endDate) {
                                end_date = moment(
                                  stages[i].endDate,
                                  "YYYY-MM-DDTHH:mm:ss"
                                ).format("YYYY-MM-DDTHH:mm:ss");
                              } else {
                                end_date = "0000-00-00T00:00";
                              }
                              console.log(strt_date, end_date);
                              setStartDate(strt_date);
                              setEndDate(end_date);
                              setStageDescription(stages[i].stageDescription);
                              setStatus(stages[i].status);
                              if (
                                !stages[i].pendingCosting &&
                                stages[i].pendingCosting != 0
                              ) {
                                setPendingCost("");
                              } else {
                                setPendingCost(stages[i].pendingCosting);
                              }

                              if (
                                !stages[i].advanceCosting &&
                                stages[i].advanceCosting != 0
                              ) {
                                setAdvanceCost("");
                              } else {
                                setAdvanceCost(stages[i].advanceCosting);
                              }

                              if (
                                !stages[i].estimatedCost &&
                                stages[i].estimatedCost != 0
                              ) {
                                setEstimatedCost("");
                              } else {
                                setEstimatedCost(stages[i].estimatedCost);
                              }

                              if (
                                !stages[i].paidAmount &&
                                stages[i].paidAmount != 0
                              ) {
                                setPaidAmount("");
                              } else {
                                setPaidAmount(stages[i].paidAmount);
                              }
                              // setRemarks(stages[i])
                              break;
                            }
                            i++;
                          }
                        }}
                      >
                        {props.project.stageInfo.map((stage) => {
                          return (
                            <MenuItem value={stage._id}>
                              {stage.stageName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    {/*
                        <TextField
                          className={classes.formControl}
                          margin="dense"
                          variant="outlined"
                          id="add_description"
                          label="Description"
                          type="add_comment"
                          fullWidth
                          multiline
                          value={
                            stageDescription ? stageDescription.toString() : ""
                          }
                          onChange={(e) => {
                            setStageDescription(e.target.value);
                          }}
                        />
                    */}
                    <TextField
                      className={classes.formControl}
                      id="datetime-local"
                      label="Start date"
                      type="datetime-local"
                      //   defaultValue="2017-05-24T10:30"
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={startDate}
                      onChange={(e) => {
                        setStartDate(e.target.value);
                      }}
                    />
                    <TextField
                      className={classes.formControl}
                      id="datetime-local"
                      label="End date"
                      type="datetime-local"
                      //   defaultValue="2017-05-24T10:30"
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={endDate}
                      onChange={(e) => {
                        setEndDate(e.target.value);
                      }}
                    />

                    <div className="slector_cmts">
                      <div className="attach_icn">
                        <IconButton
                          onClick={() => {
                            inputFile.current.click();
                          }}
                        >
                          <AttachFileIcon
                            style={{ transform: "rotate(45deg)" }}
                          ></AttachFileIcon>
                        </IconButton>
                      </div>
                      <TextField
                        id="standard-multiline-flexible"
                        label={fileSelected ? "Add File" : "Add Comment"}
                        focused={
                          fileSelected || (remark && remark.length > 0)
                            ? true
                            : false
                        }
                        multiline
                        maxRows={7}
                        style={{
                          width: "100%",
                          marginTop: "4px",
                          marginBottom: "10px",
                        }}
                        value={fileSelected ? fileSelected.name : remark}
                        onChange={(e) => {
                          if (!fileSelected) {
                            setRemark(e.target.value);
                          }
                        }}
                      />
                      {fileSelected ? (
                        <IconButton
                          onClick={() => {
                            setFileSelected(null);
                            setRemark("");
                          }}
                        >
                          <CancelIcon></CancelIcon>
                        </IconButton>
                      ) : (
                        ""
                      )}
                    </div>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <FormLabel component="legend">Select Status</FormLabel>
                      <RadioGroup
                        row
                        aria-label="position"
                        name="status"
                        value={status ? status.toString() : "locked"}
                        onChange={(e) => {
                          setStatus(e.target.value);
                        }}
                      >
                        <FormControlLabel
                          value="active"
                          control={<Radio color="primary" />}
                          label="Active"
                        />
                        <FormControlLabel
                          value="completed"
                          control={<Radio color="primary" />}
                          label="Completed"
                        />
                        <FormControlLabel
                          value="locked"
                          control={<Radio color="primary" />}
                          label="Locked"
                        />
                      </RadioGroup>
                        <FormGroup>
                            <FormControlLabel control={<Switch
                              onChange={(e) => setIsStageHidden(e.target.checked)} 
                                value={isStageHidden}
                            />} label="Hidden" />
                        </FormGroup>
                    </FormControl>
                    <div className={classes.formControl}>
                      <Button
                        style={{ marginRight: "10px" }}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          if (stageNumber) {
                            setEditLoading(true);
                            console.log(estimatedCost);
                            if (remark || fileSelected) {
                              let formData = new FormData();
                              formData.append("projectId", props.project._id);
                              if (fileSelected) {
                                formData.append("doc", fileSelected);
                                formData.append("type", "multimedia");
                              } else {
                                formData.append("type", "textMessage");
                                formData.append("message", remark);
                              }
                              let token = localStorage.getItem("token");
                              setCmtAdding(true);

                              axios
                                .post(
                                  `https://platform.elecbits.in/api/projects/addRemark/${stageNumber}`,
                                  formData,
                                  {
                                    headers: {
                                      Authorization: `Bearer ${token}`,
                                      "Content-type": "multipart/form-data",
                                    },
                                  }
                                )
                                .then((resp) => {
                                  setCmtAdding(false);

                                  enqueueSnackbar("Remark added successfully", {
                                    variant: "success",
                                  });
                                  setRemark("");
                                  setFileSelected(null);
                                })
                                .catch((err) => {
                                  setCmtAdding(false);
                                  enqueueSnackbar("Not able to add remark", {
                                    variant: "error",
                                  });
                                });
                            }

                            axiosInstance
                              .put(`/projects/editStage/${stageNumber}`, {
                                projectId: props.project._id,
                                stageDescription: stageDescription,
                                startDate: startDate,
                                endDate: endDate,
                                status: status,
                                advanceCosting: advanceCost,
                                pendingCosting: pendingCost,
                                estimatedCost: estimatedCost,
                                paidAmount: paidAmount,
                                hidden: isStageHidden
                              })
                              .then((resp) => {
                                setEditLoading(false);
                                enqueueSnackbar("Stage updated successfully", {
                                  variant: "success",
                                });
                              })
                              .catch((err) => {
                                enqueueSnackbar(
                                  "Not able to update the stage",
                                  {
                                    variant: "error",
                                  }
                                );
                                setEditLoading(false);
                              });
                          } else {
                            enqueueSnackbar("Please select stage number", {
                              variant: "error",
                            });
                          }
                        }}
                      >
                        Save Changes
                      </Button>
                    </div>
                  </Col>
                  <Col md={5} sm={12}>
                    <DocumentUpload
                      setEditLoading={setEditLoading}
                      project={props.project}
                      fetchProjectData={props.fetchProjectData}
                      handleClose={handleClose}
                    ></DocumentUpload>
                  </Col>
                </Row>
              </Container>
            </DialogContent>
          </>
        ) : (
          ""
        )}
      </Dialog>
    </div>
  );
}
