import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Setting from "../assests/processor.svg";
import Confirm from "../assests/currency.svg";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import "./dashboard.css";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Collaborators from "./Collaborators";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function DashboardStats(props) {
  console.log(props.profile_data);
  const [collapseVisible, setVisible] = useState(true);
  const [open, setOpen] = useState(false);
  const elementRef = useRef(null);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
 
  useEffect(() => {}, []);
  console.log(props.activeStage);
  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          💵 Billing Details
        </DialogTitle>
        <DialogContent dividers>
          <div className="bill_cmp large">
            <div className="bill_type">Pending Amount</div>
            <div className="bill_amount"> ₹{props.totalPendingAmount}</div>
          </div>
          <div className="bill_cmp large">
            <div className="bill_type">Paid Amount</div>
            <div className="bill_amount"> ₹{props.totalPaidAmount}</div>
          </div>
          <div className="break_down_head">Billing Breakdown</div>
          {props.project_data.billing
            ? props.project_data.billing.map((obj) => {
                if (obj.type.toString() == "Pending Amount") {
                } else if (obj.type.toString() == "Paid Amount") {
                } else {
                  return (
                    <div className="bill_cmp">
                      <div className="bill_type">{obj.type}</div>
                      <div className="bill_amount"> ₹{obj.amount}</div>
                    </div>
                  );
                }
              })
            : ""}
        </DialogContent>
      </Dialog>
      <Container className="stats_wrapper">
        <Row>
          <Col md={6} sm={12}>
            <div className="stats_card">
              <div>
                <img src={Setting} style={{ width: "80px" }}></img>
              </div>
              <div className="stats_card_right">
                <div className="box_type">Project Overview</div>
                <div>
                  {props.activeStage ? (
                    <>
                      <span>Active Stage</span> :{" "}
                      <span style={{ color: "black" }}>
                        {props.activeStage.stageName}
                      </span>
                    </>
                  ) : (
                    `Active Stage : NA`
                  )}
                </div>
                <div>
                  {props.activeStage && props.activeStage.endDate ? (
                    <>
                      <span>Due Date :</span>{" "}
                      <span style={{ color: "black" }}>
                        {moment(
                          props.activeStage.endDate,
                          "YYYY-MM-DDTHH:mm:ss"
                        ).format("dddd, MMMM Do YYYY, h:mm a")}
                      </span>
                    </>
                  ) : (
                    `Due Date : NA`
                  )}
                </div>
              </div>
            </div>
          </Col>
      {/*<BillingDetails handleClickOpen={handleClickOpen} props={props}/>*/}
        </Row>
        <Row>
          <div className="proj_description">
            <div className="proj_description_head">
              📃 Project Description and Features
            </div>
            <div className="proj_description">
              {props.project_data.projectDescription}
            </div>
          </div>
        </Row>
        <Row>
          <Collaborators profile_data={props.profile_data} history={props.history} fetchProfile={props.fetchProfile} fetchProject={props.fetchProject} allUsers={props.allUsers} project_info={props.project_data}></Collaborators>
        </Row>
      </Container>
    </>
  );
}

const BillingDetails = ({handleClickOpen, props}) => {
    return (
          <Col md={6} sm={12}>
            <div className="stats_card">
              <div>
                <img src={Confirm} style={{ width: "80px" }}></img>
              </div>
              <div className="stats_card_right">
                <div className="box__type">
                  <span>Billing Details</span>
                  <span
                    className="detaails_btn"
                    onClick={() => {
                      handleClickOpen();
                    }}
                  >
                    <u> View Details</u>
                  </span>
                </div>
                <div>
                  <span>Total Amount Paid :</span>
                  <span style={{ color: "black" }}>
                    {" "}
                    ₹{props.totalPaidAmount}
                  </span>
                </div>
                <div>
                  <span>Total Pending Amount :</span>
                  <span style={{ color: "black" }}>
                    {" "}
                    ₹{props.totalPendingAmount}
                  </span>
                </div>
              </div>
            </div>
          </Col>
    )
}

