import { useEffect, useState } from "react";
import "./explorer.css";
import { Paper, Modal } from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import Button from "@mui/material/Button";
import { axiosInstance } from "../utils/axiosInterceptor";

import { SnackbarProvider, useSnackbar } from "notistack";
import DashboardHeader from "../ClientEngagement/DashboardHeader";
import useErrorHandler from "../Hooks/useErrorHandler";
import { TextField, styled, Box } from "@mui/material";
import CustomBreadcrumbs from "../Components/CustomBreadcrumbs";
import { LoadingButton } from "@mui/lab";

const CustomTextField = styled(TextField)(({ theme }) => ({
  width: "30%",
  marginBottom: "1rem",
  [theme.breakpoints.down("md")]: {
    width: "60%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "80%",
  },
}));

const DropzoneContainer = styled(Box)(({ theme }) => ({
  width: "50%",
  marginBottom: "1rem",
  [theme.breakpoints.down("md")]: {
    width: "70%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
}));

const MainBoxContainer = styled(Box)(({ theme }) => ({
  margin: "4rem",
  [theme.breakpoints.down("lg")]: {
    margin: "3rem",
  },
  [theme.breakpoints.down("md")]: {
    margin: "2rem",
  },
  [theme.breakpoints.down("sm")]: {
    margin: "1.2rem",
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  backgroundColor: "#fff",
  boxShadow: 24,
  p: 4,
  borderRadius: "3px",
};

const FABNewProject = (props) => {
  const [files, setFiles] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [PCBQuantity, setPCBQuantity] = useState(0);
  const [prof_loading, setLoading] = useState(true);
  const [profile_data, set_profile] = useState(null);
  const [userEmail, setUserEmail] = useState("");
  const [createLoading, setCreateLoading] = useState(false);
  const [surfaceFinish, setSurfaceFinish] = useState({
    val: "HASL (with Lead)",
    no: 0,
  });
  const [deliveryFormat, setDeliveryFormat] = useState({
    val: "Single PCB",
    no: 0,
  });
  const [stencilRequired, setStencilRequired] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [isNumberError, NumberHelperText, NumberErrorHandler] =
    useErrorHandler();

  useEffect(() => {
    console.log(surfaceFinish, deliveryFormat, stencilRequired);
    setDeliveryFormat({
      val: "Single PCB",
      no: 0,
    });
    setSurfaceFinish({
      val: "HASL (with Lead)",
      no: 0,
    });
    setStencilRequired(true);
  }, []);

  let fetchProfile = () => {
    setLoading(true);
    axiosInstance
      .get("/users/getProfile")
      .then((resp) => {
        set_profile(resp.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchProfile();
  }, []);
  const submitHandler = async () => {
    if (files.length > 0) {
      let formData = new FormData();
      formData.append("fab", files[0]);
      formData.append("projectName", projectName);
      formData.append("pcbQuantity", PCBQuantity);
      formData.append("surfaceFinish", surfaceFinish.val);
      formData.append("deliveryFormat", deliveryFormat.val);
      formData.append("isStencil", stencilRequired);
      formData.append("userEmail", userEmail);
      const token = localStorage.getItem("token");
      setCreateLoading(true);
      try {
        const res = await axiosInstance.post(
          "/fab/admin/createProject",
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-type": "multipart/form-data",
            },
          }
        );
        setCreateLoading(false);

        console.log(res);
        enqueueSnackbar("Project Created Successfully!", {
          variant: "success",
        });
        // props.history.push(`/pcbFabTimeline/${res.data.newProj._id}`);
        props.history.push("/admin/pcbFab/projects");
      } catch (e) {
        console.log(e);
        enqueueSnackbar("some error occurred", { variant: "error" });
      }
    } else {
      enqueueSnackbar("Please select atleast one file!", { variant: "error" });
    }
  };

  return (
    <div>
      {!prof_loading && profile_data && (
        <div>
          <div className="header">
            <DashboardHeader
              profile_data={profile_data.data}
              toggleLoggedIn={props.toggleLoggedIn}
              history={props.history}
              isBlack={true}
            ></DashboardHeader>
          </div>

          <MainBoxContainer>
            <CustomBreadcrumbs
              data={[
                {
                  name: "Home",
                  link: "/",
                },
                {
                  name: "EB PCB Fabricator",
                  link: "/admin/pcbFab/projects",
                },
                {
                  name: "Create New Project",
                  link: "/admin/pcbFab/newProject",
                },
              ]}
            />
            <p className="explorer-title">
              Elecbits PCB Fabricator - New Project 🚀
            </p>

            <Box
              display="flex"
              style={{
                flexDirection: "column",
                width: "80%",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <CustomTextField
                id="outlined-basic"
                label="Project Name"
                variant="outlined"
                onChange={(e) => setProjectName(e.target.value)}
              />
              <CustomTextField
                id="outlined-basic"
                label="User Email"
                variant="outlined"
                onChange={(e) => setUserEmail(e.target.value)}
              />
              <DropzoneContainer>
                <DropzoneArea
                  maxFileSize={1240000000}
                  init={() => {}}
                  onChange={(files) => {
                    setFiles(files);
                  }}
                />
              </DropzoneContainer>
              <CustomTextField
                id="outlined-basic"
                label="PCB quantity"
                variant="outlined"
                onChange={(e) => setPCBQuantity(e.target.value)}
              />
              <Box>
                <Box>
                  Surface Finish
                  <Box style={{ margin: "1rem 0rem 1rem 0rem" }}>
                    <Button
                      title="HASL (with Lead)"
                      onClick={(e) =>
                        setSurfaceFinish({ val: e.target.title, no: 0 })
                      }
                      style={{
                        color: surfaceFinish.no === 0 ? "#1565c0" : "#818181",
                        marginRight: "1rem",
                        border:
                          surfaceFinish.no === 0 ? "" : "1px solid #818181",
                      }}
                      variant="outlined"
                      color="primary"
                    >
                      HASL (with Lead)
                    </Button>
                    <Button
                      title="Lead Free HASL (ROHS)"
                      onClick={(e) =>
                        setSurfaceFinish({ val: e.target.title, no: 1 })
                      }
                      style={{
                        color: surfaceFinish.no == 1 ? "#1565c0" : "#818181",
                        border:
                          surfaceFinish.no == 1 ? "" : "1px solid #818181",
                      }}
                      variant="outlined"
                    >
                      Lead Free HASL (ROHS)
                    </Button>
                  </Box>
                </Box>
                <Box>
                  Delivery Format
                  <Box style={{ margin: "1rem 0rem 1rem 0rem" }}>
                    <Button
                      title="Single PCB"
                      onClick={(e) => {
                        setDeliveryFormat({ val: e.target.title, no: 0 });
                      }}
                      style={{
                        color: deliveryFormat.no == 0 ? "#1565c0" : "#818181",
                        marginRight: "1rem",
                        border:
                          deliveryFormat.no == 0 ? "" : "1px solid #818181",
                      }}
                      variant="outlined"
                    >
                      {" "}
                      Single PCB
                    </Button>
                    <Button
                      title="Panel by Customer"
                      onClick={(e) =>
                        setDeliveryFormat({ val: e.target.title, no: 1 })
                      }
                      style={{
                        color: deliveryFormat.no == 1 ? "#1565c0" : "#818181",
                        marginRight: "1rem",
                        border:
                          deliveryFormat.no == 1 ? "" : "1px solid #818181",
                      }}
                      variant="outlined"
                    >
                      Panel by Customer
                    </Button>
                    <Button
                      title="Panel by Elecbits"
                      onClick={(e) =>
                        setDeliveryFormat({ val: e.target.title, no: 2 })
                      }
                      style={{
                        color: deliveryFormat.no == 2 ? "#1565c0" : "#818181",
                        border:
                          deliveryFormat.no == 2 ? "" : "1px solid #818181",
                      }}
                      variant="outlined"
                    >
                      Panel by Elecbits
                    </Button>
                  </Box>
                </Box>
                <Box>
                  Stencil Required
                  <Box style={{ margin: "1rem 0rem 1rem 0rem" }}>
                    <Button
                      onClick={() => setStencilRequired(true)}
                      style={{
                        color: stencilRequired ? "#1565c0" : "#818181",
                        border: stencilRequired ? "" : "1px solid #818181",
                        marginRight: "1rem",
                      }}
                      variant="outlined"
                    >
                      Yes
                    </Button>
                    <Button
                      onClick={() => setStencilRequired(false)}
                      style={{
                        color: !stencilRequired ? "#1565c0" : "#818181",
                        border: !stencilRequired ? "" : "1px solid #818181",
                      }}
                      variant="outlined"
                    >
                      No
                    </Button>
                  </Box>
                </Box>
              </Box>
              <LoadingButton
                style={{ margin: "0rem" }}
                variant="contained"
                onClick={submitHandler}
                loading={createLoading}
              
              >
                Create Project
              </LoadingButton>

              {/* <Button
                style={{ margin: "0rem" }}
                variant="contained"
                onClick={submitHandler}
              >
                Create Project
              </Button> */}
            </Box>
          </MainBoxContainer>
        </div>
      )}
    </div>
  );
};

export default FABNewProject;
