export const supplierStages = [
  {
    value: "Request For Quote",
    label: "Request For Quote",
  },
  {
    value: "Quote Submitted",
    label: "Quote Submitted",
  },
  {
    value: "Quote Accepted",
    label: "Quote Accepted",
  },
  {
    value: "Order Packed",
    label: "Order Packed",
  },
  {
    value: "Order Shipped",
    label: "Order Shipped",
  },
  {
    value: "Delivered",
    label: "Delivered",
  },
];
