import React, { useState } from 'react';
import '../HomeComponent/Home.css';
import { Container, Row, Col } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import PCBImage from '../assests/pcbImg.svg';
import DashboardHeader from '../ClientEngagement/AdminDashboardHeader';
import { Box, styled, Typography } from '@mui/material';
import { axiosInstance } from '../utils/axiosInterceptor';
import CircularProgress from '@material-ui/core/CircularProgress';
import DashboardLogo1 from '../assests/elecbitsLogo.svg';
import _3dServices from '../assests/_3dServices.png';
import bomExplorer from '../assests/bomExplorer.png';
import consultations from '../assests/consultations.png';
import customerEngagement from '../assests/customerEngagement.png';
import HardwareArcade from '../assests/client_homepage/hardware_arcade.svg';
import PcbFab from '../assests/client_homepage/pcbFab.svg';
import bomEx from '../assests/client_homepage/bomEx.svg';
import digiSMT from '../assests/client_homepage/digiSMT.svg';
import threeDCOll from '../assests/client_homepage/3dcoll.svg';
import consultation from '../assests/client_homepage/consultation.svg';
import virtualManager from '../assests/client_homepage/virtualManager.svg';
import xlab from '../assests/client_homepage/xLabs.svg';
import {useHistory} from "react-router-dom";

import pcbArena from '../assests/pcbArena.png';
import pcbAssembly from '../assests/pcbAssembly.png';

const StyledButton = withStyles({
  root: {
    background: '#6200EE',
    color: 'white',
    '&:hover': {
      backgroundColor: '#6200EE',
    },
  },
})(Button);

const OuterContainer = styled(Box)(() => ({
  width: '20vw',
  height: '28vh',
  borderRadius: 15,
  padding: 3,
  background:
    'linear-gradient(90deg, #F67C1B 20%, #E95252 40%,#E95252 60%,#0275FC 80%,#0275FC 100%)',
}));

const ServiceOverviewContainer = styled(Box)(({ disabled }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  boxShadow:
    '0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
  // margin: "20px",
  padding: '20px',
  gap: '5px',
  alignItems: 'center',
  borderRadius: 15,
  backgroundColor: disabled ? '#E8E8E8' : '#fff',
  transition: 'all 0.2s ease-in-out',
  '& .service_sub': {
    color: '#838383',
    fontSize: 12,
  },
  '& .service_title': {
    color: '#000',
    fontSize: 20,
    fontWeight: 500,
  },
  '&:hover': {
    backgroundColor: '#E8E8E8',
  },
  '& .coming_soon': {
    color: '#838383',
  },
  cursor: 'pointer',
  '& .text-container': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center ',
  },
}));

const ServiceOverview = ({ service }) => {
  const [isHovered, setIsHovered] = useState(false);

  return service.value === 'cs' ? (
    <>
      <OuterContainer>
        <ServiceOverviewContainer
          disabled={service?.disabled}
          onMouseEnter={() => {
            setIsHovered(true);
          }}
          onMouseLeave={() => {
            setIsHovered(false);
          }}
        >
          <div style={{ marginBottom: '10px' }}>
            <img src={service.src} alt={service.src}></img>
          </div>
          <div className="text-container">
            <div className="service_title">{service.name}</div>
            <div className="service_sub">{service.description}</div>
            {isHovered && service?.disabled ? (
              <div className="coming_soon">COMING SOON...</div>
            ) : null}
          </div>
        </ServiceOverviewContainer>
      </OuterContainer>
    </>
  ) : (
    <Link to={service.link}>
      <OuterContainer>
        <ServiceOverviewContainer
          disabled={service?.disabled}
          onMouseEnter={() => {
            setIsHovered(true);
          }}
          onMouseLeave={() => {
            setIsHovered(false);
          }}
        >
          <div style={{ marginBottom: '10px' }}>
            <img src={service.src} alt={service.src}></img>
          </div>
          <div className="text-container">
            <div className="service_title">{service.name}</div>

            {isHovered && service?.disabled ? (
              <div className="coming_soon">COMING SOON...</div>
            ) : (
              <div className="service_sub">{service.description}</div>
            )}
          </div>
        </ServiceOverviewContainer>
      </OuterContainer>
    </Link>
  );
};

const options = [
  {
    serviceType: 'Manufacturing ',
    services: [
      {
        name: 'PCB Fabricator',
        description: 'Fabricate PCBs Like a Pro',
        value: 'pb',
        link: '/admin/pcbFab',
        src: PcbFab,
      },
      {
        name: 'BOM Explorer',
        description: (
          <div>
            Streamline Procurement,
            <br /> Launch Faster
          </div>
        ),
        value: 'bl',
        link: '/admin/bomExplorer',
        src: bomEx,
      },
      {
        name: 'DigiSMT',
        description: 'Fully Managable and Scalable PCB Assembly Service',
        value: 'dg',
        // link: "/pcbFabricator",
        link: '/',
        src: digiSMT,
        disabled: true,
      },
      {
        name: '3D Colosseum',
        description: (
          <div>
            Enclosure manufacturing <br /> simplified
          </div>
        ),
        value: 'd3',
        link: '/admin/3d',
        src: threeDCOll,
      },
    ],
  },
  {
    serviceType: 'Design and Development ',
    services: [
      {
        name: 'Hardware Arcade',
        value: 'cl',
        link: '/',
        disabled: true,
        src: HardwareArcade,
        description: 'PCBs PCBs Everywhere!',
      },
    ],
  },
  {
    serviceType: 'Miscellaneous ',
    services: [
      {
        name: 'Consult an Expert',
        description: 'Confused? We Can Help You!',
        value: 'cs',
        link: '/admin/allConsultations',
        src: consultation,
      },
      {
        name: 'Virtual Manager',
        description: (
          <div>
            Your Virtual Project <br /> Manager
          </div>
        ),
        value: 'vm',
        link: '/clientEngagement',
        src: virtualManager,
      },
    ],
  },
  {
    serviceType: 'Admin services',
    services: [
      {
        name: 'Platform Users',
        description: 'Where Magic Happens',
        value: 'pu',
        link: '/admin/allUsers',
        src: xlab,
      },
      {
        name: 'Platform PM',
        description: 'Where Magic Happens',
        value: 'pm',
        link: '/admin/projectManagers',
        src: xlab,
      },
      {
        name: 'Vendor Management',
        description: 'Where Magic Happens',
        value: 'vm',
        link: '/admin/vendorManagementDashboard',
        src: xlab,
      },
    ],
  },
];

const pmOptions = [
  {
    serviceType: 'Manufacturing ',
    services: [
      {
        name: 'PCB Fabricator',
        description: 'Fabricate PCBs Like a Pro',
        value: 'pb',
        link: '/pm/pcbFab/',
        src: PcbFab,
      },
      {
        name: 'BOM Explorer',
        description: (
          <div>
            Streamline Procurement,
            <br /> Launch Faster
          </div>
        ),
        value: 'bl',
        link: '/pm/bomExplorer',
        src: bomEx,
      },
      {
        name: 'DigiSMT',
        description: 'Fully Managable and Scalable PCB Assembly Service',
        value: 'dg',
        // link: "/pcbFabricator",
        link: '/',
        src: digiSMT,
        disabled: true,
      },
      {
        name: '3D Colosseum',
        description: (
          <div>
            Enclosure manufacturing <br /> simplified
          </div>
        ),
        value: 'd3',
        link: '/pm/3d',
        src: threeDCOll,
      },
    ],
  },
  {
    serviceType: 'Design and Development ',
    services: [
      {
        name: 'Hardware Arcade',
        value: 'cl',
        //link: '/',
        disabled: true,
        src: HardwareArcade,
        description: 'PCBs PCBs Everywhere!',
      },
    ],
  },
  {
    serviceType: 'Miscellaneous ',
    services: [
      {
        name: 'Consult an Expert',
        description: 'Confused? We Can Help You!',
        value: 'cs',
        link: '/pm/allConsultations',
        src: consultation,
      },
      {
        name: 'Virtual Manager',
        description: (
          <div>
            Your Virtual Project <br /> Manager
          </div>
        ),
        value: 'vm',
        link: '/clientEngagement',
        src: virtualManager,
      },
    ],
  },
  {
    serviceType: 'Admin services',
    services: [
      {
        name: 'Platform Users',
        description: 'Where Magic Happens',
        value: 'pu',
        link: '/pm/allUsers',
        src: xlab,
      },
      {
        name: 'Vendor Management',
        description: 'Where Magic Happens',
        value: 'vm',
        link: '/pm/vendorManagementDashboard',
        src: xlab,
      },
    ],
  },
];

export default function AdminHomePage(props) {
  const [profile_data, set_profile] = React.useState(null);
  const [loading, set_loading] = React.useState(null);
  const [isPM, setIsPM] = useState(false);
  let fetchProfile = () => {
    set_loading(true);
    axiosInstance
      .get('/users/getProfile')
      .then((resp) => {
        set_profile(resp.data);
        set_loading(false);
      })
      .catch((err) => {
        set_loading(false);
      });
  };
    const history = useHistory();
    React.useEffect(() => {
        if(history.location.pathname === "/" && localStorage.PM) history.push("/pm");
    }, []);
  React.useEffect(() => {
      console.log("location: ", history.location);
    fetchProfile();
    if (localStorage.PM) {

      setIsPM(true);
    }
  }, []);
  return (
    <Box>
      {!loading && profile_data ? (
        <Box
          sx={{
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'hidden',
          }}
        >
          <DashboardHeader
            profile_data={profile_data.data}
            toggleLoggedIn={props.toggleLoggedIn}
            history={props.history}
            isBlack
          ></DashboardHeader>
          <Box
            sx={{
              width: '100%',
              flex: 1,
              padding: '50px 50px',
              fontFamily: 'Poppins',
              backgroundColor: '#F4F7FE',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            <Box sx={{ marginBottom: '40px' }}>
              <Typography sx={{ fontSize: '2em', fontWeight: 600 }}>
                {isPM
                  ? ' Elecbits PM Console 🚀'
                  : ' Elecbits Admin Console 🚀'}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
              {isPM
                ? pmOptions.map((option) => (
                    <Box>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: '1.3em',
                          marginBottom: '10px',
                        }}
                      >
                        {option.serviceType}
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          gap: '40px',
                          alignItems: 'center',
                        }}
                      >
                        {option.services.map((service) => (
                          <ServiceOverview service={service} />
                        ))}
                      </Box>
                    </Box>
                  ))
                : options.map((option) => (
                    <Box>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: '1.3em',
                          marginBottom: '10px',
                        }}
                      >
                        {option.serviceType}
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          gap: '40px',
                          alignItems: 'center',
                        }}
                      >
                        {option.services.map((service) => (
                          <ServiceOverview service={service} />
                        ))}
                      </Box>
                    </Box>
                  ))}
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img src={DashboardLogo1} style={{ width: '150px' }} alt="logo"></img>
          <CircularProgress style={{ marginLeft: '20px' }}></CircularProgress>
        </Box>
      )}
    </Box>
  );
}
