import {
  styled,
  Box,
  Grid,
  TextField,
  Select,
  Slider,
  Button,
} from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import LoadingButton from "@mui/lab/LoadingButton";
// import { Select } from "@material-ui/core";

export const PageContainer = styled(Box)(() => ({
  width: "100vw",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
}));

export const CreateProjectContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  flex: 1,
  padding: "50px 50px",
  fontFamily: "Poppins",
  backgroundColor: "#F4F7FE",
  overflowY: "auto",

  "& .title": {
    marginTop: "30px",
    fontWeight: 600,
  },
  "& .sub-title": {
    marginTop: "10px",
    color: "#8B8B8B",
    marginBottom: "50px",
  },
  "& form": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "20px 20px",
  },
}));

export const CreateProjectFormContainer = styled(Box)(() => ({
  width: "80%",
  margin: "20px auto",
  "& .dropzone-container": {
    width: "100%",
    margin: "0 auto",
    marginBottom: "50px",
  },
}));

export const CusotmGridItem = styled(Grid)(({ shift, isLast, theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: shift ? "end" : "start",
  "& .grid-item-inner": {
    width: isLast ? "100%" : "70%",
  },
  "& .Mui-disabled": {
    backgroundColor: "#d8d8d8",
  },
  [theme.breakpoints.down("md")]: {
    alignItems: "start",
  },
}));

export const CustomInput = styled(TextField)(({ error }) => ({
  width: "100%",

  backgroundColor: "transparent",
  outline: "none",
  "&:focus": {
    outline: "none",
  },
  "& legend": {
    width: "unset",
  },
  "& .MuiOutlinedInput-root": {
    border: error ? "2px solid red" : "2px solid #8B8B8B4D",
    borderRadius: 8,
  },
  "& input": {
    padding: "7px",
  },
  "& fieldset": {
    border: "none",
  },
}));

export const CustomSelect = styled(Select)(() => ({
  width: "100%",

  backgroundColor: "transparent",
  borderRadius: 8,
  padding: "5px",
  "& .MuiSelect-select": {
    padding: "5px",
  },
  "& .MuiInputBase-root.MuiOutlinedInput-root": {
    border: "2px solid #8B8B8B4D",
    borderRadius: "8px",
  },
  "& fieldset": {
    // border:'none',
    border: "2px solid #8B8B8B4D",
    borderRadius: "8px",
  },
  "& legend": {
    width: "unset",
  },
}));

export const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

export const CustomSlider = styled(Slider)(() => ({
  "& .MuiSlider-rail": {
    height: "10px",
    backgroundColor: "#dfd1ff",
  },
  "& .MuiSlider-track": {
    height: "10px",
    backgroundColor: "#7c6d9e",
  },
  "& .MuiSlider-thumb": {
    backgroundColor: "#2f1b4f",
  },
}));

export const CustomButton = styled(LoadingButton)(() => ({
  backgroundColor: "#FF972A !important",
  color: "#fff",
  textTransform: "none !important",
  padding: "5px 50px !important",
  borderRadius: "8px !important",
  fontWeight: 500,
  margin: "10px auto !important",
}));
