import React, { useState } from "react";
import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { CustomButton } from "../BOMProcurement/BomOrderSummary";
import { axiosInstance } from "../utils/axiosInterceptor";
import { useSnackbar } from "notistack";

const AddPmModalContainer = styled(Box)(({ theme }) => ({
  width: "40vw",
  height: "fit-content",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#fff",
  outline: "none",
  borderRadius: 10,
  padding: "10px 20px 20px 20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  "& .headerText": {
    fontSize: "1.7em",
    fontWeight: 600,
    marginBottom: "30px",
  },
  "& form": {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    alignItems: "center",
  },

  [theme.breakpoints.down("md")]: {
    width: "50vw",
  },
  [theme.breakpoints.down("sm")]: {
    width: "80vw",
  },
}));

const AddPmModal = ({ state, toggleModal }) => {
  const [data, setData] = useState({
    email: "",
    password: "",
    fullname: "",
  });

  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(data);

    try {
      const res = await axiosInstance.post("/pm/signup", data);
      if (res.data?.success) {
        enqueueSnackbar("PM added successfully!", { variant: "success" });
        toggleModal();
        window.location.reload();
      } else {
        enqueueSnackbar("Something went wrong!", { variant: "error" });
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Something went wrong!", { variant: "error" });
    }
  };

  return (
    <Modal open={state} onClose={toggleModal}>
      <AddPmModalContainer>
        <Typography className="headerText">ADD NEW PM</Typography>
        <form onSubmit={handleSubmit}>
        <TextField
            name="fullname"
            value={data.fullname}
            onChange={handleChange}
            label="Full Name"
            type="text"
            fullWidth
            required
          />
          <TextField
            name="email"
            value={data.email}
            onChange={handleChange}
            label="Email"
            type="email"
            fullWidth
            required
          />
          <TextField
            name="password"
            value={data.password}
            onChange={handleChange}
            label="Password"
            type="password"
            fullWidth
            required
          />
          <CustomButton type="submit">ADD</CustomButton>
        </form>
      </AddPmModalContainer>
    </Modal>
  );
};

export default AddPmModal;
