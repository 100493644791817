import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { axiosInstance } from "../utils/axiosInterceptor";
import axios from "axios";
import { SnackbarProvider, useSnackbar } from "notistack";
import DashboardLogo1 from "../assests/elecbitsLogo.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@mui/material/Button";
import { Container, Row, Col, Modal } from "react-bootstrap";
import ReceiptIcon from "@mui/icons-material/Receipt";
import HomeIcon from "@mui/icons-material/Home";
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@mui/material/TextField";
import "./market.css";
import FormControl from "@material-ui/core/FormControl";
import clsx from "clsx";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import DeleteTwoToneIcon from "@material-ui/icons/DeleteTwoTone";
import { nanoid } from "nanoid";
import ReactDOM from "react-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Actions from "./AdminModals/Actions";

var createReactClass = require("create-react-class");

let finalBilling = {};
let nodes = {};

export default function DisplayOrdersAdmin() {
  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    rightIcon: {
      marginLeft: theme.spacing(1),
    },
    iconSmall: {
      fontSize: 20,
    },
    root: {
      padding: theme.spacing(1, 1),
      display: "flex",
    },
    root1: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
    margin: {
      margin: theme.spacing(1),
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 400,
    },
  }));

  const classes = useStyles();

  const [allOrders, setAllOrders] = useState(null);
  const [orderList, setOrderList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [shipAddressVisible, setShipAddressVisible] = useState(false);
  const [billingVisible, setBillingVisible] = useState(false);
  const [approveOrder, setApproveOrder] = useState(false);
  const [selectedShippingIdx, setSelectedShippingIdx] = useState(-1);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedApprove, setSelectedApprove] = useState([]);
  const [shipTime, setShipTime] = useState(null);
  const [totalBilling, setTotalBilling] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [editStatus, setEditStatus] = useState([]);

  function createData(
    orderId,
    createdAt,
    orderOwner,
    orderStatus,
    shippingAddress,
    orderItemsAndBilling,
    options
  ) {
    return {
      orderId,
      createdAt,
      orderOwner,
      orderStatus,
      shippingAddress,
      orderItemsAndBilling,
      options,
    };
  }
  const rows = [];
  const setRowsToRender = (orderData) => {
    console.log("ds");
    console.log(orderData);
    for (let i = 0; i < orderData.length; ++i) {
      let order = orderData[i];
      rows.push(
        createData(
          order._id,
          moment(order.createdAt).format("DD/MM/YYYY - HH:mm"),
          order.user,
          order.orderStatus,
          <IconButton
            onClick={() => {
              console.log(i);
              setSelectedShippingIdx(i);
              setApproveOrder(true);
            }}
            variant="contained"
            color="primary"
          >
            <HomeIcon></HomeIcon>
          </IconButton>,
          <Link target="_blank" to={`/pcbMarket/viewCart/${order.cart}`}>
            <IconButton variant="contained" color="primary" disabled>
              <ReceiptIcon></ReceiptIcon>
            </IconButton>
          </Link>,
          <Tooltip title="Approve Order">
            <IconButton
              disabled={order.orderStatus == "pending_approval" ? false : true}
              variant="contained"
              color="primary"
              onClick={() => {
                console.log("clicked Approved!", i);
                setSelectedApprove(i);
                // setApproveModal(true);
              }}
            >
              <ThumbUpIcon></ThumbUpIcon>
            </IconButton>
          </Tooltip>
        )
      );
    }
    setAllOrders(rows);
    setLoading(false);
  };

  const getAllOrder = () => {
    setLoading(true);
    axiosInstance
      .get("/products/getAllOrders")
      .then((resp) => {
        console.log("ds");
        setOrderList(resp.data.response);
        console.log(resp.data.response);
        setRowsToRender(resp.data.response);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllOrder();
  }, []);

  const handleClose = () => {
    // setApproveModal(false);
    setStatusModal(false);
    getAllOrder();
  };

  //console.log(selectedApprove);

  // const handleApprove = (evt) => {
  //   evt.preventDefault();
  // };

  // let handleDelete = (idx) => {
  //   for (let id in finalBilling) {
  //     nodes[id] = (
  //       <div className="form_el_cont" key={id}>
  //         <FormControl
  //           className={clsx(
  //             classes.margin,
  //             classes.withoutLabel,
  //             classes.textField
  //           )}
  //           style={{
  //             width: "55%",
  //           }}
  //         >
  //           <TextField
  //             label="Billing type"
  //             value={finalBilling[id]["type"]}
  //             variant="outlined"
  //           />
  //         </FormControl>
  //         <FormControl
  //           className={clsx(
  //             classes.margin,
  //             classes.withoutLabel,
  //             classes.textField
  //           )}
  //           style={{
  //             width: "35%",
  //           }}
  //           className={classes.margin}
  //           variant="outlined"
  //         >
  //           <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
  //           <OutlinedInput
  //             id="outlined-adornment-amount"
  //             value={finalBilling[id]["amount"]}
  //             startAdornment={
  //               <InputAdornment position="start">₹</InputAdornment>
  //             }
  //             labelWidth={60}
  //           ></OutlinedInput>
  //         </FormControl>

  //         <FormControl className={clsx(classes.margin)}>
  //           <IconButton
  //             onClick={() => {
  //               handleDelete(id);
  //             }}
  //           >
  //             <DeleteTwoToneIcon></DeleteTwoToneIcon>
  //           </IconButton>
  //         </FormControl>
  //       </div>
  //     );
  //   }

  //   delete nodes[idx];
  //   delete finalBilling[idx];
  //   var Fields = createReactClass({
  //     render: () => {
  //       // Change this to get the list from props or state
  //       let rNodes = [];
  //       for (let k in nodes) {
  //         rNodes.push(nodes[k]);
  //       }
  //       return <>{rNodes}</>;
  //     },
  //   });
  //   ReactDOM.render(<Fields />, document.getElementById("addedFields"));
  // };

  // let handleAdd = () => {
  //   for (let id in finalBilling) {
  //     nodes[id] = (
  //       <div className="form_el_cont" key={id}>
  //         <FormControl
  //           className={clsx(
  //             classes.margin,
  //             classes.withoutLabel,
  //             classes.textField
  //           )}
  //           style={{
  //             width: "55%",
  //           }}
  //         >
  //           <TextField
  //             label="Billing type"
  //             value={finalBilling[id]["type"]}
  //             variant="outlined"
  //           />
  //         </FormControl>
  //         <FormControl
  //           className={clsx(
  //             classes.margin,
  //             classes.withoutLabel,
  //             classes.textField
  //           )}
  //           style={{
  //             width: "35%",
  //           }}
  //           className={classes.margin}
  //           variant="outlined"
  //         >
  //           <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
  //           <OutlinedInput
  //             id="outlined-adornment-amount"
  //             value={finalBilling[id]["amount"]}
  //             startAdornment={
  //               <InputAdornment position="start">₹</InputAdornment>
  //             }
  //             labelWidth={60}
  //           ></OutlinedInput>
  //         </FormControl>

  //         <FormControl className={clsx(classes.margin)}>
  //           <IconButton
  //             onClick={() => {
  //               handleDelete(id);
  //             }}
  //           >
  //             <DeleteTwoToneIcon></DeleteTwoToneIcon>
  //           </IconButton>
  //         </FormControl>
  //       </div>
  //     );
  //   }
  //   let idx = nanoid(5);
  //   finalBilling[idx] = {};
  //   finalBilling[idx]["type"] = "";
  //   finalBilling[idx]["amount"] = "";

  //   nodes[idx] = (
  //     <div className="form_el_cont" key={idx}>
  //       <FormControl
  //         className={clsx(
  //           classes.margin,
  //           classes.withoutLabel,
  //           classes.textField
  //         )}
  //         style={{
  //           width: "55%",
  //         }}
  //       >
  //         <TextField
  //           label="Billing type"
  //           onChange={(e) => {
  //             finalBilling[idx]["type"] = e.target.value;

  //             console.log(finalBilling.hasOwnProperty(idx), finalBilling);
  //           }}
  //           variant="outlined"
  //         />
  //       </FormControl>
  //       <FormControl
  //         className={clsx(
  //           classes.margin,
  //           classes.withoutLabel,
  //           classes.textField
  //         )}
  //         style={{
  //           width: "35%",
  //         }}
  //         className={classes.margin}
  //         variant="outlined"
  //       >
  //         <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
  //         <OutlinedInput
  //           id="outlined-adornment-amount"
  //           onChange={(e) => {
  //             finalBilling[idx]["amount"] = e.target.value;
  //           }}
  //           startAdornment={<InputAdornment position="start">₹</InputAdornment>}
  //           labelWidth={60}
  //         ></OutlinedInput>
  //       </FormControl>

  //       <FormControl className={clsx(classes.margin)}>
  //         <IconButton
  //           onClick={() => {
  //             handleDelete(idx);
  //           }}
  //         >
  //           <DeleteTwoToneIcon></DeleteTwoToneIcon>
  //         </IconButton>
  //       </FormControl>
  //     </div>
  //   );

  //   var Fields = createReactClass({
  //     render: () => {
  //       // Change this to get the list from props or state
  //       let rNodes = [];
  //       for (let k in nodes) {
  //         rNodes.push(nodes[k]);
  //       }
  //       return <>{rNodes}</>;
  //     },
  //   });
  //   ReactDOM.render(<Fields />, document.getElementById("addedFields"));
  // };

  // Edit Status

  const handleStatusShow = (id) => {
    let modalItem = orderList;
    console.log(modalItem);

    let item = [];

    for (let i = 0; i < modalItem.length; ++i) {
      //console.log(modalItem[i]._id)

      if (modalItem[i]._id === id) {
        //console.log(modalItem[i])

        item = modalItem[i];

        setEditStatus(item);
        setSelectedApprove(item);
      }
    }

    setStatusModal(true);
  };

  // console.log(editStatus)
  // console.log(allOrders)
  // console.log(orderList) // shipping address, order status

  return (
    <>
      {!loading && allOrders && orderList ? (
        <Container>
          {/* Actions Modal */}

          <Actions
            statusModal={statusModal}
            handleClose={handleClose}
            editStatus={editStatus}
            shipTime={shipTime}
            setShipTime={setShipTime}
            totalBilling={totalBilling}
            setTotalBilling={setTotalBilling}
            finalBilling={finalBilling}
            allOrders={allOrders}
            selectedApprove={selectedApprove}
          />

          <Row>
            <Col id="all_ordrs">
              <h4>Elecbits PCB Arcade - All Orders</h4>
            </Col>
          </Row>
          <Row style={{ marginBottom: "20px", marginTop: "20px" }}>
            <Col>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Order Date</TableCell>
                      <TableCell align="center">Email</TableCell>
                      <TableCell align="center">Status</TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderList.map((row) => (
                      <TableRow
                        key={row.orderId}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {moment(row.createdAt).format("DD/MM/YYYY - HH:mm")}
                        </TableCell>
                        <TableCell align="center">{row?.user?.email}</TableCell>

                        <TableCell align="center">{row.orderStatus}</TableCell>

                        <TableCell align="right">
                          <IconButton
                            variant="contained"
                            color="primary"
                            onClick={() => handleStatusShow(row._id)}
                          >
                            <MoreVertIcon></MoreVertIcon>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Col>
          </Row>
        </Container>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <img src={DashboardLogo1} style={{ width: "150px" }}></img>
          <CircularProgress style={{ marginLeft: "20px" }}></CircularProgress>
        </div>
      )}
    </>
  );
}
