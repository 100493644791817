import React, { useState, useEffect, useRef } from "react";

//components
import CustomBreadcrumbs from "../../Components/CustomBreadcrumbs";
import DashboardHeader from "../../ClientEngagement/DashboardHeader";
import ConfirmModal from "../../Components/ComfirmModal";
import SupplierTimelineComponent from "../Supplier/SupplierTimelineComponent";
import QuotationStatus from "../../assests/quotationStatus.png";

import { supplierStages } from "../Supplier/Supplier.data";
import { ReactComponent as UploadIcon } from "../../assests/Upload.svg";
import { ReactComponent as DownloadIcon } from "../../assests/download.svg";
import { ReactComponent as DownloadCloudIcon } from "../../assests/downloadCloud.svg";
import { ReactComponent as BoardsIcon } from "../../assests/boards.svg";
import { ReactComponent as DescriptionIcon } from "../../assests/description.svg";
import { ReactComponent as TimeIcon } from "../../assests/time.svg";
import { ReactComponent as TimerIcon } from "../../assests/timer.svg";

//utils
import { axiosInstance } from "../../utils/axiosInterceptor";
import { Box, Grid, Typography, TextField, Button } from "@mui/material";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import ClosedProject from "../../Components/ClosedProject";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";

//styles
import {
  PageContainer,
  SupplierQuotationsContainer,
  CustomButton,
  BrowseButton,
} from "./SupplierQuotations.styles";

//assets
import { ReactComponent as SearchIcon } from "../../assests/search.svg";
// import { ReactComponent as DownloadIcon } from "../../assests/download.svg";

//assets
// import { ReactComponent as SearchIcon } from '../../assests/search.svg';
// import { ReactComponent as DownloadIcon } from '../../assests/download.svg';

const AdminFabQuoation = (props) => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState(null);
  const [isClose, setIsClose] = useState(false);
  const [quotation, setQuotation] = useState(null);
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const inputRef = useRef(null);
  const [adminBomFile, setAdminBomFile] = useState(null);

  const handleBrowseClick = () => {
    inputRef.current.click();
  };

  const handleFileChange = (e) => {
    const fileObj = e.target.files && e.target.files[0];

    e.target.value = null;
    console.log(fileObj, "here is the admin file");
    setAdminBomFile(fileObj);
  };

  const toggleConfirmModal = () => {
    setShowConfirmModal(!showConfirmModal);
  };

  const fetchProfile = () => {
    setLoading(true);
    axiosInstance
      .get("/users/getProfile")
      .then((resp) => {
        setProfile(resp.data);
        console.log(resp);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const fetchProject = async () => {
    try {
      const res = await axiosInstance.get(`/bom/admin/supplierQuote/${id}`);
      console.log(res);
      setProject(res.data.quote.project);
      setQuotation(res.data.quote);
      setIsClose(res.data?.quote?.project?.isClosed);
    } catch (err) {
      console.log(err);
    }
  };

  const getQuoteBackgroundColor = (quotation) => {
    if (quotation.isAccepted) {
      return "rgba(11, 165, 44, 0.149)";
    }
    return "rgba(139, 139, 139, 0.149)";
  };

  const handleAcceptQuotation = async () => {
    try {
      if (adminBomFile == null) {
        enqueueSnackbar("Please add a quotation file!", { variant: "error" });
        return;
      }
      let formdata = new FormData();
      formdata.append("bom", adminBomFile);
      const res = await axiosInstance.post(
        `/bom/admin/acceptQuotation/${id}`,
        formdata
      );
      console.log(res);
      enqueueSnackbar("Quotation accepted !", { variant: "success" });
      setQuotation({
        ...quotation,
        isAccepted: true,
      });
    } catch (err) {
      console.log(err);
      enqueueSnackbar("Something went wrong!", { variant: "error" });
    }
    toggleConfirmModal();
  };
  
  const handleName = (name, len) => {
    if (len === null) {
      len = 20;
    }
    if (name === null) {
      return name;
    }

    if (name?.length > len) {
      return name.substring(0, len) + "...";
    } else {
      return name;
    }
  };

  useEffect(() => {
    fetchProfile();
    fetchProject();
  }, []);

  return (
    <Box sx={isClose ? { pointerEvents: "none" } : {}}>
      {isClose && <ClosedProject />}
      {!loading && quotation ? (
        <PageContainer>
          <DashboardHeader profile_data={profile.data} isBlack={true} />
          <SupplierQuotationsContainer>
            <CustomBreadcrumbs
              data={[
                {
                  name: "Home",
                  link: "/",
                },
                {
                  name: "EB BOM Explorer",
                  link: "/admin/bomExplorer",
                },
                {
                  name: "Request for quotation",
                  link: `/admin/bom/suppliers/${quotation?.project?._id}`,
                },
                {
                  name: "Quotation",
                  link: `/admin/bom/suppliers/quotation/${id}`,
                },
              ]}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="title">Project ID: {quotation?.project?._id}</div>
            </Box>
            <input
              style={{ display: "none" }}
              ref={inputRef}
              type="file"
              onChange={handleFileChange}
            />
            <Box>
              <Typography sx={{ margin: "30px 0px 20px 0px" }}>
                Project Details 📃
              </Typography>
              <Box
                sx={{
                  border: "1px solid #0275FC",
                  borderRadius: "12px",
                  padding: "20px",
                  margin: "10px 0px",
                }}
              >
                <Grid container sx={{ marginBottom: "20px" }}>
                  <Grid item md={6}>
                    <Box
                      sx={{
                        display: "flex",
                        margin: "10px 10px 10px 0px",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#8C8C8C",
                          marginRight: "20px",
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        <BoardsIcon width="15px" height="15px" />
                        Number Of Boards:
                      </Typography>
                      <Typography>
                        {project.adminData?.numberOfBoards}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item md={6}>
                    <Box
                      sx={{
                        display: "flex",
                        margin: "10px 10px 10px 0px",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#8C8C8C",
                          marginRight: "20px",
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        <TimeIcon width="15px" height="15px" />
                        Project Live Since:
                      </Typography>
                      <Typography>
                        {" "}
                        {moment(project?.updationDate).format(
                          "MMMM Do YYYY, h:mm a"
                        ) || "no date"}
                      </Typography>
                    </Box>
                  </Grid>{" "}
                  <Grid item md={12}>
                    <Box
                      sx={{
                        display: "flex",
                        margin: "10px 10px 10px 0px",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#8C8C8C",
                          marginRight: "20px",
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        <DescriptionIcon width="15px" height="15px" />
                        Additional Description:
                      </Typography>
                      <Typography>
                        {project.adminData.additionalDescription || "--"}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                    alignItems: "center",
                    justifyContent: "space-between",
                    "& svg > path": {
                      fill: "white",
                    },
                  }}
                >
                  <a href={project.adminData.bomSheet.location}>
                    <CustomButton style={{ display: "flex", gap: "10px" }}>
                      <DownloadIcon width="15px" height="15px" /> DOWNLOAD BOM
                    </CustomButton>
                  </a>
                  <Box
                    sx={{
                      display: "flex",
                      marginTop: "0px",
                      gap: "10px",
                      alignItems: "center",
                      "& svg > path": {
                        fill: "#7D7D7D",
                      },
                    }}
                  >
                    <TimerIcon width="15px" height="15px" />
                    <Typography sx={{ color: "#7D7D7D" }}>
                      Last Bom File upload was on{" "}
                    </Typography>
                    <Typography sx={{ color: "#0275FC", fontWeight: 700 }}>
                      {moment(
                        project.adminData.bomSheet.uploadedOn ||
                          project.updationDate
                      ).format("MMMM Do YYYY, h:mm a") || "no date"}
                    </Typography>
                  </Box>
                  {/* <Box sx={{ display: "flex", marginTop: "0px", gap: "10px" }}>
                    <Typography sx={{ color: "#7D7D7D" }}>
                      Last Bom File upload was on{" "}
                    </Typography>
                    <Typography sx={{ color: "#0275FC", fontWeight: 700 }}>
                      {moment(
                        project.adminData.bomSheet.uploadedOn ||
                          project.updationDate
                      ).format("MMMM Do YYYY, h:mm a") || "no date"}
                    </Typography>
                  </Box> */}
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                marginTop: "30px",
                display: "flex",
                flexDirection: "row",
                gap: "30px",
                height: "fit-content",
              }}
            >
              <Box sx={{ flex: 2 }}>
                <Typography>Supplier Quote</Typography>
                {/* <form > */}
                <Box
                  sx={{
                    width: "100%",
                    height: "fit-content",
                    border: "3px solid #0275FC",
                    borderRadius: "12px",
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    padding: "20px",
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      sx={{ width: "20%", color: "#0702FC", fontWeight: 500 }}
                    >
                      Step 1:
                    </Typography>
                    <Box sx={{ flex: 1 }}>
                      <Typography>Download Quotation</Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <DownloadCloudIcon width="70px" height="70px" />
                    <Typography>
                      Download BOM file uploaded by supplier
                    </Typography>
                    <Typography
                      sx={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        color: "#7D7D7D",
                      }}
                    >
                      Latest BOM file{" "}
                      {handleName(
                        quotation?.quotation?.bomFile?.originalname,
                        24
                      )}{" "}
                      was updated at{" "}
                      {moment(quotation?.quotation?.bomFile?.uploadedOn).format(
                        "HH:mma, Do MMMM YYYY"
                      )}
                      <a href={quotation?.quotation?.bomFile?.location}>
                        <DownloadIcon width="15px" height="15px" />
                      </a>
                    </Typography>
                  </Box>

                  <Box sx={{ display: "flex", marginTop: "30px" }}>
                    <Typography
                      sx={{ width: "20%", color: "#0702FC", fontWeight: 500 }}
                    >
                      Step 2:
                    </Typography>
                    <Box sx={{ flex: 1 }}>
                      <Typography>Shipping Quotation</Typography>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          gap: "20px",
                          marginTop: "30px",
                        }}
                      >
                        <Box
                          sx={{
                            flex: 1,
                            display: "flex",
                            alignItems: "center",
                            gap: "20px",
                          }}
                        >
                          <Typography sx={{ width: "40%" }}>
                            Fast Shipping
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <TextField
                              variant="standard"
                              name="fashShippingMinTime"
                              onChange={() => {}}
                              disabled
                              value={
                                quotation.quotation.shippingCost.fast.minTime
                              }
                              sx={{
                                width: "30px",
                                "& input": { textAlign: "center" },
                              }}
                              required
                            />
                            <div>to</div>
                            <TextField
                              variant="standard"
                              name="fastShippingMaxTime"
                              onChange={() => {}}
                              disabled
                              value={
                                quotation.quotation.shippingCost.fast.maxTime
                              }
                              sx={{
                                width: "30px",
                                "& input": { textAlign: "center" },
                              }}
                              required
                            />
                            <Typography>days</Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            flex: 1,
                            display: "flex",
                            alignItems: "center",
                            gap: "20px",
                          }}
                        >
                          <Typography sx={{ width: "50%" }}>
                            Shipping cost:
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              gap: "10px",
                              alignItems: "baseline",
                            }}
                          >
                            <Typography>₹</Typography>
                            <TextField
                              variant="standard"
                              name="fastShippingCost"
                              value={quotation.quotation.shippingCost.fast.cost}
                              onChange={() => {}}
                              disabled
                              required
                              sx={{ width: "50%" }}
                            />
                          </Box>
                          {/* <TextField
                            variant="standard"
                            name="fastShippingCost"
                            value={quotation.quotation.shippingCost.fast.cost}
                            onChange={() => {}}
                            disabled
                            required
                            sx={{ width: "50%" }}
                          /> */}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          gap: "20px",
                          marginTop: "30px",
                        }}
                      >
                        <Box
                          sx={{
                            flex: 1,
                            display: "flex",
                            alignItems: "center",
                            gap: "20px",
                          }}
                        >
                          <Typography sx={{ width: "40%" }}>
                            Normal Shipping
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <TextField
                              variant="standard"
                              name="normalShippingMinTime"
                              onChange={() => {}}
                              disabled
                              value={
                                quotation.quotation.shippingCost.normal.minTime
                              }
                              sx={{
                                width: "30px",
                                "& input": { textAlign: "center" },
                              }}
                              required
                            />
                            <div>to</div>
                            <TextField
                              variant="standard"
                              name="normalShippingMaxTime"
                              onChange={() => {}}
                              disabled
                              value={
                                quotation.quotation.shippingCost.normal.maxTime
                              }
                              sx={{
                                width: "30px",
                                "& input": { textAlign: "center" },
                              }}
                              required
                            />
                            <Typography>days</Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            flex: 1,
                            display: "flex",
                            alignItems: "center",
                            gap: "20px",
                          }}
                        >
                          <Typography sx={{ width: "50%" }}>
                            Shipping cost:
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              gap: "10px",
                              alignItems: "baseline",
                            }}
                          >
                            <Typography>₹</Typography>
                            <TextField
                              variant="standard"
                              name="normalShippingCost"
                              value={
                                quotation.quotation.shippingCost.normal.cost
                              }
                              onChange={() => {}}
                              disabled
                              required
                              sx={{ width: "50%" }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      sx={{ width: "20%", color: "#0702FC", fontWeight: 500 }}
                    >
                      Step 3:
                    </Typography>
                    <Box sx={{ flex: 1 }}>
                      <Typography>Payment Terms</Typography>
                      <Box
                        sx={{
                          width: "100%",
                          marginTop: "30px",
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            alignItems: "end",
                            gap: "15px",
                          }}
                        >
                          <Typography sx={{ width: "25%" }}>
                            Advance Payment:
                          </Typography>
                          <TextField
                            variant="standard"
                            name="advance"
                            onChange={() => {}}
                            disabled
                            value={quotation.quotation.paymentTerms.advance}
                            sx={{
                              width: "30px",
                              "& input": { textAlign: "center" },
                            }}
                            required
                          />
                          <Typography>%</Typography>
                        </Box>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            alignItems: "end",
                            gap: "15px",
                            marginTop: "20px",
                          }}
                        >
                          <Typography sx={{ width: "25%" }}>
                            On-Delivery Payment:
                          </Typography>
                          <TextField
                            variant="standard"
                            name="minTime"
                            disabled
                            value={
                              100 - quotation.quotation.paymentTerms.advance
                            }
                            sx={{
                              width: "30px",
                              "& input": { textAlign: "center" },
                            }}
                            required
                          />
                          <Typography>%</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                {/* </form> */}
              </Box>
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  height: "auto",
                  flexDirection: "column",
                }}
              >
                <Typography>Quote Status</Typography>
                <Box
                  sx={{
                    width: "100%",
                    backgroundColor: " rgba(139, 139, 139, 0.1)",
                    flex: 1,
                    border: "3px solid #8B8B8B",
                    borderRadius: "12px",
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "20px",
                    padding: "20px",
                    backgroundColor: getQuoteBackgroundColor(quotation),
                  }}
                >
                  <img
                    src={QuotationStatus}
                    height="150px"
                    width="150px"
                    alt=""
                  />
                  <Typography
                    sx={{
                      fontSize: "1.2em",
                      textAlign: "center",
                      lineHeight: "18 0%",
                    }}
                  >
                    {quotation?.isAccepted
                      ? "Re-upload Accepted Quotation"
                      : "Upload Accepted Quotation"}
                  </Typography>
                  <UploadIcon width="50px" height="50px" />
                  <Typography>
                    {" "}
                    {quotation?.isAccepted
                      ? "Re-upload Accepted BOM File"
                      : "Upload Accepted BOM File"}
                  </Typography>
                  <BrowseButton onClick={handleBrowseClick}>
                    Browse Computer
                  </BrowseButton>
                  {quotation.isAccepted ? (
                    <Box
                      sx={{
                        width: "80%",
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                      }}
                    >
                      <Typography>
                        Latest BOM quotation was updated at{" "}
                        {moment(quotation?.adminBomFile?.uploadedOn).format(
                          "HH:mma, Do MMMM YYYY"
                        )}
                      </Typography>
                      <a href={quotation?.adminBomFile?.location}>
                        <DownloadIcon
                          style={{ cursor: "pointer" }}
                          width="30px"
                          height="30px"
                        />
                      </a>
                    </Box>
                  ) : null}
                  <Typography sx={{ fontSize: "0.8em", fontWeight: 500 }}>
                    {adminBomFile ? adminBomFile.name : ""}
                  </Typography>
                  <CustomButton
                    onClick={handleAcceptQuotation}
                    style={{ backgroundColor: "#FF972A" }}
                  >
                    ACCEPT BID ✅
                  </CustomButton>
                </Box>
              </Box>
            </Box>

            {/* <Box>
              <Typography sx={{ margin: "30px 0px 20px 0px" }}>
                Supplier Quote 📃
              </Typography>
              <Box
                sx={{
                  border: '1px solid #0275FC',
                  borderRadius: '12px',
                  padding: '20px',
                  margin: '10px 0px',
                }}
              >
                <form>
                  <Grid container sx={{ marginBottom: '0px' }}>
                    <Grid item md={6}>
                      <Box
                        sx={{
                          display: 'flex',
                          margin: '10px 10px 10px 0px',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          sx={{ color: '#8C8C8C', marginRight: '20px' }}
                        >
                          Lead time:
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                          }}
                        >
                          {`${quotation.quotation.leadTime.minTime} to ${quotation.quotation.leadTime.maxTime} days`}
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item md={6}>
                      <Box
                        sx={{
                          display: 'flex',
                          margin: '10px 10px 10px 0px',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          sx={{ color: '#8C8C8C', marginRight: '20px' }}
                        >
                          Shipping cost:
                        </Typography>
<<<<<<< HEAD
                        <Typography sx={{ marginRight: "10px" }}>₹</Typography>
=======
                        <Typography sx={{ marginRight: '10px' }}>₹</Typography>
>>>>>>> Aryan
                        {quotation.quotation.shippingCost}
                      </Box>
                    </Grid>

                    <Grid
                      item
                      md={12}
                      sx={{
                        display: 'flex',
                        marginTop: '20px',
                        gap: '20px',
                        alignItems: 'center',
                      }}
                    >
                      <a href={quotation.quotation.bomFile.location}>
                        <CustomButton>DOWNLOAD QUOTE</CustomButton>
                      </a>
                      <Box sx={{ display: 'flex', gap: '10px' }}>
                        <Typography sx={{ color: '#7D7D7D' }}>
                          Quotation was last submitted on{' '}
                        </Typography>
                        <Typography sx={{ color: '#0275FC', fontWeight: 700 }}>
                          {moment(quotation.createdOn).format(
                            'MMMM Do YYYY, h:mm a'
                          ) || 'no date'}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Box> */}
            <Box>
              <Typography sx={{ margin: "30px 0px 20px 0px" }}>
                Order Timeline 📈
              </Typography>
              {/* <Box
                sx={{
                  border: '1px solid #0275FC',
                  borderRadius: '12px',
                  padding: '20px',
                  margin: '10px 0px',
                }}
              >
                <Grid container sx={{ marginBottom: '20px' }}>
                  <Grid item md={6}>
                    <Box
                      sx={{
                        display: 'flex',
                        margin: '10px 10px 10px 0px',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{ color: '#8C8C8C', marginRight: '20px' }}
                      >
                        Number Of Boards:
                      </Typography>
                      <Typography>{project.numberOfBoards}</Typography>
                    </Box>
                  </Grid>
                  <Grid item md={6}>
                    <Box
                      sx={{
                        display: 'flex',
                        margin: '10px 10px 10px 0px',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{ color: '#8C8C8C', marginRight: '20px' }}
                      >
                        Project Created On:
                      </Typography>
                      <Typography>
                        {' '}
                        {moment(project?.updationDate).format(
                          'MMMM Do YYYY, h:mm a'
                        ) || 'no date'}
                      </Typography>
                    </Box>
                  </Grid>{' '}
                  <Grid item md={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        margin: '10px 10px 10px 0px',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{ color: '#8C8C8C', marginRight: '20px' }}
                      >
                        Additional Description:
                      </Typography>
                      <Typography>
                        {project.otherSpecification || '--'}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Box
                  sx={{ display: 'flex', gap: '20px', alignItems: 'center' }}
                >
                  <a href={project.bomSheet.location}>
                    <CustomButton>DOWNLOAD BOM</CustomButton>
                  </a>
                  <Box sx={{ display: 'flex', marginTop: '0px', gap: '10px' }}>
                    <Typography sx={{ color: '#7D7D7D' }}>
                      Last Bom File upload was on{' '}
                    </Typography>
                    <Typography sx={{ color: '#0275FC', fontWeight: 700 }}>
                      {moment(
                        project.bomSheet.uploadedOn || project.startDate
                      ).format('MMMM Do YYYY, h:mm a') || 'no date'}
                    </Typography>
                  </Box>
                </Box>
              </Box> */}
              <Box>
                <Timeline>
                  {supplierStages.map((stage, i) => (
                    <TimelineItem>
                      <TimelineOppositeContent
                        color="text.secondary"
                        style={{ flex: 0 }}
                      >
                        {quotation?.currentStatus.length || 1 >= i + 1 ? (
                          moment(
                            quotation?.currentStatus[i]?.completionDate
                          ).format("DD/MM/YYYY")
                        ) : (
                          <Typography sx={{ visibility: "hidden" }}>
                            {moment().format("DD/MM/YYYY")}
                          </Typography>
                        )}
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot />
                        {i != 5 ? <TimelineConnector /> : null}
                      </TimelineSeparator>
                      <TimelineContent>
                        <SupplierTimelineComponent
                          stage={stage}
                          i={i}
                          quotation={quotation}
                        />
                      </TimelineContent>
                    </TimelineItem>
                  ))}
                </Timeline>
              </Box>
            </Box>
          </SupplierQuotationsContainer>
        </PageContainer>
      ) : null}
    </Box>
  );
};

export default AdminFabQuoation;
