import React from "react";
import { Box, Button, Modal, styled } from "@mui/material";
import { ReactComponent as AlertSvg } from "../assests/alert.svg";

const ConfirmModalContainer = styled(Box)(({ theme }) => ({
  width: "30vw",
  height: "fit-content",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#fff",
  outline: "none",
  borderRadius: 10,
  padding: "10px 20px 20px 20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  //   '& .alert-svg':{
  //     '& path':{
  //         fill:'#c73737'
  //     }
  //   },

  "& .delete-text": {
    fontSize: "1.4em",
    fontWeight: 600,
    textAlign: "center",
  },
  "& .delete-text-sub": {
    fontSize: "0.9em",
    color: "#a3a3a3",
    textAlign: "center",
  },
  "& .btn-container": {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "30px",

    "& .MuiButton-root": {
      width: "48%",
      borderRadius: 5,
    },
  },
  "& .delete-btn": {
    backgroundColor: "#000",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#000",
    },
  },
  "& .cancel-btn": {
    backgroundColor: "#FF972A",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#FF972A",
    },
  },
  [theme.breakpoints.down("md")]: {
    width: "50vw",
  },
  [theme.breakpoints.down("sm")]: {
    width: "80vw",
  },
}));

const ConfirmModal = ({ state, onYes, onNo, toggleModal, text, sub }) => {
  return (
    <Modal open={state} onClose={toggleModal}>
      <ConfirmModalContainer>
        <AlertSvg width={150} height={150} className="alert-svg" />
        <div>
          <div className="delete-text">
            {text || "Do you really want to do this?"}
          </div>
          <div className="delete-text-sub">
            {sub || "Once action performed, cannot be undone"}
          </div>
        </div>
        <div className="btn-container">
          <Button className="cancel-btn" onClick={onNo}>
            NO
          </Button>
          <Button className="delete-btn" onClick={onYes}>
            YES
          </Button>
        </div>
      </ConfirmModalContainer>
    </Modal>
  );
};

export default ConfirmModal;
