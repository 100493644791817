import React, { useState, useEffect } from "react";
import { axiosInstance } from "../utils/axiosInterceptor";
import { Container, Row, Col } from "react-bootstrap";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { SnackbarProvider, useSnackbar } from "notistack";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import * as EmailValidator from "email-validator";
import AddIcon from '@mui/icons-material/Add';
import { set } from "date-fns";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));
export default function Collaborators(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [collaborator, setCollaborator] = useState(null);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [delLoading, setDelLoading] = useState(false);
  const [delOpen, setDelOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [delCollaborator, setDelCollaborator] = useState(null);
  const [emailAddress, setEmailAddress] = useState("");
  const [googleSignIn, setGoogleSignIn] = useState(false);
  const [isInviteActive,setInviteActive]=useState(false);
  const [inviteEmail,setInviteEmail]=useState('');

  let projectId = props.history.location.pathname.split("/")[2];
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelClickOpen = () => {
    setDelOpen(true);
  };

  const handleDelClose = () => {
    setDelOpen(false);
  };
  const handleSubmit = () => {
    console.log(emailAddress, googleSignIn);
    if (emailAddress && EmailValidator.validate(emailAddress)) {
      setInviteEmail(emailAddress);
      let projId = props.project_info._id;
      setLoading(true);
      axiosInstance
        .post(`/projects/addCollaboratorByMail/${projId}`, {
          email: emailAddress,
          googleUsed: googleSignIn,
        })
        .then((resp) => {
          enqueueSnackbar("Collaborator added successfully!", {
            variant: "success",
          });
          setOpen(false);
          setInviteActive(false);
          props.fetchProject(projectId);
          props.fetchProfile();
          setLoading(false);
        })
        .catch((err) => {
          enqueueSnackbar("No such user found!Invite him/her to elecbits", {
            variant: "error",
          });
          setInviteActive(true);
          setLoading(false);
        });
    } else {
      enqueueSnackbar("Please provide a valid email!", {
        variant: "error",
      });
    }
  };
  let displayFlag =
    props.profile_data.data._id == props.project_info.projectOwner._id;

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
        {loading ? (
          <div className={classes.root}>
            <LinearProgress></LinearProgress>
          </div>
        ) : (
          ""
        )}
        <DialogTitle>Add Collaborators</DialogTitle>
        <DialogContent style={{ minHeight: "30px" }}>
          <form>
            <TextField
              variant="outlined"
              value={emailAddress}
              onChange={(e) => {
                setEmailAddress(e.target.value);
              }}
              style={{ width: "100%", marginTop: "20px" }}
              label="Enter email address of the collaborator"
            ></TextField>
            <FormGroup style={{ width: "100%", marginTop: "20px" }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={googleSignIn}
                    value={googleSignIn}
                    onChange={(e) => {
                      setGoogleSignIn(!googleSignIn);
                    }}
                  />
                }
                label="Signed in with google?"
              />
            </FormGroup>
          </form>
          {
            (isInviteActive)?(
              <>
              <div className="invite_text">
                Invite {inviteEmail} to elecbits !
              </div>
              <Button onClick={()=>{
                setLoading(true);
                axiosInstance.post('/projects/sendInvite',{
                  email:inviteEmail,
                  projName:props.project_info.projectName,
                  inviter:props.profile_data.data.email,
                }).then((resp)=>{
                  enqueueSnackbar('Invite sent successfully',{variant:'success'});
                  setLoading(false);
                  setInviteActive(false);
                })
                .catch((err)=>{
                  setLoading(false);
                  enqueueSnackbar('Not able to send invite',{variant:'error'})
                })
              }} variant="contained">Invite <AddIcon></AddIcon></Button>
              </>
            ):('')
          }
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleSubmit}>
            Add
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={delOpen}
        onClose={handleDelClose}
        fullWidth={true}
        maxWidth="md"
      >
        {delLoading ? (
          <div className={classes.root}>
            <LinearProgress></LinearProgress>
          </div>
        ) : (
          ""
        )}
        <DialogTitle>List of Collaborators</DialogTitle>
        <DialogContent>
          <div className="list_wrapper">
            {props.project_info.projectCollab.map((usr) => {
              return (
                <div className="list_edit_item">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      alignContent: "center",
                    }}
                  >
                    <Avatar
                      src={usr.profilePic}
                      style={{ marginRight: "10px" }}
                    />
                    {"   "}
                    {!usr.googleId ? usr.email : `${usr.email} -- google`}{" "}
                  </div>
                  {displayFlag ? (
                    <IconButton
                      onClick={() => {
                        setConfirmOpen(true);
                        setDelCollaborator(usr._id);
                      }}
                    >
                      <DeleteIcon fontSize="medium"></DeleteIcon>
                    </IconButton>
                  ) : (
                    ""
                  )}
                </div>
              );
            })}
            {props.project_info.projectCollab &&
            props.project_info.projectCollab.length == 0 ? (
              <div className="list_edit_item">No Collaborators</div>
            ) : (
              ""
            )}
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={confirmOpen}
        onClose={() => {
          setConfirmOpen(false);
        }}
      >
        <DialogTitle>
          Are you sure you want to remove this collaborator?
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              setConfirmOpen(false);
              if (delCollaborator) {
                setDelLoading(true);
                axiosInstance
                  .post(
                    `/projects/removeCollaborator/${props.project_info._id}`,
                    {
                      collaborator: delCollaborator,
                    }
                  )
                  .then((resp) => {
                    setDelLoading(false);
                    setDelOpen(false);
                    projectId = props.history.location.pathname.split("/")[2];
                    props.fetchProject(projectId);
                    props.fetchProfile();
                    enqueueSnackbar("Successfully deleted the collaborator", {
                      variant: "success",
                    });
                  })
                  .catch((err) => {
                    setDelLoading(false);
                    enqueueSnackbar("Not able to delete the collaborator", {
                      variant: "error",
                    });
                  });
              } else {
                enqueueSnackbar("Some problem occured");
              }
            }}
          >
            Remove
          </Button>
          <Button
            onClick={() => {
              setConfirmOpen(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <div className="collaborators">
        <div className="proj_description_head">🤝 Collaborators</div>
        <div className="collaborator_wrapper">
          <AvatarGroup max={7}>
            {props.project_info.projectCollab.map((usr) => {
              return <Avatar src={usr.profilePic} />;
            })}
          </AvatarGroup>
          {displayFlag ? (
            <Tooltip title="Add Collaborators">
              <IconButton aria-label="delete" onClick={handleClickOpen}>
                <AddCircleIcon fontSize="large"></AddCircleIcon>
              </IconButton>
            </Tooltip>
          ) : (
            ""
          )}

          <Tooltip
            title={
              displayFlag ? "View and edit Collaborators" : "View Collaborators"
            }
          >
            <IconButton aria-label="delete" onClick={handleDelClickOpen}>
              <ModeEditIcon fontSize="large"></ModeEditIcon>
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </>
  );
}
