import React, { useState, useEffect } from "react";

//libs
import {
  Modal,
  Box,
  styled,
  Button,
  Slide,
  Typography,
  TextField,
  Grid,
  Select,
  FormControl,
  OutlinedInput,
  InputLabel,
  Chip,
  useTheme,
} from "@mui/material";
import { MenuItem } from "@material-ui/core";
import moment from "moment";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { useSnackbar } from "notistack";
import { axiosInstance } from "../../utils/axiosInterceptor";
import { CustomButton } from "../../BOMProcurement/ProjectTimeline/BomTImeline.styles";

const ModalContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: "60vw",
  maxHeight: "70vh",
  overflow: "hidden",
  overflowY: "scroll",
  left: "20vw",
  top: "12vh",
  backgroundColor: "#fff",
  boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.2)",
  borderRadius: 15,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "30px",
  gap: "20px",
  "& .heading": {
    fontSize: "1.2em",
    fontWeight: 700,
  },
  [theme.breakpoints.down("md")]: {
    width: "90vw",
    left: "5vw",
  },
}));

const InternalNameField = ({ data, label, type, id, isAdmin }) => {
  const [internalState, setInternalState] = useState({
    editMode: false,
    data: data || "default",
  });
  const { enqueueSnackbar } = useSnackbar();

  const handleSaveInternalState = async () => {
    console.log(internalState);

    try {
      const res = await axiosInstance.put(
        `/3d/admin/editProject/${type}/${id}`,
        {
          [label]: internalState.data,
        }
      );
      console.log(res);
      if (res.status === 200) {
        enqueueSnackbar("Project Updated Successfully!", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Something went wrong", { variant: "error" });
      }
    } catch (err) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
    setInternalState({
      ...internalState,
      editMode: false,
    });
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", marginRight: "-30px" }}>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          "& .MuiInput-input.Mui-disabled": {
            color: "#000",
            textFillColor: "#000",
          },
        }}
      >
        <TextField
          disabled={!internalState.editMode}
          variant="standard"
          sx={{
            width: "100%",
            "& input": { textAlign: "start", paddingRight: "10px" },
          }}
          value={internalState.data}
          InputProps={{ disableUnderline: !internalState.editMode }}
          onChange={(e) => {
            setInternalState({
              ...internalState,
              data: e.target.value,
            });
          }}
        />{" "}
      </Box>

      {isAdmin ? (
        <>
          {!internalState.editMode ? (
            <EditOutlinedIcon
              sx={{ cursor: "pointer", transform: "scale(0.9)" }}
              onClick={() => {
                setInternalState({
                  ...internalState,
                  editMode: !internalState.editMode,
                });
              }}
            />
          ) : (
            <SaveOutlinedIcon
              sx={{ cursor: "pointer", transform: "scale(0.9)" }}
              onClick={handleSaveInternalState}
            />
          )}
        </>
      ) : null}
    </Box>
  );
};

function getStyles(val, data, theme) {
  return {
    fontWeight:
      data.indexOf(val) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MultiSelect = ({ options, label, data1, editData, setEditData }) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [internalState, setInternalState] = useState({
    data: data1 || [],
  });
  const handleSaveInternalState = async (e) => {
    console.log(internalState);
  };
  const handleChange = (e) => {
    console.log(e.target.value);
    setInternalState({
      ...internalState,
      data:
        typeof e.target.value === "string"
          ? e.target.value.split(",")
          : e.target.value,
    });
    setEditData({
      ...editData,
      [label]:
        typeof e.target.value === "string"
          ? e.target.value.split(",")
          : e.target.value,
    });
  };
  return (
    <Box
      sx={{
        minWidth: 120,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",
      }}
    >
      <FormControl variant="standard" fullWidth sx={{ border: "none" }}>
        <InputLabel id="demo-multiple-chip-label">Chip</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={internalState.data}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          // MenuProps={MenuProps}
        >
          {options.map((opt) => (
            <MenuItem
              key={opt}
              value={opt}
              style={getStyles(opt, internalState.data, theme)}
            >
              {opt}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <></>
    </Box>
  );
};

const SingleSelect = ({ options, label, data, editData, setEditData }) => {
  const [val, setval] = useState(data || "no value");
  const handleChange = async (e) => {
    setval(e.target.value);
    setEditData({ ...editData, [label]: e.target.value });
  };
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl variant="standard" fullWidth>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={val}
          label={label}
          onChange={handleChange}
        >
          {options.map((opt) => (
            <MenuItem value={opt}>{opt}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
const PMselect = ({ options, label, data, editData, setEditData }) => {
  console.log(data, "bruh how this working");
  const [val, setval] = useState(data || "no value");
  const handleChange = async (e) => {
    setval(e.target.value);
    setEditData({ ...editData, [label]: e.target.value });
  };
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl variant="standard" fullWidth>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={val}
          label={label}
          onChange={handleChange}
        >
          {options.map((opt) => (
            <MenuItem value={opt.id}>
              <Box>
                <Typography>{opt.fullName}</Typography>
                <Typography sx={{ fontSize: "0.7em", color: "#989898" }}>
                  {opt.email}
                </Typography>
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

const ColosseumProjectDetailsModal = ({
  state,
  toggleModal,
  project,
  type,
  isAdmin,
  user,
}) => {
    console.log("userrr: ",user);
    console.log("projecttt: ",project);
    user = {
        data: project.projectOwner
    };
  const [data, setData] = useState(null);
  const [projectTrackingState, setProjectTrackingState] = useState({});
  const [editData, setEditData] = useState({});
  const [allPms, setAllPms] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const handleEditChange = async () => {
    try {
      const res = await axiosInstance.post(
        `/3d/admin/editProjectTracking/${project._id}/${user.data._id}`,
        {
          ...editData,
          type,
        }
      );
      console.log(res);

      enqueueSnackbar("Project Updated Successfully!", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  const getAllPms = async () => {
    try {
      const res = await axiosInstance.get("/pm/getAllPMs");
      const cleanData = res.data.data.map((pm) => {
        return {
          fullName: pm?.fullName || `${pm?.firstName} ${pm?.lastName}` || "NaN",
          email: pm.email,
          id: pm._id,
        };
      });
      setAllPms(cleanData);
    } catch (e) {
      console.log(e);
    }
  };

  const generateData = (type, project) => {
    let data;
    switch (type) {
      case "3dprinting":
        data = {
          ID: project.projectId,
          "Internal Name": (
            <InternalNameField
              data={project.internalName}
              label="internalName"
              id={project._id}
              type={type}
              isAdmin={isAdmin}
            />
          ),
          "Created on":
            moment(project?.createdOn).format("MMMM Do YYYY, h:mm a") ||
            "no date",

          Quantity: (
            <InternalNameField
              data={project.projectDetails.quantity}
              label="quantity"
              id={project._id}
              type={type}
              isAdmin={isAdmin}
            />
          ),
          "Project Name": project.projectName,
          Service: "3D Printing",
          "Printing Service": project.projectDetails.printingService,
          Material: project.projectDetails.material,
          Colour:
            project.projectDetails.colour.toUpperCase()[0] +
            project.projectDetails.colour.slice(1),
        };
        if (project.projectDetails.printingService === "FDM") {
          data = {
            ...data,
            "Print Quality": project.projectDetails.printQuality,
          };
        } else {
          data = {
            ...data,
            "Surface Finish": project.projectDetails.surfaceFinish,
          };
        }
        data = {
          ...data,
          "Other Specification": project.projectDetails.otherSpecification,
        };
        return data;
      case "injectionMolding":
        data = {
          ID: project.projectId,
          "Internal Name": (
            <InternalNameField
              data={project.internalName}
              label="internalName"
              id={project._id}
              type={type}
              isAdmin={isAdmin}
            />
          ),
          "Created on":
            moment(project?.createdOn).format("MMMM Do YYYY, h:mm a") ||
            "no date",

          Quantity: (
            <InternalNameField
              data={project.projectDetails.quantity}
              label="quantity"
              id={project._id}
              type={type}
              isAdmin={isAdmin}
            />
          ),
          "Project Name": project.projectName,
          Service: "Injection Molding",
          Mold: project.projectDetails.mold,
          "Resin Material": project.projectDetails.resinMaterial,
          Colour:
            project.projectDetails.colour.toUpperCase()[0] +
            project.projectDetails.colour.slice(1),
          "Other Specification": project.projectDetails.otherSpecification,
        };

        return data;

      case "prebuiltCase":
        data = {
          ID: project.projectId,
          "Internal Name": (
            <InternalNameField
              data={project.internalName}
              label="internalName"
              id={project._id}
              type={type}
              isAdmin={isAdmin}
            />
          ),
          "Created on":
            moment(project?.createdOn).format("MMMM Do YYYY, h:mm a") ||
            "no date",
          "Project Name": project.projectName,
          Service: "Prebuilt Case",
          "Outer Dimensions": project.projectDetails.outerDimensions,
          Colour:
            project.projectDetails.colour.toUpperCase()[0] +
            project.projectDetails.colour.slice(1),
          "Enclosure Application": project.projectDetails.enclosureApplication,
          "Other Specification": project.projectDetails.otherSpecification,
        };
        return data;
        break;
    }
  };

  const generateProjectTrackingData = (project, user) => {
    console.log(user, "hereee");
    let ProjectTrackingDetails = {
      "Client ratings": (
        <SingleSelect
          options={[1, 2, 3, 4, 5]}
          label="rating"
          editData={editData}
          setEditData={setEditData}
          data={user.data.clientDetails?.rating}
        />
      ),
      Priority: (
        <SingleSelect
          options={["Low", "Medium", "High", "Very High"]}
          label="priority"
          editData={editData}
          setEditData={setEditData}
          data={project.projectDetails?.priority}
        />
      ),
      "Organization Size": (
        <SingleSelect
          options={["Mid-Size-Enterprise", "Startup", "Large Enterprise"]}
          label="organizationSize"
          editData={editData}
          setEditData={setEditData}
          data={user.data.clientDetails?.organizationSize}
        />
      ),
      "Mode of Communication": (
        <MultiSelect
          label="modeOfCommunication"
          options={["WhatsApp", "Mail", "Platform"]}
          id={project._id}
          userId={user.data._id}
          data1={project.projectDetails?.modeOfCommunication}
          editData={editData}
          setEditData={setEditData}
        />
      ),
      "Project Manager": (
        <PMselect
          data={project.projectDetails?.projectManager?._id}
          label="projectManager"
          editData={editData}
          setEditData={setEditData}
          isAdmin={isAdmin}
          options={allPms}
        />
      ),
      "Backup PM": (
        <PMselect
          data={project.projectDetails?.backUpPM}
          label="backUpPM"
          editData={editData}
          setEditData={setEditData}
          isAdmin={isAdmin}
          options={allPms}
        />
      ),
    };
    return ProjectTrackingDetails;
  };

  useEffect(() => {
    if (type && project) {
      const d = generateData(type, project);
      setData(d);
    }
  }, [type, project]);

  useEffect(() => {
    setProjectTrackingState(generateProjectTrackingData(project, user));
  }, [project, user, editData]);

  useEffect(() => {
    getAllPms();
  }, []);

  return (
    <Modal open={state} onClose={toggleModal}>
      <Slide in={state} direction="up">
        {project ? (
          <ModalContainer>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "baseline",
              }}
            >
              <Typography
                sx={{ fontWeight: "bold", fontSize: "1.5rem", margin: "1rem" }}
              >
                Project Details
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                marginTop: "20px",
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {data !== null &&
                Object.keys(data).map((key) => (
                  <Grid container sx={{ width: "50%", marginBottom: "10px" }}>
                    <Grid item md={6}>
                      <Typography sx={{ color: "#8B8B8B" }}>{key}:</Typography>
                    </Grid>
                    <Grid item md={6}>
                      {data[key]}
                    </Grid>
                  </Grid>
                ))}
            </Box>
            {isAdmin && (
              <>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                    margin: "1.5rem",
                  }}
                >
                  Project Tracking Details
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    flexWrap: "wrap",
                  }}
                >
                  {projectTrackingState != null &&
                    Object.keys(projectTrackingState).map((key) => (
                      <Box
                        sx={{
                          width: "45%",
                          display: "flex",
                          flexDirection: "row",
                          margin: "1rem 1rem",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography sx={{ width: "40%" }}>{key}: </Typography>
                        <Box
                          sx={{
                            borderBottom: "1px solid black",
                            width: "60%",
                          }}
                        >
                          {projectTrackingState[key]}
                        </Box>
                      </Box>
                    ))}
                </Box>
              </>
            )}
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
                marginTop: "1rem",
              }}
            >
              {isAdmin && (
                <CustomButton onClick={handleEditChange} fill>
                  Submit
                </CustomButton>
              )}
            </Box>
          </ModalContainer>
        ) : null}
      </Slide>
    </Modal>
  );
};

export default ColosseumProjectDetailsModal;
