import React, { useState, useEffect } from 'react';
import DashboardLogo from '../assests/dashboardLogo.svg';
import DashboardLogo1 from '../assests/elecbitsLogo.svg';
import { axiosInstance } from '../utils/axiosInterceptor';
import './dashboard.css';
import AdminDashboardHeader from './AdminDashboardHeader';
import CircularProgress from '@material-ui/core/CircularProgress';
import AdminStats from './AdminStats';
import AdminTable from './AdminTable';
import moment from 'moment';
import EditProject from './EditProject';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import ProjectCreate from './ProjectCreate.js';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@mui/material/Tooltip';
import ViewCollaborators from './ViewCollaborators';

let format = 'MMMM Do YYYY, h:mm:ss a';
export default function AdminDashboard(props) {
  const [loading, set_loading] = useState(true);
  const [profile_data, set_profile] = useState(null);
  const [project_data, set_project] = useState(null);
  const [allUsers, set_users] = useState(null);
  const [dataUserWise, setUserWise] = useState(null);
  const [rowsRender, setRows] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    console.log(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let fetchDataUserWise = () => {
    set_loading(true);
    axiosInstance
      .get('/projects/getUserWise')
      .then((resp) => {
        set_loading(false);
        setUserWise(resp.data.data);
      })
      .catch((err) => {
        set_loading(false);
      });
  };
  let fetchUsers = () => {
    set_loading(true);
    axiosInstance
      .get('/users/getRegistered')
      .then((users) => {
        set_loading(false);
        set_users(users);
      })
      .catch((err) => {
        set_loading(false);
      });
  };

  let fetchProfile = () => {
    set_loading(true);
    axiosInstance
      .get('/users/getProfile')
      .then((resp) => {
        set_profile(resp.data);
        set_loading(false);
      })
      .catch((err) => {
        set_loading(false);
      });
  };

  let fetchProjectData = () => {
    set_loading(true);
    axiosInstance
      .get('/projects/getProjects')
      .then((projects) => {
        set_project(projects.data);
        set_loading(false);
      })
      .catch((err) => {
        set_loading(false);
      });
  };

  useEffect(() => {
    fetchProfile();
    fetchProjectData();
    fetchUsers();
  }, []);

  let rowsProcess = () => {
    let createData = (
      client_name,
      collaborators,
      project_name,
      current_step,
      due_date,
      options
    ) => {
      return {
        client_name,
        collaborators,
        project_name,
        current_step,
        due_date,
        options,
      };
    };

    console.log(project_data);
    let rows = [];
    if (project_data) {
      let idx = 0;
      let n = project_data.data.length;
      while (idx < n) {
        let active_project = project_data.data[idx];
        let active_stage;

        let stages = [];
        if (active_project) {
          stages = active_project.stageInfo;
          for (let i = 0; i < stages.length; ++i) {
            if (stages[i].status == 'active') {
              active_stage = stages[i];
              break;
            }
          }
        }

        rows.push(
          createData(
            active_project.projectOwner
              ? !active_project.projectOwner.googleId
                ? active_project.projectOwner.email
                : `${active_project.projectOwner.email}--Google`
              : 'NA',
            <ViewCollaborators
              fetchProfile={fetchProfile}
              fetchProjectData={fetchProjectData}
              allUsers={allUsers}
              projectId={active_project._id}
              collaborators={active_project.projectCollab}
            ></ViewCollaborators>,
            active_project ? active_project.projectName : 'NA',
            active_stage ? active_stage.stageName : 'No active stage',
            active_stage
              ? moment(active_stage.endDate, 'YYYY-MM-DDTHH:mm:ss').format(
                  format
                )
              : 'NA',
            <EditProject
              project={active_project}
              fetchProjectData={fetchProjectData}
            ></EditProject>
          )
        );
        idx++;
      }
      console.log(rows);
      setRows(rows);
    }
  };

  useEffect(() => {
    rowsProcess();
  }, [project_data]);

  return (
    <div className="_wrap">
      {!loading && profile_data && project_data ? (
        <div className="dashboard_wrapper1">
          <div className="dashboard_wrapper">
            {/* <div className="sider_menu">
            <img src={DashboardLogo}></img>
          </div> */}
            <div className="right_dashboard_content admin">
              <div className="header">
                <AdminDashboardHeader
                  profile_data={profile_data.data}
                  toggleLoggedIn={props.toggleLoggedIn}
                  history={props.history}
                  isBlack
                ></AdminDashboardHeader>
              </div>
              {/* <div>hi</div> */}
              <div className="admin_main_body">
                <AdminStats
                  allUsers={allUsers}
                  project_data={project_data}
                ></AdminStats>

                <AdminTable
                  rowsToRender={rowsRender}
                  fetchDataUserWise={fetchDataUserWise}
                  fetchUsers={fetchUsers}
                  fetchProjectData={fetchProjectData}
                  dataUserWise={dataUserWise}
                  project_data={project_data}
                  allUsers={allUsers}
                  rowsProcess={rowsProcess}
                ></AdminTable>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <img src={DashboardLogo1} style={{ width: '150px' }}></img>
          <CircularProgress style={{ marginLeft: '20px' }}></CircularProgress>
        </>
      )}
    </div>
  );
}
