import React, { useState, useEffect } from "react";
import { axiosInstance } from "../utils/axiosInterceptor";
import DashboardLogo1 from "../assests/elecbitsLogo.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./ViewProduct.css";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import ClampLines from "react-clamp-lines";
import Tooltip from "@material-ui/core/Tooltip";
import CurrencyFormat from "react-currency-format";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";

export default function ViewProdcuts(props) {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState();
  const [cartLength, setCartLength] = useState(0);
  const getCartProducts = () => {
    axiosInstance
      .get("/products/getCartProducts")
      .then((prods) => console.log(prods.data?.response[0]?.products?.length));
  };
  useEffect(() => {
    getCartProducts();
  }, [cartLength]);

  const getProducts = () => {
    setLoading(true);
    axiosInstance
      .get("/products/getProducts")
      .then((resp) => {
        setLoading(false);
        setProducts(resp.data.response);
        console.log(resp.data.response);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(value);

  useEffect(() => {
    getProducts();
  }, []);
  console.log("dsa");
  return (
    <div>
      {!loading && products ? (
        <Container className="main_prod_wrap">
          <Row style={{ marginBottom: "20px" }}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link to="/">Home</Link>
              <Typography color="text.primary">PCB Market</Typography>
            </Breadcrumbs>
          </Row>
          <Row>
            {products.map((product) => {
              let amt;
              let amount_obj = product["quantity_wise_pricing"][0];
              for (let key in amount_obj) {
                amt = amount_obj[key]["amount"];
                break;
              }
              return (
                <Col md={3} sm={12}>
                  <Link to={`/pcbMarket/productView/${product._id}`}>
                    <div className="prod_card">
                      <div className="prod_card_img">
                        <img src={product.product_images[0].location}></img>
                      </div>
                      <div className="prod_details">
                        <div className="prod_name">{product.short_name}</div>
                        <Tooltip title={product.sku_name} placement="top">
                          <div className="prod_sku">
                            <ClampLines
                              text={product.sku_name}
                              id="really-unique-id"
                              lines={1}
                              ellipsis="..."
                              className="custom-class"
                              innerElement="p"
                              buttons={false}
                            />
                          </div>
                        </Tooltip>
                        <div className="prod_price">{numberFormat(amt)}</div>
                      </div>
                    </div>
                  </Link>
                </Col>
              );
            })}
          </Row>
        </Container>
      ) : (
        <div className="time_wrap">
          <img src={DashboardLogo1} style={{ width: "150px" }}></img>
          <CircularProgress style={{ marginLeft: "20px" }}></CircularProgress>
        </div>
      )}
    </div>
  );
}
