import { Box } from '@mui/system';
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    console.log(payload, 'payloaddd');
    return (
      <Box
        sx={{
          backgroundColor: 'white',
          borderRadius: '5px',
          padding: '8px',
          boxShadow: '2px 2px 10px #D3D3D3',
        }}
        className="custom-tooltip"
      >
        <b>{payload[0].value}</b> Vendors in{' '}
        {payload[0].payload.name || payload[0].payload.service}
      </Box>
    );
  }
  return null;
};

export default CustomTooltip;
