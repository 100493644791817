import React, { useState, useEffect } from "react";

//libs and utils
import { Box, Typography, styled } from "@mui/material";
import { ReactComponent as CompleteIcon } from "../assests/complete.svg";

const StyledInput = styled("input")(() => ({
  width: "120px",
  border: "2px solid #E8E8E8",
  outline: "none",
  borderRadius: "10px",
  padding: "5px",
  fontSize: "0.8em",
  color: "#2F2F2F",
  "&:focus": {
    outline: "none",
  },
}));

const CustomColorPicker = ({
  colors,
  disabled,
  value,
  onChange,
  enableTextField,
}) => {
  const [selectedColor, setSelectedColor] = useState(value || null);

  const handleClick = (c) => {
    if (!disabled) {
      setSelectedColor(c.value);
      onChange(c.name);
    }
  };

  const handleChange = (e) => {
    if (enableTextField) {
      setSelectedColor(null);
      onChange(e.target.value);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "20px",
        backgroundColor: disabled ? "#d8d8d8" : "#fff",
        padding: "10px",
        borderRadius: "10px",
        // boxShadow: "0px 0px 30px rgba(139, 139, 139, 0.2)",
        border: "2px solid #8B8B8B4D",
      }}
    >
      {colors.length > 0
        ? colors.map((c) => (
            <Box
              sx={{
                height: 25,
                width: 25,
                borderRadius: "100%",
                backgroundColor: disabled ? "transparent" : c.value,
                border: !disabled && "1px solid #0702FC26",
                backgroundImage:
                  c.value === "transparent" && !disabled
                    ? `url('https://t3.ftcdn.net/jpg/03/76/74/78/360_F_376747823_L8il80K6c2CM1lnPYJhhJZQNl6ynX1yj.jpg')`
                    : "",
                display: "grid",
                placeItems: "center",
                "& svg": {
                  transform: "scale(0.6)",
                },
                "& path": {
                  fill:
                    selectedColor === "#FFFFFF" ||
                    selectedColor === "transparent"
                      ? "black"
                      : "white",
                },
              }}
              onClick={() => {
                handleClick(c);
              }}
            >
              {selectedColor === c.value ? <CompleteIcon /> : null}
            </Box>
          ))
        : null}
      {enableTextField ? (
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Typography sx={{ color: "#2F2F2F" }}>Any other colour:</Typography>
          <StyledInput onChange={handleChange} placeholder="eg. yellow" />
        </Box>
      ) : null}
    </Box>
  );
};

export default CustomColorPicker;
