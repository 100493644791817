import logo from './logo.svg';
import './App.css';
import React, { Component } from 'react';
import { render } from '@testing-library/react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from 'react-router-dom';
import Login from './ProfileComponents/LoginComponent/Login';
import SignUp from './ProfileComponents/SignUpComponent/SignUp';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { axiosInstance } from '../src/utils/axiosInterceptor';
import ClientDashboard from './ClientEngagement/ClientDashboard';
import AdminDashboard from './ClientEngagement/AdminDashboard';
import SupplierDashboard from './SupplierEngagement/SupplierDashboard';
import ProfileView from './ProfileComponents/ProfileView/ProfileView';
import AdminTimeLine_view from './ClientEngagement/AdminTimeLine_view';
import CircularProgress from '@material-ui/core/CircularProgress';
import DashboardLogo1 from './assests/elecbitsLogo.svg';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/moment';
import EditBilling from './ClientEngagement/EditBilling';
import HomePage from './HomeComponent/HomePage';
import ServiceView from './Components/ServiceView.js';
import AdminPcbMarket from './PCBMarket/AdminPCBMarket';
import SupplierLogin from './ProfileComponents/LoginComponent/LoginSupplier';
import SupplierSignup from './ProfileComponents/SignUpComponent/SupplierSignup';
import SupplierOnBoarding from './Supplier/SupplierOnBoarding';
import CustomerOnBoarding from './Customer/CustomerOnBoarding';
import Directional from './ProfileComponents/ProfileView/Directional';
import BomExplorerAdmin from './Admin/BomExplorer';
import BomExplorerDetail from './Admin/BomExplorerDetail';
// import BomExplorerTimeLine from "./Admin/BomExplorerTimeline";
import BomExplorerTimeLine from './BOMProcurement/ProjectTimeline/BomTImeline';
import AdminLogin from './Admin/AdminLogin';
import AdminHomePage from './Admin/AdminHomepage';
import BomQuotations from "./BOMProcurement/Quotations";
// import BomQuotations from './BOMProcurement/Quotations/BomQuotations.component';

// import BomQuotations from "./BOMProcurement/Quotations/BomQuotations.component";
// import PcbFabQuotations from "./PCBFabricator/Quotations";
import PcbFabQuotations from './PCBFabricator/Quotations/PcbQuotations.component';
// import BomExplorer from "./BOMProcurement/BomExplorer";
import BomExplorer from './BOMProcurement/BomExplorer/BomExplorer.component';
import CreateBOMProject from './BOMProcurement/CreateNewProject/CreateBomProject';

import FabricatorExplorer from './PCBFabricator/FabExplorer/FabExplorer.component';
// import FABNewProject from "./PCBFabricator/FABNewProject";
import FABNewProject from './PCBFabricator/CreateNewProject/CreateFabProject';
// import FABTimeline from "./PCBFabricator/FABTimeline";
import FABTimeline from './PCBFabricator/ProjectTimeline/FabTimeline';
import FABAllProjects from './PCBFabricator/FABAllProjects';
import FABDetails from './PCBFabricator/FABDetails';
// import FABNewProjectAdmin from "./PCBFabricator/FabNewProjectAdmin";
import FabNewProjectAdmin from './PCBFabricator/FabNewProjectAdmin';
import FAbSupplierQuoatations from './PCBFabricator/AdminPages/FabQuotations';
import AllUsers from './Admin/AllUsers';
import ForgotPassword from './ProfileComponents/ForgotPasswordComponents/ForgotPassword';
import ResetPassword from './ProfileComponents/ForgotPasswordComponents/ResetPassword';
import ColosseumExplorer from './3dColosseum/ColosseumExplorer/ColsseumExplorer.component';
import ColosseumServices from './3dColosseum/ColosseumServices/ColosseumServices.component';
import Create3dPrintingProject from './3dColosseum/CreateNewProject/Create3dPrintingProject';
import CreateInjectionMoldingProject from './3dColosseum/CreateNewProject/CreateInjectionMoldingProject';
import CreatePrebuiltCasesProject from './3dColosseum/CreateNewProject/CreaatePrebuiltCasesProject';
import ColosseumProjects from './3dColosseum/ColossuemProjects/ColosseumProjects.component';
import ColosseumTimeline from './3dColosseum/ProjectTimeline/ColosseumTimeline';
import AdminCollesumProjects from './3dColosseum/AdminPages/AdminColosseumProjects';
import ColosseumQuotations from './3dColosseum/Quotations/ColosseumQuotations.component';

import SupplierFabProjects from './PCBFabricator/Suppliers/SupplierFabProjects';
import SupplierFabQuotation from './PCBFabricator/Suppliers/SupplierFabQuotation';
import AdminFabQuoation from './PCBFabricator/AdminPages/AdminFabQuotation';

import AdminBomQuotation from './BOMProcurement/AdminPages/AdminBomQuotation';
import BomSupplierQuotations from './BOMProcurement/AdminPages/BomQuotations';
import SupplierBomQuotation from './BOMProcurement/Supplier/SupplierBomQuotation';
import SupplierBomProjects from './BOMProcurement/Supplier/SupplierBomProjects';

import AllConsultations from './ProfileComponents/ConsultationComponents/AllConsultations';
import AdminFabDashboard from './PCBFabricator/FabProjects/AdminFabDashboard';
import AllPMs from './Admin/AllPMs';
import PMLogin from './Admin/PMLogin';
import PMHomepage from './Admin/PMHomepage';
import AdminBomDashboard from './BOMProcurement/BomProjects/AdminBomDashboard';
import VendorManagement from './Admin/VendorManagement';
import AddNewVendor from './Admin/AddNewVendor';
import VMS from './VMS';
import AdminColosseumNew from './3dColosseum/AdminPages/AdminColosseumProjectsNew';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      loading: false,
    };
    this.toggleLoggedIn = this.toggleLoggedIn.bind(this);
  }
  toggleLoggedIn() {
    this.setState({
      ...this.state,
      loggedIn: !this.state.loggedIn,
    });
    // this.setState({
    //   ...this.state,
    //   loggedIn: !this.state.
    // })
  }
  componentDidMount() {
    this.setState({
      ...this.state,
      loading: true,
    });
    let token = localStorage.token;
    if (token) {
      axiosInstance.interceptors.request.use((config) => {
        token = localStorage.token;
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      });

      axiosInstance
        .get('/dummy')
        .then((resp) => {
          this.setState(
            {
              ...this.state,
              loading: false,
            },
            () => {
              this.toggleLoggedIn();
            }
          );
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            ...this.state,
            loading: false,
          });
        });
    } else {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  }
  render() {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <SnackbarProvider maxSnack={3}>
          <Router>
            {this.state.loading ? (
              <Switch>
                <Route
                  path="/"
                  exact
                  history={this.props.history}
                  render={(props) => (
                    <div
                      style={{
                        height: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        src={DashboardLogo1}
                        style={{ width: '150px' }}
                        alt="img"
                      ></img>
                      <CircularProgress
                        style={{ marginLeft: '20px' }}
                      ></CircularProgress>
                    </div>
                  )}
                ></Route>
              </Switch>
            ) : this.state.loggedIn ? (
              localStorage.admin == 'true' || localStorage.PM == 'true' ? (
                <Switch>
                  {/* <Route
                    path="/"
                    exact
                    history={this.props.history}
                    render={(props) => {
                      console.log(props.history, "s");
                      return (
                        <ServiceView>
                          history={props.history}
                          toggleLoggedIn={this.toggleLoggedIn}
                        </ServiceView>
                      );
                    }}
                  ></Route> */}
                  <Route
                    path="/"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <AdminHomePage
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/pm"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <AdminHomePage
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/admin/bomExplorer"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <AdminBomDashboard
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                        isAdmin={true}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/pm/bomExplorer"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <AdminBomDashboard
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                        isAdmin={true}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/admin/vendorManagementDashboard"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <VMS
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                        isAdmin={localStorage.admin}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/pm/vendorManagementDashboard"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <VMS
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                        isAdmin={localStorage.admin}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/admin/allUsers"
                    exact
                    history={this.props.history}
                    render={(props) => <AllUsers history={props.history} />}
                  ></Route>
                  <Route
                    path="/pm/allUsers"
                    exact
                    history={this.props.history}
                    render={(props) => <AllUsers history={props.history} />}
                  ></Route>
                  <Route
                    path="/admin/projectManagers"
                    exact
                    history={this.props.history}
                    render={(props) => <AllPMs history={props.history} />}
                  ></Route>
                  <Route
                    path="/admin/vendorMasterList"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <VendorManagement history={props.history} />
                    )}
                  ></Route>
                  <Route
                    path="/pm/vendorMasterList"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <VendorManagement history={props.history} />
                    )}
                  ></Route>
                  <Route
                    path="/admin/addNewVendor"
                    exact
                    history={this.props.history}
                    render={(props) => <AddNewVendor />}
                  ></Route>
                  <Route
                    path="/pm/addNewVendor"
                    exact
                    history={this.props.history}
                    render={(props) => <AddNewVendor />}
                  ></Route>
                  <Route
                    path="/admin/allConsultations"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <AllConsultations history={props.history} />
                    )}
                  ></Route>
                  {/*not mapped*/}
                  <Route
                    path="/pm/allConsultations"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <AllConsultations history={props.history} />
                    )}
                  ></Route>
                  <Route
                    path="/admin/bomExplorer/quotation/:id"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <BomQuotations
                        isAdmin={true}
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                      />
                    )}
                  ></Route>
                  {/*not mapped*/}
                  <Route
                    path="/pm/bomExplorer/quotation/:id"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <BomQuotations
                        isAdmin={true}
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/admin/bomExplorerDetail/:id"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <BomExplorerDetail
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/pm/bomExplorerDetail/:id"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <BomExplorerDetail
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/admin/bomExplorer/timeline/:id"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <BomExplorerTimeLine
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                        isAdmin={true}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/pm/bomExplorer/timeline/:id"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <BomExplorerTimeLine
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                        isAdmin={true}
                      />
                    )}
                  ></Route>

                  <Route
                    path="/admin/pcbFab/timeline/:id"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <FABTimeline
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                        isAdmin={true}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/pm/pcbFab/timeline/:id"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <FABTimeline
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                        isAdmin={true}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/admin/pcbFab/quotations/:id"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <PcbFabQuotations
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                        isAdmin={true}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/pm/pcbFab/quotations/:id"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <PcbFabQuotations
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                        isAdmin={true}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/admin/pcbFab/suppliers/quotation/:id"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <AdminFabQuoation
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                        isAdmin={true}
                        location={props.location}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/pm/pcbFab/suppliers/quotation/:id"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <AdminFabQuoation
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                        isAdmin={true}
                        location={props.location}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/admin/pcbFab/suppliers/:id"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <FAbSupplierQuoatations
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                        isAdmin={true}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/pm/pcbFab/suppliers/:id"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <FAbSupplierQuoatations
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                        isAdmin={true}
                      />
                    )}
                  ></Route>

                  <Route
                    path="/admin/pcbFabDetails/:id"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <FABDetails
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/pm/pcbFabDetails/:id"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <FABDetails
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/admin/pcbFab"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <AdminFabDashboard
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                        isAdmin={true}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/pm/pcbFab"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <AdminFabDashboard
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                        isAdmin={true}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/admin/pcbFab/newProject"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <FabNewProjectAdmin
                        history={props.history}
                        isAdmin={true}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/pm/pcbFab/newProject"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <FabNewProjectAdmin
                        history={props.history}
                        isAdmin={true}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/admin/bom/suppliers/quotation/:id"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <AdminBomQuotation
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                        isAdmin={true}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/admin/bomNewProject"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <CreateBOMProject
                        toggleLoggedIn={this.toggleLoggedIn}
                        isAdmin={true}
                        history={props.history}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/pm/bomNewProject"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <CreateBOMProject
                        toggleLoggedIn={this.toggleLoggedIn}
                        isAdmin={true}
                        history={props.history}
                      />
                    )}
                  ></Route>

                  <Route
                    path="/pm/bom/suppliers/quotation/:id"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <AdminBomQuotation
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                        isAdmin={true}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/admin/bom/suppliers/:id"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <BomSupplierQuotations
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                        isAdmin={true}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/pm/bom/suppliers/:id"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <BomSupplierQuotations
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                        isAdmin={true}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/admin/3d/"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <AdminCollesumProjects
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                        isAdmin={true}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/pm/3d/"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <AdminCollesumProjects
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                        isAdmin={true}
                      />
                    )}
                  ></Route>

                  <Route
                    path="/admin/3d/services"
                    exact
                    render={(props) => (
                      <ColosseumServices
                        history={props.history}
                        isAdmin={true}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/pm/3d/services"
                    exact
                    render={(props) => (
                      <ColosseumServices
                        history={props.history}
                        isAdmin={true}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/admin/3d/newProject/3dPrinting"
                    exact
                    render={(props) => (
                      <Create3dPrintingProject
                        history={props.history}
                        isAdmin={true}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/pm/3d/newProject/3dPrinting"
                    exact
                    render={(props) => (
                      <Create3dPrintingProject
                        history={props.history}
                        isAdmin={true}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/admin/3d/newProject/injectionMolding"
                    exact
                    render={(props) => (
                      <CreateInjectionMoldingProject
                        history={props.history}
                        isAdmin={true}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/pm/3d/newProject/injectionMolding"
                    exact
                    render={(props) => (
                      <CreateInjectionMoldingProject
                        history={props.history}
                        isAdmin={true}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/admin/3d/newProject/prebuiltCases"
                    exact
                    render={(props) => (
                      <CreatePrebuiltCasesProject
                        history={props.history}
                        isAdmin={true}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/pm/3d/newProject/prebuiltCases"
                    exact
                    render={(props) => (
                      <CreatePrebuiltCasesProject
                        history={props.history}
                        isAdmin={true}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/admin/3d/quotations/:type/:id"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <ColosseumQuotations
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                        isAdmin={true}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/pm/3d/quotations/:type/:id"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <ColosseumQuotations
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                        isAdmin={true}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/admin/3d/timeline/:type/:id"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <ColosseumTimeline
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                        isAdmin={true}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/pm/3d/timeline/:type/:id"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <ColosseumTimeline
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                        isAdmin={true}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/clientEngagement"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <AdminDashboard
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/pm/clientEngagement"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <AdminDashboard
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/pcbMarket"
                    history={this.props.history}
                    render={(props) => (
                      <AdminPcbMarket
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/pm/pcbMarket"
                    history={this.props.history}
                    render={(props) => (
                      <AdminPcbMarket
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/profile"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <ProfileView
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/pm/profile"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <ProfileView
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                      />
                    )}
                  ></Route>

                  <Route
                    path="/viewProject/:projectId"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <AdminTimeLine_view
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/pm/viewProject/:projectId"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <AdminTimeLine_view
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/editBilling/:projectId"
                    exact
                    history={this.props.history}
                    render={(props) => <EditBilling history={props.history} />}
                  ></Route>
                  <Route
                    path="/pm/editBilling/:projectId"
                    exact
                    history={this.props.history}
                    render={(props) => <EditBilling history={props.history} />}
                  ></Route>
                </Switch>
              ) : localStorage.getItem('supplier') ? (
                <Switch>
                  <Route
                    exact
                    path="/"
                    history={this.props.history}
                    render={(props) => (
                      <SupplierDashboard
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                      />
                    )}
                  ></Route>
                  <Route
                    exact
                    path="/supplier/onBoarding"
                    history={this.props.history}
                    render={(props) => (
                      <SupplierOnBoarding history={props.history} />
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/supplier/fab/projects"
                    history={this.props.history}
                    render={(props) => (
                      <SupplierFabProjects
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                      />
                    )}
                  ></Route>
                  <Route
                    exact
                    path="/supplier/fab/projects/:id"
                    history={this.props.history}
                    render={(props) => (
                      <SupplierFabQuotation
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                      />
                    )}
                  ></Route>
                  <Route
                    exact
                    path="/supplier/bom/projects"
                    history={this.props.history}
                    render={(props) => (
                      <SupplierBomProjects
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                      />
                    )}
                  ></Route>
                  <Route
                    exact
                    path="/supplier/bom/projects/:id"
                    history={this.props.history}
                    render={(props) => (
                      <SupplierBomQuotation
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                      />
                    )}
                  ></Route>
                </Switch>
              ) : localStorage.getItem('pm') ? (
                <Switch>
                  <Route
                    exact
                    path="/"
                    history={this.props.history}
                    render={(props) => (
                      <SupplierDashboard
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                      />
                    )}
                  ></Route>
                </Switch>
              ) : (
                <Switch>
                  <Route
                    path="/bomExplorer"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <BomExplorer history={props.history}></BomExplorer>
                    )}
                  ></Route>
                  <Route
                    path="/pcbFabricator"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <FabricatorExplorer history={props.history} />
                    )}
                  ></Route>

                  <Route
                    path="/pcbFabNewProject"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <FABNewProject
                        toggleLoggedIn={this.toggleLoggedIn}
                        isAdmin={false}
                        history={props.history}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/bomNewProject"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <CreateBOMProject
                        toggleLoggedIn={this.toggleLoggedIn}
                        isAdmin={false}
                        history={props.history}
                      />
                    )}
                  ></Route>
                  {/* <Route
                    path="/pcbFab"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <FABNewProject
                        toggleLoggedIn={this.toggleLoggedIn}
                        isAdmin={false}
                        history={props.history}
                      />
                    )}
                  ></Route> */}
                  <Route
                    path="/pcbFabTimeline/:id"
                    exact
                    history={this.props.history}
                    render={(props) => <FABTimeline history={props.history} />}
                  ></Route>
                  <Route
                    path="/pcbFab/quotations/:id"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <PcbFabQuotations history={props.history} />
                    )}
                  ></Route>
                  <Route
                    path="/3dcolosseum"
                    exact
                    render={(props) => (
                      <ColosseumExplorer history={props.history} />
                    )}
                  ></Route>
                  <Route
                    path="/3dcolosseum/services"
                    exact
                    render={(props) => (
                      <ColosseumServices history={props.history} />
                    )}
                  ></Route>
                  <Route
                    path="/3dcolosseum/quotations/:id"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <PcbFabQuotations history={props.history} />
                    )}
                  ></Route>
                  <Route
                    path="/3dcolosseum/newProject/3dPrinting"
                    exact
                    render={(props) => (
                      <Create3dPrintingProject history={props.history} />
                    )}
                  ></Route>
                  <Route
                    path="/3dcolosseum/newProject/injectionMolding"
                    exact
                    render={(props) => (
                      <CreateInjectionMoldingProject history={props.history} />
                    )}
                  ></Route>
                  <Route
                    path="/3dcolosseum/newProject/prebuiltCases"
                    exact
                    render={(props) => (
                      <CreatePrebuiltCasesProject history={props.history} />
                    )}
                  ></Route>
                  <Route
                    path="/3dcolosseum/quotations/:type/:id"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <ColosseumQuotations history={props.history} />
                    )}
                  ></Route>
                  <Route
                    path="/3dcolosseum/projects"
                    exact
                    render={(props) => (
                      <ColosseumProjects history={props.history} />
                    )}
                  ></Route>
                  <Route
                    path="/3dcolosseum/:type/:id"
                    exact
                    render={(props) => (
                      <ColosseumTimeline history={props.history} />
                    )}
                  ></Route>

                  <Route
                    path="/bomExplorerTimeline/:id"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <BomExplorerTimeLine
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                        isAdmin={false}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/bomExplorer/project/quotations/:id"
                    exact
                    history={this.props.history}
                    render={(props) => (
                      <BomQuotations asAdmin={false} history={props.history} />
                    )}
                  ></Route>
                  <Route
                    exact
                    path="/client/onBoarding"
                    history={this.props.history}
                    render={(props) => (
                      <CustomerOnBoarding history={props.history} />
                    )}
                  ></Route>
                  <Route
                    path="/"
                    history={this.props.history}
                    render={(props) => (
                      <ClientDashboard
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                      />
                    )}
                  ></Route>
                  {/* <Route
                    path="/supplierDash"
                    history={this.props.history}
                    render={(props) => (
                      <SupplierDashboard
                        history={props.history}
                        toggleLoggedIn={this.toggleLoggedIn}
                      />
                    )}
                  ></Route> */}
                  <Redirect to="/client" />
                </Switch>
              )
            ) : (
              <Switch>
                {/*
<Route exact path="/">
                  <Directional />
                </Route>
                    */}
                <Route
                  path="/"
                  exact
                  render={(props) => (
                    <Login
                      history={props.history}
                      toggleLoggedIn={this.toggleLoggedIn}
                    />
                  )}
                />
                <Route
                  path="/forgotpassword"
                  exact
                  render={(props) => (
                    <ForgotPassword
                      history={props.history}
                      toggleLoggedIn={this.toggleLoggedIn}
                    />
                  )}
                />
                <Route
                  path="/resetpassword/:token"
                  exact
                  render={(props) => (
                    <ResetPassword
                      history={props.history}
                      toggleLoggedIn={this.toggleLoggedIn}
                    />
                  )}
                />
                <Route
                  history={this.props.history}
                  exact
                  path="/admin/login"
                  render={(props) => (
                    <AdminLogin
                      history={props.history}
                      toggleLoggedIn={this.toggleLoggedIn}
                    />
                  )}
                />
                <Route
                  history={this.props.history}
                  exact
                  path="/pm/login"
                  render={(props) => (
                    <PMLogin
                      history={props.history}
                      toggleLoggedIn={this.toggleLoggedIn}
                    />
                  )}
                />
                <Route
                  history={this.props.history}
                  exact
                  path="/supplier/login"
                  render={(props) => (
                    <SupplierLogin
                      history={props.history}
                      toggleLoggedIn={this.toggleLoggedIn}
                    />
                  )}
                />
                <Route
                  history={this.props.history}
                  exact
                  path="/pm/login"
                  render={(props) => (
                    <PMLogin
                      history={props.history}
                      toggleLoggedIn={this.toggleLoggedIn}
                    />
                  )}
                />
                <Route
                  path="/signUp"
                  exact
                  history={this.props.history}
                  render={(props) => <SignUp history={props.history}></SignUp>}
                ></Route>
                <Route
                  path="/supplier/signUp"
                  exact
                  history={this.props.history}
                  render={(props) => (
                    <SupplierSignup history={props.history}></SupplierSignup>
                  )}
                ></Route>
              </Switch>
            )}
          </Router>
        </SnackbarProvider>
      </MuiPickersUtilsProvider>
    );
  }
}

export default App;
