import React, { useState, useEffect } from 'react';

//components
import CustomBreadcrumbs from '../../Components/CustomBreadcrumbs';
import DashboardHeader from '../../ClientEngagement/DashboardHeader';
import ConfirmModal from '../../Components/ComfirmModal';
import SupplierTimelineComponent from '../Suppliers/SupplierTimelineComponent';
import { useLocation } from 'react-router-dom';
import { supplierStages } from '../Suppliers/Supplier.data';
import ClosedProject from '../../Components/ClosedProject';

//utils
import { axiosInstance } from '../../utils/axiosInterceptor';
import { Box, Grid, Typography, TextField, Button } from '@mui/material';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

//styles
import {
  PageContainer,
  SupplierQuotationsContainer,
  CustomButton,
} from './SupplierQuotations.styles';

//assets
import { ReactComponent as SearchIcon } from '../../assests/search.svg';
import { ReactComponent as DownloadIcon } from '../../assests/download.svg';

const AdminFabQuoation = (props) => {
  const location = useLocation();
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState(null);
  const [quotation, setQuotation] = useState(null);
  const [isClose, setIsClose] = useState(false);
  const { id } = useParams();
  const projId = location.state;
  // const { state } = props.location;

  console.log(location);
  console.log(props.location, 'hehhehhe');
  const { enqueueSnackbar } = useSnackbar();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const toggleConfirmModal = () => {
    setShowConfirmModal(!showConfirmModal);
  };

  const fetchProfile = () => {
    setLoading(true);
    axiosInstance
      .get('/users/getProfile')
      .then((resp) => {
        setProfile(resp.data);
        console.log(resp);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const fetchProject = async () => {
    try {
      const res = await axiosInstance.get(`/fab/admin/supplierQuote/${id}`);
      console.log(res);
      setProject(res.data.quote.project);
      setQuotation(res.data.quote);
      setIsClose(res.data?.quote.project?.isClosed);
    } catch (err) {
      console.log(err);
    }
  };

  const handleAcceptQuotation = async () => {
    try {
      const res = await axiosInstance.post(`/fab/admin/acceptQuotation/${id}`);
      console.log(res);
      enqueueSnackbar('Quotation accepted !', { variant: 'success' });
      setQuotation({
        ...quotation,
        isAccepted: true,
      });
    } catch (err) {
      console.log(err);
      enqueueSnackbar('Something went wrong!', { variant: 'error' });
    }
    toggleConfirmModal();
  };

  useEffect(() => {
    fetchProfile();
    fetchProject();
  }, []);

  return (
    <Box sx={isClose ? { pointerEvents: 'none' } : {}}>
      {isClose && <ClosedProject />}

      {!loading && quotation ? (
        <PageContainer>
          <DashboardHeader profile_data={profile.data} isBlack={true} />
          <SupplierQuotationsContainer>
            <CustomBreadcrumbs
              data={[
                {
                  name: 'Home',
                  link: '/',
                },
                {
                  name: 'EB PCB Fabricator',
                  link: '/admin/pcbFab/projects',
                },
                {
                  name: 'Request for Quotation',
                  link: `/admin/pcbFab/suppliers/${project._id || projId}`,
                },
                {
                  name: 'Quotation',
                  link: `/admin/pcbFab/suppliers/quotation/${id}`,
                },
              ]}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div className="title">
                Quotation from {quotation.supplier.fullName},{' '}
                {quotation.supplier.supplierDetails.address}
              </div>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                height: '40px',
              }}
            >
              <div className="sub-title">
                {quotation.project.internalName} -{' '}
                {quotation.project.projectName}
              </div>
              {!quotation.project.isQuoted ? (
                <Button
                  onClick={toggleConfirmModal}
                  sx={{
                    '&&&': {
                      backgroundColor: '#FF972A',
                      color: '#fff',
                      padding: '5px 20px',
                    },
                  }}
                  disabled={quotation.isAccepted}
                >
                  {quotation.isAccepted ? 'BID ACCEPTED' : 'ACCEPT BID'}
                </Button>
              ) : (
                <Button
                  sx={{
                    '&&&': {
                      backgroundColor: '#FF972A',
                      color: '#fff',
                      padding: '5px 20px',
                    },
                  }}
                  disabled
                >
                  PROJECT ALREDY QUOTED
                </Button>
              )}

              <ConfirmModal
                state={showConfirmModal}
                toggleModal={toggleConfirmModal}
                onNo={toggleConfirmModal}
                onYes={handleAcceptQuotation}
                text="Accept this bid?"
              />
            </Box>
            <Box>
              <Typography sx={{ margin: '30px 0px 20px 0px' }}>
                Supplier Quote 📃
              </Typography>
              <Box
                sx={{
                  border: '1px solid #0275FC',
                  borderRadius: '12px',
                  padding: '20px',
                  margin: '10px 0px',
                }}
              >
                <form>
                  <Grid container sx={{ marginBottom: '15px' }}>
                    <Grid item md={12}>
                      <Box
                        sx={{
                          display: 'flex',
                          margin: '10px 10px 10px 0px',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          sx={{ color: '#8C8C8C', marginRight: '20px' }}
                        >
                          Lead time:
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                          }}
                        >
                          {`${quotation.quotation.leadTime.minTime} to ${quotation.quotation.leadTime.maxTime} days`}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item md={6}>
                      <Box
                        sx={{
                          display: 'flex',
                          margin: '10px 10px 10px 0px',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          sx={{ color: '#8C8C8C', marginRight: '20px' }}
                        >
                          Total cost:
                        </Typography>
                        <Typography sx={{ marginRight: '10px' }}>₹</Typography>
                        {quotation.quotation.totalCost}
                      </Box>
                    </Grid>
                    <Grid item md={6}>
                      <Box
                        sx={{
                          display: 'flex',
                          margin: '10px 10px 10px 0px',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          sx={{ color: '#8C8C8C', marginRight: '20px' }}
                        >
                          Total cost(incl GST):
                        </Typography>
                        <Typography sx={{ marginRight: '10px' }}>₹</Typography>
                        <Typography>
                          {(quotation.quotation.totalCost * 1.18)
                            .toFixed(2)
                            .replace(/[.,]00$/, '')}
                        </Typography>
                      </Box>
                    </Grid>{' '}
                    <Grid item md={6}>
                      <Box
                        sx={{
                          display: 'flex',
                          margin: '10px 10px 10px 0px',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          sx={{ color: '#8C8C8C', marginRight: '20px' }}
                        >
                          Shipping cost:
                        </Typography>
                        <Typography sx={{ marginRight: '10px' }}>₹</Typography>
                        {quotation.quotation.shippingCost}
                      </Box>
                    </Grid>
                    <Grid item md={6}>
                      <Box
                        sx={{
                          display: 'flex',
                          margin: '10px 10px 10px 0px',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          sx={{ color: '#8C8C8C', marginRight: '20px' }}
                        >
                          Grand Total:
                        </Typography>
                        <Typography sx={{ marginRight: '10px' }}>₹</Typography>
                        <Typography>
                          {(
                            Number(quotation.quotation.totalCost * 1.18) +
                            Number(quotation.quotation.shippingCost)
                          )
                            .toFixed(2)
                            .replace(/[.,]00$/, '')}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item md={12}>
                      <Box
                        sx={{ display: 'flex', marginTop: '10px', gap: '10px' }}
                      >
                        <Typography sx={{ color: '#7D7D7D' }}>
                          Quotation was last submitted on{' '}
                        </Typography>
                        <Typography sx={{ color: '#0275FC', fontWeight: 700 }}>
                          {moment(quotation.createdOn).format(
                            'MMMM Do YYYY, h:mm a'
                          ) || 'no date'}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Box>
            <Box>
              <Typography sx={{ margin: '30px 0px 20px 0px' }}>
                Project Details 📃
              </Typography>
              <Box
                sx={{
                  border: '1px solid #0275FC',
                  borderRadius: '12px',
                  padding: '20px',
                  margin: '10px 0px',
                }}
              >
                <Grid container sx={{ marginBottom: '20px' }}>
                  <Grid item md={6}>
                    <Box
                      sx={{
                        display: 'flex',
                        margin: '10px 10px 10px 0px',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{ color: '#8C8C8C', marginRight: '20px' }}
                      >
                        PCB Quantity:
                      </Typography>
                      <Typography>
                        {project.projectDetails?.pcbQuantity}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item md={6}>
                    <Box
                      sx={{
                        display: 'flex',
                        margin: '10px 10px 10px 0px',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{ color: '#8C8C8C', marginRight: '20px' }}
                      >
                        Surface Finish:
                      </Typography>
                      <Typography>
                        {project.projectDetails?.surfaceFinish}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item md={6}>
                    <Box
                      sx={{
                        display: 'flex',
                        margin: '10px 10px 10px 0px',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{ color: '#8C8C8C', marginRight: '20px' }}
                      >
                        Delivery Format:
                      </Typography>
                      <Typography>
                        {project.projectDetails?.deliveryFormat}
                      </Typography>
                    </Box>
                  </Grid>{' '}
                  <Grid item md={6}>
                    <Box
                      sx={{
                        display: 'flex',
                        margin: '10px 10px 10px 0px',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{ color: '#8C8C8C', marginRight: '20px' }}
                      >
                        Stencil Requirement:
                      </Typography>
                      <Typography>
                        {project.projectDetails.isStencil ? 'Yes' : 'No'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item md={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        margin: '10px 10px 10px 0px',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{ color: '#8C8C8C', marginRight: '20px' }}
                      >
                        Project Created On:
                      </Typography>
                      <Typography>
                        {' '}
                        {moment(project?.updationDate).format(
                          'MMMM Do YYYY, h:mm a'
                        ) || 'no date'}
                      </Typography>
                    </Box>
                  </Grid>{' '}
                  <Grid item md={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        margin: '10px 10px 10px 0px',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{ color: '#8C8C8C', marginRight: '20px' }}
                      >
                        Additional Description:
                      </Typography>
                      <Typography>
                        {project.projectDetails.otherSpecification || '--'}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Box
                  sx={{ display: 'flex', gap: '20px', alignItems: 'center' }}
                >
                  <a href={project.gerberFile.location}>
                    <CustomButton>DOWNLOAD GERBER</CustomButton>
                  </a>
                  <Box sx={{ display: 'flex', marginTop: '0px', gap: '10px' }}>
                    <Typography sx={{ color: '#7D7D7D' }}>
                      Last Gerber File upload was on{' '}
                    </Typography>
                    <Typography sx={{ color: '#0275FC', fontWeight: 700 }}>
                      {moment(
                        project.gerberFile.uploadedOn || project.updationDate
                      ).format('MMMM Do YYYY, h:mm a') || 'no date'}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box sx={{ marginTop: '30px' }}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  margin: '20px 0px',
                }}
              >
                <Typography>Order Timeline 📈</Typography>
                {/* <Box
                  sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <Select
                    value={currentStage}
                    sx={{
                      "& legend": {
                        width: "unset",
                      },
                      "& .MuiSelect-select": {
                        padding: "5px 20px",
                      },
                    }}
                    disabled={
                      project.fabQuotation?.currentStatus.length <= 2 ||
                      project.fabQuotation == null
                    }
                    onChange={handleStageUpdate}
                  >
                    {supplierStages.map((m) => (
                      <MenuItem value={m.value}>{m.label}</MenuItem>
                    ))}
                  </Select>
                  <CustomButton
                    disabled={
                      project.fabQuotation?.currentStatus.length <= 2 ||
                      project.fabQuotation == null
                    }
                    onClick={handleStageChange}
                  >
                    UPDATE
                  </CustomButton>
                </Box> */}
              </Box>
              <Box>
                <Timeline>
                  {supplierStages.map((stage, i) => (
                    <TimelineItem>
                      <TimelineOppositeContent
                        color="text.secondary"
                        style={{ flex: 0 }}
                      >
                        {quotation?.currentStatus.length || 1 >= i + 1 ? (
                          moment(
                            quotation?.currentStatus[i]?.completionDate
                          ).format('DD/MM/YYYY')
                        ) : (
                          <Typography sx={{ visibility: 'hidden' }}>
                            {moment().format('DD/MM/YYYY')}
                          </Typography>
                        )}
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot />
                        {i != 5 ? <TimelineConnector /> : null}
                      </TimelineSeparator>
                      <TimelineContent>
                        <SupplierTimelineComponent
                          stage={stage}
                          i={i}
                          quotation={quotation}
                        />
                      </TimelineContent>
                    </TimelineItem>
                  ))}
                </Timeline>
              </Box>
            </Box>
          </SupplierQuotationsContainer>
        </PageContainer>
      ) : null}
    </Box>
  );
};

export default AdminFabQuoation;
