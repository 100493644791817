import React, { useState, Fragment, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import Button from "@mui/material/Button";
import { Listbox } from "@headlessui/react";
import "./Actions.css";
import CheckIcon from "@mui/icons-material/Check";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import { axiosInstance } from "../../utils/axiosInterceptor";
import { SnackbarProvider, useSnackbar } from "notistack";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import moment from "moment";
import FormControl from "@material-ui/core/FormControl";
import clsx from "clsx";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import DeleteTwoToneIcon from "@material-ui/icons/DeleteTwoTone";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import ReactDOM from "react-dom";
import { nanoid } from "nanoid";
var createReactClass = require("create-react-class");

let finalBilling = {};
let nodes = {};

const statuses = [
  { id: 1, status: "pending_approval" },
  { id: 2, status: "pending_payment" },
  { id: 3, status: "in_production" },
  { id: 4, status: "shipped" },
];

const EditStatusModal = ({
  statusModal,
  handleClose,
  editStatus,
  shipTime,
  setShipTime,
  totalBilling,
  setTotalBilling,
  finalBilling,
  allOrders,
  selectedApprove,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [selectedStatus, setSelectedStatus] = useState([0]);
  const [statusMsg, setStatusMsg] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);

  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    rightIcon: {
      marginLeft: theme.spacing(1),
    },
    iconSmall: {
      fontSize: 20,
    },
    root: {
      padding: theme.spacing(1, 1),
      display: "flex",
    },
    root1: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
    margin: {
      margin: theme.spacing(1),
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 400,
    },
  }));

  const classes = useStyles();

  const handleMsg = (evt) => {
    const msg = evt.target.value;
    setStatusMsg(msg);
  };

  let selectedItem = [];
  for (let i = 0; i < allOrders.length; i++) {
    if (selectedApprove?._id === allOrders[i].orderId) {
      selectedItem = allOrders[i];
      //console.log(allOrders[i])
    }
  }

  let handleDelete = (idx) => {
    for (let id in finalBilling) {
      nodes[id] = (
        <div className="form_el_cont" key={id}>
          <FormControl
            className={clsx(
              classes.margin,
              classes.withoutLabel,
              classes.textField
            )}
            style={{
              width: "55%",
            }}
          >
            <TextField
              label="Billing type"
              value={finalBilling[id]["type"]}
              variant="outlined"
            />
          </FormControl>
          <FormControl
            className={clsx(
              classes.margin,
              classes.withoutLabel,
              classes.textField
            )}
            style={{
              width: "35%",
            }}
            className={classes.margin}
            variant="outlined"
          >
            <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              value={finalBilling[id]["amount"]}
              startAdornment={
                <InputAdornment position="start">₹</InputAdornment>
              }
              labelWidth={60}
            ></OutlinedInput>
          </FormControl>

          <FormControl className={clsx(classes.margin)}>
            <IconButton
              onClick={() => {
                handleDelete(id);
              }}
            >
              <DeleteTwoToneIcon></DeleteTwoToneIcon>
            </IconButton>
          </FormControl>
        </div>
      );
    }

    delete nodes[idx];
    delete finalBilling[idx];
    var Fields = createReactClass({
      render: () => {
        // Change this to get the list from props or state
        let rNodes = [];
        for (let k in nodes) {
          rNodes.push(nodes[k]);
        }
        return <>{rNodes}</>;
      },
    });
    ReactDOM.render(<Fields />, document.getElementById("addedFields"));
  };

  let handleAdd = () => {
    for (let id in finalBilling) {
      nodes[id] = (
        <div className="form_el_cont" key={id}>
          <FormControl
            className={clsx(
              classes.margin,
              classes.withoutLabel,
              classes.textField
            )}
            style={{
              width: "55%",
            }}
          >
            <TextField
              label="Billing type"
              value={finalBilling[id]["type"]}
              variant="outlined"
            />
          </FormControl>
          <FormControl
            className={clsx(
              classes.margin,
              classes.withoutLabel,
              classes.textField
            )}
            style={{
              width: "35%",
            }}
            className={classes.margin}
            variant="outlined"
          >
            <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              value={finalBilling[id]["amount"]}
              startAdornment={
                <InputAdornment position="start">₹</InputAdornment>
              }
              labelWidth={60}
            ></OutlinedInput>
          </FormControl>

          <FormControl className={clsx(classes.margin)}>
            <IconButton
              onClick={() => {
                handleDelete(id);
              }}
            >
              <DeleteTwoToneIcon></DeleteTwoToneIcon>
            </IconButton>
          </FormControl>
        </div>
      );
    }
    let idx = nanoid(5);
    finalBilling[idx] = {};
    finalBilling[idx]["type"] = "";
    finalBilling[idx]["amount"] = "";

    nodes[idx] = (
      <div className="form_el_cont" key={idx}>
        <FormControl
          className={clsx(
            classes.margin,
            classes.withoutLabel,
            classes.textField
          )}
          style={{
            width: "55%",
          }}
        >
          <TextField
            label="Billing type"
            onChange={(e) => {
              finalBilling[idx]["type"] = e.target.value;

              console.log(finalBilling.hasOwnProperty(idx), finalBilling);
            }}
            variant="outlined"
          />
        </FormControl>
        <FormControl
          className={clsx(
            classes.margin,
            classes.withoutLabel,
            classes.textField
          )}
          style={{
            width: "35%",
          }}
          className={classes.margin}
          variant="outlined"
        >
          <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            onChange={(e) => {
              finalBilling[idx]["amount"] = e.target.value;
            }}
            startAdornment={<InputAdornment position="start">₹</InputAdornment>}
            labelWidth={60}
          ></OutlinedInput>
        </FormControl>

        <FormControl className={clsx(classes.margin)}>
          <IconButton
            onClick={() => {
              handleDelete(idx);
            }}
          >
            <DeleteTwoToneIcon></DeleteTwoToneIcon>
          </IconButton>
        </FormControl>
      </div>
    );

    var Fields = createReactClass({
      render: () => {
        // Change this to get the list from props or state
        let rNodes = [];
        for (let k in nodes) {
          rNodes.push(nodes[k]);
        }
        return <>{rNodes}</>;
      },
    });
    ReactDOM.render(<Fields />, document.getElementById("addedFields"));
  };

  console.log(selectedItem);

  let handleBilling = () => {
    console.log(
      finalBilling,
      shipTime,
      totalBilling,
      selectedApprove,
      selectedItem?.orderId
    );
    setBtnLoading(true);
    axiosInstance
      .post(`/products/approveOrder/${selectedItem?.orderId}`, {
        shippingTime: shipTime,
        totalbillingAmount: totalBilling,
        userId: selectedItem?.orderOwner?._id,
        billingBreakdown: finalBilling,
      })
      .then(() => {
        enqueueSnackbar("Billing Added", { variant: "success" });
        setBtnLoading(false);
      })
      .catch((err) => {
        enqueueSnackbar("Some error occured", { variant: "error" });
        setBtnLoading(false);
      });
    console.log({
      Status: selectedStatus.status,
      Message: statusMsg,
      cartItem: editStatus,
    });
  };

  let handleUpdate = () => {
    console.log(
      finalBilling,
      shipTime,
      totalBilling,
      selectedApprove,
      selectedItem?.orderId
    );
    console.log(selectedItem.orderId);
    console.log("hello");
    setBtnLoading(true);

    axiosInstance
      .put(`/products/approveOrder/${selectedItem?.orderId}`, {
        shippingTime: shipTime,
      })
      .then(() => {
        enqueueSnackbar("Shipping Time Updated", { variant: "success" });
        setBtnLoading(false);
      })
      .catch((err) => {
        enqueueSnackbar("Some error occured", { variant: "error" });
        setBtnLoading(false);
      });
    console.log({
      Status: selectedStatus.status,
      Message: statusMsg,
      cartItem: editStatus,
    });

    if (totalBilling && finalBilling !== " ") {
      axiosInstance
        .put(`/products/updateOrderBilling/${selectedItem?.orderId}`, {
          totalbillingAmount: totalBilling,
          billingBreakdown: finalBilling,
        })
        .then(() => {
          enqueueSnackbar("Billing Updated", { variant: "success" });
          setBtnLoading(false);
        })
        .catch((err) => {
          enqueueSnackbar("Some error occured here", { variant: "error" });
          setBtnLoading(false);
        });
      console.log({
        Status: selectedStatus.status,
        Message: statusMsg,
        cartItem: editStatus,
      });
    }
  };

  const handleStatus = () => {
    //evt.preventDefault();
    //console.log('Status updated')
    axiosInstance
      .put(`/products/changeStatus/${editStatus._id}`, {
        status: selectedStatus.status,
        orderMessage: statusMsg,
      })
      .then(() => {
        enqueueSnackbar("Status Updated", { variant: "success" });
        handleClose();
      })
      .catch((err) => {
        enqueueSnackbar("Unable to Update", { variant: "error" });
        handleClose();
      });
  };

  const orderProducts = editStatus.cart;

  console.log(editStatus);
  console.log(selectedStatus, statusMsg);

  return (
    <div className="status_modal">
      <Dialog
        fullWidth
        width="md"
        open={statusModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <p> Order Details</p>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {/* Shipping and Details */}

            <div className="shipping_address">
              <div className="order_details">
                <p>OrderID: {editStatus._id}</p>
                <p>
                  Placed on:{" "}
                  {moment(editStatus.createdAt).format("DD/MM/YYYY - HH:mm")}
                </p>

                <div className="product_d">
                  {orderProducts?.map((item) => (
                    <div className="s_product">
                      <h6>{item?.product.short_name}</h6>
                      <p>Quantity: {item?.quantity}</p>
                      <p>Price: ₹{item?.newPrice}</p>
                    </div>
                  ))}
                </div>
                <div className="shipping_add">
                  <h6>Ship to</h6>
                  {editStatus.shippingAddress?.addressType}, City:{" "}
                  {editStatus.shippingAddress?.city}, State:{" "}
                  {editStatus.shippingAddress?.state}
                </div>
              </div>

              <div className="user_d">
                <h6>User Details</h6>
                <p>Name: {editStatus.shippingAddress?.fullName}</p>
                <p>Phone: {editStatus.shippingAddress?.mobileNumber}</p>
              </div>
            </div>

            {/* Billing And Order Updating */}

            <div className="approve_inputs">
              <form>
                <TextField
                  required
                  label="Shipping Time"
                  id="margin-normal"
                  name="shippingTime"
                  defaultValue={editStatus?.shippingTime}
                  value={shipTime}
                  onChange={(e) => {
                    setShipTime(e.target.value);
                  }}
                  style={{ marginBottom: "20px" }}
                />

                <TextField
                  required
                  label="Total Billing"
                  id="margin-normal"
                  name="totalBilling"
                  type="number"
                  value={totalBilling}
                  defaultValue={editStatus?.billing?.totalbillingAmount}
                  onChange={(e) => {
                    setTotalBilling(e.target.value);
                  }}
                  style={{ marginBottom: "20px" }}
                />
                <div style={{ marginBottom: "10px" }}>Billing Details :</div>
                <span id="addedFields"></span>

                {editStatus.billing && editStatus.billing.billingBreakdown
                  ? Object.entries(editStatus.billing.billingBreakdown).map(
                      (obj) => {
                        const key = obj[0];
                        const value = obj[1];

                        return (
                          <div className="form_el_cont" key={obj[key]}>
                            <FormControl
                              className={clsx(
                                classes.margin,
                                classes.withoutLabel,
                                classes.textField
                              )}
                              style={{
                                width: "55%",
                              }}
                            >
                              <TextField
                                label="Billing type"
                                defaultValue={value.type}
                                variant="outlined"
                              />
                            </FormControl>
                            <FormControl
                              className={clsx(
                                classes.margin,
                                classes.withoutLabel,
                                classes.textField
                              )}
                              style={{
                                width: "35%",
                              }}
                              className={classes.margin}
                              variant="outlined"
                            >
                              <InputLabel htmlFor="outlined-adornment-amount">
                                Amount
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-amount"
                                defaultValue={value.amount}
                                startAdornment={
                                  <InputAdornment position="start">
                                    ₹
                                  </InputAdornment>
                                }
                                labelWidth={60}
                              ></OutlinedInput>
                            </FormControl>

                            <FormControl className={clsx(classes.margin)}>
                              <IconButton
                                onClick={() => {
                                  handleDelete(obj[key]);
                                }}
                              >
                                <DeleteTwoToneIcon></DeleteTwoToneIcon>
                              </IconButton>
                            </FormControl>
                          </div>
                        );
                      }
                    )
                  : ""}

                <div style={{ width: "100%", textAlign: "center" }}>
                  <Button
                    style={{ width: "80%", marginTop: "5px" }}
                    color="primary"
                    variant="outlined"
                    onClick={(e) => {
                      handleAdd();
                    }}
                  >
                    Add Billing
                  </Button>
                </div>
              </form>

              <Button
                style={{
                  width: "120px",
                  marginBottom: "20px",
                  marginRight: "10px",
                }}
                variant="contained"
                color="primary"
                onClick={(e) => {
                  handleUpdate();
                }}
              >
                Update
              </Button>

              <Button
                style={{ width: "120px", marginBottom: "20px" }}
                variant="contained"
                color="primary"
                disabled={
                  selectedApprove.orderStatus === "pending_approval"
                    ? false
                    : true
                }
                onClick={(e) => {
                  handleBilling();
                }}
              >
                Add
              </Button>
            </div>

            {/* Status Updating */}

            <div className="selectStatus">
              <h5>Order Status: </h5>
              <Listbox value={selectedStatus} onChange={setSelectedStatus}>
                <Listbox.Button className="status_btn">
                  {selectedStatus.status ? (
                    selectedStatus.status
                  ) : (
                    <span>
                      Select Status{" "}
                      <KeyboardArrowDownIcon></KeyboardArrowDownIcon>{" "}
                    </span>
                  )}
                </Listbox.Button>
                <Listbox.Options className="status_list">
                  {statuses.map((status) => (
                    <Listbox.Option
                      key={status.id}
                      value={status}
                      as={Fragment}
                    >
                      {({ active, selected }) => (
                        <li
                          className={`${active ? "active_status" : "status"}`}
                        >
                          {selected && <CheckIcon />}
                          {status.status}
                        </li>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Listbox>

              <div className="status_message">
                <TextareaAutosize
                  maxRows={4}
                  defaultValue={
                    selectedApprove?.orderMessage === ""
                      ? ""
                      : selectedApprove?.orderMessage
                  }
                  aria-label="status message"
                  onChange={handleMsg}
                  placeholder="Write your massage"
                  style={{ width: 300, height: 100 }}
                />
              </div>

              <LoadingButton
                // loading={btnLoading}
                // type="submit"
                style={{ width: "180px" }}
                variant="contained"
                color="primary"
                onClick={(e) => {
                  handleStatus();
                }}
              >
                Update
              </LoadingButton>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditStatusModal;
