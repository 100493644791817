import React, { useState, useEffect } from "react";

//libs
import {
  Modal,
  Box,
  styled,
  Button,
  Slide,
  LinearProgress,
  Typography,
  CircularProgress,
} from "@mui/material";
import CustomFileDropper from "../Components/CustomFileDropper";
import { axiosInstance } from "../utils/axiosInterceptor";
import { useSnackbar } from "notistack";

const ModalContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: "60vw",
  height: "80vh",
  left: "20vw",
  top: "10vh",
  backgroundColor: "#fff",
  boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.2)",
  borderRadius: 15,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  gap: "20px",
  "& .heading": {
    fontSize: "1.2em",
    fontWeight: 700,
  },
  [theme.breakpoints.down("md")]: {
    width: "90vw",
    left: "5vw",
  },
}));

export const CustomButton = styled(Button)(() => ({
  "&&&": {
    backgroundColor: "#FF972A",
    color: "#fff",
    textTransform: "none",
    padding: "5px 50px",
    borderRadius: "8px",
    fontWeight: 500,
    margin: "10px auto",
  },
}));

const VendorImageEditModal = ({ state, toggleModal, images, vendorId }) => {
  const [newImages, setNewImages] = useState([]);

  const [previousImages, setPreviousImages] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (images && images.length > 0) {
      setPreviousImages(images);
    }
  }, [images]);

  const removeImage = (img) => {
    const ni = previousImages.filter((i) => i.location != img.location);

    setPreviousImages(ni);
  };

  const handleSave = async () => {
    setLoading(true);

    try {
      const formData = new FormData();

      if (newImages.length > 0) {
        newImages.forEach((fl) => formData.append("images", fl));
      }

      formData.append("previousImages", JSON.stringify(previousImages));

      const res = await axiosInstance.post(
        `/vendor/editVendorImages/${vendorId}`,
        formData
      );
      setLoading(false);

      enqueueSnackbar("Images updated successfully!", { variant: "success" });
      window.location.reload();
    } catch (err) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
      toggleModal();
      setLoading(false);
    }
  };

  return (
    <Modal open={state} onClose={toggleModal}>
      <Slide in={state} direction="up">
        <ModalContainer>
          <p className="heading">EDIT VENDOR IMAGES</p>
          <Box
            sx={{ display: "flex", width: "100%", gap: "30px", height: "80%" }}
          >
            <Box sx={{ flex: 1, height: "100%", overflowY: "auto" }}>
              <CustomFileDropper
                small
                subText="Upload new images here"
                setFile={setNewImages}
              />
            </Box>
            <Box
              sx={{
                flex: 1,
                height: "100%",
                overflowY: "auto",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              {previousImages.map((item) => (
                <Box
                  sx={{
                    width: "95%",
                    display: "flex",
                    gap: "20px",
                    border: "1px solid #6e6e6e",
                    padding: "5px",
                    borderRadius: "10px",
                  }}
                >
                  <img
                    src={item?.location}
                    alt=""
                    style={{
                      width: "80px",
                      height: "80px",
                      objectFit: "cover",
                      borderRadius: "10px",
                    }}
                  />
                  <Box>
                    <Typography>{item.originalName}</Typography>
                    <Button
                      size="small"
                      sx={{
                        backgroundColor: "#d43636",
                        color: "#fff",
                        fontSize: "0.7em",
                        marginTop: "10px",
                        "&:hover": {
                          backgroundColor: "#d43636",
                          color: "#fff",
                        },
                      }}
                      onClick={() => {
                        removeImage(item);
                      }}
                    >
                      REMOVE
                    </Button>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
          <CustomButton onClick={handleSave} disabled={loading}>
            {loading ? (
              <CircularProgress
                sx={{ color: "white" }}
                style={{ width: "25px", height: "25px" }}
              />
            ) : (
              "SAVE"
            )}
          </CustomButton>
        </ModalContainer>
      </Slide>
    </Modal>
  );
};

export default VendorImageEditModal;
