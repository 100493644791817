import React, { useState } from "react";
import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";

// [
//     {
//         name:"",link:""
//     }
// ]

const CustomBreadcrumbs = ({ data }) => {
  return (
    <Breadcrumbs
      sx={{
        color:'#8E8E8E',
        "& a": {
          color: "#8E8E8E",
        },
      }}
      separator=">"
    >
      {data.map((dt) => (
        <Link to={dt.link}>{dt.name}</Link>
      ))}
    </Breadcrumbs>
  );
};

export default CustomBreadcrumbs;
