import {
  styled,
  Box,
  Grid,
  TextField,
  Select,
  Slider,
  Button,
  ButtonBase,
} from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
// import { Select } from "@material-ui/core";

export const PageContainer = styled(Box)(() => ({
  width: "100vw",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
}));

export const SupplierFabProjectsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  flex: 1,
  padding: "50px 50px",
  fontFamily: "Poppins",
  // backgroundColor: "#F4F7FE",
  backgroundColor:"#fff",
  overflowY: "auto",

  "& .title": {
    marginTop: "30px",
    fontWeight: 600,
  },
  "& .sub-title": {
    marginTop: "10px",
    color: "#8B8B8B",
    marginBottom: "50px",
  },
}));

export const StyledInput = styled(TextField)(() => ({
  "& input": {
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: "10px 10px 10px 0px",
    color: "#7A7A7A",
    "&::placeholder": {
      color: "#7A7A7A",
    },
  },
  "&:focus": {
    outline: "none",
  },
  "& legend": {
    width: "unset",
  },
  "& fieldset": {
    border: "none",
  },
  "& .MuiInputBase-root.MuiOutlinedInput-root": {
    backgroundColor: "#fff",
    borderRadius: "8px",
  },
}));

export const CustomButton = styled(Button)(() => ({
  "&&&": {
    width: "fit-content",
    height: "fit-content",
    backgroundColor: "#0275FC",
    color:"#fff",
    borderRadius:'4px',
    padding:'5px 20px'
  },
}));
