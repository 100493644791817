import React, { useState, useEffect } from 'react';

//components
import SupplierCommentModal from './SupplierCommentModal';

//libs
import { Box, Typography, Button } from '@mui/material';

const SupplierTimelineComponent = ({ stage, i, quotation }) => {
  const [showCommentModal, setShowCommentModal] = useState(false);

  const toggleCommentModal = () => {
    setShowCommentModal(!showCommentModal);
  };
  return (
    <Box
      sx={{
        width: '80vw',
        boxShadow: '0px 0px 30px rgba(139, 139, 139, 0.15)',
        padding: '30px',
        backgroundColor:
          quotation?.currentStatus.length >= i + 1 ? '#fff' : '#E0E0E0',
        borderRadius: '4px',
        margin: '10px 0px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Typography sx={{ fontSize: '1.2em', fontWeight: 500 }}>
        {i + 1}. {stage.label}
      </Typography>
      <Box
        sx={
          {
            // display: "flex",
            // width: "100%",
            // justifyContent: "space-between",
            // alignItems: "center",
          }
        }
      >
        <Button
          variant="outlined"
          size="small"
          onClick={toggleCommentModal}
          disabled={
            quotation == null || quotation?.currentStatus.length < i + 1
          }
        >
          View Details
        </Button>
        <SupplierCommentModal
          state={showCommentModal}
          toggleModal={toggleCommentModal}
          number={i + 1}
          stage={stage}
          quotation={quotation}
        />
      </Box>
    </Box>
  );
};

export default SupplierTimelineComponent;
