import React, { useState, useEffect } from "react";

//libs
import {
  Modal,
  Box,
  styled,
  Button,
  Slide,
  LinearProgress,
  Typography,
} from "@mui/material";
import { axiosInstance } from "../../utils/axiosInterceptor";
import { useSnackbar } from "notistack";
import { MenuItem } from "@material-ui/core";

//styled
import { CustomInput, CustomSelect } from "./PcbQuotations.styles";

const ModalContainer = styled(Box)(() => ({
  position: "absolute",
  width: "38vw",
  height: "fit-content",
  left: "31vw",
  top: "15vh",
  backgroundColor: "#fff",
  boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.2)",
  borderRadius: 15,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "30px",
  gap: "20px",
  fontFamily: "Poppins",
  "& .heading": {
    fontSize: "1.2em",
    fontWeight: 600,
    fontFamily: "Poppins",
  },
  "& .input-container": {
    display: "flex",
    alignItems: "center",
    width: "100%",
    margin: "10px 0px",
    gap: "10px",
  },
  "& .input-text": {
    width: "50%",
    marginRight: "10px",
    color: "#8B8B8B",
  },
}));

export const CustomButton = styled(Button)(() => ({
  "&&&": {
    backgroundColor: "#FF972A",
    color: "#fff",
    textTransform: "none",
    padding: "5px 50px",
    borderRadius: "8px",
    fontWeight: 500,
    margin: "10px auto",
  },
}));

const AddQuotationModal = ({ state, toggleModal, id, serviceType }) => {
  const [vendorData, setVendorData] = useState({
    vendorName: "",
    vendorLocation: "",
    leadTime: null,
    cost: null,
    shippingCost: null,
    isFav: null,
  });
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (e) => {
    setVendorData({
      ...vendorData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    console.log(vendorData);
    try {
      const newQuote = {
        ...vendorData,
        totalCost: 0,
      };
      const res = await axiosInstance.post(`fab/admin/addQuote/${id}`, {
        newQuote,
      });
      console.log(res);
      enqueueSnackbar("Quotation added successfully!", { variant: "success" });
      toggleModal();
      window.location.reload();
    } catch (err) {
      console.log(err);
      enqueueSnackbar("Something went wrong!", { variant: "error" });
    }
  };

  return (
    <Modal open={state} onClose={toggleModal}>
      <Slide in={state} direction="up">
        <ModalContainer>
          <div className="heading">Add Quotation</div>
          <Box sx={{ width: "100%" }}>
            <Box className="input-container">
              <Typography className="input-text">Vendor Name:</Typography>
              <CustomInput
                type="text"
                name="vendorName"
                value={vendorData.vendorName}
                onChange={handleChange}
                required
              />
            </Box>
            <Box className="input-container">
              <Typography className="input-text">Vendor Location:</Typography>
              <CustomInput
                type="text"
                name="vendorLocation"
                value={vendorData.vendorLocation}
                onChange={handleChange}
                required
              />
            </Box>
            <Box className="input-container">
              <Typography className="input-text">
                Lead Time(in Days):
              </Typography>
              <CustomInput
                type="text"
                name="leadTime"
                value={vendorData.leadTime}
                onChange={handleChange}
                required
              />
            </Box>
            <Box className="input-container">
              <Typography className="input-text">Unit Cost:</Typography>
              <CustomInput
                type="text"
                name="cost"
                value={vendorData.cost}
                onChange={handleChange}
                required
              />
            </Box>
            <Box className="input-container">
              <Typography className="input-text">Shipping Cost:</Typography>
              <CustomInput
                type="text"
                name="shippingCost"
                value={vendorData.shippingCost}
                onChange={handleChange}
                required
              />
            </Box>
            <Box className="input-container">
              <Typography className="input-text">IsFav:</Typography>
              <CustomSelect
                variant="outlined"
                value={vendorData.isFav}
                id="surfaceFinish"
                onChange={handleChange}
                name="isFav"
              >
                <MenuItem value={true}>True</MenuItem>
                <MenuItem value={false}>False</MenuItem>
              </CustomSelect>
            </Box>
          </Box>
          <CustomButton onClick={handleSubmit}>Submit</CustomButton>
        </ModalContainer>
      </Slide>
    </Modal>
  );
};

export default AddQuotationModal;
