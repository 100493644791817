import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DashboardHeader from "../ClientEngagement/AdminDashboardHeader";
import Box from "@mui/material/Box";
import { axiosInstance } from "../utils/axiosInterceptor";
import CircularProgress from "@material-ui/core/CircularProgress";
import DashboardLogo1 from "../assests/elecbitsLogo.svg";
import Button from "@mui/material/Button";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import useErrorHandler from "../Hooks/useErrorHandler";
import { Paper, Modal } from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import MenuItem from "@mui/material/MenuItem";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { SnackbarProvider, useSnackbar } from "notistack";
import { Formik, useFormik } from "formik";
import moment from "moment";
import CustomBreadcrumbs from "../Components/CustomBreadcrumbs";
const stages = [
  { value: "Under Review", label: "Under Review" },
  { value: "Quotation Optimization", label: "Quotation Optimization" },
  { value: "Quotation Uploaded", label: "Quotation Uploaded" },
  { value: "Order Review", label: "Order Review" },
  { value: "Order Accepted", label: "Order Accepted" },
  { value: "Order in process", label: "Order in process" },
  { value: "Shipment Packed", label: "Shipment Packed" },
  { value: "Waiting for Pickup", label: "Waiting for Pickup" },
  { value: "In Transit", label: "In Transit" },
  { value: "Out for Delivery", label: "Out for Delivery" },
  { value: "Successfully Delivered", label: "Successfully Delivered" },
];

const initialQuotesData = {
  componentQuoptes: [
    {
      component: {
        compName: "",
        mountingType: "",
        package: "",
        make: "",
        designator: "",
        compDescription: "",
        quantity: 0,
        mpn: "",
      },
      quotes: [
        {
          id: 0,
          vendorName: "",
          vendorLocation: "",
          leadTime: 0,
          mom: 0,
          cost: 0,
          totalCost: 0,
          shippingCost: 0,
          isFav: false,
        },
      ],
    },
  ],
};

const modalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  backgroundColor: "#fff",
  boxShadow: 24,
  p: 4,
  borderRadius: "3px",
};

let componentQuoteObject = {
  component: {},
  quotes: [],
};
const finalQuotesData = initialQuotesData;

const vendorQuoteObject = {};

const FABDetails = (props) => {
  const { id } = useParams();
  const [files, setFiles] = useState([]);
  const [profile_data, set_profile] = React.useState(null);
  const [loading, set_loading] = React.useState(null);
  const [project, setProject] = React.useState(null);
  const [editNumBoards, setEditNumBoards] = useState({
    boards: 0,
    state: false,
  });
  const [newProjectModalOpen, setNewProjectModalOpen] = useState(false);
  const openNewProjectModal = () => setNewProjectModalOpen(true);
  const closeNewProjectModal = () => setNewProjectModalOpen(false);

  const { enqueueSnackbar } = useSnackbar();

  const [stage, setStage] = React.useState("Under Review");
  const [stageDate, setStageDate] = useState(new Date());
  const [comment, setComment] = useState("");
  let fetchProfile = () => {
    set_loading(true);
    axiosInstance
      .get("/users/getProfile")
      .then((resp) => {
        set_profile(resp.data);
        console.log(resp.data);
        set_loading(false);
      })
      .catch((err) => {
        set_loading(false);
      });
  };
  const singleProject = async () => {
    const id = window.location.pathname.split("/")[3];
    console.log(id);
    try {
      const res = await axiosInstance.get(`/fab/client/singleProject/${id}`);
      setProject(res.data);
      setEditNumBoards({
        ...editNumBoards,
        boards: res.data?.projectDetails.pcbQuantity,
      });
      console.log(res.data);
    } catch (e) {
      console.log(e);
    }
  };

  const updateNumBoards = async () => {
    const id = window.location.pathname.split("/")[3];
    try {
      const res = await axiosInstance.put(`/fab/admin/editProject/${id}`, {
        pcbQuantity: editNumBoards.boards,
      });
      console.log(res);
      if (res.status === 200) {
        enqueueSnackbar("Project Updated Successfully!", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Something went wrong", { variant: "error" });
      }
    } catch (err) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
    setEditNumBoards({
      ...editNumBoards,
      state: !editNumBoards.state,
    });
  };

  const [temp, setTemp] = useState("");

  useEffect(() => {
    setTemp(project?.currentStatus[project?.currentStatus.length - 1]?.status);
  }, [project]);
  console.log(temp);

  // const componentQuoteObject = {
  //   component: {},
  //   quotes: [],
  // };

  React.useEffect(() => {
    fetchProfile();
    singleProject();
  }, []);

  const stageSubmitHandler = async (values) => {
    try {
      let formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("fab", files[i]);
      }
      console.log(stage, stageDate, comment);
      formData.append("currentStage", stage);
      formData.append("date", stageDate);
      formData.append("comment", comment);
      const token = localStorage.getItem("token");
      console.log(formData);
      const res = await axiosInstance.post(
        `/fab/admin/addStage/${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "multipart/form-data",
          },
        }
      );
      console.log(res);
      enqueueSnackbar("Stage updated successfully", {
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
        variant: "success",
      });
    } catch (e) {
      console.log(e);
    }
  };

  console.log(project);
  console.log(profile_data);
  return (
    <Box>
      {!loading && profile_data && project ? (
        <>
          <DashboardHeader
            profile_data={profile_data.data}
            toggleLoggedIn={props.toggleLoggedIn}
            history={props.history}
            isBlack={true}
          ></DashboardHeader>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
              margin: "2rem",
              marginLeft: "8rem",
              fontSize: "1.2rem",
            }}
          >
            <Box
              sx={{
                fontSize: "2rem",
                fontWeight: "bold",
                marginBottom: "1rem",
              }}
            >
              <CustomBreadcrumbs
                data={[
                  {
                    name: "Home",
                    link: "/",
                  },
                  {
                    name: "EB PCB Fabricator",
                    link: "/admin/pcbFab/projects",
                  },
                  {
                    name: `${project?.projectName}-Project Detail`,
                    link: `/admin/pcbFabDetails/${project?._id}`,
                  },
                ]}
              />
              Project Detail Page
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "80%",
              }}
            >
              <Box sx={{ fontSize: "1.4rem", fontWeight: "bold" }}>
                {project.projectName} - {project.projectOwner.enterpriseName}
              </Box>
              <Box>Project Id: {project.projectId}</Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "80%",
                margin: "1rem 0rem",
              }}
            >
              <Box>
                Project Created on:{" "}
                {moment(project.createdOn).format("MMMM Do YYYY, h:mm a")}
              </Box>
              {/* <Box>
                Total Number Of Boards:{" "}
                {project?.numberOfBorads}
              </Box> */}
              <a href={project?.gerberFile?.location}>
                <Button variant="contained">Download Gerber</Button>
              </a>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  "& .MuiInput-input.Mui-disabled": {
                    color: "#000",
                    textFillColor: "#000",
                  },
                }}
              >
                Total Number Of Boards:{" "}
                <TextField
                  disabled={!editNumBoards.state}
                  variant="standard"
                  sx={{ width: "100px" }}
                  value={editNumBoards.boards}
                  InputProps={{ disableUnderline: !editNumBoards.state }}
                  onChange={(e) => {
                    setEditNumBoards({
                      ...editNumBoards,
                      boards: e.target.value,
                    });
                  }}
                />{" "}
              </Box>
              {!editNumBoards.state ? (
                <EditOutlinedIcon
                  sx={{ cursor: "pointer", transform: "scale(0.9)" }}
                  onClick={() => {
                    setEditNumBoards({
                      ...editNumBoards,
                      state: !editNumBoards.state,
                    });
                  }}
                />
              ) : (
                <SaveOutlinedIcon
                  sx={{ cursor: "pointer", transform: "scale(0.9)" }}
                  onClick={updateNumBoards}
                />
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "80%",
                margin: "1.4rem 0rem",
              }}
            >
              <Box
                sx={{
                  fontSize: "1.8rem",
                  margin: "0.5rem 0rem 1rem 0rem",
                  fontWeight: "500",
                }}
              >
                Stage Settings
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "40%",
                }}
              >
                <Box sx={{ textDecoration: "underline", color: "black" }}>
                  <a
                    style={{ color: "black" }}
                    href={`/admin/pcbFab/timeline/${id}`}
                    target="_blank"
                  >
                    {" "}
                    View TimeLine
                  </a>
                </Box>
                <Box
                  style={{ color: "black" }}
                  sx={{ textDecoration: "underline" }}
                >
                  <a
                    style={{ color: "black" }}
                    href={`/admin/pcbFab/quotations/${id}`}
                    target="_blank"
                  >
                    View Quotation Sheet
                  </a>
                </Box>
              </Box>
            </Box>
            {/* <form
              onSubmit={(e) => {
                e.preDefault();
                stageSubmitHandler(e);
              }} */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TextField
                  id="outlined currentStage"
                  name="currentStage"
                  select
                  label="Select Stage"
                  value={temp || ""}
                  defaultValue={temp || ""}
                  onChange={(e) => {
                    setTemp(e.target.value);
                    setStage(e.target.value);
                  }}
                  helperText="Please select your current stage"
                  sx={{ margin: "0.5rem 0rem 1rem 0rem" }}
                >
                  {stages.map((option) => (
                    <MenuItem
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "start",
                        flexDirection: "column",
                        padding: "1rem 0.5rem",
                      }}
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                {/* <TextField
                  id="outlined-basic comment"
                  name="comment"
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                  label="Per stage comment addition"
                  variant="outlined"
                  sx={{ margin: "-2rem 0rem 0rem 1rem" }}
                /> */}
              </Box>
              {/* <Box sx={{ margin: "1rem 0rem" }}> */}
              {/* <Button
                  sx={{ margin: "1rem" }}
                  variant="contained"
                  component="label"
                  onClick={openNewProjectModal}
                > */}
              {/* Document Upload per stage */}
              {/* <input type="file" hidden /> */}
              {/* </Button> */}
              {/* </Box> */}
              <Box sx={{ margin: "1rem 0rem" }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Completion Date"
                    inputFormat="MM/dd/yyyy"
                    value={stageDate}
                    onChange={(value) => setStageDate(value)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                {/* <Button
                  sx={{ margin: "0.5rem 0rem 1rem 0rem" }}
                  variant="contained"
                  component="label"
                > */}
                {/* </Button> */}
              </Box>
              <Button
                margin="1rem"
                m={2}
                sx={{ margin: 1 }}
                variant="contained"
                onClick={stageSubmitHandler}
              >
                Save
              </Button>
              {/* </form> */}
            </Box>
          </Box>

          <Modal
            open={newProjectModalOpen}
            onClose={closeNewProjectModal}
            aria-labelledby="modal-modal-title"
          >
            <Box sx={modalStyles}>
              <p
                style={{ textAlign: "center" }}
                className="explorer-title"
                id="modal-modal-title"
              >
                Elecbits BOM Explorer - New Project
              </p>
              <Paper>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-end",
                    flexDirection: "column",
                    padding: "1rem",
                  }}
                >
                  {/* <Box sx={{display: 'flex'}}>
                            <p>Please make sure that the BOM excel is in the correct format.</p>
                            <br />
                            <p>Download sample</p>
                            <button onClick={() => props.history.push("/bomExplorer/project")}>Submit</button>
                        </Box> */}
                  <DropzoneArea
                    maxFileSize={100000000}
                    init={() => {}}
                    onChange={(files) => {
                      setFiles(files);
                    }}
                    id="documents"
                    name="documents"
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      Please make sure that the FAB excel is in the correct
                      format. <a href="/admin/homepage">Download Sample</a>
                    </Box>
                    <Button
                      sx={{ margin: "1rem" }}
                      variant="contained"
                      onClick={closeNewProjectModal}
                    >
                      close
                    </Button>
                  </Box>
                </Box>
              </Paper>
            </Box>
          </Modal>
        </>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={DashboardLogo1} style={{ width: "150px" }} alt="logo"></img>
          <CircularProgress style={{ marginLeft: "20px" }}></CircularProgress>
        </Box>
      )}
    </Box>
  );
};

export default FABDetails;
