import React, { useState, useEffect } from "react";

//utils
import { Box, Typography } from "@mui/material";

import { axiosInstance } from "../../utils/axiosInterceptor";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import { useSnackbar } from "notistack";

//components
import DashboardHeader from "../../ClientEngagement/DashboardHeader";
import CustomBreadcrumbs from "../../Components/CustomBreadcrumbs";

//styles
import {
  AllConsultationsContainer,
  PageContainer,
} from "./AllConsultations.styles";
import ConsultationOverview from "./ConsultationOverview";

const AllConsultations = () => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [consultations, setConsultations] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const fetchConsultations = async () => {
    const res = await axiosInstance.get("/users/allConsultations");
    console.log(res);
    if (res.status == 200) {
      setConsultations(res.data.consultations);
    } else {
      enqueueSnackbar("Somehting went wrong!");
    }
  };

  const fetchProfile = () => {
    setLoading(true);
    axiosInstance
      .get("/users/getProfile")
      .then((resp) => {
        setProfile(resp.data);
        console.log(resp);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchProfile();
    fetchConsultations();
  }, []);

  return (
    <Box>
      {!loading && consultations ? (
        <PageContainer>
          <DashboardHeader profile_data={profile.data} isBlack={true} />
          <AllConsultationsContainer>
            <CustomBreadcrumbs
              data={[
                {
                  name: "Home",
                  link: "/",
                },
                {
                  name: "EB Consultation Dashboard",
                  link: "/admin/allConsultations",
                },
              ]}
            />
            <div className="head-title">Elecbits Consultation Dashboard</div>
            <div className="title">All consultation requests</div>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              {consultations
                .slice(0)
                .reverse()
                .map((consultation, idx) => (
                  <ConsultationOverview
                    consultation={consultation}
                    key={idx}
                    setConsultations={setConsultations}
                  />
                ))}
            </Box>
          </AllConsultationsContainer>
        </PageContainer>
      ) : null}
    </Box>
  );
};

export default AllConsultations;
