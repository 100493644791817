import React, { useState } from "react";

import { Box, Grid, styled, Typography, Avatar, Button } from "@mui/material";
import moment from "moment";
import ConsultationDetails from "./ConsultationDetailsModal";
import DeleteModal from "../../Components/DeleteModal";
import { axiosInstance } from "../../utils/axiosInterceptor";
import { useSnackbar } from "notistack";

const ConsultaionContainer = styled(Box)(() => ({
  width: "40vw",
  height: "fit-content",
  padding: "20px 20px",
  borderRadius: "10px",
  backgroundColor: "white",
  boxShadow: "0px 0px 30px rgba(139, 139, 139, 0.15)",
  display: "flex",
  flexDirection: "row",
  gap: "50px",

  "& .MuiAvatar-root": {
    width: "60px",
    height: "60px",
  },

  "& .bd": {
    fontSize: "0.9em",
    color: "#828282",
    fontWeight: 600,
  },
}));

const CustomGrid = styled(Box)(() => ({
  display: "flex",
  gap: "10px",
  alignItems: "center",
}));

const CustomButton = styled(Button)(({ bgc }) => ({
  "&&&": {
    backgroundColor: bgc || "#FF972A",
    color: "#fff",
    fontSize: "0.8em",
    textTransform: "none",
    padding: "3px 30px",
    borderRadius: "5px",
    fontWeight: 500,
  },
}));

const ConsultationOverview = ({ consultation, setConsultations }) => {
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const toggleDetailModal = () => {
    setShowDetailModal(!showDetailModal);
  };

  const handleDelete = async () => {
    try {
      const res = await axiosInstance.delete(
        `/users/deleteConsultation/${consultation._id}`
      );
      enqueueSnackbar("Consultation deleted successfully!", {
        variant: "success",
      });
      setConsultations((prev) => {
        const nc = prev.filter((p) => p._id !== consultation._id);
        return nc;
      });
    } catch (err) {
      console.log(err);
      enqueueSnackbar("Something went wrong!", { variant: "error" });
    }
  };

  return (
    <ConsultaionContainer>
      <Box sx={{ width: "10%" }}>
        <Avatar src={consultation?.userId?.profilePic} />
      </Box>
      <Box>
        <CustomGrid>
          <Typography className="bd">Name:</Typography>
          <Typography>{consultation.details?.fullName || "Test"}</Typography>
        </CustomGrid>
        <CustomGrid>
          <Typography className="bd">Company:</Typography>
          <Typography>{consultation.details?.company || "Test"}</Typography>
        </CustomGrid>
        <CustomGrid>
          <Typography className="bd">Designation:</Typography>
          <Typography>{consultation.details?.designation || "Test"}</Typography>
        </CustomGrid>
        <CustomGrid>
          <Typography className="bd">Email:</Typography>
          <Typography>{consultation.details?.email || "Test"}</Typography>
        </CustomGrid>
        <CustomGrid>
          <Typography className="bd">Created At:</Typography>
          <Typography>
            {moment(consultation?.createdAt).format("hh:mm a, Do MMMM YYYY") ||
              "NaN"}
          </Typography>
        </CustomGrid>
        <Box
          sx={{
            marginTop: "20px",
            display: "flex",
            flexDirection: "row",
            gap: "20px",
          }}
        >
          <CustomButton bgc="#FF972A" onClick={toggleDetailModal}>
            VIEW
          </CustomButton>
          <CustomButton bgc="#D11A2A" onClick={toggleDeleteModal}>
            DELETE
          </CustomButton>
          <ConsultationDetails
            state={showDetailModal}
            toggleModal={toggleDetailModal}
            consultation={consultation}
          />
          <DeleteModal
            state={showDeleteModal}
            toggleModal={toggleDeleteModal}
            onYes={handleDelete}
            onNo={toggleDeleteModal}
          />
        </Box>
      </Box>
    </ConsultaionContainer>
  );
};

export default ConsultationOverview;
