import { styled, Box, useTheme, Button } from "@mui/material";

export const TimelineBox = styled(Box)(({ theme, status }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  boxShadow: "0px 0px 30px rgba(139, 139, 139, 0.15)",
  width: "80%",
  padding: "20px",
  marginBottom: "20px",
  height: "auto",
  borderRadius: 15,
  backgroundColor:
    status == "active" ? "#dad9ff" : status == "locked" ? "#bfc1c4" : "#fff",
  fontFamily: "Poppins",
  marginLeft: "30px",
  [theme.breakpoints.down("md")]: {
    width: "70vw",
    flexDirection: "column",
    alignItems: "start",
    gap: 10,
    padding: "1rem",
  },
}));

export const TimelineInnerBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  flexDirection: "column",
  width: "75%",
  maxWidth: "75%",
  marginRight: "0.2rem",
}));

export const OuterContainer = styled(Button)(() => ({
  "&&&": {
    flex: 1,
    borderRadius: 24,
    padding: 1,
    background:
      "linear-gradient(90deg, #F67C1B 20%, #E95252 40%,#E95252 60%,#0275FC 80%,#0275FC 100%)",
    textTransform: "none",
    marginTop: "10px",
  },
}));

export const InnerContainer = styled(Box)(({ disabled }) => ({
  backgroundColor: disabled ? "transparent" : "#fff",
  width: "100%",
  height: "100%",
  borderRadius: 21,
  padding: "5px 13px",
  color: "#000",
  fontWeight: 600,

  "& path": {
    fill: "#000",
  },
}));


