import React, { useState, useEffect } from "react";

//libs
import {
  Modal,
  Box,
  styled,
  Button,
  Slide,
  LinearProgress,
} from "@mui/material";
import { axiosInstance } from "../utils/axiosInterceptor";
import { useSnackbar } from "notistack";

//components
import CustomFileDroper from "../Components/CustomFileDropper";

const ModalContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: "36vw",
  height: "fit-content",
  left: "32vw",
  top: "15vh",
  backgroundColor: "#fff",
  boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.2)",
  borderRadius: 15,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "30px 30px 10px 30px",
  gap: "20px",
  "& .heading": {
    fontSize: "1.2em",
    fontWeight: 700,
  },
  [theme.breakpoints.down("md")]: {
    width: "90vw",
    left: "5vw",
  },
}));

export const CustomButton = styled(Button)(() => ({
  "&&&": {
    backgroundColor: "#FF972A",
    color: "#fff",
    textTransform: "none",
    padding: "5px 50px",
    borderRadius: "8px",
    fontWeight: 500,
    margin: "10px auto",
  },
}));

const VendorDocModal = ({ state, toggleModal, id, setFiles }) => {
  const [bomFile, setBomFile] = useState(null);
  const [progress, setProgress] = useState({
    loading: false,
    value: 0,
  });

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    console.log(bomFile, "here e go!");
  }, [bomFile]);

  const handleSubmit = () => {
    if (bomFile !== null) {
      setFiles(bomFile);
      toggleModal();
    } else {
      enqueueSnackbar("Please select a file!", { variant: "error" });
    }
  };

  return (
    <Modal open={state} onClose={toggleModal}>
      <Slide in={state} direction="up">
        <ModalContainer>
          <div className="heading">Elecbits VMS</div>
          <CustomFileDroper
            small
            setFile={setBomFile}
            headerText="Drag and drop to upload a file"
            subText=""
          />

          <CustomButton onClick={handleSubmit}>Submit</CustomButton>
        </ModalContainer>
      </Slide>
    </Modal>
  );
};

export default VendorDocModal;
