import React, { useState, useEffect } from "react";

//libs
import {
  Modal,
  Box,
  styled,
  Button,
  Slide,
  Typography,
  TextField,
  Grid,
} from "@mui/material";
import moment from "moment";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { useSnackbar } from "notistack";
import { axiosInstance } from "../../utils/axiosInterceptor";

const ModalContainer = styled(Box)(({theme}) => ({
  position: "absolute",
  width: "36vw",
  height: "fit-content",
  left: "32vw",
  top: "20vh",
  backgroundColor: "#fff",
  boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.2)",
  borderRadius: 15,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "30px",
  gap: "20px",
  "& .heading": {
    fontSize: "1.2em",
    fontWeight: 700,
  },
  [theme.breakpoints.down('md')]:{
    width:'90vw',
    left:'5vw',
    
  }
}));

const InternalNameField = ({ data, label, id, isAdmin }) => {
  const [internalState, setInternalState] = useState({
    editMode: false,
    data: data || "default",
  });
  const { enqueueSnackbar } = useSnackbar();

  const handleSaveInternalState = async () => {
    console.log(internalState);

    try {
      const res = await axiosInstance.put(`/fab/admin/editProject/${id}`, {
        [label]: internalState.data,
      });
      console.log(res);

      enqueueSnackbar("Project Updated Successfully!", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
    setInternalState({
      ...internalState,
      editMode: false,
    });
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", marginRight: "-30px" }}>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          "& .MuiInput-input.Mui-disabled": {
            color: "#000",
            textFillColor: "#000",
          },
        }}
      >
        <TextField
          disabled={!internalState.editMode}
          variant="standard"
          sx={{
            width: "100%",
            "& input": { textAlign: "start", paddingRight: "10px" },
          }}
          value={internalState.data}
          InputProps={{ disableUnderline: !internalState.editMode }}
          onChange={(e) => {
            setInternalState({
              ...internalState,
              data: e.target.value,
            });
          }}
        />{" "}
      </Box>

      {isAdmin ? (
        <>
          {!internalState.editMode ? (
            <EditOutlinedIcon
              sx={{ cursor: "pointer", transform: "scale(0.9)" }}
              onClick={() => {
                setInternalState({
                  ...internalState,
                  editMode: !internalState.editMode,
                });
              }}
            />
          ) : (
            <SaveOutlinedIcon
              sx={{ cursor: "pointer", transform: "scale(0.9)" }}
              onClick={handleSaveInternalState}
            />
          )}
        </>
      ) : null}
    </Box>
  );
};

const FabDetailsModal = ({ state, toggleModal, project, isAdmin }) => {
  const [data, setData] = useState(null);

  const generateData = (project) => {
    let data;
    data = {
      ID: project.projectId,
      "Created on":
        moment(project?.createdOn).format("MMMM Do YYYY, h:mm a") || "no date",
      "Internal Name": (
        <InternalNameField
          data={project.internalName}
          label="internalName"
          id={project._id}
          isAdmin={isAdmin}
        />
      ),
      Quantity: (
        <InternalNameField
          data={project.projectDetails.pcbQuantity}
          label="pcbQuantity"
          id={project._id}
          isAdmin={isAdmin}
        />
      ),
      "Project Name": project.projectName,
      "Surface Finish": project.projectDetails.surfaceFinish,
      "Delivery Format": project.projectDetails.deliveryFormat,
      "Stencil Requirement": project.projectDetails.isStencil ? "Yes" : "No",
    };

    data = {
      ...data,
      "Other Specification": project.projectDetails.otherSpecification,
    };
    return data;
  };

  useEffect(() => {
    if (project) {
      const d = generateData(project);
      setData(d);
    }
  }, [project]);

  return (
    <Modal open={state} onClose={toggleModal}>
      <Slide in={state} direction="up">
        {project ? (
          <ModalContainer>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "baseline",
              }}
            >
              <Typography style={{ fontWeight: 600, fontSize: "1.2em" }}>
                {project?.projectName} - {project?.projectOwner?.enterpriseName}
              </Typography>
            </Box>
            <Box sx={{ width: "100%", marginTop: "20px" }}>
              {data !== null &&
                Object.keys(data).map((key) => (
                  <Grid container sx={{ width: "100%", marginBottom: "10px" }}>
                    <Grid item md={6}>
                      <Typography sx={{ color: "#8B8B8B" }}>{key}:</Typography>
                    </Grid>
                    <Grid item md={6}>
                      {data[key]}
                    </Grid>
                  </Grid>
                ))}
            </Box>
          </ModalContainer>
        ) : null}
      </Slide>
    </Modal>
  );
};

export default FabDetailsModal;
