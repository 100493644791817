import React, { useState, useEffect } from "react";
import "../HomeComponent/Home.css";
import { Container, Row, Col } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

import PCBImage from "../assests/pcbImg.svg";
import DashboardHeader from "../ClientEngagement/AdminDashboardHeader";
import Box from "@mui/material/Box";
import { axiosInstance } from "../utils/axiosInterceptor";
import CircularProgress from "@material-ui/core/CircularProgress";
import DashboardLogo1 from "../assests/elecbitsLogo.svg";
import _3dServices from "../assests/_3dServices.png";
import bomExplorer from "../assests/bomExplorer.png";
import consultations from "../assests/consultations.png";

import customerEngagement from "../assests/customerEngagement.png";

import pcbArena from "../assests/pcbArena.png";
import pcbAssembly from "../assests/pcbAssembly.png";
import { Typography, InputAdornment, useTheme } from "@mui/material";
import UserOverview from "../Components/UserOverview";
import { Search } from "@mui/icons-material";
import CustomBreadcrumbs from "../Components/CustomBreadcrumbs";
import * as XLSX from 'xlsx';
const StyledButton = withStyles({
  root: {
    background: "#6200EE",
    color: "white",
    "&:hover": {
      backgroundColor: "#6200EE",
    },
  },
})(Button);

export default function AllUsers(props) {
  const [profile_data, set_profile] = React.useState(null);
  const [loading, set_loading] = React.useState(null);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFiltetredUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const theme = useTheme();

  const includesName = (searchQuery, firstName, lastName) => {
    if (firstName === undefined || lastName === undefined) return false;
    const nm = firstName + " " + lastName;
    return nm.toLowerCase().includes(searchQuery.toLowerCase());
  };

  const includesOrganization = (searchQuery, enterpriseName) => {
    if (enterpriseName === undefined) return false;
    return enterpriseName.toLowerCase().includes(searchQuery.toLowerCase());
  };

  useEffect(() => {
    if (localStorage.PM && props.history.location.pathname.includes("/admin")) props.history.push("/pm/allUsers");
  }, []);
  useEffect(() => {
    setFiltetredUsers(
      users.filter(
        (u) =>
          u?.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
          includesName(searchQuery, u?.firstName, u?.lastName) ||
          includesOrganization(searchQuery, u?.enterpriseName)
      )
    );
  }, [searchQuery]);

  let fetchProfile = () => {
    set_loading(true);
    axiosInstance
      .get("/users/getProfile")
      .then((resp) => {
        set_profile(resp.data);
        set_loading(false);
      })
      .catch((err) => {
        set_loading(false);
      });
  };

  const fetchUsers = async () => {
    const res = await axiosInstance.get("/users/admin/getAllUsers");
    console.log(res, "here i am");
    if (res.status === 200) {
      setUsers(res.data.users);
      setFiltetredUsers(res.data.users);
    }
  };
  const exportUserData = () => {
    let formattedData = [];
    if (filteredUsers.length == 0) return;

    formattedData = filteredUsers.map((user) => ({
      email: user?.email,
      createdOn: user?.createdOn || "N/A",
      profilePic: user?.profilePic || "N/A",
      googleId: user?.googleId || "N/A",
      firstName: user?.firstName || "N/A",
      lastName: user?.lastName || "N/A",
      shippingAddress: JSON.stringify(user?.shippingAddress) || "N/A",
      organizationMail: user?.organizationMail || "N/A",
      industry: user?.industry || "N/A",
      stage: user?.stage || "N/A",
      fullName: user?.fullName || "N/A",
      enterpriseName: user?.enterpriseName || "N/A",
      phoneNumber: user?.phoneNumber || "N/A",
      enterpriseOrStartUpOrIndividual: user?.enterpriseOrStartUpOrIndividual || "N/A",
      services: JSON.stringify(user?.services) || "N/A",
      supplierDetails: JSON.stringify(user?.supplierDetails) || "N/A",
      clientDetails: JSON.stringify(user?.clientDetails) || "N/A",
      currentStage: user?.currentStage || "N/A",
      location: user?.location || "N/A",
      designation: user?.designation || "N/A",
    }));

    const ws = XLSX.utils.json_to_sheet(formattedData);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'excelfile');

    const wbout = XLSX.writeFileXLSX(wb, 'users.xlsx');
  }

  React.useEffect(() => {
    fetchProfile();
    fetchUsers();
  }, []);
  return (
    <Box>
      {!loading && profile_data ? (
        <Box>
          <DashboardHeader
            profile_data={profile_data.data}
            toggleLoggedIn={props.toggleLoggedIn}
            history={props.history}
            isBlack
          ></DashboardHeader>
          <Container>
            <Typography
              sx={{ fontSize: "2em", fontWeight: 400, marginTop: "32px" }}
            >
              <CustomBreadcrumbs
                data={[
                  {
                    name: "Home",
                    link: "/",
                  },
                  {
                    name: "Platform users",
                    link: "/admin/allUsers",
                  },
                ]}
              />
              Platform Users 🎰
            </Typography>
            <Typography sx={{ color: "#0275FC", marginTop: "16px" }}>
              Total user count: {users.length}
            </Typography>
            <Box
              sx={{
                width: "30%",
                marginTop: "20px",
                marginBottom: "30px",
                [theme.breakpoints.down("lg")]: { width: "40%" },
                [theme.breakpoints.down("md")]: { width: "60%" },
                [theme.breakpoints.down("sm")]: { width: "80%" },
              }}
            >
              <TextField
                placeholder="Search by name,email,organization"
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Button style={{
              margin: "0px 0px 20px 0px", backgroundColor: "#0275FC", padding: "5px 20px", color: "#fff", '&:hover': {
                backgroundColor: "#0275FC", color: "#fff"
              }

            }} onClick={exportUserData} >EXPORT USERS</Button>
            <Box>
              {
                filteredUsers.length == 0 && <Typography sx={{ fontSize: '1.5em', display: 'flex', gap: '20px', alignItems: "center" }} >Fetching users <CircularProgress sx={{ height: "5px", width: "5px" }} /> </Typography>
              }
              {filteredUsers
                .slice()
                .reverse()
                .map((user) => (
                  <UserOverview
                    user={user}
                    setUsers={setFiltetredUsers}
                    users={filteredUsers}
                  />
                ))}
            </Box>
          </Container>
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={DashboardLogo1} style={{ width: "150px" }} alt="logo"></img>
          <CircularProgress style={{ marginLeft: "20px" }}></CircularProgress>
        </Box>
      )}
    </Box>
  );
}
