import React, { useState } from 'react';

//libs and utils
import { Box, useTheme, Button, Chip } from '@mui/material';

//components
// import CommentModal from "./CommentModal";
import CommentModal from '../../Components/CommentModal';

//styles
import {
  TimelineBox,
  TimelineInnerBox,
  OuterContainer,
  InnerContainer,
} from './Timeline.styles';

//assets
import { ReactComponent as ViewIcon } from '../../assests/view.svg';
import { ReactComponent as LockIcon } from '../../assests/lock.svg';
import { ReactComponent as CompleteIcon } from '../../assests/complete.svg';
import { ReactComponent as ActiveIcon } from '../../assests/active.svg';

const CustomButton = ({ children, disabled, ...props }) => {
  return (
    <OuterContainer disabled={disabled} {...props}>
      <InnerContainer disabled={disabled}>{children}</InnerContainer>
    </OuterContainer>
  );
};

const TimelineComponent = ({
  number,
  stage,
  status,
  project,
  isAdmin,
  isClose,
}) => {
  const [showCommentModal, setShowCommentModal] = useState(false);
  const { breakpoints } = useTheme();

  const toggelCommentModal = () => {
    setShowCommentModal(!showCommentModal);
  };

  let customChip = () => {};

  if (status === 'active') {
    customChip = () => (
      <Chip
        label={status[0].toUpperCase() + status.slice(1)}
        icon={<ActiveIcon />}
        sx={{
          backgroundColor: '#FF972A',
          color: '#fff',
          padding: '5px 10px',
          '& svg': { transform: 'scale(0.8)' },
        }}
      />
    );
  } else if (status === 'complete') {
    customChip = () => (
      <Chip
        label={status[0].toUpperCase() + status.slice(1)}
        icon={<CompleteIcon />}
        sx={{
          backgroundColor: '#0702FC',
          color: '#fff',
          padding: '5px 10px',
          '& svg': { transform: 'scale(0.8)' },
        }}
      />
    );
  } else if (status === 'locked') {
    customChip = () => (
      <Chip
        label={status[0].toUpperCase() + status.slice(1)}
        icon={<LockIcon />}
        sx={{
          backgroundColor: '#8B8B8B',
          color: '#fff',
          padding: '5px 10px',
          '& svg': { transform: 'scale(0.8)' },
        }}
      />
    );
  }

  return (
    <TimelineBox status={status}>
      <TimelineInnerBox>
        <Box sx={{ fontWeight: '600', fontSize: '1.4rem' }}>
          {number}. {stage.stage}
        </Box>
        <Box
          sx={{
            width: '95%%',
            maxWidth: '95%%',
            marginBottom: '0.5rem',
            fontSize: '0.9em',
            color: '#2F2F2F',
            [breakpoints.down('md')]: {
              marginLeft: '0rem',
              width: '100%',
              maxWidth: '100%',
            },
          }}
        >
          {stage.desc}
        </Box>
      </TimelineInnerBox>
      <Box
        sx={{
          width: '20%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'end',
          '& .Mui-disabled': {
            background: 'none !important',
            backgroundColor: '#8b8b8b !important',
          },
          [breakpoints.down('md')]: {
            width: '100%',
            alignItems: 'start',
          },
        }}
      >
        {customChip()}
        <CustomButton
          disabled={status === 'locked' || isClose}
          onClick={toggelCommentModal}
        >
          <ViewIcon /> View Details
        </CustomButton>
        <CommentModal
          state={showCommentModal}
          toggleModal={toggelCommentModal}
          number={number}
          project={project}
          service="fab"
          isAdmin={isAdmin}
        />
        {/* <Button onClick={() => {}} variant="outlined" disabled={!true}>
          View Details
        </Button> */}
      </Box>
    </TimelineBox>
  );
};

export default TimelineComponent;
