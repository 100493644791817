import React, { useState, useEffect } from 'react';

//components
import CustomFileDropper from '../../Components/CustomFileDropper';

//libs
import {
  Modal,
  Box,
  styled,
  Button,
  Slide,
  Typography,
  TextField,
  Avatar,
  InputAdornment,
} from '@mui/material';
import { axiosInstance } from '../../utils/axiosInterceptor';
import { useSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';
import { useParams } from 'react-router-dom';

//assets
import { ReactComponent as ClockIcon } from '../../assests/clock.svg';
import { ReactComponent as DocumentIcon } from '../../assests/document.svg';
import { ReactComponent as CommentIcon } from '../../assests/comment.svg';
import { ReactComponent as DownloadIcon } from '../../assests/download.svg';
import { ReactComponent as AddCommentIcon } from '../../assests/addComment.svg';
import moment from 'moment';

const ModalContainer = styled(Box)(() => ({
  position: 'absolute',
  width: '70vw',
  height: '70vh',
  left: '15vw',
  top: '15vh',
  backgroundColor: '#fff',
  boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.2)',
  borderRadius: 15,
  display: 'flex',
  flexDirection: 'column',
  padding: '40px',
  gap: '20px',
  '& .heading': {
    fontSize: '1.2em',
    fontWeight: 700,
    fontFamily: 'Poppins',
  },
  '& legend': {
    width: 'unset',
  },
  '& .MuiTypography-root': {
    fontFamily: 'Poppins',
  },
  fontFamily: 'Poppins',
}));
const UploadModalContainer = styled(Box)(() => ({
  position: 'absolute',
  width: '36vw',
  height: 'fit-content',
  left: '32vw',
  top: '15vh',
  backgroundColor: '#fff',
  boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.2)',
  borderRadius: 15,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  gap: '20px',
  '& .heading': {
    fontSize: '1.2em',
    fontWeight: 700,
    fontFamily: 'Poppins',
  },
  '& legend': {
    width: 'unset',
  },
  '& .MuiTypography-root': {
    fontFamily: 'Poppins',
  },
  fontFamily: 'Poppins',
}));

const CustomButton = styled(LoadingButton)(() => ({
  '&&&': {
    backgroundColor: '#FF972A',
    color: '#fff',
    textTransform: 'none',
    padding: '5px 50px',
    borderRadius: '8px',
    fontWeight: 500,
  },
}));

const hyperlink = (textContent) => {
  let a = textContent?.replace(
    /(https?:\/\/[^\s]+)/g,
    (href) => `<a href="${href}" target="_blank">${href}</a>`
  );
  return { __html: a };
};

const SupplierCommentModal = ({
  state,
  toggleModal,
  service,
  type,
  quotation,
  number,
  stage,
}) => {
  const [comment, setComment] = useState('');
  const [allComments, setAllComments] = useState([]);
  const [allDocs, setAllDocs] = useState([]);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [uploadFilesLoading, setUploadFilesLoading] = useState({
    progress: 0,
    loading: false,
  });
  const [stageData, setStageData] = useState(null);

  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const handleChange = (e) => {
    setComment(e.target.value);
  };

  const toggleUploadModal = () => {
    setShowUploadModal(!showUploadModal);
  };

  useEffect(() => {
    if (quotation?.currentStatus && quotation.currentStatus?.length >= number) {
      setAllComments([...quotation?.currentStatus[number - 1]?.remarks]);
      if (quotation?.currentStatus[number - 1].remarkDocs !== undefined) {
        setAllDocs([...quotation?.currentStatus[number - 1]?.remarkDocs]);
      }
      setStageData(quotation?.currentStatus[number - 1]);
    }
  }, [quotation]);

  useEffect(() => {
    console.log(allComments);
  }, [allComments]);

  const addComment = async (e) => {
    e.preventDefault();
    //add comment
    const data = {
      comment,
      stageNumber: number - 1,
    };

    if (comment.length == 0) {
      enqueueSnackbar('Please add a comment!', { variant: 'error' });
      return;
    }

    try {
      console.log(comment);

      let url = `/supplier/bom/addComment/${quotation._id}`;

      const res = await axiosInstance.post(url, data);
      console.log(res);
      setAllComments([...allComments, res.data.comment]);

      enqueueSnackbar('Comment added successfully!', { variant: 'success' });
      setComment('');
    } catch (e) {
      console.log(e);
    }
  };

  const handleName = (name, len) => {
    if (len === null) {
      len = 20;
    }
    if (name === null) {
      return name;
    }

    if (name.length > len) {
      return name.substring(0, len) + '...';
    } else {
      return name;
    }
  };

  const handleUploadFiles = async () => {
    console.log(uploadFiles);
    let formData = new FormData();
    formData.append('stageNumber', number - 1);

    let url = `/supplier/bom/addFiles/${quotation._id}`;
    uploadFiles.forEach((fl) => formData.append('bom', fl));
    // if (service === "3d") {
    //   url = `/3d/admin/addFiles/${type}/${quotation._id}`;
    //   uploadFiles.forEach((fl) => formData.append("3d", fl));
    // } else if (service == "fab") {
    //   url = `/fab/admin/addFile/${quotation._id}`;
    //   uploadFiles.forEach((fl) => formData.append("fab", fl));
    // } else if (service == "bom") {
    //   url = `/bom/admin/addFile/${quotation._id}`;
    //   uploadFiles.forEach((fl) => formData.append("bom", fl));
    // }

    setUploadFilesLoading({ ...uploadFilesLoading, loading: true });
    try {
      const token = localStorage.getItem('token');
      const res = await axiosInstance.post(url, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-type': 'multipart/form-data',
        },
      });
      console.log(res);

      setAllDocs([...res?.data?.docs]);

      setUploadFilesLoading({ ...uploadFilesLoading, loading: false });
      enqueueSnackbar('Files uploaded successfully!', { variant: 'success' });
      toggleUploadModal();
    } catch (err) {
      console.log(err);
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  return (
    <Modal open={state} onClose={toggleModal}>
      <Slide in={state} direction="up">
        <ModalContainer>
          <Box sx={{ width: '100%', height: '10%' }}>
            <div className="heading">
              {id}-{stage.label}
            </div>
            {/* <Typography
              sx={{
                marginTop: "10px",
                fontFamily: "Poppins",
                "& svg": { transform: "scale(0.8)", marginRight: "5px" },
              }}
            >
              <ClockIcon />
              Under Review
            </Typography> */}
            <Typography sx={{ marginTop: '10px', fontWeight: '600' }}>
              {moment(stageData?.completionDate).format('MMMM Do YYYY')}
            </Typography>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              height: '90%',
              padding: '0px 0px 30px 0px',
              marginRight: '30px',
            }}
          >
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <Typography
                sx={{ marginTop: '20px', '& svg': { transform: 'scale(0.8)' } }}
              >
                <DocumentIcon /> Documents
              </Typography>

              <Box sx={{ margin: '10px 0px' }}>
                <CustomButton onClick={toggleUploadModal}>
                  UPLOAD DOC
                </CustomButton>
              </Box>

              <Modal open={showUploadModal} onClose={toggleUploadModal}>
                <Slide in={showUploadModal} direction="up">
                  <UploadModalContainer>
                    <div className="heading">Upload files</div>
                    <CustomFileDropper
                      small
                      setFile={setUploadFiles}
                      uploadFiles={uploadFiles}
                      headerText="Drag or drop the files to be uploaded"
                      subText="Currently supported file formats are .csv, .xlsx (Excel sheet)"
                    />
                    <CustomButton
                      onClick={handleUploadFiles}
                      loading={uploadFilesLoading.loading}
                      loadingPosition="end"
                    >
                      UPLOAD
                    </CustomButton>
                  </UploadModalContainer>
                </Slide>
              </Modal>

              <Box
                sx={{
                  flex: 1,
                  overflowY: 'auto',
                  marginTop: '0px',
                  padding: '10px 0px',
                }}
              >
                {allDocs.map((i) => (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      border: '2px solid #E8E8E8',
                      margin: '10px 5px',
                      padding: '15px 15px',
                      width: '70%',
                      borderRadius: '10px',
                      '& svg': { transform: 'scale(0.8)' },
                    }}
                  >
                    <Typography>
                      {handleName(i.originalname || i.originalName, 25)}
                    </Typography>
                    <a href={i.location}>
                      {' '}
                      <DownloadIcon style={{ cursor: 'pointer' }} />
                    </a>
                  </Box>
                ))}
              </Box>
            </Box>
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                minWidth: '50%',
                borderLeft: '1px solid #8B8B8B4D',
                padding: '0px 30px 0px 30px',
                height: '100%',
              }}
            >
              <Typography
                sx={{
                  '& svg': { transform: 'scale(0.8)' },
                  marginBottom: '10px',
                }}
              >
                <CommentIcon /> Comments
              </Typography>
              <Box sx={{ width: '100%', flex: 1, overflowY: 'auto' }}>
                {allComments.length > 0 &&
                  allComments
                    .slice(0)
                    .reverse()
                    .map((remark) => (
                      <Box
                        sx={{
                          display: 'flex',
                          whiteSpace: 'pre-line',
                          justifyContent: 'space-between',
                          // boxShadow: "0px 0px 30px rgba(139, 139, 139, 0.15)",
                          border: '2px solid #E8E8E8',
                          margin: '10px 5px',
                          padding: '10px 5px',
                          width: '95%',
                          overflow: 'visible',
                          borderRadius: '8px',
                          gap: '10px',
                          alignItems: 'center',
                        }}
                      >
                        <Box
                          sx={{
                            width: '20%',
                            height: '100%',
                            display: 'grid',
                            placeItems: 'center',
                          }}
                        >
                          <Avatar
                            sx={{ width: '65px', height: '65px' }}
                            src={remark.userProfilePic}
                          />
                        </Box>
                        <Box sx={{ flex: 1, maxWidth: '80%' }}>
                          <Typography
                            sx={{ color: '#2F2F2F', fontSize: '0.8em' }}
                          >
                            {remark.userName}
                          </Typography>
                          <Typography
                            sx={{ color: '#8B8B8B', fontSize: '0.7em' }}
                          >
                            {moment(remark.date).format('MMMM Do YYYY, h:mma')}
                          </Typography>
                          <Typography sx={{ color: '#2F2F2F' }}>
                            {' '}
                            <div
                              style={{
                                maxWidth: '100%',
                                overflowWrap: 'break-word',
                              }}
                              dangerouslySetInnerHTML={hyperlink(
                                remark.comment
                              )}
                            />
                          </Typography>
                        </Box>
                      </Box>
                    ))}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  gap: '10px',
                  alignItems: 'center',
                  marginTop: '30px',
                }}
              >
                <Avatar />
                <form onSubmit={addComment} style={{ width: '100%' }}>
                  <TextField
                    autoFocus
                    value={comment}
                    onChange={handleChange}
                    sx={{
                      width: '100%',
                      backgroundColor: '#E8E8E880',
                      border: 'none',
                      color: '#2F2F2F',
                      borderRadius: '8px',
                      '& fieldset': {
                        border: 'none',
                      },
                      '& input': {
                        padding: '10px',
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <AddCommentIcon
                            onClick={addComment}
                            style={{ cursor: 'pointer' }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </form>
              </Box>
            </Box>
          </Box>
        </ModalContainer>
      </Slide>
    </Modal>
  );
};

export default SupplierCommentModal;
