import React, { useState, useEffect } from 'react';

//components
import CustomBreadcrumbs from '../../Components/CustomBreadcrumbs';
import DashboardHeader from '../../ClientEngagement/DashboardHeader';

//utils
import { axiosInstance } from '../../utils/axiosInterceptor';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  InputAdornment,
  useTheme,
  TablePagination,
  FormControl,
  Select,
  InputLabel,
} from '@mui/material';
import { MenuItem } from '@material-ui/core';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { stages } from './utils';
import { useHistory } from 'react-router-dom';

import { useSnackbar } from 'notistack';

//styles
import {
  PageContainer,
  ColosseumProjectsContainer,
  StyledInput,
  CustomSelect,
  CustomInput,
  StyledRow,
} from './BomProjects.styles';

//assets
import { ReactComponent as SearchIcon } from '../../assests/search.svg';
import timeline from '../../assests/timeline.png';
import startup from '../../assests/startup.png';
import midsizeEnterprise from '../../assests/midsize-enterprise.png';
import largeEnterprise from '../../assests/large-enterprise.png';
import bin from '../../assests/bin.png';

import DeleteModal from '../../Components/DeleteModal';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Switch } from '@material-ui/core';

const AdminBomDashboard = (props) => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [testProjects, setTestProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [deleteModalState, setDeleteModalState] = React.useState({
    visible: false,
    text: '',
    onYes: () => {},
  });
  const [size, setSize] = useState('');
  const [filters, setFilters] = useState({
    size: '',
    priority: '',
    stage: '',
    projectManager: '',
  });
  const [managers, setManagers] = useState([]);
  const [isPM, setIsPM] = useState(false);
  const [testToggle, setTestToggle] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const fetchProfile = () => {
    setLoading(true);
    axiosInstance
      .get('/users/getProfile')
      .then((resp) => {
        setProfile(resp.data);
        console.log(resp);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const fetchProjects = async () => {
    try {
      let url;
      if (localStorage.PM) {
        url = `/pm/getBomProjects/${profile?.data?._id}`;
      } else {
        url = '/bom/admin/allProjects';
      }
      const res = await axiosInstance.get(url);
      console.log(res, 'opopopopopo');
      const rp = res.data.projects.slice(0).reverse();

      setFilteredProjects([...projects, ...rp]);

      console.log('filtered projects: ', filteredProjects);
      rp.forEach((p) => {
        if (p.isTest) {
          testProjects.push(p);
        } else {
          projects.push(p);
        }
        if (p?.projectDetails?.projectManager) {
          setManagers((state) => {
            if (
              state.find(
                (m) => m.id == p?.projectDetails?.projectManager?._id
              ) == undefined
            ) {
              return [
                ...state,
                {
                  email: p.projectDetails?.projectManager?.email,
                  id: p.projectDetails?.projectManager?._id,
                },
              ];
            } else {
              return [...state];
            }
          });
        }
      });
      setFilteredProjects(projects);
      setProjects(projects);
      setTestProjects(testProjects);
    } catch (err) {
      console.log(err);
    }
  };

  const filterProjects = (query) => {
    const newProjects = projects.filter(
      (p) =>
        p.projectName.toLowerCase().includes(query.toLowerCase()) ||
        p?.internalName?.toLowerCase().includes(query.toLowerCase()) ||
        p?.projectOwner?.email?.toLowerCase().includes(query.toLowerCase()) ||
        p.projectOwner?.enterpriseName
          ?.toLowerCase()
          .includes(query.toLowerCase())
    );
    setFilteredProjects([...newProjects]);
  };

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const deleteProject = async (projectId, orderId) => {
    const res = await axiosInstance.delete(`/bom/admin/projects/${projectId}`);
    enqueueSnackbar('Successfully deleted project!', { variant: 'success' });
    console.log(res);
    setDeleteModalState({
      ...deleteModalState,
      visible: false,
    });

    // setProjects(projects.filter((p) => p.OrderId !== orderId));
    setFilteredProjects(
      filteredProjects.filter((p) => p.projectId !== orderId)
    );
  };

  const handleDeleteProject = async (projectId, projectName, orderId) => {
    setDeleteModalState({
      visible: true,
      text: `Delete project ${projectName}`,
      onYes: () => {
        deleteProject(projectId, orderId);
      },
    });
  };

  const toggleDeleteModal = () => {
    setDeleteModalState({
      ...deleteModalState,
      visible: !deleteModalState.visible,
    });
  };

  useEffect(() => {
    filterProjects(searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    if (localStorage.PM) {
      setIsPM(true);
    }
    fetchProfile();
  }, []);

  useEffect(() => {
    fetchProjects();
  }, [isPM, profile]);

  const handleSizeChange = (e) => {
    setSize(e.target.value);
  };

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const priorityFilter = (priority, projects) => {
    console.log('in herererere');
    return projects.filter(
      (p) =>
        p?.projectDetails?.priority &&
        p?.projectDetails?.priority.toLowerCase() == priority.toLowerCase()
    );
  };

  const stageFilter = (stage, projects) => {
    return projects.filter(
      (p) => p.currentStatus[p.currentStatus.length - 1]?.status == stage
    );
  };

  const managerFilter = (managerId, projects) => {
    return projects.filter(
      (p) =>
        p?.projectDetails?.projectManager &&
        p.projectDetails.projectManager._id == managerId
    );
  };

  const sizeFilter = (size, projects) => {
    return projects.filter(
      (p) =>
        p?.projectOwner?.clientDetails?.organizationSize &&
        p?.projectOwner?.clientDetails?.organizationSize.toLowerCase() ==
          size.toLowerCase()
    );
  };
  const history = useHistory();
  useEffect(() => {
    if (localStorage.PM && history.location.pathname.includes('/admin'))
      history.push('/pm/bomExplorer');
  }, []);
  useEffect(() => {
    let fp = projects;
    if (filters.priority != '') {
      fp = priorityFilter(filters.priority, fp);
    }
    if (filters.stage != '') {
      fp = stageFilter(filters.stage, fp);
    }
    if (filters.projectManager != '') {
      fp = managerFilter(filters.projectManager, fp);
    }
    if (filters.size != '') {
      fp = sizeFilter(filters.size, fp);
    }
    setFilteredProjects(fp);
  }, [filters]);

  useEffect(() => {
    if (filteredProjects) {
      if (testToggle) {
        setFilteredProjects([...testProjects]);
      } else {
        setFilteredProjects([...projects]);
      }
    }
  }, [testToggle]);

  return (
    <Box>
      {!loading ? (
        <PageContainer>
          {/* <DashboardHeader profile_data={profile.data} isBlack={true} /> */}
          <ColosseumProjectsContainer>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <CustomBreadcrumbs
                data={[
                  {
                    name: 'Home',
                    link: '/',
                  },
                  {
                    name: 'EB BOM Explorer',
                    link: `/${isPM ? 'pm' : 'admin'}/bomExplorer`,
                  },
                ]}
              />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {props.isAdmin && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '8px',
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          checked={testToggle}
                          onChange={() => setTestToggle(!testToggle)}
                          color="primary"
                        />
                      }
                      label="Test Projects"
                    />
                  </Box>
                )}
                <Box>
                  <Link
                    to={isPM ? '/pm/pcbFab/newProject' : '/admin/bomNewProject'}
                    style={{
                      backgroundColor: '#493eff',
                      fontSize: '1rem',
                      color: '#fff',
                      border: 'none',
                      padding: '5px 15px',
                      borderRadius: '5px',
                    }}
                  >
                    CREATE PROJECT 🚀
                  </Link>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '50px',
                [theme.breakpoints.down('md')]: {
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '10px',
                },
              }}
            >
              <div className="title">My Projects</div>
              <Box sx={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
                <Box sx={{ minWidth: 180 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Enterprise Size
                    </InputLabel>
                    <CustomSelect
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={filters.size}
                      label="Enterprise size"
                      name="size"
                      onChange={handleFilterChange}
                    >
                      <MenuItem value="">none</MenuItem>
                      <MenuItem value="mid-size-enterprise">Mid Size</MenuItem>
                      <MenuItem value="startup">Startup</MenuItem>
                      <MenuItem value="large enterprise">
                        Large Enterprise
                      </MenuItem>
                    </CustomSelect>
                  </FormControl>
                </Box>
                <Box sx={{ minWidth: 150 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Current Stage
                    </InputLabel>
                    <CustomSelect
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={filters.stage}
                      label="Current Stage"
                      onChange={handleFilterChange}
                      name="stage"
                    >
                      <MenuItem value="">none</MenuItem>
                      {stages.map((stage) => (
                        <MenuItem value={stage.value}>{stage.label}</MenuItem>
                      ))}
                    </CustomSelect>
                  </FormControl>
                </Box>
                <Box sx={{ minWidth: 100 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Priority
                    </InputLabel>
                    <CustomSelect
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={filters.priority}
                      label="Priority"
                      name="priority"
                      onChange={handleFilterChange}
                    >
                      <MenuItem value="">none</MenuItem>
                      <MenuItem value="low">Low</MenuItem>
                      <MenuItem value="medium">Medium</MenuItem>
                      <MenuItem value="high">High</MenuItem>
                      <MenuItem value="very high">Very High</MenuItem>
                    </CustomSelect>
                  </FormControl>
                </Box>{' '}
                {!isPM ? (
                  <Box sx={{ minWidth: 180 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Project Manager
                      </InputLabel>
                      <CustomSelect
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={filters.projectManager}
                        label="projectManager"
                        name="projectManager"
                        onChange={handleFilterChange}
                      >
                        <MenuItem value="">none</MenuItem>
                        {managers.map((manager) => (
                          <MenuItem value={manager.id}>
                            {manager.email}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </FormControl>
                  </Box>
                ) : null}
                <CustomInput
                  fullWidth
                  type="text"
                  placeholder="Search for projects,users,company"
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
            <TableContainer component={Paper} sx={{ marginTop: '30px' }}>
              <Table sx={{ minWidth: 50 }} aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ backgroundColor: '#8B8B8B33' }}>
                    <TableCell
                      sx={{ fontSize: '0.9rem', fontWeight: 500 }}
                      align="left"
                      minWidth={80}
                    >
                      Size
                    </TableCell>
                    <TableCell
                      sx={{ fontSize: '0.9rem', fontWeight: 500 }}
                      align="left"
                    >
                      Company Name
                    </TableCell>
                    <TableCell
                      sx={{ fontSize: '0.9rem', fontWeight: 500 }}
                      align="left"
                    >
                      Internal Name
                    </TableCell>
                    <TableCell
                      sx={{ fontSize: '0.9rem', fontWeight: 500 }}
                      align="left"
                    >
                      Project Name
                    </TableCell>
                    <TableCell
                      sx={{ fontSize: '0.9rem', fontWeight: 500 }}
                      align="left"
                    >
                      Priority
                    </TableCell>
                    <TableCell
                      sx={{ fontSize: '0.9rem', fontWeight: 500 }}
                      align="left"
                    >
                      Project Manager
                    </TableCell>
                    <TableCell
                      sx={{ fontSize: '0.9rem', fontWeight: 500 }}
                      align="left"
                    >
                      Created On
                    </TableCell>
                    <TableCell
                      sx={{ fontSize: '0.9rem', fontWeight: 500 }}
                      align="left"
                    >
                      Current Stage
                    </TableCell>
                    {/* <TableCell
                      sx={{ fontSize: "0.9rem", fontWeight: 500 }}
                      align="left"
                    >
                      Timeline
                    </TableCell>{" "} */}
                    {!isPM ? (
                      <TableCell
                        sx={{ fontSize: '0.9rem', fontWeight: 500 }}
                        align="left"
                      >
                        Delete
                      </TableCell>
                    ) : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredProjects?.length > 0 ? (
                    <>
                      {filteredProjects
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((project) => (
                          <StyledRow
                            key={project.projectId}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                              cursor: 'pointer',
                            }}
                            priority={project?.projectDetails?.priority}
                            onClick={(e) => {
                              if (e.target?.id == 'deleteimg') {
                                return;
                              }
                              window.open(
                                `/${
                                  isPM ? 'pm' : 'admin'
                                }/bomExplorer/timeline/${project._id}`,
                                '_blank'
                              );
                            }}
                          >
                            <TableCell>
                              <img
                                src={(() => {
                                  let size =
                                    project?.projectOwner?.clientDetails
                                      ?.organizationSize;
                                  if (size === 'Startup') return startup;
                                  if (size === 'Mid-Size-Enterprize')
                                    return midsizeEnterprise;
                                  if (size === 'Large Enterprise')
                                    return largeEnterprise;
                                  return startup;
                                })()}
                                alt=""
                                style={{ cursor: 'pointer' }}
                              />
                            </TableCell>
                            <TableCell>
                              {project.projectOwner.enterpriseName || ''}
                            </TableCell>
                            <TableCell>{project.internalName || ''}</TableCell>
                            <TableCell>{project.projectName}</TableCell>
                            <TableCell>
                              {project?.projectDetails?.priority || 'N/A'}
                            </TableCell>
                            <TableCell>
                              {project?.projectDetails?.projectManager?.email ||
                                'N/A'}
                            </TableCell>
                            <TableCell>
                              {moment(project?.startDate).format(
                                'MMMM Do YYYY, h:mm a'
                              ) || 'no date'}
                            </TableCell>
                            <TableCell>
                              {project.isClosed
                                ? 'Project Closed'
                                : project.currentStatus[
                                    project.currentStatus.length - 1
                                  ]?.status}
                            </TableCell>
                            {/* <TableCell>
                              <img
                                src={timeline}
                                alt=""
                                onClick={() =>
                                  window.open(
                                    `/${
                                      isPM ? "pm" : "admin"
                                    }/bomExplorer/timeline/${project._id}`,
                                    "_blank"
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              />
                            </TableCell> */}
                            {!isPM ? (
                              <TableCell>
                                <img
                                  src={bin}
                                  id="deleteimg"
                                  onClick={() => {
                                    console.log('delete clicked');
                                    handleDeleteProject(
                                      project._id,
                                      project.projectName,
                                      project.projectId
                                    );
                                  }}
                                  style={{ cursor: 'pointer' }}
                                />
                              </TableCell>
                            ) : null}
                          </StyledRow>
                        ))}
                    </>
                  ) : (
                    <TableCell
                      colSpan={7}
                      sx={{
                        color: '#737373',
                        margin: '10px',
                        width: '100%',
                        textAlign: 'center',
                      }}
                    >
                      Currently you don't have any projects !
                    </TableCell>
                  )}
                </TableBody>
              </Table>
              <DeleteModal
                onYes={deleteModalState.onYes}
                onNo={toggleDeleteModal}
                state={deleteModalState.visible}
                toggleModal={toggleDeleteModal}
                text={deleteModalState.text}
              />
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={projects.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </ColosseumProjectsContainer>
        </PageContainer>
      ) : null}
    </Box>
  );
};

export default AdminBomDashboard;
