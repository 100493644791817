import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import { DropzoneArea } from "material-ui-dropzone";
import { SnackbarProvider, useSnackbar } from "notistack";
import { axiosInstance } from "../utils/axiosInterceptor";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2),
    minWidth: "100%",
  },
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function DocumentUpload(props) {
  const classes = useStyles();

  const [files, setFiles] = useState([]);
  const [stageNumber, setStage] = useState(null);
  const [documents, setDocuments] = useState(null);
  const [open, setOpen] = useState(false);
  const [deleteDocumentLoading, setDeleteDocumentLoading] = useState(false);
  const [del_index, setDelIndex] = useState(null);
  const [docToProcess, setDocToProcess] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  const { enqueueSnackbar } = useSnackbar();

  let deleteDocument = (projectId, stageId, docAddress, delIndex) => {
    console.log(docToProcess);
    let arr = documents;
    arr.splice(delIndex, 1);

    setDeleteDocumentLoading(true);

    axiosInstance
      .post(`/projects/deleteDocuments/${projectId}`, {
        stageId: stageId,
        docAddress: docToProcess.serverFileName,
        updatedArr: arr,
      })
      .then((resp) => {
        setDeleteDocumentLoading(false);
        enqueueSnackbar("Document deleted successfully", {
          variant: "success",
        });
        setOpen(false);
      })
      .catch((err) => {
        setDeleteDocumentLoading(false);
        enqueueSnackbar("Not able to delete document successfully", {
          variant: "error",
        });
      });
  };

  return (
    <div>
      <Dialog
        fullWidth="true"
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <>
          {deleteDocumentLoading ? (
            <div className={classes.root}>
              <LinearProgress></LinearProgress>
            </div>
          ) : (
            ""
          )}

          <DialogTitle id="form-dialog-title">
            Are you sure you want to delete this document?
          </DialogTitle>
          <DialogContent>
            <div className="btn_opts_del">
              <Button
                style={{ marginRight: "10px" }}
                variant="contained"
                color="primary"
                onClick={() => {
                  deleteDocument(
                    props.project._id,
                    stageNumber,
                    document.serverFileName,
                    del_index
                  );
                }}
              >
                Delete
              </Button>
              <Button variant="outlined" color="primary" onClick={handleClose}>
                Cancel
              </Button>
            </div>
          </DialogContent>
        </>
      </Dialog>
      <h3 className="sec_head">Document Upload</h3>
      <FormControl variant="filled" fullWidth className={classes.formControl}>
        <InputLabel id="demo-simple-select-label" fullWidth>
          Select stage to edit
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          label="Slect active stage"
          onChange={(e) => {
            setStage(e.target.value);
            let stages = props.project.stageInfo;
            let i = 0;
            while (i < stages.length) {
              if (stages[i]._id.toString() == e.target.value.toString()) {
                console.log(stages[i].documents);
                setDocuments(stages[i].documents);
              }
              i++;
            }
          }}
        >
          {props.project.stageInfo
            ? props.project.stageInfo.map((stage) => {
                return <MenuItem value={stage._id}>{stage.stageName}</MenuItem>;
              })
            : ""}
        </Select>
      </FormControl>
      <DropzoneArea
        maxFileSize={20000000}
        init={() => {}}
        onChange={(files) => {
          setFiles(files);
        }}
      />
      <div className={classes.formControl}>
        <Button
          style={{ marginTop: "10px" }}
          variant="contained"
          color="primary"
          onClick={() => {
            if (stageNumber && files.length > 0) {
              props.setEditLoading(true);
              let formData = new FormData();
              for (let i = 0; i < files.length; ++i) {
                formData.append("doc", files[i]);
              }
              formData.append("projectId", props.project._id);
              let token = localStorage.getItem("token");

              axios
                .post(
                  `https://platform.elecbits.in/api/projects/addDocument/${stageNumber}`,
                  formData,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                      "Content-type": "multipart/form-data",
                    },
                  }
                )
                .then((resp) => {
                  enqueueSnackbar("Document uploaded successfully", {
                    variant: "success",
                  });
                  setOpen(false);
                  props.setEditLoading(false);
                  props.handleClose();
                  props.fetchProjectData();
                })
                .catch((err) => {
                  enqueueSnackbar("Not able to upload document", {
                    variant: "error",
                  });
                  props.setEditLoading(false);
                });
            } else {
              if (!stageNumber) {
                enqueueSnackbar("Stage number is required", {
                  variant: "error",
                });
              }
              if (files.length == 0) {
                enqueueSnackbar("Select file to be uploaded", {
                  variant: "error",
                });
              }
            }
          }}
        >
          Upload Document
        </Button>
      </div>
      <h3 className="sec_head">Documents</h3>
      <div className="documentHoler">
        {documents && documents.length > 0 ? (
          documents.map((document, index) => {
            return (
              <div className="document_Item">
                <div className="documentItem">
                  <a href={document.fileLocation}>{document.fileName}</a>
                </div>
                <IconButton
                  aria-label="delete"
                  color="primary"
                  onClick={() => {
                    setOpen(true);
                    setDelIndex(index);
                    setDocToProcess(document);
                  }}
                >
                  <DeleteIcon fontSize="small"></DeleteIcon>
                </IconButton>
              </div>
            );
          })
        ) : (
          <div id="noDocAval">No documents available</div>
        )}
      </div>
    </div>
  );
}
