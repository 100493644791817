import { Modal, styled, TextField, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import CustomButton from "./CustomButton";
import "./ProfileView.css";
import { axiosInstance } from "../../utils/axiosInterceptor";
import DeleteModal from "../../Components/DeleteModal";
import { useSnackbar } from "notistack";

const AddressOverviewContainer = styled("div")(() => ({
  width: 320,
  border: "1px solid #A3A3A3",
  borderRadius: 12,
  padding: 10,
  margin: "15px 20px 0px 0px",
  minHeight: 200,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

const AddressOverview = ({ address }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const toggleEditModal = () => {
    setShowEditModal(!showEditModal);
  };

  const handleDeleteAddress = async () => {
    const res = await axiosInstance.delete(
      `/users/shippingAddress/${address?.addressId}`
    );
    if (res.status === 200) {
      enqueueSnackbar("Successfully deleted shipping address", {
        variant: "success",
      });
    } else {
      enqueueSnackbar("Could not delete shipping address", {
        variant: "error",
      });
    }
    toggleDeleteModal();
    window.location.reload();
    console.log(res);
  };

  return (
    <AddressOverviewContainer>
      <div>
        <h6>{address?.addressType}</h6>
        <p>
          {`${address?.houseNumber},${address?.street},near ${address?.landmark},${address.city},${address.state}-${address.pincode}`}
        </p>
        <h6>
          {address?.fullName},{address.mobile}
        </h6>
      </div>
      <div>
        <CustomButton
          sx={{
            backgroundColor: "#D1D1D1",
            "&:hover": { backgroundColor: "#D1D1D1" },
          }}
          onClick={toggleEditModal}
        >
          EDIT
        </CustomButton>
        <AddressModal
          state={showEditModal}
          toggleModal={toggleEditModal}
          address={address}
        />
        <CustomButton
          sx={{
            backgroundColor: "#D1D1D1",
            "&:hover": { backgroundColor: "#D1D1D1" },
          }}
          onClick={toggleDeleteModal}
        >
          DELETE
        </CustomButton>
      </div>
      <DeleteModal
        state={showDeleteModal}
        toggleModal={toggleDeleteModal}
        onNo={toggleDeleteModal}
        onYes={handleDeleteAddress}
      />
    </AddressOverviewContainer>
  );
};

const AddressModalContainer = styled("div")(({theme}) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30vw",
  height: "92vh",
  backgroundColor: "#fff",
  outline: "none",
  display: "flex",
  flexDirection: "column",
  padding: 20,
  borderRadius: 5,
  "& .MuiTextField-root": {
    width: "80%",
    marginBottom: 10,
    "& input": {
      fontSize: 12,
    },
  },
  "& .modal_header": {
    fontSize: 28,
    color: "#565656",
    marginBottom:'20px'
  },
  [theme.breakpoints.down('lg')]:{
    width: "45vw",
  },
  [theme.breakpoints.down('md')]:{
    width: "60vw",
  },
  [theme.breakpoints.down('sm')]:{
    width: "85vw",
  }
}));

const AddressModal = ({ state, toggleModal, address }) => {
  const [addressData, setAddressData] = useState({
    fullName: "",
    mobile: "",
    pincode: "",
    houseNumber: "",
    street: "",
    landmark: "",
    city: "",
    state: "",
    addressType: "",
  });

  useEffect(() => {
    if (address) {
      setAddressData(address);
    }
  }, [address]);

  const handleChange = (e) => {
    setAddressData({
      ...addressData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(addressData);
    const res = await axiosInstance.post(
      "/users/addShippingAddress",
      addressData
    );
    toggleModal();
    setAddressData({
      fullName: "",
      mobile: "",
      pincode: "",
      houseNumber: "",
      street: "",
      landmark: "",
      city: "",
      state: "",
      addressType: "",
    });
    window.location.reload();
  };

  return (
    <Modal open={state} onClose={toggleModal}>
      <AddressModalContainer>
        <h2 className="modal_header">Add/Edit Shipping Address</h2>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Full Name"
            required
            name="fullName"
            onChange={handleChange}
            value={addressData.fullName}
          />
          <TextField
            label="Mobile Number"
            required
            name="mobile"
            onChange={handleChange}
            value={addressData.mobile}
          />
          <TextField
            label="Pincode"
            required
            name="pincode"
            onChange={handleChange}
            value={addressData.pincode}
          />
          <TextField
            label="House number"
            required
            name="houseNumber"
            onChange={handleChange}
            value={addressData.houseNumber}
          />
          <TextField
            label="Street"
            required
            name="street"
            onChange={handleChange}
            value={addressData.street}
          />
          <TextField
            label="Landmark"
            required
            name="landmark"
            onChange={handleChange}
            value={addressData.landmark}
          />
          <TextField
            label="City"
            required
            name="city"
            onChange={handleChange}
            value={addressData.city}
          />
          <TextField
            label="State"
            required
            name="state"
            onChange={handleChange}
            value={addressData.state}
          />
          <TextField
            label="Address Type"
            placeholder="(Office,Home,Gurgaon Branch)"
            name="addressType"
            required
            onChange={handleChange}
            value={addressData.addressType}
          />
          <br/>
          <CustomButton style={{ marginTop: "10px" }} type="submit">
            SAVE
          </CustomButton>
        </form>
      </AddressModalContainer>
    </Modal>
  );
};

const ShippingAddress = ({ shippingAddresses }) => {
  const [showAddressModal, setShowAddressModal] = useState(false);
  const toggleAddressModal = () => {
    setShowAddressModal(!showAddressModal);
  };
  return (
    <div>
      <div className="profile_label">Your Addresses</div>
      <Grid container sx={{ marginBottom: "20px" }}>
        {shippingAddresses &&
          shippingAddresses.map((address) => (
            <Grid item md={6}>
              <AddressOverview address={address} />
            </Grid>
          ))}
      </Grid>

      <CustomButton
        onClick={toggleAddressModal}
        sx={{ margin: "10px 0 40px 0" }}
      >
        ADD ADDRESS
      </CustomButton>
      <AddressModal state={showAddressModal} toggleModal={toggleAddressModal} />
    </div>
  );
};

export default ShippingAddress;
