import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import clsx from "clsx";
import { SnackbarProvider, useSnackbar } from "notistack";
import { nanoid } from "nanoid";
var createReactClass = require("create-react-class");

const useStyles = makeStyles((theme) => ({
  // root: {
  //   display: "flex",
  //   flexWrap: "wrap",
  // },
  margin: {
    margin: theme.spacing(1),
  },
  // root1: {
  //   width: "100%",
  //   "& > * + *": {
  //     marginTop: theme.spacing(2),
  //   },
  // // },
  textField: {
    width: "25ch",
  },
}));

let finalBilling = {};
let nodes = {};

export default function QuantityWisePricing(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    //Get the project Billing
    finalBilling = {};
    nodes = {};
  }, []);

  let handleAdd = () => {
    for (let id in finalBilling) {
      nodes[id] = (
        <div className="form_el_cont" key={id}>
          <FormControl
            className={clsx(
              classes.margin,
              classes.withoutLabel,
              classes.textField
            )}
            style={{
              width: "55%",
            }}
          >
            <TextField
              label="Billing range start"
              type="number"
              value={finalBilling[id]["rangeStart"]}
              variant="outlined"
            />
          </FormControl>

          <FormControl
            className={clsx(
              classes.margin,
              classes.withoutLabel,
              classes.textField
            )}
            style={{
              width: "55%",
            }}
          >
            <TextField
              label="Billing range end"
              type="number"
              value={finalBilling[id]["rangeEnd"]}
              variant="outlined"
            />
          </FormControl>

          <FormControl
            className={clsx(
              classes.margin,
              classes.withoutLabel,
              classes.textField
            )}
            style={{
              width: "35%",
            }}
            className={classes.margin}
            variant="outlined"
          >
            <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              value={finalBilling[id]["amount"]}
              startAdornment={
                <InputAdornment position="start">₹</InputAdornment>
              }
              labelWidth={60}
            ></OutlinedInput>
          </FormControl>

          <FormControl className={clsx(classes.margin)}>
            <IconButton
              onClick={() => {
                handleDelete(id);
              }}
            >
              <DeleteTwoToneIcon></DeleteTwoToneIcon>
            </IconButton>
          </FormControl>
        </div>
      );
    }

    let idx = nanoid(5);
    finalBilling[idx] = {};
    finalBilling[idx]["rangeStart"] = "";
    finalBilling[idx]["rangeEnd"] = "";
    finalBilling[idx]["amount"] = "";

    nodes[idx] = (
      <div className="form_el_cont" key={idx}>
        <FormControl
          className={clsx(
            classes.margin,
            classes.withoutLabel,
            classes.textField
          )}
          style={{
            width: "55%",
          }}
        >
          <TextField
            type="number"
            label="Billing range start"
            onChange={(e) => {
              finalBilling[idx]["rangeStart"] = e.target.value;

              console.log(finalBilling.hasOwnProperty(idx), finalBilling);
            }}
            variant="outlined"
          />
        </FormControl>
        <FormControl
          className={clsx(
            classes.margin,
            classes.withoutLabel,
            classes.textField
          )}
          style={{
            width: "55%",
          }}
        >
          <TextField
            label="Billing range end"
            type="number"
            onChange={(e) => {
              finalBilling[idx]["rangeEnd"] = e.target.value;

              console.log(finalBilling.hasOwnProperty(idx), finalBilling);
            }}
            variant="outlined"
          />
        </FormControl>
        <FormControl
          className={clsx(
            classes.margin,
            classes.withoutLabel,
            classes.textField
          )}
          style={{
            marginTop: "5px",
            width: "35%",
          }}
          className={classes.margin}
          variant="outlined"
        >
          <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            startAdornment={<InputAdornment position="start">₹</InputAdornment>}
            labelWidth={60}
            onChange={(e) => {
              finalBilling[idx]["amount"] = e.target.value;

              console.log(finalBilling.hasOwnProperty(idx), finalBilling);
            }}
          ></OutlinedInput>
          {/* <TextField
            label="Price"
            type="number"
            onChange={(e) => {
              finalBilling[idx]["amount"] = e.target.value;

              console.log(finalBilling.hasOwnProperty(idx), finalBilling);
            }}
            variant="outlined"
          /> */}
        </FormControl>

        <FormControl className={clsx(classes.margin)}>
          <IconButton
            onClick={() => {
              handleDelete(idx);
            }}
          >
            <DeleteTwoToneIcon></DeleteTwoToneIcon>
          </IconButton>
        </FormControl>
      </div>
    );

    var Fields = createReactClass({
      render: () => {
        // Change this to get the list from props or state
        let rNodes = [];
        for (let k in nodes) {
          rNodes.push(nodes[k]);
        }
        return <>{rNodes}</>;
      },
    });
    ReactDOM.render(<Fields />, document.getElementById("addedFields"));
  };

  let handleDelete = (idx) => {
    for (let id in finalBilling) {
      nodes[id] = (
        <div className="form_el_cont" key={id}>
          <FormControl
            className={clsx(
              classes.margin,
              classes.withoutLabel,
              classes.textField
            )}
            style={{
              width: "55%",
            }}
          >
            <TextField
              label="Billing range start"
              type="number"
              value={finalBilling[id]["rangeStart"]}
              variant="outlined"
            />
          </FormControl>

          <FormControl
            className={clsx(
              classes.margin,
              classes.withoutLabel,
              classes.textField
            )}
            style={{
              width: "55%",
            }}
          >
            <TextField
              label="Billing range end"
              type="number"
              value={finalBilling[id]["rangeEnd"]}
              variant="outlined"
            />
          </FormControl>

          <FormControl
            className={clsx(
              classes.margin,
              classes.withoutLabel,
              classes.textField
            )}
            style={{
              width: "35%",
            }}
            className={classes.margin}
            variant="outlined"
          >
            <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              value={finalBilling[id]["amount"]}
              startAdornment={
                <InputAdornment position="start">₹</InputAdornment>
              }
              labelWidth={60}
            ></OutlinedInput>
          </FormControl>

          <FormControl className={clsx(classes.margin)}>
            <IconButton
              onClick={() => {
                handleDelete(id);
              }}
            >
              <DeleteTwoToneIcon></DeleteTwoToneIcon>
            </IconButton>
          </FormControl>
        </div>
      );
    }
    delete nodes[idx];
    delete finalBilling[idx];
    var Fields = createReactClass({
      render: () => {
        // Change this to get the list from props or state
        let rNodes = [];
        for (let k in nodes) {
          rNodes.push(nodes[k]);
        }
        return <>{rNodes}</>;
      },
    });
    ReactDOM.render(<Fields />, document.getElementById("addedFields"));
  };
  return (
    <div style={{ marginTop: "10px" }}>
      <span id="addedFields"></span>
      <div style={{ width: "100%", textAlign: "center" }}>
        <Button
          style={{ width: "80%", marginTop: "5px" }}
          color="primary"
          variant="outlined"
          onClick={(e) => {
            handleAdd();
          }}
        >
          Add Pricing
        </Button>
        <Button
          color="primary"
          variant="contained"
          style={{ marginTop: "5px", marginLeft: "5px" }}
          onClick={() => {
            props.setPricing(finalBilling);
            enqueueSnackbar("Pricing successfully confirmed", {
              variant: "success",
            });
          }}
        >
          Confirm Pricing
        </Button>
        <div className="billing_divider"></div>
      </div>
    </div>
  );
}
