import React from "react";

//libs
import { styled, Box, Typography, Button } from "@mui/material";

export const CustomButton = styled(Button)(() => ({
  "&&&": {
    backgroundColor: "#FF972A",
    color: "#fff",
    textTransform: "none",
    padding: "5px 50px",
    borderRadius: "8px",
    fontWeight: 500,
  },
}));

const OrderSummaryContainer = styled(Box)(() => ({
  width: "100vw",
  height: "100px",
  position: "absolute",
  bottom: "0px",
  left: "0px",
  backgroundColor: "#2F2F2F",
  color: "#fff",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
}));

const ColosseumOrderSummary = ({
  selectedQuotation,
  confirmOrder,
  orderState,
}) => {
  return (
    <OrderSummaryContainer>
      <Box>
        <Typography sx={{ fontWeight: 600, fontSize: "1.2em" }}>
          Order Summary for
        </Typography>
        <Typography
          sx={{
            borderBottom: "1px solid white",
            width: "fit-content",
            marginTop: "5px",
          }}
        >
          Quantity: {selectedQuotation.quantity}
        </Typography>
      </Box>
      <Box>
        <Typography>
          Total Cost: Rs.{" "}
          {(Number(selectedQuotation.pricePerQty) / 1.18)
            .toFixed(2)
            .replace(/[.,]00$/, "")}
        </Typography>
        <Typography sx={{ marginTop: "5px" }}>
          Total Cost(incl GST): Rs.{" "}
          {Number(selectedQuotation.pricePerQty)
            .toFixed(2)
            .replace(/[.,]00$/, "")}
        </Typography>
      </Box>
      <Box>
        <Typography>
          Total Shipping Cost: Rs {selectedQuotation.shippingCost}
        </Typography>
      </Box>
      <Typography sx={{ fontWeight: 600, fontSize: "1.2em" }}>
        Grand Total: Rs {Number(selectedQuotation.totalCost).toFixed(2)
            .replace(/[.,]00$/, "")}
      </Typography>
      <CustomButton onClick={confirmOrder} disabled={orderState.confirm}>
        {orderState.confirm ? "Order Confirmed" : "Confirm Order"}
      </CustomButton>
    </OrderSummaryContainer>
  );
};

export default ColosseumOrderSummary;
