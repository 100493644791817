import React, { useState, useEffect } from "react";
import { axiosInstance } from "../utils/axiosInterceptor";
import { Container, Row, Col } from "react-bootstrap";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { SnackbarProvider, useSnackbar } from "notistack";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));
export default function Collaborators(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [collaborator, setCollaborator] = useState(null);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [delLoading, setDelLoading] = useState(false);
  const [delOpen, setDelOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [delCollaborator, setDelCollaborator] = useState(null);
  let projectId = props.projectId;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelClickOpen = () => {
    setDelOpen(true);
  };

  const handleDelClose = () => {
    setDelOpen(false);
  };

  const handleSubmit = () => {
    if (collaborator) {
      setLoading(true);
      axiosInstance
        .post(`/projects/addCollaborator/${projectId}`, {
          collaborator: collaborator,
        })
        .then((resp) => {
          setLoading(false);
          setOpen(false);
          props.fetchProfile();
          props.fetchProjectData();
          enqueueSnackbar("Successfully added a new collaborator", {
            variant: "success",
          });
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          enqueueSnackbar("Not able to add a new collaborator", {
            variant: "error",
          });
        });
    } else {
      enqueueSnackbar("Please select a collaborator first!", {
        variant: "error",
      });
    }
  };

  return props.allUsers && props.collaborators ? (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
        {loading ? (
          <div className={classes.root}>
            <LinearProgress></LinearProgress>
          </div>
        ) : (
          ""
        )}
        <DialogTitle>Add Collaborators</DialogTitle>
        <DialogContent>
          <Autocomplete
            onChange={(e, s) => {
              setCollaborator(s);
            }}
            style={{ marginTop: "15px" }}
            selectOnFocus
            clearOnBlur
            id="combo-box-demo"
            getOptionLabel={(option) => option.email}
            autoHighlight
            renderOption={(props, option) => {
              if (
                true
                // option._id.toString() !=
                // props.project_info.projectOwner._id.toString()
              ) {
                return (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img width="20" src={option.profilePic} alt="" />
                    {option.googleId
                      ? `${option.email}--Google`
                      : `${option.email}`}
                  </Box>
                );
              }
            }}
            options={props.allUsers.data.data}
            renderInput={(params) => (
              <TextField {...params} label="Choose Collaborator" />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>Add</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={delOpen}
        onClose={handleDelClose}
        fullWidth={true}
        maxWidth="sm"
      >
        {delLoading ? (
          <div className={classes.root}>
            <LinearProgress></LinearProgress>
          </div>
        ) : (
          ""
        )}
        <DialogTitle>List of Collaborators</DialogTitle>
        <DialogContent>
          <div className="list_wrapper">
            {props.collaborators.map((usr) => {
              return (
                <div className="list_edit_item">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Avatar
                      src={usr.profilePic}
                      style={{ marginRight: "5px" }}
                    />
                    {"   "}
                    {!usr.googleId ? usr.email : `${usr.email} -- google`}{" "}
                  </div>

                  <IconButton
                    onClick={() => {
                      setConfirmOpen(true);
                      setDelCollaborator(usr._id);
                    }}
                  >
                    <DeleteIcon fontSize="medium"></DeleteIcon>
                  </IconButton>
                </div>
              );
            })}
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={confirmOpen}
        onClose={() => {
          setConfirmOpen(false);
        }}
      >
        <DialogTitle>
          Are you sure you want to remove this collaborator?
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              setConfirmOpen(false);
              if (delCollaborator) {
                setDelLoading(true);
                axiosInstance
                  .post(`/projects/removeCollaborator/${projectId}`, {
                    collaborator: delCollaborator,
                  })
                  .then((resp) => {
                    setDelLoading(false);
                    setDelOpen(false);
                    props.fetchProfile();
                    props.fetchProjectData();
                    enqueueSnackbar("Successfully deleted the collaborator", {
                      variant: "success",
                    });
                  })
                  .catch((err) => {
                    setDelLoading(false);
                    enqueueSnackbar("Not able to delete the collaborator", {
                      variant: "error",
                    });
                  });
              } else {
                enqueueSnackbar("Some problem occured");
              }
            }}
          >
            Remove
          </Button>
          <Button
            onClick={() => {
              setConfirmOpen(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <div className="collaborators" style={{display:'flex'}}>
        <Tooltip title="Add Collaborators">
          <IconButton aria-label="delete" onClick={handleClickOpen}>
            <AddCircleIcon fontSize="medium"></AddCircleIcon>
          </IconButton>
        </Tooltip>

        <Tooltip title="View and edit Collaborators">
          <IconButton aria-label="delete" onClick={handleDelClickOpen}>
            <ModeEditIcon fontSize="medium"></ModeEditIcon>
          </IconButton>
        </Tooltip>
      </div>
    </>
  ) : (
    ""
  );
}
