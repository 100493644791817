import React, { useState } from "react";

import {
  Modal,
  Box,
  styled,
  Button,
  Slide,
  Typography,
  TextField,
  Avatar,
  InputAdornment,
  useTheme,
} from "@mui/material";
import CustomFileDropper from "../../Components/CustomFileDropper.js";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbar } from "notistack";
import { axiosInstance } from "../../utils/axiosInterceptor.js";

export const CustomButton = styled(LoadingButton)(() => ({
  backgroundColor: "#FF972A !important",
  color: "#fff !important",
  textTransform: "none !important",
  padding: "5px 50px !important",
  borderRadius: "8px !important",
  fontWeight: 500,
  margin: "10px auto !important",
}));

const ModalContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: "36vw",
  height: "fit-content",
  left: "32vw",
  top: "10vh",
  backgroundColor: "#fff",
  boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.2)",
  borderRadius: 15,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "25px 40px",
  gap: "20px",
  "& .heading": {
    fontSize: "1.2em",
    fontWeight: 700,
    fontFamily: "Poppins",
  },
  "& legend": {
    width: "unset",
  },
  "& .MuiTypography-root": {
    fontFamily: "Poppins",
  },
  fontFamily: "Poppins",
  [theme.breakpoints.down("md")]: {
    width: "90vw",
    left: "5vw",
  },
}));

export const CustomInput = styled(TextField)(({ error }) => ({
  width: "100%",

  backgroundColor: "transparent",
  outline: "none",
  "&:focus": {
    outline: "none",
  },
  "& legend": {
    width: "unset",
  },
  "& .MuiOutlinedInput-root": {
    border: error ? "2px solid red" : "2px solid #8B8B8B4D",
    borderRadius: 8,
  },
  "& input": {
    padding: "7px",
  },
  "& fieldset": {
    border: "none",
  },
}));

const AdminBomQuotationModal = ({ state, toggleModal, id }) => {
  const [data, setData] = useState({
    numberOfBoards: 0,
    additionalDescription: "",
  });
  const [bomFile, setBomFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleClick = async () => {
    console.log(data);
    console.log(bomFile);
    if (bomFile == null || data.numberOfBoards == 0) {
      enqueueSnackbar("Please fill all the fields", { varaiant: "error" });
      return;
    }
    try {
      const formData = new FormData();
      formData.append("numberOfBoards", data.numberOfBoards);
      formData.append("additionalDescription", data.additionalDescription);
      formData.append("bom", bomFile);
      formData.append("state", true);
      setIsLoading(true);
      const res = await axiosInstance.post(
        `/bom/admin/toggleStatus/${id}`,
        formData
      );
      enqueueSnackbar("Project is now live", { variant: "success" });
      setIsLoading(false);
    } catch (err) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
    }

    toggleModal("submit");
  };

  return (
    <Modal open={state} onClose={toggleModal}>
      <Slide in={state} direction="up">
        <ModalContainer>
          <h1 className="heading">Elecbits BOM Explorer - Upload files</h1>
          <CustomFileDropper
            small
            single
            setFile={setBomFile}
            headerText="Drag and drop to upload a file or"
            subText="Please make sure that the BOM excel is in the correct format"
          />
          <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
            <Typography sx={{ width: "40%" }}>No of boards:</Typography>
            <CustomInput
              type="number"
              id="numberOfBoards"
              name="numberOfBoards"
              onChange={handleChange}
              required
            />
          </Box>
          <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
            <Typography sx={{ width: "40%" }}>
              Additional Description:
            </Typography>
            <CustomInput
              type="text"
              id="additionalDescription"
              name="additionalDescription"
              onChange={handleChange}
            />
          </Box>
          <CustomButton loading={isLoading} onClick={handleClick}>
            Submit
          </CustomButton>
        </ModalContainer>
      </Slide>
    </Modal>
  );
};

export default AdminBomQuotationModal;
