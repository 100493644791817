import React, { useState, useEffect } from "react";

//libs
import { styled, Box, Typography, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useParams } from "react-router-dom";
import { axiosInstance } from "../../utils/axiosInterceptor";
import { useSnackbar } from "notistack";

// const useStyles = makeStyles({
//   root: {
//     "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
//       outline: "none",
//     },
//   },
// });

const TableContainer = styled(Box)(() => ({
  boxShadow: "0px 0px 30px rgba(139, 139, 139, 0.15)",
  width: "100%",
  height: "fit-content",
  marginTop: "30px",
  borderRadius: "15px",
  backgroundColor: "#fff",
  padding: "25px",
}));

const DetailsContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  gap: "50px",
  "& .detail-col": {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
}));

export const CustomButton = styled(Button)(() => ({
  "&&&": {
    backgroundColor: "#FF972A",
    color: "#fff",
    textTransform: "none",
    padding: "5px 50px",
    borderRadius: "8px",
    fontWeight: 500,
    margin: "10px auto",
  },
}));

const columns = [
  {
    field: "vendorName",
    headerName: "Name",
    // width: "170",
    flex: 1.5,
    editable: true,
  },
  {
    field: "location",
    headerName: "Location",
    // width: "140",
    flex: 1,
    editable: true,
  },
  {
    field: "leadTime",
    headerName: "Lead Time(in days)",
    // width: "150",
    flex: 1,
    editable: true,
  },
  {
    field: "unitCost",
    headerName: "Unit Cost(in Rs)",
    // width: "150",
    flex: 1,
    editable: true,
  },
  {
    field: "unitCostGST",
    headerName: "Unit Cost(incl GST)",
    // width: "150",
    flex: 1,
    editable: true,
  },
  {
    field: "pricePerQty",
    headerName: "Price as per qty",
    // width: "140",
    flex: 1,
  },
  {
    field: "shippingCost",
    headerName: "Shipping Cost",
    // width: "130",
    flex: 1,
    editable: true,
  },
  {
    field: "totalCost",
    headerName: "Total Cost",
    // width: "130",
    flex: 1,
  },
  {
    field: "isFav",
    headerName: "isFav",
    // width: "100",
    flex: 1,
    editable: true,
  },
];

const ColosseumQuotationsTable = ({
  project,
  selectionMode,
  selectQuotation,
  isAdmin,
  mode,
  disabled,
  selectedQuotation
}) => {
  const [rowData, setRowData] = useState([]);
  const [tobeDeleted, setToBeDeleted] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [dataCols, setDataCols] = useState(columns);

  const { type, id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const getProjectType = (details) => {
    if (details.resinMaterial) {
      return {
        val: "injectionMolding",
        label: "Injection Molding",
      };
    } else if (details.outerDimensions) {
      return {
        val: "prebuiltCase",
        label: "Prebuilt Case",
      };
    } else {
      return {
        val: "3dprinting",
        label: "3D Printing",
      };
    }
  };

  useEffect(() => {
    if (!isAdmin) {
      let newDataCols = dataCols.map((d) => {
        return { ...d, editable: false };
      });
      newDataCols = newDataCols.filter((a) => a.field != "isFav");
      setDataCols([...newDataCols]);
    }
  }, [isAdmin]);

  // const classes = useStyles();

  useEffect(() => {
    if (mode === "magic") {
      const sQuote = rowData.find(
        (q) => q.isFav === "true" || q.isFav === true
      );
      selectQuotation(sQuote);
      setSelectedRow(sQuote);
    }
  }, [mode]);

  useEffect(() => {
    console.log(selectedRow, "hehehe");
  }, [selectedRow]);

  useEffect(() => {
    if (project) {
      const data = project.quotations.map((quote) => {
        const costWithGST = (Number(quote.unitCost) * 1.18)
          .toFixed(2)
          .replace(/[.,]00$/, "");
        return {
          vendorName: quote.vendorName,
          location: quote.location,
          leadTime: quote.leadTime,
          unitCost: quote.unitCost,
          unitCostGST: costWithGST,
          pricePerQty: costWithGST * (project?.projectDetails?.quantity || 1),
          shippingCost: quote.shippingCost,
          totalCost:
            costWithGST * (project?.projectDetails?.quantity || 1) +
            quote.shippingCost,
          isFav: quote.isFav,
          id: quote._id,
          quantity: project?.projectDetails?.quantity,
        };
      });

      console.log(data, "lololol");

      setRowData([...data]);
    }
  }, [project]);

  useEffect(() => {
    setToBeDeleted([]);
    setSelectedRow([]);
  }, [selectionMode]);

  const handleCellEdit = async (d) => {
    if (!d.hasFocus) {
      console.log(d.id, d.value, d.field);

      const updatedRowData = rowData.find((i) => i.id == d.id);
      updatedRowData[d.field] = d.value;

      try {
        const res = await axiosInstance.put(`/3d/admin/quote/${type}/${id}`, {
          updatedQuotation: { ...updatedRowData },
        });
        console.log(res);
        enqueueSnackbar(`${d.field} successfully updated`, {
          variant: "success",
        });
      } catch (err) {
        console.log(err);
        enqueueSnackbar("Something went wrong!", { variant: "error" });
      }

      console.log(updatedRowData, "hehehehe");
    }
  };

  const handleDeleteQuotations = async () => {
    console.log(tobeDeleted, "lop");
    try {
      const res = await axiosInstance.post(
        `/3d/admin/deleteQuote/${type}/${id}`,
        {
          toDeleteQuotations: [...tobeDeleted],
        }
      );
      console.log(res);
      enqueueSnackbar("Quotations deleted successfully!", {
        variant: "success",
      });
      let newRowData = [...rowData];
      tobeDeleted.forEach((q) => {
        newRowData = newRowData.filter((r) => r.id !== q);
      });

      setRowData([...newRowData]);
    } catch (err) {
      console.log(err);
      enqueueSnackbar("Something went wrong!", { variant: "error" });
    }
  };

  const handleRowSelection = (data) => {
    if (!selectionMode && isAdmin) {
      setToBeDeleted([...data]);
    } else {
      const srow = rowData.find((r) => r.id === data[0]);
      setSelectedRow(srow);
      selectQuotation(srow);
      console.log(srow);
    }
  };

  return (
    <TableContainer>
      <DetailsContainer>
        <Typography>
          {getProjectType(project.projectDetails).label} - {project.projectName}
        </Typography>
        {type === "3dprinting" || type === "injectionMolding" ? (
          <>
            <Box className="detail-col">
              <Typography>
                Quantity: {project.projectDetails.quantity}
              </Typography>
              <Typography>
                Material :{" "}
                {project.projectDetails.material ||
                  project.projectDetails.resinMaterial}
              </Typography>
            </Box>
            <Box className="detail-col">
              <Typography>Colour: {project.projectDetails.colour}</Typography>
              {type == "3dprinting" ? (
                <Typography>
                  Printing Service : {project.projectDetails.printingService}
                </Typography>
              ) : (
                <Typography>Mold : {project.projectDetails.mold}</Typography>
              )}
            </Box>
          </>
        ) : (
          <Box className="detail-col">
            <Typography>Colour: {project.projectDetails.colour}</Typography>
            <Typography>
              Dimension (in mm): {project.projectDetails.outerDimensions}
            </Typography>
          </Box>
        )}
      </DetailsContainer>
      <Box
        sx={{
          height: "fit-content",
          width: "100%",
          marginTop: "20px",
          [`& .custom-${selectedQuotation?.totalCost}`]:{
            backgroundColor:'#FF972A',
          },
          [`& .custom-${selectedQuotation?.totalCost}:hover`]:{
            backgroundColor:'#FF972A',
          }
        }}
      >
        {disabled ? (
          <DataGrid
            rows={rowData}
            columns={dataCols}
            pageSize={5}
            // className={classes.root}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            autoHeight
            getRowClassName={(params) => `custom-${params.row.totalCost}`}
            selectionModel={[selectedQuotation]}

          />
        ) : (
          <DataGrid
            rows={rowData}
            columns={dataCols}
            pageSize={5}
            // className={classes.root}
            rowsPerPageOptions={[5]}
            checkboxSelection={!selectionMode && isAdmin}
            disableSelectionOnClick={!selectionMode && isAdmin}
            onCellEditCommit={handleCellEdit}
            onSelectionModelChange={handleRowSelection}
            autoHeight
            getRowClassName={(params) => `custom-${params.row.totalCost}`}
          />
        )}
      </Box>
      {isAdmin ? (
        <CustomButton
          disabled={tobeDeleted.length === 0}
          onClick={handleDeleteQuotations}
        >
          Delete Quotations
        </CustomButton>
      ) : null}
    </TableContainer>
  );
};

export default ColosseumQuotationsTable;
