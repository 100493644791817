import React, { useState, useEffect } from "react";

//libs
import {
  Modal,
  Box,
  styled,
  Button,
  Slide,
  LinearProgress,
} from "@mui/material";
import { axiosInstance } from "../../utils/axiosInterceptor";
import { useSnackbar } from "notistack";

//components
import CustomFileDroper from "../../Components/CustomFileDropper";

const ModalContainer = styled(Box)(({theme}) => ({
  position: "absolute",
  width: "36vw",
  height: "fit-content",
  left: "32vw",
  top: "15vh",
  backgroundColor: "#fff",
  boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.2)",
  borderRadius: 15,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "30px 30px 10px 30px",
  gap: "20px",
  "& .heading": {
    fontSize: "1.2em",
    fontWeight: 700,
  },
  [theme.breakpoints.down('md')]:{
    width:'90vw',
    left:'5vw',
    
  }
}));

export const CustomButton = styled(Button)(() => ({
  "&&&": {
    backgroundColor: "#FF972A",
    color: "#fff",
    textTransform: "none",
    padding: "5px 50px",
    borderRadius: "8px",
    fontWeight: 500,
    margin: "10px auto",
  },
}));

const ReuploadGerberFile = ({ state, toggleModal, id, type, serviceType }) => {
  const [gerberFile, setGerberFile] = useState(null);
  const [progress, setProgress] = useState({
    loading: false,
    value: 0,
  });

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    console.log(gerberFile, "here e go!");
  }, [gerberFile]);

  const handleSubmit = async () => {
    if (gerberFile !== null) {
      let formData = new FormData();
      formData.append("fab", gerberFile);
      const token = localStorage.getItem("token");
      try {
        console.log(id);
        setProgress({
          value: 0,
          loading: true,
        });
        const res = await axiosInstance.post(
          `/fab/client/reuploadBom/${id}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
              var percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setProgress({
                loading: true,
                value: percentCompleted,
              });
            },
          }
        );

        console.log(res);
        enqueueSnackbar("Files Updated successfully Successfully!", {
          variant: "success",
        });
        setProgress({
          value: 100,
          loading: false,
        });
        toggleModal();
        window.location.reload();
      } catch (e) {
        console.log(e);
        enqueueSnackbar("some error occurred", { variant: "error" });
      }
    } else {
      enqueueSnackbar("Please select a file!", { variant: "error" });
    }
  };

  return (
    <Modal open={state} onClose={toggleModal}>
      <Slide in={state} direction="up">
        <ModalContainer>
          <div className="heading">
            Elecbits PCB Fabricator - Reupload Files
          </div>
          <CustomFileDroper
            small
            setFile={setGerberFile}
            single
            headerText="Drag and drop to upload a file"
            subText="Only .zip and .rar files are accepted. Maximum file size: 20mb"
          />
          <Box sx={{ width: "100%", marginTop: "20px" }}>
            <LinearProgress
              variant="determinate"
              value={progress.value}
              sx={{
                backgroundColor: "#8B8B8B4D",
                "& .MuiLinearProgress-bar": { backgroundColor: "#FF972A" },
              }}
            />
            <p>{progress.value}%</p>
          </Box>
          <CustomButton onClick={handleSubmit}>Submit</CustomButton>
        </ModalContainer>
      </Slide>
    </Modal>
  );
};

export default ReuploadGerberFile;
