import React, { useState } from "react";
import {
  Box,
  Button,
  Modal,
  styled,
  Slide,
  Grid,
  TextField,
  Typography,
  Avatar,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { axiosInstance } from "../../utils/axiosInterceptor";
import { useSnackbar } from "notistack";
import moment from "moment";

const ConsultationDetailsContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: "75vw",
  height: "fit-content",
  left: "15vw",
  top: "10vh",
  maxHeight: "80vh",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#fff",
  outline: "none",
  borderRadius: 10,
  padding: "20px 30px 20px 30px",
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  gap: "20px",
  justifyContent: "space-between",
  "& .headerText": {
    fontSize: "1.3em",
    fontWeight: 600,
  },

  "& .bd": {
    fontSize: "0.9em",
    color: "#828282",
    fontWeight: 600,
  },
  "& .hd": {
    fontSize: "0.9em",
    color: "#4e4e4e",
    fontWeight: 600,
  },
  [theme.breakpoints.down("md")]: {
    width: "50vw",
  },
  [theme.breakpoints.down("sm")]: {
    width: "80vw",
  },
}));

const hyperlink = (textContent) => {
  let a = textContent.replace(
    /(https?:\/\/[^\s]+)/g,
    (href) => `<a href="${href}" target="_blank">${href}</a>`
  );
  return { __html: a };
};

const OuterDetailContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  gap: "40px",
  flex: 1,
}));

const CustomButton = styled(LoadingButton)(() => ({
  "&&&": {
    backgroundColor: "#FF972A",
    color: "#fff",
    textTransform: "none",
    padding: "3px 30px",
    borderRadius: "8px",
    fontWeight: 500,
    fontSizr: "0.8em",
  },
}));

const CustomGrid = styled(Box)(() => ({
  display: "flex",
  gap: "10px",
  alignItems: "center",
}));

const ConsultationDetails = ({ state, toggleModal, consultation }) => {
  const [link, setLink] = useState("");
  const [comment, setComment] = useState("");
  const [allComments, setAllComments] = useState(consultation.comments);
  const { enqueueSnackbar } = useSnackbar();

  const sendLink = async () => {
    if (link == "") {
      enqueueSnackbar("Please add link !", { variant: "error" });
      return;
    }
    try {
      const res = await axiosInstance.post("/users/acceptConsultation", {
        id: consultation._id,
        link,
      });

      console.log(res);
      if (res.status == 200) {
        enqueueSnackbar("Link sent successfully!", { variant: "success" });
      }
    } catch (err) {
      console.log(err);
      enqueueSnackbar("Something went wrong!", { variant: "error" });
    }
  };

  const addComment = async () => {
    if (comment == "") {
      enqueueSnackbar("Please add a comment", { variant: "error" });
      return;
    }

    try {
      const res = await axiosInstance.post("/users/commentConsultation", {
        id: consultation._id,
        comment,
      });

      console.log(res);
      if (res.status == 200) {
        enqueueSnackbar("Comment added successfully!", { variant: "success" });
        const nc = res.data.comment;
        const newcomments = [...allComments, nc];
        setAllComments(newcomments);
        setComment("");
      }
    } catch (err) {
      console.log(err);
      enqueueSnackbar("Something went wrong!", { variant: "error" });
    }
  };

  return (
    <Modal open={state} onClose={toggleModal}>
      <Slide in={state} direction="up">
        <ConsultationDetailsContainer>
          <h1 className="headerText">Consult an expert</h1>
          <OuterDetailContainer>
            <Box sx={{ flex: 1, maxHeight: "66vh",overflowY:"auto" }}>
              <Typography className="hd">DETAILS</Typography>
              {consultation.status.isApproved ? (
                <>
                  <Typography
                    sx={{
                      width: "fit-content",
                      height: "fit-content",
                      backgroundColor: "#0abf47",
                      fontSize: "0.8em",
                      padding: "3px 20px",
                      borderRadius: "30px",
                      color: "white",
                      marginTop: "10px",
                      marginBottom: "5px",
                    }}
                  >
                    APPROVED
                  </Typography>
                  <a href={consultation.status.link} target="_blank">
                    {consultation.status.link}
                  </a>
                </>
              ) : null}
              <Box
                sx={{
                  marginTop: "20px",
                  border: "1px solid #a4a4a4",
                  borderRadius: "10px",
                  padding: "10px",
                }}
              >
                <CustomGrid>
                  <Typography className="bd">Name:</Typography>
                  <Typography>
                    {consultation.details?.fullName || "Test"}
                  </Typography>
                </CustomGrid>
                <CustomGrid>
                  <Typography className="bd">Company:</Typography>
                  <Typography>
                    {consultation.details?.company || "Test"}
                  </Typography>
                </CustomGrid>
                <CustomGrid>
                  <Typography className="bd">Designation:</Typography>
                  <Typography>
                    {consultation.details?.designation || "Test"}
                  </Typography>
                </CustomGrid>
                <CustomGrid>
                  <Typography className="bd">Email:</Typography>
                  <Typography>
                    {consultation.details?.email || "Test"}
                  </Typography>
                </CustomGrid>
                <CustomGrid>
                  <Typography className="bd">Created At:</Typography>
                  <Typography>
                    {moment(consultation?.createdAt).format(
                      "hh:mm a, Do MMMM YYYY"
                    ) || "NaN"}
                  </Typography>
                </CustomGrid>
              </Box>
              <Box
                sx={{
                  marginTop: "20px",
                  border: "1px solid #a4a4a4",
                  borderRadius: "10px",
                  padding: "10px",
                  fontSize: "0.9em",

                }}
              >
                {consultation.details.details}
              </Box>
            </Box>
            <Box
              sx={{
                flex: 1,
                height: "100%",
                display: "flex",
                flexDirection: "column",
                maxHeight: "65vh",
                overflowY: "auto",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  alignItems: "start",
                }}
              >
                <Typography className="hd">SEND LINK</Typography>
                <TextField
                  label="Link"
                  fullWidth
                  variant="standard"
                  onChange={(e) => {
                    setLink(e.target.value);
                  }}
                  value={link}
                />
                <CustomButton onClick={sendLink}>SEND</CustomButton>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  alignItems: "start",
                  marginTop: "30px",
                  flex: 1,
                  //   backgroundColor: "red",
                }}
              >
                <Typography className="hd">ADD COMMENT</Typography>
                <TextField
                  label="Comment"
                  fullWidth
                  variant="standard"
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                  value={comment}
                />
                <CustomButton onClick={addComment}>COMMENT</CustomButton>

                <Box
                  sx={{
                    width: "100%",
                    maxHeight: "30%",
                    overflowY: "auto",
                    flex: 1,
                  }}
                >
                  {allComments
                    .slice(0)
                    .reverse()
                    .map((comment) => (
                      <Box
                        sx={{
                          display: "flex",
                          whiteSpace: "pre-line",
                          justifyContent: "space-between",
                          // boxShadow: "0px 0px 30px rgba(139, 139, 139, 0.15)",
                          border: "2px solid #E8E8E8",
                          margin: "10px 5px",
                          padding: "10px 5px",
                          width: "95%",
                          overflow: "visible",
                          borderRadius: "8px",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            width: "20%",
                            height: "100%",
                            display: "grid",
                            placeItems: "center",
                          }}
                        >
                          <Avatar
                            sx={{ width: "65px", height: "65px" }}
                            src={comment.userProfilePic}
                          />
                        </Box>
                        <Box sx={{ flex: 1, maxWidth: "80%" }}>
                          <Typography
                            sx={{ color: "#2F2F2F", fontSize: "0.8em" }}
                          >
                            {comment.userName}
                          </Typography>
                          <Typography
                            sx={{ color: "#8B8B8B", fontSize: "0.7em" }}
                          >
                            {moment(comment.createdOn).format(
                              "MMMM Do YYYY, h:mma"
                            )}
                          </Typography>
                          <Typography sx={{ color: "#2F2F2F" }}>
                            <div
                              style={{
                                maxWidth: "100%",
                                overflowWrap: "break-word",
                              }}
                              dangerouslySetInnerHTML={hyperlink(
                                comment.comment
                              )}
                            />
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                </Box>
              </Box>
            </Box>
          </OuterDetailContainer>
        </ConsultationDetailsContainer>
      </Slide>
    </Modal>
  );
};

export default ConsultationDetails;
