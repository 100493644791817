import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import elecbitsLogoWhite from '../assests/elecbitsLogoWhite.svg';
import DashboardLogo1 from '../assests/logoBlack.png';

const useStyles = makeStyles((theme) => ({
  theming: {
    color: 'white',
    backgroundColor: 'black',
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  typography: {
    padding: theme.spacing(1.4),
    textAlign: 'center',
  },
}));

export default function DashboardHeader(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  let handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  let handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const classes = useStyles();
  console.log(props.profile_data);
  return (
    <div
      style={props.isBlack ? { backgroundColor: '#F4F7FE' } : {}}
      className="header_wrapper"
    >
      <Link to="/">
        <div>
          <img src={DashboardLogo1} style={{ width: '160px' }}></img>
        </div>
      </Link>
      {props.profile_data.profilePic ? (
        <Button aria-describedby={id} onClick={handleClick}>
          <Avatar
            className={classes.theming}
            src={`${props.profile_data.profilePic}`}
          ></Avatar>
        </Button>
      ) : (
        <Button aria-describedby={id} onClick={handleClick}>
          <Avatar className={classes.theming}>
            {props.profile_data.email[0].toUpperCase()}
          </Avatar>
        </Button>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Link to="/profile" style={{ textDecoration: 'none', color: 'black' }}>
          <div className="pop_over_item">
            <Typography className={classes.typography}>
              {props.profile_data.email}
            </Typography>
          </div>
        </Link>

        <div
          className="pop_over_item"
          onClick={() => {
            localStorage.clear();
            props.history.push('/');
            props.toggleLoggedIn();
          }}
        >
          <Typography className={classes.typography}>Logout</Typography>
        </div>
      </Popover>
    </div>
  );
}
