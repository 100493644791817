import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { DropzoneArea } from "material-ui-dropzone";
import { SnackbarProvider, useSnackbar } from "notistack";
import { axiosInstance } from "../utils/axiosInterceptor";
import axios from "axios";
import LinearProgress from "@material-ui/core/LinearProgress";
export default function ProjectProfileChange(props) {
  const { enqueueSnackbar } = useSnackbar();
  let [files, setFiles] = React.useState([]);
  let uploadPic = () => {
    let formData = new FormData();
    let file=files[files.length - 1];
    console.log(file);
    formData.append("doc", file);
    let token = localStorage.getItem("token");
    props.setEditLoading(true);
    axios.post(
      `https://platform.elecbits.in/api/projects/changeProjectProfile/${props.project._id}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "multipart/form-data",
        },
      }
    )
    .then((resp)=>{
        enqueueSnackbar("Document uploaded successfully", {
            variant: "success",
          });
          
          props.setEditLoading(false);
          props.handleClose();
          props.fetchProjectData();
    })
    .catch((err)=>{
        enqueueSnackbar("Not able to upload document", {
            variant: "error",
          });
          props.setEditLoading(false);
    });
  };
  return (
    <div style={{ marginTop: "10px" }}>
      <DropzoneArea
        maxFileSize={20000000}
        onChange={(files) => {
          setFiles(files);
        }}
        filesLimt={2}
        acceptedFiles={["image/*"]}
      />
      <Button variant="contained" onClick={uploadPic} color="primary" style={{ marginTop: "10px" }}>
        Upload Pic
      </Button>
    </div>
  );
}
