import React, { useState, useEffect } from "react";
import { axiosInstance } from "../../utils/axiosInterceptor";
import "./ProfileView.css";
import { Container, Row, Col } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import CircularProgress from "@material-ui/core/CircularProgress";
import DashboardLogo1 from "../../assests/elecbitsLogo.svg";
import EditIcon from "@material-ui/icons/Edit";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { SnackbarProvider, useSnackbar } from "notistack";
import LinearProgress from "@material-ui/core/LinearProgress";
import axios from "axios";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link, useHistory } from "react-router-dom";
import FileDroper from "./FileDroper";
import { Box, Modal, styled } from "@mui/material";

import CustomTextfield from "./CustomTextfield";
import CustomButton from "./CustomButton";
import ShippingAddress from "./ShippingAddress";

const ImageModalContainer = styled("div")(({ url }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30vw",
  height: "30vw",
  backgroundColor: "#fff",
  outline: "none",
  backgroundImage: `url('${url}')`,
  backgroundPosition: "center",
  backgroundSize: "cover",
}));

const ProfileContainer = styled("div")(({ theme }) => ({
  maxWidth: "50vw",
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("lg")]: {
    maxWidth: "70vw",
  },
  [theme.breakpoints.down("md")]: {
    maxWidth: "80vw",
  },
  [theme.breakpoints.down("sm")]: {
    maxWidth: "90vw",
  },
}));

const useStyles = makeStyles((theme) => ({
  margin: {
    marginTop: theme.spacing(2),
  },
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));
export default function ProfileView() {
  const classes = useStyles();
  const [profileInfo, setProfile] = useState(null);
  const [profileData, setProfileData] = useState({
    email: "",
    name: "",
    phoneNumber: "",
    enterpriseName: "",
    profilePic: "",
    shippingAddresses: [],
  });
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const [updatedPic, setPic] = useState(null);
  const [imgUrl, setUrl] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);
  const [hasProfile, setHasProfile] = useState(null);
  const toggleImageModal = () => {
    setShowImageModal(!showImageModal);
  };

  const history = useHistory();

  function readURL(input) {
    if (input) {
      var reader = new FileReader();
      reader.onload = (e) => {
        console.log(e.target.result);
        setUrl(e.target.result);
      };
      reader.readAsDataURL(input);
    }
  }

  const uploadFile = async (newPic) => {
    console.log(newPic);
    setPic(newPic);
    readURL(newPic);
    await updateProfile(newPic);
  };
  const { enqueueSnackbar } = useSnackbar();
  let fetchProfile = () => {
    setLoading(true);
    axiosInstance
      .get("/users/getProfile")
      .then((profile) => {
        console.log(profile);
        if (!profile.data.data.phoneNumber) {
          setHasProfile(false);
        } else {
          setHasProfile(true);
        }
        setProfile(profile.data.data);
        setFirstName(profile.data.data.firstName);
        setLastName(profile.data.data.lastName);
        console.log(profile.data.data.profilePic);
        setUrl(`${profile.data.data.profilePic}`);
        setProfileData({
          name: `${profile.data?.data?.firstName || ""} ${
            profile.data?.data?.lastName || ""
          }`,
          email: profile.data?.data?.email,
          profilePic: profile.data?.data?.profilePic,
          enterpriseName: profile.data?.data?.enterpriseName,
          phoneNumber: profile.data?.data?.phoneNumber,
          shippingAddresses: profile.data?.data?.shippingAddress,
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(()=>{
    if(hasProfile === false){
      history.push('/client/onBoarding')
    }
  },[hasProfile])

  const updateProfile = async (pic) => {
    setBtnLoading(true);
    let formData = new FormData();

    if (pic) {
      formData.append("avatar", pic);
    } else if (updatedPic) {
      formData.append("avatar", updatedPic);
    }

    formData.append("phoneNumber", profileData.phoneNumber);
    formData.append("enterpriseName", profileData.enterpriseName);
    formData.append("firstName", profileData.name.split(" ")[0]);
    formData.append("lastName", profileData.name.split(" ")[1]);

    console.log(profileData);

    let token = localStorage.getItem("token");

    const res = await axiosInstance.put("/users/editProfile", formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "multipart/form-data",
      },
    });

    console.log(res, "this is updated info");
    setProfileData({
      ...profileData,
      profilePic: res.data?.data?.profile?.profilePic,
    });
    if (res.status === 200) {
      enqueueSnackbar("Successfully saved changes!", { variant: "success" });
      setBtnLoading(false);
    } else {
      enqueueSnackbar("Not able to save changes!", { variant: "error" });
      setBtnLoading(false);
    }
  };

  let handleSubmit = (e) => {
    e.preventDefault();
    console.log(updatedPic);
    setBtnLoading(true);
    let formData = new FormData();
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    if (updatedPic) {
      formData.append("avatar", updatedPic);
    }
    let token = localStorage.getItem("token");
    axios
      .put(`https://platform.elecbits.in/api/users/editProfile`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        enqueueSnackbar("Successfully saved changes!", { variant: "success" });
        setBtnLoading(false);
        fetchProfile();
      })
      .catch((err) => {
        enqueueSnackbar("Not able to save changes!", { variant: "error" });
        setBtnLoading(false);
      });
  };

  const handleTextChange = (e) => {
    setProfileData({
      ...profileData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    document.title = "Elecbits | Profile";
    fetchProfile();
  }, []);
  console.log(imgUrl);
  return (
    <>
      {btnLoading ? (
        <div className={classes.root}>
          <LinearProgress></LinearProgress>
        </div>
      ) : (
        ""
      )}

      <div className="prof_wrapper">
        {!loading && profileInfo ? (
          <Container className="profile_wrapper">
            <ProfileContainer>
              <h3 className="profile_header">My Account 🛰</h3>
              <div className="profile_label">Profile Picture</div>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: ["column", "row"],
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Avatar
                  src={profileData?.profilePic}
                  style={{ marginBottom: "10px" }}
                />
                <div>
                  <CustomButton onClick={toggleImageModal}>
                    VIEW PICTURE
                  </CustomButton>
                  <FileDroper uploadFile={uploadFile}></FileDroper>
                </div>
                <Modal open={showImageModal} onClose={toggleImageModal}>
                  <ImageModalContainer url={profileData.profilePic} />
                </Modal>
              </Box>
              <CustomTextfield
                label="Name"
                value={profileData.name}
                name="name"
                onChange={handleTextChange}
                saveChange={updateProfile}
              />
              <CustomTextfield
                label="Email"
                disableEdit
                value={profileData.email}
              />
              <div className="account_signup">
                <div className="profile_label">Account Signing Type</div>
                <span
                  className={`signup_option ${
                    profileInfo.googleId ? "active_option" : ""
                  }`}
                >
                  Google Signup
                </span>
                <span
                  className={`signup_option ${
                    !profileInfo.googleId ? "active_option" : ""
                  } `}
                >
                  Email Signup
                </span>
              </div>
              <CustomTextfield
                label="Phone Number"
                value={profileData.phoneNumber}
                name="phoneNumber"
                onChange={handleTextChange}
                saveChange={updateProfile}
              />
              <CustomTextfield
                label="Organizartion Name"
                value={profileData.enterpriseName}
                name="enterpriseName"
                onChange={handleTextChange}
                saveChange={updateProfile}
              />
              <div className="team_section">
                <span>
                  {" "}
                  <span className="profile_label">Team Members</span>
                  <span className="small_text">(coming soon)</span>
                </span>
                <CustomButton isDisabled>INVITE+</CustomButton>
              </div>
              <ShippingAddress
                shippingAddresses={profileData.shippingAddresses}
              />
            </ProfileContainer>
          </Container>
        ) : (
          <>
            <img src={DashboardLogo1} style={{ width: "150px" }}></img>
            <CircularProgress style={{ marginLeft: "20px" }}></CircularProgress>
          </>
        )}
      </div>
    </>
  );
}
