import React, { useState, useEffect } from "react";
import "../HomeComponent/Home.css";
import { Container, Row, Col } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

import PCBImage from "../assests/pcbImg.svg";
import DashboardHeader from "../ClientEngagement/AdminDashboardHeader";
import Box from "@mui/material/Box";
import { axiosInstance } from "../utils/axiosInterceptor";
import CircularProgress from "@material-ui/core/CircularProgress";
import DashboardLogo1 from "../assests/elecbitsLogo.svg";
import _3dServices from "../assests/_3dServices.png";
import bomExplorer from "../assests/bomExplorer.png";
import consultations from "../assests/consultations.png";

import customerEngagement from "../assests/customerEngagement.png";

import pcbArena from "../assests/pcbArena.png";
import pcbAssembly from "../assests/pcbAssembly.png";
import { Typography, InputAdornment, useTheme } from "@mui/material";
import UserOverview from "../Components/UserOverview";
import { Search } from "@mui/icons-material";
import CustomBreadcrumbs from "../Components/CustomBreadcrumbs";
import { CustomButton } from "../BOMProcurement/BomOrderSummary";
import AddPmModal from "./AddPmModal";
import ConfirmModal from "../Components/ComfirmModal";
import { useSnackbar } from "notistack";

const StyledButton = withStyles({
  root: {
    background: "#6200EE",
    color: "white",
    "&:hover": {
      backgroundColor: "#6200EE",
    },
  },
})(Button);

export default function AllPMs(props) {
  const [profile_data, set_profile] = React.useState(null);
  const [loading, set_loading] = React.useState(null);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFiltetredUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);
  const [showCofirmModal, setShowConfirmModal] = useState({
    state: false,
    id: null,
    name: null,
  });
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const includesName = (searchQuery, firstName, lastName) => {
    if (firstName === undefined || lastName === undefined) return false;
    const nm = firstName + " " + lastName;
    return nm.toLowerCase().includes(searchQuery.toLowerCase());
  };

  const getName = (user) => {
    if (user?.fullName) return user.fullName;

    if (user?.firstName == undefined || user?.lastName == undefined) {
      return "NaN";
    }
  };

  const toggleConfirmModal = () => {
    setShowConfirmModal({
      ...showCofirmModal,
      state: !showCofirmModal.state,
    });
  };

  const removePM = async (id) => {
    try {
      const res = await axiosInstance.get(`/pm/removePM/${id}`);
      const nu = filteredUsers.filter((u) => u?._id != id);
      setFiltetredUsers(nu);
      enqueueSnackbar("PM removed successfully!", { variant: "success" });
    } catch (err) {
      console.log(err);
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  useEffect(() => {
    setFiltetredUsers(
      users.filter(
        (u) =>
          u?.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
          includesName(searchQuery, u?.firstName, u?.lastName)
      )
    );
  }, [searchQuery]);

  let fetchProfile = () => {
    set_loading(true);
    axiosInstance
      .get("/users/getProfile")
      .then((resp) => {
        set_profile(resp.data);
        set_loading(false);
      })
      .catch((err) => {
        set_loading(false);
      });
  };

  const fetchUsers = async () => {
    const res = await axiosInstance.get("/pm/getAllPMs");
    console.log(res, "here i am");
    if (res.status === 200) {
      setUsers(res.data.data);
      setFiltetredUsers(res.data.data);
    }
  };

  const toggleAddModal = () => {
    setShowAddModal(!showAddModal);
  };

  React.useEffect(() => {
    fetchProfile();
    fetchUsers();
  }, []);
  return (
    <Box>
      {!loading && profile_data ? (
        <Box>
          <DashboardHeader
            profile_data={profile_data.data}
            toggleLoggedIn={props.toggleLoggedIn}
            history={props.history}
            isBlack
          ></DashboardHeader>
          <Container>
            <Typography
              sx={{
                fontSize: "2em",
                fontWeight: 400,
                marginTop: "32px",
                marginBottom: "20px",
              }}
            >
              <CustomBreadcrumbs
                data={[
                  {
                    name: "Home",
                    link: "/",
                  },
                  {
                    name: "Platform PMs",
                    link: "/admin/projectManagers",
                  },
                ]}
              />
              Platform Project Managers 🎰
            </Typography>
            <CustomButton onClick={toggleAddModal}>ADD NEW PM</CustomButton>
            <AddPmModal state={showAddModal} toggleModal={toggleAddModal} />

            <Typography sx={{ color: "#0275FC", marginTop: "16px" }}>
              Total pm count: {users.length}
            </Typography>
            <Box
              sx={{
                width: "30%",
                marginTop: "20px",
                marginBottom: "30px",
                [theme.breakpoints.down("lg")]: { width: "40%" },
                [theme.breakpoints.down("md")]: { width: "60%" },
                [theme.breakpoints.down("sm")]: { width: "80%" },
              }}
            >
              <TextField
                placeholder="Search by name,email"
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box>
              {filteredUsers
                .slice()
                .reverse()
                .map((user) => (
                  <Box
                    sx={{
                      width: "30vw",
                      padding: "5px 10px",
                      borderRadius: "5px",
                      height: "fit-content",
                      margin: "15px 0px",
                      border: "1px solid gray",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ fontSize: "1.2em" }}>
                        {getName(user)}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "0.8em",
                          color: "red",
                          letterSpacing: "1px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setShowConfirmModal({
                            state: true,
                            id: user?._id,
                            name: getName(user),
                          });
                          // toggleConfirmModal();
                        }}
                      >
                        REMOVE
                      </Typography>
                    </Box>
                    <Typography sx={{ fontSize: "0.8em", color: "#989898" }}>
                      {user?.email}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        fontSize: "0.8em",
                        color: "#989898",
                      }}
                    >
                      <Typography>{user?.uPass}</Typography>
                    </Box>
                  </Box>
                ))}
            </Box>
            <ConfirmModal
              state={showCofirmModal.state}
              onNo={toggleConfirmModal}
              toggleModal={toggleConfirmModal}
              onYes={() => {
                removePM(showCofirmModal.id);
                toggleConfirmModal();
              }}
              text={`Are you sure you want to remove ${showCofirmModal.name} as PM?`}
              sub="You can grant pm rights again!"
            />
          </Container>
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={DashboardLogo1} style={{ width: "150px" }} alt="logo"></img>
          <CircularProgress style={{ marginLeft: "20px" }}></CircularProgress>
        </Box>
      )}
    </Box>
  );
}
