import React, { useState, useEffect } from 'react';

//components
import CustomBreadcrumbs from '../Components/CustomBreadcrumbs';
import DashboardHeader from '../ClientEngagement/DashboardHeader';
//utils
import { axiosInstance } from '../utils/axiosInterceptor';
import {
  Box,
  InputAdornment,
  useTheme,
  FormControl,
  Select,
  InputLabel,
  Grid,
  Typography,
  TextField,
  Rating,
  Button,
} from '@mui/material';
import { MenuItem } from '@material-ui/core';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { useSnackbar } from 'notistack';
import { ReactComponent as UploadIcon } from '../assests/Upload.svg';
import Input from '@mui/material/Input';
import Chip from '@mui/material/Chip';
//styles
import {
  PageContainer,
  ColosseumProjectsContainer,
  StyledInput,
  CustomSelect,
  CustomInput,
  StyledRow,
  CustomButton2,
} from './VendorManagement.styles';

//assets
import { ReactComponent as SearchIcon } from '../assests/search.svg';
import timeline from '../assests/timeline.png';
import bin from '../assests/bin.png';
import { useHistory } from 'react-router-dom';
import DeleteModal from '../Components/DeleteModal';
import VendorInfoModal from './VendorInfoModal';
import VendorDocModal from './VendorDocModal';
import { ReactComponent as CloseIcon } from '../assests/close.svg';
import DescriptionIcon from '@mui/icons-material/Description';
import BusinessIcon from '@mui/icons-material/Business';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

const services = [
  'Bill of Material',
  'PCB Fabrication',
  'PCB Assembly',
  '3D Printing',
  'Injection Moulding',
  'Vaccum Casting',
  'Mould Manufacturer',
  'Metal Casting',
  'Metal Fabrication',
  'Pre built Cases',
  'CNC Machining & Tooling',
  'QA &  Certifications',
  'Designing & Modeling',
  'Lab Equipment',
  'PCB Designing',
  'EV Battery & Charger',
  'Wire harness',
  'Assembly Line Equipment',
  'Mechanical Tools',
  'Smart board/tv',
  'Boxes',
];

const AddNewVendor = (props) => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isPM, setIsPM] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [primaryData, setPrimaryData] = useState({
    vendorName: '',
    vendorId: '',
    vendorService: [],
    personName: '',
    emails: '',
    phone: '',
    location: '',
    locationCity: '',
    locationState: '',
    locationCountry: '',
    websiteLink: '',
    whatsappNo: '',
    address: '',
    remarks: [],
  });
  const [industryData, setIndustryData] = useState({
    rating: 0,
    responseTime: '',
    orderOfServices: '',
    timeForQuotation: '',
    paymentTerms: '',
    modeOfPayment: '',
    cinNumber: '',
    panNumber: '',
    gstNumber: '',
    reviews: '',
  });
  const [bankData, setBankData] = useState({
    bankName: '',
    accountName: '',
    accountNumber: '',
    ifsc: '',
    branch: '',
    mobileNumber: '',
  });
  const [currentRemark, setCurrentRemark] = useState('');
  const [images, setImages] = useState([]);
  const [docs, setDocs] = useState([]);
  const [supportedDocs, setSpportedDocs] = useState([]);

  const [showDocModal, setShowDocModal] = useState(false);
  const toggleDocModal = () => {
    setShowDocModal(!showDocModal);
  };

  const [showSupportedModal, setShowSupportedModal] = useState(false);
  const toggleSupportedModal = () => {
    setShowSupportedModal(!showSupportedModal);
  };

  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { getRootProps, getInputProps, acceptedFiles, isDragActive } =
    useDropzone();

  const fetchProfile = () => {
    setLoading(true);
    axiosInstance
      .get('/users/getProfile')
      .then((resp) => {
        setProfile(resp.data);
        console.log(resp);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

    useEffect(() => {
        if(localStorage.PM && history.location.pathname.includes("/admin")) history.push("/pm/addNewVendor");
    }, []);
  useEffect(() => {
    if (localStorage.PM) {
      setIsPM(true);
    }
    fetchProfile();
  }, []);

  const handlePrimaryDataChange = (e) => {
    setPrimaryData({
      ...primaryData,
      [e.target.name]: e.target.value,
    });
  };

  const handleIndustryDataChange = (e) => {
    setIndustryData({
      ...industryData,
      [e.target.name]: e.target.value,
    });
  };

  const handleBankDataChange = (e) => {
    setBankData({
      ...bankData,
      [e.target.name]: e.target.value,
    });
  };

  const uploadDocuments = async (vendorId) => {
    let formData = new FormData();
    let url;
    const token = localStorage.getItem('token');

    if (!vendorId) {
      return;
    }

    if (acceptedFiles.length > 0) {
      // formData.append("images", acceptedFiles);
      acceptedFiles.forEach((fl) => formData.append('images', fl));
      url = `/vendor/addImages/${vendorId}`;

      const res = await axiosInstance.post(url, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-type': 'multipart/form-data',
        },
      });

      console.log(res, 'yo,res1');
    }

    formData.delete('images');

    if (docs.length > 0) {
      docs.forEach((fl) => formData.append('docs', fl));

      url = `/vendor/addDocs/${vendorId}`;

      const res = await axiosInstance.post(url, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-type': 'multipart/form-data',
        },
      });
      console.log(res, 'yo,res2');
    }

    formData.delete('docs');

    if (supportedDocs.length > 0) {
      supportedDocs.forEach((fl) => formData.append('sdocs', fl));
      url = `/vendor/addSupportedDocs/${vendorId}`;
      const res = await axiosInstance.post(url, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-type': 'multipart/form-data',
        },
      });
      console.log(res, 'yo,res3');
    }
  };

  const handleSubmit = async () => {
    console.log(primaryData);
    console.log(industryData);
    console.log(bankData);

    if (
      primaryData.vendorName == '' ||
      primaryData.vendorId == '' ||
      primaryData.vendorService == '' ||
      primaryData.vendorService == []
    ) {
      enqueueSnackbar('Please fill the required fields', { variant: 'error' });
      return;
    }

    setAddLoading(true);
    primaryData.location2 = `${primaryData.locationCity},${primaryData.locationState},${primaryData.locationCountry}`;
    console.log('check this location dawg', primaryData.location2);
    const res = await axiosInstance.post('/vendor/addVendor', {
      primaryData,
      industryData,
      bankData,
    });
    console.log(res, 'yo, this is the invincible response');

    if (!res.data.ok) {
      enqueueSnackbar(res.data?.message || 'Something went wrong', {
        variant: 'error',
      });
      setAddLoading(false);

      return;
    }

    if (res.data.ok && res.data.vendor) {
      await uploadDocuments(res.data.vendor._id);
    }
    setAddLoading(false);
    history.push(`/admin/vendorMasterList?vendor=${res.data.vendor.vendorId}`);
  };

  return (
    <Box>
      {!loading ? (
        <PageContainer>
          {/* <DashboardHeader profile_data={profile.data} isBlack={true} /> */}
          <ColosseumProjectsContainer>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <CustomBreadcrumbs
                data={[
                  {
                    name: 'Home',
                    link: '/',
                  },
                  {
                    name: 'EB Vendor Management',
                    link: '/admin/AddNewVendor',
                  },
                ]}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '50px',
                [theme.breakpoints.down('md')]: {
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '10px',
                },
              }}
            >
              <div className="title">Add New Vendor</div>

              <Box sx={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
                <CustomButton2 onClick={handleSubmit} disabled={addLoading}>
                  Save
                </CustomButton2>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '20px',
                alignItems: 'center',
                zIndex: 9999,
                marginBottom: '30px',
              }}
            >
              {primaryData.remarks.length > 0 &&
                primaryData.remarks.map((remark) => (
                  <Box
                    sx={{
                      fontSize: '0.8em',
                      backgroundColor: 'rgba(29, 201, 255, 0.15)',
                      border: '1px solid #1DC9FF',
                      padding: '3px 10px',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                    }}
                  >
                    <Typography> {remark}</Typography>
                    <Box
                      sx={{
                        cursor: 'pointer',
                        padding: '3px',
                      }}
                      onClick={() => {
                        const nrm = primaryData.remarks.filter(
                          (r) => r != remark
                        );
                        setPrimaryData({
                          ...primaryData,
                          remarks: nrm,
                        });
                      }}
                    >
                      <CloseIcon style={{ cursor: 'pointer' }} />
                    </Box>
                  </Box>
                ))}
            </Box>
            <Box sx={{ display: 'flex', gap: '60px' }}>
              <Grid sx={{ flex: 3 }} container spacing={3}>
                <Grid item md={12}>
                  <Typography
                    sx={{
                      fontSize: '1.5em',
                      fontWeight: 600,
                      marginTop: '30px',
                    }}
                  >
                    <DescriptionIcon
                      sx={{ marginBottom: '0.5rem', marginRight: '0.5rem' }}
                    />
                    Primary Information
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <TextField
                    label="Vendor Name"
                    fullWidth
                    variant="standard"
                    value={primaryData.vendorName}
                    onChange={handlePrimaryDataChange}
                    name="vendorName"
                    required
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    label="Vendor Id"
                    fullWidth
                    variant="standard"
                    value={primaryData.vendorId}
                    onChange={handlePrimaryDataChange}
                    name="vendorId"
                    required
                  />
                </Grid>
                <Grid item md={4}>
                  <FormControl
                    fullWidth
                    variant="standard"
                    sx={{
                      '& .MuiInputBase-root': {
                        padding: '0px',
                      },
                    }}
                  >
                    <InputLabel id="demo-multiple-chip-label">
                      Service Category *
                    </InputLabel>
                    <CustomSelect
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      value={primaryData.vendorService}
                      label="Service Category"
                      name="vendorService"
                      multiple
                      input={<Input id="select-multiple-chip" label="Chip" />}
                      //renderValue={(vendorService) => (
                      //  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      //    {primaryData.vendorService.map((value) => (
                      //      <Chip key={value} label={value} />
                      //    ))}
                      //  </Box>
                      //)}
                      onChange={(e) => {
                        setPrimaryData({
                          ...primaryData,
                          vendorService: e.target.value,
                        });
                      }}
                      requried
                    >
                      <MenuItem value="">none</MenuItem>
                      {services.map((service) => (
                        <MenuItem value={service}>{service}</MenuItem>
                      ))}
                    </CustomSelect>
                  </FormControl>
                </Grid>
                <Grid item md={4}>
                  <TextField
                    label="Contact Person Name"
                    fullWidth
                    variant="standard"
                    value={primaryData.personName}
                    onChange={handlePrimaryDataChange}
                    name="personName"
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    label="E-mail ID"
                    fullWidth
                    variant="standard"
                    value={primaryData.emails}
                    onChange={handlePrimaryDataChange}
                    name="emails"
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    label="Phone Number"
                    fullWidth
                    variant="standard"
                    value={primaryData.phone}
                    onChange={handlePrimaryDataChange}
                    name="phone"
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    label="City"
                    fullWidth
                    variant="standard"
                    value={primaryData.locationCity}
                    onChange={handlePrimaryDataChange}
                    name="locationCity"
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    label="State"
                    fullWidth
                    variant="standard"
                    value={primaryData.locationState}
                    onChange={handlePrimaryDataChange}
                    name="locationState"
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    label="Country"
                    fullWidth
                    variant="standard"
                    value={primaryData.locationCountry}
                    onChange={handlePrimaryDataChange}
                    name="locationCountry"
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    label="Website Link"
                    fullWidth
                    variant="standard"
                    value={primaryData.websiteLink}
                    onChange={handlePrimaryDataChange}
                    name="websiteLink"
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    label="Whatsapp No"
                    fullWidth
                    variant="standard"
                    value={primaryData.whatsappNo}
                    onChange={handlePrimaryDataChange}
                    name="whatsappNo"
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    label="Address"
                    fullWidth
                    variant="standard"
                    value={primaryData.address}
                    onChange={handlePrimaryDataChange}
                    name="address"
                  />
                </Grid>{' '}
                <Grid item md={8}>
                  <Box sx={{ display: 'flex', gap: '10px' }}>
                    <TextField
                      label="Remarks"
                      fullWidth
                      variant="standard"
                      value={currentRemark}
                      onChange={(e) => {
                        setCurrentRemark(e.target.value);
                      }}
                      name="remarks"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment>
                            <CustomButton2
                              onClick={() => {
                                setPrimaryData({
                                  ...primaryData,
                                  remarks: [
                                    ...primaryData.remarks,
                                    currentRemark,
                                  ],
                                });
                                setCurrentRemark('');
                              }}
                              style={{
                                padding: '0px',
                                fontSize: '0.8em',
                                marginBottom: '10px',
                              }}
                              fill
                            >
                              ADD
                            </CustomButton2>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {/* <CustomButton2
                      onClick={() => {
                        setPrimaryData({
                          ...primaryData,
                          remarks: [...primaryData.remarks, currentRemark],
                        });
                        setCurrentRemark("");
                      }}
                      fill
                    >
                      ADD
                    </CustomButton2> */}
                  </Box>
                </Grid>
                <Grid item md={12}>
                  <Typography
                    sx={{
                      fontSize: '1.5em',
                      fontWeight: 600,
                      marginTop: '50px',
                    }}
                  >
                    <BusinessIcon
                      sx={{ marginBottom: '0.5rem', marginRight: '0.5rem' }}
                    />
                    Industry Information
                  </Typography>
                </Grid>
                {/* <Grid item md={4}>
                  <Typography sx={{ color: '#7d7d7d' }}>Rating</Typography>
                  <Rating
                    value={industryData.rating}
                    onChange={(e) => {
                      setIndustryData({
                        ...industryData,
                        rating: e.target.value,
                      });
                    }}
                  />
                </Grid> */}
                <Grid item md={4}>
                  <TextField
                    label="Response Time"
                    fullWidth
                    variant="standard"
                    value={industryData.responseTime}
                    onChange={handleIndustryDataChange}
                    name="responseTime"
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    label="Order Of Services"
                    fullWidth
                    variant="standard"
                    value={industryData.orderOfServices}
                    onChange={handleIndustryDataChange}
                    name="orderOfServices"
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    label="Time For Quotation"
                    fullWidth
                    variant="standard"
                    value={industryData.timeForQuotation}
                    onChange={handleIndustryDataChange}
                    name="timeForQuotation"
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    label="Payment Terms"
                    fullWidth
                    variant="standard"
                    value={industryData.paymentTerms}
                    onChange={handleIndustryDataChange}
                    name="paymentTerms"
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    label="Mode Of Payment"
                    fullWidth
                    variant="standard"
                    value={industryData.modeOfPayment}
                    onChange={handleIndustryDataChange}
                    name="modeOfPayment"
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    label="CIN Number"
                    fullWidth
                    variant="standard"
                    value={industryData.cinNumber}
                    onChange={handleIndustryDataChange}
                    name="cinNumber"
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    label="PAN Number"
                    fullWidth
                    variant="standard"
                    value={industryData.panNumber}
                    onChange={handleIndustryDataChange}
                    name="panNumber"
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    label="GST Number"
                    fullWidth
                    variant="standard"
                    value={industryData.gstNumber}
                    onChange={handleIndustryDataChange}
                    name="gstNumber"
                  />
                </Grid>
                <Grid item md={12}>
                  <Typography
                    sx={{
                      fontSize: '1.5em',
                      fontWeight: 600,
                      marginTop: '50px',
                    }}
                  >
                    <AccountBalanceIcon
                      sx={{ marginBottom: '0.5rem', marginRight: '0.5rem' }}
                    />
                    Bank Details
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <TextField
                    label="Bank Name"
                    fullWidth
                    variant="standard"
                    value={bankData.bankName}
                    onChange={handleBankDataChange}
                    name="bankName"
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    label="Account Name"
                    fullWidth
                    variant="standard"
                    value={bankData.accountName}
                    onChange={handleBankDataChange}
                    name="accountName"
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    label="Account No"
                    fullWidth
                    variant="standard"
                    value={bankData.accountNumber}
                    onChange={handleBankDataChange}
                    name="accountNumber"
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    label="IFSC Code"
                    fullWidth
                    variant="standard"
                    value={bankData.ifsc}
                    onChange={handleBankDataChange}
                    name="ifsc"
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    label="Branch"
                    fullWidth
                    variant="standard"
                    value={bankData.branch}
                    onChange={handleBankDataChange}
                    name="branch"
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    label="Mobil Number(Related to bank account)"
                    fullWidth
                    variant="standard"
                    value={bankData.mobileNumber}
                    onChange={handleBankDataChange}
                    name="mobileNumber"
                  />
                </Grid>
              </Grid>
              <Box
                sx={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '30px',
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: '1.5em',
                      fontWeight: 600,
                      marginTop: '50px',
                    }}
                  >
                    Photos
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor: 'rgba(47, 47, 47, 0.1)',
                      padding: '30px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: '10px',
                      borderRadius: '5px',
                      textAlign: 'center',
                      border: isDragActive ? '2px solid red' : '',
                    }}
                    {...getRootProps({ className: 'dropzone' })}
                  >
                    <input {...getInputProps()} />
                    <UploadIcon width="60px" height="60px" />
                    <Typography>
                      Drag and drop to upload <br />
                      pictures
                    </Typography>
                    <Button
                      sx={{
                        backgroundColor: 'white !important',
                        borderRadius: '5px !important',
                        fontSize: '0.8em !important',
                        color: '#000 !important',
                        padding: '5px 10px !important',
                      }}
                    >
                      Browse Computer
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      marginTop: '10px',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '5px',
                    }}
                  >
                    {acceptedFiles.length > 0 &&
                      acceptedFiles.map((file) => (
                        <Box
                          sx={{
                            width: '100%',
                            border: '1px solid #a4a4a4',
                            borderRadius: '5px',
                            padding: '5px 10px',
                          }}
                        >
                          {file.name}
                        </Box>
                      ))}
                  </Box>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: '1.5em',
                      fontWeight: 600,
                      marginTop: '30px',
                    }}
                  >
                    List of Documents
                  </Typography>
                  <Button
                    sx={{
                      width: '100%',
                      height: 'fit-content',
                      padding: '5px',
                      display: 'grid',
                      placeItems: 'center',
                      color: '#000',
                      backgroundColor: 'rgba(47, 47, 47, 0.1) !important',
                      borderRadius: '5px',
                      margin: '10px 0px !important',
                      cursor: 'pointer',
                      padding: '5px 10px !important',
                    }}
                    onClick={toggleDocModal}
                  >
                    Upload new file
                  </Button>
                  <VendorDocModal
                    state={showDocModal}
                    toggleModal={toggleDocModal}
                    setFiles={setDocs}
                  />
                  <Box
                    sx={{
                      marginTop: '10px',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '5px',
                    }}
                  >
                    {docs.length > 0 &&
                      docs.map((file) => (
                        <Box
                          sx={{
                            width: '100%',
                            border: '1px solid #a4a4a4',
                            borderRadius: '5px',
                            padding: '5px 10px',
                          }}
                        >
                          {file.name}
                        </Box>
                      ))}
                  </Box>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: '1.5em',
                      fontWeight: 600,
                      marginTop: '50px',
                    }}
                  >
                    Supported Documents
                  </Typography>
                  <Button
                    sx={{
                      width: '100%',
                      height: 'fit-content',
                      padding: '5px',
                      display: 'grid',
                      placeItems: 'center',
                      color: '#000',
                      backgroundColor: 'rgba(47, 47, 47, 0.1) !important',
                      borderRadius: '5px',
                      margin: '10px 0px !important',
                      cursor: 'pointer',
                      padding: '5px 10px !important',
                    }}
                    onClick={toggleSupportedModal}
                  >
                    Upload new file
                  </Button>
                  <VendorDocModal
                    state={showSupportedModal}
                    toggleModal={toggleSupportedModal}
                    setFiles={setSpportedDocs}
                  />
                  <Box
                    sx={{
                      marginTop: '10px',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '5px',
                    }}
                  >
                    {supportedDocs.length > 0 &&
                      supportedDocs.map((file) => (
                        <Box
                          sx={{
                            width: '100%',
                            border: '1px solid #a4a4a4',
                            borderRadius: '5px',
                            padding: '5px 10px',
                          }}
                        >
                          {file.name}
                        </Box>
                      ))}
                  </Box>
                </Box>
              </Box>
            </Box>
          </ColosseumProjectsContainer>
        </PageContainer>
      ) : null}
    </Box>
  );
};

export default AddNewVendor;
