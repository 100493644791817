export const stages = [
  { value: "Under Review", label: "Under Review" },
  { value: "Quotation Optimization", label: "Quotation Optimization" },
  { value: "Quotation Uploaded", label: "Quotation Uploaded" },
  { value: "Order Review", label: "Order Review" },
  { value: "Order Accepted", label: "Order Accepted" },
  { value: "Order in process", label: "Order in process" },
  { value: "Shipment Packed", label: "Shipment Packed" },
  { value: "Waiting for Pickup", label: "Waiting for Pickup" },
  { value: "In Transit", label: "In Transit" },
  { value: "Out for Delivery", label: "Out for Delivery" },
  { value: "Successfully Delivered", label: "Successfully Delivered" },
];
