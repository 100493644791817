import {
  styled,
  Box,
  Grid,
  TextField,
  Select,
  Slider,
  Button,
  ButtonBase,
} from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
// import { Select } from "@material-ui/core";

export const PageContainer = styled(Box)(() => ({
  width: "100vw",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
}));

export const SupplierBomProjectsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  flex: 1,
  padding: "50px 50px",
  fontFamily: "Poppins",
  // backgroundColor: "#F4F7FE",
  backgroundColor: "#fff",
  overflowY: "auto",

  "& .title": {
    marginTop: "30px",
    fontWeight: 600,
  },
  "& .sub-title": {
    marginTop: "10px",
    color: "#8B8B8B",
    marginBottom: "50px",
  },
}));

export const StyledInput = styled(TextField)(() => ({
  "& input": {
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: "10px 10px 10px 0px",
    color: "#7A7A7A",
    "&::placeholder": {
      color: "#7A7A7A",
    },
  },
  "&:focus": {
    outline: "none",
  },
  "& legend": {
    width: "unset",
  },
  "& fieldset": {
    border: "none",
  },
  "& .MuiInputBase-root.MuiOutlinedInput-root": {
    backgroundColor: "#fff",
    borderRadius: "8px",
  },
}));

export const CustomButton = styled(Button)(({ disabled }) => ({
  "&&&": {
    width: "fit-content",
    height: "fit-content",
    backgroundColor: "#0275FC",
    color: "#fff",
    borderRadius: "4px",
    padding: "5px 20px",
    background: disabled ? "gray" : "",
    borderColor: disabled ? "gray" : "#0275FC",
  },
}));

export const RootContainer = styled(Box)(({ isActive, small }) => ({
  width: "100%",
  border: !isActive ? "2px solid #8B8B8B4D" : "2px solid red",
  borderRadius: 8,
  padding: small ? "25px" : "25px 140px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: small ? "15px" : "25px",

  "& .main-content": {
    fontSize: "1.3em",
    textAlign: "center",
  },
  "& .sub-content": {
    fontSize: "0.8em",
    textAlign: "center",
    color: "#8B8B8B",
  },
  "& .note-content": {
    fontSize: "0.75em",
    textAlign: "center",
    color: "#8B8B8B",
  },
}));

export const BrowseButton = styled(Button)(() => ({
  "&&": {
    backgroundColor: "#fff",
    color: "#000",
    padding: "10px 20px",
    border: "2px solid #E8E8E8",
    borderRadius: "8px",
    textTransform: "none",
    fontWeight: 400,
  },
}));

export const ProjectChip = styled(Box)(({ color, selected }) => ({
  width: "fit-content",
  height: "fit-content",
  padding: "5px 20px",
  backgroundColor: selected ? color : "white",
  border: `2px solid ${color}`,
  borderRadius: "200px",
  fontSize: "0.8em",
  color: selected ? "white" : color,
  fontSize: "0.8em",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: color,
    color: "white",
  },
}));
