import React, { useState, useEffect } from "react";
import DashboardLogo from "../assests/dashboardLogo.svg";
import DashboardLogo1 from "../assests/elecbitsLogo.svg";
import { axiosInstance } from "../utils/axiosInterceptor";
import "./dashboard.css";
import DashboardHeader from "./DashboardHeader";
import CircularProgress from "@material-ui/core/CircularProgress";
import DashboardStats from "./DashboardStats";
import DashboardTimeLine from "./DashboardTimeLine";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import ProjectView from "./ProjectView";
import ProfileView from "../ProfileComponents/ProfileView/ProfileView";
import ProjectTimeLine from "./ProjectTimeLine";
import HomePage from "../HomeComponent/HomePage";
import ViewProdcuts from "../PCBMarket/ViewProdcuts";
import PCBMarketHeader from "../PCBMarket/PCBMarketHeader";
import ProductDeatils from "../PCBMarket/ProductDetails";
import PcbCart from "../PCBMarket/PCBCart/PCBCart";
import PCBShipping from "../PCBMarket/PCBCart/PCBShipping/PCBShipping";
import AddNewShipping from "../PCBMarket/PCBCart/PCBShipping/AddNewShipping/AddNewShipping";
import OrderPage from "../PCBMarket/OrderPage/OrderPage";
import Billing from "../Billing/BillingHome.js";
// import SupplierDashboard from "../SupplierEngagement/SupplierDashboard";
// import ProjectHomepage from "../BOMProcurement/ProjectHomepage";
import ProjectHomepage from "../BOMProcurement/BomProjects/BomProjects.component";
import BomExplorerTimeLine from "../Admin/BomExplorerTimeline";
// import FabProjectHomepage from "../PCBFabricator/ProjectHomepage.js";
import FabProjectHomepage from "../PCBFabricator/FabProjects/FabProjects.component.jsx";

export default function ClientDashboard(props) {
  const [loading, set_loading] = useState(true);
  const [profile_data, set_profile] = useState(null);
  const [project_info, set_project] = useState(null);
  const [activeStage, setActiveStage] = useState(null);
  const [totalPaidAmount, setTotalPaid] = useState(0);
  const [totalPendingAmount, setToalPending] = useState(0);
  const [allUsers, set_users] = useState([]);

  let fetchProject = () => {
    set_loading(true);
    axiosInstance
      .get("/projects/getOngoingProject")
      .then((resp) => {
        set_project(resp.data);
        let proj = resp.data.response;
        console.log(resp.data);
        if (proj.length >= 1) {
          let active_Stage = proj[0].activeStageNumber;
          let stage = proj[0].stageInfo;
          console.log(active_Stage);
          for (let i = 0; i < stage.length; ++i) {
            if (active_Stage.toString() == stage[i]._id.toString()) {
              setActiveStage(stage[i]);
              break;
            }
          }
        }
        set_loading(false);
      })
      .catch((err) => {
        set_loading(false);
      });
  };

  let fetchProfile = () => {
    set_loading(true);
    axiosInstance
      .get("/users/getProfile")
      .then((resp) => {
        set_profile(resp.data);
        set_loading(false);
      })
      .catch((err) => {
        set_loading(false);
      });
  };

  let fetchUsers = () => {
    set_loading(true);
    axiosInstance
      .get("/users/getRegistered")
      .then((users) => {
        set_loading(false);
        set_users(users);
      })
      .catch((err) => {
        set_loading(false);
      });
  };

  useEffect(() => {
    console.log(props.history.location.pathname.toString().split("/")[1]);
    fetchProject();
    fetchProfile();
    fetchUsers();
  }, []);
  return (
    <div className="_wrap">
      {!loading && profile_data && project_info ? (
        <div className="dashboard_wrapper1">
          <div className="dashboard_wrapper">
            {/* <div className="sider_menu">
            <img src={DashboardLogo}></img>
          </div> */}
            <div className="right_dashboard_content">
              <div className="header">
                {props.history.location.pathname.toString().split("/")[1] ==
                "pcbMarket" ? (
                  <PCBMarketHeader
                    profile_data={profile_data.data}
                    toggleLoggedIn={props.toggleLoggedIn}
                    history={props.history}
                  ></PCBMarketHeader>
                ) : (
                  <DashboardHeader
                    profile_data={profile_data.data}
                    toggleLoggedIn={props.toggleLoggedIn}
                    history={props.history}
                    isBlack
                  ></DashboardHeader>
                )}
              </div>
              <div className="main_body">
                <Switch>
                  <Route
                    path="/virtualProjectManager"
                    exact
                    children={() => (
                      <ProjectView
                        profile_data={profile_data}
                        history={props.history}
                      ></ProjectView>
                    )}
                  ></Route>
                  <Route
                    path="/profile"
                    exact
                    history={props.history}
                    children={() => (
                      <ProfileView history={props.history}></ProfileView>
                    )}
                  ></Route>
                  <Route
                    path="/viewProject/:projectId"
                    exact
                    history={props.history}
                    render={(props) => (
                      <ProjectTimeLine
                        allUsers={allUsers}
                        history={props.history}
                      ></ProjectTimeLine>
                    )}
                  ></Route>
                  <Route
                    path="/"
                    exact
                    history={props.history}
                    render={(props) => (
                      <HomePage
                        history={props.history}
                        hasProfile={profile_data?.data.phoneNumber}
                      />
                    )}
                  ></Route>
                  <Route
                    path="/pcbMarket"
                    exact
                    history={props.history}
                    render={(props) => (
                      <ViewProdcuts history={props.history}></ViewProdcuts>
                    )}
                  ></Route>

                  <Route
                    path="/bomExplorer/project"
                    exact
                    history={props.history}
                    render={(props) => (
                      <ProjectHomepage history={props.history} />
                    )}
                  ></Route>
                  <Route
                    path="/pcbFab/projects"
                    exact
                    history={props.history}
                    render={(props) => (
                      <FabProjectHomepage history={props.history} />
                    )}
                  ></Route>
                  <Route
                    path="/bomExplorer/project/timeline"
                    exact
                    history={props.history}
                    render={(props) => (
                      <BomExplorerTimeLine history={props.history} />
                    )}
                  ></Route>
                  <Route
                    path="/billing"
                    exact
                    history={props.history}
                    render={(props) => {
                      return <Billing></Billing>;
                    }}
                  ></Route>
                  <Route
                    path="/pcbMarket/productView/:projId"
                    exact
                    history={props.history}
                    render={(props) => (
                      <ProductDeatils history={props.history}></ProductDeatils>
                    )}
                  ></Route>
                  <Route
                    path="/pcbMarket/cart"
                    exact
                    history={props.history}
                    render={(props) => (
                      <PcbCart history={props.history}></PcbCart>
                    )}
                  ></Route>
                  <Route
                    path="/pcbMarket/cart/addNewShipping"
                    exact
                    history={props.history}
                    render={(props) => (
                      <AddNewShipping history={props.history}></AddNewShipping>
                    )}
                  ></Route>
                  <Route
                    path="/home"
                    exact
                    history={props.history}
                    render={(props) => <HomePage history={props.history} />}
                  ></Route>
                  <Route
                    path="/pcbMarket"
                    exact
                    history={props.history}
                    render={(props) => (
                      <ViewProdcuts history={props.history}></ViewProdcuts>
                    )}
                  ></Route>
                  <Route
                    path="/pcbMarket/productView/:projId"
                    exact
                    history={props.history}
                    render={(props) => (
                      <ProductDeatils history={props.history}></ProductDeatils>
                    )}
                  ></Route>
                  <Route
                    path="/pcbMarket/cart"
                    exact
                    history={props.history}
                    render={(props) => (
                      <PcbCart history={props.history}></PcbCart>
                    )}
                  ></Route>
                  <Route
                    path="/pcbMarket/cart/addNewShipping"
                    exact
                    history={props.history}
                    render={(props) => (
                      <AddNewShipping history={props.history}></AddNewShipping>
                    )}
                  ></Route>
                  <Route
                    path="/pcbMarket/orderPage"
                    exact
                    history={props.history}
                    render={(props) => (
                      <OrderPage history={props.history}></OrderPage>
                    )}
                  ></Route>
                </Switch>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <img src={DashboardLogo1} style={{ width: "150px" }} alt="logo"></img>
          <CircularProgress style={{ marginLeft: "20px" }}></CircularProgress>
        </>
      )}
    </div>
  );
}
