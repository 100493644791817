import React, { useState, useEffect } from "react";
// import PCBMarketHeader from "./PCBMarketHeader";
import PCBMarketNewHeader from "./PCBMarketNewHeader";
import Button from "@mui/material/Button";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import AddProduct from "./AddProduct";
import ManageProducts from "./ManageProducts";
import DashboardLogo1 from "../assests/elecbitsLogo.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import { axiosInstance } from "../utils/axiosInterceptor";
import DisplayOrdersAdmin from "./DisplayOrdersAdmin";
import AdminBilling from "./AdminBilling";
import { Typography, Box } from "@mui/material";
import { createTheme } from "@material-ui/core";
import { ThemeProvider } from "@mui/material/styles";
import EditProduct from "./EditProduct";
const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
  },
});
export default function AdminPCBMarket(props) {
  const [loading, set_loading] = useState(true);
  const [profile_data, set_profile] = useState(null);

  let fetchProfile = () => {
    set_loading(true);
    axiosInstance
      .get("/users/getProfile")
      .then((resp) => {
        set_profile(resp.data);
        set_loading(false);
      })
      .catch((err) => {
        set_loading(false);
      });
  };

  useEffect(() => {
    fetchProfile();
  }, []);
  const pathname = useLocation();
  return (
    <div>
      {!loading && profile_data ? (
        <>
          <PCBMarketNewHeader
            profile_data={profile_data.data}
            toggleLoggedIn={props.toggleLoggedIn}
            history={props.history}
            isBlack={true}
          ></PCBMarketNewHeader>

          <div className="main_adminPcb_body">
            <Switch>
              <Route
                path="/pcbMarket"
                exact
                children={() => {
                  console.log("asd");
                  return (
                    <>
                      <Box
                        sx={{
                          fontSize: "1.8rem",
                          fontWeight: "bold",
                          margin: "2rem 2.8rem",
                        }}
                      >
                        <Typography variant="h6" gutterBottom component="div">
                          <strong>Elecbits PCB Arcade Admin Console 🤖</strong>
                        </Typography>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          PCBs PCBs Everywhere!
                        </Typography>

                        <div style={{ paddingTop: "20px" }}>
                          <ThemeProvider theme={theme}>
                            <Link to="/pcbMarket/allOrders">
                              <Button
                                variant="outlined"
                                size="large"
                                sx={{
                                  mr: 4,
                                  width: 200,
                                  pt: 2,
                                  pb: 2,
                                }}
                                style={{
                                  justifyContent: "flex-start",
                                  textTransform: "capitalize",
                                }}
                              >
                                📦 All Orders
                              </Button>
                            </Link>
                            <Link to="/pcbMarket/manageProducts">
                              <Button
                                variant="outlined"
                                size="large"
                                sx={{
                                  mr: 4,
                                  width: 200,
                                  pt: 2,
                                  pb: 2,
                                }}
                                style={{
                                  justifyContent: "flex-start",
                                  textTransform: "capitalize",
                                }}
                                // startIcon={<DeleteIcon />}
                              >
                                🛠 Manage Products
                              </Button>
                            </Link>
                          </ThemeProvider>
                        </div>
                      </Box>
                    </>
                  );
                }}
              ></Route>
              <Route
                path="/pcbMarket/manageProducts"
                exact
                children={(props) => {
                  return (
                    <ManageProducts history={props.history}></ManageProducts>
                  );
                }}
              ></Route>
              <Route
                path="/pcbMarket/addProduct"
                exact
                children={(props) => {
                  return <AddProduct history={props.history}></AddProduct>;
                }}
              ></Route>
              <Route
                path="/pcbMarket/allOrders"
                exact
                children={() => {
                  return <DisplayOrdersAdmin></DisplayOrdersAdmin>;
                }}
              ></Route>
              <Route
                path="/pcbMarket/viewCart/:cartId"
                exact
                children={() => {
                  return <AdminBilling></AdminBilling>;
                }}
              ></Route>
              <Route
                path="/pcbMarket/editProduct/:productId"
                exact
                children={() => {
                  return <EditProduct></EditProduct>;
                }}
              ></Route>
            </Switch>
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <img src={DashboardLogo1} style={{ width: "150px" }}></img>
          <CircularProgress style={{ marginLeft: "20px" }}></CircularProgress>
        </div>
      )}
    </div>
  );
}
