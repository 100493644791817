import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ElecbitsLogo from "../assests/elecbitsLogo.svg";
import "./CustomerOnBoarding.css";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

import { SnackbarProvider, useSnackbar } from "notistack";
import { customeronBoardingAction } from "./customerAction";
import { axiosInstance } from "../utils/axiosInterceptor";
import { useHistory } from "react-router";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@mui/material/FormControl";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
{
  /* <MenuItem value="Design and Development">
Design and Development
</MenuItem>
<MenuItem value="PCB Fabrication">PCB Fabrication</MenuItem>
<MenuItem value="BOM Procurement">BOM Procurement</MenuItem>
<MenuItem value="PCBjustifyContent Assembly"> PCB Assembly</MenuItem>
<MenuItem value="3D/Enclosure Services">
3D/Enclosure Services
</MenuItem>
<MenuItem value="Certification">Certification</MenuItem>
<MenuItem value="Consultation with Experts">
Consultation with Experts
</MenuItem> */
}

const servicesList = [
  "Design and Development",
  "PCB Fabrication",
  "BOM Procurement",
  "PCB Assembly",
  "3D/Enclosure Services",
  "Certification",
  "Consultation with Experts",
];
const currentStages = [
  "Design and development",
  "Prototype",
  "Low Volume Production (0-100)",
  "Medium Volume Production (100-1000)",
  "High Volume Production (>1000)",
];
const CustomerOnBoarding = (props) => {
  const history = useHistory();
  useEffect(async () => {
    const res = await axiosInstance.get("/users/getProfile");
    if (!res.data.data.stage) {
      history.push("/client/onBoarding");
    }
  }, []);

  const [data, setData] = useState({
    fullName: "",
    enterpriseName: "",
    phoneNumber: "",
    stage: "formFilled",
    industry: "",
    enterpriseOrStartUpOrIndividual: "",
    services: [],
    lastName: "",
    firstName: "",
    currentStage: "",
    location: "",
    designation: "",
  });
  const { enqueueSnackbar } = useSnackbar();
  const onChangeHandler = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const onSubmitHandler = (e) => {
    e.preventDefault();
    console.log(data);
    // const phoneRegExp = new RegExp(
    //   '^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$'
    // );

    if (!isValidPhoneNumber(data.phoneNumber)) {
      enqueueSnackbar("Please enter a valid phone number", {
        variant: "error",
      });
      return;
    }
    const newData = {
      ...data,
      firstName: data.fullName.split(" ")[0],
      lastName: data.fullName.split(" ")[1],
    };
    customeronBoardingAction(newData, enqueueSnackbar, props);
  };

  return (
    <div className="main">
      <div className="top">
        <img src={ElecbitsLogo} />
      </div>
      <div className="details">
        <p className="title">You are almost here!</p>

        <p className="sub-title">
          Please share the following details to help us serve you better
        </p>
        <form
          onSubmit={onSubmitHandler}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <FormControl sx={{ m: 1, width: "100%" }}>
            <div className="textfields">
              <TextField
                variant="outlined"
                className="textCustomer"
                label="Full Name"
                name="fullName"
                onChange={(e) => onChangeHandler(e)}
                required
              />
              <PhoneInput
                style={{
                  border: "1px solid #c4c4c4",
                  padding: "0.8rem 0.4rem",
                  borderRadius: "0.2rem",
                }}
                placeholder="Enter phone number"
                className="phoneInput"
                name="phoneNumber"
                label="Phone Number"
                onChange={(e) => setData({ ...data, phoneNumber: e })}
                required
              />
              <TextField
                variant="outlined"
                className="textCustomer"
                label="Location"
                name="location"
                onChange={(e) => onChangeHandler(e)}
                required
              />{" "}
              <TextField
                variant="outlined"
                className="textCustomer"
                label="Designation"
                name="designation"
                onChange={(e) => onChangeHandler(e)}
                required
              />
              {/* <TextField
                variant="outlined"
                className="textCustomer"
                labdefaultValue={['']}
                el="Phone Number"
                name="phoneNumber"
                label="Phone Number"
                onChange={(e) => onChangeHandler(e)}
                required
                type="number"
              /> */}
              {/* <TextField
            variant="outlined"
            className="textCustomer"
            label="Enterprise / Startup / Individual Dropdown"
          /> */}
              <TextField
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                className="textCustomer"
                variant="outlined"
                select
                label="Organization Type"
                defaultValue={""}
                name="enterpriseOrStartUpOrIndividual"
                onChange={(e) => onChangeHandler(e)}
                required
              >
                <MenuItem value="Start Up">Start Up</MenuItem>
                <MenuItem value="Enterprise">Enterprise</MenuItem>
                <MenuItem value="Individual">Individual</MenuItem>
              </TextField>
              <TextField
                variant="outlined"
                className="textCustomer"
                label="If enterprise/startup, company name"
                name="enterpriseName"
                onChange={(e) => onChangeHandler(e)}
                required
              />
              <TextField
                label="Industry"
                className="textCustomer"
                variant="outlined"
                defaultValue={""}
                name="industry"
                onChange={(e) => onChangeHandler(e)}
                select
                required
              >
                <MenuItem value="Agritech">Agritech</MenuItem>
                <MenuItem value="Industry">Industry</MenuItem>
                <MenuItem value="IIOT">IIOT</MenuItem>
                <MenuItem value=" Electronics Manufacturing Services (EMS)">
                  {" "}
                  Electronics Manufacturing Services (EMS)
                </MenuItem>
                <MenuItem value="Electric Vehicles">Electric Vehicles</MenuItem>
                <MenuItem value="Smart Home">Smart Home</MenuItem>
                <MenuItem value="Smart Building">Smart Building</MenuItem>
                <MenuItem value="Healthcare">Healthcare</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </TextField>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <p
                  style={{ margin: "0rem 1rem -1.1rem 1.1rem", color: "gray" }}
                >
                  Services interested in
                </p>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  multiple
                  label="Services Interested In"
                  className="textCustomer"
                  variant="outlined"
                  value={data.services}
                  name="services"
                  onChange={(e) => onChangeHandler(e)}
                  required
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>Placeholder</em>;
                    }
                    return selected.join(", ");
                  }}
                >
                  {servicesList.map((service) => {
                    return (
                      <MenuItem value={service}>
                        <Checkbox
                          color="primary"
                          checked={data.services.indexOf(service) > -1}
                        />
                        <ListItemText primary={service} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
              <TextField
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                className="textCustomer"
                variant="outlined"
                select
                label="Current Stage"
                defaultValue={""}
                name="currentStage"
                onChange={(e) => onChangeHandler(e)}
                required
              >
                {currentStages.map((stage) => {
                  return <MenuItem value={stage}>{stage}</MenuItem>;
                })}
              </TextField>
            </div>
          </FormControl>

          <Button type="submit" className="buttonCustomer">
            Create account
          </Button>
        </form>
      </div>
    </div>
  );
};

export default CustomerOnBoarding;
